import { ActionIcon } from '@mantine/core'
import { IconClose } from 'components/icons/IconClose'
import { useEffect, useMemo, useState } from 'react'
import { colors } from 'settings/colors'

const DURATION = 3000

export type NotificationType = 'success' | 'error'

const MessageWrapper = (props: {
  icon: React.ReactNode
  children: React.ReactNode
  handleClose: () => void
}) => (
  <div
    css={{
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      marginRight: 20,
      padding: 15,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      justifyContent: 'space-between',
      height: 50,
      flexGrow: 1,
      borderRadius: 3,
    }}
  >
    <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
      <div>{props.icon}</div>
      <div css={{ fontSize: 16, fontWeight: 500 }}>{props.children}</div>
    </div>
    <ActionIcon css={{ color: colors.grey800, marginTop: -15 }} onClick={() => props.handleClose()}>
      <IconClose />
    </ActionIcon>
  </div>
)

export const useRelativeNotification = () => {
  const [isNotificationShown, setIsNotificationShown] = useState<boolean>(false)
  const [type, setType] = useState<NotificationType>('success')
  const [text, setText] = useState<string>('')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isNotificationShown) setIsNotificationShown(false)
    }, DURATION)

    return () => clearTimeout(timer)
  }, [isNotificationShown])

  const showRelativeNotification = ({ type, message }: { type: NotificationType; message: string }) => {
    setText(message)

    setType(type)

    setIsNotificationShown(true)
  }

  const handleClose = () => {
    setIsNotificationShown(false)
  }

  const getNotification = () => {
    if (!isNotificationShown) return <div css={{ height: 50 }} />

    switch (type) {
      case 'error':
        return (
          <MessageWrapper
            icon={
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.2773" cy="10.7034" r="10" fill={colors.red} />
                <path
                  d="M14.2773 7.50908L13.4716 6.70337L10.2773 9.89766L7.08306 6.70337L6.27734 7.50908L9.47163 10.7034L6.27734 13.8977L7.08306 14.7034L10.2773 11.5091L13.4716 14.7034L14.2773 13.8977L11.0831 10.7034L14.2773 7.50908Z"
                  fill="white"
                />
              </svg>
            }
            handleClose={handleClose}
          >
            {text}
          </MessageWrapper>
        )

      case 'success':
        return (
          <MessageWrapper
            icon={
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10.2422" cy="10.5366" r="10" fill={colors.green} />
                <path
                  d="M8.06037 13.656L5.19673 10.8351L4.24219 11.7754L8.06037 15.5366L16.2422 7.47692L15.2876 6.53662L8.06037 13.656Z"
                  fill="white"
                />
              </svg>
            }
            handleClose={handleClose}
          >
            {text}
          </MessageWrapper>
        )

      default:
        return null
    }
  }

  const RelativeNotification = useMemo(() => getNotification(), [isNotificationShown])

  return { showRelativeNotification, RelativeNotification }
}
