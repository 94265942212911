import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { colors } from 'settings/colors'
import type { ButtonProps } from '../../../../components/Button/Button'
import { Button } from '../../../../components/Button/Button'

const ThreeButtons = (props: ButtonProps) => {
  return (
    <div css={{ display: 'flex', gap: 24 }}>
      <div>
        <Button variant={props.variant} {...props}>
          {'Button Text'}
        </Button>
        <div css={{ color: colors.grey500, fontSize: 10 }}>{'No icon'}</div>
      </div>

      <div>
        <Button variant={props.variant} leftIcon={<FontAwesomeIcon icon={faCircle} />} {...props}>
          {'Button Text'}
        </Button>
        <div css={{ color: colors.grey500, fontSize: 10 }}>{'Left icon'}</div>
      </div>

      <div>
        <Button variant={props.variant} rightIcon={<FontAwesomeIcon icon={faCircle} />} {...props}>
          {'Button Text'}
        </Button>
        <div css={{ color: colors.grey500, fontSize: 10 }}>{'Right icon'}</div>
      </div>
    </div>
  )
}

export const ButtonVariantSection = (props: { variant: ButtonProps['variant'] }) => {
  return (
    <div>
      <h2 css={{ margin: '6px 0' }}>
        <code>{props.variant}</code>
      </h2>
      <div>
        <div>{'Normal'}</div>
        <ThreeButtons variant={props.variant} />

        <div>{'Disabled'}</div>
        <ThreeButtons variant={props.variant} disabled />

        <div>{'Loading'}</div>
        <ThreeButtons variant={props.variant} loading />

        <div>{'async: Success'}</div>
        <ThreeButtons variant={props.variant} async />

        <div>{'async: Success (Custom)'}</div>
        <ThreeButtons
          variant={props.variant}
          async
          asyncOptions={{
            loadingContent: 'Custom loading message...',
            successContent: 'Custom success message',
            errorContent: 'Custom error message',
            minimumVisualLoadingMilliseconds: 3000,
            errorMilliseconds: 3000,
            successMilliseconds: 3000,
          }}
        />

        <div>{'async: Fail'}</div>
        <ThreeButtons
          variant={props.variant}
          async
          onClick={async () => {
            throw new Error()
          }}
        />

        <div>{'async: Fail (Custom)'}</div>
        <ThreeButtons
          variant={props.variant}
          async
          asyncOptions={{
            loadingContent: 'Custom loading message...',
            successContent: 'Custom success message',
            errorContent: 'Custom error message',
            minimumVisualLoadingMilliseconds: 3000,
            errorMilliseconds: 3000,
            successMilliseconds: 3000,
          }}
          onClick={async () => {
            throw new Error()
          }}
        />
      </div>
    </div>
  )
}
