import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { Alert, Contact, Group } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { SleepUnitsType } from '../ModalDrawerCreateOrEditAlert'

export const convertAPIAlertToInitivalValues = ({ initialValues }: { initialValues: Alert | null }) => {
  const propertiesObject = fieldAssetStore.getState().properties
  const allContactGroups = userDetailsStore.getState().groups
  // note that blocks and repeatAnnually are not included, as they weren't used at the time of coding this
  const alertTypeId = (initialValues && initialValues.type.id) || null

  const contacts: Contact[] =
    (initialValues && initialValues.contacts && initialValues.contacts.length && initialValues.contacts) || []

  //@ts-expect-error: null data already filtered
  const groups: Group[] =
    (initialValues &&
      initialValues.groups &&
      initialValues.groups.length &&
      initialValues.groups
        .map((group) => {
          const groupInAllGroups = allContactGroups.find((g) => g.id === String(group.id))

          if (groupInAllGroups)
            return { ...groupInAllGroups, alertsContactMethods: group.alertsContactMethods }

          return null
        })
        .filter((g) => g !== null)) ||
    []

  const endDate =
    (initialValues && initialValues.endDate && moment.tz(initialValues.endDate, 'America/Los_Angeles')) ||
    null

  const frequencyMinutes = (initialValues && initialValues.frequencyMinutes) || null
  const insectId = (initialValues && Number(initialValues.insect?.id)) || null
  const name = (initialValues && initialValues.name) || null

  const notifyOwnerByEmail =
    initialValues && typeof initialValues.notifyOwnerByEmail === 'boolean'
      ? !!initialValues.notifyOwnerByEmail
      : true // should be true by default

  const notifyOwnerBySMS =
    initialValues && typeof initialValues.notifyOwnerBySMS === 'boolean'
      ? !!initialValues.notifyOwnerBySMS
      : !!userDetailsStore.getState().smsNumber // make it true if the user has one

  const rules = (initialValues && Array.isArray(initialValues.rules) && initialValues.rules) || []
  const sleepMinutes = (initialValues && initialValues.sleepMinutes) || null

  const startDate =
    (initialValues && initialValues.startDate && moment.tz(initialValues.startDate, 'America/Los_Angeles')) ||
    null

  let coverageType = 'all'
  let coverageProperties: string[] = !initialValues ? [] : initialValues.properties.map((p) => p.id)

  coverageProperties = [...new Set(coverageProperties)].map(String)

  if (coverageProperties.length) coverageType = 'properties'

  let coverageStations: string[] = []
  let coverageBlocks: string[] = []

  const hasStations = initialValues && Array.isArray(initialValues.stations) && initialValues.stations.length
  const hasBlocks = initialValues && Array.isArray(initialValues.blocks) && initialValues.blocks.length

  if (initialValues && (hasStations || hasBlocks)) {
    coverageProperties = []

    coverageType = 'stationsOrBlocks'

    /**
     * we want to build an array of stations if the coverage was for specific stations,
     * but all we have is the geom for a station, and propertyIds, but we need to have
     * strings of the form `${propertyId}-${stationGEOM}`, so we can map over the stations
     * and add each property id in front of them, but do it cleverly so that we only
     * include property/geom pairs that exist in reality. Also, make it a unique array,
     * to avoid exceptions—though there SHOULDN'T be any
     */
    if (hasStations) {
      coverageStations = [
        ...new Set(
          initialValues.stations
            .map((sId) => {
              // for each station, map over each property that we received
              return (initialValues.properties || [])
                .map((pId) => {
                  // make a string of propertyId-GEOM, but only if that property/geom combo exist in reality
                  try {
                    const property = propertiesObject && propertiesObject[Number(pId.id)]

                    const isPointInPropertyHasThatGeom = Object.values(property?.points || {}).some(
                      (p) => p.geom === sId.geom,
                    )

                    if (isPointInPropertyHasThatGeom) return `${pId.id}&${sId.geom}`
                  } catch (err) {}

                  return ''
                })
                .filter((s) => !!s.length) // filter out the empty strings from the mapping
            })
            // do a reduce to flatten the array
            .reduce((a, b) => a.concat(b), []),
        ),
      ]
    }

    if (hasBlocks) {
      coverageBlocks = [
        ...new Set(
          initialValues.blocks
            .map((bId) => {
              // for each station, map over each property that we received
              return (initialValues.properties || [])
                .map((pId) => {
                  // make a string of propertyId-blockId, but only if that property/blockId combo exist in reality
                  try {
                    const property = propertiesObject && propertiesObject[Number(pId.id)]

                    if (property?.blocks && property.blocks[Number(bId?.id)]) {
                      return `${pId.id}&${bId.id}`
                    }
                  } catch (err) {}

                  // otherwise return empty string
                  return ''
                })
                .filter((s) => !!s.length) // filter out the empty strings from the mapping
            })
            // do a reduce to flatten the array
            .reduce((a, b) => a.concat(b), []),
        ),
      ]
    }
  }

  let sleepUnits: SleepUnitsType = 'minutes'

  if (sleepMinutes) {
    if (sleepMinutes % 1440 === 0) sleepUnits = 'days'
    else if (sleepMinutes % 60 === 0) sleepUnits = 'hours'
  }

  return {
    alertTypeId,
    contacts,
    coverageProperties,
    coverageStations,
    coverageBlocks,
    coverageType,
    endDate,
    frequencyMinutes,
    groups,
    insectId,
    name,
    notifyOwnerByEmail,
    notifyOwnerBySMS,
    rules,
    sleepMinutes,
    sleepUnits,
    startDate,
  }
}

export default convertAPIAlertToInitivalValues
