import { Tabs, useMantineTheme } from '@mantine/core'
import { isEmpty } from '@semios/app-platform-common'
import { mapPropertiesForPropertySelect } from 'App/irrigation-scheduler/utils/mapPropertiesForPropertySelect'
import { getIrrigationGroupProperties } from 'App/irrigation-scheduler/utils/store/selectors/getIrrigationGroupProperties'
import { getIrrigationProperties } from 'App/irrigation-scheduler/utils/store/selectors/getIrrigationProperties'
import { SettingsCreateNewButton } from 'App/Map/UserSettingsMenu/components/SettingsCreateNewButton/SettingsCreateNewButton'
import { PropertySelect } from 'components/PropertySelect/PropertySelect'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { sortByKey } from 'utils/sortByKey'
import { GroupsTab } from './GroupsTab/GroupsTab'
import { ZonesTab } from './ZonesTab/ZonesTab'

type TActiveTab = 'GROUPS' | 'ZONES'

export const IrrigationSchedulerSettingsTabs = ({
  selectedPropertyId,
  setSelectedPropertyId,
  openNewGroupModal,
}: {
  selectedPropertyId: number | null
  setSelectedPropertyId: (arg: number | null) => void
  openNewGroupModal: () => void
}) => {
  const [activeTab, setActiveTab] = useState<TActiveTab>('GROUPS')
  const [filterPropertiesText, setFilterPropertiesText] = useState('')
  const theme = useMantineTheme()
  const fass = fieldAssetStore.useSelector((s) => s)
  const propertiesWithEmitters = getIrrigationProperties(fass)
  const propertiesWithEmitterGroups = getIrrigationGroupProperties(fass)
  const filteredProperties = activeTab === 'GROUPS' ? propertiesWithEmitterGroups : propertiesWithEmitters
  const selectedPropertyOptions = filteredProperties.filter((p) => p.propertyId === selectedPropertyId)

  return (
    <Tabs
      css={{ marginTop: 24 }}
      styles={{
        tabsList: {
          button: {
            'fontSize': 15,
            'padding': 10,
            'color': theme.colors.primary[0],
            '&:hover': { background: 'transparent', fontWeight: 600 },
            '&[data-active]': {
              'fontWeight': 700,
              'borderBottom': `4px solid ${colors.midnight}`,
              '&:hover': { fontWeight: 700, borderColor: colors.midnight },
            },
            '&:not(:first-of-type)': { marginLeft: 20 },
          },
        },
      }}
      value={activeTab}
      onTabChange={(val: TActiveTab) => setActiveTab(val)}
    >
      <Tabs.List>
        <Tabs.Tab value="GROUPS">{translate.phrases.banyanApp('Groups')}</Tabs.Tab>
        <Tabs.Tab value="ZONES">{translate.phrases.banyanApp('Zones')}</Tabs.Tab>
      </Tabs.List>
      <div css={{ display: 'flex', gap: '20px', padding: '10px 0' }}>
        <div css={{ flexGrow: 2 }}>
          <SearchTextInput
            placeholder={
              activeTab === 'GROUPS'
                ? translate.phrases.banyanApp('Filter groups')
                : translate.phrases.banyanApp('Filter zones')
            }
            searchText={filterPropertiesText}
            setSearchText={setFilterPropertiesText}
            clearable={true}
          />
        </div>
        <div css={{ flexGrow: 1 }}>
          <PropertySelect
            selectedPropertyId={selectedPropertyId}
            onChange={setSelectedPropertyId}
            disabled={isEmpty(filteredProperties)}
            data={mapPropertiesForPropertySelect(propertiesWithEmitters)}
            clearable={true}
          />
        </div>
      </div>
      <Tabs.Panel value="GROUPS">
        {selectedPropertyId && !selectedPropertyOptions.length ? (
          <div>
            <SectionTitleBar
              collapsed={false}
              onCollapse={() => {
                // no op
                return
              }}
              title={selectedPropertyId && fass.properties?.[selectedPropertyId]?.propertyName}
            />
            <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: 20 }}>
              <div css={{ fontStyle: 'italic' }}>
                {translate.phrases.banyanApp('Property does not have any groups yet.')}
              </div>{' '}
              <SettingsCreateNewButton onClick={openNewGroupModal} />
            </div>
          </div>
        ) : (
          <GroupsTab
            filterPropertiesText={filterPropertiesText}
            properties={
              selectedPropertyId && selectedPropertyOptions.length
                ? selectedPropertyOptions
                : filteredProperties.sort(sortByKey('propertyName'))
            }
          />
        )}
      </Tabs.Panel>
      <Tabs.Panel value="ZONES">
        <ZonesTab
          filterPropertiesText={filterPropertiesText}
          properties={
            selectedPropertyId && selectedPropertyOptions.length
              ? selectedPropertyOptions
              : filteredProperties.sort(sortByKey('propertyName'))
          }
        />
      </Tabs.Panel>
    </Tabs>
  )
}
