import type { CSSObject } from '@emotion/css'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import type { ReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { makeReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { IrrigationZoneValueBadgeComponent } from './IrrigationZoneValueBadgeComponent/IrrigationZoneValueBadgeComponent'

export type IRRIGATION_ZONE_VALUE_META = {
  irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  irrigationZoneName: string
  irrigationZoneEmitterType: TFieldAssetKeyTypes.TEmitterType
  irrigationZoneId: number
  propertyId: number
  bounds: google.maps.LatLngBounds
  latLngs: google.maps.LatLng[]
  latLng: google.maps.LatLng
  onClick: () => void
}

// TODO: will eventually come from current values type
export type TIrrigationZonesValueTypesToPropsDictionary = Record<
  string,
  {
    children: React.ReactNode
    getContainerCSS: () => CSSObject
    progressText?: string
    progressPercent?: number
  }
>

export class IrrigationZoneValuesCache extends MapItemsCache<
  IRRIGATION_ZONE_VALUE_META,
  TIrrigationZonesValueTypesToPropsDictionary,
  {
    badge: ReactComponentOverlayView<{
      meta: IRRIGATION_ZONE_VALUE_META
      valueTypesToPropsDictionary: TIrrigationZonesValueTypesToPropsDictionary
    }>
    polygon?: google.maps.Polygon
  }
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        const polygon = hubConnectorSettings.enableIrrigationZonePolygons
          ? new o.maps.Polygon({ paths: o.meta.latLngs, map: o.map })
          : undefined

        polygon?.addListener('click', () => {
          setSelectedFieldAsset({
            irrigationZoneEmitter: o.meta.irrigationZoneEmitterId,
          })
        })

        return {
          polygon,
          badge: makeReactComponentOverlayView({
            component: IrrigationZoneValueBadgeComponent,
            latLng: o.meta.latLng,
            maps: o.maps,
            map: o.map,
          }),
        }
      },
      renderOverlay: (o) => {
        const isSelected =
          selectedFieldAssetsStore.getState().irrigationZoneEmitter === o.meta.irrigationZoneEmitterId

        o.overlay.polygon?.setOptions({
          map: o.map,
          strokeColor: isSelected ? 'yellow' : 'white',
          strokeWeight: isSelected ? 4 : 2,
          strokePosition: o.maps.StrokePosition.CENTER,
          fillColor: 'transparent',
          fillOpacity: 0.6,
        })

        o.overlay.badge.render({ ...o, valueTypesToPropsDictionary: o.value })
      },
      hideOverlay: (o) => {
        o.overlay.polygon?.setMap(null)

        o.overlay.badge.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
