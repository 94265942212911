import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconDrizzle = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 350 312')}>
      <g clipPath="url(#clip0_11_471)">
        <g clipPath="url(#clip1_11_471)">
          <path
            d="M291 107C290.15 107 289.32 107.09 288.47 107.13C293.294 87.7023 291.013 67.1901 282.039 49.2967C273.065 31.4034 257.989 17.3083 239.534 9.55632C221.078 1.80437 200.459 0.906499 181.399 7.02481C162.339 13.1431 146.095 25.8744 135.6 42.9199C127.108 37.8427 117.42 35.11 107.526 35.0009C97.6323 34.8918 87.8868 37.4103 79.2846 42.2991C70.6824 47.1879 63.5316 54.272 58.5624 62.828C53.5932 71.3841 50.9835 81.1056 50.9999 90.9999C51.0218 94.0432 51.2893 97.0797 51.7999 100.08C37.1521 102.897 24.0788 111.069 15.1298 123.003C6.18078 134.936 1.99767 149.776 3.39631 164.626C4.79495 179.477 11.675 193.274 22.6948 203.327C33.7145 213.379 48.0837 218.967 62.9999 219C64.3499 219 65.6699 218.89 66.9999 218.8V219H291C305.852 219 320.096 213.1 330.598 202.598C341.1 192.096 347 177.852 347 163C347 148.148 341.1 133.904 330.598 123.402C320.096 112.9 305.852 107 291 107Z"
            fill="url(#paint0_linear_11_471)"
            stroke="#CAD6E8"
            strokeWidth="6"
            strokeMiterlimit="10"
          />
        </g>
        <g clipPath="url(#clip2_11_471)">
          <path
            d="M119 231C116.878 231 114.843 230.157 113.343 228.657C111.843 227.157 111 225.122 111 223V211C111 208.878 111.843 206.843 113.343 205.343C114.843 203.843 116.878 203 119 203C121.122 203 123.157 203.843 124.657 205.343C126.157 206.843 127 208.878 127 211V223C127 225.122 126.157 227.157 124.657 228.657C123.157 230.157 121.122 231 119 231Z"
            fill="url(#paint1_linear_11_471)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
          <path
            d="M175 311C172.878 311 170.843 310.157 169.343 308.657C167.843 307.157 167 305.122 167 303V291C167 288.878 167.843 286.843 169.343 285.343C170.843 283.843 172.878 283 175 283C177.122 283 179.157 283.843 180.657 285.343C182.157 286.843 183 288.878 183 291V303C183 305.122 182.157 307.157 180.657 308.657C179.157 310.157 177.122 311 175 311Z"
            fill="url(#paint2_linear_11_471)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
          <path
            d="M231 261C228.878 261 226.843 260.157 225.343 258.657C223.843 257.157 223 255.122 223 253V241C223 238.878 223.843 236.843 225.343 235.343C226.843 233.843 228.878 233 231 233C233.122 233 235.157 233.843 236.657 235.343C238.157 236.843 239 238.878 239 241V253C239 255.122 238.157 257.157 236.657 258.657C235.157 260.157 233.122 261 231 261Z"
            fill="url(#paint3_linear_11_471)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_471"
          x1="99.4499"
          y1="30.6799"
          x2="232.64"
          y2="261.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F7FE" />
          <stop offset="0.45" stopColor="#F3F7FE" />
          <stop offset="1" stopColor="#DEEAFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_471"
          x1="112.395"
          y1="205.575"
          x2="125.593"
          y2="228.432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_471"
          x1="168.398"
          y1="285.569"
          x2="181.598"
          y2="308.436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_471"
          x1="224.396"
          y1="235.574"
          x2="237.595"
          y2="258.431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <clipPath id="clip0_11_471">
          <rect width="350" height="312" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_471">
          <rect width="350" height="222" fill="white" />
        </clipPath>
        <clipPath id="clip2_11_471">
          <rect width="129" height="109" fill="white" transform="translate(110.5 202.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
