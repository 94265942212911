import { WeatherCondition } from 'components/WeatherCondition/WeatherCondition'
import React from 'react'
import { colors } from 'settings/colors'
import { EnumWeatherCondition } from 'utils/weather-conditions/types'

const weatherConditions = Object.values(EnumWeatherCondition)

export const WeatherConditionExamples = () => {
  return (
    <div
      css={{
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
      }}
    >
      {weatherConditions.map((weatherCondition) => (
        <div key={weatherCondition} css={{ display: 'flex', gap: 40 }}>
          <div css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: 120 }}>
            <code css={{ fontSize: 14 }}>{weatherCondition}</code>
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 120,
              height: 120,
              fontSize: 40,
              color: colors.midnight,
              backgroundColor: colors.white,
            }}
          >
            <WeatherCondition weatherCondition={weatherCondition} />
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 120,
              height: 120,
              fontSize: 40,
              color: colors.white,
              backgroundColor: colors.midnight,
            }}
          >
            <WeatherCondition weatherCondition={weatherCondition} />
          </div>
        </div>
      ))}
    </div>
  )
}
