import type { Dispatch, SetStateAction } from 'react'
import type { Rule, TAlertRuleKey } from '../../../settings/alertSettings'
import { alertRulesObjectFromArray } from './alertRulesObjectFromArray'

export const updateAlertRules = ({
  rules,
  setRules,
  rulesToChange,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  rulesToChange: Record<string, Partial<Rule> | null>
}) => {
  const rulesObject = alertRulesObjectFromArray(rules)

  Object.keys(rulesToChange).forEach((ruleKey) => {
    // delete rules where it is set to a falsy value like null, (as opposed to an object)
    if (!rulesToChange[ruleKey]) return delete rulesObject[ruleKey]

    // mutate the existing rules for each bit that's changed
    rulesObject[ruleKey] = { ...rulesObject[ruleKey], ...rulesToChange[ruleKey] }
  })

  const rulesArrayToSend = Object.entries(rulesObject).reduce(
    (a: Array<{ key: TAlertRuleKey } & Rule>, [ruleKey, vals]) =>
      a.concat({ ...vals, key: ruleKey as TAlertRuleKey }),
    [],
  )

  setRules(rulesArrayToSend)
}
