import { clearIonicStoreButKeepUserLanguage } from 'utils/clearIonicStoreButKeepUserLanguage'
import { SharedSettings } from '../settings/SharedSettings'
import { redirectRoute } from './redirectRoute'

export const unauthenticateAppState = async () => {
  localStorage.removeItem(SharedSettings.LOCAL_STORAGE_KEYS.AUTH_TOKEN)

  /**
   * We keep the user language so that the public areas (e.g. login, reset password, etc.) will be in the
   * correct language for the user on future visits.
   */
  await clearIonicStoreButKeepUserLanguage()

  redirectRoute('/login')
}
