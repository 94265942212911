import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'

export enum TNodeStatus {
  PLANNED = 'planned',
  ACTIVE = 'active',
  ERROR = 'error',
  OFFLINE = 'offline',
  PENDING = 'pending',
  REMOVED = 'removed',
}

export const ConsumableType = SharedTypes.ConsumableType

export type AnyNode =
  | SharedTypes.TPlannedNode
  | SharedTypes.TPlannedGateway
  | SharedTypes.TActiveNode
  | SharedTypes.TActiveGateway

export type TPlannedNode = SharedTypes.TPlannedNode

export type TPlannedGateway = SharedTypes.TPlannedGateway

export type TActiveNode = SharedTypes.TActiveNode

export type TActiveGateway = SharedTypes.TActiveGateway

export type TDevice = SharedTypes.TDevice

export type TNodeDevice = SharedTypes.TNodeDevice

export type TNodeConsumablesStatus = SharedTypes.TNodeConsumablesStatus

export type TNodeConsumablesParams = SharedTypes.NodeConsumablesRequest & {
  propertyId: number
}

export type TNodeConsumablesStatusGetParams = SharedTypes.GetNodeConsumablesStatusRequest & {
  propertyId: number
}

export type TNodeDevices = SharedTypes.TNodeDevices

export type TNodeDevicePort = SharedTypes.TNodeDevicePort

export const NodeDevicePort = SharedTypes.NodeDevicePort

export type TNodeDeviceChannels = SharedTypes.TNodeDeviceChannels

export type TNodeType = SharedTypes.TNodeType

export type TNodeLog = SharedTypes.TNodeLog

export type TSnNodeLog = SharedTypes.TSnNodeLog

export type TDnNodeLog = SharedTypes.TDnNodeLog

export type TLnNodeLog = SharedTypes.TLnNodeLog

export type TLnSCDLog = SharedTypes.TLnSCDLog

export type TLures = SharedTypes.TLures

export type TDeviceLog = SharedTypes.TDeviceLog

export type TManagementTypes = SharedTypes.ManagementTypes

export const ManagementTypes = SharedTypes.ManagementTypes

export type TNodeDeviceInstallationStatus = SharedTypes.NodeDeviceInstallationStatus

export const NodeDeviceInstallationStatus = SharedTypes.NodeDeviceInstallationStatus

export type TOnboardLog = SharedTypes.TOnboardLog

export enum InstallationStep {
  LOCATION_CONFIRMATION = 'LOCATION_CONFIRMATION',
  OVERVIEW = 'OVERVIEW',
  NODE_ACTIVATION = 'NODE_ACTIVATION',
  DEVICES_INSTALLATION = 'DEVICES_INSTALLATION',
  CHECKLIST = 'CHECKLIST',
  REVIEW = 'REVIEW',
  NODE_CONFIGURATION = 'NODE_CONFIGURATION',
}

export enum HelpGuideSection {
  QA = 'QA',
  ASSEMBLY = 'ASSEMBLY',
  INSTALLATION = 'INSTALLATION',
  MAINTENANCE = 'MAINTENANCE',
}

export type THelpGuides = SharedTypes.THelpGuides

export type THelpGuideStep = SharedTypes.THelpGuideStep

export type TInstallationStepNodeActivation = {
  type: InstallationStep.NODE_ACTIVATION
  title: string
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepDevicesInstallation = {
  type: InstallationStep.DEVICES_INSTALLATION
  title: string
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepLocationConfirmation = {
  type: InstallationStep.LOCATION_CONFIRMATION
  title: string
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepChecklist = {
  type: InstallationStep.CHECKLIST
  title: string
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepNodeConfiguration = {
  type: InstallationStep.NODE_CONFIGURATION
  title: string
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TInstallationStepReview = {
  type: InstallationStep.REVIEW
  title: string
  enabled: boolean
  completed: boolean
  required: boolean
}

export type TEquipmentInstallationStep =
  | TInstallationStepNodeActivation
  | TInstallationStepDevicesInstallation
  | TInstallationStepChecklist
  | TInstallationStepReview
  | TInstallationStepLocationConfirmation
  | TInstallationStepNodeConfiguration

export type TInstallationChecklist = SharedTypes.TInstallationChecklist

export type TNodeServiceIssueSummary = SharedTypes.TNodeServiceIssueSummary

export type TNodeServiceIssueDetail = SharedTypes.TNodeServiceIssueDetail

export type TResolutionType = SharedTypes.TResolutionType

// eslint-disable-next-line no-redeclare
export const TResolutionType = SharedTypes.TResolutionType

export type TServiceIssueStatus = SharedTypes.TServiceIssueStatus

// eslint-disable-next-line no-redeclare
export const TServiceIssueStatus = SharedTypes.TServiceIssueStatus

export type TResolutionActionActual = SharedTypes.TResolutionActionActual

export type TServiceIssueResolutionAction = SharedTypes.TResolutionAction
