import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconSensorConnected = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 16 16')}>
      <path
        d="M3.33333 14C3.14444 14 2.98611 13.9361 2.85833 13.8083C2.73056 13.6806 2.66667 13.5222 2.66667 13.3333V12.6667H2V10C2 9.81111 2.06389 9.65278 2.19167 9.525C2.31944 9.39722 2.47778 9.33333 2.66667 9.33333H3.33333V4.66667C3.33333 3.93333 3.59444 3.30556 4.11667 2.78333C4.63889 2.26111 5.26667 2 6 2C6.73333 2 7.36111 2.26111 7.88333 2.78333C8.40556 3.30556 8.66667 3.93333 8.66667 4.66667V11.3333C8.66667 11.7 8.79722 12.0139 9.05833 12.275C9.31945 12.5361 9.63333 12.6667 10 12.6667C10.3667 12.6667 10.6806 12.5361 10.9417 12.275C11.2028 12.0139 11.3333 11.7 11.3333 11.3333V6.66667H10.6667C10.4778 6.66667 10.3194 6.60278 10.1917 6.475C10.0639 6.34722 10 6.18889 10 6V3.33333H10.6667V2.66667C10.6667 2.47778 10.7306 2.31944 10.8583 2.19167C10.9861 2.06389 11.1444 2 11.3333 2H12.6667C12.8556 2 13.0139 2.06389 13.1417 2.19167C13.2694 2.31944 13.3333 2.47778 13.3333 2.66667V3.33333H14V6C14 6.18889 13.9361 6.34722 13.8083 6.475C13.6806 6.60278 13.5222 6.66667 13.3333 6.66667H12.6667V11.3333C12.6667 12.0667 12.4056 12.6944 11.8833 13.2167C11.3611 13.7389 10.7333 14 10 14C9.26667 14 8.63889 13.7389 8.11667 13.2167C7.59444 12.6944 7.33333 12.0667 7.33333 11.3333V4.66667C7.33333 4.3 7.20278 3.98611 6.94167 3.725C6.68056 3.46389 6.36667 3.33333 6 3.33333C5.63333 3.33333 5.31944 3.46389 5.05833 3.725C4.79722 3.98611 4.66667 4.3 4.66667 4.66667V9.33333H5.33333C5.52222 9.33333 5.68056 9.39722 5.80833 9.525C5.93611 9.65278 6 9.81111 6 10V12.6667H5.33333V13.3333C5.33333 13.5222 5.26944 13.6806 5.14167 13.8083C5.01389 13.9361 4.85556 14 4.66667 14H3.33333Z"
        fill="currentColor"
      />
    </svg>
  )
}
