import { useDebouncedState } from '@mantine/hooks'
import { useCallback, useEffect, useRef } from 'react'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import type { REGION_META, TRegionsValueTypesToPropsDictionary } from '../RegionsValuesCache'

export type TRegionBadgeComponentProps = {
  meta: REGION_META
  valueTypesToPropsDictionary: TRegionsValueTypesToPropsDictionary
}

export const RegionBadgeComponent = (props: TRegionBadgeComponentProps) => {
  const primaryValueGroup = usePrimaryValueGroup()

  const onRegionBadgeClick = useCallback(() => {
    setSelectedFieldAsset({ region: props.meta.regionId })
  }, [])

  const { loadingCurrentValues, valueType } = mapControlsStore.useSelector((s) => ({
    loadingCurrentValues: s.loadingCurrentValues,
    valueType: !primaryValueGroup ? null : s.mapVisualValueGroup.REGION[primaryValueGroup]?.valueType,
  }))

  // TODO: this is a hack, as you probably guessed. It seems that the maps cache has a lag in updating the props. This will
  const [loadingCurrentValuesDebounced, setLoadingCurrentValuesDebounced] = useDebouncedState(
    loadingCurrentValues,
    1,
  )

  useEffect(() => {
    setLoadingCurrentValuesDebounced(loadingCurrentValues)
  }, [loadingCurrentValues])

  const ref = useRef<HTMLDivElement>(null)
  const isSelected = selectedFieldAssetsStore.useSelector((s) => s.region === props.meta.regionId)

  useSetMapOverlayZIndex({
    baseZIndex: props.valueTypesToPropsDictionary?.[String(valueType)]?.baseZIndex,
    ref,
    isSelected,
    mItem: 'M_Region',
  })

  const height = 40

  if (loadingCurrentValues || loadingCurrentValuesDebounced) return null

  return (
    <div
      css={{
        position: 'relative',
        color: 'black',
        backgroundColor: colors.white,
        fontSize: 14,
        fontWeight: 'bold',
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 22,
        border: `2px solid ${(isSelected && 'yellow') || 'white'}`,
        padding: '0 16px',
        flexDirection: 'column',
        top: 0,
        left: 0,
        maxWidth: 220,
      }}
      ref={ref}
      onClick={onRegionBadgeClick}
    >
      <div
        css={{
          marginLeft: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {props.meta.regionName}
        {props.valueTypesToPropsDictionary?.[String(valueType)]?.children ? (
          <div
            css={{
              borderRadius: '50%',
              height: height * 0.9,
              width: height * 0.9,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 13,
              marginRight: -15,
              ...props.valueTypesToPropsDictionary?.[String(valueType)]?.getContainerCSS?.(),
            }}
          >
            {props.valueTypesToPropsDictionary?.[String(valueType)]?.children}
          </div>
        ) : null}
      </div>
    </div>
  )
}
