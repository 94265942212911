import type { NumberInputProps as MantineNumberInputProps } from '@mantine/core'
import { NumberInput as MantineNumberInput } from '@mantine/core'

export type NumberInputProps = Omit<MantineNumberInputProps, 'type'>

export const NumberInput = (props: NumberInputProps) => {
  return (
    <MantineNumberInput
      type="number" // forces input type number at the keyboard level
      {...props}
    />
  )
}
