import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import { generateDefaultHeatmapDangerColors } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { generateUsualStyleGetCacheUpdatesFromResponse } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getValueType } from './_utils/getValueType'

const baseUnitConverter = unitConverter.insectTrapCatches
const blockValueKey = 'value'
const heatmapExtremesForAllPropertiesInVisibleRegions = true

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
  primaryValueGroup: TGetCacheUpdatesFromResponseParameters['primaryValueGroup'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, primaryValueGroup),
    },
    trapPoint: {
      valueType: getValueType(MAP_VISUAL.TRAP, primaryValueGroup),
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, primaryValueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  primaryValueGroup,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches, primaryValueGroup)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  return generateUsualStyleGetCacheUpdatesFromResponse({
    cacheKeys,
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    response,
    block: {
      valueType: getValueType(MAP_VISUAL.BLOCK, primaryValueGroup),
      unitConverterFunction: baseUnitConverter,
      valueKey: blockValueKey,
    },
    trapPoint: {
      valueType: getValueType(MAP_VISUAL.TRAP, primaryValueGroup),
      unitConverterFunction: baseUnitConverter,
    },
    property: {
      valueType: getValueType(MAP_VISUAL.PROPERTY, primaryValueGroup),
      unitConverterFunction: baseUnitConverter,
    },
    heatmapColoring: generateDefaultHeatmapDangerColors(),
  })
}
