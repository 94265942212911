import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { FilterListPopover } from 'components/FilterListPopover/FilterListPopover'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const PropertyTitle = ({
  data,
  filterActiveForProperties,
  filteredProperties,
  setFilteredProperties,
}: {
  data: routes.PheromonePurRecDownload.Response['files']
  filterActiveForProperties: boolean
  filteredProperties: { [propertyId: string]: boolean }
  setFilteredProperties: Dispatch<SetStateAction<{ [propertyId: string]: boolean }>>
}) => {
  const propertiesInData: { [propertyId: string]: boolean } = data.reduce(
    (a: { [propertyId: string]: boolean }, b) => {
      b.propertyIds.forEach((propertyId: number) => {
        a[propertyId] = true
      })

      return a
    },
    {},
  )

  const propertiesList = fieldAssetStore.useSelector((s) => Object.values(s.properties ?? {}) ?? [])
  const properties = propertiesList.filter((property) => !!propertiesInData[property.propertyId])

  return (
    <div css={{ display: 'flex' }}>
      {translate.phrases.banyanApp('Properties')}
      {data && !!data.length && (
        <FilterListPopover
          filterIsActive={filterActiveForProperties}
          optionsGroups={[
            {
              options: properties
                .map(({ propertyName, propertyId }) => ({
                  key: propertyId,
                  title: propertyName,
                  checked: !!filteredProperties[propertyId],
                  onChange: () =>
                    setFilteredProperties({
                      ...filteredProperties,
                      [propertyId]: !filteredProperties[propertyId],
                    }),
                }))
                .sort(sortByKey('title')),
            },
          ]}
          searchPlaceholder={translate.phrases.banyanApp('Search for a property')}
          onClear={() => setFilteredProperties({})}
          width={300}
        />
      )}
    </div>
  )
}
