import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { userDetailsStore } from 'stores/userDetailsStore'

export const useIsServiceCenterActive = () => {
  const hasPermission = userDetailsStore.useSelector(
    (s) => s.permissions?.['EDIT_SERVICE_CENTER']?.['*'] === true,
  )

  const equipmentStatusIsSelected = selectedValueGroupsStore.useSelector(
    (s) => !!s.selectedValueGroups['equipment_status'],
  )

  return hasPermission && equipmentStatusIsSelected
}
