import { closeModal, openModal } from '@mantine/modals'
import { Button } from 'components/Button/Button'
import { IconRefresh } from 'components/icons/IconRefresh'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import React from 'react'
import { ApiRequestQueue, ApiRequestStatus } from '../utils/api/queue'
import { apiRequestQueueStore } from '../utils/api/queue/apiRequestQueueStore'
import { RequestCard } from './RequestCard/RequestCard'

const MODAL_ID = 'queued-requests'

export function openQueuedRequestsModal() {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <QueuedRequestsModal />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export const QueuedRequestsModal = () => {
  const failedRequests = apiRequestQueueStore.useSelector(apiRequestQueueStore.selectors.getFailedRequests)

  const uncompletedRequests = apiRequestQueueStore.useSelector(
    apiRequestQueueStore.selectors.getUncompletedRequests,
  )

  const retryableRequests = uncompletedRequests.filter(
    (request) => request.status === ApiRequestStatus.ERROR || request.status === ApiRequestStatus.PENDING,
  )

  const handleRetryAll = async () => {
    await ApiRequestQueue.process({ force: true })
  }

  const handleRetryRequest = (requestId: string) => {
    ApiRequestQueue.process({ requestId, force: true })
  }

  const handleCancelRequest = (requestId: string) => {
    ApiRequestQueue.cancelRequest(requestId)
  }

  return (
    <>
      <WideHeader
        title={translate.phrases.placeholder('Pending Requests')}
        onClose={() => closeModal(MODAL_ID)}
      />
      <div css={{ padding: 20 }}>
        <Button
          variant="secondary"
          css={{ marginBottom: 10, width: '100%' }}
          disabled={!retryableRequests.length}
          onClick={handleRetryAll}
          leftIcon={<IconRefresh />}
        >
          {translate.phrases.placeholder('Retry All')}
        </Button>

        {[...uncompletedRequests, ...failedRequests].map((request) => (
          <RequestCard
            key={request.id}
            request={request}
            onRetry={() => handleRetryRequest(request.id)}
            onCancel={() => handleCancelRequest(request.id)}
          />
        ))}
      </div>
    </>
  )
}
