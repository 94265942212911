import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { EAggregationInterval } from 'App/Map/types'
import type { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import type { SeriesOptionsType } from 'highcharts'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { FruitGrowthSettings } from './FruitGrowthSettings/FruitGrowthSettings'

const pointCategory: TPointCategory = 'fruitGrowthPoint'
const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_FRUIT_GROWTH_DETAILS' })

export const defaultValuesRequested: Partial<
  Record<
    VV.DomainTypes.FruitGrowth.TTimeseriesValueTypeKeysMerged,
    { preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily> }
  >
> = {
  fruitSize: { preferredAggregationInterval: EAggregationInterval.DAILY },
  fruitSizeTarget: { preferredAggregationInterval: EAggregationInterval.DAILY },
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.fruit_growth || !selectedFieldAssets[pointCategory]) return {}

  const valuesRequested = {
    ...defaultValuesRequested,
    fruitSize: { preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily() },
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.fruitSize().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
      />
    ),
    id: 'stackem-fruit-growth',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { compareSeasonsInterval, dateFrom, dateTo } = detailsPanelStore.getState()
  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = selectedFieldAssets[pointCategory] as TFieldAssetKeyTypes.TLngLat
  const series: SeriesOptionsType[] = []

  const cropId =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points?.[
      stationLngLat as TFieldAssetKeyTypes.TLngLat
    ]?.configuration.fruitDendrometerCropId ?? null

  const {
    targetSize = null,
    targetSizeUnit = null,
    targetDate = null,
  } = data?.points?.[stationLngLat]?.values?.fruitSize?.[0]?.metadata ?? {}

  let outputUnit: 'in' | 'mm' | undefined = undefined

  if (targetSizeUnit) {
    if (targetSizeUnit === 'INCH') outputUnit = 'in'
    else outputUnit = 'mm'
  }

  const firstForecastTimestamp = +new Date()

  const fruitSizeSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.midnight,
    name: unitConverter.fruitSize().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.fruitGrowth(null, { outputUnit }).defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.fruitGrowth(null, { outputUnit }).suffix()}`,
    },
    data: (data?.points?.[stationLngLat]?.values?.fruitSize?.[0]?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.fruitSize(d.value, { outputUnit }).value(),
    ]),
    type: 'line',
    id: 'fruitSize',
  }

  series.push(fruitSizeSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(fruitSizeSeries, {
        data: (compareSeasonsData?.points?.[stationLngLat]?.values?.fruitSize?.[0]?.timeseries ?? []).flatMap(
          (d) => {
            const timestamp = +new Date(d.timestamp)

            let value = unitConverter.fruitSize(d.value, { outputUnit }).value()

            if (+timestamp > firstForecastTimestamp) {
              /**
               * because the fruitSize for forecast data is only once per day,
               * we should filter out our non-daily values for the compare
               * seasons so they are more easily comparable
               */
              if (+moment.tz(+timestamp, timezone).startOf('day') !== +timestamp) {
                return []
              }
            }

            return [[+timestamp, value]]
          },
        ),
      }),
    )
  }

  let yAxisOptions: StackedChartSection['items'][number]['chartConfig']['yAxis'] = { plotLines: [] }
  let xAxisOptions: StackedChartSection['items'][number]['chartConfig']['xAxis'] = { plotLines: [] }

  if (!compareSeasonsInterval) {
    const fruitSizeTargetToIterate =
      data?.points?.[stationLngLat]?.values?.fruitSizeTarget?.[0]?.timeseries ?? []

    if (!!targetDate && fruitSizeTargetToIterate.length) {
      series.push({
        zones: [], // we aim to make this a solid line
        color: colors.green,
        name: unitConverter.fruitSizeTarget().shortTitle(),
        tooltip: {
          valueDecimals: unitConverter.fruitSizeTarget(null, { outputUnit }).defaultNumberOfDecimalPlaces(),
          valueSuffix: ` ${unitConverter.fruitSizeTarget(null, { outputUnit }).suffix()}`,
        },
        data: fruitSizeTargetToIterate.map((d) => [
          +new Date(d.timestamp),
          unitConverter.fruitSizeTarget(d.value, { outputUnit }).value(),
        ]),
        type: 'line',
        id: 'fruitSizeTarget',
      })
    }

    if (!!targetDate) {
      const isTargetInView =
        !isNil(targetDate) &&
        +new Date(dateFrom) <= +new Date(targetDate) &&
        +new Date(targetDate) <= +new Date(dateTo)

      const fruitSizeTargetConvertedValue = unitConverter.fruitSizeTarget(targetSize, { outputUnit }).value()

      yAxisOptions = {
        softMax:
          isTargetInView && !isNil(fruitSizeTargetConvertedValue) ? fruitSizeTargetConvertedValue : undefined,
        softMin:
          isTargetInView && !isNil(fruitSizeTargetConvertedValue) ? fruitSizeTargetConvertedValue : undefined,
        plotLines: [
          {
            color: colors.grey800,
            dashStyle: 'Dash',
            label: {
              style: { fontSize: '14px', color: colors.grey800 },
              text: translate.phrases.banyanApp('Target Size'),
              y: 12,
            },
            value: unitConverter.fruitSizeTarget(targetSize, { outputUnit }).value() ?? undefined,
            width: 2,
          },
        ],
      }

      xAxisOptions = {
        plotLines: [
          {
            color: colors.grey800,
            dashStyle: 'Dash',
            label: {
              style: { fontSize: '14px', color: colors.grey800 },
              text: translate.phrases.templates('{{labelA}} {{labelB}}', {
                labelA: translate.phrases.banyanApp('Target Date'),
                labelB: translate.dates.format(moment.tz(targetDate, timezone), 'MMM D'),
              }),
              x: -15,
              y: 40,
            },
            value: +moment.tz(targetDate, timezone),
            width: 2,
          },
        ],
      }
    }
  }

  const isDaily =
    data?.points?.[stationLngLat]?.values?.fruitSize?.[0]?.metadata?.aggregationInterval === 'DAILY'

  return {
    ...commonReturnItems,
    title: translate.phrases.templates('{{labelA}} - {{labelB}}', {
      labelA: translate.phrases.banyanApp('Fruit Growth'),
      labelB: getCropNameFromId(Number(cropId)),
    }),
    items: [
      {
        chartConfig: {
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
          },
          semiosHighchartsAdditions: {
            id: 'FruitGrowth',
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          yAxis: yAxisOptions,
          xAxis: xAxisOptions,
          series,
        },
        childrenLower: (
          <FruitGrowthSettings
            crop={fieldAssetStore.getState()?.crops?.[Number(cropId)]?.name ?? ''}
            timezone={timezone}
            defaultTargetSize={unitConverter.fruitGrowth(targetSize, { outputUnit }).value()}
            defaultTargetDate={targetDate}
            defaultTargetSizeUnit={targetSizeUnit}
          />
        ),
      },
    ],
  }
}
