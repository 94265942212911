import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconDewPoint = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 12 13')}>
      <path
        d="M8.34656 1.86914C8.34656 1.5918 8.41492 1.33789 8.55164 1.10742C8.68835 0.876953 8.87 0.693359 9.09656 0.556641C9.32312 0.416016 9.57117 0.345703 9.8407 0.345703C10.1102 0.345703 10.3563 0.416016 10.579 0.556641C10.8016 0.693359 10.9794 0.876953 11.1122 1.10742C11.245 1.33789 11.3114 1.5918 11.3114 1.86914C11.3114 2.14648 11.245 2.40039 11.1122 2.63086C10.9794 2.85742 10.8016 3.03906 10.579 3.17578C10.3563 3.30859 10.1102 3.375 9.8407 3.375C9.57117 3.375 9.32312 3.30859 9.09656 3.17578C8.87 3.03906 8.68835 2.85742 8.55164 2.63086C8.41492 2.40039 8.34656 2.14648 8.34656 1.86914ZM9.07312 1.86914C9.07312 2.08398 9.14734 2.26367 9.29578 2.4082C9.44812 2.55273 9.62976 2.625 9.8407 2.625C10.0516 2.625 10.2274 2.55273 10.368 2.4082C10.5126 2.26367 10.5848 2.08398 10.5848 1.86914C10.5848 1.65039 10.5126 1.4668 10.368 1.31836C10.2274 1.16992 10.0516 1.0957 9.8407 1.0957C9.62976 1.0957 9.44812 1.16992 9.29578 1.31836C9.14734 1.4668 9.07312 1.65039 9.07312 1.86914Z"
        fill="currentColor"
      />
      <path
        d="M4.54735 13C3.34179 12.9985 2.18604 12.5189 1.33357 11.6664C0.481108 10.814 0.00152415 9.65822 0 8.45265C0.0271808 7.56621 0.287567 6.70261 0.754911 5.94888L4.11929 0.589121C4.16788 0.520875 4.23209 0.465237 4.30655 0.426845C4.38101 0.388454 4.46357 0.368423 4.54735 0.368423C4.63113 0.368423 4.71369 0.388454 4.78815 0.426845C4.86261 0.465237 4.92682 0.520875 4.97541 0.589121L8.32473 5.92286C8.80002 6.68346 9.06566 7.55624 9.0947 8.45265C9.09318 9.65822 8.61359 10.814 7.76113 11.6664C6.90867 12.5189 5.75291 12.9985 4.54735 13ZM4.54735 1.80761L1.62608 6.46C1.24963 7.05871 1.03733 7.74593 1.01052 8.45265C1.01052 9.39068 1.38315 10.2903 2.04644 10.9536C2.70972 11.6169 3.60933 11.9895 4.54735 11.9895C5.48538 11.9895 6.38498 11.6169 7.04827 10.9536C7.71155 10.2903 8.08418 9.39068 8.08418 8.45265C8.05539 7.73598 7.83787 7.03962 7.45361 6.43398L4.54735 1.80761Z"
        fill="currentColor"
      />
    </svg>
  )
}
