export const alertDeleteQuery = `mutation AlertDelete($id: ID!) {
    deleteAlert(id: $id)
}`

export const alertCreateQuery = `mutation AlertCreate(
    $alertTypeId: ID!
    $blocks: [ID]
    $contacts: [AlertContactInput]!
    $endDate: DateTime
    $frequencyMinutes: Int
    $groups: [AlertGroupInput]!
    $insectId: ID
    $name: String!
    $notifyOwnerByEmail: Boolean
    $notifyOwnerBySMS: Boolean
    $properties: [ID]
    $repeatAnnually: Boolean
    $rules: [AlertRuleInput!]!
    $sleepMinutes: Int
    $startDate: DateTime
    $stations: [ID]
  ) {
    createAlert(
      data: {
        alertRules: $rules
        alertType: $alertTypeId
        blocks: $blocks
        contacts: $contacts
        endDate: $endDate
        frequencyMinutes: $frequencyMinutes
        groups: $groups
        insect: $insectId
        name: $name
        notifyOwnerByEmail: $notifyOwnerByEmail
        notifyOwnerBySMS: $notifyOwnerBySMS
        properties: $properties
        repeatAnnually: $repeatAnnually
        sleepMinutes: $sleepMinutes
        startDate: $startDate
        stations: $stations
      }
    ) {
      id
      name
      type {
        id
      }
      rules {
        id
        key
        value
        operator
        unit
        options
      }
      properties {
        id
      }
      blocks {
        id
      }
      stations {
        geom
      }
      contacts {
        id
        name
        sms
        email
        alertsContactMethods
      }
      groups {
        id
        name
        contacts {
          id
          name
          sms
          email
        }
        alertsContactMethods
      }
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      insect {
        id
      }
      notifyOwnerByEmail
      snoozeUntil
      snoozeAllUntil
      sleepMinutes
      frequencyMinutes
      repeatAnnually
    }
  }`

export const AlertUpdateQuery = `mutation AlertUpdate(
    $alertId: ID!
    $blocks: [ID]
    $contacts: [AlertContactInput]!
    $endDate: DateTime
    $frequencyMinutes: Int
    $groups: [AlertGroupInput]!
    $name: String!
    $notifyOwnerByEmail: Boolean
    $notifyOwnerBySMS: Boolean
    $properties: [ID]
    $repeatAnnually: Boolean
    $rules: [AlertRuleInput!]!
    $sleepMinutes: Int
    $startDate: DateTime
    $stations: [ID]
  ) {
    updateAlert(
      id: $alertId
      data: {
        alertRules: $rules
        blocks: $blocks
        contacts: $contacts
        endDate: $endDate
        frequencyMinutes: $frequencyMinutes
        groups: $groups
        name: $name
        notifyOwnerByEmail: $notifyOwnerByEmail
        notifyOwnerBySMS: $notifyOwnerBySMS
        properties: $properties
        repeatAnnually: $repeatAnnually
        sleepMinutes: $sleepMinutes
        startDate: $startDate
        stations: $stations
      }
    ) {
      id
      name
      type {
        id
      }
      rules {
        id
        key
        value
        operator
        unit
        options
      }
      properties {
        id
      }
      blocks {
        id
      }
      stations {
        geom
      }
      contacts {
        id
        name
        sms
        email
        alertsContactMethods
      }
      groups {
        id
        name
        contacts {
          id
          name
          sms
          email
        }
        alertsContactMethods
      }
      startDate
      endDate
      createdAt
      updatedAt
      deletedAt
      insect {
        id
      }
      notifyOwnerByEmail
      snoozeUntil
      snoozeAllUntil
      sleepMinutes
      frequencyMinutes
      repeatAnnually
    }
  }`
