import { Tooltip } from '@mantine/core'
import { IconMessage } from 'components/icons/IconMessage'
import { translate } from 'i18n/i18n'
import { useIntercom } from 'react-use-intercom'
import { intercomStore } from 'stores/intercomStore'
import { colors } from '../../../settings/colors'

export const UnreadMessagesCountBadge = () => {
  const intercom = useIntercom()
  const unreadMessagesCount = intercomStore.useSelector((s) => s.unreadMessagesCount)

  if (unreadMessagesCount === 0) return null

  return (
    <Tooltip
      label={
        unreadMessagesCount > 1
          ? translate.phrases.placeholder('{{unreadMessagesCount}} messages', { unreadMessagesCount })
          : translate.phrases.placeholder('{{unreadMessagesCount}} message', { unreadMessagesCount })
      }
    >
      <span style={{ color: '#29CD6B', fontSize: 24, position: 'relative' }} onClick={() => intercom.show()}>
        <IconMessage />
        <span
          css={{ position: 'absolute', left: 8, top: 0, color: colors.white, fontSize: 14, fontWeight: 500 }}
        >
          {unreadMessagesCount ?? ''}
        </span>
      </span>
    </Tooltip>
  )
}
