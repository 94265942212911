import type { TSemiosCustomHighchartsDateFormats } from 'components/HighchartsChart/_utils/setCustomHighchartsDateFormats'
import { detailsPanelStore } from 'stores/detailsPanelStore'

export const getXDateFormat = (daily = true): TSemiosCustomHighchartsDateFormats => {
  const hasCompareSeasons = !!detailsPanelStore.getState().compareSeasonsInterval

  if (daily) {
    if (hasCompareSeasons) return '%C'

    return '%D'
  }

  if (hasCompareSeasons) return '%X'

  return '%Z'
}
