import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconControlFuel = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 14 15')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 15"
      fill="currentColor"
    >
      <path
        d="M13.1417 3.525L13.15 3.51667L10.05 0.416667L9.16667 1.3L10.925 3.05833C10.1417 3.35833 9.58333 4.10833 9.58333 5C9.58333 6.15 10.5167 7.08333 11.6667 7.08333C11.9667 7.08333 12.2417 7.01667 12.5 6.90833V12.9167C12.5 13.375 12.125 13.75 11.6667 13.75C11.2083 13.75 10.8333 13.375 10.8333 12.9167V9.16667C10.8333 8.25 10.0833 7.5 9.16667 7.5H8.33333V1.66667C8.33333 0.75 7.58333 0 6.66667 0H1.66667C0.75 0 0 0.75 0 1.66667V15H8.33333V8.75H9.58333V12.9167C9.58333 14.0667 10.5167 15 11.6667 15C12.8167 15 13.75 14.0667 13.75 12.9167V5C13.75 4.425 13.5167 3.9 13.1417 3.525ZM6.66667 5.83333H1.66667V1.66667H6.66667V5.83333ZM11.6667 5.83333C11.2083 5.83333 10.8333 5.45833 10.8333 5C10.8333 4.54167 11.2083 4.16667 11.6667 4.16667C12.125 4.16667 12.5 4.54167 12.5 5C12.5 5.45833 12.125 5.83333 11.6667 5.83333Z"
        fill="#464B53"
      />
      <rect x="1.83301" y="6.10938" width="5.16648" height="6.88864" fill="#464B53" />
    </svg>
  )
}
