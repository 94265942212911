import { persistentInit } from './_utils/persistentStore/persistentStore'

export const layersNavigationMenuStore = persistentInit<{
  layersSubsectionCollapsed: boolean
  menuCollapsed: boolean
  openLayersSubsectionKeys: Record<string, boolean>
}>({
  initialState: {
    layersSubsectionCollapsed: true,
    menuCollapsed: true,
    openLayersSubsectionKeys: {},
  },
  keysToPutInPersistentStorage: {
    layersSubsectionCollapsed: true,
    menuCollapsed: true,
    openLayersSubsectionKeys: true,
  },
  keysToPutInURL: {
    layersSubsectionCollapsed: true,
    menuCollapsed: true,
    openLayersSubsectionKeys: false,
  },
  storeName: 'layersNavigationMenuStore',
})
