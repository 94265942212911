import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'

export const EditButton = ({ openModal }: { openModal: () => void }) => {
  return (
    <Button onClick={openModal} leftIcon={<FontAwesomeIcon icon={faPen} />} variant="link">
      {translate.phrases.banyanApp('Edit')}
    </Button>
  )
}
