import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { useMemo, useState } from 'react'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import type { TTimeseries } from '../TrapBadgeComponent'
import { useFetchFullTimeSeriesAndAddToState } from './useFetchFullTimeSeriesAndAddToState'
import { useFetchMostRecentImageAndAddToState } from './useFetchMostRecentImageAndAddToState'

export const useTrapImageData = ({
  insectId,
  lngLat,
  preventFetch,
  timezone,
}: {
  insectId?: TFieldAssetKeyTypes.TInsectId
  lngLat: TFieldAssetKeyTypes.TLngLat
  preventFetch: boolean
  timezone: string
}): {
  datePickerLoading: boolean
  datesWithImages: Record<string, TTimeseries>
  datesWithTrapCatches: Record<string, TTimeseries>
  fullLoading: boolean
  maxDate: string | null
  minDate: string | null
  prettyDateString: string
  selectedDate: string
  setSelectedDate: Dispatch<SetStateAction<string>>
} => {
  const [selectedDate, setSelectedDate] = useState<string>('')

  const [timeseriesTrapCatchDataObject, setTimeseriesTrapCatchDataObject] = useState<
    Record<string, TTimeseries>
  >({} as Record<string, TTimeseries>)

  const { mostRecentImageLoading } = useFetchMostRecentImageAndAddToState({
    insectId,
    lngLat,
    preventFetch,
    setSelectedDate,
    setTimeseriesTrapCatchDataObject,
    timezone,
  })

  const { timeseriesTrapCatchLoading } = useFetchFullTimeSeriesAndAddToState({
    insectId,
    lngLat,
    preventFetch,
    setTimeseriesTrapCatchDataObject,
    setSelectedDate,
    timezone,
  })

  const { datesWithImages, datesWithTrapCatches, maxDate, minDate } = useMemo((): Pick<
    ReturnType<typeof useTrapImageData>,
    'datesWithImages' | 'datesWithTrapCatches' | 'maxDate' | 'minDate'
  > => {
    const datesWithImages: Record<string, TTimeseries> = {}
    const datesWithTrapCatches: Record<string, TTimeseries> = {}

    Object.values(timeseriesTrapCatchDataObject).forEach((ts) => {
      if (ts.imageHash) {
        datesWithImages[ts.timestamp] = ts
      }

      if (Number(ts.value) > 0) {
        datesWithTrapCatches[ts.timestamp] = ts
      }
    })

    const sortedKeys = Object.keys(datesWithImages).sort(alphabeticalSort)
    const maxDate = sortedKeys[sortedKeys.length - 1] ?? null
    const minDate = sortedKeys[0] ?? null

    return {
      datesWithImages,
      datesWithTrapCatches,
      maxDate,
      minDate,
    }
  }, [timeseriesTrapCatchLoading, mostRecentImageLoading])

  const prettyDateString = !datesWithImages?.[selectedDate]?.imageTakenOn
    ? ''
    : translate.dates.format(
        moment.tz(datesWithImages?.[selectedDate]?.imageTakenOn, timezone),
        'MMM D, YYYY h:mm a (z)',
      )

  return {
    datePickerLoading: timeseriesTrapCatchLoading,
    datesWithImages,
    datesWithTrapCatches,
    fullLoading: timeseriesTrapCatchLoading && mostRecentImageLoading,
    maxDate,
    minDate,
    prettyDateString,
    selectedDate: selectedDate,
    setSelectedDate,
  }
}
