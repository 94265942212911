import { Switch } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapSelectedCardStore } from 'stores/mapSelectedCardStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { toggleValueGroups } from 'stores/selectedValueGroupsStore/toggleValueGroups'

export const NdviSwitch = () => {
  const isChecked = selectedValueGroupsStore.useSelector((s) => !!s.selectedValueGroups.ndvi)

  return (
    <Switch
      label={translate.phrases.banyanApp('Vegetation')}
      checked={isChecked}
      onChange={(event) => {
        if (event.target.checked) {
          toggleValueGroups(['ndvi'], true)
        } else {
          toggleValueGroups(['ndvi'], false)
        }

        mapSelectedCardStore.setState((s) => {
          return {
            ...s,
            metaNdvi: event.currentTarget.checked ? s.metaNdvi : undefined,
          }
        })
      }}
      size="xs"
      styles={{
        label: {
          color: HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD ? colors.midnight : colors.white,
          cursor: 'pointer',
          fontSize: 13,
          paddingLeft: '0.5rem',
        },
        track: {
          cursor: 'pointer',
          backgroundColor: isChecked ? `${colors.green} !important` : undefined,
          borderColor: isChecked ? `${colors.green} !important` : undefined,
        },
      }}
    />
  )
}
