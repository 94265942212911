import type { TTooltipContentsHelperTag } from './helperTagsMaker'
import { helperTagsMaker } from './helperTagsMaker'

type TUpdateTooltipContentsFunction = (o: { content: string }) => string

export const updateTooltipContents = ({
  seriesId,
  tooltipContents,
  fieldsToChange,
}: {
  seriesId: string
  tooltipContents: string
  fieldsToChange: Partial<Record<TTooltipContentsHelperTag, TUpdateTooltipContentsFunction>>
}): string => {
  const helperTags = helperTagsMaker({ seriesId })

  let returner = tooltipContents

  Object.entries(fieldsToChange).forEach(([key, func]) => {
    const helperTagsForThisKey = helperTags[key as TTooltipContentsHelperTag]

    if (helperTagsForThisKey) {
      const content = ((tooltipContents || '').split(helperTagsForThisKey.opening)[1] ?? '').split(
        helperTagsForThisKey.closing,
      )[0]

      const updatedContentToInjectBackIn = func({ content })

      returner = returner.replace(
        `${helperTagsForThisKey.opening}${content}${helperTagsForThisKey.closing}`,
        `${helperTagsForThisKey.opening}${updatedContentToInjectBackIn}${helperTagsForThisKey.closing}`,
      )
    }
  })

  return returner
}
