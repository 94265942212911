import type { MantineNumberSize } from '@mantine/core'
import { Modal } from '@mantine/core'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'

export const ConfirmationModal = ({
  title,
  content,
  modalWindowSize,
  buttonContent,
  modalOpened,
  closeModal,
  zIndex = SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 1,
}: {
  title: string
  content: React.ReactNode | string
  modalWindowSize?: MantineNumberSize
  buttonContent: React.ReactNode
  modalOpened: boolean
  closeModal: () => void
  zIndex?: number
}) => {
  return (
    <>
      <Modal.Root zIndex={zIndex} opened={modalOpened} onClose={closeModal} size={modalWindowSize}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header
            style={{ justifyContent: 'start', alignContent: 'center', height: '3em', paddingLeft: 5 }}
          >
            <Modal.CloseButton style={{ marginLeft: 0, marginRight: 5, paddingBottom: 3 }} />
            <Modal.Title>
              <div>
                <h4>{title}</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ color: colors.midnight, margin: 0, padding: 0 }}>
            <div style={{ margin: 10 }}>{content}</div>
            <div
              style={{
                backgroundColor: colors.grey50,
                padding: 10,
                borderTop: `1px solid ${colors.grey200}`,
              }}
            >
              {buttonContent}
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  )
}
