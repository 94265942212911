import type { TAvailablePreset } from 'App/Map/types'
import { SettingsEditButton } from 'App/Map/UserSettingsMenu/components/SettingsEditButton/SettingsEditButton'

export const EditPresetButton = ({
  editPreset,
  preset,
}: {
  editPreset: (a: TAvailablePreset) => void
  preset: TAvailablePreset
}) => {
  return (
    <SettingsEditButton
      onClick={() => {
        editPreset(preset)
      }}
    />
  )
}
