import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconHumidity = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 176 262')}>
      <g clipPath="url(#clip0_18_899)">
        <g clipPath="url(#clip1_18_899)">
          <path
            d="M87.9004 13.5801C39.2004 85.5801 7.90039 130.58 7.90039 174.28C7.90039 217.98 43.7004 253.58 87.9004 253.58C132.1 253.58 167.9 218.08 167.9 174.28C167.9 130.48 136.6 85.4801 87.9004 13.5801Z"
            stroke="currentColor"
            strokeWidth="15"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M50.8 127.5C54 124.5 58.5667 123 64.5 123C70.4333 123 74.9667 124.5 78.1 127.5C81.3 130.433 82.9 134.567 82.9 139.9V147.9C82.9 153.1 81.3 157.167 78.1 160.1C74.9 163.033 70.3667 164.5 64.5 164.5C58.5 164.5 53.9333 163.033 50.8 160.1C47.6667 157.167 46.0667 153.1 46 147.9V139.9C46 134.567 47.6 130.433 50.8 127.5ZM122 125.9C122.124 126.349 122.134 126.822 122.03 127.276C121.925 127.73 121.708 128.151 121.4 128.5L68.4 201.8C67.6761 202.922 66.7216 203.876 65.6 204.6C64.1186 205.09 62.5575 205.294 61 205.2H56.6C55.3 205.2 54.5 204.8 54.1 204.1C53.9515 203.614 53.9381 203.096 54.0614 202.603C54.1848 202.11 54.4401 201.659 54.8 201.3L107.8 128C108.405 126.88 109.304 125.947 110.4 125.3C111.826 124.877 113.315 124.708 114.8 124.8H119.7C120.9 124.8 121.7 125.2 122 125.9ZM64.5 133.5C59.3667 133.5 56.8 135.833 56.8 140.5V147.2C56.8 151.867 59.3667 154.2 64.5 154.2C69.6333 154.2 72.2 151.867 72.2 147.2V140.4C72.2 135.8 69.6333 133.5 64.5 133.5ZM97.9 169.9C101.033 166.9 105.6 165.4 111.6 165.4C117.6 165.4 122.133 166.9 125.2 169.9C128.4 172.9 130 177.033 130 182.3V190.3C130 195.5 128.4 199.567 125.2 202.5C122 205.433 117.433 206.933 111.5 207C105.567 207 101.033 205.533 97.9 202.6C94.7667 199.667 93.1667 195.567 93.1 190.3V182.3C93.1 176.967 94.7 172.833 97.9 169.9ZM111.5 176C106.433 176 103.9 178.333 103.9 183V189.6C103.9 194.267 106.433 196.6 111.5 196.6C116.567 196.6 119.133 194.267 119.2 189.6V182.9C119.2 178.3 116.633 176 111.5 176Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_899">
          <rect width="176" height="262" fill="white" />
        </clipPath>
        <clipPath id="clip1_18_899">
          <rect width="175" height="260.9" fill="white" transform="translate(0.400391 0.179688)" />
        </clipPath>
      </defs>
    </svg>
  )
}
