import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type { StackedChartSection } from 'components/StackedChart/types'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { plantWaterStress } from './PlantWaterStress/plantWaterStress'
import { trunkDisplacement } from './TrunkDisplacement/trunkDisplacement'

export const pointCategory: TPointCategory = 'plantStressPoint'

export const checkPermission = () =>
  selectedPropertyHasPermission({ permission: 'VIEW_PLANT_STRESS_DETAILS' })

const preferredAggregationInterval = {
  preferredAggregationInterval: EAggregationInterval.DAILY,
}

export const defaultValuesRequested: Partial<
  Record<
    VV.DomainTypes.PlantStress.TTimeseriesValueTypeKeysMerged,
    { preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily> }
  >
> = {
  trunkDisplacement: preferredAggregationInterval,
  signalTS5: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.plant_stress || !selectedFieldAssets[pointCategory]) return {}

  const valuesRequested = {
    ...defaultValuesRequested,
    trunkDisplacement: { preferredAggregationInterval: chooseAmongstUnAggHourlyAndDaily() },
  }

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
  updateData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  updateData: (pathToSet: string, dataToSet: unknown) => void
}): StackedChartSection[] => {
  const plantStressCharts = []

  plantStressCharts.push(plantWaterStress({ data, compareSeasonsData, updateData }))

  plantStressCharts.push(trunkDisplacement({ data, compareSeasonsData }))

  return plantStressCharts
}
