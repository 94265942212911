import { translate } from 'i18n/i18n'
import { useIntercom } from 'react-use-intercom'
import { UnreadMessagesCountBadge } from '../../../App/ServiceCenter/UnreadMessagesCountBadge/UnreadMessagesCountBadge'
import { IconHelp } from '../../icons/IconHelp'
import { IconAndTitle } from '../IconAndTitle/IconAndTitle'

export const HelpCenter = () => {
  const intercom = useIntercom()

  return (
    <IconAndTitle
      icon={<IconHelp />}
      onClick={() => intercom.show()}
      right={
        <span style={{ position: 'relative', top: 4 }}>
          <UnreadMessagesCountBadge />
        </span>
      }
      title={translate.phrases.banyanApp('Help')}
    />
  )
}
