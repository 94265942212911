import { translate } from 'i18n/i18n'

export const AlertFrequencyWording = ({ frequencyMinutes }: { frequencyMinutes: number | null }) => {
  if (!frequencyMinutes) return null

  switch (frequencyMinutes) {
    case 10:
      return <translate.Phrases.banyanApp k="Real-time (every 10 minutes)" />

    case 30:
      return <translate.Phrases.banyanApp k="30-min summary" />

    case 60:
      return <translate.Phrases.banyanApp k="1-hour summary" />

    case 180:
      return <translate.Phrases.banyanApp k="3-hour summary" />

    case 360:
      return <translate.Phrases.banyanApp k="6-hour summary" />

    case 1440:
      return <translate.Phrases.banyanApp k="Every day" />

    default:
      return <translate.Phrases.banyanApp k="Every {{minutes}} minutes" v={{ frequencyMinutes }} />
  }
}
