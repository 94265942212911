import { Tooltip } from '@mantine/core'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { IconInfo } from 'components/icons/IconInfo'
import { colors } from 'settings/colors'
import type { TAdminPermission } from '../../utils/useAdminEntites'

const rowWrapperStyle = {
  'alignItems': 'center',
  'border': `1px solid ${colors.grey200}`,
  'display': 'flex',
  'padding': '4px 10px',
  'transition': '0.15s ease all',
  '&:hover': {
    background: colors.subtleBackground,
  },
}

export const PermissionRow = ({
  permission,
  selected,
  setSelected,
  hasEditPermission,
}: {
  permission: TAdminPermission
  selected: boolean
  setSelected: (selected: boolean) => void
  hasEditPermission: boolean
}) => {
  return (
    <div
      css={{
        ...rowWrapperStyle,
        cursor: 'pointer',
        borderTop: undefined,
      }}
      onClick={() => {
        setSelected(!selected)
      }}
    >
      <>
        <Checkbox defaultChecked={selected} disabled={!hasEditPermission} css={{ marginRight: 12 }} />
        <div css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Tooltip label={permission.description} withArrow position={'right'}>
            <span css={{ cursor: 'help', paddingRight: '5px' }}>
              <IconInfo />
            </span>
          </Tooltip>
          {permission.id}
        </div>
      </>
    </div>
  )
}
