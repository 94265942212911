import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import type { FC } from 'react'
import { colors } from 'settings/colors'
import { SettingsCollapsedItems } from '../../components/SettingsCollapsedItems/SettingsCollapsedItems'
import { AcceptancePackageLinks } from '../../Shared/AcceptancePackageLinks/AcceptancePackageLinks'
import type { TSettingsMenuSidePanelProps } from '../../types'
import { SettingItem } from './SettingItem'

const linksHeight = 30

export const SettingsMenuSidePanel: FC<TSettingsMenuSidePanelProps> = ({
  currentTab,
  setCurrentTab,
  searchText,
  setSearchText,
  filteredSettingItems,
}) => {
  let wideScreenCurrentTab = currentTab

  if (wideScreenCurrentTab === 'mainMenu') wideScreenCurrentTab = 'myDetails' // Set my details as default page on widescreen

  return (
    <div
      css={{
        height: '92vh',
        width: 400,
        borderRight: `1px solid ${colors.grey200}`,
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div css={{ padding: 30 }}>
        <SearchTextInput searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div css={{ flex: 1, marginBottom: linksHeight + 20, overflowY: 'auto' }}>
        {filteredSettingItems?.map((section, index) => {
          const { items, sectionLabel } = section
          const isSubMenu = items.length > 1

          return isSubMenu ? (
            <SettingsCollapsedItems sectionLabel={sectionLabel} key={index}>
              {items.map((item) => (
                <SettingItem
                  key={item.tabKey}
                  tabKey={item.tabKey}
                  setCurrentTab={setCurrentTab}
                  isSelected={item.tabKey === wideScreenCurrentTab}
                  label={item.label}
                  isSubItem={true}
                />
              ))}
            </SettingsCollapsedItems>
          ) : (
            <SettingItem
              key={items[0].label}
              tabKey={items[0].tabKey}
              setCurrentTab={setCurrentTab}
              isSelected={items[0].tabKey === wideScreenCurrentTab}
              label={items[0].label}
            />
          )
        })}
      </div>
      <div css={{ height: linksHeight }}>
        <AcceptancePackageLinks />
      </div>
    </div>
  )
}
