import { groupTooltipRowsIntoColumns } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/soil/_utils/groupTooltipRowsIntoColumns'
import { isNil } from 'lodash'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { alphaColorReplacer } from 'settings/colors'
import type { TTooltipFormatterContextObject } from '../../types'
import { getFixedValueOrUnknown } from './getFixedValueOrUnknown'
import { minAverageMaxCellMaker } from './minAverageMaxCellMaker'

export const buildTooltipDualColumnValueRowsForTooltip = (points: TTooltipFormatterContextObject[]) => {
  let template = ''

  groupTooltipRowsIntoColumns(points).forEach(([val1, val2]) => {
    const val1Point = val1?.point
    const val2Point = val2?.point
    const val1UserOptions = val1?.series.userOptions
    const val2UserOptions = val2?.series.userOptions
    const val1ValueDecimals = val1UserOptions?.tooltip?.valueDecimals ?? 0
    const val2ValueDecimals = val2UserOptions?.tooltip?.valueDecimals ?? 0

    const val1ValueSuffix =
      (!isNil(val1Point?.y) || !isNil(val1Point?.compareSeasons?.y)) && val1UserOptions?.tooltip?.valueSuffix
        ? val1UserOptions.tooltip.valueSuffix
        : ''

    const val2ValueSuffix =
      (!isNil(val2Point?.y) || !isNil(val2Point?.compareSeasons?.y)) && val2UserOptions?.tooltip?.valueSuffix
        ? val2UserOptions.tooltip.valueSuffix
        : ''

    const val1CompareSeasons = val1Point?.compareSeasons?.year
      ? {
          year: val1Point.compareSeasons.year,
          value: getFixedValueOrUnknown(val1Point.compareSeasons.y, val1ValueDecimals),
          low:
            val1Point.compareSeasons?.point?.low !== undefined
              ? getFixedValueOrUnknown(val1Point.compareSeasons.point.low, val1ValueDecimals)
              : null,
          high:
            val1Point.compareSeasons?.point?.high !== undefined
              ? getFixedValueOrUnknown(val1Point.compareSeasons.point.high, val1ValueDecimals)
              : null,
          seriesColor:
            val1UserOptions?.compareSeasonsColor ||
            alphaColorReplacer(val1UserOptions.color as TRGBAColorWith1AtTheEnd, 0.5),
        }
      : undefined

    const val2CompareSeasons = val2Point?.compareSeasons?.year
      ? {
          year: val2Point.compareSeasons.year,
          value: getFixedValueOrUnknown(val2Point.compareSeasons.y, val2ValueDecimals),
          low:
            val2Point.compareSeasons?.point?.low !== undefined
              ? getFixedValueOrUnknown(val2Point.compareSeasons.point.low, val2ValueDecimals)
              : null,
          high:
            val2Point.compareSeasons?.point?.high !== undefined
              ? getFixedValueOrUnknown(val2Point.compareSeasons.point.high, val2ValueDecimals)
              : null,
          seriesColor:
            val2UserOptions?.compareSeasonsColor ||
            alphaColorReplacer(val2UserOptions.color as TRGBAColorWith1AtTheEnd, 0.5),
        }
      : undefined

    const leftColumnValueCell =
      val1 && val1UserOptions?.hideFromTooltip !== true
        ? minAverageMaxCellMaker({
            seriesId: String(val1UserOptions?.id),
            seriesColor: val1UserOptions.color as string,
            seriesName: val1UserOptions.name ?? '',
            low:
              val1Point?.low !== undefined ? getFixedValueOrUnknown(val1Point.low, val1ValueDecimals) : null,
            high:
              val1Point?.high !== undefined
                ? getFixedValueOrUnknown(val1Point.high, val1ValueDecimals)
                : null,
            value: getFixedValueOrUnknown(val1Point?.y, val1ValueDecimals),
            paddingRight: true,
            xYear: `${new Date(val1Point?.x).getFullYear()}`,
            valueSuffix: val1ValueSuffix,
            dashed: val1UserOptions?.tooltip?.dashed,
            compareSeasons: val1CompareSeasons,
          })
        : ''

    const rightColumnValueCell =
      val2 && val2UserOptions?.hideFromTooltip !== true
        ? minAverageMaxCellMaker({
            seriesId: String(val2UserOptions?.id),
            seriesColor: val2UserOptions.color as string,
            seriesName: val2UserOptions.name ?? '',
            low:
              val2Point?.low !== undefined ? getFixedValueOrUnknown(val2Point.low, val2ValueDecimals) : null,
            high:
              val2Point?.high !== undefined
                ? getFixedValueOrUnknown(val2Point.high, val2ValueDecimals)
                : null,
            value: getFixedValueOrUnknown(val2Point?.y, val2ValueDecimals),
            xYear: `${new Date(val2Point?.x).getFullYear()}`,
            valueSuffix: val2ValueSuffix,
            dashed: val2UserOptions?.tooltip?.dashed,
            compareSeasons: val2CompareSeasons,
          })
        : ''

    template += `<tr>${leftColumnValueCell}${rightColumnValueCell}</tr>`
  })

  return template
}
