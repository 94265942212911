import type { CSSObject } from '@emotion/css'
import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import type { Dispatch, FC, SetStateAction } from 'react'
import React from 'react'
import { alphaColorReplacer, colors } from 'settings/colors'
import { useViewportSizeWithCorrectInitialState } from 'utils/useViewportSizeWithCorrectInitialState'
import { IconMapControls } from '../icons/IconMapControls'

interface WideScreenPanelProps {
  children?: React.ReactNode
  expandedContent?: React.ReactNode
  isExpanded: boolean
  setIsExpanded: Dispatch<SetStateAction<boolean>>
  style?: React.CSSProperties | CSSObject
}

export const WideScreenPanel: FC<WideScreenPanelProps> = ({
  children,
  expandedContent,
  isExpanded,
  setIsExpanded,
  style: panelStyle,
}) => {
  const width = 310
  const viewportSize = useViewportSizeWithCorrectInitialState()
  const y1 = 10
  const maxHeight = viewportSize.height - y1 - 10
  const maxWidth = 410

  return (
    <div
      css={{
        height: 'fit-content',
        position: 'fixed',
        bottom: 'env(safe-area-inset-bottom)',
        ...panelStyle,
      }}
    >
      {isExpanded && (
        <div
          css={{
            display: 'flex',
            backgroundColor: colors.midnight,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            height: 30,
          }}
        >
          <div
            css={{
              color: 'white',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: 13,
              fontSize: 14,
            }}
            onClick={() => setIsExpanded((s) => !s)}
          >
            <ExpandableChevronIndicator expanded={isExpanded} />
          </div>
        </div>
      )}
      <div
        css={{
          width: isExpanded ? width : 'auto',
          maxHeight,
          maxWidth,
          backgroundColor: alphaColorReplacer(colors.midnight, 0.65),
          borderRadius: 3,
          overflow: 'visible',
          position: 'relative',
          padding: 14,
        }}
      >
        <div css={{ minWidth: 0 }}>
          {!isExpanded ? (
            <div
              css={{
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 'auto',
                fontSize: 14,
              }}
              onClick={() => setIsExpanded((s) => !s)}
            >
              <div css={{ fontSize: 15 }}>
                <IconMapControls />
              </div>
              <div css={{ marginLeft: 12, fontSize: 14 }}>
                <ExpandableChevronIndicator expanded={isExpanded} expandDirection="expand-rightwards" />
              </div>
            </div>
          ) : (
            <>
              {children}
              {expandedContent}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
