import { persistentInit } from './_utils/persistentStore/persistentStore'

const storeName = 'intercomStore'

export type TIntercomStore = {
  unreadMessagesCount: number
}

export const intercomStore = persistentInit<TIntercomStore>({
  initialState: { unreadMessagesCount: 0 },
  keysToPutInPersistentStorage: { unreadMessagesCount: true },
  keysToPutInURL: { unreadMessagesCount: false },
  storeName: storeName,
})
