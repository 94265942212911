import type { TPermissionEntityType } from '@semios/app-platform-banyan-route-definitions/src/shared-types/admin'
import { colors } from 'settings/colors'

export const EntityPicker = ({
  entityItems,
  currentEntityType,
  setEntityType,
}: {
  entityItems: { value: TPermissionEntityType; label: string }[]
  currentEntityType: TPermissionEntityType
  setEntityType: (entityType: TPermissionEntityType) => void
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${colors.grey200}`,
        marginRight: 5,
      }}
    >
      {entityItems.map((entity) => (
        <div
          key={entity.value}
          css={{
            'cursor': 'pointer',
            'height': 45,
            'display': 'flex',
            'alignItems': 'center',
            'paddingRight': '10px',
            'paddingLeft': '10px',
            'borderRadius': '3px 0 0 3px',
            '&:hover': {
              backgroundColor: colors.grey50,
            },
            'backgroundColor': entity.value === currentEntityType ? colors.grey50 : colors.white,
            'color': colors.midnight,
            'fontSize': 16,
            'fontWeight': entity.value === currentEntityType ? 700 : 500,
          }}
          onClick={() => {
            setEntityType(entity.value)
          }}
        >
          {entity.label}
        </div>
      ))}
    </div>
  )
}
