import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon } from '@mantine/core'
import { MapContext } from 'App/Map/MapContext/MapContext'
import React, { useContext, useEffect } from 'react'
import { colors } from 'settings/colors'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { networkStore } from '../store/networkStore'
import { loadMapData, loadStartupData } from '../utils/loadStartupData'

export const ReloadMapButton = () => {
  const { setLoadingData, loadingData } = useContext(MapContext)
  const selectedPropertyIds = selectedFieldAssetsStore.useSelector((s) => (s.property ? [s.property] : []))
  const connected = networkStore.useSelector((s) => s.connectionStatus.connected)

  useEffect(() => {
    // prevent icon from stuck in spinning when connection is lost while downloading data
    if (!connected) setLoadingData(false)
  }, [connected])

  const handleClick = async () => {
    if (!connected) return

    setLoadingData(true)

    await Promise.all([selectedPropertyIds.length && loadMapData(selectedPropertyIds), loadStartupData()])

    setLoadingData(false)
  }

  return (
    <ActionIcon
      loading={loadingData}
      onClick={handleClick}
      size="xl"
      radius="xl"
      variant="outline"
      style={{
        color: !connected ? colors.gray : colors.primary,
        backgroundColor: colors.grey50,
      }}
      disabled={!connected}
    >
      <FontAwesomeIcon icon={faRotateRight} />
    </ActionIcon>
  )
}
