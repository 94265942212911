import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { useEffect, useState } from 'react'

export const useKeyboardVisible = () => {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardWillShow', () => setKeyboardVisible(true))

      Keyboard.addListener('keyboardWillHide', () => setKeyboardVisible(false))

      return () => {
        Keyboard.removeAllListeners()
      }
    }
  }, [])

  return { isKeyboardVisible }
}
