export type UUID = {
  readonly service: string
  readonly characteristic: string
}

export type ScanOptions = {
  nodeType?: string
  nodeId?: string
}

export enum AdapterStatus {
  DISABLED,
  IDLE,
  SCANNING,
  CONNECTING,
  CONNECTED,
}

export class DTO {}

export type DtoConstructor = new () => DTO

export interface Decoder<T extends DTO> extends Convertor<T> {
  decode(data: DataView): T | null
  notifiable?: boolean
}

export interface Encoder<T extends DTO> extends Convertor<T> {
  encode(data: T): DataView
}

export interface Convertor<T extends DTO> {
  readonly uuid: UUID
  type: new () => T
}
