export const getMoveDirection = (
  point: number,
  startingPoint: number,
): 'UP_OR_LEFT' | 'DOWN_OR_RIGHT' | undefined => {
  if (point < startingPoint) return 'UP_OR_LEFT'

  if (point > startingPoint) return 'DOWN_OR_RIGHT'

  return undefined
}
