import { css } from '@emotion/css'
import { createStyles, LoadingOverlay, PasswordInput, Stack, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { Button } from 'components/Button/Button'
import type { FC, ReactNode } from 'react'
import { useScreenSize } from 'utils/useScreenSize'
import type { z } from 'zod'

export type InputPropsType = {
  placeholder: string
  key: string
  initialValue: string
  isPasswordInput?: boolean
}

export type AuthFormProps = {
  inputProps: InputPropsType[]
  validationSchema: z.Schema
  buttonText: string
  buttonRightIcon?: ReactNode
  onSubmit: (values: { [k: string]: string }) => void
  submitLoading?: boolean
  header?: ReactNode
  buttonVariant?: 'primary' | 'secondary' | 'tertiary'
}

const useStyles = createStyles((theme) => ({
  invalid: {
    borderColor: theme.colors.red[0],
  },
}))

export const AuthenticationFormWithoutSemiosLogo: FC<AuthFormProps> = ({
  inputProps,
  validationSchema,
  buttonText,
  buttonRightIcon,
  onSubmit,
  submitLoading = false,
  buttonVariant = 'primary',
  header,
}) => {
  const { classes } = useStyles()
  const { isWideScreen } = useScreenSize()

  const form = useForm({
    initialValues: Object.fromEntries(inputProps.map((input) => [input.key, input.initialValue])),

    validate: zodResolver(validationSchema),

    validateInputOnBlur: true,
  })

  return (
    <div
      className={css`
        display: flex;
      `}
    >
      <LoadingOverlay visible={submitLoading} />

      <div
        className={css`
          width: 90%;
        `}
      >
        {header}
        <form
          onSubmit={form.onSubmit((values) => onSubmit(values))}
          className={css`
            margin-top: 20px;
          `}
        >
          <Stack>
            {inputProps.map((input) => {
              const { placeholder, key, isPasswordInput } = input
              const formInputProps = form.getInputProps(key)

              return !isPasswordInput ? (
                <TextInput
                  key={key}
                  placeholder={placeholder}
                  className={css`
                    margin-top: 10px;
                    margin-bottom: 10px;
                    width: ${isWideScreen ? '330px' : '100%'};
                  `}
                  classNames={{ input: formInputProps.error ? classes.invalid : undefined }}
                  {...formInputProps}
                />
              ) : (
                <PasswordInput
                  key={key}
                  placeholder={placeholder}
                  className={css`
                    margin-top: 10px;
                    margin-bottom: 10px;
                    width: ${isWideScreen ? '330px' : '100%'};
                  `}
                  classNames={{ input: formInputProps.error ? classes.invalid : undefined }}
                  {...formInputProps}
                />
              )
            })}
          </Stack>

          <Button
            variant={buttonVariant}
            type="submit"
            rightIcon={buttonRightIcon}
            className={css`
              margin-top: 20px;
            `}
            disabled={!form.isValid() && form.isTouched()}
          >
            {buttonText}
          </Button>
        </form>
      </div>
    </div>
  )
}
