import type { TNodeType } from '../types'
import { NodeIdPattern } from './regexes'

export const getPossibleNodeType: (nodeIdentifier: string) => TNodeType[] = (nodeIdentifier) => {
  const possibleNodeTypes: TNodeType[] = []

  for (const [nodeType, regex] of Object.entries(NodeIdPattern)) {
    if (regex && regex.test(nodeIdentifier)) {
      possibleNodeTypes.push(nodeType as TNodeType)
    }
  }

  return possibleNodeTypes
}

export const isValidNodeIdentifier: (nodeIdentifier: string, type?: TNodeType) => boolean = (
  nodeIdentifier,
  type,
) => {
  if (type) {
    return getPossibleNodeType(nodeIdentifier).includes(type)
  } else {
    return getPossibleNodeType(nodeIdentifier).length > 0
  }
}
