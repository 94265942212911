import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const serviceCenterNodeRemoveReasonsGet = async () => {
  const removeReasons = await apiFetch<
    routes.ServiceCenterNodeRemoveReasonsGet.Request,
    routes.ServiceCenterNodeRemoveReasonsGet.Response
  >({
    url: routes.ServiceCenterNodeRemoveReasonsGet.path,
    body: null,
    params: {
      method: 'POST',
    },
  })

  return removeReasons
}
