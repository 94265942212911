import type { SelectProps } from '@mantine/core'
import { Select } from '@mantine/core'
import { getNodeTypeLabel } from 'App/ServiceCenter/utils/getNodeTypeLabel'
import { getPossibleNodeType } from 'App/ServiceCenter/utils/validateNodeIdentifier'
import { forwardRef, useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { checkAuthorization } from 'utils/checkAuthorization'

const NodeTypeField = forwardRef<
  HTMLInputElement,
  Omit<SelectProps, 'data'> &
    React.RefAttributes<HTMLInputElement> & {
      nodeIdentifier: string | undefined
      propertyId: number | undefined
    }
>((props, ref) => {
  const [possibleTypes, setPossibleTypes] = useState<{ label: string; value: string; disabled?: boolean }[]>(
    [],
  )

  const availableNodeTypes = possibleTypes.filter(({ disabled }) => !disabled)
  const { nodeIdentifier, value, propertyId, ...restProps } = props

  useEffect(() => {
    if (props.onChange) {
      //Select the only possible type if there is only one

      if (availableNodeTypes.length === 1) {
        props.onChange(availableNodeTypes[0].value)
      }

      if (availableNodeTypes.length === 0) {
        props.onChange(null)
      }
    }
  }, [possibleTypes])

  useEffect(() => {
    // if user changes property to install node, reset node type as node type user can install depends on property
    if (props.onChange) {
      props.onChange(null)
    }
  }, [propertyId])

  useEffect(() => {
    if (props.nodeIdentifier) {
      const nodeTypes = getPossibleNodeType(props.nodeIdentifier)

      if (!propertyId) return

      const filteredNodeTypes = nodeTypes.map((nodeType) => {
        let isEnabled

        if (nodeType.startsWith('ex'))
          isEnabled = checkAuthorization({ permission: 'VIEW_SSC_NODE_ACTIVE_EXTERNALS', entity: propertyId })

        switch (nodeType) {
          case 'scd':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_DISPENSERS',
              entity: propertyId,
            })

            break

          case 'ln_scd':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_DISPENSERS',
              entity: propertyId,
            })

            break

          case 'gtwy':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_GATEWAYS',
              entity: propertyId,
            })

            break

          case 'ln_gtwy':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_GATEWAYS',
              entity: propertyId,
            })

            break

          case 'rpt':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_REPEATERS',
              entity: propertyId,
            })

            break

          case 'y':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_STATIONS',
              entity: propertyId,
            })

            break

          case 'dn_y':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_STATIONS',
              entity: propertyId,
            })

            break

          case 'ln_r':
            isEnabled = checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_STATIONS',
              entity: propertyId,
            })

            break

          case 'trp':
            isEnabled = checkAuthorization({ permission: 'VIEW_SSC_NODE_ACTIVE_TRAPS', entity: propertyId })

            break

          case 'dn_trp':
            isEnabled = checkAuthorization({ permission: 'VIEW_SSC_NODE_ACTIVE_TRAPS', entity: propertyId })

            break

          default:
            isEnabled = false
        }

        return {
          label: isEnabled
            ? getNodeTypeLabel(nodeType)
            : `${getNodeTypeLabel(nodeType)} (Permission required) `,
          value: nodeType,
          disabled: !isEnabled,
        }
      })

      setPossibleTypes(filteredNodeTypes)
    }
  }, [props.nodeIdentifier, propertyId])

  return (
    <Select
      data={possibleTypes}
      value={availableNodeTypes.length === 1 ? availableNodeTypes[0].value : value}
      ref={ref}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      {...restProps}
    />
  )
})

NodeTypeField.displayName = 'NodeTypeField'

export { NodeTypeField }
