import { translate } from 'i18n/i18n'

export const SettingsSearchNotFound = () => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 50,
        textAlign: 'center',
        fontSize: 16,
      }}
    >
      <translate.Phrases.banyanApp k="We couldn't find anything matching your search. <br />Try again with a different term." />
    </div>
  )
}
