import type { TEquipmentType } from '../../store/serviceCenterStore'
import type { TNodeType } from '../../types'

const NODE_EQUIPMENT_TYPES: { [K in TNodeType]: TEquipmentType } = {
  scd: 'dispenser',
  ln_scd: 'dispenser',
  dcd: 'dispenser',
  gtwy: 'gateway',
  ln_gtwy: 'gateway',
  rpt: 'repeater',
  trp: 'trap',
  dn_trp: 'trap',
  y: 'station',
  dn_y: 'station',
  ln_r: 'station',
  ex_windmachine: 'external',
  ex_pump: 'external',
  ex_valve: 'external',
  ex_communication: 'external',
  ex_twig_valve: 'external',
  ex_twig_mc_gtwy: 'external',
  ex_twig_rpt: 'external',
}

export function getNodeEquipmentType(nodeType: TNodeType): TEquipmentType {
  return NODE_EQUIPMENT_TYPES[nodeType]
}
