import React from 'react'
import { useScreenSize } from '../../../../utils/useScreenSize'
import { EquipmentStatusMultiSelect } from '../../../ServiceCenter/EquipmentStatusMultiSelect/EquipmentStatusMultiSelect'
import { serviceCenterStore } from '../../../ServiceCenter/store/serviceCenterStore'
import type { TNodeStatus } from '../../../ServiceCenter/types'

export const EquipmentStatusFilter = () => {
  const { isWideScreen } = useScreenSize()

  const selectedStatuses = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentStatuses,
  )

  const handleSelectionChange = (selectedStatuses: TNodeStatus[]) => {
    serviceCenterStore.actions.setMapEquipmentStatuses(selectedStatuses)
  }

  return (
    <div css={{ marginTop: 10 }}>
      <EquipmentStatusMultiSelect
        selectedStatuses={selectedStatuses}
        dropdownPosition={isWideScreen ? 'bottom' : 'top'}
        onChange={handleSelectionChange}
      />
    </div>
  )
}
