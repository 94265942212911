import { translate } from 'i18n/i18n'

export const RecentlyAddedItem = ({ translatedTitle }: { translatedTitle: string }) => (
  <div css={{ display: 'flex', flexDirection: 'column' }}>
    <div>{translatedTitle}</div>
    <div>
      <em css={{ fontSize: 11 }}>{translate.phrases.banyanApp('Recently Added')}</em>
    </div>
  </div>
)
