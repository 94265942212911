export const contactCreateQuery = `mutation ContactCreate(
    $name: String!
    $email: String
    $sms: String
    $language: Language!
    $alertsContactMethods: [ContactMethods]
    $scoutContactMethods: [ContactMethods]
    $reportsContactMethods: [ContactMethods]
    $irrigationPlannerContactMethods: [ContactMethods]
    $groupIds: [ID]
  ) {
    createContact(
      data: {
        name: $name
        email: $email
        sms: $sms
        alertsContactMethods: $alertsContactMethods
        scoutContactMethods: $scoutContactMethods
        reportsContactMethods: $reportsContactMethods
        irrigationPlannerContactMethods: $irrigationPlannerContactMethods
        language: $language
        groupIds: $groupIds
      }
    ) {
      id
      name
      email
      sms
      groups {
        id
        name
      }
    }
  }`

export const contactUpdateQuery = `mutation contactUpdate(
    $id: ID!
    $name: String
    $email: String
    $sms: String
    $language: Language!
    $alertsContactMethods: [ContactMethods]
    $scoutContactMethods: [ContactMethods]
    $reportsContactMethods: [ContactMethods]
    $irrigationPlannerContactMethods: [ContactMethods]
    $groupIds: [ID]
  ) {
    updateContact(
      data: {
        name: $name
        email: $email
        sms: $sms
        alertsContactMethods: $alertsContactMethods
        scoutContactMethods: $scoutContactMethods
        reportsContactMethods: $reportsContactMethods
        irrigationPlannerContactMethods: $irrigationPlannerContactMethods
        language: $language
        groupIds: $groupIds
      }
      id: $id
    ) {
      id
      name
      email
      sms
      groups {
        id
        name
      }
    }
  }`

export const contactDeleteQuery = `mutation contactDelete($id: ID!) {
    deleteContact(id: $id)
  }`

export const groupCreateQuery = `mutation groupCreate($name: String!, $contactIds: [ID!]!) {
    createGroup(data: { name: $name, contactIds: $contactIds })
  }`

export const groupUpdateQuery = `mutation groupUpdate($id: ID!, $name: String!, $contactIds: [ID]!) {
  updateGroup(data: { name: $name, id: $id, contactIds: $contactIds })
}`

export const groupDeleteQuery = `mutation groupDelete($id: ID!) {
  deleteGroup(id: $id)
}`
