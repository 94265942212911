import Panel from 'components/SidePanel/Panel/Panel'
import type CssType from 'csstype'
import type { Dispatch } from 'react'
import React, { useState } from 'react'
import { smallStore } from 'stores/smallStore'
import type { TabPane } from '../DetailPanel'

export default function Bottom(props: {
  viewportSize: { height: number; width: number }
  allowDragContentForTouch?: boolean
  handleSize: number
  children?: ({
    width,
    height,
    isPanelExpanded,
  }: {
    width: number
    height: number
    isPanelExpanded: boolean
  }) => React.ReactNode
  backgroundColor: CssType.Property.BackgroundColor
  isPanelExpanded: boolean
  setIsPanelExpanded: Dispatch<React.SetStateAction<boolean>>
  tabs?: TabPane[]
}) {
  const { viewportSize, allowDragContentForTouch, handleSize, isPanelExpanded, setIsPanelExpanded } = props
  const safeArea = smallStore.useSelector((s) => s.safeArea)
  const x1Start = Math.round(viewportSize.width)
  const x2Start = Math.round(viewportSize.width - 5)
  const [width, setWidth] = useState<number>(Math.round(x2Start - x1Start - handleSize))
  const y1 = 5
  const y2 = viewportSize.height - 5
  const paddingTop = 150 + safeArea.insets.top //150 is the height of minimized side panel
  const y1Start = Math.round(viewportSize.height) - safeArea.insets.bottom
  const height = y2 - y1

  return (
    <Panel
      backgroundColor={props.backgroundColor}
      handleSize={handleSize}
      tabs={props.tabs}
      placement="bottom"
      borderRadius="10px"
      sizes={{
        y1: [paddingTop, y1Start],
        y2: viewportSize.height,
        x1: 0,
        x2: viewportSize.width,
      }}
      y1Start={isPanelExpanded ? paddingTop : y1Start}
      sizesSnapping={{
        y1: false,
        y2: false,
        x1: false,
        x2: false,
      }}
      debugShowSnapPointsOnDrag={false}
      allowDragContentForTouch={allowDragContentForTouch}
      onCoordsChange={(args) => setWidth(args.visibleWidth)}
      setIsPanelExpanded={setIsPanelExpanded}
    >
      <div
        css={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {props.children && props.children({ width: width + handleSize, height, isPanelExpanded })}
      </div>
    </Panel>
  )
}
