import type { FC } from 'react'
import React, { lazy, Suspense } from 'react'

const IrrigationSchedulerLauncher = lazy(() =>
  import('App/irrigation-scheduler/IrrigationSchedulerLauncher').then((m) => ({
    default: m.IrrigationSchedulerLauncher,
  })),
)

export const IrrigationSchedulerLauncherLazy: FC = () => {
  return (
    <Suspense fallback={null}>
      <IrrigationSchedulerLauncher />
    </Suspense>
  )
}
