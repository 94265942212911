import type { TabPane } from 'components/DetailPanel/DetailPanel'
import { DetailPanel } from 'components/DetailPanel/DetailPanel'
import { IconServiceCenter } from 'components/icons/IconServiceCenter'
import { translate } from 'i18n/i18n'
import React from 'react'
import { EquipmentStatus } from './EquipmentStatus/EquipmentStatus'

export const ServiceCenterDetailPanel = () => {
  const tabs: TabPane[] = [
    {
      key: 'STATUS',
      label: translate.phrases.placeholder('Status'),
      icon: (
        <span css={{ fontSize: 32 }}>
          <IconServiceCenter />
        </span>
      ),
    },
  ]

  return (
    <DetailPanel tabs={tabs}>
      {() => {
        return <EquipmentStatus />
      }}
    </DetailPanel>
  )
}
