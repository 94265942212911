import type { CSSObject } from '@emotion/css'
import { css, cx } from '@emotion/css'
import { useMantineTheme } from '@mantine/core'
import type { ButtonProps } from 'components/Button/Button'
import { Button } from 'components/Button/Button'
import React, { useEffect, useState } from 'react'

export type ToggleButtonProps<T> = {
  buttonProps?: Omit<ButtonProps, 'state' | 'stateChildren' | 'variant'>
  data: Array<{
    label: React.ReactNode
    value: T
  }>
  onChange: (value: T) => void
  defaultValue?: T
  value?: T
}

export const ToggleButton = <T,>({
  buttonProps,
  data,
  onChange,
  defaultValue,
  value,
}: ToggleButtonProps<T>) => {
  const theme = useMantineTheme()
  const { className, ...restOfButtonProps } = buttonProps ?? {}
  const [selectedButton, setSelectedButton] = useState<T>(value ?? defaultValue ?? data[0]?.value)

  const toggleButtonWrapperStyles = css`
    display: flex;
    flex-wrap: nowrap;
  `

  const handleToggle = (value: T) => {
    setSelectedButton(value)

    onChange(value)
  }

  useEffect(() => {
    if (value !== selectedButton) setSelectedButton(value as T)
  }, [value])

  return (
    <div className={toggleButtonWrapperStyles}>
      {data.map(({ label, value }, i, allItems) => {
        const borderRadiusArgs: CSSObject = {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        }

        if (!i) {
          borderRadiusArgs.borderTopRightRadius = 0

          borderRadiusArgs.borderBottomRightRadius = 0
        }

        if (i === allItems.length - 1) {
          borderRadiusArgs.borderTopLeftRadius = 0

          borderRadiusArgs.borderBottomLeftRadius = 0
        }

        const backgroundColor = value === selectedButton ? theme.colors.grey[3] : theme.colors.white[0]
        const color = value === selectedButton ? theme.colors.white[0] : theme.colors.midnight[0]

        return (
          <Button
            variant="tertiary"
            className={cx(
              className,
              css(borderRadiusArgs),
              css({
                backgroundColor: `${backgroundColor} !important`,
                color: `${color} !important`,
                borderColor: `${theme.colors.grey[3]} !important`,
              }),
            )}
            key={i}
            onClick={() => handleToggle(value)}
            {...restOfButtonProps}
          >
            {label}
          </Button>
        )
      })}
    </div>
  )
}
