import { apiRequestQueueStore } from './apiRequestQueueStore'
import { ApiRequestStatus } from './types'

export function waitForRequestCompletion(id: string): Promise<ApiRequestStatus> {
  return new Promise((resolve, reject) => {
    const unsubscribe = apiRequestQueueStore.subscribeToChanges(
      (s) => s.queuedRequests,
      (queuedRequests) => {
        const request = queuedRequests.find((r) => r.id === id)

        if (!request) {
          reject(new Error(`Request not found (${id})`))

          unsubscribe()
        } else if (request.status === ApiRequestStatus.COMPLETED) {
          // Request succeeded
          resolve(ApiRequestStatus.COMPLETED)

          unsubscribe()
        } else if (request.status === ApiRequestStatus.ERROR) {
          // Request errored
          reject(request.error)

          unsubscribe()
        } else if (request.status === ApiRequestStatus.PENDING && (request.retryCount || 0) > 0) {
          // Request is pending retry: don't wait for the next retry
          resolve(request.status)

          unsubscribe()
        }
      },
    )
  })
}
