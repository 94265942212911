import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import { IconLayers } from 'components/icons/IconLayers'
import { IconAndTitle } from 'components/SideNav/IconAndTitle/IconAndTitle'
import { translate } from 'i18n/i18n'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { generateNavigationMenu } from './generateNavigationMenu/generateNavigationMenu'
import { SectionItem } from './SectionItem/SectionItem'
import { SelectedLayersCountBadge } from './SelectedLayersCountBadge/SelectedLayersCountBadge'

export const Layers = () => {
  const layersSubsectionCollapsed = layersNavigationMenuStore.useSelector((s) => s.layersSubsectionCollapsed)
  const selectedValueGroups = selectedValueGroupsStore.useSelector((s) => s.selectedValueGroups)
  // TODO: we may want to memoize this, but the dependencies might get tricky
  const navigationMenu = generateNavigationMenu({ selectedValueGroups })
  const totalLayersSelected = navigationMenu.reduce((a, b) => a + Number(b?.childrenCheckedCount), 0)

  return (
    <>
      <IconAndTitle
        icon={<IconLayers />}
        onClick={() =>
          layersNavigationMenuStore.setState((s) => ({
            ...s,
            layersSubsectionCollapsed: !layersSubsectionCollapsed,
          }))
        }
        right={<ExpandableChevronIndicator expanded={!layersSubsectionCollapsed} stroke size={27} />}
        title={
          <>
            {translate.phrases.banyanApp('Layers')}
            <SelectedLayersCountBadge count={totalLayersSelected} />
          </>
        }
      />
      {!layersSubsectionCollapsed && (
        <div css={{ display: 'flex', flexDirection: 'column', paddingLeft: 10 }}>
          {navigationMenu.map((section) => (
            <SectionItem key={section.key} section={section} />
          ))}
        </div>
      )}
    </>
  )
}
