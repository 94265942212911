import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type { StackedChartSection } from 'components/StackedChart/types'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import type { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { wmActivity } from './wmActivity'
import { wmBattery } from './wmBattery'
import { wmRunTime } from './wmRunTime'

export const defaultValuesRequested: Partial<
  Record<
    VV.DomainTypes.WindMachine.TTimeseriesValueTypeKeysMerged,
    { preferredAggregationInterval: ReturnType<typeof chooseAmongstUnAggHourlyAndDaily> }
  >
> = {
  windMachineRPM: { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED },
  windMachineBattery: { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED },
  windMachineFuel: { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED },
  windMachineTemperature: { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED },
  windMachineActivity: { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED },
  windMachineSettings: { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED },
  windMachineActivityIntervals: { preferredAggregationInterval: EAggregationInterval.UNAGGREGATED },
}

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_FROST_FAN_DETAILS' })

export const apiArgs = ({
  selectedValueGroups,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  const selectedWindMachine = selectedFieldAssetsStore.getState().windMachinePoint

  if (!selectedValueGroups.wind_machine || isNil(selectedWindMachine)) {
    return {}
  }

  const valuesRequested = {
    ...defaultValuesRequested,
  }

  return {
    points: {
      lngLats: [selectedWindMachine],
      valuesRequested,
    },
  }
}

export const content = ({ data }: { data: routes.Values.Response }): StackedChartSection[] => {
  const windMachineLngLat = selectedFieldAssetsStore.getState().windMachinePoint
  const windMachineCharts = []

  windMachineCharts.push(wmRunTime({ data, windMachineLngLat, defaultValuesRequested }))

  windMachineCharts.push(wmActivity({ data, windMachineLngLat, defaultValuesRequested }))

  windMachineCharts.push(wmBattery({ data, windMachineLngLat, defaultValuesRequested }))

  return windMachineCharts
}
