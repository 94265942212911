import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { OfflineRequestError } from 'utils/errorCodes'
import type { TDeviceConfiguration } from '../DevicesManagement/types'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { serviceCenterNodeDeviceAdd } from './api/serviceCenterNodeDeviceAdd'
import { serviceCenterNodeDeviceInstall } from './api/serviceCenterNodeDeviceInstall'

export async function installOrAttachNodeDevice(nodeIdentifier: string, config: TDeviceConfiguration) {
  let unsynced = true

  const isInstallPlannedDevice =
    config.installationStatus === SharedTypes.NodeDeviceInstallationStatus.PLANNED

  const request = {
    ...config,
    nodeIdentifier,
  }

  try {
    const { status } = await ApiRequestQueue.createRequest(
      isInstallPlannedDevice ? ApiRequestType.INSTALL_NODE_DEVICE : ApiRequestType.ADD_NODE_DEVICE,
      request,
    )

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      // TODO: Return a user-friendly error message
      throw error
    }
  }

  if (unsynced) {
    if (isInstallPlannedDevice) {
      serviceCenterNodeDeviceInstall.callback(undefined, request)
    } else {
      serviceCenterNodeDeviceAdd.callback(undefined, request)
    }
  }
}
