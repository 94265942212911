import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconTrashCan = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 14 16')}>
      <path
        d="M0.968366 14.2222C0.968366 15.2 1.8399 16 2.9051 16H10.652C11.7172 16 12.5888 15.2 12.5888 14.2222V3.55556H0.968366V14.2222ZM13.5571 0.888889H10.1678L9.19948 0H4.35765L3.38928 0.888889H0V2.66667H13.5571V0.888889Z"
        fill="currentColor"
      />
    </svg>
  )
}
