import { Select } from '@mantine/core'
import { PanelDetailsContext } from 'App/Map/PanelDetails/PanelDetails'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { useContext, useMemo } from 'react'
import { colors } from 'settings/colors'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { filterFieldAssetsByValueTypes } from 'utils/filterFieldAssetsByValueTypes'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { sortByKey } from 'utils/sortByKey'
import type { TDropdownSelectorProps } from '../_types'

type SelectDataItem = { value: string; label: string }

export type TDropdownSelectorPropertyProps = TDropdownSelectorProps

export const DropdownSelectorPropertyWindMachine = ({
  valuesTimeseriesToFilterOn,
}: TDropdownSelectorPropertyProps) => {
  const { selectedProperty } = selectedFieldAssetsStore.useSelector((s) => ({
    selectedProperty: s.property,
  }))

  const { containerWidth } = useContext(PanelDetailsContext)

  const { properties } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties ?? [],
  }))

  const data = useMemo<SelectDataItem[]>(() => {
    return Object.values(properties ?? {})
      .sort(sortByKey('propertyName'))
      .flatMap((p) => {
        if (valuesTimeseriesToFilterOn && !isEmpty(valuesTimeseriesToFilterOn)) {
          const propertyHasValueTypes = valuesTimeseriesToFilterOn.some((vt) =>
            p.valuesTimeseries.includes(vt),
          )

          const pointsWithValues = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.points || []),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })

          const blocksWithValues = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.blocks || []),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })

          const zonesWithValues = filterFieldAssetsByValueTypes({
            fieldAssets: Object.values(p.irrigationZoneEmitters || []),
            valuesTimeseries: valuesTimeseriesToFilterOn,
          })

          if (
            !propertyHasValueTypes &&
            isEmpty(pointsWithValues) &&
            isEmpty(blocksWithValues) &&
            isEmpty(zonesWithValues)
          )
            return []
        }

        return {
          value: String(p.propertyId),
          label: p.propertyName,
        }
      })
  }, [selectedProperty, properties])

  if (!data.length) return null

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        <Select
          css={{
            width: containerWidth < 600 ? 200 : Math.min(280, Math.round(containerWidth / 3)),
            border: `1px solid ${colors.grey500}`,
            color: colors.grey800,
            borderRadius: 3,
          }}
          clearable={false}
          data={data}
          filter={mantineSelectSearchFilterProp}
          onChange={(newProperty: string) => setSelectedFieldAsset({ property: Number(newProperty) })}
          placeholder={translate.phrases.banyanApp('Select a Property')}
          searchable
          size="xs"
          spellCheck="false"
          styles={{
            ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
            dropdown: { marginTop: -6, fontWeight: 'normal' },
            input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
          }}
          value={String(selectedProperty)}
          withinPortal
          zIndex={hubConnectorSettings.zIndexForDropdownSelectorInStackemCharts}
        />
      </div>
      {checkAuthorization({
        permission: 'PUBLISH_FROST_FAN_OPERATIONAL_DEVICE_SETTINGS',
        entity: Number(selectedProperty),
      }) ? (
        <Button
          variant="tertiary"
          type="button"
          onClick={() =>
            smallStore.setState((s) => ({
              ...s,
              showWindMachineViewDetails: true,
            }))
          }
        >
          {translate.phrases.placeholder('Controls')}
        </Button>
      ) : null}
    </>
  )
}
