import { NumberInput, Slider } from '@mantine/core'
import { roundToDecimalPlaces } from '@semios/app-platform-common'
import type {
  TSelectedKcConfiguration,
  TSelectedKcConfigurationMap,
} from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useScreenSize } from 'utils/useScreenSize'

export const KcMultiplierSliderOrInput = ({
  blockId,
  selectedValue,
  setSelectedValueMap,
  setDirtyBlockIds,
}: {
  blockId: number
  selectedValue: TSelectedKcConfiguration
  setSelectedValueMap: Dispatch<SetStateAction<TSelectedKcConfigurationMap>>
  setDirtyBlockIds: Dispatch<SetStateAction<number[]>>
}) => {
  const [sliderValue, setSliderValue] = useState<number>((selectedValue?.kcMultiplier ?? 1) * 100)

  useEffect(() => {
    setSliderValue((selectedValue?.kcMultiplier ?? 1) * 100)
  }, [selectedValue?.modelId, selectedValue?.kcMultiplier])

  const handleOnChangeEnd = useCallback(
    (value: number) => {
      setSelectedValueMap((prev) => {
        const prevCopy = { ...prev }

        prevCopy[Number(blockId)] = {
          ...prevCopy[Number(blockId)],
          kcMultiplier: value / 100,
        }

        return prevCopy
      })

      setDirtyBlockIds((prev) => {
        if (!prev.includes(blockId)) {
          return [...prev, blockId]
        }

        return prev
      })
    },
    [blockId, setDirtyBlockIds, setSelectedValueMap],
  )

  const handleNumberInputOnChange = useCallback(
    (val: number) => {
      const roundedValue = roundToDecimalPlaces(Number(val), 2) ?? 0

      if (roundedValue >= 0 && roundedValue <= 2) {
        setSliderValue(roundedValue)

        setDirtyBlockIds((prev) => {
          if (!prev.includes(blockId)) {
            return [...prev, blockId]
          }

          return prev
        })

        setSelectedValueMap((prev) => {
          const prevCopy = { ...prev }

          prevCopy[Number(blockId)] = {
            ...prevCopy[Number(blockId)],
            kcMultiplier: roundedValue,
          }

          return prevCopy
        })
      }
    },
    [blockId, setDirtyBlockIds, setSelectedValueMap],
  )

  const { isWideScreen } = useScreenSize()

  return (
    <div css={{ padding: 10 }}>
      {isWideScreen ? (
        <Slider
          w={200}
          min={0}
          max={200}
          value={sliderValue}
          onChange={setSliderValue}
          onChangeEnd={handleOnChangeEnd}
          marks={[
            { value: 50, label: '50%' },
            { value: 100, label: '100%' },
            { value: 150, label: '150%' },
            { value: 200, label: '200%' },
          ]}
        />
      ) : (
        <NumberInput
          w={75}
          min={0}
          max={2}
          step={0.01}
          precision={2}
          size={'sm'}
          value={sliderValue / 100}
          onChange={handleNumberInputOnChange}
        />
      )}
    </div>
  )
}
