import { Group, Text, useMantineTheme } from '@mantine/core' // Import the Text component from the appropriate library
import { useBleManager } from 'App/ServiceCenter/BluetoothLowEnergy/BleManager'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import { SignalStrength } from 'App/ServiceCenter/SelectedEquipmentCard/ActiveNodeDetail/log/SignalStrength'
import { ToggleSymbolValue } from 'App/ServiceCenter/ToggleSymbolValue/ToggleSymbolValue'
import type { TNodeType } from 'App/ServiceCenter/types'
import { getNodeTypeLabel } from 'App/ServiceCenter/utils/getNodeTypeLabel'
import { Button } from 'components/Button/Button'
import { IconBluetooth } from 'components/icons/IconBluetooth'
import { IconCellular } from 'components/icons/IconCellular'
import { translate } from 'i18n/i18n'
import React from 'react'

export const NodeSummary: React.FC<{
  nodeId?: string
  nodeType?: TNodeType
  connectionStatus?: {
    bleStatus: AdapterStatus
    loraNetworkStatus?: number | 'disconnected'
  }
  children?: React.ReactNode
}> = ({ nodeId, nodeType, connectionStatus, children }) => {
  const theme = useMantineTheme()
  const bleManager = useBleManager()
  const nodeIdentifier = nodeId

  const getCellularSignalLevel = (rssi: number) => {
    if (rssi < -80) {
      return 1
    } else if (rssi < -70) {
      return 2
    } else if (rssi < -60) {
      return 3
    } else if (rssi < -50) {
      return 4
    } else {
      return 5
    }
  }

  const getBleConnectionStatusColor = (status: AdapterStatus) => {
    switch (status) {
      case AdapterStatus.IDLE:
        return theme.colors.red

      case AdapterStatus.DISABLED:
        return theme.colors.red

      case AdapterStatus.SCANNING:
        return theme.colors.gray[5]

      case AdapterStatus.CONNECTING:
        return 'lightgreen'

      case AdapterStatus.CONNECTED:
        return '#13A44D'

      default:
        return theme.colors.gray
    }
  }

  return (
    <div
      css={{
        backgroundColor: theme.colors.grey[0],
        border: `1px solid ${theme.colors.grey[1]}`,
        borderRadius: 3,
        padding: 20,
      }}
    >
      <h3 css={{ margin: '0 0 20px' }}>{translate.phrases.placeholder('Overview')}</h3>

      <h4 css={{ margin: '0 0 10px' }}>{translate.phrases.placeholder('Equipment Type')}</h4>
      <p css={{ margin: 0 }}>
        {nodeType ? getNodeTypeLabel(nodeType) : translate.phrases.placeholder('N/A')}
      </p>
      {connectionStatus && nodeId && connectionStatus.bleStatus === AdapterStatus.IDLE && (
        <Button
          variant="tertiary"
          css={{ width: '100%', marginTop: 10, marginBottom: 10 }}
          onClick={() => nodeIdentifier && bleManager.connectDeviceByNodeId(nodeIdentifier)}
        >
          <Text>{translate.phrases.placeholder('Connect with Bluetooth')}</Text>
        </Button>
      )}
      {connectionStatus && connectionStatus.bleStatus !== AdapterStatus.IDLE && (
        <Group position="apart" grow>
          <Group position="left" spacing={'xs'}>
            <Text>{translate.phrases.placeholder('Bluetooth:')}</Text>
            <span
              css={{
                alignItems: 'center',
                display: 'flex',
                color: getBleConnectionStatusColor(connectionStatus.bleStatus),
              }}
            >
              <IconBluetooth />
            </span>
          </Group>
          <Group position="left" spacing={'xs'}>
            <Text>{translate.phrases.placeholder('Wireless:')}</Text>
            {connectionStatus.loraNetworkStatus === 'disconnected' && (
              <span
                css={{
                  alignItems: 'center',
                  display: 'flex',
                  color: theme.colors.gray[6],
                }}
              >
                <IconCellular />
              </span>
            )}
            {typeof connectionStatus.loraNetworkStatus === 'number' && (
              <div>
                <ToggleSymbolValue value={`${connectionStatus.loraNetworkStatus} dBm`}>
                  <SignalStrength
                    signal={connectionStatus.loraNetworkStatus}
                    getSignalLevel={getCellularSignalLevel}
                  />
                </ToggleSymbolValue>
              </div>
            )}
          </Group>
        </Group>
      )}
      {children}
    </div>
  )
}
