import { unitConverter } from 'utils/unitConverter/unitConverter'
import { PREDICTION_KEY } from './predictionKey'

export const getFormattedSoilDepthValue = (depth: string) => {
  if (!depth) return 0

  if (depth === PREDICTION_KEY) return PREDICTION_KEY

  return Math.ceil(unitConverter.soilDepth(Number(depth)).value() || 0)
}
