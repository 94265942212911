import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents } from 'App/irrigation-scheduler/components/right-panels/utils/getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents'
import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const pulseEventsUpdate = (apiEvents: routes.IrrigationSchedulerPulseEventsUpdate.Response) => {
  if (!Array.isArray(apiEvents)) return

  irrigationSchedulerStore.setState((isss) => {
    const scheduledPulseIrrigationEvents = { ...isss.scheduledPulseIrrigationEvents }

    const propertyTimezone = getSelectedPropertyTimezoneForIrrigationScheduler(
      fieldAssetStore.getState(),
      isss,
    )

    apiEvents.forEach((apiEvent) => {
      const { earliestDateFrom, latestDateTo } = getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents(
        apiEvent.pulseIrrigationEvents,
        propertyTimezone,
      )

      if (earliestDateFrom && latestDateTo && apiEvent.status) {
        scheduledPulseIrrigationEvents[apiEvent.pulseEventId] = {
          ...scheduledPulseIrrigationEvents[apiEvent.pulseEventId],
          dateFrom: earliestDateFrom,
          dateTo: latestDateTo,
          type: mapApiScheduledEventStatusToTScheduledEventEnum(apiEvent.status),
          pulseIrrigationConfig: apiEvent.pulseIrrigationConfig,
          pulseIrrigationEvents: apiEvent.pulseIrrigationEvents.map((event) => {
            return {
              dateFrom: moment.tz(event.dateFrom, propertyTimezone),
              dateTo: moment.tz(event.dateTo, propertyTimezone),
              eventId: event.eventId.toString(),
              externalEventId: event.externalEventId?.toString(),
              status: mapApiScheduledEventStatusToTScheduledEventEnum(event.status),
            }
          }),
        }
      }
    })

    return {
      ...isss,
      scheduledPulseIrrigationEvents,
    }
  })
}
