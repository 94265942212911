import type { TActiveNode } from 'App/ServiceCenter/types'
import { getNodeTypeLabel } from 'App/ServiceCenter/utils/getNodeTypeLabel'
import React from 'react'

export const NodeInfoHeader: React.FC<{ node: TActiveNode | null }> = ({ node }) => {
  if (!node) return null

  return (
    <>
      <strong css={{ fontSize: '20px' }}>{node?.name}</strong>
      <div css={{ marginTop: '4px', fontSize: 14 }}>{node.nodeIdentifier}</div>
      <div css={{ marginTop: '4px', fontSize: 14 }}>{getNodeTypeLabel(node?.nodeType)}</div>
    </>
  )
}
