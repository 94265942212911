import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconInsect = () => (
  <svg {...makeBaseSvgIconProps('0 0 16 20')}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.31542H13.19C12.74 4.48621 12.12 3.77395 11.37 3.23177L13 1.49895L11.59 0L9.42 2.30689C8.96 2.18995 8.49 2.12617 8 2.12617C7.51 2.12617 7.04 2.18995 6.59 2.30689L4.41 0L3 1.49895L4.62 3.23177C3.88 3.77395 3.26 4.48621 2.81 5.31542H0V7.44159H2.09C2.04 7.7924 2 8.14322 2 8.50467V9.56775H0V11.6939H2V12.757C2 13.1185 2.04 13.4693 2.09 13.8201H0V15.9463H2.81C3.85 17.8492 5.78 19.1355 8 19.1355C10.22 19.1355 12.15 17.8492 13.19 15.9463H16V13.8201H13.91C13.96 13.4693 14 13.1185 14 12.757V11.6939H16V9.56775H14V8.50467C14 8.14322 13.96 7.7924 13.91 7.44159H16V5.31542ZM10 13.8192H6V11.693H10V13.8192ZM10 9.56919H6V7.44302H10V9.56919Z"
      fill="currentColor"
    />
  </svg>
)
