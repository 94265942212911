import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorWindMachine } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorWindMachine/DropdownSelectorWindMachine'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { StackedChartSection, StackedChartSectionItem } from 'components/StackedChart/types'
import type { TooltipFormatterContextObject } from 'highcharts'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { Legend } from './Legend/Legend'
import { makeIntervals } from './makeIntervals'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_FROST_FAN_DETAILS' })

export const wmRunTime = ({
  data,
  windMachineLngLat,
  defaultValuesRequested,
}: {
  data: routes.Values.Response
  windMachineLngLat: string | null
  defaultValuesRequested: object
}): StackedChartSection => {
  let items = [] as StackedChartSectionItem[]

  const commonReturnItems = {
    title: translate.phrases.placeholder('Wind Machine Run Time'),
    titleChildren: (
      <DropdownSelectorWindMachine
        valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
        pointCategory={'windMachinePoint'}
      />
    ),
    id: 'control-wind-machine',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const wmdata = data?.points?.[String(windMachineLngLat)]?.values
  const wmIntervals = wmdata?.windMachineActivityIntervals
  const wmActivity = wmdata?.windMachineActivity
  const timeseries = wmIntervals?.[0]?.timeseries
  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const intervals = makeIntervals({ timeseries })

  items.push({
    childrenLower: <Legend intervals={intervals} />,
    chartConfig: {
      semiosHighchartsAdditions: {
        id: 'stackem-wind-machine-run-time',
        firstForecastTimestamp: +new Date(),
      },
      chart: {
        type: 'xrange',
        height: 200,
      },

      tooltip: {
        xDateFormat: '%Z',
        outside: false,
        formatter: function (this: TooltipFormatterContextObject) {
          const { color, name, x, x2 } = this.points?.[0]?.point ?? {}
          const dateFrom = moment.tz(x, timezone)
          const dateTo = moment.tz(x2, timezone)
          const formatToUse = 'ddd, MMM D, YYYY h:mm A (z)'
          const formattedDateFrom = dateFrom.format(formatToUse)
          const formattedDateTo = dateTo.format(formatToUse)

          return `
                <div style="font-size:16px;display:flex;flex-direction:column;gap:4px 12px">
                  <div style="display:flex;align-items:center;gap:6px">
                    <div style="width:12px;height:12px;background:${color}"></div>
                    <div style="font-weight:bold">${name}</div>
                  </div>
                  <table>
                    <tr>
                      <td style="font-weight:bold;padding:0 6px">${translate.phrases.banyanApp('From')}</td>
                      <td>${formattedDateFrom}</td>
                    </tr>
                    <tr>
                      <td style="font-weight:bold;padding:0 6px">${translate.phrases.banyanApp('To')}</td>
                      <td>${formattedDateTo}</td>
                    </tr>
                  </table>
                </div>`
        },
      },
      series: [
        {
          showInLegend: false,
          borderRadius: 0,
          minPointLength: 3,
          borderWidth: 0,
          enableMouseTracking: true,
          type: 'xrange',
          id: 'windMachine',
          name: wmActivity?.[0].metadata?.name ?? 'N/A',
          data: intervals,
        },
      ],
    },
  })

  return {
    title: translate.phrases.placeholder('Wind Machine Run Time'),
    titleChildren: (
      <DropdownSelectorWindMachine
        valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
        pointCategory={'windMachinePoint'}
      />
    ),
    id: 'control-wind-machine',
    items,
  }
}
