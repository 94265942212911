import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconWmWarning = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 63 64')}>
      <path
        d="M41.8633 36.3536L46.1698 33.8203L51.4264 40.3435C51.4264 40.3435 52.313 42.8134 50.0964 42.4968C47.8798 42.2434 46.1698 40.9135 44.7765 39.3935C43.3199 37.9369 41.8633 36.3536 41.8633 36.3536Z"
        fill="white"
      />
      <path
        d="M48.1323 32.362L50.4756 34.7686L57.8854 36.9219C57.8854 36.9219 60.292 37.7452 61.6853 36.6685C63.142 35.5919 63.7119 33.8186 61.6853 33.3753C59.6587 32.932 51.6156 30.082 51.6156 30.082L48.1323 31.982"
        fill="white"
      />
      <path d="M35.9093 34.832H32.806L31.0327 39.1386V63.3313H37.9992V39.1386L35.9093 34.832Z" fill="white" />
      <path d="M32.806 29.1326H35.9093L37.9992 24.8261V0H31.0327V24.8261L32.806 29.1326Z" fill="white" />
      <path
        d="M56.3653 18.9974C56.3653 18.9974 46.8022 24.5706 43.6356 26.2172C40.469 27.8005 38.8224 28.6238 38.8224 28.6238L37.4924 30.3971H31.6659L30.0193 28.8772C30.0193 28.8772 18.7462 25.5206 16.4029 23.8106C14.3763 22.2907 12.9197 23.494 12.9197 23.494L0 27.2939V44.0134L13.9963 34.957L20.5195 41.3535C20.5195 41.3535 20.6462 49.9666 20.6462 51.5499C20.6462 53.1332 20.2028 57.5031 23.3694 58.1364C25.586 58.5798 26.916 54.8432 27.106 53.2599C27.5493 49.27 28.1193 39.8335 28.1193 39.8335L32.4259 33.627H36.9224L38.949 36.3503C38.949 36.3503 52.312 27.8005 54.7187 26.2172C57.1253 24.6339 57.8852 23.8739 58.7085 20.644C59.5319 17.4141 56.3653 18.9974 56.3653 18.9974Z"
        fill="white"
      />
    </svg>
  )
}
