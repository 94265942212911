import type { TPermission } from '@semios/app-platform-value-type-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { isEmpty } from 'lodash'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { userDetailsStore } from 'stores/userDetailsStore'

type RequiresObject = {
  permission: TPermission
  entity: number | TFieldAssetKeyTypes.TRegionId | '*' | 'FOR_ANY_ENTITY'
}

export type RequiresArg = RequiresObject | RequiresObject[] | boolean

export const checkAuthorization = (
  requires: RequiresArg,
  userPermissions = userDetailsStore.getState().permissions,
  excludeRegionsFromCheck = false,
) => {
  if (typeof requires === 'boolean') return requires

  const requiresArray: RequiresObject[] = [].concat(requires as [])

  return requiresArray.some(({ permission, entity }) => {
    let passes = false

    const permissionArray: TPermission[] = [].concat(permission as unknown as [])

    try {
      passes = permissionArray.some((p) => {
        if (p === 'MOCK_PERMISSION') return true

        if (HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD) {
          /* eslint-disable prettier/prettier, padding-line-between-statements */
          if (p.startsWith('EDIT_')) return false
          if (p.startsWith('PUBLISH_')) return false
          if (p === 'VIEW_ALMOND_HULL_SPLIT_SUMMARY') return false
          /* eslint-enable prettier/prettier, padding-line-between-statements */
        }

        let hasThisPermission = false

        try {
          if (excludeRegionsFromCheck && entity === 'FOR_ANY_ENTITY') {
            const entityIds = Object.keys(userPermissions[p] ?? {})

            hasThisPermission = entityIds.filter((eid) => Number(eid) || eid === '*').length > 0
          } else if (entity === 'FOR_ANY_ENTITY') {
            hasThisPermission = !isEmpty(userPermissions[p])
          } else {
            hasThisPermission = !isEmpty(userPermissions[p]) && userPermissions[p][entity]
          }
        } catch (err) {}

        return hasThisPermission
      })
    } catch (err) {}

    return passes
  })
}
