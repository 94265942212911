const convertPixelWidthHeightToEm = (o: { width: number; height: number }) => {
  if (o.width >= o.height) {
    return {
      width: '1em',
      height: `${o.height / o.width}em`,
    }
  } else {
    return {
      height: '1em',
      width: `${o.width / o.height}em`,
    }
  }
}

export const makeBaseSvgIconProps = (viewBox: string) => {
  const [, , viewBoxWidth, viewBoxHeight] = viewBox.split(' ')

  /**
   * it would be nice to just write width="1em" xor height="1em" on
   * each SVG, but old versions of Safari necessitate declarations
   * for both width and height
   */
  const { width, height } = convertPixelWidthHeightToEm({
    width: Number(viewBoxWidth),
    height: Number(viewBoxHeight),
  })

  return {
    width,
    height,
    viewBox,
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  }
}
