import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'

export const getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents = (
  pulseIrrigationEvents: SharedTypes.ScheduledIrrigationEvent['pulseIrrigationEvents'],
  timezone: string,
) => {
  if (!pulseIrrigationEvents || !pulseIrrigationEvents.length)
    return {
      earliestDateFrom: null,
      latestDateTo: null,
    }

  const { earliestDateFrom, latestDateTo } = pulseIrrigationEvents.reduce<{
    earliestDateFrom: Moment | null
    latestDateTo: Moment | null
  }>(
    (acc, event) => {
      const dateFrom = moment.tz(event.dateFrom, timezone)
      const dateTo = moment.tz(event.dateTo, timezone)

      if (!acc.earliestDateFrom || dateFrom.isBefore(acc.earliestDateFrom)) {
        acc.earliestDateFrom = dateFrom
      }

      if (!acc.latestDateTo || dateTo.isAfter(acc.latestDateTo)) {
        acc.latestDateTo = dateTo
      }

      return acc
    },
    {
      earliestDateFrom: null,
      latestDateTo: null,
    },
  )

  return {
    earliestDateFrom,
    latestDateTo,
  }
}
