import { useMantineTheme } from '@mantine/core'
import { useDebouncedValue, usePrevious } from '@mantine/hooks'
import { isNil } from '@semios/app-platform-common'
import { WindMachineViewDetailsMenu } from 'App/Map/PanelDetails/_utils/by-domain/windMachine/WindMachineViewDetailsMenu/WindMachineViewDetailsMenu'
import { StatusBarArea } from 'components/StatusBarArea/StatusBarArea'
import { createContext, useContext, useEffect } from 'react'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { isLandscapeMode } from 'utils/isLandscapeMode'
import { MapContext } from '../MapContext/MapContext'
import { StackemCharts } from './StackemCharts/StackemCharts'
import { SummaryGridTable } from './SummaryGridTable/SummaryGridTable'

export const PanelDetailsContext = createContext<{ containerWidth: number }>({ containerWidth: 0 })

export const PanelDetails = ({
  panelWidth,
  panelHeight,
  isPanelExpanded,
}: {
  panelWidth: number
  panelHeight: number
  isPanelExpanded: boolean
}) => {
  const [containerWidth] = useDebouncedValue(panelWidth, 125)
  const [containerHeight] = useDebouncedValue(panelHeight, 125)
  const theme = useMantineTheme()
  const { map } = useContext(MapContext)
  const currentSelectedTab = detailsPanelStore.useSelector((s) => s.selectedTab)
  const selectedValueGroups = selectedValueGroupsStore.useSelector((s) => s.selectedValueGroups)
  const oldContainerWidth = usePrevious(containerWidth)
  // TODO: The container height isnt changing when panel is opened on non widescreen
  const oldContainerHeight = usePrevious(containerHeight)
  const previousIsLandscape = usePrevious(isLandscapeMode())

  useEffect(() => {
    if (!isNil(oldContainerWidth) && !isNil(oldContainerHeight)) {
      if (!(previousIsLandscape && !isLandscapeMode())) {
        map?.panBy((containerWidth - oldContainerWidth) / 2, (containerHeight - oldContainerHeight) / 2)
      }
    }
  }, [containerWidth, containerHeight])

  /*
    On first render, when no tab is selected yet, and the user drag the panel, we don't want to show an empty panel.
    Instead, we show the summary page.
   **/
  useEffect(() => {
    if (isPanelExpanded && currentSelectedTab === '') {
      detailsPanelStore.setState((s) => ({ ...s, selectedTab: 'SUMMARY' }))
    }
  }, [isPanelExpanded])

  return (
    <PanelDetailsContext.Provider value={{ containerWidth }}>
      <StatusBarArea backgroundColor={theme.colors.midnight[0]} darkContent={true} />
      <div css={{ display: currentSelectedTab === 'SUMMARY' ? 'block' : 'none' }}>
        <SummaryGridTable
          preventFetch={!isPanelExpanded || currentSelectedTab !== 'SUMMARY'}
          containerHeight={containerHeight}
          containerWidth={containerWidth}
          selectedValueGroups={selectedValueGroups}
        />
      </div>
      <div css={{ display: currentSelectedTab === 'DETAILS' ? 'block' : 'none' }}>
        <div
          css={{
            width: containerWidth,
            height: containerHeight,
            maxHeight: containerHeight,
            overflow: 'auto',
            userSelect: 'none',
          }}
        >
          <StackemCharts
            preventFetch={!isPanelExpanded || currentSelectedTab !== 'DETAILS'}
            containerWidth={containerWidth}
            selectedValueGroups={selectedValueGroups}
          />
          <WindMachineViewDetailsMenu />
        </div>
      </div>
    </PanelDetailsContext.Provider>
  )
}
