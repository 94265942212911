import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { checkAuthorization } from 'utils/checkAuthorization'
import type { TActiveGateway, TActiveNode, TPlannedGateway, TPlannedNode } from '../types'
import { isPlannedNode } from './isPlannedNode'

export const checkRepositionPermissions = (
  node: TActiveGateway | TActiveNode | TPlannedGateway | TPlannedNode,
) => {
  let permissionKey: TPermission

  if (isPlannedNode(node)) {
    if ((node as TPlannedGateway | TPlannedNode).nodeType.includes('gtwy')) {
      permissionKey = 'EDIT_SSC_REPOSITION_PLANNED_GATEWAY'
    } else {
      permissionKey = 'EDIT_SSC_REPOSITION_PLANNED_NODE'
    }
  } else if ((node as TActiveGateway).gatewayType) {
    permissionKey = 'EDIT_SSC_REPOSITION_ACTIVE_GATEWAY'
  } else {
    permissionKey = 'EDIT_SSC_REPOSITION_ACTIVE_NODE'
  }

  return checkAuthorization({
    permission: permissionKey,
    entity:
      (node as TActiveNode | TPlannedNode | TPlannedGateway).propertyId ||
      (node as TActiveGateway).propertyIds[0],
  })
}
