import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconQuestionMark = ({ fillColor = '#0B1424' }: { fillColor?: string }) => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <circle cx="10" cy="10" r="9" fill={fillColor} stroke="currentColor" strokeWidth="2" />
      <path
        d="M10.7983 12.6235H8.69629C8.69629 12.1938 8.72559 11.8154 8.78418 11.4883C8.84766 11.1562 8.95508 10.8584 9.10645 10.5947C9.25781 10.3311 9.46289 10.0869 9.72168 9.8623C9.95117 9.67188 10.1489 9.48877 10.3149 9.31299C10.481 9.13721 10.6079 8.95898 10.6958 8.77832C10.7837 8.59766 10.8276 8.40479 10.8276 8.19971C10.8276 7.9458 10.791 7.73828 10.7178 7.57715C10.6494 7.41602 10.5444 7.29639 10.4028 7.21826C10.2661 7.13525 10.0928 7.09375 9.88281 7.09375C9.71191 7.09375 9.55078 7.13525 9.39941 7.21826C9.25293 7.30127 9.1333 7.43066 9.04053 7.60645C8.94775 7.77734 8.89648 8.00439 8.88672 8.2876H6.40381C6.41846 7.56006 6.57959 6.96924 6.88721 6.51514C7.19971 6.05615 7.61475 5.72168 8.13232 5.51172C8.65479 5.29688 9.23828 5.18945 9.88281 5.18945C10.5957 5.18945 11.2085 5.29932 11.7212 5.51904C12.2339 5.73877 12.627 6.06592 12.9004 6.50049C13.1738 6.93018 13.3105 7.45996 13.3105 8.08984C13.3105 8.50977 13.23 8.87598 13.0688 9.18848C12.9126 9.49609 12.7002 9.78418 12.4316 10.0527C12.168 10.3164 11.8726 10.5996 11.5454 10.9023C11.2622 11.1514 11.0693 11.4053 10.9668 11.6641C10.8643 11.918 10.8081 12.2378 10.7983 12.6235ZM8.396 14.8501C8.396 14.4985 8.52295 14.2056 8.77686 13.9712C9.03076 13.7319 9.36035 13.6123 9.76562 13.6123C10.1709 13.6123 10.5005 13.7319 10.7544 13.9712C11.0083 14.2056 11.1353 14.4985 11.1353 14.8501C11.1353 15.2017 11.0083 15.4971 10.7544 15.7363C10.5005 15.9707 10.1709 16.0879 9.76562 16.0879C9.36035 16.0879 9.03076 15.9707 8.77686 15.7363C8.52295 15.4971 8.396 15.2017 8.396 14.8501Z"
        fill="currentColor"
      />
    </svg>
  )
}
