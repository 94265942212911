import { KcRange } from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/NewOrEditKcModelModal/KcModelTable/KcRange/KcRange'
import { KcLineChart } from 'components/KcLineChart/KcLineChart'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback } from 'react'
import type { TKcRangeRow } from '../NewOrEditKcModelModal'
import { filterValidKcRanges } from '../_utils/filterValidKcRanges'
import { AddRowButton } from './AddRowButton/AddRowButton'

export const KcModelTable = ({
  kcRanges,
  setKcRanges,
  timezone,
}: {
  kcRanges: TKcRangeRow[]
  setKcRanges: Dispatch<SetStateAction<TKcRangeRow[]>>
  timezone: string
}) => {
  const handleAddRow = useCallback(() => {
    setKcRanges((prev) => {
      const prevCopy = [...prev]

      prevCopy.sort((a, b) => {
        if (a.dateRangeStart && b.dateRangeStart) {
          return moment.tz(a.dateRangeStart, timezone).diff(moment.tz(b.dateRangeStart, timezone))
        }

        return 0
      })

      return [
        ...prevCopy,
        {
          dateRangeStart: null,
          dateRangeEnd: null,
          kc: undefined,
          id: `${+Date.now()}`,
        },
      ]
    })
  }, [timezone, setKcRanges])

  const handleSetDates = useCallback(
    (newDates: [Date | null, Date | null], index: number) => {
      setKcRanges((prev) => {
        let newRanges = [...prev]

        const newDateFrom = newDates[0] && moment.tz(newDates[0], timezone)?.format('YYYY-MM-DD')
        const newDateTo = newDates[1] && moment.tz(newDates[1], timezone)?.format('YYYY-MM-DD')

        newRanges[index].dateRangeStart = newDateFrom ?? null

        newRanges[index].dateRangeEnd = newDateTo ?? null

        return newRanges
      })
    },
    [timezone, setKcRanges],
  )

  const handleKcOnChange = useCallback(
    (newKcValue: string | number, index: number) => {
      setKcRanges((prev) => {
        const newRanges = [...prev]

        if (typeof newKcValue === 'number') {
          newRanges[index].kc = newKcValue
        } else {
          newRanges[index].kc = undefined
        }

        return newRanges
      })
    },
    [setKcRanges],
  )

  const handleRemoveRow = useCallback(
    (index: number) => {
      setKcRanges((prev) => {
        const newRanges = [...prev]

        newRanges.splice(index, 1)

        return newRanges
      })
    },
    [setKcRanges],
  )

  const sortedKcRanges = kcRanges.sort((a, b) => {
    if (!a.dateRangeStart || !b.dateRangeStart) {
      return 0
    }

    return moment.tz(a.dateRangeStart, timezone).diff(moment.tz(b.dateRangeStart, timezone))
  })

  const kcRangesWithIncompleteDateRangesFilteredOut = filterValidKcRanges(sortedKcRanges)
  const dateFrom = kcRangesWithIncompleteDateRangesFilteredOut?.[0]?.dateRangeStart

  const dateTo =
    kcRangesWithIncompleteDateRangesFilteredOut?.[kcRangesWithIncompleteDateRangesFilteredOut.length - 1]
      ?.dateRangeEnd

  return (
    <div>
      <div css={{ marginTop: 12, marginBottom: 12, width: '100%', height: 300 }}>
        {kcRangesWithIncompleteDateRangesFilteredOut &&
          Array.isArray(kcRangesWithIncompleteDateRangesFilteredOut) && (
            <KcLineChart
              dateFrom={dateFrom ? moment.tz(dateFrom, timezone) : null}
              dateTo={dateTo ? moment.tz(dateTo, timezone) : null}
              kcRangesData={kcRangesWithIncompleteDateRangesFilteredOut.map((range) => {
                return {
                  dateRangeStart: range.dateRangeStart,
                  dateRangeEnd: range.dateRangeEnd,
                  kcStart: range.kc,
                  kcEnd: range.kc,
                  linearInterpolation: false,
                  startMoment: moment.tz(range.dateRangeStart, timezone),
                  endMoment: moment.tz(range.dateRangeEnd, timezone),
                }
              })}
              kcMultiplier={1}
              noDataMessage={translate.phrases.banyanApp('Select a Date Range and Kc Value to see graph')}
            />
          )}
      </div>
      {sortedKcRanges.map((range, index) => {
        return (
          <KcRange
            removeRow={() => {
              handleRemoveRow(index)
            }}
            disabled={false}
            key={range.id}
            index={index}
            dates={[
              range.dateRangeStart ? moment.tz(range.dateRangeStart, timezone).toDate() : null,
              range.dateRangeEnd ? moment.tz(range.dateRangeEnd, timezone).toDate() : null,
            ]}
            setDates={(e) => {
              handleSetDates(e, index)
            }}
            kcValue={range.kc}
            setKcValue={(val) => {
              handleKcOnChange(val, index)
            }}
            kcRanges={kcRanges}
            timezone={timezone}
          />
        )
      })}
      <AddRowButton
        disabled={
          kcRanges[kcRanges.length - 1].dateRangeStart === null ||
          kcRanges[kcRanges.length - 1].dateRangeEnd === null
        }
        onClick={handleAddRow}
      />
    </div>
  )
}
