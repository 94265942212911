import { translate } from 'i18n/i18n'
import { alphaColorReplacer, colors } from 'settings/colors'

export const makeRainSeries = (
  timeseries: {
    timestamp: string
    value: { sum: number | null }
  }[],
) => {
  const rainData = timeseries
    .reduce((acc, curr, index, array) => {
      const isFuture = curr.timestamp > new Date().toISOString()
      const unixTime = +new Date(curr.timestamp)
      const currentSum = curr?.value?.sum ?? 0
      const currentStatus = currentSum > 0 ? 'on' : 'off'

      if (index === 0) {
        acc.push({
          x: unixTime,
          x2: undefined, // to be finalized later
          key: `${index}-rain`,
          y: 0,
          color: isFuture ? alphaColorReplacer(colors.rain, 0.5) : colors.rain,
          sum: currentSum,
          status: currentStatus,
          pointWidth: 14,
        })
      } else {
        const last = acc[acc.length - 1]

        if (last.status === 'on' && currentStatus === 'on') {
          // Continue the 'on' period and accumulate sum
          last.sum += currentSum

          last.color = isFuture ? alphaColorReplacer(colors.rain, 0.5) : colors.rain
        } else if (last.status !== currentStatus) {
          last.x2 = unixTime

          acc.push({
            x: unixTime,
            x2: undefined,
            key: `${index}-rain`,
            y: 0,
            color: isFuture ? alphaColorReplacer(colors.rain, 0.5) : colors.rain,
            sum: currentStatus === 'on' ? currentSum : 0,
            status: currentStatus,
            pointWidth: 14,
          })
        }
      }

      if (index === array.length - 1) {
        if (acc[acc.length - 1].x2 === null) {
          acc[acc.length - 1].x2 = unixTime
        }
      }

      return acc
    }, [] as { key: string; y: number; color: string; x: number; x2?: number; sum: number; status: 'on' | 'off'; pointWidth: number }[])
    .filter(({ status }) => status === 'on')

  return {
    type: 'xrange' as const,
    showInLegend: false,
    data: rainData,
    id: 'rainIntervals',
    pointPadding: 0.5,
    groupPadding: 0.5,
    borderRadius: 2,
    borderColor: colors.grey800,
    color: colors.rain,
    name: translate.phrases.banyanApp('Rain'),
  }
}
