import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const serviceCenterLuresGet = async (deactivated = false) =>
  await apiFetch<routes.ServiceCenterLuresGet.Request, routes.ServiceCenterLuresGet.Response>({
    url: routes.ServiceCenterLuresGet.path,
    body: {
      deactivated,
    },
    params: {
      method: 'POST',
    },
  })
