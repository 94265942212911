import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconCloud = () => (
  <svg {...makeBaseSvgIconProps('0 0 22 15')}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3439 5.6625C16.7344 2.42812 14.0185 0 10.7559 0C8.16553 0 5.91575 1.5375 4.79534 3.7875C2.0974 4.0875 0 6.47813 0 9.375C0 12.4781 2.41112 15 5.37795 15H17.0302C19.504 15 21.5118 12.9 21.5118 10.3125C21.5118 7.8375 19.6743 5.83125 17.3439 5.6625Z"
      fill="currentColor"
    />
  </svg>
)
