import { Radio } from 'components/Radio/Radio'

export const RadioWrapper = ({
  checked,
  children,
  onClick,
  title,
}: {
  checked: boolean
  children?: React.ReactNode
  onClick: () => void
  title: string
}) => {
  return (
    <div css={{}}>
      <div css={{ display: 'flex', marginBottom: 10 }}>
        <Radio checked={checked} onClick={onClick} css={{ marginRight: 10 }} readOnly />

        {/* if there are no children, don't bother limiting the title's width */}
        <span css={{ width: children ? 160 : 'auto', cursor: 'pointer' }} onClick={onClick}>
          {title}
        </span>
      </div>
      <div css={{ marginLeft: 30 }}>{children}</div>
    </div>
  )
}
