import type { SelectProps } from '@mantine/core'
import { Select } from '@mantine/core'
import { getStyles } from 'components/PropertySelect/getStyles'
import { forwardRef } from 'react'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'

type Props = {
  disabled?: boolean
} & SelectProps

const PropertyField = forwardRef<HTMLInputElement, Props & React.RefAttributes<HTMLInputElement>>(
  (props, ref) => {
    const { value, disabled = false, ...restProps } = props

    return (
      <Select
        ref={ref}
        css={{ borderRadius: 3 }}
        clearable={false}
        disabled={disabled}
        filter={mantineSelectSearchFilterProp}
        searchable
        size="sm"
        spellCheck="false"
        styles={getStyles(false, disabled)}
        value={String(props.value)}
        {...restProps}
      />
    )
  },
)

PropertyField.displayName = 'PropertyField'

export { PropertyField }
