import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TEmitterType } from '@semios/app-platform-common'
import {
  getValueTypeFromEmitterType,
  IRRIGATION_ACTIVITY_APPLIED_VALUE_TYPE_PREFIX,
  IRRIGATION_ACTIVITY_PLANNED_VALUE_TYPE_PREFIX,
} from '@semios/app-platform-common'
import { EAggregationInterval } from '@semios/app-platform-value-type-definitions'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

export const getIrrigationZoneArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
  irrigationZoneId,
  emitterType,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  irrigationZoneId: string
  emitterType: TEmitterType
}): Partial<routes.Values.Request> => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.irrigationZoneEmitter) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: EAggregationInterval.UNAGGREGATED,
  }

  const valuesRequestedForIrrigationZones: Record<string, typeof preferredAggregationInterval> = {}

  const valueTypeForSemiosIntervals = getValueTypeFromEmitterType(
    IRRIGATION_ACTIVITY_APPLIED_VALUE_TYPE_PREFIX,
    emitterType,
  )

  const valueTypeForPlannedSemiosIntervals = getValueTypeFromEmitterType(
    IRRIGATION_ACTIVITY_PLANNED_VALUE_TYPE_PREFIX,
    emitterType,
  )

  valuesRequestedForIrrigationZones[valueTypeForSemiosIntervals] = preferredAggregationInterval

  valuesRequestedForIrrigationZones[valueTypeForPlannedSemiosIntervals] = preferredAggregationInterval

  return {
    irrigationZones: {
      irrigationZoneIds: [+irrigationZoneId],
      valuesRequested: valuesRequestedForIrrigationZones,
    },
  }
}
