import { css } from '@emotion/css'
import { faCloudSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDebouncedState, useDisclosure } from '@mantine/hooks'
import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { colors } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapSearchStore } from 'stores/mapSearchStore'
import { mapStore } from 'stores/mapStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import { Label } from '../../Label/Label'
import type { PROPERTY_META, TPropertiesValueTypesToPropsDictionary } from '../PropertyValuesCache'

export type TPropertyBadgeComponentProps = {
  meta: PROPERTY_META
  valueTypesToPropsDictionary: TPropertiesValueTypesToPropsDictionary
}

const USE_EXPANDER = false

export const PropertyBadgeComponent = (props: TPropertyBadgeComponentProps) => {
  const primaryValueGroup = usePrimaryValueGroup()

  const { loadingCurrentValues, showMapBadgeNames, valueType } = mapControlsStore.useSelector((s) => ({
    showMapBadgeNames: s.showMapBadgeNames,
    valueType: !primaryValueGroup ? null : s.mapVisualValueGroup.PROPERTY[primaryValueGroup]?.valueType,
    otherThingsWeWantToTriggerARefreshOn: [s.heatmapColoring, s.heatmapExtremes],
    loadingCurrentValues: s.loadingCurrentValues,
  }))

  // TODO: this is a hack, as you probably guessed. It seems that the maps cache has a lag in updating the props. This will
  const [loadingCurrentValuesDebounced, setLoadingCurrentValuesDebounced] = useDebouncedState(
    loadingCurrentValues,
    1,
  )

  useEffect(() => {
    setLoadingCurrentValuesDebounced(loadingCurrentValues)
  }, [loadingCurrentValues])

  const [isHovered, { close, open }] = useDisclosure(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expanded, setIsExpanded] = useState(false)
  const selectedProperty = selectedFieldAssetsStore.useSelector((s) => s.property)
  const highlightedPropertyIds = mapSearchStore.useSelector((s) => s.highlightedPropertyIds)
  const ref = useRef<HTMLDivElement>(null)
  const isSelected = Number(props.meta.propertyId) === selectedProperty
  const isHighlighted = highlightedPropertyIds[Number(props.meta.propertyId)]
  const zoom = mapStore.useSelector((s) => s.zoom)

  const onPropertyClick = useCallback(() => {
    setSelectedFieldAsset({ property: Number(props.meta.propertyId) })
  }, [])

  useSetMapOverlayZIndex({
    baseZIndex: props.valueTypesToPropsDictionary?.[String(valueType)]?.baseZIndex,
    ref,
    mItem: 'M_Property',
    isSelected,
    isHighlighted,
    isHovered,
  })

  useEffect(() => {
    close()
  }, [zoom])

  const height = USE_EXPANDER && expanded ? 220 : 40

  if (loadingCurrentValues || loadingCurrentValuesDebounced) return null

  return (
    <div
      css={{
        position: 'relative',
        color: 'white',
        backgroundColor: colors.midnight,
        fontSize: 14,
        fontWeight: 'bold',
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 22,
        border: `2px solid ${(isSelected && 'yellow') || (isHighlighted && colors.belowCanopy) || 'white'}`,
        padding: '0 16px',
        flexDirection: 'column',
        top: USE_EXPANDER && expanded ? 198 : 0,
        left: USE_EXPANDER && expanded ? 20 : 0,
        maxWidth: USE_EXPANDER ? 300 : 220,
        // opacity: somePropertiesAreHighlighted && !isHighlighted ? 0.2 : 1,
        ...props.valueTypesToPropsDictionary?.[String(valueType)]?.getContainerCSS?.(),
      }}
      ref={ref}
      onClick={onPropertyClick}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {!!props.valueTypesToPropsDictionary?.[String(valueType)]?.onHover &&
        (isHovered || showMapBadgeNames) && <Label bottom={height + 3}>{props.meta.propertyName}</Label>}
      <div
        css={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 40,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
        // onClick={(e) => {
        //   e.stopPropagation()

        //   setIsExpanded((s) => !s.valueOf())
        // }}
      >
        {USE_EXPANDER && <ExpandableChevronIndicator expanded={expanded} stroke={false} />}
        <div css={{ marginLeft: USE_EXPANDER ? 12 : 0, width: '100%' }}>
          {props.valueTypesToPropsDictionary?.[String(valueType)]?.children ?? props.meta.propertyName}
        </div>
      </div>
      <div
        css={{
          height: USE_EXPANDER && expanded ? 160 : 0,
          width: USE_EXPANDER && expanded ? '100%' : '0%',
          overflow: 'hidden',
          display: 'flex',
        }}
      >
        {USE_EXPANDER && expanded && (
          // <>
          //   <ForecastTile
          //     dateHeadingText={t('Today')}
          //     humidity={68.2}
          //     precipitation={22.1}
          //     temperatureMax={25}
          //     temperatureMin={7}
          //     weatherIcon="wi-cloudy"
          //     windDirection={155}
          //     windSpeed={3}
          //   />
          //   <ForecastTile
          //     dateHeadingText={t('Tomorrow')}
          //     humidity={68.2}
          //     precipitation={22.1}
          //     temperatureMax={25}
          //     temperatureMin={7}
          //     weatherIcon="wi-cloudy"
          //     windDirection={155}
          //     windSpeed={3}
          //   />
          // </>
          <div
            className={css`
              display: grid;
              grid-template-columns: auto repeat(7, 1fr);
              grid-template-rows: repeat(4, 1fr);
              column-gap: 12px;
              row-gap: 4px;
              justify-items: center;
              padding: 6px;
            `}
          >
            <div
              className={css`
                grid-area: 4 / 1;
                justify-self: start;
              `}
            >
              {translate.phrases.abbreviations('High')}
            </div>
            <div
              className={css`
                grid-area: 5 / 1;
                justify-self: start;
              `}
            >
              {translate.phrases.abbreviations('Low')}
            </div>
            {Array.from({ length: 7 }, (_, i) => {
              const day = moment.tz().startOf('day').add(i, 'day')

              return (
                <React.Fragment key={i}>
                  <div
                    className={css`
                      grid-area: ${1} / ${i + 2};
                    `}
                  >
                    {translate.dates.format(day, 'ddd')}
                  </div>
                  <div
                    className={css`
                      grid-area: ${2} / ${i + 2};
                      font-size: 11px;
                    `}
                  >
                    {translate.dates.format(day, 'MMM D')}
                  </div>
                  <div
                    className={css`
                      grid-area: ${3} / ${i + 2};
                    `}
                  >
                    <FontAwesomeIcon icon={faCloudSun} />
                  </div>
                  <div
                    className={css`
                      grid-area: ${4} / ${i + 2};
                    `}
                  >
                    {`${80 + i}°F`}
                  </div>
                  <div
                    className={css`
                      grid-area: ${5} / ${i + 2};
                    `}
                  >
                    {`${71 + i}°F`}
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
