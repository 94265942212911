import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconHazeDay = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 388 310')}>
      <g clipPath="url(#clip0_11_491)">
        <mask
          id="mask0_11_491"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="388"
          height="208"
        >
          <path d="M388 0H0V208H388V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_11_491)">
          <g clipPath="url(#clip1_11_491)">
            <path
              d="M194 278C240.392 278 278 240.392 278 194C278 147.608 240.392 110 194 110C147.608 110 110 147.608 110 194C110 240.392 147.608 278 194 278Z"
              fill="url(#paint0_linear_11_491)"
              stroke="#F8AF18"
              strokeWidth="6"
              strokeMiterlimit="10"
            />
            <path
              d="M194 63.66V14M194 374V324.34M286.17 101.83L321.28 66.72M66.72 321.28L101.83 286.17M101.83 101.83L66.72 66.72M321.28 321.28L286.17 286.17M63.66 194H14M374 194H324.34"
              stroke="#FBBF24"
              strokeWidth="24"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
        </g>
        <g clipPath="url(#clip2_11_491)">
          <path
            d="M74 298H104"
            stroke="url(#paint1_linear_11_491)"
            strokeWidth="24"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M164 298H254"
            stroke="url(#paint2_linear_11_491)"
            strokeWidth="24"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeDasharray="60 60"
          />
          <path
            d="M284 298H314"
            stroke="url(#paint3_linear_11_491)"
            strokeWidth="24"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M74 250H104"
            stroke="url(#paint4_linear_11_491)"
            strokeWidth="24"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M164 250H254"
            stroke="url(#paint5_linear_11_491)"
            strokeWidth="24"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeDasharray="60 60"
          />
          <path
            d="M284 250H314"
            stroke="url(#paint6_linear_11_491)"
            strokeWidth="24"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_491"
          x1="151.99"
          y1="121.24"
          x2="236.01"
          y2="266.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBBF24" />
          <stop offset="0.45" stopColor="#FBBF24" />
          <stop offset="1" stopColor="#F59E0B" />
        </linearGradient>
        <linearGradient id="paint1_linear_11_491" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint2_linear_11_491" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint3_linear_11_491" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint4_linear_11_491" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint5_linear_11_491" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint6_linear_11_491" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <clipPath id="clip0_11_491">
          <rect width="388" height="310" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_491">
          <rect width="384" height="384" fill="white" transform="translate(2 2)" />
        </clipPath>
        <clipPath id="clip2_11_491">
          <rect width="264" height="72" fill="white" transform="translate(62 238)" />
        </clipPath>
      </defs>
    </svg>
  )
}
