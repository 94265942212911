import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'

const makePolygonalLatLngsFromGeoJson = (inputGeoJSON: SharedTypes.LandingPage.TGeoJson) => {
  const polygonalCoordinates: google.maps.LatLng[][] = []

  const processGeoJsonCoordinates = ({ type, coordinates }: SharedTypes.LandingPage.TGeoJson) => {
    if (type === 'Polygon') {
      coordinates
        .map((perimeter) => perimeter.map(([lng, lat]) => new window.google.maps.LatLng({ lng, lat })))
        .forEach((paths) => polygonalCoordinates.push(paths))
    } else if (type === 'MultiPolygon') {
      coordinates.forEach((polygonCoordinates) => {
        processGeoJsonCoordinates({ coordinates: polygonCoordinates, type: 'Polygon' })
      })
    } else {
      // TODO: errorLogger
    }
  }

  processGeoJsonCoordinates(inputGeoJSON)

  return polygonalCoordinates
}

export const buildOverlay = ({
  multiPolygonGeoJSONString,
  map,
}: {
  multiPolygonGeoJSONString: string
  map: google.maps.Map | null
}) => {
  if (!window?.google?.maps) return null

  const multiPolygonOrPolygon = JSON.parse(multiPolygonGeoJSONString) as
    | (SharedTypes.LandingPage.TGeoJson & { type: 'MultiPolygon' })
    | (SharedTypes.LandingPage.TGeoJson & { type: 'Polygon' })

  const latLngs = makePolygonalLatLngsFromGeoJson(multiPolygonOrPolygon)

  const polylines: google.maps.Polyline[] = latLngs.map((latLng) => {
    return new window.google.maps.Polyline({ path: latLng, map })
  })

  return {
    show: () => {
      polylines.forEach((pl) =>
        pl.setOptions({
          map,
          strokeColor: 'white',
          strokeOpacity: 0,
          strokeWeight: 0,
          icons: [
            {
              icon: {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                scale: 3,
              },
              offset: '0',
              repeat: '15px',
            },
          ],
        }),
      )
    },
    hide: () => {
      polylines.forEach((pl) => pl.setMap(null))
    },
  }
}
