import moment from 'moment-timezone'
import type { TIrrigationSchedulerStoreState } from '../irrigationSchedulerStore'
import { irrigationSchedulerStore } from '../irrigationSchedulerStore'

export const updateMostRecentApiResponseTimestamp = (
  key: keyof TIrrigationSchedulerStoreState['mostRecentApiResponseTimestamps'],
): void => {
  irrigationSchedulerStore.setState((isss) => ({
    ...isss,
    mostRecentApiResponseTimestamps: {
      ...isss.mostRecentApiResponseTimestamps,
      [key]: moment.tz().toISOString(),
    },
  }))
}
