import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import type { TActiveNode, TPlannedNode } from 'App/ServiceCenter/types'
import { TRAP_NODE_TYPES } from 'App/ServiceCenter/utils/constants/nodeType'
import { translate } from 'i18n/i18n'
import React from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const TargetPest = () => {
  const selectedNode = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentNode)
  const allInsects = fieldAssetStore.useSelector((s) => s.insects)

  if (!selectedNode || !allInsects || !(selectedNode as TActiveNode)?.nodeType) return null

  let targetInsectId = null

  if ('pseudonodeId' in selectedNode) {
    targetInsectId = (selectedNode as TPlannedNode).insectId
  } else {
    targetInsectId = (selectedNode as TActiveNode).trap?.targetInsectId
  }

  const nodeType = (selectedNode as TActiveNode | TPlannedNode).nodeType.includes('dn') ? 'DN' : 'SN'
  const targetInsect = targetInsectId ? allInsects[targetInsectId]?.name : null

  const text = targetInsect
    ? `${nodeType} Trap: ${translate.phrases.placeholder('Target Pest ')}${targetInsect}`
    : `${translate.phrases.placeholder('Target Pest')} N/A`

  return (
    <>
      {TRAP_NODE_TYPES.includes((selectedNode as TActiveNode | TPlannedNode).nodeType) && (
        <div css={{ marginTop: 20 }}>
          <h4 css={{ fontWeight: targetInsect ? 500 : 400 }}>{text}</h4>
        </div>
      )}
    </>
  )
}
