import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconInfoLight = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 13 13')}>
      <circle cx="6.5" cy="6.5" r="6" stroke="currentColor" />
      <path
        d="M7.30457 4.65302V9.80414H5.69543V4.65302H7.30457ZM5.60022 3.32477C5.60022 3.09625 5.68274 2.909 5.84778 2.763C6.01282 2.617 6.22705 2.54401 6.49048 2.54401C6.75391 2.54401 6.96814 2.617 7.13318 2.763C7.29822 2.909 7.38074 3.09625 7.38074 3.32477C7.38074 3.55328 7.29822 3.74054 7.13318 3.88654C6.96814 4.03253 6.75391 4.10553 6.49048 4.10553C6.22705 4.10553 6.01282 4.03253 5.84778 3.88654C5.68274 3.74054 5.60022 3.55328 5.60022 3.32477Z"
        fill="currentColor"
      />
    </svg>
  )
}
