import { useMantineTheme } from '@mantine/core'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { TModalDrawerStep, TNewPresetState } from '../Presets'
import { newPresetInitialStateMaker } from './newPresetInitialStateMaker'

export const useModalStepFormState = ({
  newPresetState,
  onSuccess,
  opened,
  setModalDrawerOpen,
  setModalDrawerStep,
  setNewPresetState,
  validator,
}: {
  newPresetState: TNewPresetState
  onSuccess: () => void
  opened: boolean
  setModalDrawerOpen: Dispatch<SetStateAction<boolean>>
  setModalDrawerStep: Dispatch<SetStateAction<TModalDrawerStep>>
  setNewPresetState: Dispatch<SetStateAction<TNewPresetState>>
  validator: (newPresetState: TNewPresetState) => boolean
}) => {
  const [attemptedToProceedToNext, setAttemptedToProceedToNext] = useState(false)
  const [showErrorsIfAny, setShowErrorsIfAny] = useState(false)
  const isValid = validator(newPresetState)
  const theme = useMantineTheme()
  const showErrors = showErrorsIfAny && !isValid

  useEffect(() => {
    setAttemptedToProceedToNext(false)

    setShowErrorsIfAny(false)
  }, [opened])

  return {
    primaryButtonDisabled: attemptedToProceedToNext && !isValid,
    showErrors,
    onClose: () => {
      setModalDrawerOpen(false)

      setModalDrawerStep(1)

      setNewPresetState(newPresetInitialStateMaker)
    },
    errorColor: theme.colors.red[0],
    primaryButtonOnPress: () => {
      setAttemptedToProceedToNext(true)

      if (isValid) {
        onSuccess()
      } else {
        setShowErrorsIfAny(true)
      }
    },
  }
}
