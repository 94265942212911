import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { WeatherForecastType } from '../ModalDrawerCreateOrEditAlert/ModalDrawerCreateOrEditAlert'

type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11

export type TAlertRuleKey =
  | 'alternaria'
  | 'aboveTemperature'
  | 'belowTemperature'
  | 'degreeDays'
  | 'degreeDaysForecast'
  | 'dewPoint'
  | 'fireBlight'
  | 'fireBlightForecast'
  | 'cultivarId'
  | 'daysBefore'
  | 'hours'
  | 'includeAllDevices'
  | 'inversion'
  | 'leafWetness'
  | 'numberOfDays'
  | 'pestType'
  | 'startDate'
  | 'temperature'
  | 'trapCatches'
  | 'wetBulb'
  | 'windSpeed'
  | 'windDirection'
  | 'hourOfDay'
  | 'windGreaterThanOrEqualToKph'
  | 'hasRain'
  | 'temperatureGreaterThanOrEqualToCelsius'
  | 'temperatureLessThanOrEqualToCelsius'

export type Rule = {
  key: TAlertRuleKey
  operator: string | null
  value: number | string | null | boolean | string[]
  unit?: string
}

type DefaultSettingsReturner = {
  sleepMinutes: number | null
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  frequencyMinutes: number | null
  rules: Rule[]
}

export type AlertTypeId =
  | 'almondHullSplit'
  | 'alternaria'
  | 'degreeDays'
  | 'fireBlight'
  | 'frost'
  | 'leafWetness'
  | 'temperature'
  | 'trapCatches'
  | 'weatherForecastAlert'
  | 'wetBulb'

export type AlertsSettingsType = {
  alertTypes: AlertTypeId[]
  alertTypesWithPestTypes: { [key in AlertTypeId]: boolean }
  allowSleep: { [key in AlertTypeId]: boolean }
  allowScheduling: { [key in AlertTypeId]: boolean }
  allowCoverageProperties: { [key in AlertTypeId]: boolean }
  allowCoverageStations: { [key in AlertTypeId]: boolean }
  allowCoverageBlocks: { [key in AlertTypeId]: boolean }
  automatedScheduling: { [key in AlertTypeId]: boolean }
  defaultSettings:
    | { [key in AlertTypeId]: () => DefaultSettingsReturner }
    | {
        ['trapCatches']: ({ insectId }: { insectId: number | null }) => DefaultSettingsReturner | null
      }
  emptyRules: {
    [key in AlertTypeId]: Rule[]
  }
  frequencyMinutesDefault: { [key in AlertTypeId | 'degreeDaysForecast' | 'fireBlightForecast']: number }
  frequencyMinutesOptions: { [key in AlertTypeId]: number[] }
  stationTypeKey: { [key in AlertTypeId]: string }
  includeAllDevices: { [key in AlertTypeId]: boolean }
  pestTypeKey: { [key in AlertTypeId]: string | null }
  weatherForecastAlertThresholdItems: { [key: string]: WeatherForecastType }
}

export const userIsCelsius = () => {
  const { temperature } = userDetailsStore.getState()

  return temperature === 'METRIC'
}

export const unitSpeedDisplay = () => {
  const { speed } = userDetailsStore.getState()

  return speed === 'METRIC'
    ? translate.measurements.kilometersPerHour.unit()
    : translate.measurements.milesPerHour.unit()
}

export const unitTempDisplay = () =>
  userIsCelsius()
    ? translate.measurements.degreesCelsius.unit()
    : translate.measurements.degreesFahrenheit.unit()

export const unitTempDisplayDD = () =>
  userIsCelsius()
    ? translate.measurements.degreeDaysCelsius.unit()
    : translate.measurements.degreeDaysFahrenheit.unit()

// frequencyValues
const frequency10To360 = [10, 30, 60, 180, 360]
const frequency60To360 = [60, 180, 360]
const frequencyNone: number[] = []
const tenMinutely = 10
const hourly = 60
const daily = 1440
const start = (month: Month) => moment.tz('America/Los_Angeles').month(month).startOf('month')
const end = (month: Month) => moment.tz('America/Los_Angeles').month(month).endOf('month')
/* eslint-disable @typescript-eslint/no-unused-vars */
const january = 0
const february = 1
const march = 2
const april = 3
const may = 4
const june = 5
const july = 6
const august = 7
const september = 8
const october = 9
const november = 10
const december = 11

// TODO: Since this is annoying to maintain - would suggest next time we add a pest here
// we instead consider refactoring to use permissions or value types instead (i.e. config that already exists)
export const PEST_TYPES_WITH_TRAP_CATCH_ALERTS: Record<number, boolean> = {
  1: true, // CM - Codling Moth
  2: true, // EGVM - European Grapevine Moth (AKA Lobesia)
  6: true, // NOW - Navel Orangeworm
  7: true, // OFM - Oriental Fruit Moth
  8: true, // ACM - Apple Clearwing Moth
  9: true, // PTWB - Peach Twig Borer
  10: true, // CRS - California Red Scale
  11: true, // OBLR - Obliquebanded Leafroller
  12: true, // PLR - Pandemis Leafroller
  15: true, // WHF - Walnut Husk Fly
  16: true, // OLR - Omnivorous Leafroller
  17: true, // CCW - Citrus Cutworm
  19: true, // VMB - Vine Mealybug
  20: true, // CARO - Carob Moth
  25: true, // LBAM - Light Brown Apple Moth
  26: true, // CAPURE - Summer Fruit Tortrix
  27: true, // PANDHE - Apple Brown Tortrix
  28: true, // OT - Orange Tortrix
  29: true, // PNCBM - Pecan Nut Casebearer Mexican Strain
  30: true, // PBM - Pecan Nut Casebearer Mexican Strain
}

export const AlertsSettings: AlertsSettingsType = {
  alertTypes: [
    'almondHullSplit',
    'alternaria',
    'degreeDays',
    'fireBlight',
    'frost',
    'leafWetness',
    'temperature',
    'trapCatches',
    'weatherForecastAlert',
    'wetBulb',
  ],
  alertTypesWithPestTypes: {
    almondHullSplit: false,
    alternaria: false,
    degreeDays: true,
    fireBlight: false,
    frost: false,
    leafWetness: false,
    temperature: false,
    trapCatches: true,
    wetBulb: false,
    weatherForecastAlert: false,
  },
  allowSleep: {
    almondHullSplit: false,
    alternaria: true,
    degreeDays: false,
    fireBlight: true,
    frost: true,
    leafWetness: true,
    temperature: true,
    trapCatches: true,
    wetBulb: true,
    weatherForecastAlert: false,
  },
  allowScheduling: {
    almondHullSplit: false,
    alternaria: true,
    degreeDays: true,
    fireBlight: true,
    frost: true,
    leafWetness: true,
    temperature: true,
    trapCatches: true,
    weatherForecastAlert: true,
    wetBulb: true,
  },
  allowCoverageProperties: {
    almondHullSplit: true,
    alternaria: true,
    degreeDays: true,
    fireBlight: true,
    frost: true,
    leafWetness: true,
    temperature: true,
    trapCatches: true,
    weatherForecastAlert: true,
    wetBulb: true,
  },
  allowCoverageStations: {
    almondHullSplit: true,
    alternaria: true,
    degreeDays: false, // can only select blocks
    fireBlight: true,
    frost: true,
    leafWetness: true,
    temperature: true,
    trapCatches: false, // can't select something more granular than property
    weatherForecastAlert: true,
    wetBulb: true,
  },
  allowCoverageBlocks: {
    almondHullSplit: false,
    alternaria: false,
    degreeDays: true, // degreeDays is the only alert that's block-only for now
    fireBlight: false,
    frost: true, // although blocks is selectable, it won't be used in practice without includeAllDevices being set
    leafWetness: false,
    temperature: true, // although blocks is selectable, it won't be used in practice without includeAllDevices being set
    trapCatches: false,
    weatherForecastAlert: false,
    wetBulb: true, // although blocks is selectable, it won't be used in practice without includeAllDevices being set
  },
  automatedScheduling: {
    almondHullSplit: false,
    alternaria: false,
    degreeDays: true, // the only one
    fireBlight: false,
    frost: false,
    leafWetness: false,
    temperature: false,
    trapCatches: false,
    wetBulb: false,
    weatherForecastAlert: false,
  },
  defaultSettings: {
    frost: () => ({
      frequencyMinutes: 10,
      startDate: start(september),
      endDate: end(may),
      sleepMinutes: 60 * 12, // 12 hours
      rules: [
        {
          key: 'belowTemperature',
          operator: '<=',
          unit: 'temperature',
          value: userIsCelsius() ? 2 : 35.6,
        },
        {
          key: 'inversion',
          operator: '>=',
          unit: 'inversion',
          value: userIsCelsius() ? 1 : 1.8,
        },
        {
          key: 'includeAllDevices',
          operator: '=',
          value: 'false',
        },
        {
          key: 'windSpeed',
          operator: '>=',
          value: 0,
          unit: 'speed',
        },
        {
          key: 'windDirection',
          operator: '>=',
          value: 0,
          unit: 'direction',
        },
      ],
    }),
    alternaria: () => ({
      frequencyMinutes: 1440,
      startDate: start(march),
      endDate: end(october),
      sleepMinutes: 60 * 168, // 7 days
      rules: [
        {
          key: 'alternaria',
          operator: '>=',
          value: 8,
        },
        {
          key: 'numberOfDays',
          operator: '=',
          value: 7,
        },
      ],
    }),
    fireBlight: () => ({
      frequencyMinutes: 60,
      startDate: start(january),
      endDate: end(october),
      sleepMinutes: 60 * 24, // 1 day
      rules: [
        {
          key: 'fireBlightForecast',
          operator: '>=',
          value: 200,
          unit: 'TRV',
        },
      ],
    }),
    leafWetness: () => ({
      frequencyMinutes: 60,
      startDate: start(march),
      endDate: end(december),
      sleepMinutes: null,
      rules: [
        {
          key: 'leafWetness',
          operator: '>=',
          value: 2,
        },
        {
          key: 'hours',
          operator: '>=',
          value: 8,
        },
      ],
    }),
    trapCatches: ({ insectId }: { insectId: number | null }) => {
      if (insectId !== 1 && insectId !== 6) return null

      let returner: DefaultSettingsReturner = {
        sleepMinutes: null,
        startDate: null,
        endDate: null,
        frequencyMinutes: null,
        rules: [],
      }

      if (insectId === 1) {
        returner.sleepMinutes = null

        returner.startDate = start(march)

        returner.endDate = end(october)

        returner.rules = [
          {
            key: 'trapCatches',
            operator: '>=',
            value: 4,
          },
          {
            key: 'numberOfDays',
            operator: '=',
            value: 7,
          },
        ]
      }

      if (insectId === 6) {
        returner.sleepMinutes = null

        returner.startDate = start(april)

        returner.endDate = end(november)

        returner.rules = [
          {
            key: 'trapCatches',
            operator: '>=',
            value: 4,
          },
          {
            key: 'numberOfDays',
            operator: '=',
            value: 1,
          },
        ]
      }

      return returner
    },
  },
  emptyRules: {
    almondHullSplit: [
      {
        key: 'cultivarId',
        operator: '=',
        value: null,
      },
      {
        key: 'daysBefore',
        operator: '=',
        value: null,
      },
    ],
    alternaria: [
      {
        key: 'alternaria',
        operator: '>=',
        value: null,
      },
      {
        key: 'numberOfDays',
        operator: '=',
        value: null,
      },
    ],
    degreeDays: [
      {
        key: 'degreeDays',
        operator: '>=',
        unit: 'inversion',
        value: null,
      },
      {
        key: 'startDate',
        operator: '=',
        value: 'default',
      },
    ],
    fireBlight: [
      {
        key: 'fireBlight',
        operator: '>=',
        unit: 'TRV',
        value: null,
      },
    ],
    frost: [
      {
        key: 'belowTemperature',
        operator: '<=',
        unit: 'temperature',
        value: null,
      },
      {
        key: 'includeAllDevices',
        operator: '=',
        value: 'false',
      },
      {
        key: 'windSpeed',
        operator: '>=',
        value: 0,
        unit: 'speed',
      },
      {
        key: 'windDirection',
        operator: '>=',
        value: 0,
        unit: 'direction',
      },
    ],
    leafWetness: [
      {
        key: 'leafWetness',
        operator: '>=',
        value: null,
      },
      {
        key: 'hours',
        operator: '>=',
        value: null,
      },
    ],
    temperature: [
      {
        key: 'belowTemperature',
        operator: '>=',
        unit: 'temperature',
        value: null,
      },
      {
        key: 'aboveTemperature',
        operator: '>=',
        unit: 'temperature',
        value: null,
      },
      {
        key: 'includeAllDevices',
        operator: '=',
        value: 'false',
      },
    ],
    trapCatches: [
      {
        key: 'trapCatches',
        operator: '>=',
        value: null,
      },
      {
        key: 'numberOfDays',
        operator: '=',
        value: null,
      },
    ],
    weatherForecastAlert: [
      {
        key: 'hourOfDay',
        operator: '=',
        value: '06',
      },
    ],
    wetBulb: [
      {
        key: 'wetBulb',
        operator: '<=',
        unit: 'temperature',
        value: null,
      },
      {
        key: 'includeAllDevices',
        operator: '=',
        value: 'false',
      },
      {
        key: 'windSpeed',
        operator: '>=',
        value: 0,
        unit: 'speed',
      },
      {
        key: 'windDirection',
        operator: '>=',
        value: 0,
        unit: 'direction',
      },
    ],
  },
  frequencyMinutesDefault: {
    almondHullSplit: daily,
    alternaria: daily,
    degreeDays: daily,
    fireBlight: hourly,
    frost: tenMinutely,
    leafWetness: hourly,
    temperature: tenMinutely,
    trapCatches: daily,
    weatherForecastAlert: daily,
    wetBulb: tenMinutely,

    // oddball cases
    degreeDaysForecast: daily,
    fireBlightForecast: daily,
  },
  frequencyMinutesOptions: {
    almondHullSplit: frequencyNone,
    alternaria: frequencyNone,
    degreeDays: frequencyNone,
    fireBlight: frequency60To360,
    frost: frequency10To360,
    leafWetness: frequencyNone,
    temperature: frequency10To360,
    trapCatches: frequencyNone,
    weatherForecastAlert: frequencyNone,
    wetBulb: frequency10To360,
  },
  stationTypeKey: {
    almondHullSplit: 'temperature_in',
    alternaria: 'leafWetness',
    degreeDays: 'temperature_in',
    fireBlight: 'leafWetness',
    frost: 'temperature_in',
    leafWetness: 'leafWetness',
    temperature: 'temperature_in',
    weatherForecastAlert: 'temperature_in',
    trapCatches: '',
    wetBulb: 'temperature_in',
  },
  pestTypeKey: {
    almondHullSplit: null,
    alternaria: null,
    degreeDays: 'degreeDays',
    fireBlight: null,
    frost: null,
    leafWetness: null,
    temperature: null,
    trapCatches: 'insectTrapCatches',
    weatherForecastAlert: null,
    wetBulb: null,
  },
  // whether or not the includeAllDevices checkbox will show up on the coverage form
  includeAllDevices: {
    almondHullSplit: false,
    alternaria: false,
    degreeDays: false,
    fireBlight: false,
    frost: true,
    leafWetness: false,
    temperature: true,
    trapCatches: false,
    weatherForecastAlert: true,
    wetBulb: true,
  },
  weatherForecastAlertThresholdItems: {
    WIND: 'wind',
    RAIN: 'rain',
    TEMPERATURE: 'temperature',
  },
}
