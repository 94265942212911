import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const updateUserProfile = async (
  paramsToUpdate: Partial<routes.UpdateUserProfile.Request>,
): Promise<boolean> => {
  const params: routes.UpdateUserProfile.Request = paramsToUpdate

  const response = apiFetch<routes.UpdateUserProfile.Request, routes.UpdateUserProfile.Response>({
    url: routes.UpdateUserProfile.path,
    body: params,
  })

  return response
}
