import { isEqual as lodashIsEqual } from 'lodash'
import { useSyncExternalStoreWithSelector } from './use-sync-with-selector'

const createStore = <State>(initialState: State) => {
  let state = initialState

  const listeners = new Set<(state: State, previousState: State) => void>()
  const getState = () => state

  const setState = (setStateAction: (previousState: State) => State) => {
    const previousState = state

    state = setStateAction(state)

    listeners.forEach((listener) => listener(state, previousState))
  }

  const subscribe = (listener: (state: State, previousState: State) => void) => {
    listeners.add(listener)

    return () => listeners.delete(listener)
  }

  /**
   * Add listener for store state changes.
   * For most use-cases use the useSelector function.
   * // TODO: Sometimes I've been using this but ignoring the state slice args for the onChange, and instead calling smallStore.getState after to look at some other state... Think about this api...
   * @param select Select which state components to listen to
   * @param onChange Callback function called when selected state changes
   * @param isEqual Comparator function for determining state change
   */
  const subscribeToChanges = <Selection>(
    select: (state: State) => Selection,
    onChange: (nextStateSlice: Selection, stateSlice: Selection) => void,
    isEqual: (a: Selection, b: Selection) => boolean = lodashIsEqual,
  ) => {
    const handleChange = (nextState: State, previousState: State) => {
      const nextStateSlice = select(nextState)
      const prevStateSlice = select(previousState)

      // if no change, do nothing
      if (isEqual(nextStateSlice, prevStateSlice)) return

      // otherwise, fire the onChange
      onChange(nextStateSlice, prevStateSlice)
    }

    return subscribe(handleChange)
  }

  return {
    getState,
    setState,
    subscribe,
    subscribeToChanges,
  }
}

export const init = <STATE>(initialState: STATE) => {
  const store = createStore<STATE>(initialState)

  /**
   * State listener for React components
   * @param selector State callback function, passes the current store state
   * @param isEqual State comparator, for determining state changes
   */
  const useSelector = <Selection>(
    selector: (state: STATE) => Selection,
    isEqual: (a: Selection, b: Selection) => boolean = lodashIsEqual,
  ) => {
    return useSyncExternalStoreWithSelector(store.subscribe, store.getState, selector, isEqual)
  }

  // TODO: FYI here's a selector without memoization
  // const useSelector = <Selection>(selector: (state: ReturnType<typeof store.getState>) => Selection) => {
  //   return useSyncExternalStore(store.subscribe, () => selector(store.getState()))
  // }

  return {
    ...store,
    useSelector,
  }
}
