import { Checkbox } from '@mantine/core'
import { closeModal, openModal } from '@mantine/modals'
import { Button } from 'components/Button/Button'
import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import { translate } from 'i18n/i18n'
import type { ReactElement } from 'react'
import React from 'react'

export function showModalWithCachedCheckbox(param: {
  title: string
  DescriptionElement: ReactElement
  key: string
  onOk: () => void
}) {
  const { title, DescriptionElement, key, onOk } = param
  const DIALOG_ID = key

  openModal({
    modalId: DIALOG_ID,
    fullScreen: false,
    withCloseButton: false,
    centered: true,
    title: null,
    padding: 0,
    children: (
      <>
        <div css={{ padding: '24px', position: 'relative' }}>
          <div css={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px' }}>
            <span onClick={() => closeModal(DIALOG_ID)}>
              <CloseIcon />
            </span>
            <span css={{ marginLeft: '16px', display: 'inline-block' }}>
              {translate.phrases.placeholder(title)}
            </span>
          </div>
          {DescriptionElement}
          <Checkbox
            size={'md'}
            onChange={(event) => {
              event.target.checked ? localStorage.setItem(key, 'true') : localStorage.removeItem(key)
            }}
            css={{ fontWeight: 'bold', marginTop: '16px' }}
            label={translate.phrases.placeholder('Don’t show this again')}
          />
        </div>
        <div
          css={{
            position: 'relative',
            bottom: 0,
            left: 0,
            backgroundColor: '#F8F8F8',
            borderTop: '2px solid #F8F8F8',
            width: '100%',
            padding: 10,
            paddingBottom: 'max(10px, env(safe-area-inset-bottom))',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 10,
          }}
        >
          <Button
            variant="secondary"
            css={{ flex: '0 0 auto' }}
            onClick={() => {
              closeModal(DIALOG_ID)
            }}
          >
            {translate.phrases.placeholder('Cancel')}
          </Button>
          <Button
            variant="primary"
            css={{ marginLeft: 10, flex: '0 0 auto' }}
            onClick={() => {
              closeModal(DIALOG_ID)

              onOk()
            }}
          >
            {translate.phrases.placeholder('Continue')}
          </Button>
        </div>
      </>
    ),
  })
}
