import { generateFlatNavigationList } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'
import { sortSections } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateNavigationMenu'
import { makePrettySectionName } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateNavigationMenuGroupedBySection'
import { useMemo } from 'react'
import { alphabeticalSort } from 'utils/alphabeticalSort'

export const useOptionsToRenderOut = () => {
  return useMemo(() => {
    const flatNavigationList = generateFlatNavigationList()

    return Object.entries(flatNavigationList)
      .flatMap(([navigationItemKey, values]) => {
        if (!values) return []

        /**
         * TODO: if no onToggleOn, it's a 'Coming Soon' layer. This might not
         * be true in the future, but <ComingSoon /> might be gone then too
         */
        if (!('onToggleOn' in values)) return []

        return {
          childLayers: values.childLayers,
          label: values.translatedTitle,
          sectionRaw: values.section,
          value: navigationItemKey,
        }
      })
      .sort((a, b) => sortSections(a.sectionRaw, b.sectionRaw) || alphabeticalSort(a.label, b.label))
      .map(({ childLayers, sectionRaw, label, value }) => ({
        childLayers,
        label: label,
        section: makePrettySectionName(sectionRaw),
        value: value,
      }))
  }, [])
}
