import { UnreadMessagesCountBadge } from 'App/ServiceCenter/UnreadMessagesCountBadge/UnreadMessagesCountBadge'
import React, { useEffect, useRef } from 'react'
import { colors } from 'settings/colors'
import { intercomStore } from 'stores/intercomStore'
import { smallStore } from 'stores/smallStore'
import { useScrollPosition } from 'utils/useScrollPosition'
import { useScreenSize } from '../../utils/useScreenSize'
import { CollapseButton } from './CollapseButton/CollapseButton'
import { UpperLogoLine } from './UpperLogoLine/UpperLogoLine'

interface SideNavProps {
  collapsed: boolean
  onToggleExpand: () => void
  children: React.ReactNode
}

export const SideNav: React.FC<SideNavProps> = ({ collapsed, onToggleExpand, children }) => {
  const { isWideScreen } = useScreenSize()
  const ref = useRef<HTMLDivElement>(null)
  const position = useScrollPosition(ref)
  const showDataSourceTooltip = smallStore.useSelector((s) => s.showDataSourceTooltip)
  const safeArea = smallStore.useSelector((s) => s.safeArea)
  const unreadMessageCount = intercomStore.useSelector((s) => s.unreadMessagesCount)

  useEffect(() => {
    if (showDataSourceTooltip && ref?.current) {
      ref.current.scrollTo({ top: position.y })
    }
  }, [showDataSourceTooltip])

  const getWidth = () => {
    if (isWideScreen || !collapsed) return 305

    if (collapsed && unreadMessageCount > 0) return 145

    return 115
  }

  const getMessageIconPosition = () => {
    if (isWideScreen && collapsed) return 260

    if (!collapsed) return 230

    return 105
  }

  return (
    <>
      <CollapseButton menuCollapsed={collapsed} onToggleExpand={onToggleExpand} />
      {/**
       * A translucent modal-mask'esque layer to disable all other
       * buttons on the screen when layer nav is opened on mobile view
       */}
      {!isWideScreen && !collapsed && (
        <div
          css={{
            position: 'fixed',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            overflow: 'hidden',
            zIndex: 5,
            // NOTE: the next two properties are set to match Mantine's default modal mask
            backgroundColor: colors.midnight,
            opacity: 0.5,
          }}
          onClick={onToggleExpand}
        />
      )}
      <div
        ref={ref}
        css={{
          position: 'fixed',
          top: safeArea.insets.top + 5,
          left: safeArea.insets.left + (collapsed ? 0 : 5),
          bottom: safeArea.insets.bottom + (collapsed ? 0 : 5),
          width: getWidth(),
          borderRadius: collapsed ? '0 35px 35px 0px' : '10px 10px 10px 10px',
          height: collapsed ? 62 : 'auto',
          overflowX: 'hidden',
          overflowY: collapsed || showDataSourceTooltip ? 'hidden' : 'auto',
          color: 'white',
          transition: '200ms ease all',
          zIndex: 100,
          backgroundColor: colors.midnight,
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
          paddingBottom: collapsed ? 0 : 20,
        }}
      >
        <UpperLogoLine menuCollapsed={collapsed} onToggleExpand={onToggleExpand} />
        <span style={{ position: 'fixed', top: 27, zIndex: 10000, left: getMessageIconPosition() }}>
          <UnreadMessagesCountBadge />
        </span>
        {!collapsed && <div css={{ padding: '0px 20px', marginTop: -8 }}>{children}</div>}
      </div>
    </>
  )
}
