import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconInfo = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <path
        d="M11.2378 8.0752V16H8.76221V8.0752H11.2378ZM8.61572 6.03174C8.61572 5.68018 8.74268 5.39209 8.99658 5.16748C9.25049 4.94287 9.58008 4.83057 9.98535 4.83057C10.3906 4.83057 10.7202 4.94287 10.9741 5.16748C11.228 5.39209 11.355 5.68018 11.355 6.03174C11.355 6.3833 11.228 6.67139 10.9741 6.896C10.7202 7.12061 10.3906 7.23291 9.98535 7.23291C9.58008 7.23291 9.25049 7.12061 8.99658 6.896C8.74268 6.67139 8.61572 6.3833 8.61572 6.03174Z"
        fill="white"
      />
    </svg>
  )
}
