import { useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { TOptions } from '../../../MultiSelect.types'

const MenuButton = ({ buttonText, onClick }: { buttonText: string; onClick: () => void }) => (
  <div css={{ textDecoration: 'underline', padding: '0 5px' }} onClick={onClick}>
    {buttonText}
  </div>
)

export const ActionButtons = ({
  hideClearAll,
  hideSelectAll,
  selectedValues,
  setSelectedValues,
  onChange,
  options,
}: {
  hideClearAll?: boolean
  hideSelectAll?: boolean
  selectedValues: TOptions[]
  setSelectedValues: (selectedValues: TOptions[]) => void
  onChange: (selectedValues: TOptions[]) => void
  options: TOptions[]
}) => {
  const theme = useMantineTheme()

  const selectedOptionsThatAreVisible = selectedValues.filter((selectedValue) =>
    options.some((option) => selectedValue.value === option.value),
  )

  const selectedOptionsThatAreNotVisible = selectedValues.filter(
    (selectedValue) => !options.some((option) => selectedValue.value === option.value),
  )

  const showSelectAllButton = !hideSelectAll && selectedOptionsThatAreVisible.length !== options.length
  const showClearAllButton = !hideClearAll && selectedOptionsThatAreVisible.length > 0

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '10px 10px 0',
        fontSize: 14,
        cursor: 'pointer',
        color: theme.colors.primary[0],
      }}
    >
      {showSelectAllButton && (
        <MenuButton
          onClick={() => {
            const filteredOptions = selectedOptionsThatAreNotVisible.concat(options)

            onChange(filteredOptions)

            setSelectedValues(filteredOptions)
          }}
          buttonText={translate.phrases.banyanApp('Select All')}
        />
      )}
      {showSelectAllButton && showClearAllButton && <span>{'|'}</span>}
      {showClearAllButton && (
        <MenuButton
          onClick={() => {
            onChange(selectedOptionsThatAreNotVisible)

            setSelectedValues(selectedOptionsThatAreNotVisible)
          }}
          buttonText={translate.phrases.banyanApp('Clear All')}
        />
      )}
    </div>
  )
}
