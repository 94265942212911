import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconContact = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 35 35')}>
      <rect width="35" height="35" rx="17.5" fill="#464B53" />
      <path
        d="M17.5 17.5C19.8375 17.5 21.75 15.5875 21.75 13.25C21.75 10.9125 19.8375 9 17.5 9C15.1625 9 13.25 10.9125 13.25 13.25C13.25 15.5875 15.1625 17.5 17.5 17.5ZM26 24.7888C26 23.0675 24.98 21.5375 23.4075 20.8575C21.6012 20.0712 19.6038 19.625 17.5 19.625C15.3962 19.625 13.3988 20.0712 11.5925 20.8575C10.02 21.5375 9 23.0675 9 24.7888V26H26V24.7888Z"
        fill="white"
      />
    </svg>
  )
}
