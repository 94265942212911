import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconSun = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 22 23')}>
      <path
        d="M5.76 4.8398L3.96 3.0498L2.55 4.4598L4.34 6.2498L5.76 4.8398ZM0 10.4998H3V12.4998H0V10.4998ZM10 0.549805H12V3.4998H10V0.549805ZM18.04 3.0448L19.448 4.4518L17.658 6.2418L16.251 4.8338L18.04 3.0448ZM16.24 18.1598L18.03 19.9598L19.44 18.5498L17.64 16.7598L16.24 18.1598ZM19 10.4998H22V12.4998H19V10.4998ZM11 5.4998C7.69 5.4998 5 8.1898 5 11.4998C5 14.8098 7.69 17.4998 11 17.4998C14.31 17.4998 17 14.8098 17 11.4998C17 8.1898 14.31 5.4998 11 5.4998ZM11 15.4998C8.79 15.4998 7 13.7098 7 11.4998C7 9.2898 8.79 7.4998 11 7.4998C13.21 7.4998 15 9.2898 15 11.4998C15 13.7098 13.21 15.4998 11 15.4998ZM10 19.4998H12V22.4498H10V19.4998ZM2.55 18.5398L3.96 19.9498L5.75 18.1498L4.34 16.7398L2.55 18.5398Z"
        fill="currentColor"
      />
    </svg>
  )
}
