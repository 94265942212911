import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import type { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import type { SeriesOptionsType } from 'highcharts'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getCropNameFromId } from 'utils/getCropNameFromId'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../../_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { checkPermission, defaultValuesRequested, pointCategory } from '../plantStress'
import { PlantStressSettings } from './PlantStressSettings/PlantStressSettings'
import { PlantWaterStressLegend } from './PlantWaterStressLegend/PlantWaterStressLegend'
import { getPwsChartMinMax } from './_utils/getPwsChartMinMax'
import { getPwsZones } from './_utils/getPwsZones'

export const DEFAULT_MAX_LOW_STRESS = 1.75

export const DEFAULT_MIN_HIGH_STRESS = 2.75

export const plantWaterStress = ({
  data,
  compareSeasonsData,
  updateData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  updateData: (pathToSet: string, dataToSet: unknown) => void
}): StackedChartSection => {
  let commonReturnItems = {
    title: unitConverter.plantWaterStress().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
      />
    ),
    id: 'stackem-plant-stress',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const selectedFieldAssets = selectedFieldAssetsStore.getState()
  const stationLngLat = selectedFieldAssets[pointCategory] as TFieldAssetKeyTypes.TLngLat
  const series: SeriesOptionsType[] = []

  const cropId =
    fieldAssetStore.getState()?.properties?.[Number(selectedFieldAssets.property)]?.points?.[
      stationLngLat as TFieldAssetKeyTypes.TLngLat
    ]?.configuration.treeDendrometerCropId ?? null

  const metadata = data?.points?.[stationLngLat]?.values?.signalTS5?.[0]?.metadata
  const maxLowStress = metadata?.maxLowStress ?? DEFAULT_MAX_LOW_STRESS
  const minHighStress = metadata?.minHighStress ?? DEFAULT_MIN_HIGH_STRESS
  const timeseries = data?.points?.[stationLngLat]?.values?.signalTS5?.[0]?.timeseries ?? []
  const startOfToday = new Date()

  startOfToday.setHours(0, 0, 0, 0)

  const firstForecastTimestamp = +startOfToday

  const plantWaterStressSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.midnight,
    name: unitConverter.plantWaterStress().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.plantWaterStress().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.plantWaterStress().suffix()}`,
    },
    data: (data?.points?.[stationLngLat]?.values?.signalTS5?.[0]?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.plantWaterStress(d.value).value(),
    ]),
    type: 'line',
    id: 'stressValue',
  }

  series.push(plantWaterStressSeries)

  const zonesPwsValues = getPwsZones({ timeseries, maxLowStress, minHighStress })

  let zonesPwsSeries = Object.values(zonesPwsValues) as SeriesOptionsType[]

  if (compareSeasonsInterval) {
    const compareSeasonTimeSeries =
      compareSeasonsData?.points?.[stationLngLat]?.values?.signalTS5?.[0]?.timeseries ?? []

    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(plantWaterStressSeries, {
        data: compareSeasonTimeSeries.flatMap((d) => {
          const timestamp = +new Date(d.timestamp)
          const value = unitConverter.plantWaterStress(d.value).value()

          return [[+timestamp, value]]
        }),
      }),
    )

    const compareSeasonPwsValues = getPwsZones({
      timeseries: compareSeasonTimeSeries,
      maxLowStress,
      minHighStress,
    })

    zonesPwsSeries = Object.values(compareSeasonPwsValues) as SeriesOptionsType[]
  }

  series.push(...zonesPwsSeries)

  const isDaily =
    data?.points?.[stationLngLat]?.values?.signalTS5?.[0]?.metadata?.aggregationInterval === 'DAILY'

  const isDataAvailable = data?.points?.[stationLngLat]?.values?.signalTS5?.[0].timeseries.some(
    (d) => !isNil(d.value),
  )

  if (isDataAvailable) {
    commonReturnItems = {
      ...commonReturnItems,
      titleChildren: (
        <>
          <DropdownSelectorPoint
            pointCategory={pointCategory}
            valuesTimeseriesToFilterOn={Object.keys(defaultValuesRequested)}
          />
          <PlantStressSettings
            crop={getCropNameFromId(Number(cropId))}
            defaultMaxLowStress={maxLowStress}
            defaultMinHighStress={minHighStress}
            handleAfterSave={(selectedTreeDendrometerStation, maxLowStress, minHighStress) => {
              const valuesForPoints = data.points?.[selectedTreeDendrometerStation]?.values?.signalTS5?.[0]

              const updated = {
                ...valuesForPoints,
                metadata: {
                  ...valuesForPoints?.metadata,
                  maxLowStress,
                  minHighStress,
                },
              }

              const path = `points.["${selectedTreeDendrometerStation}"].values.signalTS5.0`

              updateData(path, updated)
            }}
          />
        </>
      ),
      id: 'stackem-plant-stress',
    }
  }

  const { yAxisMinToUse, yAxisMaxToUse } = getPwsChartMinMax({
    compareSeasonsData,
    data,
    treeDendrometerLngLat: stationLngLat,
  })

  return {
    ...commonReturnItems,
    title: translate.phrases.templates('{{labelA}} - {{labelB}}', {
      labelA: unitConverter.plantWaterStress().categoryTitleWithoutUnit(),
      labelB: getCropNameFromId(Number(cropId)),
    }),
    items: [
      {
        chartConfig: {
          tooltip: {
            xDateFormat: getXDateFormat(isDaily),
          },
          semiosHighchartsAdditions: {
            id: 'PlantStress',
            firstForecastTimestamp,
          },
          chart: {
            type: 'line',
          },
          yAxis: {
            min: yAxisMinToUse,
            max: yAxisMaxToUse,
            gridLineWidth: 0,
          },
          series,
        },
        childrenLower: isDataAvailable && (
          <div css={{ marginLeft: 20, marginBottom: 20 }}>
            <PlantWaterStressLegend isChart />
          </div>
        ),
      },
    ],
  }
}
