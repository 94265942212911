export const calculateBoundaryPairs = (moistureZoneBoundaries: { [key: string]: number }) => {
  const zoneBoundaryValues = Object.values(moistureZoneBoundaries).sort((a, b) => b - a)
  const boundaryMidpoints = []

  for (let i = 0; i < zoneBoundaryValues.length - 1; i++) {
    const boundaryMidpoint =
      (zoneBoundaryValues[i] - zoneBoundaryValues[i + 1]) / 2 + zoneBoundaryValues[i + 1]

    boundaryMidpoints.push(boundaryMidpoint)
  }

  const allBoundaries = [
    moistureZoneBoundaries.saturated,
    ...boundaryMidpoints,
    moistureZoneBoundaries.extremeDeficit,
  ]

  const heatMapZoneNames = ['saturatedToIdeal', 'idealToModerate', 'moderateToHigh', 'highToExtreme']
  // Stored in an object so if one needs to debug it's more verbose
  const boundaryPairs: { [key: string]: { high: number; low: number; step: number } } = {}

  for (let i = 0; i < allBoundaries.length - 1; i++) {
    const highBoundary = allBoundaries[i]
    const lowBoundary = allBoundaries[i + 1]
    // The step is used to offset the normalized value, ranges [0, numZones - 1]
    const step = heatMapZoneNames.length - 1 - i

    boundaryPairs[heatMapZoneNames[i]] = { high: highBoundary, low: lowBoundary, step: step }
  }

  return boundaryPairs
}
