import React from 'react'
import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconNetworkOffline = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 23 23')}>
      <path
        d="M20.6906 10.4871C19.4782 9.27464 18.0728 8.32593 16.4746 7.64096C14.8763 6.956 13.1639 6.61352 11.3373 6.61352C11.0067 6.61352 10.6878 6.62533 10.3808 6.64895C10.0737 6.67257 9.76271 6.70799 9.44778 6.75523L7.0386 4.34605C7.73144 4.15709 8.43608 4.01538 9.15254 3.9209C9.869 3.82642 10.5973 3.77918 11.3373 3.77918C13.5733 3.77918 15.6597 4.19646 17.5965 5.03101C19.5333 5.86557 21.226 7.00717 22.6747 8.45584L20.6906 10.4871ZM16.9115 14.219L12.1404 9.44785C13.4159 9.57382 14.6086 9.89662 15.7188 10.4163C16.8289 10.9359 17.8091 11.6208 18.6594 12.4711L16.9115 14.219ZM18.6594 21.3521L9.77846 12.4239C9.03838 12.5971 8.34948 12.8569 7.71175 13.2033C7.07403 13.5498 6.50322 13.9828 5.99934 14.5024L4.01531 12.4711C4.51919 11.9673 5.06244 11.5264 5.64505 11.1485C6.22766 10.7705 6.84964 10.4399 7.51099 10.1564L5.38524 8.03068C4.73964 8.36136 4.13734 8.72746 3.57835 9.12899C3.01935 9.53052 2.48792 9.98323 1.98403 10.4871L0 8.45584C0.503882 7.95195 1.02745 7.49925 1.57069 7.09772C2.11394 6.69619 2.69262 6.31434 3.30672 5.95217L1.32269 3.96814L2.64538 2.64545L20.0293 20.0294L18.6594 21.3521ZM11.3373 19.8404L8.007 16.4865C8.43215 16.0613 8.92816 15.7267 9.49502 15.4826C10.0619 15.2386 10.676 15.1165 11.3373 15.1165C11.9987 15.1165 12.6128 15.2386 13.1797 15.4826C13.7465 15.7267 14.2425 16.0613 14.6677 16.4865L11.3373 19.8404Z"
        fill="currentColor"
      />
    </svg>
  )
}
