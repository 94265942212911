import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconServiceCenter = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 21')}>
      <path
        d="M16.85 20.975C16.7167 20.975 16.5917 20.9543 16.475 20.913C16.3583 20.871 16.25 20.8 16.15 20.7L11.05 15.6C10.95 15.5 10.8793 15.3917 10.838 15.275C10.796 15.1583 10.775 15.0333 10.775 14.9C10.775 14.7667 10.796 14.6417 10.838 14.525C10.8793 14.4083 10.95 14.3 11.05 14.2L13.175 12.075C13.275 11.975 13.3833 11.904 13.5 11.862C13.6167 11.8207 13.7417 11.8 13.875 11.8C14.0083 11.8 14.1333 11.8207 14.25 11.862C14.3667 11.904 14.475 11.975 14.575 12.075L19.675 17.175C19.775 17.275 19.846 17.3833 19.888 17.5C19.9293 17.6167 19.95 17.7417 19.95 17.875C19.95 18.0083 19.9293 18.1333 19.888 18.25C19.846 18.3667 19.775 18.475 19.675 18.575L17.55 20.7C17.45 20.8 17.3417 20.871 17.225 20.913C17.1083 20.9543 16.9833 20.975 16.85 20.975ZM16.85 18.6L17.575 17.875L13.9 14.2L13.175 14.925L16.85 18.6ZM3.125 21C2.99167 21 2.86267 20.975 2.738 20.925C2.61267 20.875 2.5 20.8 2.4 20.7L0.3 18.6C0.2 18.5 0.125 18.3873 0.075 18.262C0.025 18.1373 0 18.0083 0 17.875C0 17.7417 0.025 17.6167 0.075 17.5C0.125 17.3833 0.2 17.275 0.3 17.175L5.6 11.875H7.725L8.575 11.025L4.45 6.9H3.025L0 3.875L2.825 1.05L5.85 4.075V5.5L9.975 9.625L12.875 6.725L11.8 5.65L13.2 4.25H10.375L9.675 3.55L13.225 0L13.925 0.7V3.525L15.325 2.125L18.875 5.675C19.1583 5.95833 19.375 6.279 19.525 6.637C19.675 6.99567 19.75 7.375 19.75 7.775C19.75 8.175 19.675 8.55833 19.525 8.925C19.375 9.29167 19.1583 9.61667 18.875 9.9L16.75 7.775L15.35 9.175L14.3 8.125L9.125 13.3V15.4L3.825 20.7C3.725 20.8 3.61667 20.875 3.5 20.925C3.38333 20.975 3.25833 21 3.125 21ZM3.125 18.6L7.375 14.35V13.625H6.65L2.4 17.875L3.125 18.6ZM3.125 18.6L2.4 17.875L2.775 18.225L3.125 18.6Z"
        fill="currentColor"
      />
    </svg>
  )
}
