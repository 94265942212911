import type { TSoilSensorDepths } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup/fieldAssetValueTypes'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const getFavoriteDepths = (): TSoilSensorDepths[] => {
  const propertyId = selectedFieldAssetsStore.getState().property
  const properties = fieldAssetStore.getState().properties
  const soilPointCategory: TPointCategory = 'soilPoint'

  const soilStationLngLat: TFieldAssetKeyTypes.TLngLat | null =
    selectedFieldAssetsStore.getState()[soilPointCategory]

  let propertyPoints
  let soilStationFavoriteDepths: TSoilSensorDepths[] = []

  if (propertyId && properties) {
    propertyPoints = properties[propertyId]?.points

    if (propertyPoints) {
      const soilStation = Object.values(propertyPoints).find((point) => {
        return point?.lngLat === soilStationLngLat
      })

      soilStationFavoriteDepths = soilStation?.configuration?.soilDefaultProbeDepths ?? []
    }
  }

  return soilStationFavoriteDepths
}
