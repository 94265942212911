import type { TKcDateRange } from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import { HighchartsChart } from 'components/HighchartsChart/HighchartsChart'
import type { TSemiosCustomHighchartsDateFormats } from 'components/HighchartsChart/_utils/setCustomHighchartsDateFormats'
import { baseChartOptions } from 'components/StackedChart/_utils/baseChartOptions'
import { baseLineChartOptions } from 'components/StackedChart/_utils/baseLineChartOptions'
import { translate } from 'i18n/i18n'
import { merge } from 'lodash'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { upperYAxisTitleMaker } from 'utils/upperYAxisTitleMaker'
import { getKcForDay } from './_utils/getKcForDay'

export const KcLineChart = ({
  dateFrom,
  dateTo,
  kcRangesData,
  kcMultiplier,
  noDataMessage,
  timezone = moment.tz.guess(),
}: {
  dateFrom: moment.Moment | null
  dateTo: moment.Moment | null
  kcRangesData: ({ startMoment: moment.Moment; endMoment: moment.Moment } & TKcDateRange)[]
  kcMultiplier: number
  noDataMessage?: string
  timezone?: string
}) => {
  const data: [number, number | null][] = []

  let lastDate = null

  for (let i = 0; i < kcRangesData.length; i++) {
    const dateRangeData = kcRangesData[i]
    const dateRange = dateRangeData.endMoment.diff(dateRangeData.startMoment, 'days') + 1

    // If there is a gap between the last date and the current range's startMoment, fill it with nulls
    if (lastDate && dateRangeData.startMoment.diff(lastDate, 'days') > 1) {
      const gapBetweenRanges = dateRangeData.startMoment.diff(lastDate, 'days')

      for (let gapDay = 1; gapDay < gapBetweenRanges; gapDay++) {
        const missingDate = lastDate.clone().add(gapDay, 'days')

        data.push([+missingDate, null])
      }
    }

    for (let j = 0; j < dateRange; j++) {
      const dateToPush = dateRangeData.startMoment.clone().add(j, 'days')

      if (dateToPush.isBetween(dateFrom, dateTo, 'day', '[]')) {
        const kcForDay = getKcForDay({
          date: dateToPush,
          startMoment: dateRangeData.startMoment,
          endMoment: dateRangeData.endMoment,
          kcStart: dateRangeData.kcStart,
          kcEnd: dateRangeData.kcEnd,
          linearInterpolation: dateRangeData.linearInterpolation,
          kcMultiplier,
        })

        data.push([+dateToPush, kcForDay])
      }
    }

    lastDate = dateRangeData.endMoment
  }

  const noData = !data.some(([, kc]) => kc !== null)

  const chartOptions = merge(baseChartOptions(timezone), baseLineChartOptions(), {
    semiosHighchartsAdditions: {
      id: 'kc-line-chart',
      firstForecastTimestamp: +new Date(),
    },
    chart: {
      backgroundColor: kcRangesData.length ? 'transparent' : colors.grey50,
    },
    timezone,
    lang: {
      noData: noDataMessage,
    },
    tooltip: {
      xDateFormat: '%D' as TSemiosCustomHighchartsDateFormats,
    },
    yAxis: {
      title: upperYAxisTitleMaker(translate.phrases.unitSymbols('Kc')),
      visible: kcRangesData.length,
      min: 0,
    },
    xAxis: {
      plotBands: null, // prevent forecast from showing up
      plotLines: [
        {
          color: 'orange',
          value: +moment.tz(timezone).startOf('day'),
          width: 2,
          label: {
            text: translate.phrases.banyanApp('Today'),
          },
        },
      ],
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        color: colors.wmGreen,
        data: noData ? [] : data,
        dragDrop: { draggableX: false, draggableY: false },
        name: translate.phrases.unitSymbols('Kc'),
        type: 'spline',
        visible: true,
        yAxis: 0,
        connectNulls: false,
      },
    ],
  })

  return <HighchartsChart height={300} chartOptions={chartOptions} />
}
