import { Input } from '@mantine/core'
import React, { useRef, useState } from 'react'
import { colors } from 'settings/colors'
import { Button } from '../../../../components/Button/Button'
import { MultiSelect } from '../../../../components/MultiSelect/MultiSelect'
import type { TOptions } from '../../../../components/MultiSelect/MultiSelect.types'

export const MultiSelectAddItem = () => {
  const [options, setOptions] = useState<TOptions[]>([
    { id: '1001', label: 'Apple', value: 'apple', group: 'Fruits' },
    { id: '1002', label: 'Orange', value: 'orange', group: 'Fruits' },
    { id: '1003', label: 'Grapes', value: 'grapes', group: 'Fruits' },
    { id: '1004', label: 'Carrot', value: 'carrot', group: 'Veggies' },
    { id: '1005', label: 'Onion', value: 'onion', group: 'Veggies' },
  ])

  const recentlyUsedOptions = ['1001', '1003']
  const [selectedOptions, setSelectedOptions] = useState<TOptions[]>([])
  const [addItem, setAddItem] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addItemInputBox: any = useRef(null)

  const renderAddItemSection = () => {
    return (
      <div
        css={{
          'position': 'sticky',
          'bottom': 0,
          'width': '100%',
          'display': 'flex',
          'flexWrap': 'wrap',
          'alignItems': 'center',
          'justifyContent': 'space-between',
          'padding': 5,
          'borderTop': `1px solid ${colors.grey500}`,
          'zIndex': 10,
          'backgroundColor': 'white',
          '@media (max-width: 600px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Input
          css={{
            'width': '80%',
            '@media (max-width: 900px)': {
              width: '65%',
            },
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }}
          ref={addItemInputBox}
          placeholder={'Item to add'}
          value={addItem}
          onClick={() => {
            if (addItemInputBox && addItemInputBox.current) {
              addItemInputBox.current.focus()
            }
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAddItem(event?.target?.value)}
        />
        <Button
          css={{
            'width': '15%',
            '@media (max-width: 900px)': {
              width: '30%',
            },
            '@media (max-width: 600px)': {
              marginTop: 5,
              width: '100%',
            },
          }}
          variant={'tertiary'}
          onClick={async () => {
            await new Promise((resolve) =>
              setTimeout(() => {
                resolve(
                  setOptions([
                    ...options,
                    { id: `100${options.length + 1}`, label: addItem, value: addItem },
                  ]),
                )
              }, 500),
            )
          }}
        >
          {'Add Item'}
        </Button>
      </div>
    )
  }

  return (
    <>
      <h4>Multi Select - Add an Item To List</h4>
      <MultiSelect
        data={options}
        topSectionLabel={'RECENTLY USED'}
        topSectionItems={recentlyUsedOptions}
        bottomSectionLabel={'ALL ITEMS'}
        selectedData={selectedOptions}
        onChange={(selectedValues) => setSelectedOptions(selectedValues)}
        groupOptions={true}
        placeholder="Select Attribute"
        noOfValuesToDisplay={2}
        selectedValueDecorator={(value, index) => {
          return index && index >= 2 ? `+ ${value}` : `${value}`
        }}
        customSelectListBottomSection={renderAddItemSection()}
      />
    </>
  )
}
