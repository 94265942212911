import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import React, { useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { setTokenAndPushHome } from 'utils/setTokenAndPushHome'
import { z } from 'zod'
import type { InputPropsType } from './AuthenticationForm'
import { AuthenticationForm } from './AuthenticationForm'
import { useToast } from './AuthenticationForm/Toast/useToast'
import { appMetaDataMaker } from './_utils/appMetaMaker'

const getValidationSchema = () =>
  z.object({
    email: z
      .string()
      .min(1, {
        message: translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Email'),
        }),
      })
      .email({
        message: translate.phrases.validation('{{label}} is invalid', {
          label: translate.phrases.banyanApp('Email'),
        }),
      }),
    password: z.string().min(6, {
      message: translate.phrases.validation('{{label}} must be at least {{count}} characters', {
        label: translate.phrases.banyanApp('Password'),
        count: 6,
      }),
    }),
  })

export const Login: FC<{ setCurrentFormType: React.Dispatch<React.SetStateAction<string>> }> = ({
  setCurrentFormType,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showToast, Toast } = useToast({ durationMillisecond: 3000, type: 'error' })

  const inputProps: InputPropsType[] = [
    {
      placeholder: translate.phrases.banyanApp('Email'),
      key: 'email',
      initialValue: '',
      isPasswordInput: false,
    },
    {
      placeholder: translate.phrases.banyanApp('Password'),
      key: 'password',
      initialValue: '',
      isPasswordInput: true,
    },
  ]

  const handleSubmit = async (values: { email: string; password: string }) => {
    setLoading(true)

    try {
      const response = await apiFetch<routes.Login.Request, routes.Login.Response>({
        url: routes.Login.path,
        body: { ...values, appMeta: appMetaDataMaker() },
      })

      const token = response['x-token']

      if (token) setTokenAndPushHome(token)
    } catch (error) {
      showToast(
        translate.phrases.banyanApp(
          "Your email and password combination don't seem to match. Please try again.",
        ),
      )
    }

    setLoading(false)
  }

  return (
    <AuthenticationForm
      header={
        <>
          {Toast}
          <Text size={24} weight={300}>
            {translate.phrases.banyanApp('Hello again!')}
          </Text>
          <Text size={14} weight={400}>
            {translate.phrases.banyanApp('Please login below')}
          </Text>
        </>
      }
      inputProps={inputProps}
      validationSchema={getValidationSchema()}
      buttonText={translate.phrases.banyanApp('Login')}
      buttonRightIcon={<FontAwesomeIcon icon={faArrowAltCircleRight} />}
      anchorText={translate.phrases.banyanApp('Forgot Password?')}
      onAnchorPress={() => setCurrentFormType('forgotPassword')}
      // TODO: type this better, maybe a generic on the component
      onSubmit={(values) => handleSubmit(values as { email: string; password: string })}
      submitLoading={loading}
    />
  )
}
