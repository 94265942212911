import { IconDirection } from 'components/icons/IconDirection'
import { translate } from 'i18n/i18n'
import React from 'react'
import { colors } from 'settings/colors'

interface DirectionButtonProps {
  latLng: google.maps.LatLngLiteral
}

export const DirectionButton: React.FC<DirectionButtonProps> = ({ latLng }) => {
  return (
    <div>
      <a
        href={`https://www.google.com/maps/dir/?api=1&destination=${latLng.lat},${latLng.lng}`}
        target="_blank"
        css={{
          display: 'inline-block',
          fontSize: 16,
          fontWeight: 700,
          color: colors.primary,
        }}
        rel="noreferrer"
      >
        <span css={{ marginRight: 10, position: 'relative', top: 3 }}>
          <IconDirection />
        </span>
        {translate.phrases.banyanApp('Get Directions')}
      </a>
    </div>
  )
}
