import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

export const refreshMapData = (expectedPrimaryValueGroup: TValueGroup) => {
  const primaryValueGroup = getPrimaryValueGroup() as TValueGroup

  if (primaryValueGroup === expectedPrimaryValueGroup) {
    mapControlsStore.setState((s) => ({ ...s, keyForRefreshingMap: new Date().toISOString() }))
  }
}
