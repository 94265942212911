import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Rule } from '../../../settings/alertSettings'
import { AlertFrequencyWording } from './AlertFrequencyWording/AlertFrequencyWording'
import { ScheduleWording } from './ScheduleWording/ScheduleWording'
import { SleepWording } from './SleepWording/SleepWording'

export const FrequencyAndScheduling = ({
  sleepMinutes,
  frequencyMinutes,
  startDate,
  endDate,
  rules,
}: {
  sleepMinutes: number | null
  frequencyMinutes: number | null
  startDate: string | null
  endDate: string | null
  rules: Rule[]
}) => {
  const hasScheduling = startDate && endDate
  const foundHourOfDayValue = rules.find((r) => r.key === 'hourOfDay')?.value as number

  const hourOfDayString =
    foundHourOfDayValue &&
    moment
      .tz()
      .startOf('year')
      .add(foundHourOfDayValue, 'hours')
      .format(translate.dates.getMomentFormat('h:mm a'))

  return (
    <div>
      <AlertFrequencyWording frequencyMinutes={frequencyMinutes} />
      {sleepMinutes && (
        <>
          {', '}
          <SleepWording sleepMinutes={sleepMinutes} />
        </>
      )}
      {', '}
      {!!hourOfDayString && translate.phrases.banyanApp('at around {{hourOfDayString}}', { hourOfDayString })}
      {!!hourOfDayString && ', '}
      {hasScheduling ? (
        <ScheduleWording startDate={startDate} endDate={endDate} />
      ) : (
        <>{translate.phrases.banyanApp('year-round')}</>
      )}
    </div>
  )
}
