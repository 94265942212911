import { Select, Stack, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { isBleSupported } from 'App/ServiceCenter/BluetoothLowEnergy/util/utility'
import { translate } from 'i18n/i18n'
import _ from 'lodash'
import React, { useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { useKeyboardVisible } from 'utils/useKeyboardVisible'
import { Footer } from '../../NodeInstallation/Footer/Footer'
import type { TActiveNode, TNodeDevice, TNodeDeviceChannels, TNodeDevicePort } from '../../types'
import { ManagementTypes, NodeDeviceInstallationStatus } from '../../types'
import { getDeviceInstallationHeightLabel } from '../../utils/getDeviceInstallationHeightLabel'
import type { TDeviceConfiguration } from '../types'
import { checkDeviceGroupPermission } from '../utils'

interface DeviceConfigurationProps {
  node: TActiveNode
  port: TNodeDevicePort
  channel: number
  detectSource?: string
  onCancel: () => void
  onConfirm: (config: TDeviceConfiguration) => Promise<void>
}

export const DeviceConfiguration: React.FC<DeviceConfigurationProps> = ({
  node: selectedNode,
  port: selectedPort,
  channel: selectedChannel,
  detectSource,
  onCancel,
  onConfirm,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const { isKeyboardVisible } = useKeyboardVisible()
  const allDeviceModels = fieldAssetStore.useSelector((s) => s.devices)
  const nodeDevice = selectedNode.devices?.[selectedPort]?.[selectedChannel]

  let portOptions: { label: string; value: TNodeDevicePort; disabled: boolean }[] = []
  let channelOptions: { label: string; value: string; disabled: boolean }[] = []
  let heightOptions: { label: string; value: string; disabled: boolean }[] = []

  const form = useForm<TDeviceConfiguration>({
    initialValues: {
      connector: selectedPort,
      channel: selectedChannel,
      source: detectSource ? detectSource : nodeDevice?.source || '',
      height: nodeDevice?.height || '',
      installationStatus: nodeDevice?.installationStatus || NodeDeviceInstallationStatus.INSTALLED,
      hiddenFromCustomer: nodeDevice?.hiddenFromCustomer || false,
      managementType: selectedNode.managementType || ManagementTypes.COMMERCIAL,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      maintenanceOwnerId: selectedNode.maintenanceOwnerId!,
    },
    validate: {
      source: (value: string) => {
        if (sources.length === 0) {
          return translate.phrases.placeholder('No devices available, please check your permissions.')
        }

        if (value.length === 0) {
          return translate.phrases.placeholder('Please select a device type')
        }

        return null
      },
      height: (value: string) => {
        if (value.length === 0) {
          return translate.phrases.placeholder('Please select a height')
        }

        return null
      },
      channel: (value: number) => {
        if (
          !channelOptions
            .filter((opt) => !opt.disabled)
            .map((opt) => opt.value)
            .includes(value.toString())
        ) {
          return translate.phrases.placeholder('There is no available channel for this device')
        }

        return null
      },
    },
  })

  const sources = _.sortBy(
    Object.values(allDeviceModels)
      .filter((device) => device.connector === selectedPort)
      .filter((device) => checkDeviceGroupPermission(device.deviceGroupName))
      .map((device) => {
        return {
          label: device.name,
          value: device.source,
          group: device.connector.toUpperCase(),
        }
      }),
  )

  //Can't install the same source at same height on the same node
  const heightInUse = Object.entries(selectedNode.devices || []).reduce(
    (acc: string[], [connector, deviceGroup]: [string, TNodeDeviceChannels]) => {
      Object.entries(deviceGroup).forEach(([channel, device]: [string, TNodeDevice]) => {
        if (connector === selectedPort && +channel === selectedChannel) return

        if (device.source === form.values.source) {
          acc.push(device.height)
        }
      })

      return acc
    },
    [],
  )

  const channelInUse = Object.entries(selectedNode.devices || []).reduce(
    (acc: number[], [connector, deviceGroup]: [string, TNodeDeviceChannels]) => {
      Object.entries(deviceGroup).forEach(([channel]: [string, TNodeDevice]) => {
        if (connector === selectedPort && +channel === selectedChannel) return

        if (connector === selectedPort) {
          acc.push(+channel)
        }
      })

      return acc
    },
    [],
  )

  if (form.values.source && allDeviceModels[form.values.source]) {
    const model = allDeviceModels[form.values.source]

    if (model) {
      heightOptions = model.installationHeights.map((height: string) => {
        return {
          label: getDeviceInstallationHeightLabel(height),
          value: height,
          disabled: heightInUse.includes(height),
        }
      })

      channelOptions = model.installationChannels.map((channel: number) => {
        return {
          label: channel.toString(),
          value: channel.toString(),
          disabled: channelInUse.includes(channel),
        }
      })
    }
  }

  portOptions = [
    {
      label: selectedPort.toUpperCase(),
      value: selectedPort,
      disabled: false,
    },
  ]

  const handleSubmit = async () => {
    const validation = form.validate()

    if (validation.hasErrors) {
      return
    }

    try {
      setIsSaving(true)

      await onConfirm(form.values)
    } catch (error) {
      setIsSaving(false)
    }
  }

  return (
    // device selection option is searchable and mobile keyboard hide some options so when keyboard is open, push up entire screen
    <div css={{ padding: 10, paddingBottom: isKeyboardVisible ? 500 : 10 }}>
      <h3 css={{ margin: 0 }}>{translate.phrases.placeholder('Configure Device')}</h3>

      <p css={{ lineHeight: '24px', marginTop: 10, marginBottom: 20 }}>
        {translate.phrases.placeholder('Select the device height & port below.')}
      </p>

      <Stack>
        <Select
          required
          {...form.getInputProps('source')}
          label={translate.phrases.placeholder('Device Type')}
          disabled={detectSource !== undefined || nodeDevice?.source !== undefined} // if detectSource is provided, user can't change the source
          placeholder={translate.phrases.placeholder('Please select a device type')}
          data={sources}
          styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
          searchable
          onChange={(v: string) => {
            form.setValues((prev) => ({ ...prev, source: v, height: '' }))
          }}
        />
        {form.isValid('source') && (
          <>
            <Select
              {...form.getInputProps('height')}
              required
              label={translate.phrases.placeholder('Installation Height/Depth')}
              placeholder={translate.phrases.placeholder('Select height')}
              data={heightOptions}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
            />
            <Select
              {...form.getInputProps('connector')}
              disabled
              required
              label={translate.phrases.placeholder('Input')}
              placeholder={translate.phrases.placeholder('Select Input')}
              data={portOptions}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
            />
            <Select
              {...form.getInputProps('channel')}
              disabled
              required
              label={translate.phrases.placeholder('Channel')}
              placeholder={translate.phrases.placeholder('Select Channel')}
              data={channelOptions}
              value={selectedChannel.toString()}
              styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
              searchable
            />
          </>
        )}

        {selectedPort === 'sdi' && detectSource && isBleSupported() && (
          <Text css={{ marginTop: 20 }}>
            {translate.phrases.placeholder(
              'If this is one of multiple devices you wish to plug into the SDI port be sure to remove other devices, plug this device in, and then set a unique channel before finalizing the install.',
            )}
          </Text>
        )}
      </Stack>
      <Footer
        nextButtonLabel={translate.phrases.placeholder('Next')}
        previousButtonLabel={translate.phrases.placeholder('Previous')}
        showPreviousButton={true}
        loading={isSaving}
        onNext={() => handleSubmit()}
        onPrevious={() => onCancel()}
      />
    </div>
  )
}
