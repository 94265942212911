import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconTrap = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 41 41')}>
      <path
        d="M20.0282322,5.14717315 L7.4741221,13.6730863 C7.323148,13.7755817 7.20910802,13.9238294 7.14874394,14.0960182 L0.0486840945,34.3478479 C-0.145554483,34.9018172 0.265611387,35.4816873 0.85269787,35.4816873 L40.1471501,35.4816873 C40.7481497,35.4816873 41.1602613,34.8761721 40.9397641,34.3170908 L32.9502351,14.0604047 C32.8911064,13.910453 32.7906558,13.780438 32.6606409,13.6852698 L21.0099046,5.16429831 C20.7192028,4.95169065 20.326176,4.94483206 20.0282322,5.14717315 Z"
        fill="currentColor"
      />
    </svg>
  )
}
