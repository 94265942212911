// TODO: Probably belongs in value-type-definitions
export enum EnumWeatherCondition {
  'wi-cloudy' = 'wi-cloudy',
  'wi-day-cloudy' = 'wi-day-cloudy',
  'wi-day-fog' = 'wi-day-fog',
  'wi-day-hail' = 'wi-day-hail',
  'wi-day-haze' = 'wi-day-haze',
  'wi-day-rain' = 'wi-day-rain',
  'wi-day-snow' = 'wi-day-snow',
  'wi-day-sunny' = 'wi-day-sunny',
  'wi-day-thunderstorm' = 'wi-day-thunderstorm',
  'wi-day-windy' = 'wi-day-windy',
  'wi-fog' = 'wi-fog',
  'wi-hail' = 'wi-hail',
  'wi-hot' = 'wi-hot',
  'wi-hurricane' = 'wi-hurricane',
  'wi-na' = 'wi-na',
  'wi-night-clear' = 'wi-night-clear',
  'wi-night-cloudy' = 'wi-night-cloudy',
  'wi-night-showers' = 'wi-night-showers',
  'wi-night-snow' = 'wi-night-snow',
  'wi-night-thunderstorm' = 'wi-night-thunderstorm',
  'wi-rain' = 'wi-rain',
  'wi-rain-mix' = 'wi-rain-mix',
  'wi-sandstorm' = 'wi-sandstorm',
  'wi-showers' = 'wi-showers',
  'wi-sleet' = 'wi-sleet',
  'wi-smoke' = 'wi-smoke',
  'wi-snow' = 'wi-snow',
  'wi-snow-wind' = 'wi-snow-wind',
  'wi-snowflake-cold' = 'wi-snowflake-cold',
  'wi-sunrise' = 'wi-sunrise',
  'wi-sunset' = 'wi-sunset',
  'wi-thunderstorm' = 'wi-thunderstorm',
  'wi-tornado' = 'wi-tornado',
  'wi-windy' = 'wi-windy',
}
