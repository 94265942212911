import type { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  getValueTypeFromEmitterType,
  IRRIGATION_ACTIVITY_APPLIED_VALUE_TYPE_PREFIX,
} from '@semios/app-platform-common'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'
import type { StackedChartSection } from 'components/StackedChart/types'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { chooseAmongstUnAggHourlyAndDaily } from '../../chooseAmongstUnAggHourlyAndDaily'
import { getPressureSensorArgs } from './pressureSensors/getPressureSensorArgs'
import { pressureSensorsChart } from './pressureSensors/pressureSensorsChart'
import { getIrrigationZoneArgs } from './zones/getIrrigationZoneArgs'
import { zonesActivitySemiosIntervalsChart } from './zones/zonesActivitySemiosIntervalsChart'

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.irrigationZoneEmitter) return {}

  const intervalToRequest = chooseAmongstUnAggHourlyAndDaily()

  const { irrigationZoneId, emitterType } = getIdAndEmitterTypeFromZoneEmitterTypeKey(
    selectedFieldAssets.irrigationZoneEmitter,
  )

  const valueTypeForIntervals = getValueTypeFromEmitterType(
    IRRIGATION_ACTIVITY_APPLIED_VALUE_TYPE_PREFIX,
    emitterType,
  )

  const irrigationZoneArgs = getIrrigationZoneArgs({
    selectedValueGroups,
    selectedFieldAssets,
    irrigationZoneId,
    emitterType,
  })

  const pressureSensorArgs = getPressureSensorArgs({
    selectedValueGroups,
    selectedFieldAssets,
    emitterType,
    valueTypeForIntervals,
    intervalToRequest,
  })

  return {
    ...pressureSensorArgs,
    ...irrigationZoneArgs,
  }
}

export const content = ({
  data,
  selectedFieldAssets,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartSection[] => {
  return [
    pressureSensorsChart({ data, selectedFieldAssets }),
    zonesActivitySemiosIntervalsChart({ data, selectedFieldAssets }),
  ].filter((chart): chart is StackedChartSection => Boolean(chart))
}
