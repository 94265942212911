import { translate } from 'i18n/i18n'

export const SleepWording = ({ sleepMinutes }: { sleepMinutes: number | null }) => {
  if (!sleepMinutes) return null

  if (sleepMinutes % 1440 === 0) {
    return (
      <translate.Phrases.banyanApp
        k="sleeping {{count}} day after being triggered"
        v={{ count: sleepMinutes / 1440 }}
      />
    )
  }

  if (sleepMinutes % 60 === 0) {
    return (
      <translate.Phrases.banyanApp
        k="sleeping {{count}} hour after being triggered"
        v={{ count: sleepMinutes / 60 }}
      />
    )
  }

  return (
    <translate.Phrases.banyanApp
      k="sleeping {{count}} minute after being triggered"
      v={{ count: sleepMinutes }}
    />
  )
}
