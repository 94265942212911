import { css } from '@emotion/css'
import type { GridTableColumn, GridTableContentSection } from 'components/GridTable/types'
import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { useEffect, useMemo, useState } from 'react'
import { colors } from 'settings/colors'
import { ItemRow } from './ItemRow/ItemRow'

export const Section = ({
  columns,
  containerWidth,
  section,
  widthForLabelColumn,
  widthForValueColumns,
}: {
  columns: GridTableColumn[]
  containerWidth: number
  section: GridTableContentSection
  widthForLabelColumn: number
  widthForValueColumns: number
}) => {
  const [collapsed, setCollapsed] = useState(!!section.defaultCollapsed)

  const sectionHeight = useMemo(() => {
    const itemsHeight = section.items?.reduce((a, b) => a + b.height, 0) ?? 0
    const fullWidthItemsHeight = section.fullWidthItems?.reduce((a, b) => a + b.height, 0) ?? 0

    return itemsHeight + fullWidthItemsHeight
  }, [section])

  useEffect(() => {
    if ('defaultCollapsed' in section) {
      setCollapsed(!!section.defaultCollapsed)
    }
  }, [section.defaultCollapsed])

  return (
    <>
      <SectionTitleBar
        containerWidth={containerWidth}
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        sticky
        title={section.title}
      >
        {section.titleChildren}
      </SectionTitleBar>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          opacity: collapsed ? 0 : 1,
          height: collapsed ? 0 : sectionHeight,
          transition: '200ms ease all',
          overflow: collapsed ? 'hidden' : 'unset',
          boxSizing: 'border-box',
          border: `0.5px solid ${colors.grey200}`,
          borderCollapse: 'collapse',
        })}
      >
        {!!section.fullWidthItems?.length &&
          section.fullWidthItems.map((fwi, i) => {
            return (
              <div
                key={i}
                css={{
                  // NOTE: keep roughly in sync with StackedChart if desired
                  display: 'flex',
                  height: fwi.height,
                  maxHeight: fwi.height,
                  transition: 'height 200ms, opacity 200ms',
                  padding: 20,
                  position: 'sticky',
                  left: 0,
                  fontSize: 20,
                  fontWeight: 300,
                  width: containerWidth,
                  maxWidth: containerWidth,
                  boxSizing: 'border-box',
                  border: `0.5px solid ${colors.grey200}`,
                  borderCollapse: 'collapse',
                  overflow: 'hidden',
                }}
              >
                {fwi.content}
              </div>
            )
          })}
        {!!section.items?.length &&
          section?.items.map((item) => (
            <ItemRow
              columns={columns}
              height={item.height}
              key={item.id}
              label={item.label}
              labelCellCSS={item.labelCellCSS}
              render={item.render}
              valueCellCSS={item.valueCellCSS}
              values={item.values}
              valueWidth={item.valueMinWidth}
              widthForLabelColumn={widthForLabelColumn}
              widthForValueColumns={widthForValueColumns}
            />
          ))}
      </div>
    </>
  )
}
