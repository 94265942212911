import type { TIrrigationDeviceStatus } from '@semios/app-platform-value-type-definitions/src/valuesTimeseriesTypes/irrigation'
import { colors } from 'settings/colors'

export const getIrrigationStatusColors = (status: TIrrigationDeviceStatus) => {
  if (status === 'on') return { backgroundColor: colors.green, color: colors.midnight }

  if (status === 'off') return { backgroundColor: colors.midnight, color: colors.white }

  if (status === 'error') return { backgroundColor: colors.red100, color: colors.strongRed }

  return { backgroundColor: colors.grey200, color: colors.midnight }
}
