import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { translate } from 'i18n/i18n'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { colors } from 'settings/colors'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'
import { AlertSection } from '../../../components/AlertSection'
import { ErrorTextWrapper } from '../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../components/TranslateComponentWrapper'
import { TranslatePhraseWrapper } from '../../../components/TranslatePhraseWrapper'
import type { AlertTypeId } from '../../../settings/alertSettings'
import { AlertsSettings } from '../../../settings/alertSettings'
import { getErrorStyle } from '../../../_utils/getErrorStyle'

export const Scheduling = ({
  alertTypeId,
  limitedSchedule,
  endDate,
  setEndDate,
  setLimitedSchedule,
  setStartDate,
  startDate,
  triedToSubmit,
  setScheduleIsValid,
}: {
  alertTypeId: AlertTypeId
  limitedSchedule: boolean
  endDate: Moment | null
  setEndDate: Dispatch<SetStateAction<Moment | null>>
  setLimitedSchedule: Dispatch<SetStateAction<boolean>>
  setStartDate: Dispatch<SetStateAction<Moment | null>>
  startDate: Moment | null
  triedToSubmit: boolean
  setScheduleIsValid: Dispatch<SetStateAction<boolean>>
}) => {
  useEffect(() => {
    setScheduleIsValid(!limitedSchedule || !!(startDate && endDate))
  }, [limitedSchedule, startDate, endDate])

  const allowScheduling = AlertsSettings.allowScheduling[alertTypeId]

  if (!allowScheduling) return null

  let helpText
  let validateStatusError

  if (limitedSchedule && triedToSubmit && (!startDate || !endDate)) {
    helpText = translate.phrases.banyanApp('Please select both a start month and an end month')

    validateStatusError = true
  }

  if (
    limitedSchedule &&
    startDate &&
    endDate &&
    +startDate.clone().startOf('day') === +endDate.clone().startOf('day')
  ) {
    helpText = translate.phrases.banyanApp('Please select a date range of at least one day')

    validateStatusError = true
  }

  const errorStyle = getErrorStyle()

  return (
    <AlertSection title={translate.phrases.banyanApp('Scheduling')}>
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
        <Switch
          checked={limitedSchedule}
          onChange={() => setLimitedSchedule(!limitedSchedule)}
          styles={{
            track: {
              cursor: 'pointer',
              backgroundColor: limitedSchedule ? `${colors.green} !important` : undefined,
              borderColor: limitedSchedule ? `${colors.green} !important` : undefined,
            },
          }}
        />
        <span
          css={{ fontWeight: '500', fontSize: 16, color: colors.midnight, cursor: 'pointer' }}
          onClick={() => setLimitedSchedule(!limitedSchedule)}
        >
          &nbsp;{translate.phrases.banyanApp('Limit days of year when alert is active')}&nbsp;
        </span>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ...fadeInOutStylesMaker(limitedSchedule),
        }}
      >
        <TranslatePhraseWrapper>
          <translate.Phrases.banyanApp
            k="Alert will be active <datePickerInput1/> to <datePickerInput2/>."
            c={{
              datePickerInput1: (
                <TranslateComponentWrapper>
                  <DatePickerInput
                    valueFormat={translate.dates.getMomentFormat('MMM D')}
                    clearable={false}
                    placeholder={translate.phrases.banyanApp('Select start date')}
                    value={startDate ? startDate.toDate() : null}
                    minDate={moment.tz('America/Los_Angeles').startOf('year').toDate()}
                    maxDate={moment.tz('America/Los_Angeles').endOf('year').toDate()}
                    onChange={(date) => {
                      setStartDate((date && moment.tz(date, 'America/Los_Angeles')) || null)

                      setEndDate(null)
                    }}
                    classNames={{ input: validateStatusError ? errorStyle : undefined }}
                    rightSection={
                      <FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />
                    }
                    css={{ width: 240, margin: '0 5px' }}
                  />
                  <div />
                </TranslateComponentWrapper>
              ),
              datePickerInput2: (
                <TranslateComponentWrapper>
                  <DatePickerInput
                    valueFormat={translate.dates.getMomentFormat('MMM D')}
                    clearable={false}
                    disabled={!startDate}
                    placeholder={translate.phrases.banyanApp('Select end date')}
                    value={endDate ? endDate.toDate() : null}
                    minDate={moment.tz(startDate, 'America/Los_Angeles').add(1, 'day').toDate()}
                    maxDate={moment
                      .tz(startDate, 'America/Los_Angeles')
                      .add(1, 'year')
                      .subtract(1, 'day')
                      .toDate()}
                    onChange={(date) => setEndDate((date && moment.tz(date, 'America/Los_Angeles')) || null)}
                    classNames={{ input: validateStatusError ? errorStyle : undefined }}
                    rightSection={
                      <FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />
                    }
                    css={{ width: 240, margin: '0 5px' }}
                  />
                  <div />
                </TranslateComponentWrapper>
              ),
            }}
          />
        </TranslatePhraseWrapper>
      </div>
      {helpText && <ErrorTextWrapper>{helpText}</ErrorTextWrapper>}
    </AlertSection>
  )
}
