import { IconCurrentLocationMarker } from 'components/icons/IconCurrentLocationMarker'
import { useRef } from 'react'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'

export const CurrentLocationMarker = () => {
  const ref = useRef<HTMLDivElement>(null)

  useSetMapOverlayZIndex({ ref, mItem: 'M_CurrentLocation' })

  return (
    <div ref={ref} css={{ fontSize: 64 }}>
      <IconCurrentLocationMarker />
    </div>
  )
}
