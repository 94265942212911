import type { TChartSeries } from 'components/StackedChart/types'
import type { TXAxisPlotLinesOptionsWithTooltipDisplay } from 'components/StackedChart/_utils/lineChartTooltipFormatter/buildTooltipPlotLinesIfAny'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'

export const generateBiofixPlotLinesAndSeries = ({
  biofixDates,
  dateFrom,
  dateTo,
  intervalForCompareSeasonsOffset,
  timezone,
}: {
  biofixDates: {
    baseSeason: string[]
    comparisonSeason: string[]
  }
  dateFrom: string
  dateTo: string
  intervalForCompareSeasonsOffset: number
  timezone: string
}): {
  biofixPlotLines?: TXAxisPlotLinesOptionsWithTooltipDisplay[]
  biofixSeriesForAddingToggle?: TChartSeries
} => {
  const momentyDateFrom = moment.tz(dateFrom, timezone)
  const momentyDateTo = moment.tz(dateTo, timezone)

  const someBaseSeasonBiofixInView = biofixDates?.baseSeason?.some((bfd) => {
    const momentBiofixDate = moment.tz(bfd, timezone)

    if (momentBiofixDate < momentyDateFrom || momentBiofixDate > momentyDateTo) return false

    return true
  })

  const someCompareSeasonBiofixInView = biofixDates?.comparisonSeason?.some((bfd) => {
    const momentBiofixDate = moment.tz(bfd, timezone).add(intervalForCompareSeasonsOffset, 'years')

    if (momentBiofixDate < momentyDateFrom || momentBiofixDate > momentyDateTo) return false

    return true
  })

  if (!someBaseSeasonBiofixInView && !someCompareSeasonBiofixInView) return {}

  const generatePlotLines = (): TXAxisPlotLinesOptionsWithTooltipDisplay[] => {
    const baseSeasonBiofixes = biofixDates.baseSeason.map(
      (bfd): TXAxisPlotLinesOptionsWithTooltipDisplay => ({
        color: 'rgba(230, 16, 183, 1)',
        value: +new Date(bfd),
        width: 2,
        id: 'BIOFIX_DATE_PLOTLINE',
        dashStyle: 'ShortDot',
        tooltipDisplay: (ctx) =>
          intervalForCompareSeasonsOffset
            ? translate.phrases.templates('{{labelA}} {{labelB}}', {
                labelA: translate.phrases.banyanApp('Biofix Date'),
                labelB: translate.dates.format(moment.tz(ctx.x, timezone), 'YYYY'),
              })
            : translate.phrases.banyanApp('Biofix Date'),
      }),
    ) as TXAxisPlotLinesOptionsWithTooltipDisplay[]

    const compareSeasonBiofixes = biofixDates.comparisonSeason.map(
      (bfd): TXAxisPlotLinesOptionsWithTooltipDisplay => ({
        color: 'rgba(230, 16, 183, 1)',
        value: +moment.tz(bfd, timezone).add(intervalForCompareSeasonsOffset, 'years'),
        width: 2,
        id: 'BIOFIX_DATE_PLOTLINE_compareSeasons',
        dashStyle: 'ShortDot',
        tooltipDisplay: (ctx) =>
          translate.phrases.templates('{{labelA}} {{labelB}}', {
            labelA: translate.phrases.banyanApp('Biofix Date'),
            labelB: translate.dates.format(
              moment.tz(ctx.x, timezone).subtract(intervalForCompareSeasonsOffset, 'years'),
              'YYYY',
            ),
          }),
      }),
    ) as TXAxisPlotLinesOptionsWithTooltipDisplay[]

    return [...baseSeasonBiofixes, ...compareSeasonBiofixes]
  }

  const series: TChartSeries = {
    id: 'biofix',
    color: 'rgba(230, 16, 183, 1)',
    dashStyle: 'ShortDot',
    data: [],
    name: translate.phrases.banyanApp('Biofix Dates'),
    type: 'line',
    hideFromTooltip: true,
    events: {
      legendItemClick: function (event) {
        const visible = !event.target.visible

        if (!visible) {
          try {
            // @ts-ignore
            this.chart.xAxis?.[0]?.plotLinesAndBands.forEach((pl) => {
              if (pl?.id.startsWith('BIOFIX_DATE')) {
                this.chart.xAxis[0].removePlotLine(pl.id)
              }
            })
          } catch (err) {
            // TODO: probably not errorLogger, but maybe something here
          }
        } else {
          generatePlotLines().forEach((pl) => {
            this.chart.xAxis[0].addPlotLine(pl)
          })
        }
      },
    },
  }

  return {
    biofixPlotLines: generatePlotLines(),
    biofixSeriesForAddingToggle: series,
  }
}
