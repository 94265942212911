import type { CSSObject } from '@emotion/react'
import { Pagination } from '@mantine/core'
import { IconCircleLeft } from 'components/icons/IconCircleLeft'
import { IconCircleRight } from 'components/icons/IconCircleRight'
import { TableLengthText } from 'components/TableLengthText/TableLengthText'
import { translate } from 'i18n/i18n'
import { isEqual } from 'lodash'
import type { CSSProperties, ReactNode } from 'react'
import { memo, useCallback, useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { sortByKey } from '../../utils/sortByKey'
import { BodyRow } from './BodyRow/BodyRow'
import { HeaderRow } from './HeaderRow/HeaderRow'
import type { TColumn, TColumnGroup, TColumnSorting, TDataElement } from './SummaryTable.types'

const genericSortingFunction = () => 0

const SummaryTable = ({
  autoSortColumns,
  columnGroups = [],
  columns = [],
  data = [],
  noDataText = translate.phrases.banyanApp('No data found.'),
  onRow,
  pageSize,
  paginationResetKey,
  rowCSS,
  rowKey,
  showColumnTitles = true,
  tableTitle = '',
  thRowStyle = {},
  wrapperCSS = {},
  onPageChange,
}: {
  autoSortColumns?: string[]
  columnGroups?: TColumnGroup[]
  columns: TColumn[]
  data: TDataElement[]
  noDataText?: ReactNode
  onRow?: (row: TDataElement, rowIndex: number) => { [key: string]: () => void }
  pageSize?: number
  paginationResetKey?: string
  rowCSS?: (row: TDataElement, rowIndex: number) => CSSProperties
  rowKey?: (row: TDataElement, rowIndex: number) => string
  showColumnTitles?: boolean
  tableTitle?: ReactNode
  thRowStyle?: CSSProperties
  wrapperCSS?: CSSObject
  onPageChange?: (page: number) => void
}) => {
  const [columnSorting, setColumnSorting] = useState<TColumnSorting>({
    order: null,
    column: '',
    sortingFunction: genericSortingFunction,
  })

  const defaultPagination = { min: 0, max: pageSize ?? data.length }
  const [paginationMinMax, setPaginationMinMax] = useState(defaultPagination)
  const [currentPage, setCurrentPage] = useState(1)
  const { sortingFunction, order: sortOrder, column: sortColumn } = columnSorting
  const usePagination = !!(pageSize && data.length > pageSize)

  const onColumnSorterClick = (col: TColumn) => {
    // if we're clicking on a new column, sort it ascending
    if (sortColumn !== col.key) {
      return setColumnSorting({
        ...columnSorting,
        column: col.key,
        order: 1,
        sortingFunction: col.sorter ?? sortByKey(col.dataIndex),
      })
    }

    // If the user clicks on a column that is currently sorted in ascending order, we will sort it in descending order instead.
    if (sortColumn === col.key && sortOrder === 1) {
      return setColumnSorting({ ...columnSorting, order: -1 })
    }

    // if we're reclicking on the actively descendedly sorted column, remove sorting
    return setColumnSorting({
      order: null,
      column: '',
      sortingFunction: genericSortingFunction,
    })
  }

  let sortedData = data

  if (sortOrder) {
    sortedData = data.slice().sort((a, b) => sortingFunction(a, b) * sortOrder)
  }

  let paginatedData = sortedData.slice(paginationMinMax.min, paginationMinMax.max)

  useEffect(() => {
    setPaginationMinMax(defaultPagination)

    setCurrentPage(1)
  }, [paginationResetKey])

  const handlePaginationChange = useCallback(
    (pageNum: number): void => {
      if (usePagination) {
        if (pageNum <= 1) {
          setPaginationMinMax(defaultPagination)

          setCurrentPage(1)

          onPageChange?.(1)
        } else {
          setCurrentPage(pageNum)

          onPageChange?.(pageNum)

          setPaginationMinMax({ min: (pageNum - 1) * pageSize, max: pageNum * pageSize })
        }
      }
    },
    [setPaginationMinMax, setCurrentPage, onPageChange],
  )

  const showNoDataText = !sortedData || !sortedData.length

  return (
    <div css={{ position: 'relative', width: '100%', ...wrapperCSS }}>
      {!showNoDataText ? null : (
        <div
          css={{
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.45)',
            position: 'absolute',
            width: '100%',
            left: 0,
            right: 0,
            bottom: 12,
          }}
        >
          {noDataText}
        </div>
      )}
      <div css={{ paddingBottom: 12, marginBottom: -12, overflow: 'auto' }}>
        <table css={{ borderCollapse: 'collapse', borderSpacing: 1, width: '100%', fontSize: 14 }}>
          {tableTitle ? (
            <thead css={{ borderBottom: `1px solid ${colors.midnight}` }}>
              <tr>
                <th
                  css={{
                    'textAlign': 'left',
                    'padding': 8,
                    '&:first-child': {
                      fontWeight: 600,
                      paddingLeft: '14px !important',
                    },
                  }}
                  colSpan={columns.length}
                >
                  {tableTitle}
                </th>
              </tr>
            </thead>
          ) : null}
          {!!showColumnTitles && (
            <thead css={{ borderBottom: `1px solid ${colors.midnight}` }}>
              {!!columnGroups.length && (
                <tr>
                  {columnGroups.map((group: TColumnGroup) => {
                    return (
                      <th
                        css={{
                          'textAlign': 'left',
                          'padding': '8px 8px 0',
                          '&:first-child': {
                            fontWeight: 600,
                            paddingLeft: '14px !important',
                          },
                        }}
                        key={group.key}
                        colSpan={group.span}
                      >
                        {group.title}
                      </th>
                    )
                  })}
                </tr>
              )}
              <tr>
                {columns.map((col) => (
                  <HeaderRow
                    autoSortColumns={autoSortColumns}
                    col={col}
                    columnSorting={columnSorting}
                    enoughDataToShowSorting={sortedData && sortedData.length >= 2}
                    key={col.key}
                    onColumnSorterClick={onColumnSorterClick}
                    thRowStyle={thRowStyle}
                  />
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {showNoDataText ? (
              /**
               * give a single row that is the same'ish size as noDataText.
               * Note that this will likely have to be handled more in-depth
               * e.g. if noDataText is really tall and the table is really wide
               */
              <tr>
                <td colSpan={columns.length}>
                  <div css={{ visibility: 'hidden', paddingBottom: 18 }}>{noDataText}</div>
                </td>
              </tr>
            ) : (
              paginatedData.map((row, i) => (
                <BodyRow
                  columns={columns}
                  key={rowKey ? rowKey(row, i) : i}
                  row={row}
                  rowIndex={i}
                  onRow={onRow}
                  rowCSS={rowCSS}
                />
              ))
            )}
          </tbody>
        </table>
        {usePagination && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: '20px auto',
            }}
          >
            <TableLengthText
              filteredRows={sortedData.length}
              totalRows={data.length}
              activePage={currentPage}
              pageSize={pageSize}
            />
            <Pagination
              unstyled
              styles={{
                control: {
                  'border': 'none',
                  '&[data-active]': {
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    color: colors.midnight,
                  },
                  '&:[data-active]:not([data-disabled]):hover': {
                    backgroundColor: `${colors.grey200}`,
                  },
                  ':first-of-type': {
                    fontSize: 30,
                  },
                  ':last-of-type': {
                    fontSize: 30,
                  },
                  ':not(:first-of-type):not(:last-of-type)': {
                    marginBottom: '0.3rem',
                  },
                },
              }}
              css={{ margin: 'auto', textAlign: 'center' }}
              onChange={handlePaginationChange}
              total={Math.ceil(sortedData.length / pageSize)}
              value={currentPage}
              previousIcon={IconCircleLeft}
              nextIcon={IconCircleRight}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const MemoizedSummaryTable = memo(SummaryTable, isEqual) as typeof SummaryTable

export { MemoizedSummaryTable as SummaryTable }
