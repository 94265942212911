import * as Sentry from '@sentry/react'
import { appInfo } from './appInfo'

if (appInfo.useSentry) {
  Sentry.init({
    dsn: 'https://eb10a544960b47ecb104923265b0293a@o117841.ingest.sentry.io/4504058028621824',
    environment: window.location.hostname,
    initialScope: {
      tags: appInfo,
    },
  })
}
