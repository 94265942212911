import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconLWS = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 956 1024')}>
      <path
        fill="currentColor"
        d="M141.527 879.369c-41.386-45.19-73.804-97.422-97.256-156.676-23.451-59.263-35.177-120.529-35.177-183.805s11.036-125.799 33.109-187.568c22.073-61.769 57.938-119.772 107.601-174.010 32.188-35.153 71.962-65.283 119.327-90.393s103.464-44.946 168.299-59.51c64.837-14.564 138.871-23.352 222.101-26.365s176.348 0.503 279.349 10.547c7.356 106.463 9.658 204.391 6.896 293.78-2.751 89.39-10.339 169.991-22.758 241.804s-29.894 134.587-52.421 188.315c-22.538 53.74-50.349 98.689-83.461 134.839-48.742 53.237-100.474 92.159-155.195 116.759-54.72 24.61-110.59 36.914-167.609 36.914-59.78 0-118.178-12.807-175.197-38.421-57.019-25.604-106.222-61.010-147.607-106.212zM296.032 855.263c26.671 17.076 54.030 29.38 82.081 36.914s56.789 11.301 86.22 11.301c42.305 0 84.15-9.291 125.535-27.874s80.932-48.454 118.637-89.639c16.555-18.079 33.339-43.443 50.352-76.079 17.015-32.646 31.729-75.332 44.145-128.063s21.842-116.507 28.277-191.334c6.445-74.826 7.366-163.965 2.762-267.415-45.064-2.009-95.875-2.762-152.436-2.26s-112.889 5.273-168.99 14.313c-56.1 9.039-109.44 23.604-160.022 43.69s-91.968 47.708-124.156 82.861c-41.386 45.196-69.895 89.893-85.528 134.085s-23.451 86.878-23.451 128.058c0 59.259 10.348 111.235 31.039 155.93 20.692 44.699 38.857 76.079 54.49 94.159 38.628-80.346 89.669-157.435 153.126-231.255s137.49-134.336 222.101-181.542c-66.218 63.276-123.925 134.837-173.128 214.685s-86.22 173-111.051 279.464z"
      ></path>
      <path
        fill="currentColor"
        d="M492.638 221.406c3.081-4.025 7.668-6.511 12.362-6.707 5.172-0.274 10.403 2.269 13.795 6.707 0 0 43.618 57.675 87.25 131.103s88.862 160.875 88.862 232.936c0 118.309-84.907 214.645-189.189 214.645s-189.189-96.334-189.189-214.645c0-72.061 45.23-159.507 88.862-232.936s87.25-131.103 87.25-131.103zM456.213 600.513c-0.497-0.852-1.039-1.786-2.094-3.275-1.057 1.491-1.599 2.425-2.096 3.277-0.72 1.236-1.34 2.302-3.278 4.651-12.054 14.588-28.451 32.318-51.956 32.318-17.628 0-31.763-9.656-43.356-20.531 13.044 82.086 76.086 144.11 152.283 144.11 73.317 0 134.352-57.495 150.491-134.963-8.307 6.642-18.151 11.384-30.099 11.384-23.505 0-39.901-17.73-51.956-32.318-1.94-2.349-2.56-3.413-3.28-4.651-0.497-0.852-1.040-1.786-2.096-3.275-1.054 1.491-1.599 2.425-2.096 3.277-0.72 1.236-1.34 2.302-3.278 4.651-12.054 14.588-28.451 32.318-51.956 32.318s-39.9-17.73-51.956-32.318c-1.94-2.349-2.56-3.413-3.281-4.651z"
      ></path>
    </svg>
  )
}
