import type { CSSProperties } from 'react'
import { memo } from 'react'
import type { TColumn, TColumnSorting } from '../SummaryTable.types'
import { SortArrows } from './SortArrows/SortArrows'

const HeaderRow = ({
  autoSortColumns,
  col,
  columnSorting,
  enoughDataToShowSorting,
  onColumnSorterClick,
  thRowStyle,
}: {
  autoSortColumns?: string[]
  col: TColumn
  columnSorting: TColumnSorting
  enoughDataToShowSorting: boolean
  onColumnSorterClick: (col: TColumn) => void
  thRowStyle: CSSProperties
}): JSX.Element => {
  const hasSorting = enoughDataToShowSorting && (col.sorter || (autoSortColumns || []).includes(col.key))
  const paddingRight = hasSorting ? 'padding-right: 22px' : ''

  return (
    <th
      key={col.key}
      css={{
        'padding': '11px 8px',
        paddingRight,
        'minWidth': col.width || undefined,
        'position': 'relative',
        ...thRowStyle,
        'textAlign': col.align || 'left',
        'cursor': hasSorting ? 'pointer' : undefined,
        '&:first-of-type': {
          fontWeight: 'bold',
          paddingLeft: '14px !important',
          borderTopLeftRadius: '3px',
        },
        '&:last-of-type': {
          borderTopRightRadius: '3px',
        },
      }}
      onClick={hasSorting ? () => onColumnSorterClick(col) : undefined}
    >
      {col.title}
      {hasSorting && (
        <SortArrows isBeingSorted={columnSorting.column === col.key} sortOrder={columnSorting.order || -1} />
      )}
    </th>
  )
}

const MemoizedHeaderRow = memo(HeaderRow) as typeof HeaderRow

export { MemoizedHeaderRow as HeaderRow }
