import { ClickHereLink } from 'components/ClickHereLink/ClickHereLink'
import { IconWarning } from 'components/icons/IconWarning/IconWarning'
import { translate } from 'i18n/i18n'

export const RootZoneWarning = () => {
  return (
    <div style={{ fontSize: '12px', marginLeft: '40px', marginBottom: '10px' }}>
      <span style={{ marginRight: '5px' }}>
        <IconWarning />
      </span>
      <translate.Phrases.banyanApp
        k="Root zone depth is not set, click <HereLink>here</HereLink> to configure"
        c={{ HereLink: <ClickHereLink>{null}</ClickHereLink> }}
      />
    </div>
  )
}
