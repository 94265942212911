import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { SettingsDeleteButton } from 'App/Map/UserSettingsMenu/components/SettingsDeleteButton/SettingsDeleteButton'
import { SettingsEditButton } from 'App/Map/UserSettingsMenu/components/SettingsEditButton/SettingsEditButton'
import { CountBadge } from 'components/CountBadge/CountBadge'
import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import { useMemo, useState } from 'react'
import { colors } from 'settings/colors'
import { getScreenSize } from 'utils/getScreenSize'

export const PropertySection = ({
  propertyId,
  propertyName,
  zoneGroups,
  filterPropertiesText,
  setSelectedZoneGroupEdit,
  setSelectedZoneGroupDelete,
}: {
  propertyId: number
  propertyName: string
  zoneGroups: TFieldAssetValueTypes.TIrrigationZoneEmitterGroup[]
  filterPropertiesText: string
  setSelectedZoneGroupEdit: React.Dispatch<
    React.SetStateAction<(TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null>
  >
  setSelectedZoneGroupDelete: React.Dispatch<
    React.SetStateAction<(TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null>
  >
}) => {
  const [collapsed, setCollapsed] = useState(false)

  const filteredSettingItems = useMemo(() => {
    return arrayOfObjectsSearch(zoneGroups, filterPropertiesText, ['groupName'])
  }, [filterPropertiesText, zoneGroups])

  if (filteredSettingItems.length === 0) return null

  const maxWidth = getScreenSize().screenWidth - 40

  return (
    <div key={propertyId}>
      <SectionTitleBar
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        title={propertyName}
        containerWidth={maxWidth}
      />
      <div
        css={{
          padding: collapsed ? 0 : '10px 0',
          opacity: collapsed ? 0 : 1,
          height: collapsed ? 0 : '100%',
          overflow: collapsed ? 'hidden' : 'unset',
          boxSizing: 'border-box',
          borderCollapse: 'collapse',
          transition: 'height 80ms, opacity 80ms',
        }}
      >
        {filteredSettingItems.map((zoneGroup, i) => {
          return (
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: 10,
                borderBottom: i === filteredSettingItems.length - 1 ? '' : `0.5px solid ${colors.grey200}`,
                paddingBottom: 10,
              }}
              key={zoneGroup.groupId}
            >
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <div
                  css={{
                    marginLeft: 5,
                    wordBreak: 'break-word',
                  }}
                >
                  {zoneGroup.groupName}
                </div>
                <CountBadge count={zoneGroup?.irrigationZoneEmitterIds?.length || null} />
              </div>
              <div css={{ textWrap: 'nowrap', whiteSpace: 'nowrap' }}>
                <SettingsDeleteButton
                  onClick={() => {
                    setSelectedZoneGroupDelete({ ...zoneGroup, propertyId })
                  }}
                />
                <SettingsEditButton
                  onClick={() => {
                    setSelectedZoneGroupEdit({ ...zoneGroup, propertyId })
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
