import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import type { Contact, Group, Report } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { showNotification } from 'utils/showNotification'
import { ReportContacts } from '../ReportForm/ReportContacts/Contacts'
import { ReportContent } from '../ReportForm/ReportContent/ReportContent'
import { ReportCoverage } from '../ReportForm/ReportCoverage/ReportCoverage'
import { ReportName } from '../ReportForm/ReportName/ReportName'
import { ReportPestTypes } from '../ReportForm/ReportPestTypes/ReportPestTypes'
import { ReportProperties } from '../ReportForm/ReportProperties/ReportProperties'
import { ReportScheduling } from '../ReportForm/ReportScheduling/ReportScheduling'
import type { TReportType } from '../ReportForm/ReportType/ReportType'
import {
  ReportType,
  WEEKLY_TRAP_CATCHES_REPORT_TYPE,
  WEEKLY_UPDATE_REPORT_TYPE,
} from '../ReportForm/ReportType/ReportType'
import type { WeeklyReport, WeeklyTrapReport } from '../types'
import convertAPIAlertToInitivalValues from '../_utils/convertAPIAlertToInitivalValues'
import type { TPestSelected, TReportStartEnd } from '../_utils/formTypesAndStyles'
import {
  getReports,
  reportCreateQuery,
  reportCreateTrapCatchesQuery,
  reportUpdateQuery,
  reportUpdateTrapCatchesQuery,
} from '../_utils/queries'
import type { TTrapVariables, TVariables } from './_utils/getReportVariablesForQuery'
import {
  getReportVariablesForQuery,
  getTrapReportVariablesForQuery,
} from './_utils/getReportVariablesForQuery'

type CreateReportResponse = {
  data: {
    createWeeklyGeneralReport: { id: string }
    createWeeklyTrapCatchesReport: { id: string }
  }
}

type UpdateReportResponse = {
  data: {
    updateWeeklyGeneralReport: { id: string }
    updateWeeklyTrapCatchesReport: { id: string }
  }
}

type getReportResponse = {
  data: {
    user: {
      reports: Report
    }
  }
}

export const ModalDrawerCreateOrEditReport = ({
  opened,
  reportId,
  onClose,
  selectedReport,
  reportType,
  setReportType,
  isDuplicate,
  setIsDuplicate,
}: {
  opened: boolean
  reportId: string | null
  onClose: () => void
  selectedReport: WeeklyReport | WeeklyTrapReport | null
  reportType: TReportType
  setReportType: Dispatch<SetStateAction<TReportType>>
  isDuplicate: boolean
  setIsDuplicate: Dispatch<SetStateAction<boolean>>
}) => {
  const initialState = convertAPIAlertToInitivalValues({ initialValues: selectedReport, reportType })
  const [typeIsValid, setTypeIsValid] = useState(false)
  // State for properties
  const [reportProperties] = useState<TProperty[]>(initialState.reportProperties)
  const [selectedReportProperties, setSelectedReportProperties] = useState<TOptions[]>([])
  const [propertiesIsValid, setPropertiesIsValid] = useState(false)
  // State for Scheduling
  const [dayOfWeek, setDayOfWeek] = useState<string | null>(initialState.dayOfWeek)
  const [scheduleIsValid, setScheduleIsValid] = useState(true)
  // State for Contacts
  const [contacts, setContacts] = useState<Contact[]>(initialState.contacts)
  const [groups, setGroups] = useState<Group[]>(initialState.groups)
  const [notifyOwnerByEmail, setNotifyOwnerByEmail] = useState(initialState.notifyOwnerByEmail)
  // State for Name
  const [contactsIsValid, setContactsIsValid] = useState(true)
  const [nameIsValid, setNameIsValid] = useState(false)
  const [name, setName] = useState<string | null>(initialState.name)
  // State for weather
  const [weather, setWeather] = useState<boolean>(initialState.weather)
  const [weatherStartEnd, setWeatherStartEnd] = useState<TReportStartEnd | null>(initialState.weatherStartEnd)
  // State for fireblight
  const [fireBlight, setFireBlight] = useState<boolean>(initialState.fireBlight)

  const [fireBlightStartEnd, setFireBlightStartEnd] = useState<TReportStartEnd | null>(
    initialState.fireBlightStartEnd,
  )

  // State for pest control
  const [pestsSelected, setPestsSelected] = useState<TPestSelected | null>(initialState.pests)
  // State for water management
  const [evapotranspiration, setEvapotranspiration] = useState<boolean>(initialState.evapotranspiration)

  const [evapotranspirationStartEnd, setEvapotranspirationBorerStartEnd] = useState<TReportStartEnd | null>(
    initialState.evapotranspirationStartEnd,
  )

  // State for coverage
  const [coverageType, setCoverageType] = useState<string>(initialState.coverageType)
  const [coverageIsValid, setCoverageIsValid] = useState<boolean>(true)
  //State for pest type
  const [pestType, setPestType] = useState<string>(initialState.pestType)
  const [pestsIsValid, setPestsIsValid] = useState<boolean>(true)

  const [trapCatchStartEnd, setTrapCatchStartEnd] = useState<TReportStartEnd | null>(
    initialState.trapCatchStartEnd,
  )

  const [irrigationActivity, setIrrigationActivity] = useState<boolean>(false)

  const [irrigationActivityStartEnd, setIrrigationActivityStartEnd] = useState<TReportStartEnd | null>(
    initialState.irrigationActivityStartEnd,
  )

  const [soilMoisture, setSoilMoisture] = useState<boolean>(false)

  const [soilMoistureStartEnd, setSoilMoistureStartEnd] = useState<TReportStartEnd | null>(
    initialState.soilMoistureStartEnd,
  )

  // State for crop development
  const [chill, setChill] = useState<boolean>(initialState.chill)
  const [chillStartEnd, setChillStartEnd] = useState<TReportStartEnd | null>(initialState.chillStartEnd)
  const [pollination, setPollination] = useState<boolean>(initialState.pollination)

  const [pollinationStartEnd, setPollinationStartEnd] = useState<TReportStartEnd | null>(
    initialState.pollinationStartEnd,
  )

  const [growDegree, setGrowDegree] = useState<boolean>(initialState.growingDegreeDays)

  const [growDegreeStartEnd, setGrowDegreeStartEnd] = useState<TReportStartEnd | null>(
    initialState.growingDegreeDaysStartEnd,
  )

  const [showContentError, setShowContentError] = useState<boolean>(false)
  // STATE FOR KEEPING TRACK OF WHETHER OR NOT WE SENT IT YET
  const [triedToSubmit, setTriedToSubmit] = useState(false)
  const { contacts: allContacts, groups: allContactGroups } = userDetailsStore.useSelector((s) => s)
  const [configurationIsValid, setConfigurationIsValid] = useState(true)

  const resetFormState = (reportInitialValues?: typeof selectedReport) => {
    const defaultInitialState = convertAPIAlertToInitivalValues({
      initialValues: reportInitialValues ?? null,
      reportType,
    })

    setReportType(defaultInitialState.reportTypeId || isDuplicate ? reportType : '')

    setGroups(defaultInitialState.groups)

    setContacts(defaultInitialState.contacts)

    setNotifyOwnerByEmail(defaultInitialState.notifyOwnerByEmail)

    setSelectedReportProperties(defaultInitialState.selectedProperties)

    setDayOfWeek(defaultInitialState.dayOfWeek)

    setName(defaultInitialState.name)

    setWeather(defaultInitialState.weather)

    setFireBlight(defaultInitialState.fireBlight)

    setFireBlightStartEnd(defaultInitialState.fireBlightStartEnd)

    setPestsSelected(defaultInitialState.pests)

    setEvapotranspiration(defaultInitialState.evapotranspiration)

    setEvapotranspirationBorerStartEnd(defaultInitialState.evapotranspirationStartEnd)

    setIrrigationActivity(defaultInitialState.irrigationActivity)

    setIrrigationActivityStartEnd(defaultInitialState.irrigationActivityStartEnd)

    setSoilMoisture(defaultInitialState.soilMoisture)

    setSoilMoistureStartEnd(defaultInitialState.soilMoistureStartEnd)

    setWeatherStartEnd(defaultInitialState.weatherStartEnd)

    setChill(defaultInitialState.chill)

    setChillStartEnd(defaultInitialState.chillStartEnd)

    setPollination(defaultInitialState.pollination)

    setPollinationStartEnd(defaultInitialState.pollinationStartEnd)

    setGrowDegree(defaultInitialState.growingDegreeDays)

    setGrowDegreeStartEnd(defaultInitialState.growingDegreeDaysStartEnd)

    setPestType(defaultInitialState.pestType)

    setCoverageType(defaultInitialState.coverageType)

    setTrapCatchStartEnd(defaultInitialState.trapCatchStartEnd)

    setTriedToSubmit(false)

    setIsDuplicate(false)
  }

  const handleOnClose = () => {
    resetFormState()

    onClose()
  }

  useEffect(() => {
    resetFormState(selectedReport)
  }, [selectedReport, opened])

  useEffect(() => {
    const hasPestSelectedTraps = Object.values(pestsSelected ?? {}).some((p) => p?.trapCatches)
    const hasPestSelectedDegreeDays = Object.values(pestsSelected ?? {}).some((p) => p?.degreeDays)

    const hasContent =
      !!weather ||
      !!chill ||
      !!fireBlight ||
      hasPestSelectedTraps ||
      hasPestSelectedDegreeDays ||
      !!pollination ||
      !!growDegree ||
      !!evapotranspiration ||
      !!soilMoisture ||
      !!irrigationActivity

    const isWeeklyUpdateValid =
      reportType === WEEKLY_UPDATE_REPORT_TYPE &&
      typeIsValid &&
      propertiesIsValid &&
      scheduleIsValid &&
      contactsIsValid &&
      nameIsValid &&
      hasContent &&
      !!name

    const isWeeklyTrapCatchesValid =
      reportType === WEEKLY_TRAP_CATCHES_REPORT_TYPE &&
      typeIsValid &&
      coverageIsValid &&
      scheduleIsValid &&
      pestsIsValid &&
      contactsIsValid &&
      nameIsValid &&
      !!name

    const isValid = reportType === WEEKLY_UPDATE_REPORT_TYPE ? isWeeklyUpdateValid : isWeeklyTrapCatchesValid

    setShowContentError(propertiesIsValid ? !hasContent : false)

    setConfigurationIsValid(isValid)
  }, [
    reportType,
    typeIsValid,
    propertiesIsValid,
    scheduleIsValid,
    contactsIsValid,
    nameIsValid,
    coverageIsValid,
    pestsIsValid,
    pestType,
    coverageType,
    triedToSubmit,
    name,
    weather,
    chill,
    fireBlight,
    pestsSelected,
    pollination,
    growDegree,
    evapotranspiration,
    soilMoisture,
    irrigationActivity,
  ])

  useEffect(() => {
    const updatedGroups = groups
      .map((group) => {
        const groupInAllGroups = allContactGroups.find((g) => g.id === group.id)

        if (groupInAllGroups) return { ...groupInAllGroups, alertsContactMethods: group.alertsContactMethods }

        return null
      })
      .filter((g) => g !== null)

    const updatedContacts = contacts
      .map((contact) => {
        const contactInAllContacts = allContacts.find((c) => c.id === contact.id)

        if (contactInAllContacts)
          return { ...contactInAllContacts, alertsContactMethods: contact.alertsContactMethods }

        return null
      })
      .filter((c) => c !== null)

    setGroups(updatedGroups as Group[])

    setContacts(updatedContacts as Contact[])
  }, [allContacts, allContactGroups])

  const handleSubmit = () => {
    if (!triedToSubmit) setTriedToSubmit(true)

    if (configurationIsValid) {
      const propertyIds = selectedReportProperties
        ? Object.values(selectedReportProperties).map((obj) => obj.value as string)
        : null

      const variables = getReportVariablesForQuery({
        reportId: reportId ?? undefined,
        groups,
        name,
        contacts,
        notifyOwnerByEmail: notifyOwnerByEmail,
        rules: {
          dayOfWeek,
          propertyIds: propertyIds,
          weather: weather ? weatherStartEnd : null,
          fireBlight: fireBlight ? fireBlightStartEnd : null,
          pollination: pollination ? pollinationStartEnd : null,
          growingDegreeDays: growDegree ? growDegreeStartEnd : null,
          evapotranspiration: evapotranspiration ? evapotranspirationStartEnd : null,
          irrigationActivity: irrigationActivity ? irrigationActivityStartEnd : null,
          irrigationPlanner: null,
          soilMoisture: soilMoisture ? soilMoistureStartEnd : null,
          chill: chill ? chillStartEnd : null,
          scout: null,
          pests: pestsSelected,
        },
      })

      const trapVariables = getTrapReportVariablesForQuery({
        reportId: reportId ?? undefined,
        groups,
        name,
        contacts,
        notifyOwnerByEmail: notifyOwnerByEmail,
        rules: {
          dayOfWeek,
          propertyIds: propertyIds,
          startDate: trapCatchStartEnd?.startDate,
          endDate: trapCatchStartEnd?.endDate,
          pests:
            pestType === translate.phrases.banyanApp('Include the following selected pests')
              ? pestsSelected
              : null,
        },
      })

      reportId ? handleEditReport(variables, trapVariables) : handleCreateReport(variables, trapVariables)
    }
  }

  const handleEditReport = async (variables: TVariables, trapVariables: TTrapVariables) => {
    try {
      const response: UpdateReportResponse = await fetchApiCustomer({
        body: {
          query: reportType === WEEKLY_UPDATE_REPORT_TYPE ? reportUpdateQuery : reportUpdateTrapCatchesQuery,
          variables: {
            data: reportType === WEEKLY_UPDATE_REPORT_TYPE ? variables : trapVariables,
          },
        },
      })

      const updatedReportId =
        reportType === WEEKLY_UPDATE_REPORT_TYPE
          ? response?.data?.updateWeeklyGeneralReport?.id
          : response?.data?.updateWeeklyTrapCatchesReport?.id

      if (updatedReportId) {
        const reportResponse: getReportResponse = await fetchApiCustomer({
          body: {
            query: getReports,
          },
        })

        const newReport: Report = { ...reportResponse?.data?.user?.reports }

        userDetailsStore.setState((s) => {
          return {
            ...s,
            reports: newReport,
          }
        })

        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('Report successfully updated'),
        })

        handleOnClose()
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to update report'),
        })

        onClose()
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to update report'),
      })

      onClose()
    }
  }

  const handleCreateReport = async (variables: TVariables, trapVariables: TTrapVariables) => {
    try {
      const response: CreateReportResponse = await fetchApiCustomer({
        body: {
          query: reportType === WEEKLY_UPDATE_REPORT_TYPE ? reportCreateQuery : reportCreateTrapCatchesQuery,
          variables: {
            data: reportType === WEEKLY_UPDATE_REPORT_TYPE ? variables : trapVariables,
          },
        },
      })

      const newReportId =
        reportType === WEEKLY_UPDATE_REPORT_TYPE
          ? response?.data?.createWeeklyGeneralReport?.id
          : response?.data?.createWeeklyTrapCatchesReport?.id

      if (newReportId) {
        const reportResponse: getReportResponse = await fetchApiCustomer({
          body: {
            query: getReports,
          },
        })

        const newReport: Report = { ...reportResponse?.data?.user?.reports }

        userDetailsStore.setState((s) => {
          return {
            ...s,
            reports: newReport,
          }
        })

        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('Report successfully created'),
        })

        handleOnClose()
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to create report'),
        })
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to create report'),
      })
    }
  }

  const submitButtonText = reportId
    ? translate.phrases.banyanApp('Update Report')
    : translate.phrases.banyanApp('Create Report')

  return (
    <ModalDrawer
      title={
        reportId
          ? translate.phrases.banyanApp('Update Report - {{reportName}}', { reportName: name })
          : translate.phrases.banyanApp('Create New Report')
      }
      opened={opened}
      onClose={handleOnClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 100}
      primaryButtonText={submitButtonText}
      primaryButtonOnPress={handleSubmit}
    >
      <div css={{ padding: 20 }}>
        <ReportType
          reportId={reportId}
          setReportType={setReportType}
          reportType={reportType}
          triedToSubmit={triedToSubmit}
          setTypeIsValid={setTypeIsValid}
        />
        {reportType || reportId ? (
          <>
            {reportType === WEEKLY_UPDATE_REPORT_TYPE ? (
              <ReportProperties
                properties={reportProperties}
                selectedReportProperties={selectedReportProperties}
                setSelectedReportProperties={setSelectedReportProperties}
                setPropertiesIsValid={setPropertiesIsValid}
                triedToSubmit={triedToSubmit}
              />
            ) : null}
            <ReportScheduling
              dayOfWeek={dayOfWeek}
              setDayOfWeek={setDayOfWeek}
              setScheduleIsValid={setScheduleIsValid}
              triedToSubmit={triedToSubmit}
              pestType={pestType}
              trapCatchStartEnd={trapCatchStartEnd}
              setTrapCatchStartEnd={setTrapCatchStartEnd}
              reportType={reportType}
            />
            {reportType === WEEKLY_TRAP_CATCHES_REPORT_TYPE ? (
              <>
                <ReportPestTypes
                  selectedReportProperties={selectedReportProperties}
                  properties={reportProperties}
                  pestType={pestType}
                  setPestType={setPestType}
                  pestsSelected={pestsSelected}
                  setPestsSelected={setPestsSelected}
                  setPestsIsValid={setPestsIsValid}
                  triedToSubmit={triedToSubmit}
                />
                <ReportCoverage
                  selectedReportProperties={selectedReportProperties}
                  properties={reportProperties}
                  setSelectedReportProperties={setSelectedReportProperties}
                  coverageType={coverageType}
                  setCoverageType={setCoverageType}
                  setCoverageIsValid={setCoverageIsValid}
                  triedToSubmit={triedToSubmit}
                />
              </>
            ) : null}
            {reportType === WEEKLY_UPDATE_REPORT_TYPE ? (
              <ReportContent
                weather={weather}
                setWeather={setWeather}
                weatherStartEnd={weatherStartEnd}
                setWeatherStartEnd={setWeatherStartEnd}
                fireBlight={fireBlight}
                setFireBlight={setFireBlight}
                fireBlightStartEnd={fireBlightStartEnd}
                setFireBlightStartEnd={setFireBlightStartEnd}
                pestsSelected={pestsSelected}
                setPestsSelected={setPestsSelected}
                evapotranspiration={evapotranspiration}
                setEvapotranspiration={setEvapotranspiration}
                evapotranspirationStartEnd={evapotranspirationStartEnd}
                setEvapotranspirationBorerStartEnd={setEvapotranspirationBorerStartEnd}
                chill={chill}
                setChill={setChill}
                chillStartEnd={chillStartEnd}
                setChillStartEnd={setChillStartEnd}
                pollination={pollination}
                setPollination={setPollination}
                pollinationStartEnd={pollinationStartEnd}
                setPollinationStartEnd={setPollinationStartEnd}
                growDegree={growDegree}
                setGrowDegree={setGrowDegree}
                growDegreeStartEnd={growDegreeStartEnd}
                setGrowDegreeStartEnd={setGrowDegreeStartEnd}
                selectedReportProperties={selectedReportProperties}
                reportProperties={reportProperties}
                irrigationActivity={irrigationActivity}
                setIrrigationActivity={setIrrigationActivity}
                irrigationActivityStartEnd={irrigationActivityStartEnd}
                setIrrigationActivityStartEnd={setIrrigationActivityStartEnd}
                soilMoisture={soilMoisture}
                setSoilMoisture={setSoilMoisture}
                soilMoistureStartEnd={soilMoistureStartEnd}
                setSoilMoistureStartEnd={setSoilMoistureStartEnd}
                showContentError={showContentError}
                triedToSubmit={triedToSubmit}
              />
            ) : null}
            {/* CONTACTS */}
            <ReportContacts
              allContacts={allContacts}
              allContactGroups={allContactGroups}
              contacts={contacts}
              contactsIsValid={contactsIsValid}
              groups={groups}
              notifyOwnerByEmail={notifyOwnerByEmail}
              setContacts={setContacts}
              setContactsIsValid={setContactsIsValid}
              setGroups={setGroups}
              setNotifyOwnerByEmail={setNotifyOwnerByEmail}
            />

            {/* NAME */}
            <ReportName
              name={name}
              setName={setName}
              setNameIsValid={setNameIsValid}
              triedToSubmit={triedToSubmit}
            />
          </>
        ) : null}
      </div>
    </ModalDrawer>
  )
}
