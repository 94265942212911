import { sortNumber } from 'utils/sortNumber'
import type { TValueGroup } from './selectedValueGroupsStore'
import { selectedValueGroupsStore, valueGroupsWithPrimaryAllowed } from './selectedValueGroupsStore'

export const getPrimaryValueGroup = (
  selectedValueGroupsStoreState = selectedValueGroupsStore.getState(),
): TValueGroup | null => {
  if (selectedValueGroupsStoreState.primaryValueGroup) return selectedValueGroupsStoreState.primaryValueGroup

  const mostRecentValidValueGroup = Object.entries(selectedValueGroupsStoreState.selectedValueGroups)
    .filter(([valueGroup, selectionTime]) => {
      if (!selectionTime) return false

      if (!valueGroupsWithPrimaryAllowed[valueGroup as TValueGroup]) return false

      return true
    })
    .sort((a, b) => sortNumber(Number(a[1]), Number(b[1])) * -1)?.[0]?.[0]

  return (mostRecentValidValueGroup as TValueGroup) ?? null
}
