import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import type { TEmitterConfiguration } from '../EmitterConfiguration/types'

const requiredPermission = 'VIEW_IRRIGATION_PLANNER'

type TEmitterConfigurationFromFASS = {
  id: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  zoneId: number
  flowRate: number
  unitPerHour: 'INCH' | 'MILLIMETER'
  emitterType: TFieldAssetKeyTypes.TEmitterType
  propertyId: number
  zoneName: string
}

export const getEmitterConfiguration = (
  properties: routes.UserAppStartup.Response['properties'],
  permissions: routes.UserAppStartup.Response['user']['permissions'],
) => {
  const propertyIdsByRequiredPermission = Object.keys(permissions[requiredPermission] ?? {})
  const emitterConfigurationsFromFASS: TEmitterConfigurationFromFASS[] = []

  propertyIdsByRequiredPermission.forEach((propertyId) => {
    const property = properties?.[Number(propertyId)]

    if (!property) return

    const emitterConfigurationData = Object.values(property.irrigationZoneEmitters ?? {}).map((zone) => {
      return {
        id: zone.irrigationZoneEmitterId,
        zoneId: zone.irrigationZoneId,
        flowRate: zone.flowRate.rate,
        unitPerHour: zone.flowRate.unitsPerHour,
        emitterType: zone.emitterType,
        propertyId: Number(propertyId),
        zoneName: zone.name,
      }
    })

    emitterConfigurationsFromFASS.push(...emitterConfigurationData)
  })

  const shapedData: TEmitterConfiguration = {}

  emitterConfigurationsFromFASS.forEach((zoneData) => {
    const { id, zoneId, flowRate, unitPerHour, emitterType, propertyId, zoneName } = zoneData

    shapedData[propertyId] = {
      propertyId,
      propertyName: properties?.[Number(propertyId)]?.propertyName ?? '',
      irrigationZones: {
        ...shapedData[propertyId]?.irrigationZones,
        [zoneId]: {
          zoneId,
          zoneName,
          flowRate: {
            ...shapedData[propertyId]?.irrigationZones[zoneId]?.flowRate,
            [emitterType]: {
              id,
              flowRate: Number(flowRate),
              unitPerHour,
              emitterType: emitterType as TFieldAssetKeyTypes.TEmitterType,
            },
          },
        },
      },
    }
  })

  return shapedData
}
