import { SharedSettings } from '../settings/SharedSettings'
import { redirectRoute } from './redirectRoute'

export const setTokenAndPushHome = async (token: string) => {
  if (token) {
    localStorage.setItem(SharedSettings.LOCAL_STORAGE_KEYS.AUTH_TOKEN, token)

    redirectRoute('/')
  }
}
