import type { CSSObject } from '@emotion/react'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import type { ReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { makeReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import type { TPropertyBadgeComponentProps } from './PropertyBadgeComponent/PropertyBadgeComponent'
import { PropertyBadgeComponent } from './PropertyBadgeComponent/PropertyBadgeComponent'

export type PROPERTY_META = {
  propertyName: string
  propertyId: string
  bounds: google.maps.LatLngBounds
}

// TODO: will eventually come from current values type
export type TPropertiesValueTypesToPropsDictionary = Record<
  string,
  {
    baseZIndex?: number
    children: React.ReactNode
    getContainerCSS: () => CSSObject
    onHover: boolean
  }
>

// export type BLOCK_SCDS_VALUE = Record<SCD_LAT_LNG, Record<string, COMPONENT_PROPS>>
export class PropertyValuesCache extends MapItemsCache<
  PROPERTY_META,
  TPropertiesValueTypesToPropsDictionary,
  ReactComponentOverlayView<TPropertyBadgeComponentProps>
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        return makeReactComponentOverlayView({
          component: PropertyBadgeComponent,
          transform: 'translate(-50%, -110%)',
          latLng: {
            lat: o.meta.bounds.getNorthEast().lat(),
            lng: o.meta.bounds.getCenter().lng(),
          },
          maps: o.maps,
          map: o.map,
        })
      },
      renderOverlay: (o) => {
        o.overlay.render({ ...o, valueTypesToPropsDictionary: o.value })
      },
      hideOverlay: (o) => {
        o.overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
