import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'
import { pick } from 'lodash'
import type { init } from 'stores/_utils/simple-store'
import { ionicStore } from '../../../../ionicStorage'

export const subscribeToStoreForPersistedState = <TPersistentInitState extends Record<string, unknown>>({
  keysToPutInPersistentStorage,
  store,
  storeName,
}: {
  keysToPutInPersistentStorage: Record<keyof TPersistentInitState, boolean>
  store: ReturnType<typeof init<TPersistentInitState>>
  storeName: string
}) => {
  const keysToListenTo: (keyof TPersistentInitState)[] = Object.keys(keysToPutInPersistentStorage).filter(
    (key) => keysToPutInPersistentStorage[key],
  )

  store.subscribeToChanges(
    (s) => pick(s, keysToListenTo),
    (state) => {
      ionicStore.set(storeName, {
        ...state,
        storeVersion: APP_PLATFORM_VERSION,
        timestamp: Date.now(),
      })
    },
  )
}
