import { StaticAssetsLink } from 'components/StaticAssetsLink/StaticAssetsLink'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const AcceptancePackageLinks = () => {
  return (
    <div
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        justifyContent: 'flex-end',
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        backgroundColor: colors.white,
        width: '100%',
      }}
    >
      <StaticAssetsLink asset="privacy" label={translate.phrases.banyanApp('Privacy Policy')} />
      <StaticAssetsLink asset="sula" label={translate.phrases.banyanApp('Software User License Agreement')} />
    </div>
  )
}
