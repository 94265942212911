import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const getPointLngLatsForIrrigationEmitterZoneId = ({
  propertyId,
  irrigationEmitterZoneId,
}: {
  propertyId: TFieldAssetKeyTypes.TPropertyId
  irrigationEmitterZoneId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
}): Array<TFieldAssetKeyTypes.TLngLat> => {
  const property = fieldAssetStore.getState()?.properties?.[propertyId]

  if (property) {
    const pointsForZone = Object.values(property.points || {}).filter((p) =>
      p.irrigationEmitterZoneIds?.includes(irrigationEmitterZoneId),
    )

    return pointsForZone.map((p) => p.lngLat)
  }

  return []
}
