import type { TLanguage } from '@semios/app-platform-i18n-core'
import { translate } from 'i18n/i18n'

export const getLanguageLabels = (): Record<TLanguage, string> => {
  return {
    'en': translate.phrases.banyanApp('English (US)'),
    'en-AU': translate.phrases.banyanApp('English (Australia)'),
    'es': translate.phrases.banyanApp('Español (Estados Unidos)'),
    'it': translate.phrases.banyanApp('Italiano (Italia)'),
  }
}
