import { Capacitor } from '@capacitor/core'
import type { ScreenOrientationResult } from '@capacitor/screen-orientation'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import type { TCurrentTabSelection } from 'App/Map/UserSettingsMenu/types'
import type { SafeAreaInsets } from 'capacitor-plugin-safe-area/dist/esm/definitions'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { useScreenSize } from 'utils/useScreenSize'
import { userDetailsStore } from './userDetailsStore'

const storeName = 'smallStore'

export type TAppliedIrrigationActivityDisplayOption = 'DURATION' | 'VOLUME'

export type TMobileScreenOrientationType = 'landscape' | 'portrait' | null

export type TSmallStore = {
  appliedIrrigationActivityDisplay: TAppliedIrrigationActivityDisplayOption
  safeArea: SafeAreaInsets
  mobileScreenOrientation: TMobileScreenOrientationType //notApplicable is for web
  selectedPreset: string | null
  showSettingsModal: boolean
  showWindMachineViewDetails: boolean
  showWeatherWidgetModal: boolean
  settingsModalSelectedTab: TCurrentTabSelection
  showDataSource: boolean
  showDataSourceTooltip: boolean
  showRegionalData: boolean
}

export const smallStore = persistentInit<TSmallStore>({
  initialState: {
    appliedIrrigationActivityDisplay: 'DURATION',
    safeArea: { insets: { top: 0, left: 0, bottom: 0, right: 0 } },
    mobileScreenOrientation: null,
    selectedPreset: null,
    settingsModalSelectedTab: 'mainMenu',
    showSettingsModal: false,
    showWindMachineViewDetails: false,
    showWeatherWidgetModal: false,
    showDataSource: true,
    showDataSourceTooltip: false,
    showRegionalData: false,
  },
  keysToPutInPersistentStorage: {
    appliedIrrigationActivityDisplay: true,
    safeArea: false,
    mobileScreenOrientation: false,
    selectedPreset: true,
    settingsModalSelectedTab: false,
    showSettingsModal: true,
    showWindMachineViewDetails: true,
    showWeatherWidgetModal: true,
    showDataSource: true,
    showDataSourceTooltip: false,
    showRegionalData: false,
  },
  keysToPutInURL: {
    appliedIrrigationActivityDisplay: false,
    safeArea: false,
    mobileScreenOrientation: false,
    selectedPreset: true,
    settingsModalSelectedTab: false,
    showSettingsModal: false,
    showWindMachineViewDetails: false,
    showWeatherWidgetModal: false,
    showDataSource: false,
    showDataSourceTooltip: false,
    showRegionalData: false,
  },
  storeName,
})

function getDeviceScreenOrientationType(orientation: ScreenOrientationResult): TMobileScreenOrientationType {
  if (!Capacitor.isNativePlatform()) return null
  else if (orientation.type.match(/landscape/)) return 'landscape'
  else if (orientation.type.match(/portrait/)) return 'portrait'
  else return null
}

ScreenOrientation.addListener('screenOrientationChange', (orientation: ScreenOrientationResult) => {
  smallStore.setState((s) => ({
    ...s,
    mobileScreenOrientation: getDeviceScreenOrientationType(orientation),
  }))
})

export const initDeviceScreenOrientation = async () => {
  const orientation = await ScreenOrientation.orientation()

  smallStore.setState((s) => ({
    ...s,
    mobileScreenOrientation: getDeviceScreenOrientationType(orientation),
  }))
}

/**
 * Hook to get the screen orientation, if it's a mobile device, it will return the device's screen orientation
 * @returns 'landscape' | 'portrait'
 */
export const useScreenOrientation = () => {
  const mobileScreenOrientation = smallStore.useSelector((s) => s.mobileScreenOrientation)
  const screenSize = useScreenSize()

  if (mobileScreenOrientation === null) {
    //web or desktop
    return screenSize.screenWidth > screenSize.screenHeight ? 'landscape' : 'portrait'
  } else {
    return mobileScreenOrientation
  }
}

userDetailsStore.subscribeToChanges(
  (s) => s.isFreeRegionalUser,
  (isFreeRegionalUser) => {
    if (isFreeRegionalUser) {
      smallStore.setState((s) => ({ ...s, showRegionalData: true }))
    } else {
      smallStore.setState((s) => ({ ...s, showRegionalData: false }))
    }
  },
)
