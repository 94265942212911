import type { Options } from 'highcharts/highstock'
import { translate } from 'i18n/i18n'
import { lineChartTooltipFormatter } from './lineChartTooltipFormatter/lineChartTooltipFormatter'

export const baseLineChartOptions = (firstForecastTimestamp: number = +new Date()) => {
  const options: Options = {
    legend: {
      enabled: true,
      align: 'left',
      margin: 6,
      itemStyle: {
        left: '12px',
        padding: '12px',
        fontSize: '14px',
      },
    },
    plotOptions: {
      line: {
        zoneAxis: 'x',
        zones: [
          {
            value: firstForecastTimestamp + 1000,
          },
          {
            dashStyle: 'Dot',
          },
        ],
        zIndex: 1,
        marker: { enabled: false },
        lineWidth: 2,
        showInLegend: true,
        visible: true,
      },
      area: {
        zIndex: 1,
      },
      arearange: {
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      column: {
        /**
         * this stops reserving space for columns so that the
         * chart area that gets plotted won't conditionally
         * be expanded horizontally (e.g. slightly before the
         * starting date) when column series are present
         */
        pointRange: 0,
      },
      series: {
        states: {
          // Not sure if we want to keep this in here. It differs from customer-web.
          hover: {
            enabled: true,
            halo: null,
            shadow: false,
          },
        },
        turboThreshold: 0,
        // highlight all lines when hovering one line
        dataGrouping: {
          enabled: false,
        },
        // triangle and other shapes when hovering
        marker: {
          enabled: false,
          symbol: 'circle', // TODO: use same symbol from customer-web?
        },
      },
    },
    tooltip: {
      formatter: function (tooltip) {
        return lineChartTooltipFormatter(this, tooltip, firstForecastTimestamp)
      },
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderRadius: 8,
      borderWidth: 0,
      followPointer: true,
      hideDelay: 0,
      outside: false,
      padding: 12,
      shadow: true,
      style: {
        display: 'block',
        fontFamily: 'Roboto, "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        fontSize: '16px',
        opacity: 0.98,
      },
      useHTML: true,
      valueDecimals: 0,
    },
    xAxis: {
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      // disabling this will hide dates/time on chart x axis
      // visible: false,
      dateTimeLabelFormats: {
        minute: '%H:%M',
        hour: '%l %P',
        day: '%C',
        week: '%C',
        month: '%b',
        year: '%Y',
      },
      plotBands: [
        {
          id: 'plotBand-forecast',
          color: 'rgba(215,215,215,.25)',
          from: firstForecastTimestamp,
          to: 999999999999999,
          zIndex: 1,
          label: {
            text: translate.phrases.banyanApp('Forecast'),
            y: -4,
            x: -10,
            align: 'right',
            style: {
              fontSize: '14px',
              zIndex: 99999999,
            },
            useHTML: true,
          },
        },
      ],
      type: 'datetime',
    },
    yAxis: {
      softMax: 1, // this keeps series (such as rain) at the bottom of the y axis if they are constantly 0
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
  }

  return options
}
