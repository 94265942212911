type TValue = unknown | null

type TValueAggregated = {
  min: TValue
  max: TValue
  average: TValue
}

export const isAggregatedType = (value: TValue | TValueAggregated | null): value is TValueAggregated => {
  return typeof value === 'object'
}
