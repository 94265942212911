import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconCloudy = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 350 222')}>
      <g clipPath="url(#clip0_11_468)">
        <g clipPath="url(#clip1_11_468)">
          <path
            d="M291 107C290.15 107 289.32 107.09 288.47 107.13C293.294 87.7023 291.013 67.1901 282.039 49.2967C273.065 31.4034 257.989 17.3083 239.534 9.55632C221.078 1.80437 200.459 0.906499 181.399 7.02481C162.339 13.1431 146.095 25.8744 135.6 42.9199C127.108 37.8427 117.42 35.11 107.526 35.0009C97.6323 34.8918 87.8868 37.4103 79.2846 42.2991C70.6824 47.1879 63.5316 54.272 58.5624 62.828C53.5932 71.3841 50.9835 81.1056 50.9999 90.9999C51.0218 94.0432 51.2893 97.0797 51.7999 100.08C37.1521 102.897 24.0788 111.069 15.1298 123.003C6.18078 134.936 1.99767 149.776 3.39631 164.626C4.79495 179.477 11.675 193.274 22.6948 203.327C33.7145 213.379 48.0837 218.967 62.9999 219C64.3499 219 65.6699 218.89 66.9999 218.8V219H291C305.852 219 320.096 213.1 330.598 202.598C341.1 192.096 347 177.852 347 163C347 148.148 341.1 133.904 330.598 123.402C320.096 112.9 305.852 107 291 107Z"
            fill="url(#paint0_linear_11_468)"
            stroke="#CAD6E8"
            strokeWidth="6"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_468"
          x1="99.4499"
          y1="30.6799"
          x2="232.64"
          y2="261.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F7FE" />
          <stop offset="0.45" stopColor="#F3F7FE" />
          <stop offset="1" stopColor="#DEEAFB" />
        </linearGradient>
        <clipPath id="clip0_11_468">
          <rect width="350" height="222" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_468">
          <rect width="350" height="222" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
