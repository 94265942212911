import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { pointCategories } from 'stores/selectedFieldAssetsStore'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'
import { getArgsForAnchorCoordinates } from '../getArgsForAnchorCoordinates'

export type TSetByArg = Partial<Record<TPointCategory, TFieldAssetKeyTypes.TLngLat | null>>

export const argIsForPoint = (arg: object): arg is TSetByArg => {
  return pointCategories.some((c) => Object.keys(arg).includes(c))
}

export const setBy = (
  arg: TSetByArg,
  currentState: TSelectedFieldAssetsStoreState,
): Partial<TSelectedFieldAssetsStoreState> | null => {
  const [pointCategory, lngLat] = Object.entries(arg)[0]

  if (!lngLat || !pointCategory) return null

  const allProperties = fieldAssetStore.getState()?.properties

  const property = Object.values(allProperties ?? {}).find((propertyData) => {
    return !!propertyData?.points?.[lngLat]
  })

  if (!property || !property?.points?.[lngLat]) {
    return null
  }

  const anchorCoordinates = lngLatStringToLngLatObject(lngLat)
  const overallResult = getArgsForAnchorCoordinates({ property, anchorCoordinates })

  // is property same as currently selected property, then we just let user specify for this category but not all the other point categories
  if (property.propertyId === currentState.property) {
    return {
      ...currentState,
      [pointCategory]: lngLat,
      block: overallResult.block,
      irrigationZoneEmitter: overallResult.irrigationZoneEmitter,
      lngLat: overallResult.lngLat,
    }
  }

  return overallResult
}
