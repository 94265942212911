import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'

export const ScheduleWording = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
  const timezone = moment.tz.guess() || 'America/Los_Angeles'

  return (
    <translate.Phrases.banyanApp
      k="during {{dateA}} through {{dateB}}"
      v={{
        dateA: translate.dates.format(moment.tz(startDate, timezone), 'MMM D'),
        dateB: translate.dates.format(moment.tz(endDate, timezone), 'MMM D'),
      }}
    />
  )
}
