import { IconHamburgerMenu } from 'components/icons/IconHamburgerMenu'
import { SemiosLogo } from 'components/SemiosLogo/SemiosLogo'
import React from 'react'
import { alphaColorReplacer, colors } from 'settings/colors'
import { useScreenSize } from 'utils/useScreenSize'

interface UpperLogoLineProps {
  menuCollapsed: boolean
  onToggleExpand: () => void
}

export const UpperLogoLine: React.FC<UpperLogoLineProps> = ({ menuCollapsed, onToggleExpand }) => {
  const { isWideScreen } = useScreenSize()

  return (
    <div
      css={{
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        height: 62,
        alignItems: 'center',
        paddingLeft: 20,
        columnGap: 60,
        position: 'sticky',
        top: 0,
        background: menuCollapsed
          ? 'unset'
          : `linear-gradient(180deg,
             ${alphaColorReplacer(colors.midnight, 0.98)} 0%,
             ${alphaColorReplacer(colors.midnight, 0.95)} 70%,
              transparent 100%
            )`,
        zIndex: 10000,
      }}
      // TODO: ask QA and Design if we should only allow clicking this whole div when closed
      onClick={onToggleExpand}
    >
      <div
        css={{
          color: 'white',
          fontSize: 20,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div css={{ width: menuCollapsed ? 20 : 0, transition: '100ms ease all', overflow: 'hidden' }}>
          <IconHamburgerMenu />
        </div>
        <div
          css={{
            marginTop: 7,
            marginLeft: menuCollapsed ? 25 : 0,
            transition: '200ms ease all',
            clipPath:
              isWideScreen || !menuCollapsed
                ? 'polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)'
                : 'polygon(0 0%, 30% 0%, 10% 100%, 0% 100%)',
          }}
        >
          <SemiosLogo width={155} />
        </div>
      </div>
    </div>
  )
}
