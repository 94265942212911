import { useMantineTheme } from '@mantine/core'

export const TopSectionItems = ({
  topSectionLabel,
  bottomSectionLabel,
  renderTopSectionItems,
}: {
  topSectionLabel?: React.ReactNode
  bottomSectionLabel?: React.ReactNode
  renderTopSectionItems: () => React.ReactNode
}) => {
  const theme = useMantineTheme()

  return (
    <div>
      <div
        css={{
          padding: 10,
          fontSize: 12,
          lineHeight: '14px',
          color: theme.colors.grey[2],
        }}
      >
        {topSectionLabel}
      </div>
      {renderTopSectionItems()}
      <div
        css={{
          padding: 10,
          fontSize: 12,
          lineHeight: '14px',
          color: theme.colors.grey[2],
        }}
      >
        {bottomSectionLabel}
      </div>
    </div>
  )
}
