import React, { useEffect, useRef } from 'react'

// TODO: We may only want to fire this when the multiselect is open
const useOutsideClickAlerter = (
  ref: React.RefObject<HTMLDivElement>,
  clickEvent: (event: MouseEvent) => void,
): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node

      if (ref.current && !ref.current.contains(target)) {
        clickEvent(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, clickEvent])
}

export const OutsideClickAlerter = ({
  children,
  outsideClick,
}: {
  children: React.ReactNode
  outsideClick: (event: MouseEvent) => void
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useOutsideClickAlerter(wrapperRef, outsideClick)

  return <div ref={wrapperRef}>{children}</div>
}
