import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

type TWindSpeedUnit = 'mph' | 'kph' | 'mps'

const DEFAULT_DECIMAL_PLACES_WIND_SPEED = 1
const MILES_PER_KILOMETRE = 0.6213711922374
const KPH_PER_MPS = 3.6

const windSpeed = (
  windSpeed: number | null = null,
  {
    decimalPlaces = DEFAULT_DECIMAL_PLACES_WIND_SPEED,
    inputUnit = 'mps',
    outputUnit = userDetailsStore.getState().speed === 'METRIC' ? 'kph' : 'mph',
  }: TUnitConverterOptionsWithUnits<TWindSpeedUnit> = {},
): TUnitConverterTypicalReturn => {
  let convertedValue: number | null

  const shouldConvertKPHToMPH = inputUnit === 'kph' && outputUnit === 'mph'
  const shouldConvertKPHToMPS = inputUnit === 'kph' && outputUnit === 'mps'
  const shouldConvertMPHToKPH = inputUnit === 'mph' && outputUnit === 'kph'
  const shouldConvertMPHToMPS = inputUnit === 'mph' && outputUnit === 'mps'
  const shouldConvertMPSToKPH = inputUnit === 'mps' && outputUnit === 'kph'
  const shouldConvertMPSToMPH = inputUnit === 'mps' && outputUnit === 'mph'

  if (isNil(windSpeed)) {
    convertedValue = null
  } else if (shouldConvertKPHToMPH) {
    convertedValue = windSpeed * MILES_PER_KILOMETRE
  } else if (shouldConvertKPHToMPS) {
    convertedValue = windSpeed / KPH_PER_MPS
  } else if (shouldConvertMPHToKPH) {
    convertedValue = windSpeed / MILES_PER_KILOMETRE
  } else if (shouldConvertMPHToMPS) {
    convertedValue = windSpeed * (MILES_PER_KILOMETRE * KPH_PER_MPS)
  } else if (shouldConvertMPSToKPH) {
    convertedValue = windSpeed * KPH_PER_MPS
  } else if (shouldConvertMPSToMPH) {
    convertedValue = windSpeed * (MILES_PER_KILOMETRE * KPH_PER_MPS)
  } else {
    convertedValue = windSpeed
  }

  const decimalPlacesToUse = decimalPlaces ?? DEFAULT_DECIMAL_PLACES_WIND_SPEED

  let unitSymbol: string
  let valueAsString: string
  let valueWithSuffix: string

  if (outputUnit === 'mph') {
    unitSymbol = translate.measurements.milesPerHour.unit()

    valueAsString = translate.measurements.milesPerHour.value(convertedValue, decimalPlacesToUse)

    valueWithSuffix = translate.measurements.milesPerHour.valueWithUnit(convertedValue, decimalPlacesToUse)
  } else if (outputUnit === 'kph') {
    unitSymbol = translate.measurements.kilometersPerHour.unit()

    valueAsString = translate.measurements.kilometersPerHour.value(convertedValue, decimalPlacesToUse)

    valueWithSuffix = translate.measurements.kilometersPerHour.valueWithUnit(
      convertedValue,
      decimalPlacesToUse,
    )
  } else {
    unitSymbol = translate.measurements.metersPerSecond.unit()

    valueAsString = translate.measurements.metersPerSecond.value(convertedValue, decimalPlacesToUse)

    valueWithSuffix = translate.measurements.metersPerSecond.valueWithUnit(convertedValue, decimalPlacesToUse)
  }

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Wind'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Wind'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_WIND_SPEED,
    suffix: () => unitSymbol,
    shortTitle: () => translate.phrases.banyanApp('Speed'),
    title: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Wind'),
        unitSymbol,
      }),
    titleWithoutUnit: () => translate.phrases.banyanApp('Wind Speed'),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlacesToUse)
    },
    valueAsString: () => valueAsString,
    valueWithSuffix: () => valueWithSuffix,
    valueWithNoRounding: () => convertedValue,
  }
}

export const wind = {
  windSpeed,
}
