const pointRegex = /POINT\((-?\d+\.\d+) (-?\d+\.\d+)\)/

export const parsePoint = (pointStr?: string) => {
  const match = pointStr?.match(pointRegex)

  return {
    lat: match?.[1] ? Number(match[1]) : null,
    lng: match?.[2] ? Number(match[2]) : null,
  }
}
