import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { useScreenSize } from 'utils/useScreenSize'
import { WarningIcon } from '../../../../Authentication/AuthenticationForm/WarningIcon'

export const ErrorMessage = ({
  showErrorMessage,
  onCloseButtonPress,
  message,
}: {
  showErrorMessage: boolean
  onCloseButtonPress: () => void
  message: string
}) => {
  const { isWideScreen } = useScreenSize()

  return showErrorMessage ? (
    <div
      css={{
        zIndex: 9999,
        position: 'absolute',
        left: 20,
        right: 0,
        top: 20,
        width: isWideScreen ? '60%' : '90%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 3,
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        backgroundColor: colors.grey50,
        padding: 12,
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <div css={{ paddingRight: 15 }}>
          <WarningIcon size={23} />
        </div>
        <div css={{ fontSize: 14 }}>
          <b>{translate.phrases.banyanApp('Error')}</b>
          <div>{message}</div>
        </div>
      </div>
      <div
        css={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', cursor: 'pointer' }}
        onClick={onCloseButtonPress}
      >
        <CloseIcon size={12} />
      </div>
    </div>
  ) : null
}
