import { css } from '@emotion/css'
import { Anchor, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useState } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { updateUserProfile } from 'utils/updateUserProfile'
import { z } from 'zod'
import { WarningIcon } from '../../../../../../../App/Authentication/AuthenticationForm/WarningIcon'
import { EditIcon } from '../EditIcon'
import type { InputPropsType } from './SmsNumberForm'
import { SmsNumberForm } from './SmsNumberForm'

export const SmsNumber: FC = () => {
  const theme = useMantineTheme()
  const { smsNumber } = userDetailsStore.getState()
  const [showSmsForm, setShowSmsForm] = useState(false)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)

  const inputProps: InputPropsType[] = [
    {
      placeholder: translate.phrases.banyanApp('Add Mobile Number'),
      key: 'smsNumber',
      initialValue: smsNumber || '',
    },
  ]

  const handleSubmit = async (values: { [k: string]: string }) => {
    setLoading(true)

    try {
      const response = await updateUserProfile({ smsNumber: values.smsNumber })

      if (response) {
        //Success
        userDetailsStore.setState((prevState) => {
          return {
            ...prevState,
            smsNumber: values.smsNumber,
          }
        })

        setIsError(false)

        setShowSmsForm(false)
      }
    } catch (error) {
      //Error
      setIsError(true)
    }

    setLoading(false)
  }

  const smsTextRenderer = () => {
    if (!showSmsForm && !smsNumber)
      return (
        <Anchor
          className={css`
            font-size: 14px;
            color: ${theme.colors.primary[0]};
            text-decoration: underline;
            font-weight: 700;
          `}
          onClick={() => setShowSmsForm(true)}
        >
          {translate.phrases.banyanApp('Add Mobile Number')}
        </Anchor>
      )

    if (showSmsForm) {
      return null
    }

    if (smsNumber)
      return (
        <div
          className={css`
            font-size: 14px;
          `}
        >
          {smsNumber}
        </div>
      )

    return null
  }

  return (
    <>
      <div
        className={css`
          justify-content: left;
          align-items: center;
          display: flex;
          margin: 20px 0 0 5px;
        `}
      >
        <div
          className={css`
            font-size: 14px;
            font-weight: 600;
            width: 130px;
          `}
        >
          {translate.phrases.banyanApp('SMS Number')}
        </div>
        {smsTextRenderer()}
      </div>
      {smsNumber && !showSmsForm && (
        <Anchor
          className={css`
            margin: 2px 0 0 5px;
            display: flex;
            flex-direction: row;
          `}
        >
          <div
            className={css`
              margin-right: 2px;
              margin-top: 4px;
              width: 14px;
              height: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <EditIcon />
          </div>
          <div
            className={css`
              font-size: 14px;
              color: ${theme.colors.primary[0]};
              text-decoration: underline;
              font-weight: 700;
            `}
            onClick={() => setShowSmsForm(true)}
          >
            {translate.phrases.banyanApp('Edit Number')}
          </div>
        </Anchor>
      )}
      {isError && (
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 30px 18px 0px;
          `}
        >
          <div
            className={css`
              padding-right: 14px;
            `}
          >
            <WarningIcon size={17} />
          </div>
          <div
            className={css`
              font-size: 14px;
              display: flex;
              align-items: center;
              font-weight: 700;
            `}
          >
            <div>{translate.phrases.banyanApp('Your SMS number could not be saved. Please try again.')}</div>
          </div>
        </div>
      )}
      {showSmsForm && (
        <SmsNumberForm
          inputProps={inputProps}
          validationSchema={z.object({
            smsNumber: z
              .string()
              .regex(/^\+\d{11,}$/, {
                message: translate.phrases.banyanApp(
                  'Phone number must be in international format (e.g. +18885551234)',
                ),
              })
              .or(z.literal('')),
          })}
          buttonText={
            smsNumber
              ? translate.phrases.banyanApp('Update Number')
              : translate.phrases.banyanApp('Add Number')
          }
          buttonVariant="secondary"
          onSubmit={(values) => {
            handleSubmit(values)
          }}
          submitLoading={loading}
        />
      )}
    </>
  )
}
