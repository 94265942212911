import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import type { AlertTypeId } from './settings/alertSettings'

export const mapAlertIdTypeToLabel = (alertIdType: AlertTypeId) => {
  if (alertIdType === 'almondHullSplit') return translate.phrases.banyanApp('Almond Hull Split')

  if (alertIdType === 'alternaria') return translate.phrases.banyanApp('Alternaria')

  if (alertIdType === 'degreeDays') return translate.phrases.banyanApp('Degree Days')

  if (alertIdType === 'fireBlight') return translate.phrases.banyanApp('Fire Blight')

  if (alertIdType === 'frost') return translate.phrases.banyanApp('Frost')

  if (alertIdType === 'leafWetness') return translate.phrases.banyanApp('Leaf Wetness')

  if (alertIdType === 'temperature') return translate.phrases.banyanApp('Temperature')

  if (alertIdType === 'trapCatches') return translate.phrases.banyanApp('Trap Catches')

  if (alertIdType === 'weatherForecastAlert') return translate.phrases.banyanApp('Weather Forecast')

  if (alertIdType === 'wetBulb') return translate.phrases.banyanApp('Wet Bulb')

  Sentry.captureException(`${alertIdType} has not been implemented`)

  return `${alertIdType}`
}
