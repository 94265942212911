import { useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconChevron } from 'components/icons/IconChevron'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { Authorization } from '../../../../../components/Authorization/Authorization'
import { getActiveNodeStatus, NODE_STATUS_COLORS } from '../../../Map/_utils/getActiveNodeStatus'
import type { TOnboardLog } from '../../../types'
import { TNodeStatus } from '../../../types'

interface OnboardProps {
  onboardLogs: TOnboardLog[] | undefined
}

enum TNodeOnboardStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

const DEVICE_TYPES = {
  ACCELEROMETER: 'Accelerometer',
  ACCELMAXMIN: 'Accelerometer Max/Min',
  PRESSURE: 'Pressure',
  RELHUMIDITY: 'Relative Humidity',
  TEMPERATURE: 'Temperature',
}

export const DEVICE_STATUS_COLORS: { [key in TNodeOnboardStatus]: string } = {
  [TNodeOnboardStatus.ONLINE]: '#29CD6B',
  [TNodeOnboardStatus.OFFLINE]: '#EB4C4C',
}

export const OnboardLogs: React.FC<OnboardProps> = ({ onboardLogs }) => {
  const [opened, { toggle }] = useDisclosure(false)
  const theme = useMantineTheme()

  const ONBOARD_SENSORS: TOnboardLog['deviceType'][] = [
    'ACCELEROMETER',
    'ACCELMAXMIN',
    'PRESSURE',
    'RELHUMIDITY',
    'TEMPERATURE',
  ]

  const shapedNodeOnboardSensors = useMemo(() => {
    return ONBOARD_SENSORS.map((sensor: TOnboardLog['deviceType']) => {
      const deviceLog = onboardLogs?.find((log) => log.deviceType === sensor)

      const logStatus = deviceLog
        ? getActiveNodeStatus({ stamp: deviceLog.stamp, nodeType: 'ln_r' })
        : 'unknown'

      let deviceStatus: TNodeOnboardStatus = TNodeOnboardStatus.OFFLINE
      let infoText = ''

      if (deviceLog) {
        if (logStatus !== TNodeStatus.ACTIVE) {
          infoText = translate.phrases.placeholder('Onboard sensor has not reported since {{timeAgo}}', {
            timeAgo: moment.tz(deviceLog.stamp, 'UTC').fromNow(),
          })

          deviceStatus = TNodeOnboardStatus.OFFLINE
        } else {
          deviceStatus = TNodeOnboardStatus.ONLINE
        }
      }

      return {
        deviceType: sensor,
        deviceStatus,
        logStatus,
        logStamp: deviceLog?.stamp,
        infoText,
      }
    })
  }, [onboardLogs])

  return (
    <div
      css={{
        border: `1px solid ${theme.colors.grey[1]}`,
        borderRadius: 3,
        backgroundColor: theme.colors.grey[0],
        color: theme.colors.grey[3],
        marginTop: 10,
        marginBottom: 10,
        lineHeight: '20px',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '14px 20px',
        }}
        onClick={toggle}
      >
        <div css={{ display: 'flex' }}>
          <h4 css={{ fontSize: '14px', margin: 0, marginRight: 30 }}>
            {translate.phrases.placeholder('Onboard Sensors')}
          </h4>

          {/*          <div css={{ display: 'flex' }}>
            {shapedNodeOnboardSensors.map(({ deviceType, deviceStatus }) => {
              const Icon = DEVICE_TYPE_ICONS[deviceType as TNodeDeviceType]

              return (
                <div key={deviceType} css={{ marginRight: 10, fontSize: '20px' }}>
                  <Indicator
                    color={DEVICE_STATUS_COLORS[deviceStatus as TNodeOnboardStatus]}
                    withBorder
                    disabled={deviceStatus === TNodeOnboardStatus.ONLINE}
                  >
                    {!!Icon && <Icon />}
                  </Indicator>
                </div>
              )
            })}
          </div>*/}
        </div>

        <div>
          <span
            css={{
              display: 'inline-block',
              marginLeft: 10,
              transform: opened ? 'rotate(180deg)' : undefined,
            }}
          >
            <IconChevron />
          </span>
        </div>
      </div>

      {opened && (
        <div css={{ padding: 10, borderTop: `1px solid ${theme.colors.grey[1]}`, fontSize: '12px' }}>
          <div>
            {shapedNodeOnboardSensors.map(({ deviceType, deviceStatus, logStatus, logStamp, infoText }) => (
              <div
                key={deviceType}
                css={{
                  'marginLeft': 30,
                  'marginTop': -20,
                  'marginBottom': 10,
                  '&:last-child': { marginBottom: 0 },
                  '::before': {
                    content: '""',
                    height: 14,
                    width: 14,
                    backgroundColor: DEVICE_STATUS_COLORS[deviceStatus as TNodeOnboardStatus],
                    border: '2px solid white',
                    borderRadius: '50%',
                    display: 'inline-block',
                    position: 'relative',
                    top: 22,
                    left: -20,
                  },
                }}
              >
                <div css={{ fontWeight: 500 }}>
                  {Object.keys(DEVICE_TYPES).includes(deviceType)
                    ? DEVICE_TYPES[deviceType]
                    : translate.phrases.placeholder('Unknown')}
                </div>
                <Authorization requires={{ permission: 'VIEW_SSC_NODE_LAST_LOG', entity: 'FOR_ANY_ENTITY' }}>
                  <div>
                    {translate.phrases.placeholder('Last Log:')}{' '}
                    <span css={{ color: NODE_STATUS_COLORS[logStatus as TNodeStatus], fontWeight: 500 }}>
                      {logStamp
                        ? moment.tz(logStamp, 'UTC').fromNow()
                        : translate.phrases.placeholder('Never')}
                    </span>
                    {infoText && <div css={{ color: theme.colors.grey[2] }}>{infoText}</div>}
                  </div>
                </Authorization>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
