import type { CSSObject } from '@emotion/react'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import type { ReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { makeReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import type { TRegionBadgeComponentProps } from './RegionBadgeComponent/RegionBadgeComponent'
import { RegionBadgeComponent } from './RegionBadgeComponent/RegionBadgeComponent'

export type REGION_META = {
  regionName: string
  regionId: string
  latLng: google.maps.LatLng
}

export type TRegionsValueTypesToPropsDictionary = Record<
  string,
  {
    baseZIndex?: number
    children: React.ReactNode
    getContainerCSS: () => CSSObject
    onHover: boolean
  }
>

export class RegionValuesCache extends MapItemsCache<
  REGION_META,
  TRegionsValueTypesToPropsDictionary,
  ReactComponentOverlayView<TRegionBadgeComponentProps>
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        return makeReactComponentOverlayView({
          component: RegionBadgeComponent,
          transform: 'translate(-50%, -110%)',
          latLng: {
            lat: o.meta.latLng.lat(),
            lng: o.meta.latLng.lng(),
          },
          maps: o.maps,
          map: o.map,
        })
      },
      renderOverlay: (o) => {
        o.overlay.render({ ...o, valueTypesToPropsDictionary: o.value })
      },
      hideOverlay: (o) => {
        o.overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
