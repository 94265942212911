import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconLeftArrowCircle = () => (
  <svg {...makeBaseSvgIconProps('0 0 16 16')}>
    <path
      d="M8 11.5L8.61687 10.8831L6.17562 8.4375L11.5 8.4375L11.5 7.5625L6.17562 7.5625L8.61687 5.11687L8 4.5L4.5 8L8 11.5Z"
      fill="currentColor"
    />
    <circle cx="8" cy="8" r="7" transform="rotate(180 8 8)" stroke="currentColor" />
  </svg>
)
