import { Text } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { Button } from 'components/Button/Button'
import { SemiosLogoColor } from 'components/SemiosLogo/SemiosLogoColor'
import { translate } from 'i18n/i18n'
import { clearIonicStoreButKeepUserLanguage } from 'utils/clearIonicStoreButKeepUserLanguage'
import { redirectRoute } from 'utils/redirectRoute'

export const ErrorBoundary = (props: { children: React.ReactNode }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <div
          css={{
            padding:
              'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          }}
        >
          <div css={{ padding: '33px 30px' }}>
            <div>
              <SemiosLogoColor width={272} />
            </div>
            <div css={{ minHeight: '65vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div css={{ display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'center' }}>
                <Text size={24} weight={300}>
                  {translate.phrases.banyanApp('Sorry… Something went wrong.')}
                </Text>
                <div>
                  <Button
                    async
                    onClick={async () => {
                      await clearIonicStoreButKeepUserLanguage()

                      redirectRoute('/')
                    }}
                  >
                    {translate.phrases.banyanApp('Reload')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  )
}
