import type { MantineColor, SelectItemProps } from '@mantine/core'
import { Group, Text } from '@mantine/core'
import { IconGlobe } from 'components/icons/IconGlobe'
import { IconHistory } from 'components/icons/IconHistory'
import { IconOrganization } from 'components/icons/IconOrganization'
import { IconProperty } from 'components/icons/IconProperty'
import { forwardRef } from 'react'
import type { TSearchResultsItem } from '../types'

interface ItemProps extends SelectItemProps {
  color: MantineColor
  type: TSearchResultsItem['type']
  propertyIds: TSearchResultsItem['propertyIds']
  label: TSearchResultsItem['label']
  group?: TSearchResultsItem['group']
}

// eslint-disable-next-line react/display-name
export const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ type, label, propertyIds, group, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Text css={{ display: 'flex', alignItems: 'center' }}>
          <div css={{ width: 36, display: 'inline-block' }}>
            {type === 'PROPERTY' && <IconProperty />}
            {type === 'ORGANIZATION' && <IconOrganization />}
            {type === 'COUNTRY' && <IconGlobe />}
            {type === 'ADMINISTRATIVE_AREA_LEVEL_1' && <IconGlobe />}
            {type === 'PREVIOUS_SEARCH' && <IconHistory />}
          </div>
          <div css={{ flex: 1 }}>{label}</div>
        </Text>
      </Group>
    </div>
  ),
)
