import type { NotificationProps } from '@mantine/notifications'

export const defaultNotificationProps: NotificationProps = {
  color: 'white', //Avoid the default color of indicator
  withCloseButton: true,
  styles: {
    closeButton: {
      scale: '1.5',
    },
  },
  message: undefined,
}
