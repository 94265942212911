import { Group } from '@mantine/core'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'

export const CancelSaveButtonGroup = ({
  onSave,
  onCancel,
  cancelButtonLabel = translate.phrases.banyanApp('Cancel'),
  saveButtonLabel = translate.phrases.banyanApp('Submit'),
  saveDisabled = false,
}: {
  onSave: () => void
  onCancel: () => void
  cancelButtonLabel?: string
  saveButtonLabel?: string
  saveDisabled?: boolean
}) => {
  return (
    <Group css={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="secondary" onClick={onCancel}>
        {cancelButtonLabel}
      </Button>
      <Button variant="primary" disabled={saveDisabled} onClick={onSave}>
        {saveButtonLabel}
      </Button>
    </Group>
  )
}
