import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconClose = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 16 17')}>
      <path
        d="M16 1.89854L14.3886 0.233398L8 6.83492L1.61143 0.233398L0 1.89854L6.38857 8.50006L0 15.1016L1.61143 16.7667L8 10.1652L14.3886 16.7667L16 15.1016L9.61143 8.50006L16 1.89854Z"
        fill="currentColor"
      />
    </svg>
  )
}
