import type { CSSObject } from '@emotion/css'
import { Popover, Text } from '@mantine/core'
import type { PopoverWidth } from '@mantine/core/lib/Popover/Popover.types'
import { OverlayView } from 'App/ServiceCenter/Map/_utils/OverlayView'
import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import type { FC, ReactNode } from 'react'
import React from 'react'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapStore } from 'stores/mapStore'

type Props = {
  title: string
  children: ReactNode
  latLng: google.maps.LatLngLiteral
  onClose: () => void
  footer?: ReactNode
  targetCSS?: CSSObject
  popoverWidth: PopoverWidth
}

export const OverlayPopover: FC<Props> = ({
  title,
  children,
  latLng,
  footer,
  onClose,
  targetCSS,
  popoverWidth,
}) => {
  const { mapCenter, mapZoom } = mapStore.useSelector((s) => ({ mapCenter: s.center, mapZoom: s.zoom }))

  return (
    <OverlayView position={latLng} layer="floatPane" prevent="MAP_HITS_AND_GESTURES">
      <Popover
        width={popoverWidth}
        position="top"
        withArrow
        shadow="md"
        opened={true}
        styles={{ dropdown: { padding: 0, borderRadius: 15 } }}
        trapFocus
        withinPortal={false}
        zIndex={100}
      >
        <Popover.Target>
          <div
            css={targetCSS}
            key={
              `${mapCenter?.lng},${mapCenter?.lat}/${mapZoom}/${latLng.lng},${latLng.lat}` /* We need the key so that the popover will re-render when moving the map, placing the popover in the best position (below or above the map icon) */
            }
          />
        </Popover.Target>
        <Popover.Dropdown>
          <div
            css={{
              backgroundColor: colors.white,
              zIndex: 100,
              borderRadius: 15,
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 10,
            }}
          >
            <div css={{ flex: '0 0 auto' }}>
              <div css={{ display: 'flex', flex: '0 0 auto' }}>
                <button
                  css={{
                    marginLeft: 5,
                    width: 30,
                    height: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'content-box',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    border: 'none',
                  }}
                  onClick={onClose}
                >
                  <CloseIcon />
                </button>
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1 1 auto',
                    margin: '10px 47px 5px 0',
                  }}
                >
                  <Text color={colors.midnight} weight={700} size={18}>
                    {title}
                  </Text>
                </div>
              </div>
            </div>
            <div
              css={{ overflowY: 'auto', borderBottom: footer ? `1px solid ${colors.grey200}` : undefined }}
            >
              {children}
            </div>
            <div
              css={{
                ...(HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD ? { boxSizing: 'border-box' } : {}), // TODO: This probably isn't needed... test it out on Agworld
                display: 'flex',
                justifyContent: 'center',
                padding: footer ? 20 : 0,
                width: '100%',
                bottom: 0,
                marginBottom: 'env(safe-area-inset-bottom)',
              }}
            >
              {footer}
            </div>
          </div>
        </Popover.Dropdown>
      </Popover>
    </OverlayView>
  )
}
