// TODO: when compare seasons data is all null, what should we do here?
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type {
  StackedChartSection,
  TChartSeries,
  TChartSeriesWithTRGBAColorWith1AtTheEndColor,
} from 'components/StackedChart/types'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import { updateTooltipContents } from 'components/StackedChart/_utils/lineChartTooltipFormatter/updateTooltipContents'
import { weatherConditionToComponentString } from 'components/WeatherCondition/WeatherCondition'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import moment from 'moment-timezone'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedPointHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import type { EnumWeatherCondition } from 'utils/weather-conditions/types'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'

const pointCategory: TPointCategory = 'weatherPoint'
const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WEATHER_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  sunrise: preferredAggregationInterval,
  sunset: preferredAggregationInterval,
  weatherIcon: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.conditions || !selectedFieldAssets[pointCategory]) return {}

  if (
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: Object.keys(valuesRequested),
      pointCategory,
    })
  )
    return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: translate.phrases.banyanApp('Conditions'),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={Object.keys(valuesRequested)}
      />
    ),
    id: 'stackem-conditions',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const timezone = getTimezoneForSelectedPropertyOrRegion()
  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const series: TChartSeries[] = []
  const sunsetSeriesId = 'sunset'
  const sunriseSeriesId = 'sunrise'
  const conditionsSeriesId = 'conditions'

  const sunsetSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
    color: colors.midnight,
    name: translate.phrases.banyanApp('Sunset'),
    id: sunsetSeriesId,
    yAxis: 0,
    data: (data?.points?.[stationLngLat]?.values?.sunset?.[0]?.timeseries ?? []).map((d) => {
      const sunsetMoment = moment.tz(d.value, timezone)
      const minuteOfDaySunset = sunsetMoment.diff(sunsetMoment.clone().startOf('day'), 'minute')

      return [+new Date(d.timestamp), isNil(d.value) ? null : minuteOfDaySunset]
    }),
    type: 'line',
  }

  series.push(sunsetSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(sunsetSeries, {
        data: (compareSeasonsData?.points?.[stationLngLat]?.values?.sunset?.[0]?.timeseries ?? []).map(
          (d) => {
            const sunsetMoment = moment.tz(d.value, timezone)
            const minuteOfDaySunset = sunsetMoment.diff(sunsetMoment.clone().startOf('day'), 'minute')

            return [+new Date(d.timestamp), isNil(d.value) ? null : minuteOfDaySunset]
          },
        ),
      }),
    )
  }

  const sunriseSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
    color: colors.belowCanopy,
    name: translate.phrases.banyanApp('Sunrise'),
    id: sunriseSeriesId,
    yAxis: 0,
    data: (data?.points?.[stationLngLat]?.values?.sunrise?.[0]?.timeseries ?? []).map((d) => {
      const sunriseMoment = moment.tz(d.value, timezone)
      const minuteOfDaySunrise = sunriseMoment.diff(sunriseMoment.clone().startOf('day'), 'minute')

      return [+new Date(d.timestamp), isNil(d.value) ? null : minuteOfDaySunrise]
    }),
    type: 'line',
  }

  series.push(sunriseSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(sunriseSeries, {
        data: (compareSeasonsData?.points?.[stationLngLat]?.values?.sunrise?.[0]?.timeseries ?? []).map(
          (d) => {
            const sunriseMoment = moment.tz(d.value, timezone)
            const minuteOfDaySunrise = sunriseMoment.diff(sunriseMoment.clone().startOf('day'), 'minute')

            return [+new Date(d.timestamp), isNil(d.value) ? null : minuteOfDaySunrise]
          },
        ),
      }),
    )
  }

  const conditionsDictionary: Record<number, EnumWeatherCondition> = {}
  const conditionsDictionaryCompareSeasons: Record<number, EnumWeatherCondition> = {}

  const iconSeries: TChartSeriesWithTRGBAColorWith1AtTheEndColor = {
    color: colors.midnight,
    lineWidth: 0,
    showInLegend: false,
    name: translate.phrases.banyanApp('Conditions'),
    id: conditionsSeriesId,
    yAxis: 0,
    data: (data?.points?.[stationLngLat]?.values?.weatherIcon?.[0]?.timeseries ?? []).map((d) => {
      const epoch = +new Date(d.timestamp)

      conditionsDictionary[epoch] = d.value as EnumWeatherCondition

      return [epoch, 0]
    }),
    type: 'line',
  }

  series.push(iconSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(iconSeries, {
        data: (compareSeasonsData?.points?.[stationLngLat]?.values?.weatherIcon?.[0]?.timeseries ?? []).map(
          (d) => {
            const epoch = +new Date(d.timestamp)

            conditionsDictionaryCompareSeasons[epoch] = d.value as EnumWeatherCondition

            return [epoch, 0]
          },
        ),
      }),
    )
  }

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp: +new Date(),
          },
          chart: {
            type: 'line',
          },
          tooltip: {
            xDateFormat: getXDateFormat(),
            formatter: function (tooltip) {
              let tooltipContents = lineChartTooltipFormatter(this, tooltip, +new Date())

              const startOfDayMoment = moment.tz(this.x, timezone).startOf('day')
              const seriesIdsToIterate = [sunsetSeriesId, sunriseSeriesId]

              seriesIdsToIterate.forEach((seriesId) => {
                tooltipContents = updateTooltipContents({
                  seriesId,
                  tooltipContents,
                  fieldsToChange: {
                    value: ({ content }) => {
                      if (content === '-') return '-'

                      return translate.dates.format(
                        startOfDayMoment.clone().add(content, 'minutes'),
                        'h:mm a',
                      )
                    },
                    compareSeasonsValue: ({ content }) => {
                      if (content === '-') return '-'

                      return translate.dates.format(
                        startOfDayMoment.clone().add(content, 'minutes'),
                        'h:mm a',
                      )
                    },
                  },
                })
              })

              tooltipContents = updateTooltipContents({
                seriesId: conditionsSeriesId,
                tooltipContents,
                fieldsToChange: {
                  value: () => {
                    const condition = conditionsDictionary[Number(this.x)]

                    return isNil(condition)
                      ? translate.phrases.templates('-')
                      : `<span style="font-size:30px">${weatherConditionToComponentString(condition)}</span>`
                  },
                  compareSeasonsValue: () => {
                    const condition = conditionsDictionaryCompareSeasons[Number(this.x)]

                    return isNil(condition)
                      ? translate.phrases.templates('-')
                      : `<span style="font-size:30px">${weatherConditionToComponentString(condition)}</span>`
                  },
                },
              })

              return tooltipContents
            },
          },
          yAxis: [
            {
              opposite: false,
              labels: {
                formatter: function (a) {
                  try {
                    if (typeof a.value === 'number') {
                      return translate.dates.format(
                        moment
                          .tz(timezone)
                          /**
                           * assume the first day of the year has 24 hours in
                           * it so we can use it as our baseline. Won't be
                           * perfect for the DST change days, but won't break
                           */
                          .startOf('year')
                          .startOf('day')
                          .add(a.value, 'minutes'),
                        'h:mm a',
                      )
                    } else {
                      return ''
                    }
                  } catch (err) {
                    return ''
                  }
                },
              },
            },
          ],
          series,
        },
      },
    ],
  }
}
