import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import type { TNodeType } from '../types'

export function getNodeTypeLabel(nodeType: TNodeType): string {
  switch (nodeType) {
    case 'scd':
      return translate.phrases.placeholder('SN Dispenser')

    case 'ln_scd':
      return translate.phrases.placeholder('LN Dispenser')

    case 'gtwy':
      return translate.phrases.placeholder('SN Gateway')

    case 'ln_gtwy':
      return translate.phrases.placeholder('LN Gateway')

    case 'rpt':
      return translate.phrases.placeholder('Repeater')

    case 'trp':
      return translate.phrases.placeholder('SN Trap')

    case 'dn_trp':
      return translate.phrases.placeholder('DN Trap')

    case 'y':
      return translate.phrases.placeholder('SN Station')

    case 'dn_y':
      return translate.phrases.placeholder('DN Station')

    case 'ln_r':
      return translate.phrases.placeholder('LN Station')

    default: {
      Sentry.captureException(new Error(`Node type: "${nodeType}" has not been implemented`))

      return nodeType
    }
  }
}
