import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const getSelectedRegion = ({
  selectedFieldAssets: selectedFieldAssetsArg,
  selectedProperty = selectedFieldAssetsStore.getState().property,
}: {
  selectedFieldAssets?: TSelectedFieldAssetsStoreState
  selectedProperty?: TFieldAssetKeyTypes.TPropertyId | null
}): TFieldAssetKeyTypes.TRegionId | null => {
  if (selectedProperty) {
    const propertyRegions = fieldAssetStore.getState().properties?.[selectedProperty]?.regionIds || []

    return propertyRegions[0]
  }

  const selectedFieldAssets = selectedFieldAssetsArg || selectedFieldAssetsStore.getState()
  const selectedRegion = selectedFieldAssets.region

  if (selectedRegion) return selectedRegion

  return null
}
