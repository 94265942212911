import { Popover } from '@mantine/core'
import { IconUser } from 'components/icons/IconUser'
import { ButtonSignOut } from 'components/SideNav/Preferences/UserNameWithLogout/ButtonSignOut'
import { alphaColorReplacer, colors } from 'settings/colors'
import { userDetailsStore } from 'stores/userDetailsStore'
import { IconAndTitle } from '../../IconAndTitle/IconAndTitle'

export const UserNameWithLogout = () => {
  const { firstName, lastName } = userDetailsStore.getState()

  return (
    <Popover
      withinPortal
      position="top-start"
      offset={-10}
      withArrow
      shadow="md"
      styles={{ arrow: { borderColor: alphaColorReplacer(colors.gray, 0.2) } }}
    >
      <Popover.Target>
        <div css={{ cursor: 'pointer' }}>
          <IconAndTitle icon={<IconUser />} title={`${firstName} ${lastName}`} />
        </div>
      </Popover.Target>
      <Popover.Dropdown
        css={{
          background: colors.midnight,
          color: 'white',
          borderColor: alphaColorReplacer(colors.gray, 0.2),
          padding: 3,
          borderRadius: 4,
        }}
      >
        <ButtonSignOut />
      </Popover.Dropdown>
    </Popover>
  )
}
