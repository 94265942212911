import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { SoilAverageDepthsTitleChildren } from 'App/Map/PanelDetails/SectionTitleBars/SoilAverageDepthsTitleChildren/SoilAverageDepthsTitleChildren'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import type {
  StackedChartSection,
  StackedChartSectionItem,
  TIrrigationEventSeries,
} from 'components/StackedChart/types'
import moment from 'moment-timezone'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { checkPermission } from './soil'
import { makeAwcChart } from './_utils/makeAwcChart'
import { makeEmitterSeries } from './_utils/makeEmitterSeries'
import { makeRainSeries } from './_utils/makeRainSeries'
import { makeWaterEventsChart } from './_utils/makeWaterEventsChart'
import { PREDICTION_KEY } from './_utils/predictionKey'

const possibleValueTypes: VV.DomainTypes.Soil.TTimeseriesValueTypeKeysMerged[] = ['soilMoisture']

// TODO: At the moment we are only making line series with the average value. We are not yet making area series out of the min/max values.
export const awcLineAndWaterEvents = ({
  data,
  soilStationLngLat,
  precipitationStationLngLat,
  compareSeasonsData,
  soilStationFavoriteDepths,
}: {
  data: routes.Values.Response
  precipitationStationLngLat: string | null
  soilStationLngLat: string | null
  compareSeasonsData: routes.Values.Response
  soilStationFavoriteDepths: routes.UserAppStartup.TFieldAssetValueTypes.TSoilSensorDepths[]
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.soilAwc().categoryTitleWithoutUnit(),
    titleChildren: (
      <SoilAverageDepthsTitleChildren
        showAverageToggle={false}
        valuesTimeseriesToFilterOn={possibleValueTypes}
      />
    ),
    id: 'stackem-soil-awc-line',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const soilMoisture = data?.points?.[String(soilStationLngLat)]?.values?.soilMoisture || []

  const firstTimestampFromPredictionSeries =
    soilMoisture.find((depth) => depth.metadata.depth === PREDICTION_KEY)?.timeseries[0]?.timestamp || 0

  const firstForecastTimestamp = firstTimestampFromPredictionSeries
    ? +new Date(firstTimestampFromPredictionSeries)
    : +new Date()

  const aggregationInterval = soilMoisture[0]?.metadata?.aggregationInterval
  const { compareSeasonsInterval } = detailsPanelStore.getState()
  const { properties } = fieldAssetStore.getState()
  const { property } = selectedFieldAssetsStore.getState()

  const timezone =
    properties && property && properties[property] ? properties[property]?.timezone : moment.tz.guess()

  const rainData =
    data?.points?.[String(precipitationStationLngLat)]?.values?.precipitation?.[0]?.timeseries ?? []

  const emitterData = soilMoisture?.[0]?.metadata?.irrigationActivityForZones ?? {}
  const rainSeries: TIrrigationEventSeries = makeRainSeries(rainData)
  const hasRain = !!rainSeries?.data?.length && rainSeries.data.length > 0
  const emitterSeries: TIrrigationEventSeries[] = makeEmitterSeries(emitterData, hasRain)
  const waterEventsSeriesWithData: TIrrigationEventSeries[] = []

  if (hasRain) {
    waterEventsSeriesWithData.push(rainSeries)
  }

  if (emitterSeries.length > 0) {
    emitterSeries.forEach((series) => {
      waterEventsSeriesWithData.push(series)
    })
  }

  const chartItems: StackedChartSectionItem[] = []

  if (waterEventsSeriesWithData.length > 0) {
    chartItems.push(
      makeWaterEventsChart({
        waterEventsSeriesWithData,
        rainSeries,
        firstForecastTimestamp,
        timezone,
        isDaily: aggregationInterval === 'DAILY',
      }),
    )
  }

  const availableEmittersForStation =
    (properties &&
      property &&
      soilStationLngLat &&
      properties[property]?.points?.[soilStationLngLat as TFieldAssetKeyTypes.TLngLat]?.configuration
        ?.soilProbeIntersectingZoneIdsWithEmitters) ||
    []

  chartItems.push(
    makeAwcChart({
      aggregationInterval,
      availableEmittersForStation,
      chartId: commonReturnItems.id,
      compareSeasonsData,
      compareSeasonsInterval,
      data,
      firstForecastTimestamp,
      soilStationLngLat,
      soilStationFavoriteDepths,
    }),
  )

  return {
    ...commonReturnItems,
    items: chartItems,
    titleChildren: (
      <SoilAverageDepthsTitleChildren showAverageToggle valuesTimeseriesToFilterOn={possibleValueTypes} />
    ),
  }
}
