import { useState } from 'react'

export function useNavigator<P extends string, E = unknown>(initialPage: P, extra?: E) {
  const [currentPage, setPage] = useState<{ page: P; extra?: E }>({
    page: initialPage,
    extra: extra,
  })

  return {
    page: currentPage.page,
    extra: currentPage.extra,
    navigateTo: (page: P, extra?: E) => setPage({ page, extra }),
  }
}
