import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconDendrometer = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 11')}>
      <path
        d="M18.1818 0H1.81818C0.818182 0 0 0.825 0 1.83333V9.16667C0 10.175 0.818182 11 1.81818 11H18.1818C19.1818 11 20 10.175 20 9.16667V1.83333C20 0.825 19.1818 0 18.1818 0ZM18.1818 9.16667H1.81818V1.83333H3.63636V5.5H5.45455V1.83333H7.27273V5.5H9.09091V1.83333H10.9091V5.5H12.7273V1.83333H14.5455V5.5H16.3636V1.83333H18.1818V9.16667Z"
        fill="currentColor"
      />
    </svg>
  )
}
