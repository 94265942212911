import { Tooltip, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import React from 'react'
import { getRSSILevel, getSignalStrengthColors } from '../../utils/getRSSILevel'

interface SignalStrengthProps {
  signal: number
  getSignalLevel?: (signal: number) => number
}

export const SignalStrength: React.FC<SignalStrengthProps> = ({ signal, getSignalLevel }) => {
  const theme = useMantineTheme()
  const mapping = getSignalLevel ? getSignalLevel : getRSSILevel
  const signalStrength = mapping(signal)
  const barColor = getSignalStrengthColors(signalStrength)

  return (
    <Tooltip label={translate.phrases.placeholder('Signal: {{signal}} dBm', { signal: signal.toString() })}>
      <div
        css={{
          display: 'flex',
        }}
      >
        {[1, 2, 3, 4, 5].map((i) => (
          <div
            key={i}
            css={{
              width: 5,
              height: 15,
              borderRadius: 2,
              marginRight: 5,
              backgroundColor: i <= signalStrength ? barColor : theme.colors.grey[2],
            }}
          />
        ))}
      </div>
    </Tooltip>
  )
}
