import { ApplyToAllButton } from 'components/ApplyToAllButton/ApplyToAllButton'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { RootZoneDepthMultiSelectProps } from 'components/RootZoneDepthsModal/types'
import { translate } from 'i18n/i18n'
import { useCallback, useState } from 'react'
import { colors } from 'settings/colors'
import { soilUnitConverter } from 'utils/soilUnitConverter'

export const RootZoneDepthMultiSelect = ({
  station,
  setStationData,
  stationsDataLength,
}: RootZoneDepthMultiSelectProps) => {
  const [dropdownModified, setDropdownModified] = useState(false)

  const applyToAll = useCallback(() => {
    const depthsToApplyAll = station.favoriteDepths

    setStationData((prev) => {
      return prev.map((item) => {
        const availableDepths = item.availableDepths

        const depthsToApplyCurrentStation = depthsToApplyAll.filter((depth) =>
          availableDepths.includes(depth),
        )

        return {
          ...item,
          favoriteDepths: depthsToApplyCurrentStation,
        }
      })
    })
  }, [station.favoriteDepths])

  const emptyMultiSelect = station.favoriteDepths.length === 0

  return (
    <div style={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '20px' }}>
      <div style={{ maxWidth: '500px' }}>
        <div
          style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '6px', cursor: 'pointer' }}
        >
          {<span style={{ fontWeight: '500', margin: 0, padding: 0 }}>{station.name}</span>}
          {stationsDataLength > 1 && dropdownModified && (
            <ApplyToAllButton
              buttonText={translate.phrases.banyanApp('Apply to all stations')}
              applyToAll={applyToAll}
              buttonColor={colors.primary}
            ></ApplyToAllButton>
          )}
        </div>
        <MultiSelect
          data={station.availableDepths.map((item) => {
            return {
              label: soilUnitConverter(item),
              value: item,
            }
          })}
          selectedData={station.favoriteDepths.map((item) => {
            return {
              label: soilUnitConverter(item),
              value: item,
            }
          })}
          placeholder=""
          onChange={(value) => {
            setDropdownModified(true)

            setStationData((prev) => {
              return prev.map((item) => {
                if (item.lngLat !== station.lngLat) return item

                return {
                  ...item,
                  favoriteDepths: value.map((val) => val.value as string),
                }
              })
            })
          }}
          error={emptyMultiSelect && <p>{translate.phrases.banyanApp('Select at least one depth')}</p>}
        />
      </div>
    </div>
  )
}
