import { useMantineTheme } from '@mantine/core'
import { useVirtualizer } from '@tanstack/react-virtual'
import { equipmentStatusPanelStore } from 'App/ServiceCenter/store/equipmentStatusPanelStore'
import { translate } from 'i18n/i18n'
import React, { useRef } from 'react'
import type { TActiveNode, TNodeLog, TPlannedNode } from '../../../types'
import { getIdentifier } from '../../../utils/getIdentifier'
import { isPlannedNode } from '../../../utils/isPlannedNode'
import type { NodeWithStatus } from '../EquipmentStatus'
import { NodeRow } from '../NodeRow/NodeRow'

interface NodeListProps {
  nodes: NodeWithStatus[]
  totalNodes: number
  nodeLogs: Record<string, TNodeLog>
}

export const NodeList: React.FC<NodeListProps> = ({ nodes, totalNodes, nodeLogs }) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const theme = useMantineTheme()

  const selectedPropertyId = equipmentStatusPanelStore.useSelector(
    equipmentStatusPanelStore.selectors.getPropertyId,
  )

  const rowVirtualizer = useVirtualizer({
    count: nodes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 116,
  })

  return (
    <div ref={parentRef} css={{ padding: 12, flex: '1 1 auto', overflowY: 'auto' }}>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {/* Only the visible items in the virtualizer, manually positioned to be in view */}
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const node = nodes[virtualItem.index]
          const nodeId = isPlannedNode(node) ? (node as TPlannedNode).id : getIdentifier(node as TActiveNode)
          const lastLog = nodeLogs[nodeId]

          return (
            <div
              key={virtualItem.key}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
              }}
              data-index={virtualItem.index}
              ref={rowVirtualizer.measureElement}
            >
              <NodeRow key={nodeId} node={node} lastLog={lastLog} status={node.status} />
            </div>
          )
        })}
      </div>

      {!nodes.length && (
        <div
          css={{
            color: theme.colors.grey[2],
            fontSize: '24px',
            fontWeight: 300,
            textAlign: 'center',
            margin: 'auto',
            paddingLeft: 30,
            paddingRight: 30,
            lineHeight: 1.5,
            position: 'absolute',
            top: 'calc(50% - 50px)',
            width: '100%',
          }}
        >
          {totalNodes > 0
            ? translate.phrases.placeholder('No equipments match the selected filters')
            : translate.phrases.placeholder(
                `${selectedPropertyId ? 'No equipment found on this property' : 'Please select a property'}`,
              )}
        </div>
      )}
    </div>
  )
}
