import { useEffect } from 'react'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { doesPointHaveValueType } from 'utils/doesFieldAssetHaveValueType'
import { removeCmOrInFromSoilProbeDepths } from 'utils/removeCmOrInFromSoilProbeDepths'

// when a soil station is selected, it sets the default depths for
// that station UNLESS the user has already selected all depths,
// in which case it will set all depths for that station.
export const useSoilDepthVisibility = () => {
  useEffect(() => {
    const soilStationSelector = (s: TSelectedFieldAssetsStoreState) => {
      return s.soilPoint
    }

    const soilStationOnChange = (soilStationLngLat: `POINT(${number} ${number})` | null) => {
      if (!soilStationLngLat) return

      const propertyId = selectedFieldAssetsStore.getState().property
      const points = fieldAssetStore.getState()?.properties?.[Number(propertyId)]?.points ?? {}

      if (
        !propertyId ||
        !doesPointHaveValueType({
          propertyId,
          lngLat: soilStationLngLat,
          timeseriesValueToCheck: 'soilMoisture',
        })
      )
        return

      const defaultDepthsForProbe =
        (
          points[soilStationLngLat as `POINT(${number} ${number})`]?.configuration.soilDefaultProbeDepths ??
          []
        ).reduce((acc: Record<number, boolean>, d: string) => {
          const fixedDepth = Math.abs(removeCmOrInFromSoilProbeDepths(d))

          acc[fixedDepth] = true

          return acc
        }, {}) ?? {}

      const selectAllAvailableDepths =
        points[soilStationLngLat]?.configuration.soilProbeDepthsAvailable?.reduce(
          (acc: Record<number, boolean>, d: string) => {
            const fixedDepth = Math.abs(removeCmOrInFromSoilProbeDepths(d))

            acc[fixedDepth] = true

            return acc
          },
          {},
        ) ?? {}

      detailsPanelStore.setState((p) => {
        const { allSoilDepthsAreSelected } = p

        return {
          ...p,
          soilVisibility: allSoilDepthsAreSelected ? selectAllAvailableDepths : defaultDepthsForProbe,
        }
      })
    }

    const unsubscribe = selectedFieldAssetsStore.subscribeToChanges(soilStationSelector, soilStationOnChange)

    // run it once right away to get the first soil station
    soilStationOnChange(soilStationSelector(selectedFieldAssetsStore.getState()))

    return () => {
      unsubscribe()
    }
  }, [])
}
