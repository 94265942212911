let sortFn

try {
  // Test browser support for localeCompare() options
  'foo'.localeCompare('bar', 'en', { ignorePunctuation: true, numeric: true })

  sortFn = new Intl.Collator('en', { ignorePunctuation: true, numeric: true }).compare
} catch (e) {
  // Fallback on basic comparator
  sortFn = new Intl.Collator().compare
}

export const natComparator = sortFn

// sorts an array of objects by giving an object key
// … called with […].sort(sortByKey('id'))
// … called with […].sort(sortByKey('id', -1)) to get the reverse
export const sortByKey =
  (key: string, order = 1) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (a: any, b: any) =>
    natComparator(a?.[key], b?.[key]) * order
