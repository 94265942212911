import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'
import { colors } from 'settings/colors'

export const IconCalculator = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 13 19')}>
      <rect width="12.6529" height="18.5" rx="1" fill={colors.midnight} />
      <rect x="1.62952" y="2.10889" width="9.39378" height="3.35492" fill="white" />
      <rect x="1.62952" y="6.99728" width="2.10881" height="2.10881" rx="0.151515" fill="white" />
      <rect x="1.62952" y="10.6398" width="2.10881" height="2.10881" rx="0.151515" fill="white" />
      <rect x="1.62952" y="14.2823" width="2.10881" height="2.10881" rx="0.151515" fill="white" />
      <rect x="5.27197" y="6.99728" width="2.10881" height="2.10881" rx="0.151515" fill="white" />
      <rect x="5.27197" y="10.6398" width="2.10881" height="2.10881" rx="0.151515" fill="white" />
      <rect x="8.91449" y="10.6398" width="2.10881" height="5.7513" rx="0.151515" fill="white" />
      <rect x="5.27197" y="14.2823" width="2.10881" height="2.10881" rx="0.151515" fill="white" />
      <rect x="8.91449" y="6.99728" width="2.10881" height="2.10881" rx="0.151515" fill="white" />
    </svg>
  )
}
