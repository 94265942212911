export const convertSearchToObject = (storeName: string, search = window.location.search) => {
  let parsedData = {}

  try {
    parsedData = JSON.parse(new URLSearchParams(search).get(storeName) || '{}')
  } catch (err) {
    /**
     * we can't necessarily trust the URL, so let's ignore the
     * search for this store if we're unable to parse the JSON
     */
  }

  return parsedData
}
