import type { TLngLat } from '@semios/app-platform-banyan-route-definitions/dist/routes/userAppStartup/fieldAssetKeyTypes'
import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { isNil } from '@semios/app-platform-common'
import type { Dispatch, SetStateAction } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { sortByKey } from 'utils/sortByKey'
import { WindMachineControls } from '../MenuComponents/DeviceControls/WindMachineControls'
import { DeviceHeader } from '../MenuComponents/DeviceHeader/DeviceHeader'
import { WindMachineInfo } from '../MenuComponents/DeviceInfo/WindMachineInfo'

export const WindMachineViewDetails = ({
  data,
  state,
  fetchData,
}: {
  data: TWindMachineDetailsElement
  state: {
    dataDate: Record<string, string | null>
    setDataDate: Dispatch<SetStateAction<Record<string, string | null>>>
    windMachineStoppedAt: Record<string, number | null>
    setWindMachineStoppedAt: Dispatch<SetStateAction<Record<string, number | null>>>
    isAuto: Record<string, boolean>
    setIsAuto: Dispatch<SetStateAction<Record<string, boolean>>>
  }
  fetchData: () => void
}) => {
  const property = selectedFieldAssetsStore.useSelector((s) => s.property)
  const sortedData = data?.windMachineDetails?.sort(sortByKey('equipmentDeviceNumber'))

  return (
    <div>
      {sortedData
        ? sortedData.map((windMachine, index) => {
            let wmName: string

            const itemLngLat =
              `POINT(${windMachine.geom.coordinates[0]} ${windMachine.geom.coordinates[1]})` as TLngLat

            if (!isNil(property) && !isNil(itemLngLat)) {
              wmName = fieldAssetStore.getState().properties?.[property]?.points?.[itemLngLat]?.name || ''
            } else {
              wmName = windMachine.equipmentDeviceNumber || ''
            }

            return (
              <div
                css={{
                  padding: '10px 10px 0px 10px',
                  overflow: 'hidden',
                }}
                key={index}
              >
                <DeviceHeader windMachineName={wmName} currentWindMachineValues={windMachine} />
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '10px',
                    width: '100%',
                  }}
                >
                  <div
                    css={{
                      padding: '0px 10px',
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    <WindMachineInfo content={windMachine} />
                    <WindMachineControls
                      windMachineName={wmName}
                      content={windMachine}
                      state={state}
                      fetchData={fetchData}
                    />
                  </div>
                </div>
              </div>
            )
          })
        : null}
    </div>
  )
}
