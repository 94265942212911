import { Loader } from '@mantine/core'
import { closeModal, openModal } from '@mantine/modals'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { InfoMessageBox } from 'components/InfoMessageBox/InfoMessageBox'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import { createContext, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { sortByKey } from 'utils/sortByKey'
import { useApiREST } from 'utils/useApiREST'
import { useSearchInput } from 'utils/useSearchInput'
import { ReportsTable } from './ReportsTable/ReportsTable'
import { useFilteredData } from './_utils/useFilteredData'

export const DownloadedFilesContext = createContext({})

const MODAL_ID = 'pheromone-pur-rec-download'

export function openDownloadModal() {
  openModal({
    modalId: MODAL_ID,
    fullScreen: true,
    withCloseButton: false,
    padding: 0,
    children: <PheromonePurRecDownloadModal />,
    styles: {
      content: {
        marginLeft: 'env(safe-area-inset-left)',
        marginRight: 'env(safe-area-inset-right)',
        boxShadow: 'none',
        transform: 'none !important',
      },
    },
  })
}

export const PheromonePurRecDownloadModal = () => {
  const [filteredProperties, setFilteredProperties] = useState<{ [propertyId: string]: boolean }>({})
  const [filteredPermitNumbers, setFilteredPermitNumbers] = useState<{ [permitNumber: string]: boolean }>({})
  const [filteredTypes, setFilteredTypes] = useState<{ [type: string]: boolean }>({})
  const [downloadedFiles, setDownloadedFiles] = useState<{ [fileLink: string]: boolean }>({})
  const properties = fieldAssetStore.useSelector((s) => Object.values(s.properties ?? {}) ?? [])

  const { data: sortedFiles, loading } = useApiREST<
    routes.PheromonePurRecDownload.Request,
    routes.PheromonePurRecDownload.Response,
    routes.PheromonePurRecDownload.Response['files']
  >({
    body: {},
    initialLoading: true,
    initialState: [],
    shaper: (data) => data.files.sort(sortByKey('dateCreatedYYYYMMDD', 'DESC')),
    url: 'pheromone-pur-rec-download',
  })

  const { SearchInput, searchString } = useSearchInput({
    placeholder: translate.phrases.banyanApp('Search for a PUR/REC'),
    maxWidth: '100%',
  })

  const filterActiveForPermitNumbers = Object.values(filteredPermitNumbers).some(Boolean)
  const filterActiveForProperties = Object.values(filteredProperties).some(Boolean)
  const filterActiveForTypes = Object.values(filteredTypes).some(Boolean)

  const dataAfterFiltersAndSearch = useFilteredData({
    files: sortedFiles,
    filterActiveForPermitNumbers,
    filterActiveForProperties,
    filterActiveForTypes,
    filteredPermitNumbers,
    filteredProperties,
    filteredTypes,
    properties,
    searchString,
  })

  return (
    <DownloadedFilesContext.Provider value={downloadedFiles}>
      <WideHeader
        title={translate.phrases.banyanApp('Pheromone PUR/REC Download')}
        onClose={() => {
          closeModal(MODAL_ID)
        }}
      />
      <div className={'data-export'} css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div css={{ maxWidth: 'min(calc(100vw - 20px), 1180px)', width: '100%', paddingTop: 10 }}>
          {loading && <Loader size="xs" />}
          {!loading && !sortedFiles.length && (
            <InfoMessageBox>{translate.phrases.banyanApp('No PUR/REC found')}</InfoMessageBox>
          )}
          {!loading && !!sortedFiles.length && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100%',
                width: '100%',
              }}
            >
              <div css={{ margin: 10 }}>{SearchInput}</div>
              <ReportsTable
                dataAfterFiltersAndSearch={dataAfterFiltersAndSearch}
                files={sortedFiles}
                filterActiveForPermitNumbers={filterActiveForPermitNumbers}
                filterActiveForProperties={filterActiveForProperties}
                filterActiveForTypes={filterActiveForTypes}
                filteredPermitNumbers={filteredPermitNumbers}
                filteredProperties={filteredProperties}
                filteredTypes={filteredTypes}
                properties={properties}
                searchString={searchString}
                setDownloadedFiles={setDownloadedFiles}
                setFilteredPermitNumbers={setFilteredPermitNumbers}
                setFilteredProperties={setFilteredProperties}
                setFilteredTypes={setFilteredTypes}
              />
            </div>
          )}
        </div>
      </div>
    </DownloadedFilesContext.Provider>
  )
}
