import type { ColorType } from 'highcharts'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { helperTagsMaker } from './helperTagsMaker'

export const minAverageMaxCellMaker = (o: {
  low?: string | null
  high?: string | null
  value?: string | null
  seriesColor?: ColorType
  dashed?: boolean
  seriesName: string
  seriesId: string
  paddingRight?: boolean
  xYear?: string | null
  valueSuffix?: string
  compareSeasons?: {
    year?: string | null
    low?: string | null
    high?: string | null
    value?: string | null
    seriesColor?: string
    valueSuffix?: string
  }
}) => {
  const { seriesId, value, valueSuffix } = o

  const hasHighAndLow =
    (!isNil(o.low) && !isNil(o.high)) || (!isNil(o.compareSeasons?.low) && !isNil(o.compareSeasons?.high))

  // this isn't a particularly important notion globally, so let's just use a somewhat arbitrary breakpoint
  const tooltipIsOnASmallishScreen = window.innerWidth < 500

  let paddingRight = '0'

  if (o.paddingRight) {
    if (!tooltipIsOnASmallishScreen) {
      paddingRight = '40px'
    } else {
      paddingRight = '6px'
    }
  }

  let template = `
  <td>
    <div style="${
      o.dashed ? `border-bottom: 2px dashed ${o.seriesColor}` : `background:${o.seriesColor}`
    }; min-width: 14px; min-height: 5px; margin: 0 7px 0 0;"></div>
  </td>
  <td style="text-align: left"><div style="width:max-content">${o.seriesName}:</div></td>
  <td style="text-align: right; padding: 0 ${paddingRight} 0 ${tooltipIsOnASmallishScreen ? 0 : 5}px;">
  `

  if (!hasHighAndLow) {
    const helperTags = helperTagsMaker({ seriesId })

    template += `
      ${
        o.compareSeasons?.year
          ? `
            <div style="display:flex;">
              <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;margin:4px 15px 4px 4px;font-size:14px">
              <div style="color:${o.seriesColor};">
                <b>${o.xYear ? ` ${o.xYear}` : ''}</b>
              </div>
              <div style="color:${o.compareSeasons?.seriesColor};">
                  <b>${o.compareSeasons.year ?? ''}</b>
                </div>
              </div>
              <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;">
              <div style="text-align: left;"width:max-content>
                <b>${helperTags.value.opening}${value ?? translate.phrases.templates('-')}${
              helperTags.value.closing
              // TODO: i18n this value === '-' check
            } ${helperTags.valueSuffix.opening}${isNil(value) || value === '-' ? '' : valueSuffix}${
              helperTags.valueSuffix.closing
            }</b
              </div>
              <div style="text-align: left;width:max-content">
                <b>${helperTags.compareSeasonsValue.opening}${
              o.compareSeasons?.value ?? translate.phrases.templates('-')
            }${helperTags.compareSeasonsValue.closing} ${helperTags.compareSeasonsValueSuffix.opening}${
              // TODO: i18n this value === '-' check
              isNil(o.compareSeasons?.value) || o.compareSeasons?.value === '-'
                ? ''
                : o.compareSeasons?.valueSuffix ?? o.valueSuffix
            }${helperTags.compareSeasonsValueSuffix.closing}</b<
                </div>
              </div>
            </div>
            `
          : `
            <div style="display: flex; justify-content: flex-start;">
              <b>${helperTags.value.opening}${o.value ?? translate.phrases.templates('-')}${
              helperTags.value.closing
            } ${helperTags.valueSuffix.opening}${o.valueSuffix}${helperTags.valueSuffix.closing}</b>
            </div>
            `
      }
      </td>`

    return template
  }

  const helperTags = helperTagsMaker({ seriesId })

  const minCell = o.low
    ? `
  <div style="text-align:right;flex:1;padding:3px;display:flex;flex-direction:column;justify-content:center;align-items:flex-end;">
    <div style="font-size:0.7em;text-align:left;">${translate.phrases.abbreviations('Minimum')}</div>
    <div style="font-size:0.9em;text-align:right"><b>${helperTags.valueMin.opening}${o.low.toString() ?? ''}${
        helperTags.valueMin.closing
      }</b></div>
    ${
      !!o.compareSeasons?.year
        ? `<div style="font-size:0.9em;text-align:right"><b>${helperTags.compareSeasonsValueMin.opening}${
            o.compareSeasons?.low?.toString() ?? translate.phrases.templates('-')
          }${helperTags.compareSeasonsValueMin.closing}</b></div>`
        : ''
    }
  </div>
`
    : ''

  const avgCell = `
    <div style="text-align:right;flex:1;padding:3px;display:flex;flex-direction:column;justify-content:center;align-items:flex-end;">
      <div style="font-size:0.7em;text-align:left;">${translate.phrases.abbreviations('Average')}</div>
      <div style="font-size:0.9em;text-align:right"><b>${helperTags.valueAverage.opening}${
    o.value?.toString() ?? ''
  }${helperTags.valueAverage.closing}</b></div>
      ${
        !!o.compareSeasons?.year
          ? `<div style="font-size:0.9em;text-align:right"><b>${
              helperTags.compareSeasonsValueAverage.opening
            }${o.compareSeasons?.value?.toString() ?? translate.phrases.templates('-')}${
              helperTags.compareSeasonsValueAverage.closing
            }</b></div>`
          : ''
      }
    </div>
  `

  const maxCell = o.high
    ? `
    <div style="text-align:right;flex:1;padding:3px;display:flex;flex-direction:column;justify-content:center;align-items:flex-end;">
      <div style="font-size:0.7em;text-align:left;">${translate.phrases.abbreviations('Maximum')}</div>
      <div style="font-size:0.9em;text-align:right"><b>${helperTags.valueMax.opening}${
        o.high.toString() ?? ''
      }${helperTags.valueMax.closing}</b></div>
      ${
        !!o.compareSeasons?.year
          ? `<div style="font-size:0.9em;text-align:right"><b>${helperTags.compareSeasonsValueMax.opening}${
              o.compareSeasons?.high?.toString() ?? translate.phrases.templates('-')
            }${helperTags.compareSeasonsValueMax.closing}</b></div>`
          : ''
      }
    </div>
  `
    : ''

  const suffixCell = `
    <div style="text-align:right;flex:1;padding:3px;display:flex;flex-direction:column;justify-content:center;align-items:flex-end;">
      <div style="font-size:0.7em;text-align:left;">&nbsp;</div>
      <div style="font-size:0.9em;text-align:right"><b>${o.valueSuffix ?? ''}</b></div>
    </div>
  `

  template += `
    <div style="display:flex;align-items:center;">
      ${
        o.compareSeasons?.year
          ? `<div style="font-size:14px;display:flex;flex-direction:column;margin:0 5px;">
              <div style="margin-top:15px;font-weight:bold;color:${o.seriesColor}">
                ${o.xYear}
              </div>
              <div style="font-weight:bold;color:${o.compareSeasons?.seriesColor}">
                ${o.compareSeasons?.year}
              </div>
            </div>
            `
          : ''
      }
        <div style="display:flex;justify-content:center;align-items:center">
          ${minCell}
          ${avgCell}
          ${maxCell}
          ${suffixCell}
        </div>
      </div>
  </td>`

  return template
}
