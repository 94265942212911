import { translate } from 'i18n/i18n'
import { useEffect } from 'react'
import { SharedSettings } from 'settings/SharedSettings'

export const PrivacyPolicy = () => {
  useEffect(() => {
    const link = translate.phrases.banyanApp('public/i18n/privacyPolicy')

    window.location.href = `${SharedSettings.S3_PUBLIC_BUCKET_URL}${link}`
  }, [])

  return null
}
