import { css } from '@emotion/css'
import { createStyles, LoadingOverlay, Stack, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { Button } from 'components/Button/Button'
import type { FC, ReactNode } from 'react'
import type { z } from 'zod'

export type InputPropsType = {
  placeholder: string
  key: string
  initialValue: string
}

export type AuthFormProps = {
  inputProps: InputPropsType[]
  validationSchema: z.Schema
  buttonText: string
  buttonRightIcon?: ReactNode
  onSubmit: (values: { [k: string]: string }) => void
  submitLoading?: boolean
  header?: ReactNode
  buttonVariant?: 'primary' | 'secondary' | 'tertiary'
}

const useStyles = createStyles((theme) => ({
  invalid: {
    borderColor: theme.colors.red[0],
  },
}))

export const SmsNumberForm: FC<AuthFormProps> = ({
  inputProps,
  validationSchema,
  buttonText,
  buttonRightIcon,
  onSubmit,
  submitLoading = false,
  buttonVariant = 'primary',
}) => {
  const { classes } = useStyles()

  const form = useForm({
    initialValues: Object.fromEntries(inputProps.map((input) => [input.key, input.initialValue])),

    validate: zodResolver(validationSchema),

    validateInputOnBlur: true,
  })

  return (
    <div
      className={css`
        display: flex;
      `}
    >
      <LoadingOverlay visible={submitLoading} />

      <div
        className={css`
          width: 100%;
        `}
      >
        <form
          onSubmit={form.onSubmit((values) => onSubmit(values))}
          className={css`
            margin-top: 20px;
          `}
        >
          <Stack>
            <div
              className={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                max-width: 365px;
              `}
            >
              {inputProps.map((input) => {
                const { placeholder, key } = input
                const formInputProps = form.getInputProps(key)

                return (
                  <TextInput
                    key={key}
                    placeholder={placeholder}
                    className={css`
                      margin-top: 10px;
                      margin-bottom: 10px;
                      margin-right: 15px;
                    `}
                    classNames={{ input: formInputProps.error ? classes.invalid : undefined }}
                    {...formInputProps}
                  />
                )
              })}
              <Button
                variant={buttonVariant}
                type="submit"
                rightIcon={buttonRightIcon}
                disabled={!form.isValid() && form.isTouched()}
              >
                {buttonText}
              </Button>
            </div>
          </Stack>
        </form>
      </div>
    </div>
  )
}
