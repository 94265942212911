import { OfflineRequestError } from 'utils/errorCodes'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import type { NodeConfigUpdateMeta } from './api/serviceCenterNodeConfigSet'
import { serviceCenterNodeConfigSet } from './api/serviceCenterNodeConfigSet'

export async function updateNodeConfig(param: NodeConfigUpdateMeta) {
  let unsynced = true

  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.SET_NODE_CONFIG, param)

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      throw error
    }
  }

  if (unsynced) {
    serviceCenterNodeConfigSet.callback(param)
  }
}
