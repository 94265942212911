import type { TPublishStatus } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'

export const getEventStatus = (status?: TPublishStatus) => {
  if (status === 'DRAFT') {
    return translate.phrases.banyanApp('Draft')
  } else if (status === 'PUBLISHED') {
    return translate.phrases.banyanApp('Scheduled')
  } else if (status === 'DELETED') {
    return translate.phrases.banyanApp('Deleted')
  } else {
    return translate.phrases.banyanApp('Run Time')
  }
}
