import { Button } from 'components/Button/Button'
import { IconPencil } from 'components/icons/IconPencil'
import { translate } from 'i18n/i18n'

export const SettingsEditButton = ({
  onClick,
  label = translate.phrases.banyanApp('Edit'),
}: {
  onClick: () => void
  label?: string
}) => {
  return (
    <Button onClick={onClick} leftIcon={<IconPencil />} variant="link">
      {label}
    </Button>
  )
}
