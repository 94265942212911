import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconPower = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M11.1111 0H8.88889V11.1111H11.1111V0ZM16.4778 2.41111L14.9 3.98889C16.6556 5.4 17.7778 7.56667 17.7778 10C17.7778 14.3 14.3 17.7778 10 17.7778C5.7 17.7778 2.22222 14.3 2.22222 10C2.22222 7.56667 3.34444 5.4 5.08889 3.97778L3.52222 2.41111C1.36667 4.24444 0 6.95556 0 10C0 15.5222 4.47778 20 10 20C15.5222 20 20 15.5222 20 10C20 6.95556 18.6333 4.24444 16.4778 2.41111Z"
        fill="currentColor"
      />
    </svg>
  )
}
