/**
 * A hacky way to measure what the width of some text would be rendered as. Returns value in pixels.
 * @param {string} fontSize
 * @param {string} fontWeight
 * @param {string} text
 * @returns {number}
 */
export const calculateTextWidth = ({
  fontSize,
  fontWeight,
  text,
}: {
  fontSize: string
  fontWeight: string
  text: string
}) => {
  const div = document.createElement('div')

  div.style.fontSize = fontSize

  div.style.fontWeight = fontWeight

  div.innerText = text

  div.style.position = 'absolute'

  div.style.visibility = 'hidden'

  div.style.height = 'auto'

  div.style.width = 'auto'

  div.style.whiteSpace = 'nowrap'

  document.body.appendChild(div)

  const textWidth = div.clientWidth + 1

  document.body.removeChild(div)

  return textWidth
}
