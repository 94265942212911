import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type { GridTableContentSection, GridTableContentSectionItem } from 'components/GridTable/types'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import {
  doesSelectedPointHaveValueTypes,
  doesSelectedRegionHaveValueTypes,
} from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { getSelectedRegion } from 'utils/getSelectedRegion'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { MinMaxValueCell } from '../../../MinMaxValueCell/MinMaxValueCell'
import {
  defaultValuesRowHeight,
  regionsTimestampToValuesDictionaryMaker,
  weatherTimestampToValuesDictionaryMaker,
} from '../_utils'

type CommonValueType =
  VV.DomainTypes.Weather.TValuesReturnWithMetaIgnoringKeying['points']['dewPoint_IN'][number]['timeseries'][number]

const permissionRequired = 'VIEW_WEATHER_DETAILS'

const checkPermission = () =>
  selectedPropertyHasPermission({ permission: permissionRequired }) ||
  (isUserOnlyAFreeRegionalUser() && selectedRegionHasPermission({ permission: permissionRequired }))

const pointCategory: TPointCategory = 'weatherPoint'

const possibleValueTypesToRequest: VV.DomainTypes.Weather.TTimeseriesValueTypeKeysMerged[] = [
  'dewPoint_ABOVE',
  'dewPoint_IN',
  'dewPoint_BELOW',
]

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const getRegionalValuesRequested = ({
  selectedFieldAssets,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  const selectedProperty = selectedFieldAssets.property
  const selectedRegion = getSelectedRegion({ selectedFieldAssets, selectedProperty })

  if (!selectedRegion) return {}

  const valuesForRegion = fieldAssetStore.getState().regions?.[selectedRegion]?.valuesTimeseries || []
  const valuesToRequestForRegion = possibleValueTypesToRequest.filter((v) => valuesForRegion.includes(v))

  const valuesRequested = valuesToRequestForRegion.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    regions: {
      regionIds: [selectedRegion],
      valuesRequested,
    },
  }
}

const getValuesRequestedForPoints = ({
  selectedFieldAssets,
}: {
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}) => {
  if (!selectedFieldAssets[pointCategory] || !selectedFieldAssets.property) return {}

  const valuesForPoint =
    fieldAssetStore.getState().properties?.[selectedFieldAssets.property]?.points?.[
      selectedFieldAssets[pointCategory]
    ]?.valuesTimeseries || []

  const valuesToRequest = possibleValueTypesToRequest.filter((v) => valuesForPoint.includes(v))

  const valuesRequested = valuesToRequest.reduce((request, valueType) => {
    request[valueType] = preferredAggregationInterval

    return request
  }, {} as Partial<Record<typeof possibleValueTypesToRequest[number], typeof preferredAggregationInterval>>)

  if (isEmpty(valuesRequested)) return {}

  return {
    points: {
      lngLats: [selectedFieldAssets[pointCategory]],
      valuesRequested,
    },
  }
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.dew_point) return {}

  const showRegionalData = smallStore.getState().showRegionalData
  const pointsToRequest = getValuesRequestedForPoints({ selectedFieldAssets })
  const regionsToRequest = showRegionalData ? getRegionalValuesRequested({ selectedFieldAssets }) : {}

  return { ...pointsToRequest, ...regionsToRequest }
}

const generateItem = ({
  id,
  values,
  valueType,
  labelOverride,
}: {
  id: string
  // values: Record<string, CommonValueType> todo: fix ts-ignore in GetTimeseriesType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
  valueType: keyof typeof unitConverter
  labelOverride?: string
}): {
  height: GridTableContentSectionItem['height']
  id: string
  label: React.ReactNode
  labelMinWidth: GridTableContentSectionItem['labelMinWidth']
  render: GridTableContentSectionItem['render']
  valueMinWidth: GridTableContentSectionItem['valueMinWidth']
  values: Record<string, CommonValueType>
} => ({
  id,
  label: labelOverride ? labelOverride : unitConverter[valueType]().shortTitle(),
  height: defaultValuesRowHeight,
  labelMinWidth: 160,
  valueMinWidth: 120,
  render: (dataPoint: { value: { max: number | null | undefined; min: number | null | undefined } }) => {
    // TODO: maybe the route definitions can be more sophisticated in its return?

    if (dataPoint === undefined || typeof dataPoint.value === 'number' || dataPoint === null) {
      return translate.phrases.templates('-')
    }

    const { max, min } = dataPoint.value

    const maxWithSuffix =
      max !== null && max !== undefined ? unitConverter[valueType](max).valueWithSuffix() : null

    const minWithSuffix =
      min !== null && min !== undefined ? unitConverter[valueType](min).valueWithSuffix() : null

    return <MinMaxValueCell min={minWithSuffix} max={maxWithSuffix} />
  },
  values,
})

export const content = ({ data }: { data: routes.Values.Response }): GridTableContentSection => {
  const commonReturnItems = {
    title: unitConverter.dewPointInCanopy().categoryTitleWithoutUnit(),
    titleChildren: (
      <DropdownSelectorPoint
        pointCategory={pointCategory}
        valuesTimeseriesToFilterOn={possibleValueTypesToRequest}
      />
    ),
    id: 'summary-grid-dew-point',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const stationLngLat = String(selectedFieldAssetsStore.getState()[pointCategory])
  const selectedProperty = Number(selectedFieldAssetsStore.getState().property)
  const selectedRegion = getSelectedRegion({ selectedProperty })

  const hasAboveCanopySensor = doesSelectedPointHaveValueTypes({
    valuesTimeseries: ['dewPoint_ABOVE'],
    pointCategory,
  })

  const hasInCanopySensor = doesSelectedPointHaveValueTypes({
    valuesTimeseries: ['dewPoint_IN'],
    pointCategory,
  })

  const hasBelowCanopySensor = doesSelectedPointHaveValueTypes({
    valuesTimeseries: ['dewPoint_BELOW'],
    pointCategory,
  })

  const showRegionalData =
    smallStore.getState().showRegionalData &&
    doesSelectedRegionHaveValueTypes({
      valuesTimeseries: ['dewPoint_IN'],
    })

  const items = [
    hasAboveCanopySensor &&
      generateItem({
        id: 'dew-point-above',
        values: weatherTimestampToValuesDictionaryMaker(data, 'dewPoint_ABOVE', stationLngLat),
        valueType: 'temperatureAboveCanopy',
      }),
    hasInCanopySensor &&
      generateItem({
        id: 'dew-point-in',
        values: weatherTimestampToValuesDictionaryMaker(data, 'dewPoint_IN', stationLngLat),
        valueType: 'temperatureInCanopy',
      }),
    hasBelowCanopySensor &&
      generateItem({
        id: 'dew-point-below',
        values: weatherTimestampToValuesDictionaryMaker(data, 'dewPoint_BELOW', stationLngLat),
        valueType: 'temperatureBelowCanopy',
      }),
  ].filter(Boolean) as GridTableContentSectionItem[]

  if (showRegionalData && selectedRegion) {
    const regionName = items.length ? fieldAssetStore.getState().regions?.[selectedRegion]?.name : undefined

    const regionalItem = generateItem({
      id: 'dew-point-regional',
      values: regionsTimestampToValuesDictionaryMaker(data, 'dewPoint_IN', selectedRegion),
      valueType: 'temperatureInCanopy',
      labelOverride: regionName,
    })

    items.push(regionalItem)
  }

  return {
    ...commonReturnItems,
    items,
  }
}
