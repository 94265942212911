import type CssType from 'csstype'
import type { Dispatch } from 'react'
import React, { useEffect, useRef } from 'react'
import { colors } from 'settings/colors'
import isReadonlyArray from 'utils/isReadonlyArray'
import { useScreenSize } from 'utils/useScreenSize'
import type { TabPane } from '../../DetailPanel/DetailPanel'
import { PanelTabList } from '../SidePanel'
import { GridLines } from './GridLines/GridLines'
import Handle from './Handle/Handle'
import type {
  MOVE_COORDS,
  MOVE_COORDS_NULLABLE,
  MOVE_POINT_STATE,
  SIZES,
  SIZES_SNAPPING,
} from './useMovePoint/useMoveTracker'
import { getStuffFromSizes, useMoveTracker } from './useMovePoint/useMoveTracker'

export type ExtraProps = {
  x1: MOVE_POINT_STATE['x1']
  x2: MOVE_POINT_STATE['x2']
  y1: MOVE_POINT_STATE['y1']
  y2: MOVE_POINT_STATE['y2']
  move: (coords: MOVE_COORDS_NULLABLE) => void
  sizes: SIZES
  transition: CssType.Property.Transition<string> | undefined
}

export type PANEL_PROPS = {
  children: React.ReactNode
  x1Start?: number
  x2Start?: number
  y1Start?: number
  y2Start?: number
  backgroundColor: CssType.Property.BackgroundColor
  borderRadius?: CssType.Property.BorderRadius
  gripWidth?: number
  gripBackground?: CssType.Property.BackgroundColor
  panelOverflow?: CssType.Property.Overflow
  sizes: SIZES
  sizesSnapping: SIZES_SNAPPING
  debugShowSnapPointsOnDrag: boolean
  onAfterResize?: (coords: MOVE_COORDS) => void
  handleSize: number
  allowDragContentForTouch?: boolean
  onCoordsChange?: (args: {
    coords: MOVE_COORDS
    visibleWidth: number
    visibleHeight: number
    handleSize: number
  }) => void
  setIsPanelExpanded?: Dispatch<React.SetStateAction<boolean>>
  placement: 'right' | 'bottom'
  tabs?: TabPane[]
}

export default function Panel(props: PANEL_PROPS) {
  const allowDragContentForTouch = props.allowDragContentForTouch ?? false
  const panelOverflow = props.panelOverflow ?? 'auto'
  const gripWidth = props.gripWidth ?? 6 // TODO: Probably don't need to expose this as an option. Reminder that it affects both mouse and touch.
  const halfOfGripWidth = gripWidth / 2
  const handleSize = props.handleSize
  const ref = useRef<HTMLDivElement>(null)

  const moveTracker = useMoveTracker({
    x1Start: props.x1Start ?? (isReadonlyArray(props.sizes.x1) ? props.sizes.x1[0] : props.sizes.x1),
    x2Start: props.x2Start ?? (isReadonlyArray(props.sizes.x2) ? props.sizes.x2[0] : props.sizes.x2),
    y1Start: props.y1Start ?? (isReadonlyArray(props.sizes.y1) ? props.sizes.y1[0] : props.sizes.y1),
    y2Start: props.y2Start ?? (isReadonlyArray(props.sizes.y2) ? props.sizes.y2[0] : props.sizes.y2),
    // cursorX1: 'crosshair',
    // cursorX2: 'ns-resize',
    sizes: props.sizes,
    sizesSnapping: props.sizesSnapping,
    onAfterResize: props.onAfterResize,
    allowDragContentForTouch: props.allowDragContentForTouch,
  })

  const transition = !moveTracker.tracking ? 'top .3s, height .3s, left .3s, width .3s' : undefined
  const { isWideScreen } = useScreenSize()
  const { x1Max, y1Max } = getStuffFromSizes(props.sizes)

  useEffect(() => {
    if (
      allowDragContentForTouch &&
      ref.current &&
      moveTracker.pointerType === 'touch' &&
      moveTracker.tracking !== null
    ) {
      if (moveTracker.tracking === 'Y1' || moveTracker.tracking === 'Y2') {
        ref.current.scrollTop = moveTracker.scrolled
      } else if (moveTracker.tracking === 'X1' || moveTracker.tracking === 'X2') {
        ref.current.scrollLeft = moveTracker.scrolled
      }
    }
  }, [allowDragContentForTouch, moveTracker.pointerType, moveTracker.tracking, moveTracker.scrolled])

  useEffect(() => {
    if (!props.setIsPanelExpanded) return

    if (moveTracker.x1 > 0 && moveTracker.x1 < x1Max) {
      props.setIsPanelExpanded(true)
    } else if (moveTracker.y1 > 0 && moveTracker.y1 < y1Max) {
      props.setIsPanelExpanded(true)
    } else {
      props.setIsPanelExpanded(false)
    }
  }, [moveTracker.x1, moveTracker.y1])

  const isY1Resizable = Array.isArray(props.sizes.y1)
  const isY2Resizable = Array.isArray(props.sizes.y2)
  const isX1Resizable = Array.isArray(props.sizes.x1)
  const isX2Resizable = Array.isArray(props.sizes.x2)

  // TODO: Needed? Expose the coords, width, height
  useEffect(() => {
    if (props.onCoordsChange) {
      props.onCoordsChange({
        coords: {
          y1: moveTracker.y1,
          y2: moveTracker.y2,
          x1: moveTracker.x1,
          x2: moveTracker.x2,
        },
        visibleWidth: Math.round(moveTracker.x2 - moveTracker.x1 - handleSize),
        visibleHeight: Math.round(moveTracker.y2 - moveTracker.y1 - handleSize),
        handleSize,
      })
    }
  }, [props.onCoordsChange, moveTracker.y1, moveTracker.y2, moveTracker.x1, moveTracker.x2, handleSize])

  return (
    <>
      <div
        ref={ref}
        style={{
          position: 'fixed',
          top: moveTracker.y1,
          height: moveTracker.y2 - moveTracker.y1,
          left: moveTracker.x1,
          width: moveTracker.x2 - moveTracker.x1,
          overflow: panelOverflow,
          touchAction: allowDragContentForTouch ? 'none' : 'pan-x pan-y', // TODO: with allowDragContentForTouch try to make opposite access scrolling work. e.g. when tracking Y1, need to allow pan-x.
          // borderRadius: props.borderRadius,
          backgroundColor: props.backgroundColor,
          transition,
        }}
        onPointerDown={(e) => {
          // TODO: test with pen
          if (!allowDragContentForTouch || !e.isPrimary) return

          const isMouse = e.pointerType === 'mouse'

          if (isMouse) {
            if (isY1Resizable && e.clientY - moveTracker.y1 <= halfOfGripWidth) {
              moveTracker.onPointerDownY1(e)

              return
            }

            if (isY2Resizable && moveTracker.y2 - e.clientY <= halfOfGripWidth) {
              moveTracker.onPointerDownY2(e)

              return
            }

            if (isX1Resizable && e.clientX - moveTracker.x1 <= halfOfGripWidth) {
              moveTracker.onPointerDownX1(e)

              return
            }

            if (isX2Resizable && moveTracker.x2 - e.clientX <= halfOfGripWidth) {
              moveTracker.onPointerDownX2(e)

              return
            }
          }

          if (!isMouse && ref.current) {
            if (isY1Resizable) {
              moveTracker.setScrolledStart(ref.current.scrollTop)

              moveTracker.onPointerDownY1(e)

              return
            }

            if (isY2Resizable) {
              moveTracker.setScrolledStart(ref.current.scrollTop)

              moveTracker.onPointerDownY2(e)

              return
            }

            if (isX1Resizable) {
              moveTracker.setScrolledStart(ref.current.scrollLeft)

              moveTracker.onPointerDownX1(e)

              return
            }

            if (isX2Resizable) {
              moveTracker.setScrolledStart(ref.current.scrollLeft)

              moveTracker.onPointerDownX2(e)

              return
            }
          }
        }}
      >
        {props.children}
      </div>
      {isY1Resizable && (
        <>
          <div
            css={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              transition: transition,
              backgroundColor: colors.midnight,
              borderBottom: '1px solid #363F4F',
              position: 'fixed',
              left: moveTracker.x1,
              top: moveTracker.y1 - handleSize,
              height: handleSize,
              width: moveTracker.x2 - moveTracker.x1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPointerDown={isWideScreen ? moveTracker.onPointerDownY1 : undefined}
          >
            <div
              css={{
                width: 47,
                height: 5,
                backgroundColor: colors.grey200,
                borderRadius: 10,
                alignSelf: 'flex-start',
                marginTop: 10,
              }}
            />
          </div>
          <div
            css={{
              borderTopRightRadius: 10,
              transition: transition,
              backgroundColor: colors.midnight,
              borderBottom: '1px solid #363F4F',
              position: 'fixed',
              right: 0,
              top: moveTracker.y1 - handleSize,
              height: handleSize,
              width: 38,
              background: `linear-gradient(270deg, ${colors.grey800} 5%, ${colors.midnight} 95%)`,
            }}
          />
        </>
      )}
      {isY2Resizable && (
        <Handle
          left={moveTracker.x1}
          top={moveTracker.y2 - handleSize}
          height={handleSize}
          width={moveTracker.x2 - moveTracker.x1}
          onPointerDown={moveTracker.onPointerDownY2}
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            cursor: moveTracker.cursorY2,
            transition: transition,
            backgroundColor: colors.midnight,
            borderTop: '1px solid #363F4F',
          }}
          handleHeight={3}
          handleWidth={32}
        />
      )}
      {isX1Resizable && (
        <>
          <div
            css={{
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              cursor: isWideScreen ? moveTracker.cursorX1 : 'default',
              transition: transition,
              backgroundColor: colors.midnight,
              position: 'fixed',
              left: moveTracker.x1 - handleSize,
              top: moveTracker.y1,
              height: moveTracker.y2 - moveTracker.y1,
              width: handleSize,
              display: 'flex',
              zIndex: 0,
              alignItems: 'center',
            }}
            onPointerDown={isWideScreen ? moveTracker.onPointerDownX1 : undefined}
          >
            {isWideScreen && (
              <div
                css={{
                  width: 5,
                  height: 47,
                  backgroundColor: colors.grey200,
                  borderRadius: 10,
                  marginLeft: 10,
                }}
              />
            )}
          </div>
          {!isWideScreen && (
            <div
              css={{
                borderBottomLeftRadius: 10,
                transition: transition,
                position: 'fixed',
                left: moveTracker.x1 - handleSize,
                bottom: 'calc(5px + env(safe-area-inset-bottom))',
                height: 38,
                width: handleSize,
                display: 'flex',
                zIndex: 0,
                alignItems: 'center',
                background: `linear-gradient(0deg, ${colors.grey800} 5%, ${colors.midnight} 95%)`,
              }}
            />
          )}
        </>
      )}
      {isX2Resizable && (
        <Handle
          left={moveTracker.x2 - handleSize}
          top={moveTracker.y1}
          height={moveTracker.y2 - moveTracker.y1}
          width={handleSize}
          onPointerDown={moveTracker.onPointerDownX2}
          style={{
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            cursor: moveTracker.cursorX2,
            transition: transition,
            backgroundColor: colors.midnight,
            borderLeft: '1px solid #363F4F',
          }}
          handleHeight={32}
          handleWidth={3}
        />
      )}
      {!!props.tabs?.length && (
        <PanelTabList
          x1={moveTracker.x1}
          x2={moveTracker.x2}
          y1={moveTracker.y1}
          y2={moveTracker.y2}
          move={moveTracker.move}
          sizes={props.sizes}
          transition={transition}
          placement={props.placement}
          tabs={props.tabs}
        />
      )}
      {props.debugShowSnapPointsOnDrag && (
        <GridLines tracking={moveTracker.tracking} sizes={props.sizes} sizesSnapping={props.sizesSnapping} />
      )}
    </>
  )
}
