import { routes } from '@semios/app-platform-banyan-route-definitions'
import { useEffect, useState } from 'react'
import { useApiREST } from 'utils/useApiREST'

export type TAdminUser = routes.AdminGetUsers.Response['data'][number]

export type TAdminPermission = routes.AdminGetPermissions.Response['data'][number]

export type TRole = routes.AdminGetRoles.Response['data'][number]

export type TAdminRegion = routes.AdminGetRegions.Response['data'][number]

export type TAdminProperty = routes.AdminGetProperties.Response['data'][number]

export type TOrgAdmin = routes.AdminGetOrgAdminInfo.Response['data'][number]

type TAdminEntities = {
  USERS: TAdminUser[]
  PROPERTIES: TAdminProperty[]
  REGIONS: TAdminRegion[]
  ROLES: TRole[]
  PERMISSIONS: TAdminPermission[]
  ORG_ADMIN: TOrgAdmin[]
}

export const useAdminEntities = (
  preventFetch: boolean,
  includeEntities: Record<keyof TAdminEntities, boolean>,
) => {
  const [data, setData] = useState<TAdminEntities>({
    USERS: [],
    PROPERTIES: [],
    REGIONS: [],
    ROLES: [],
    PERMISSIONS: [],
    ORG_ADMIN: [],
  })

  const [watcher, setWatcher] = useState<Partial<Record<string, number>>>({})
  const [loading, setLoading] = useState(false)

  const { loading: userLoading } = useApiREST<
    routes.AdminGetUsers.Request,
    routes.AdminGetUsers.Response,
    null
  >({
    body: null,
    url: routes.AdminGetUsers.path,
    initialLoading: false,
    preventFetch: preventFetch || !includeEntities.USERS,
    watchers: [watcher.USERS ?? false],
    shaper: (data) => {
      if (data?.data) {
        setData((prevState) => ({ ...prevState, USERS: data.data }))
      }

      return null
    },
  })

  const { loading: propertiesLoading } = useApiREST<
    routes.AdminGetProperties.Request,
    routes.AdminGetProperties.Response,
    null
  >({
    body: null,
    url: routes.AdminGetProperties.path,
    initialLoading: false,
    preventFetch: preventFetch || !includeEntities.PROPERTIES,
    watchers: [watcher.PROPERTIES ?? false],
    shaper: (data) => {
      if (data?.data) {
        setData((prevState) => ({ ...prevState, PROPERTIES: data.data }))
      }

      return null
    },
  })

  const { loading: regionsLoading } = useApiREST<
    routes.AdminGetRegions.Request,
    routes.AdminGetRegions.Response,
    null
  >({
    body: null,
    url: routes.AdminGetRegions.path,
    initialLoading: false,
    watchers: [watcher.REGIONS ?? false],
    preventFetch: preventFetch || !includeEntities.REGIONS,
    shaper: (data) => {
      if (data?.data) {
        setData((prevState) => ({ ...prevState, REGIONS: data.data }))
      }

      return null
    },
  })

  const { loading: rolesLoading } = useApiREST<
    routes.AdminGetRoles.Request,
    routes.AdminGetRoles.Response,
    null
  >({
    body: null,
    url: routes.AdminGetRoles.path,
    initialLoading: false,
    watchers: [watcher.ROLES ?? false],
    preventFetch: preventFetch || !includeEntities.ROLES,
    shaper: (data) => {
      if (data) {
        setData((prevState) => ({ ...prevState, ROLES: data.data }))
      }

      return null
    },
  })

  const { loading: permissionsLoading } = useApiREST<
    routes.AdminGetPermissions.Request,
    routes.AdminGetPermissions.Response,
    null
  >({
    body: null,
    url: routes.AdminGetPermissions.path,
    initialLoading: false,
    watchers: [watcher.PERMISSIONS ?? false],
    preventFetch: preventFetch || !includeEntities.PERMISSIONS,
    shaper: (data) => {
      if (data?.data) {
        setData((prevState) => ({ ...prevState, PERMISSIONS: data.data }))
      }

      return null
    },
  })

  const { loading: orgAdminLoading } = useApiREST<
    routes.AdminGetOrgAdminInfo.Request,
    routes.AdminGetOrgAdminInfo.Response,
    null
  >({
    body: null,
    url: routes.AdminGetOrgAdminInfo.path,
    initialLoading: false,
    watchers: [watcher.ORG_ADMIN ?? false],
    preventFetch,
    shaper: (data) => {
      if (data?.data) {
        setData((prevState) => ({ ...prevState, ORG_ADMIN: data.data }))
      }

      return null
    },
  })

  useEffect(() => {
    setLoading(
      userLoading ||
        propertiesLoading ||
        rolesLoading ||
        permissionsLoading ||
        orgAdminLoading ||
        regionsLoading,
    )
  }, [userLoading, permissionsLoading, rolesLoading, propertiesLoading, orgAdminLoading, regionsLoading])

  return { data, loading, setWatcher }
}
