import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconWaterDrop = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 28')}>
      <path
        d="M20 16.9688C20 19.4883 18.9991 21.9047 17.2175 23.6863C15.4359 25.4679 13.0196 26.4687 10.5 26.4688C7.98044 26.4687 5.56408 25.4679 3.78249 23.6863C2.00089 21.9047 1 19.4883 1 16.9688C1 11.72 10.5 1.53125 10.5 1.53125C10.5 1.53125 20 11.72 20 16.9688Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00781 10.7463C7.89844 9.41628 8.91969 8.05065 9.95281 6.78003"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33594 16.5889C4.50099 15.319 4.94725 14.1019 5.64219 13.0264"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 16.9688C20 22.2175 15.7488 25.2812 10.5 25.2812C5.25125 25.2812 1 22.2175 1 16.9688"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
