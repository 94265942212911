import { Group, MultiSelect, Stack, TextInput } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TAdminPermission } from 'App/Map/Admin/utils/useAdminEntites'
import { Button } from 'components/Button/Button'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'

const PERMISSION_ID_REGEX_PATTERN = /^(?!.*__.*)(?!_)[A-Z0-9_]*[A-Z0-9]$/

export const ViewEditPermissionModal = ({
  permission,
  opened,
  onClose,
}: {
  permission: TAdminPermission | null
  opened: boolean
  onClose: (hasChanges?: boolean) => void
}) => {
  const [selectedPermission, setPermission] = useState<TAdminPermission | null>(permission)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setHasErrors] = useState<Partial<Record<keyof TAdminPermission, string | null>>>({})
  const [showConfirmCancelWithoutSaving, setShowConfirmCancelWithoutSaving] = useState(false)
  const [fieldTouched, setFieldTouched] = useState<Partial<Record<keyof TAdminPermission, boolean>>>({})

  const handleResetAndClose = (hasChanges?: boolean) => {
    setPermission(null)

    setHasChanges(false)

    setHasErrors({})

    onClose(hasChanges)
  }

  useEffect(() => {
    if (permission && opened) {
      setPermission({ ...permission })
    } else {
      setPermission(null)
    }
  }, [permission, opened])

  useEffect(() => {
    setHasChanges(!isEqual(selectedPermission, permission))

    setHasErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      const { id, description, permissionTypes } = selectedPermission || {}

      if (id && !PERMISSION_ID_REGEX_PATTERN.test(id)) {
        newErrors.id = translate.phrases.banyanApp(
          'A permission ID must only contain uppercase letters and numbers with underscores "_" in place of spaces',
        )
      } else {
        newErrors.id = id
          ? null
          : translate.phrases.validation('{{label}} is required', {
              label: translate.phrases.banyanApp('Permission ID'),
            })
      }

      newErrors.permissionTypes = permissionTypes?.length
        ? null
        : translate.phrases.validation('{{label}} is required', {
            label: translate.phrases.banyanApp('Type'),
          })

      newErrors.description = description?.trim()?.length
        ? null
        : translate.phrases.validation('{{label}} is required', {
            label: translate.phrases.banyanApp('Description'),
          })

      return newErrors
    })
  }, [selectedPermission])

  const preventAutoCompleteProps = { autoComplete: 'off', name: 'searchHack' }

  const handleUserClickClose = () => {
    if (hasChanges) {
      setShowConfirmCancelWithoutSaving(true)
    } else {
      setPermission(null)

      handleResetAndClose()
    }
  }

  const handleUpdate = (key: keyof TAdminPermission, value: TAdminPermission[keyof TAdminPermission]) => {
    setPermission((prev) => {
      if (prev) {
        return { ...prev, [key]: value }
      }

      return { [key]: value } as TAdminPermission
    })
  }

  const handleSave = async () => {
    const hasErrors = Object.values(errors).some(Boolean)

    if (hasErrors || !selectedPermission) {
      return
    }

    const { id: permissionId, description, permissionTypes } = selectedPermission

    const result = await apiFetch<
      routes.AdminUpsertPermission.Request,
      routes.AdminUpsertPermission.Response
    >({
      url: routes.AdminUpsertPermission.path,
      body: {
        id: permissionId,
        description,
        permissionTypes,
      },
    }).catch(() => {
      showNotification({
        type: 'error',
        message: translate.phrases.validation('{{label}} could not be updated', {
          label: translate.phrases.banyanApp('Permission'),
        }),
      })
    })

    if (result?.data) {
      showNotification({
        type: 'success',
        message: translate.phrases.banyanApp('Permission successfully updated'),
      })
    } else {
      showNotification({
        type: 'error',
        message: translate.phrases.validation('{{label}} could not be updated', {
          label: translate.phrases.banyanApp('Permission'),
        }),
      })
    }

    handleResetAndClose(true)
  }

  const modalTitle = selectedPermission
    ? translate.phrases.banyanApp('View/Edit Permission')
    : translate.phrases.banyanApp('Create New Permission')

  return (
    <ModalDrawer
      title={modalTitle}
      opened={opened}
      onClose={handleUserClickClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 10}
    >
      {
        <form css={{ padding: 10 }}>
          <Stack spacing="lg">
            <TextInput
              {...preventAutoCompleteProps}
              label={translate.phrases.banyanApp('ID')}
              required
              defaultValue={selectedPermission?.id ?? ''}
              onChange={(e) => handleUpdate('id', e.target.value)}
              onBlur={() => setFieldTouched((prev) => ({ ...prev, id: true }))}
              error={fieldTouched.id && errors.id}
            />

            <TextInput
              {...preventAutoCompleteProps}
              autoComplete="off"
              label={translate.phrases.banyanApp('Description')}
              required
              defaultValue={selectedPermission?.description ?? ''}
              onChange={(e) => handleUpdate('description', e.target.value)}
              onBlur={() => setFieldTouched((prev) => ({ ...prev, description: true }))}
              error={fieldTouched.description && errors.description}
            />

            <MultiSelect
              label={translate.phrases.banyanApp('Permission Types')}
              required
              defaultValue={selectedPermission?.permissionTypes ?? []}
              onChange={(value: TAdminPermission['permissionTypes']) =>
                handleUpdate('permissionTypes', value)
              }
              onBlur={() => setFieldTouched((prev) => ({ ...prev, permissionTypes: true }))}
              data={[
                { value: 'PROPERTY', label: translate.phrases.banyanApp('Property') },
                { value: 'SYSTEM', label: translate.phrases.banyanApp('System') },
                { value: 'USER', label: translate.phrases.banyanApp('User') },
              ]}
              error={fieldTouched.permissionTypes && errors.permissionTypes}
            />
          </Stack>
          <Group css={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="secondary" onClick={handleUserClickClose}>
              {'Cancel'}
            </Button>
            <Button variant="primary" disabled={Object.values(errors).some(Boolean)} onClick={handleSave}>
              {'Submit'}
            </Button>
          </Group>
        </form>
      }
      <ConfirmationSettingsModal
        confirmModalOpened={showConfirmCancelWithoutSaving}
        setConfirmModalOpened={setShowConfirmCancelWithoutSaving}
        handleResetAndClose={() => {
          handleResetAndClose()
        }}
        handleUpdate={handleSave}
      />
    </ModalDrawer>
  )
}
