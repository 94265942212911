import { translate } from 'i18n/i18n'
import type { Rule } from '../settings/alertSettings'

// a function that returns true if a value is a number or a stringy number, but is not NaN
export const isNumberOrNumericString = (num: Rule['value']) => {
  if (Number.isNaN(num)) return false

  if (typeof num === 'number') return true

  if (typeof num === 'string' && !Number.isNaN(+num) && num !== '') return true

  return false
}

/**
 * NOTE: the database column for rule values is a string, for flexibility,
 * but we want to treat values as number sometimes for internal-validation,
 * so we should use a looser check on numberity.
 */
/**
 * ALSO NOTE: the pattern for alert validators differs from that of the
 * shared ones, since the shared pattern came after to work well with
 * the useAntFormValidation hook. This could be refactored, or not
 */
export const AlertsValidators = {
  almondHullSplitDaysBefore: ({ daysBefore }: { daysBefore: number }) => {
    if (!isNumberOrNumericString(daysBefore) || daysBefore > 24 || daysBefore < 0)
      return translate.phrases.banyanApp(
        'The number of days must be between {{lowValue}} and {{highValue}}',
        {
          lowValue: 0,
          highValue: 24,
        },
      )

    return false
  },
  alternaria: ({ alternaria }: { alternaria: Rule['value'] }) => {
    if (!isNumberOrNumericString(alternaria) || Number(alternaria) <= 0)
      return translate.phrases.banyanApp('The Alternaria Cumulative DSV must be greater than {{value}}', {
        value: 0,
      })

    return false
  },
  cultivarId: ({ cultivarId }: { cultivarId: string[] | null }) => {
    if (!cultivarId || !cultivarId.length)
      return translate.phrases.banyanApp('You must select at least one almond variety')

    return false
  },
  degreeDays: ({ degreeDays }: { degreeDays: Rule['value'] }) => {
    if (!isNumberOrNumericString(degreeDays) || Number(degreeDays) <= 0)
      return translate.phrases.banyanApp('The degree days value must be a number greater than {{value}}', {
        value: 0,
      })

    return false
  },
  degreeDaysStartDate: ({
    customStartDate,
    isCustomStartDate,
  }: {
    customStartDate: string | null
    isCustomStartDate: boolean
  }) => {
    if (isCustomStartDate && !customStartDate)
      return translate.phrases.banyanApp(
        'Please choose a valid custom biofix date or base the accumulation on the weather station biofix date',
      )

    return false
  },
  fireBlight: ({ fireBlight }: { fireBlight: Rule['value'] }) => {
    if (!isNumberOrNumericString(fireBlight) || Number(fireBlight) < 0)
      return translate.phrases.banyanApp('The Fire Blight TRV must be a number greater than {{value}}', {
        value: 0,
      })

    return false
  },
  leafWetness: ({ leafWetness }: { leafWetness: Rule['value'] }) => {
    if (!isNumberOrNumericString(leafWetness) || Number(leafWetness) < 0 || Number(leafWetness) > 100)
      return translate.phrases.banyanApp(
        'The leaf wetness % must not be less than {{lowValue}} nor greater than {{highValue}}',
        {
          lowValue: 0,
          highValue: 100,
        },
      )

    return false
  },
  leafWetnessHours: ({ hours }: { hours: Rule['value'] }) => {
    if (!isNumberOrNumericString(hours) || Number(hours) < 1 || Number(hours) > 24)
      return translate.phrases.banyanApp(
        'The hours must be a number between {{lowValue}} and {{highValue}}',
        {
          lowValue: 1,
          highValue: 24,
        },
      )

    return false
  },
  operator: ({ operator }: { operator: string | null }) => {
    if (operator !== null && !['>=', '<='].includes(operator))
      return translate.phrases.banyanApp(
        "Please choose either 'greater than or equal to' or 'less than or equal to' for inversion",
      )

    return false
  },
  numberOfDays: ({ numberOfDays }: { numberOfDays: Rule['value'] }) => {
    if (!isNumberOrNumericString(numberOfDays) || Number(numberOfDays) > 30 || Number(numberOfDays) < 0)
      return translate.phrases.banyanApp(
        'The number of days must be between {{lowValue}} and {{highValue}}',
        {
          lowValue: 0,
          highValue: 30,
        },
      )

    return false
  },
  temperature: ({ temperature }: { temperature: Rule['value'] }) => {
    if (!isNumberOrNumericString(temperature))
      return translate.phrases.banyanApp('Please enter a valid number for temperature')

    return false
  },
  temperatureInversion: ({ temperature }: { temperature: Rule['value'] }) => {
    if (!isNumberOrNumericString(temperature))
      return translate.phrases.banyanApp('Please enter a valid number for inversion')

    return false
  },
  temperatureWetBulb: ({ temperature }: { temperature: Rule['value'] }) => {
    if (!isNumberOrNumericString(temperature))
      return translate.phrases.banyanApp('Please enter a valid number for wet bulb temperature')

    return false
  },
  temperatureTypes: ({
    belowTemperature,
    aboveTemperature,
  }: {
    belowTemperature: boolean
    aboveTemperature: boolean
  }) => {
    if (!belowTemperature && !aboveTemperature)
      return translate.phrases.banyanApp('Please select at least one temperature measurement height')

    return false
  },
  trapCatches: ({ trapCatches }: { trapCatches: Rule['value'] }) => {
    if (!isNumberOrNumericString(trapCatches) || Number(trapCatches) <= 0)
      return translate.phrases.banyanApp('The number of trap catches must be greater than {{value}}', {
        value: 0,
      })

    return false
  },
  wind: ({ wind }: { wind: Rule['value'] }) => {
    if (!isNumberOrNumericString(wind))
      return translate.phrases.banyanApp('Please enter a valid number for wind')

    return false
  },
}
