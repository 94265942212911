import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { merge } from 'lodash'

export const mergeApiArgs = (...apiArgs: Omit<routes.Values.Request, 'dateFrom' | 'dateTo'>[]) => {
  return apiArgs.reduce((a: Omit<routes.Values.Request, 'dateFrom' | 'dateTo'>, b) => {
    const blockIds = [...new Set([...(a.blocks?.blockIds ?? [])].concat(b.blocks?.blockIds ?? []))] as [
      number,
      ...number[],
    ]

    const pointsLngLats = [...new Set([...(a.points?.lngLats ?? [])].concat(b.points?.lngLats ?? []))] as [
      string,
      ...string[],
    ]

    const regionIds = [...new Set([...(a.regions?.regionIds ?? []).concat(b.regions?.regionIds ?? [])])] as [
      string,
      ...string[],
    ]

    a = merge(a, b)

    if (a.blocks && blockIds.length) a.blocks.blockIds = blockIds

    if (a.points && pointsLngLats.length) a.points.lngLats = pointsLngLats

    if (a.regions && regionIds.length) a.regions.regionIds = regionIds

    return a
  }, {})
}
