import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconMessage = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 18 18')}>
      <path
        d="M0.00900078 1.8C0.00900078 0.81 0.810001 0 1.8 0H16.2C17.19 0 18 0.81 18 1.8V12.6C18 13.59 17.19 14.4 16.2 14.4H3.6L0 18L0.00900078 1.8Z"
        fill="currentColor"
      />
    </svg>
  )
}
