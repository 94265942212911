import { Group, Image, Text } from '@mantine/core'
import { useBleManager } from 'App/ServiceCenter/BluetoothLowEnergy/BleManager'
import { SensorCommandStatus, SensorTypeMapping } from 'App/ServiceCenter/BluetoothLowEnergy/constants'
import { DetectSdi as DetectSdiCommand } from 'App/ServiceCenter/BluetoothLowEnergy/models/LnrNode/commands'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import type { TActiveNode } from 'App/ServiceCenter/types'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { Footer } from '../../NodeInstallation/Footer/Footer'

export const SdiDeviceDetection: FC<{
  node: TActiveNode
  channel: number
  onConfirm: (source?: string) => void
  onCancel: () => void
}> = ({ node, channel, onConfirm, onCancel }) => {
  const allDeviceModels = fieldAssetStore.useSelector((s) => s.devices)
  const bleManager = useBleManager()
  const [reading, setReading] = useState(false)
  const expectedSensorType = node.devices?.sdi?.[channel]?.source //only for SDI

  const detectDevice = async () => {
    setReading(true)

    const result = await bleManager.connectedDevice?.read(DetectSdiCommand)

    setReading(false)

    const commandStatus = result?.commandStatus
    const sourceType = result?.type ? SensorTypeMapping.get(result?.type) : undefined

    if (commandStatus === SensorCommandStatus.BUSY) {
      AboveAllModal.open({
        title: translate.phrases.placeholder('Unable to Identify Device'),
        modalId: 'detectDevice',
        centered: true,
        withCloseButton: true,
        children: (
          <div>
            <Text>{translate.phrases.placeholder('Device is Busy')}</Text>
            <Button
              variant="tertiary"
              css={{ width: '100%' }}
              onClick={() => {
                AboveAllModal.close('detectDevice')
              }}
            >
              <Text>{translate.phrases.placeholder('Close')}</Text>
            </Button>
          </div>
        ),
      })
    } else if (commandStatus === SensorCommandStatus.NO_SENSOR_DETECTED) {
      AboveAllModal.open({
        title: translate.phrases.placeholder('Unable to Identify Device'),
        withCloseButton: true,
        modalId: 'detectDevice',
        centered: true,
        children: (
          <div>
            <Text>
              {translate.phrases.placeholder(
                'Make sure to only have one device plugged in and check the cable for any defects or breaks and try again.',
              )}
            </Text>
            <Button
              variant="tertiary"
              css={{ width: '100%' }}
              onClick={() => {
                AboveAllModal.close('detectDevice')
              }}
            >
              <Text>{translate.phrases.placeholder('Close')}</Text>
            </Button>
          </div>
        ),
      })
    } else if (
      commandStatus === SensorCommandStatus.OK &&
      (sourceType === expectedSensorType || expectedSensorType === undefined)
    ) {
      const deviceName = sourceType && allDeviceModels[sourceType].name

      AboveAllModal.open({
        title: translate.phrases.placeholder('Device Detected'),
        withCloseButton: true,
        modalId: 'detectDevice',
        centered: true,
        children: (
          <div>
            {deviceName}
            <Image
              src={`https://placehold.co/250x160?text=${deviceName}`}
              css={{ paddingTop: 10, paddingBottom: 10 }}
            />
            <Button
              variant="primary"
              css={{ width: '100%' }}
              onClick={() => {
                AboveAllModal.close('detectDevice')

                onConfirm(sourceType)
              }}
            >
              <Text>{translate.phrases.placeholder('Continue')}</Text>
            </Button>
          </div>
        ),
      })
    } else if (
      commandStatus === SensorCommandStatus.OK &&
      sourceType !== expectedSensorType &&
      expectedSensorType !== undefined
    ) {
      AboveAllModal.open({
        title: translate.phrases.placeholder('Wrong Device Detected'),
        withCloseButton: true,
        modalId: 'detectDevice',
        centered: true,
        children: (
          <>
            <Text css={{ marginBottom: 10 }}>
              {translate.phrases.placeholder(
                'This channel is planned for a {{expectedSensorType}}, but {{sourceType}} was detected. Make sure that you are plugging in the correct device or select a different channel from the Manage Devices page.',
                {
                  expectedSensorType: expectedSensorType && allDeviceModels[expectedSensorType].name,
                  sourceType: sourceType && allDeviceModels[sourceType].name,
                },
              )}
            </Text>
            <Group grow>
              <Button
                variant="tertiary"
                css={{ width: '100%' }}
                onClick={() => {
                  AboveAllModal.close('detectDevice')
                }}
              >
                <Text>{translate.phrases.placeholder('Cancel')}</Text>
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  AboveAllModal.close('detectDevice')

                  detectDevice()
                }}
              >
                <Text>{translate.phrases.placeholder('Retry')}</Text>
              </Button>
            </Group>
          </>
        ),
      })
    } else {
      AboveAllModal.open({
        title: translate.phrases.placeholder('Unknown error'),
        withCloseButton: true,
        modalId: 'detectDevice',
        centered: true,
        children: (
          <div>
            <Text>{translate.phrases.placeholder('Unknown error')}</Text>
            <Button
              variant="primary"
              onClick={() => {
                AboveAllModal.close('detectDevice')
              }}
            >
              <Text>{translate.phrases.placeholder('Ok')}</Text>
            </Button>
          </div>
        ),
      })
    }
  }

  return (
    <>
      <div
        css={{
          display: 'flex',
          margin: 10,
          flexDirection: 'column',
        }}
      >
        <Text
          css={{
            fontSize: '1.5em',
            fontWeight: 'bold',
          }}
        >
          {translate.phrases.placeholder('Plug In Device')}
        </Text>
        <Text>
          {translate.phrases.placeholder(
            'In order to begin configuring your device to this port and channel, make sure that there are no other devices plugged into the SDI port. When you are ready, plug the desired device in and press the button to begin the process.',
          )}
        </Text>
        <Image
          src="https://placehold.co/300x200?text=Placeholder"
          css={{ paddingTop: 10, paddingBottom: 10 }}
        />
        <Button
          variant="primary"
          disabled={bleManager.adapterStatus !== AdapterStatus.CONNECTED}
          onClick={detectDevice}
          loading={reading}
          css={{ width: '100%' }}
        >
          <Text>{translate.phrases.placeholder('Identify and Configure Device')}</Text>
        </Button>
      </div>
      <Footer
        previousButtonLabel={translate.phrases.placeholder('Previous')}
        showPreviousButton={true}
        disableNextButton={true} //disable the next button until the device is detected
        onNext={() => null}
        onPrevious={() => onCancel()}
      />
    </>
  )
}
