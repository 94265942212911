import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconRain = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 350 309')}>
      <g clipPath="url(#clip0_11_557)">
        <g clipPath="url(#clip1_11_557)">
          <path
            d="M291 107C290.15 107 289.32 107.09 288.47 107.13C293.294 87.7023 291.013 67.1901 282.039 49.2967C273.065 31.4034 257.989 17.3083 239.534 9.55632C221.078 1.80437 200.459 0.906499 181.399 7.02481C162.339 13.1431 146.095 25.8744 135.6 42.9199C127.108 37.8427 117.42 35.11 107.526 35.0009C97.6323 34.8918 87.8868 37.4103 79.2846 42.2991C70.6824 47.1879 63.5316 54.272 58.5624 62.828C53.5932 71.3841 50.9835 81.1056 50.9999 90.9999C51.0218 94.0432 51.2893 97.0797 51.7999 100.08C37.1521 102.897 24.0788 111.069 15.1298 123.003C6.18078 134.936 1.99767 149.776 3.39631 164.626C4.79495 179.477 11.675 193.274 22.6948 203.327C33.7145 213.379 48.0837 218.967 62.9999 219C64.3499 219 65.6699 218.89 66.9999 218.8V219H291C305.852 219 320.096 213.1 330.598 202.598C341.1 192.096 347 177.852 347 163C347 148.148 341.1 133.904 330.598 123.402C320.096 112.9 305.852 107 291 107Z"
            fill="url(#paint0_linear_11_557)"
            stroke="#CAD6E8"
            strokeWidth="6"
            strokeMiterlimit="10"
          />
        </g>
        <g clipPath="url(#clip2_11_557)">
          <path
            d="M119 255C116.878 255 114.843 254.157 113.343 252.657C111.843 251.157 111 249.122 111 247V207C111 204.878 111.843 202.843 113.343 201.343C114.843 199.843 116.878 199 119 199C121.122 199 123.157 199.843 124.657 201.343C126.157 202.843 127 204.878 127 207V247C127 249.122 126.157 251.157 124.657 252.657C123.157 254.157 121.122 255 119 255Z"
            fill="url(#paint1_linear_11_557)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
          <path
            d="M175 308C172.878 308 170.843 307.157 169.343 305.657C167.843 304.157 167 302.122 167 300V260C167 257.878 167.843 255.843 169.343 254.343C170.843 252.843 172.878 252 175 252C177.122 252 179.157 252.843 180.657 254.343C182.157 255.843 183 257.878 183 260V300C183 302.122 182.157 304.157 180.657 305.657C179.157 307.157 177.122 308 175 308Z"
            fill="url(#paint2_linear_11_557)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
          <path
            d="M231 273C228.878 273 226.843 272.157 225.343 270.657C223.843 269.157 223 267.122 223 265V225C223 222.878 223.843 220.843 225.343 219.343C226.843 217.843 228.878 217 231 217C233.122 217 235.157 217.843 236.657 219.343C238.157 220.843 239 222.878 239 225V265C239 267.122 238.157 269.157 236.657 270.657C235.157 272.157 233.122 273 231 273Z"
            fill="url(#paint3_linear_11_557)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_557"
          x1="99.4499"
          y1="30.6799"
          x2="232.64"
          y2="261.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F7FE" />
          <stop offset="0.45" stopColor="#F3F7FE" />
          <stop offset="1" stopColor="#DEEAFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_557"
          x1="106.338"
          y1="205.077"
          x2="131.661"
          y2="248.935"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_557"
          x1="162.336"
          y1="258.07"
          x2="187.66"
          y2="301.938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_557"
          x1="218.336"
          y1="223.067"
          x2="243.661"
          y2="266.936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <clipPath id="clip0_11_557">
          <rect width="350" height="309" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_557">
          <rect width="350" height="222" fill="white" />
        </clipPath>
        <clipPath id="clip2_11_557">
          <rect width="129" height="110" fill="white" transform="translate(110.5 198.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
