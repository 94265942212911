import type { CSSObject } from '@emotion/css'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import type { ReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { makeReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import type React from 'react'
import type { TBlockSCDComponentProps } from './BlockSCDComponent/BlockSCDComponent'
import { BlockSCDComponent } from './BlockSCDComponent/BlockSCDComponent'

type BLOCK_SCDS_META = {
  blockId: string
  bounds: google.maps.LatLngBounds
  scds: { googleLatLng: google.maps.LatLng; lngLat: string }[]
}

type SCD_LAT_LNG = string

// TODO: will eventually come from current values type
export type TBlockSCDsValueTypesToPropsDictionary = Record<
  string,
  { children: React.ReactNode; getContainerCSS: () => CSSObject; shouldAddDataSourceOverlay?: boolean }
>

export type TValueType = Record<SCD_LAT_LNG, TBlockSCDsValueTypesToPropsDictionary>

export type TOverlayType = Record<SCD_LAT_LNG, ReactComponentOverlayView<TBlockSCDComponentProps>>

export class BlockScdsValuesCache extends MapItemsCache<BLOCK_SCDS_META, TValueType, TOverlayType> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        return o.meta.scds.reduce((acc: TOverlayType, { googleLatLng }) => {
          acc[googleLatLng.toUrlValue()] = makeReactComponentOverlayView({
            component: BlockSCDComponent,
            latLng: googleLatLng,
            maps: o.maps,
            map: o.map,
          })

          return acc
        }, {})
      },
      renderOverlay: (o) => {
        o.meta.scds.forEach(({ googleLatLng }) => {
          const latLngStr = googleLatLng.toUrlValue()
          const scdValue = o.value[latLngStr]
          const scdOverlay = o.overlay[latLngStr]

          if (scdOverlay) {
            scdOverlay.render({ blockId: Number(o.meta.blockId), valueTypesToPropsDictionary: scdValue })
          }
        })
      },
      hideOverlay: (o) => {
        Object.values(o.overlay).forEach((o) => o.setMap(null))
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
