import { translate } from 'i18n/i18n'
import { AlertSection } from '../../../components/AlertSection'

export const AutomatedScheduling = ({ rulesHaveForecast }: { rulesHaveForecast: boolean }) => {
  return (
    <AlertSection title={translate.phrases.banyanApp('Frequency')}>
      {rulesHaveForecast ? (
        <span>
          {translate.phrases.banyanApp(
            'This alert will only send for the respective block(s) when the current degree days have not met the threshold but the forecast degree days are expected to be met within 1 to 7 days',
          )}
        </span>
      ) : (
        <span>
          {translate.phrases.banyanApp(
            'This alert will only send once for the respective block(s) per year, when the current degree days meets or exceed the threshold',
          )}
        </span>
      )}
    </AlertSection>
  )
}
