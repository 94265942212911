import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import { HelpGuideSection } from '../types'

export function getHelpGuideSectionTitle(section: HelpGuideSection): string {
  switch (section) {
    case HelpGuideSection.QA:
      return translate.phrases.placeholder('QA')

    case HelpGuideSection.ASSEMBLY:
      return translate.phrases.placeholder('Assembly')

    case HelpGuideSection.INSTALLATION:
      return translate.phrases.placeholder('Installation')

    case HelpGuideSection.MAINTENANCE:
      return translate.phrases.placeholder('Maintenance')

    default: {
      Sentry.captureException(`Help guide section "${section}" has not been implemented`)

      return section
    }
  }
}
