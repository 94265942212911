import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconPT = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 18')}>
      <path
        d="M19.2968 5.11066L18.0032 7.19686C18.627 8.53098 18.9324 10.0102 18.8921 11.502C18.8517 12.9938 18.4668 14.4516 17.7718 15.7446H3.19505C2.2918 14.0645 1.91753 12.117 2.12771 10.1909C2.33789 8.26478 3.12133 6.46255 4.3619 5.05138C5.60247 3.64021 7.23412 2.69523 9.01496 2.35654C10.7958 2.01784 12.631 2.30347 14.2486 3.17106L16.1942 1.78402C14.2133 0.422034 11.8636 -0.188276 9.52099 0.0507133C7.17837 0.289703 4.97817 1.36418 3.27234 3.10229C1.56651 4.84039 0.453612 7.14168 0.111653 9.63807C-0.230306 12.1344 0.218437 14.6817 1.3861 16.8723C1.56964 17.2132 1.83317 17.4966 2.15052 17.6944C2.46787 17.8923 2.82797 17.9976 3.19505 18H17.7613C18.1319 18.0016 18.4964 17.8981 18.8177 17.7001C19.1391 17.502 19.4059 17.2165 19.5913 16.8723C20.5603 15.0724 21.0466 13.0205 20.9965 10.9429C20.9464 8.86541 20.3618 6.84313 19.3073 5.09938L19.2968 5.11066ZM9.00051 12.824C9.19586 13.0336 9.42785 13.2 9.6832 13.3135C9.93855 13.427 10.2123 13.4854 10.4887 13.4854C10.7651 13.4854 11.0388 13.427 11.2942 13.3135C11.5495 13.2 11.7815 13.0336 11.9769 12.824L17.9296 3.25L9.00051 9.63264C8.80494 9.8421 8.64979 10.0908 8.54394 10.3646C8.43809 10.6384 8.3836 10.9319 8.3836 11.2283C8.3836 11.5247 8.43809 11.8182 8.54394 12.092C8.64979 12.3658 8.80494 12.6145 9.00051 12.824Z"
        fill="currentColor"
      />
    </svg>
  )
}
