import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconSortSelector = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 24 24')}
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8 9l4 -4l4 4"></path>
      <path d="M16 15l-4 4l-4 -4"></path>
    </svg>
  )
}
