import { isNil } from '@semios/app-platform-common'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { DefaultHeatmapRow } from '../DefaultHeatmapRow/DefaultHeatmapRow'

export const WindMachineHeatmapRow = () => {
  const valueType = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.wind_machine?.valueType,
  )

  let customConverterToUse

  if (valueType === 'windMachine_rpm') {
    customConverterToUse = unitConverter.windMachineRPM
  } else if (valueType === 'windMachine_batteryVoltage_v') {
    customConverterToUse = unitConverter.windMachineVoltage
  } else if (valueType === 'windMachine_temperature_c') {
    customConverterToUse = unitConverter.windMachineTemperature
  }

  return (
    <>
      {!isNil(customConverterToUse) && (
        <DefaultHeatmapRow
          unitConverterFunctionForRendering={customConverterToUse}
          unitConverterFunctionForSaving={customConverterToUse}
        />
      )}
    </>
  )
}
