import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortPestSections } from 'App/Map/PanelDetails/_utils/sortPestSections'
import type { StackedChartPestSection, StackedChartSection } from 'components/StackedChart/types'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import * as almondBloom from '../by-domain/almondBloom/almondBloom'
import * as alternaria from '../by-domain/alternaria/alternaria'
import * as beeHours from '../by-domain/beeHours/beeHours'
import * as chill from '../by-domain/chill/chill'
import * as conditions from '../by-domain/conditions/conditions'
import * as dewPoint from '../by-domain/dewPoint/dewPoint'
import * as evapotranspiration from '../by-domain/evapotranspiration/evapotranspiration'
import * as fruitGrowth from '../by-domain/fruitGrowth/fruitGrowth'
import * as humidity from '../by-domain/humidity/humidity'
import * as insectDegreeDays from '../by-domain/insectDegreeDays/insectDegreeDays'
import * as insectLarvalTrend from '../by-domain/insectLarvalTrend/insectLarvalTrend'
import * as insectTrapCatches from '../by-domain/insectTrapCatches/insectTrapCatches'
import * as irrigationActivity from '../by-domain/irrigationActivity/irrigationActivity'
import * as plantStress from '../by-domain/plantStress/plantStress'
import * as precipitation from '../by-domain/precipitation/precipitation'
import * as soil from '../by-domain/soil/soil'
import * as sprayConditions from '../by-domain/sprayConditions/sprayConditions'
import * as temperature from '../by-domain/temperature/temperature'
import * as walnutBlight from '../by-domain/walnutBlight/walnutBlight'
import * as wetBulb from '../by-domain/wetBulb/wetBulb'
import * as wind from '../by-domain/wind/wind'
import * as windMachine from '../by-domain/windMachine/windMachine'
import { applyColorPalette } from './_utils/applyColorPalette'

export const createContent = ({
  compareSeasonsData,
  data,
  selectedValueGroups,
  selectedFieldAssets,
  updateData,
}: {
  compareSeasonsData: routes.Values.Response
  data: routes.Values.Response
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  updateData: (pathToSet: string, dataToSet: unknown) => void
}): StackedChartSection[] => {
  const content: StackedChartSection[] = []

  /* ENVIRONMENTAL */
  if (!!selectedValueGroups.conditions) content.push(conditions.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.dew_point) content.push(dewPoint.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.humidity) content.push(humidity.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.precipitation) content.push(precipitation.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.air_temperature) content.push(temperature.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.wet_bulb) content.push(wetBulb.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.wind) content.push(wind.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.bee_hours) content.push(beeHours.content({ data, compareSeasonsData }))

  if (!!selectedValueGroups.almond_bloom) content.push(almondBloom.content({ data, compareSeasonsData }))

  /* CROP DEVELOPMENT */
  if (!!selectedValueGroups.chill) content.push(chill.content({ data, compareSeasonsData }))

  /* WATER */
  if (!!selectedValueGroups.evapotranspiration)
    content.push(evapotranspiration.content({ data, compareSeasonsData }))

  // TODO: when there are no soil stations on the property, should we display some kind of no data message like we do for summary grid table?
  if (!!selectedValueGroups.soil) content.push(...soil.content({ data, compareSeasonsData }))

  /* SPRAYS */
  if (!!selectedValueGroups.spray_conditions) content.push(sprayConditions.content({ data }))

  /* FRUIT GROWTH */
  if (!!selectedValueGroups.fruit_growth) content.push(fruitGrowth.content({ data, compareSeasonsData }))

  /* PLANT STRESS */
  if (!!selectedValueGroups.plant_stress)
    content.push(...plantStress.content({ data, compareSeasonsData, updateData }))

  /* ALTERNARIA */
  if (!!selectedValueGroups.alternaria)
    content.push(alternaria.content({ data, compareSeasonsData, updateData }))

  /* WALNUT BLIGHT*/
  if (!!selectedValueGroups.walnut_blight) content.push(walnutBlight.content({ data, compareSeasonsData }))

  /* Pests */
  const pestContent: StackedChartPestSection[] = []

  if (
    Object.entries(selectedValueGroups).some(
      ([valueGroup, isActive]) => !!isActive && valueGroup.startsWith('degree_days_insect_id_'),
    )
  )
    pestContent.push(...insectDegreeDays.content({ data, selectedValueGroups, compareSeasonsData }))

  if (
    Object.entries(selectedValueGroups).some(
      ([valueGroup, isActive]) => !!isActive && valueGroup.startsWith('trap_catches_insect_id_'),
    )
  )
    pestContent.push(
      ...insectTrapCatches.content({
        data,
        selectedFieldAssets,
        selectedValueGroups,
        compareSeasonsData,
      }),
    )

  if (
    Object.entries(selectedValueGroups).some(
      ([valueGroup, isActive]) => !!isActive && valueGroup.startsWith('irrigation_activity'),
    )
  ) {
    content.push(...irrigationActivity.content({ data, selectedFieldAssets }))
  }

  if (
    Object.entries(selectedValueGroups).some(
      ([valueGroup, isActive]) => !!isActive && valueGroup.startsWith('larval_trend_insect_id'),
    )
  )
    pestContent.push(...insectLarvalTrend.content({ data, selectedValueGroups, compareSeasonsData }))

  // it's nice to keep pest information grouped together by pest
  pestContent.sort(sortPestSections)

  content.push(...pestContent)

  // ** EQUIPMENT **

  if (!!selectedValueGroups.wind_machine) content.push(...windMachine.content({ data }))

  return applyColorPalette(content).sort((a, b) => {
    // sort here will put items with no data down at the bottom
    return Number(!!a.defaultCollapsed) - Number(!!b.defaultCollapsed)
  })
}
