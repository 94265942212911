import { roundToDecimalPlaces } from '@semios/app-platform-common'

export const getAverageSeriesData = (
  seriesDictionary: Record<number, number[]>,
  decimalPlacesToUse: number,
) => {
  return Object.entries(seriesDictionary).map(([epoch, values]) => {
    const averageForThisEpoch = roundToDecimalPlaces(
      values.reduce((a, b) => a + b, 0) / values.length,
      decimalPlacesToUse,
    )

    return [+epoch, averageForThisEpoch]
  })
}
