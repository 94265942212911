import React from 'react'
import { IntercomProvider as Provider } from 'react-use-intercom'
import { SharedSettings } from 'settings/SharedSettings'
import { intercomStore } from 'stores/intercomStore'
import { IntercomMessenger } from './IntercomMessenger'

export const IntercomProvider = (props: { children: React.ReactNode }) => {
  return (
    <Provider
      appId={SharedSettings.INTERCOM_APP_ID}
      onUnreadCountChange={(unreadMessagesCount) => {
        intercomStore.setState((s) => ({ ...s, unreadMessagesCount }))
      }}
    >
      <IntercomMessenger />
      {props.children}
    </Provider>
  )
}
