import { css } from '@emotion/css'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMantineTheme } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { InputPropsType } from 'App/Authentication/AuthenticationForm'
import { WarningIcon } from 'App/Authentication/AuthenticationForm/WarningIcon'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useState } from 'react'
import { apiFetch } from 'utils/apiFetch'
import { setToken } from 'utils/setToken'
import { z } from 'zod'
import { AuthenticationFormWithoutSemiosLogo } from './AuthenticationFormWithoutSemiosLogo'

const getValidationSchema = () =>
  z
    .object({
      oldPassword: z.string().min(1, {
        message: translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Current Password'),
        }),
      }),
      newPassword: z.string().min(6, {
        message: translate.phrases.validation('{{label}} must be at least {{count}} characters', {
          label: translate.phrases.banyanApp('New Password'),
          count: 6,
        }),
      }),
      confirmNewPassword: z.string().min(1, {
        message: translate.phrases.validation('{{label}} is required', {
          label: translate.phrases.banyanApp('Password'),
        }),
      }),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
      message: translate.phrases.banyanApp('Passwords do not match'),
      path: ['confirmNewPassword'],
    })

type MessageStateType = 'default' | 'success' | 'error'

export const ChangePassword: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [messageState, setMessageState] = useState<MessageStateType>('default')
  const theme = useMantineTheme()

  const inputProps: InputPropsType[] = [
    {
      placeholder: translate.phrases.banyanApp('Current Password'),
      key: 'oldPassword',
      initialValue: '',
      isPasswordInput: true,
    },
    {
      placeholder: translate.phrases.banyanApp('New Password'),
      key: 'newPassword',
      initialValue: '',
      isPasswordInput: true,
    },
    {
      placeholder: translate.phrases.banyanApp('Confirm New Password'),
      key: 'confirmNewPassword',
      initialValue: '',
      isPasswordInput: true,
    },
  ]

  const handleSubmit = async (values: { [k: string]: string }) => {
    setLoading(true)

    try {
      const response = await apiFetch<routes.ChangePassword.Request, routes.ChangePassword.Response>({
        url: routes.ChangePassword.path,
        body: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      })

      const token = response['x-token']

      if (token) setToken(token)

      setMessageState('success')
    } catch (error) {
      setMessageState('error')
    }

    setLoading(false)
  }

  const formHeaderRenderer = () => {
    switch (messageState) {
      case 'default':
        return null

      case 'error':
        return (
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-top: 12px;
              padding: 14px 18px 0px;
            `}
          >
            <div
              className={css`
                padding-right: 14px;
              `}
            >
              <WarningIcon size={17} />
            </div>
            <div
              className={css`
                font-size: 14px;
                display: flex;
                align-items: center;
                font-weight: 700;
              `}
            >
              <div>
                {translate.phrases.validation('{{label}} is incorrect', {
                  label: translate.phrases.banyanApp('Current Password'),
                })}
              </div>
            </div>
          </div>
        )

      case 'success':
        return null

      default:
        return null
    }
  }

  return (
    <div>
      {messageState === 'success' ? (
        <div
          className={css`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-top: 12px;
            padding: 14px 18px 0px;
          `}
        >
          <div
            className={css`
              padding-right: 14px;
            `}
          >
            <FontAwesomeIcon icon={faCheckCircle} color={theme.colors.green[0]} />
          </div>
          <div
            className={css`
              font-size: 14px;
              display: flex;
              align-items: center;
            `}
          >
            <div>{translate.phrases.banyanApp('Password successfully updated.')}</div>
          </div>
        </div>
      ) : (
        <AuthenticationFormWithoutSemiosLogo
          header={formHeaderRenderer()}
          inputProps={inputProps}
          validationSchema={getValidationSchema()}
          buttonText={translate.phrases.banyanApp('Change Password')}
          onSubmit={(values: { [k: string]: string }) => {
            handleSubmit(values)
          }}
          submitLoading={loading}
        />
      )}
    </div>
  )
}
