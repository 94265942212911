import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconProperty = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 18')}>
      <path
        d="M18.9652 0.337659C18.7707 -0.0964103 18.2151 -0.113773 17.9824 0.295988C16.906 2.16769 14.9787 3.33447 12.7771 3.33447H9.99905C6.31814 3.33447 3.33175 6.32087 3.33175 10.0018C3.33175 10.2448 3.35953 10.4775 3.38384 10.7136C5.59933 9.12669 8.79755 7.77934 13.3327 7.77934C13.6383 7.77934 13.8883 8.02936 13.8883 8.33495C13.8883 8.64053 13.6383 8.89055 13.3327 8.89055C4.6027 8.89055 0.900964 14.2418 0.0814413 16.2524C-0.147747 16.8184 0.123112 17.4643 0.689138 17.6969C1.25864 17.9331 1.90453 17.6587 2.14066 17.0962C2.19275 16.9712 2.86643 15.4328 4.63743 13.9501C5.76254 15.4745 7.90163 16.9295 10.7109 16.6309C16.1628 16.235 20 11.3456 20 5.35897C20 3.61575 19.625 1.81002 18.9652 0.337659Z"
        fill="currentColor"
      />
    </svg>
  )
}
