import type { Rule } from 'App/Map/UserSettingsMenu/Shared/Alerts/settings/alertSettings'
import type { Alert, Contact, Group, Report } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { fetchApiCustomer } from './fetchApiCustomer'

type TLoadAppStartupDataGraphQL = {
  includeContactsAndGroups?: boolean
  includeAlerts?: boolean
  includeReports?: boolean
}

type TLoadAppStartupDataGraphQLResponse = {
  data: {
    user: {
      contacts?: Contact[]
      groups?: Group[]
      alerts?: Alert[]
      reports?: Report
    }
  }
}

export type TWeatherForecastAlertResponse = {
  blocks: string[]
  contacts: Contact[]
  createdAt: string
  endDate: string
  frequencyMinutes: number | null
  groups: Group[]
  id: number
  name: string
  notifyOwnerByEmail: boolean
  notifyOwnerBySMS: boolean
  properties: string[]
  rules: Rule[]
  startDate: string
  stations: string[]
  updatedAt: string
}[]

export const getStartDateAndEndDate = (startDate: string | null, endDate: string | null) => {
  const currentYear = new Date().getFullYear()
  const startDateToReturn = startDate ? `${currentYear}-${startDate}` : ''

  let endDateToReturn = ''

  if (startDate && endDate) {
    const endDateToCompare = new Date(`${currentYear}-${endDate}`)
    const startDateToCompare = new Date(`${currentYear}-${startDate}`)

    if (startDateToCompare < endDateToCompare) {
      endDateToReturn = `${currentYear}-${endDate}`
    } else {
      endDateToReturn = `${currentYear + 1}-${endDate}`
    }
  }

  return { startDate: startDateToReturn, endDate: endDateToReturn }
}

export const getShapedWeatherForecastAlerts = (weatherForecastAlerts: TWeatherForecastAlertResponse) => {
  const shapedWeatherForecastAlerts = weatherForecastAlerts.map((d) => {
    const { startDate, endDate } = getStartDateAndEndDate(d.startDate, d.endDate)

    return {
      ...d,
      id: String(d.id),
      startDate,
      endDate,
      blocks: d.blocks.map((b) => ({ id: b })),
      stations: d.stations.map((s) => ({ geom: s })),
      properties: d.properties.map((p) => ({ id: p })).filter((p) => p?.id),
      type: {
        id: 'weatherForecastAlert',
      },
    }
  })

  return shapedWeatherForecastAlerts
}

/**
 * The following fields we would want to use our existing api-customer graphql endpoints:
 * - contacts and groups
 * - alerts
 * - reports
 *
 * @param {object} callsToInclude - if not passed, all calls will be included, otherwise a subset of calls will be made
 */
export const loadAppStartupApiCustomer = async (
  callsToInclude: TLoadAppStartupDataGraphQL = {
    includeReports: true,
    includeContactsAndGroups: true,
    includeAlerts: true,
  },
) => {
  try {
    const { includeReports, includeContactsAndGroups, includeAlerts } = callsToInclude

    const data = (await fetchApiCustomer({
      body: {
        query: `query UserAppStartup(
            $contactsAndGroups: Boolean = false
            $alerts: Boolean = false
            $reports: Boolean = false
          ) {
            user {
              # contacts and groups
              contacts @include(if: $contactsAndGroups) {
                id
                name
                email
                sms
                language
                alertsContactMethods
                reportsContactMethods
                groups {
                  id
                  name
                }
              }
      
              groups @include(if: $contactsAndGroups) {
                id
                name
                contacts {
                  id
                  name
                  alertsContactMethods
                  reportsContactMethods
                  email
                  sms
                }
              }
  
              alerts @include(if: $alerts) {
                id
                name
                type {
                  id
                }
                rules {
                  id
                  key
                  value
                  operator
                  unit
                  options
                }
                properties {
                  id
                }
                blocks {
                  id
                }
                stations {
                  geom
                }
                contacts {
                  id
                  name
                  sms
                  email
                  alertsContactMethods
                }
                groups {
                  id
                  name
                  contacts {
                    id
                    name
                    sms
                    email
                  }
                  alertsContactMethods
                }
                startDate
                endDate
                createdAt
                updatedAt
                deletedAt
                insect {
                  id
                }
                notifyOwnerByEmail
                notifyOwnerBySMS
                snoozeUntil
                snoozeAllUntil
                sleepMinutes
                frequencyMinutes
                repeatAnnually
              }
              reports @include(if: $reports) {
                weeklyTrapCatches {
                  id
                  name
                  notifyOwnerByEmail
                  contacts {
                    id
                    name
                    email
                    reportsContactMethods
                  }
                  groups {
                    id
                    name
                    reportsContactMethods
                    contacts {
                      id
                      name
                      email
                    }
                  }
                  rules {
                    dayOfWeek
                    properties {
                      id
                    }
                    startDate
                    endDate
                    pests {
                      insect {
                        id
                        name
                      }
                      startDate
                      endDate
                    }
                  }
                }
                weeklyGeneral {
        id
        name
        notifyOwnerByEmail
        contacts {
          id
          name
          email
          reportsContactMethods
        }
        groups {
          id
          name
          reportsContactMethods
          contacts {
            id
            name
            email
          }
        }
        rules {
          dayOfWeek
          properties {
            id
          }
          weather {
            startDate
            endDate
          }
          fireBlight {
            startDate
            endDate
          }
          pollination {
            startDate
            endDate
          }
          growingDegreeDays {
            startDate
            endDate
          }
          evapotranspiration {
            startDate
            endDate
          }
          irrigationActivity {
            startDate
            endDate
          }
          irrigationPlanner {
            startDate
            endDate
          }
          soilMoisture {
            startDate
            endDate
          }
          chill {
            startDate
            endDate
          }
          scout {
            startDate
            endDate
          }
          pests {
            insect {
              id
              name
            }
            startDate
            endDate
            trapCatches
            degreeDays
          }
        }
      }
      }

            }
            
          }`,
        variables: {
          contactsAndGroups: includeContactsAndGroups,
          alerts: includeAlerts,
          reports: includeReports,
        },
      },
    })) as TLoadAppStartupDataGraphQLResponse

    let mergedAlerts: Alert[] | null = null

    const { contacts, groups, alerts, reports } = data.data.user

    if (includeAlerts) {
      const weatherForecastAlerts: TWeatherForecastAlertResponse = await fetchApiCustomer({
        body: {},
        restApiUrl: 'weather-forecast-alert-get-all',
      })

      const shapedWeatherForecastAlerts = getShapedWeatherForecastAlerts(weatherForecastAlerts)

      const shapedAlerts = (alerts || []).map((alert) => ({
        ...alert,
        properties: alert.properties.filter((p) => p?.id),
      }))

      mergedAlerts = [...(shapedAlerts || []), ...shapedWeatherForecastAlerts].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ) as Alert[]
    }

    userDetailsStore.setState((s) => {
      return {
        ...s,
        contacts: contacts ?? s.contacts,
        groups: groups ?? s.groups,
        alerts: mergedAlerts ?? s.alerts,
        reports: reports ?? s.reports,
      }
    })
  } catch (error) {}
}
