import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconGear = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 21')}>
      <path
        d="M17.6462 11.5281C17.689 11.2 17.7104 10.8609 17.7104 10.5C17.7104 10.15 17.689 9.8 17.6355 9.47188L19.8094 7.74375C20.0021 7.59062 20.0557 7.29531 19.9379 7.07656L17.8818 3.44531C17.7533 3.20469 17.4855 3.12812 17.2499 3.20469L14.6905 4.25469C14.1551 3.83906 13.5875 3.48906 12.9557 3.22656L12.5701 0.448437C12.5273 0.185937 12.3131 0 12.0561 0H7.94388C7.68687 0 7.4834 0.185937 7.44056 0.448437L7.05504 3.22656C6.42322 3.48906 5.84493 3.85 5.3202 4.25469L2.76076 3.20469C2.52517 3.11719 2.25744 3.20469 2.12894 3.44531L0.08353 7.07656C-0.0449771 7.30625 -0.00214154 7.59062 0.212037 7.74375L2.38595 9.47188C2.33241 9.8 2.28957 10.1609 2.28957 10.5C2.28957 10.8391 2.31099 11.2 2.36453 11.5281L0.190619 13.2563C-0.00214152 13.4094 -0.055686 13.7047 0.0621122 13.9234L2.11823 17.5547C2.24673 17.7953 2.51446 17.8719 2.75005 17.7953L5.30949 16.7453C5.84493 17.1609 6.41251 17.5109 7.04433 17.7734L7.42986 20.5516C7.4834 20.8141 7.68687 21 7.94388 21H12.0561C12.3131 21 12.5273 20.8141 12.5594 20.5516L12.945 17.7734C13.5768 17.5109 14.1551 17.1609 14.6798 16.7453L17.2392 17.7953C17.4748 17.8828 17.7426 17.7953 17.8711 17.5547L19.9272 13.9234C20.0557 13.6828 20.0021 13.4094 19.7987 13.2563L17.6462 11.5281ZM10 14.4375C7.87963 14.4375 6.14478 12.6656 6.14478 10.5C6.14478 8.33438 7.87963 6.5625 10 6.5625C12.1204 6.5625 13.8552 8.33438 13.8552 10.5C13.8552 12.6656 12.1204 14.4375 10 14.4375Z"
        fill="currentColor"
      />
    </svg>
  )
}
