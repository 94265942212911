import { useDisclosure } from '@mantine/hooks'
import { sortByKey } from '@semios/app-platform-common'
import { ContactsAndGroups } from 'App/Map/UserSettingsMenu/Shared/ContactsAndGroups/ContactsAndGroups'
import { Button } from 'components/Button/Button'
import { IconContact } from 'components/icons/IconContact'
import { IconGroups } from 'components/icons/IconGroups'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import type { AlertContactMethod, Contact, Group } from 'stores/userDetailsStore'
import { AlertSection } from '../../../components/AlertSection'
import { ErrorTextWrapper } from '../../../components/ErrorTextWrapper'
import { helpTextErrorHelper } from '../_utils/helpTextErrorHelper'
import { ContactList } from './ContactList'

const CONTACT_KEY = 'CONTACT'
const GROUP_KEY = 'GROUP'

const contactOptionsGenerator = (contacts: Contact[]) => {
  return contacts
    .map((c) => ({
      value: c.id,
      label: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div css={{ fontSize: 18, marginRight: 5, display: 'flex', alignItems: 'center' }}>
            <IconContact />
          </div>
          {c.name}
        </div>
      ),
      id: `${c.id}-${CONTACT_KEY}`,
      name: c.name,
    }))
    .sort(sortByKey('name'))
}

const groupOptionsGenerator = (groups: Group[]) => {
  return groups
    .map((g) => ({
      value: g.id,
      label: (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div css={{ fontSize: 18, marginRight: 5, display: 'flex', alignItems: 'center' }}>
            <IconGroups />
          </div>
          {g.name}
        </div>
      ),
      id: `${g.id}-${GROUP_KEY}`,
      name: g.name,
    }))
    .sort(sortByKey('name'))
}

export const Contacts = ({
  allContacts,
  allContactGroups,
  contacts,
  contactsIsValid,
  groups,
  notifyOwnerByEmail,
  notifyOwnerBySMS,
  setContacts,
  setContactsIsValid,
  setGroups,
  setNotifyOwnerByEmail,
  setNotifyOwnerBySMS,
}: {
  allContacts: Contact[]
  allContactGroups: Group[]
  contacts: Contact[]
  contactsIsValid: boolean
  groups: Group[]
  notifyOwnerByEmail: boolean
  notifyOwnerBySMS: boolean
  setContacts: Dispatch<SetStateAction<Contact[]>>
  setContactsIsValid: Dispatch<SetStateAction<boolean>>
  setGroups: Dispatch<SetStateAction<Group[]>>
  setNotifyOwnerByEmail: Dispatch<SetStateAction<boolean>>
  setNotifyOwnerBySMS: Dispatch<SetStateAction<boolean>>
}) => {
  const [opened, { open, close }] = useDisclosure(false)

  const validateStatusError =
    contacts.some((c) => !(c.alertsContactMethods || []).length) &&
    translate.phrases.banyanApp('Contacts must have at least one alerts delivery method selected')

  useEffect(() => {
    setContactsIsValid(!validateStatusError)
  }, [validateStatusError])

  const multiSelectOptions = () => {
    const contactOptions = contactOptionsGenerator(allContacts)
    const groupOptions = groupOptionsGenerator(allContactGroups)

    return [...contactOptions, ...groupOptions]
  }

  const handleOnchange = (selectedValues: TOptions[]) => {
    const sContacts = selectedValues
      .filter((option) => option.id?.includes(CONTACT_KEY))
      .map((option) => option.value)

    const newContactsToUse = sContacts.flatMap((id) => {
      const contactInExistingContacts = contacts.find((c) => c.id === id)

      if (!!contactInExistingContacts) return contactInExistingContacts

      const contactInGlobalContacts = allContacts.find((c) => c.id === id)

      if (!!contactInGlobalContacts) return contactInGlobalContacts

      return []
    })

    setContacts(newContactsToUse)

    const sGroups = selectedValues
      .filter((option) => option.id?.includes(GROUP_KEY))
      .map((option) => option.value)

    const newGroupsToUse = sGroups.flatMap((id) => {
      const groupInExistingGroups = groups.find((g) => g.id === id)

      if (!!groupInExistingGroups) return groupInExistingGroups

      const groupInGlobalGroups = allContactGroups.find((g) => g.id === id)

      if (!!groupInGlobalGroups) {
        const defaultAlertsContactMethods = ['email', 'sms'] as AlertContactMethod[]

        return { ...groupInGlobalGroups, alertsContactMethods: defaultAlertsContactMethods }
      }

      return []
    })

    setGroups(newGroupsToUse)
  }

  const selectedContacts = contactOptionsGenerator(contacts)
  const selectedGroups = groupOptionsGenerator(groups)
  const selectedData = [...selectedContacts, ...selectedGroups]

  return (
    <>
      <AlertSection title={translate.phrases.banyanApp('Contacts')}>
        <div
          css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '5px 0 20px' }}
        >
          <Button variant="link" onClick={open}>
            {translate.phrases.banyanApp('Manage Contacts And Groups')}
          </Button>
        </div>
        <MultiSelect
          data={multiSelectOptions()}
          selectedData={selectedData}
          placeholder=""
          onChange={handleOnchange}
        />
        <ContactList
          contacts={contacts}
          groups={groups}
          setContacts={setContacts}
          setGroups={setGroups}
          notifyOwnerByEmail={notifyOwnerByEmail}
          notifyOwnerBySMS={notifyOwnerBySMS}
          setNotifyOwnerByEmail={setNotifyOwnerByEmail}
          setNotifyOwnerBySMS={setNotifyOwnerBySMS}
          contactsIsValid={contactsIsValid}
        />
        <ErrorTextWrapper>{helpTextErrorHelper(validateStatusError)}</ErrorTextWrapper>
      </AlertSection>
      <ModalDrawer
        opened={opened}
        onClose={close}
        zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 250}
        size="45%"
        title={translate.phrases.banyanApp('Contacts')}
      >
        <ContactsAndGroups />
      </ModalDrawer>
    </>
  )
}
