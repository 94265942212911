import { useState } from 'react'
import { ForgotPassword } from './ForgotPassword'
import { Login } from './Login'
import { ResetPassword } from './ResetPassword'

export const Authentication = () => {
  const [currentFormType, setCurrentFormType] = useState('login')

  const renderForm = () => {
    switch (currentFormType) {
      case 'login':
        return <Login setCurrentFormType={setCurrentFormType} />

      case 'forgotPassword':
        return <ForgotPassword setCurrentFormType={setCurrentFormType} />

      case 'resetPassword':
        return <ResetPassword />

      default:
        return null
    }
  }

  return renderForm()
}
