import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconWarning = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <path
        d="M11.3916 5.33594L11.0767 12.6235H9.06982L8.75488 5.33594H11.3916ZM8.68896 14.894C8.68896 14.5425 8.81592 14.2495 9.06982 14.0151C9.32373 13.7759 9.65332 13.6562 10.0586 13.6562C10.4639 13.6562 10.791 13.7759 11.04 14.0151C11.2939 14.2495 11.4209 14.5425 11.4209 14.894C11.4209 15.2456 11.2939 15.541 11.04 15.7803C10.791 16.0146 10.4639 16.1318 10.0586 16.1318C9.65332 16.1318 9.32373 16.0146 9.06982 15.7803C8.81592 15.541 8.68896 15.2456 8.68896 14.894Z"
        fill="white"
      />
    </svg>
  )
}
