import { useDisclosure } from '@mantine/hooks'
import { openStackemChartsModal } from 'App/Map/CurrentValuesMap/components/SelectedMapCard/components/_utils/openStackemChartsModal'
import { isNil, isNumber } from 'lodash'
import { useEffect, useRef } from 'react'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapStore } from 'stores/mapStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { setSelectedFieldAsset } from 'utils/setSelectedFieldAsset/setSelectedFieldAsset'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { useSetMapOverlayZIndex } from 'utils/useSetMapOverlayZIndex'
import { Label } from '../../Label/Label'
import type {
  IRRIGATION_ZONE_VALUE_META,
  TIrrigationZonesValueTypesToPropsDictionary,
} from '../IrrigationZoneValuesCache'
import { InProgressEventWave } from './InProgressEventWave'

export type TIrrigationZoneBadgeComponentProps = {
  meta: IRRIGATION_ZONE_VALUE_META
  valueTypesToPropsDictionary: TIrrigationZonesValueTypesToPropsDictionary
}

export const IrrigationZoneValueBadgeComponent = (props: TIrrigationZoneBadgeComponentProps) => {
  const [isHovered, { close, open }] = useDisclosure(false)
  const primaryValueGroup = usePrimaryValueGroup()

  const { showMapBadgeNames, valueType } = mapControlsStore.useSelector((s) => ({
    showMapBadgeNames: s.showMapBadgeNames,
    valueType: !primaryValueGroup
      ? null
      : s.mapVisualValueGroup.IRRIGATION_ZONE[primaryValueGroup]?.valueType,
  }))

  const zoom = mapStore.useSelector((s) => s.zoom)
  const selectedZone = selectedFieldAssetsStore.useSelector((s) => s.irrigationZoneEmitter)
  const ref = useRef<HTMLDivElement>(null)
  const zoneEmitterKey = props.meta.irrigationZoneEmitterId
  const isSelected = zoneEmitterKey === selectedZone
  const height = 30

  useSetMapOverlayZIndex({ ref, mItem: 'M_IrrigationZone', isSelected, isHighlighted: false, isHovered })

  useEffect(() => {
    close()
  }, [zoom])

  if (isNil(valueType)) return null

  const valueTypeForUse = valueType as string
  const valueTypeForUseData = props.valueTypesToPropsDictionary?.[valueTypeForUse]

  let value = valueTypeForUseData?.children

  const progressText = valueTypeForUseData?.progressText
  const progressPercent = valueTypeForUseData?.progressPercent
  const containerStyles = props?.valueTypesToPropsDictionary?.[valueType]?.getContainerCSS?.()

  if (progressPercent) containerStyles.backgroundColor = 'transparent'

  return (
    <div
      ref={ref}
      onClick={() => {
        setSelectedFieldAsset({ irrigationZoneEmitter: props.meta.irrigationZoneEmitterId })

        if (HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD) {
          openStackemChartsModal()
        }
      }}
      onMouseEnter={open}
      onMouseLeave={close}
    >
      {(isHovered || showMapBadgeNames) && (
        <Label bottom={height + 18}>{props.meta.irrigationZoneName}</Label>
      )}

      <div
        /* Pill Wrapper */
        css={{
          width: '100px',
          height: '60px',
          overflow: 'hidden',
          padding: '14px',
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'relative',
          cursor: 'pointer',
          zIndex: 2,
        }}
      >
        <div
          /* Pill Inner Text */
          css={{
            width: '100%',
            zIndex: 1,
            color: '#fff',
            display: 'flex',
            borderRadius: '6px',
            alignContent: 'center',
            justifyContent: 'center',
            fontSize: 14,
            fontWeight: 'bold',
            ...containerStyles,
          }}
        >
          {value}
        </div>
        <div
          /* Pill Shape */
          css={{
            border: `2px solid ${(isSelected && 'yellow') || 'white'}`,
            width: 'calc(100% - 24px)',
            height: 'calc(100% - 24px)',
            background: 'transparent',
            borderRadius: '95.2px',
            overflow: 'hidden',
            position: 'absolute',
            ...containerStyles,
          }}
        >
          {/* If there is progress, then there is an ongoing current event  */}
          {isNumber(progressPercent) && <InProgressEventWave progressPercent={progressPercent} />}
        </div>
      </div>
      {progressText && (
        <div
          css={{
            position: 'absolute',
            left: '80px',
            top: '18px',
            background: '#000',
            color: '#fff',
            fontSize: '12px',
            zIndex: 1,
            borderRadius: '15%',
            padding: '5px 15px 5px 15px',
            width: '120px',
          }}
        >
          {progressText}
        </div>
      )}
    </div>
  )
}
