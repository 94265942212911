import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'
import React from 'react'

type TIconChevronProps = {
  width?: string
  rotate?: boolean
}

export const IconChevron = ({ width, rotate = false }: TIconChevronProps) => {
  const baseProps = { ...makeBaseSvgIconProps('0 0 14 8') }

  if (width) {
    baseProps.width = width
  }

  const css = {
    transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
  }

  return (
    <svg {...baseProps} css={css}>
      <path
        d="M13 1L7 7L1 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
