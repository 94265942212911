import { SemiosLogoColor } from 'components/SemiosLogo/SemiosLogoColor'
import type { ReactNode } from 'react'
import { useKeyboardVisible } from 'utils/useKeyboardVisible'

export const SemiosHeaderTemplate = ({ children }: { children: ReactNode }) => {
  const { isKeyboardVisible } = useKeyboardVisible()
  const keyboardAvoiding = isKeyboardVisible ? { marginTop: '-12vh' } : {}

  return (
    <div
      css={{
        padding:
          'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        ...keyboardAvoiding,
      }}
    >
      <div
        css={{
          paddingLeft: 30,
          marginTop: 33,
          height: 91,
        }}
      >
        <SemiosLogoColor width={272} />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '65vh',
          flexDirection: 'column',
        }}
      >
        {children}
      </div>
    </div>
  )
}
