import { OfflineRequestError } from 'utils/errorCodes'
import { stringify as stringifyWKT } from 'wellknown'
import type { NodeDroppingActivationPayload } from '../NodeInstallation/types'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TActiveNode } from '../types'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { serviceCenterNodeDropInstall } from './api/serviceCenterNodeDropInstall'

export async function dropInstall(payload: NodeDroppingActivationPayload): Promise<TActiveNode> {
  let unsynced = true

  const updatedPayload = {
    ...payload,
    location: stringifyWKT(payload.location),
  }

  try {
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.DROP_INSTALL_NODE, updatedPayload)

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  if (unsynced) {
    serviceCenterNodeDropInstall.callback(updatedPayload)
  }

  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  const [newActiveNode] = activeNodes.filter(
    (node) => (node as TActiveNode).nodeIdentifier === updatedPayload.nodeIdentifier,
  )

  // Select newly installed active node
  serviceCenterStore.actions.selectEquipment(updatedPayload.nodeIdentifier, 'active')

  return newActiveNode as TActiveNode
}
