import type { TAvailablePreset } from 'App/Map/types'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import {
  selectedValueGroupsStore,
  ValueGroupDictionary,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

export const setSelectedValueGroupsAndMapPrimaryMeasure = (preset: TAvailablePreset) => {
  const newDate = +new Date()

  selectedValueGroupsStore.setState((s) => ({
    ...s,
    primaryValueGroup: preset.valueGroupForMap as TValueGroup,
    selectedValueGroups: preset.selectedValueGroups.reduce(
      (a: Partial<Record<TValueGroup, number | false>>, b) => {
        if (b === preset.valueGroupForMap) {
          a[b as TValueGroup] = newDate + 1
        } else if (ValueGroupDictionary[b as TValueGroup]) {
          a[b as TValueGroup] = newDate
        }

        return a
      },
      {},
    ),
  }))
}
