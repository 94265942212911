import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'

export const SettingsCreateNewButton = ({
  onClick,
  label = translate.phrases.banyanApp('New Group'),
  ...props
}: {
  onClick: () => void
  label?: string
}) => (
  <Button variant="primary" leftIcon={<FontAwesomeIcon icon={faPlusCircle} />} onClick={onClick} {...props}>
    {label}
  </Button>
)
