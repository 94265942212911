import { colors } from 'settings/colors'
import { useScreenSize } from 'utils/useScreenSize'

export const ItemHeader = ({ children }: { children: React.ReactNode }) => {
  const { isWideScreen } = useScreenSize()

  if (!isWideScreen) return null

  return (
    <div
      css={{
        margin: '20px',
        padding: '20px',
        height: '63px',
        fontSize: '20px',
        borderBottom: `1px solid ${colors.grey200}`,
        fontWeight: 'bold',
      }}
    >
      {children}
    </div>
  )
}
