import type { TEmitterType } from '@semios/app-platform-common'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'

export const makeLabelForNameAndEmitterType = ({
  deviceName,
  emitterType,
}: {
  deviceName: string
  emitterType: TEmitterType
}) => `${deviceName} | ${mapApiEmitterTypeToLabel(emitterType)}`
