import { isNil } from 'lodash'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import * as almondBloom from './by-domain/almondBloom'
import * as almondHullSplit from './by-domain/almondHullSplit'
import * as alternaria from './by-domain/alternaria'
import * as beeHours from './by-domain/beeHours'
import * as chill from './by-domain/chill'
import * as dewPoint from './by-domain/dewPoint'
import * as evapotranspiration from './by-domain/evapotranspiration'
import * as fruitGrowth from './by-domain/fruitGrowth'
import * as humidity from './by-domain/humidity'
import * as insectDegreeDays from './by-domain/insectDegreeDays'
import * as insectTrapCatches from './by-domain/insectTrapCatches'
import * as irrigationActivity from './by-domain/irrigationActivity'
import * as ndvi from './by-domain/ndvi'
import * as plantStress from './by-domain/plantStress'
import * as precipitation from './by-domain/precipitation'
import * as soil from './by-domain/soil'
import * as sprayConditions from './by-domain/sprayConditions'
import * as temperature from './by-domain/temperature'
import * as wetBulb from './by-domain/wetBulb'
import * as wind from './by-domain/wind'
import * as windMachine from './by-domain/windMachine'
import type {
  TCurrentValuesMapCacheKeys,
  TCurrentValuesMapProcessedCaches,
  TGetCacheUpdatesFromResponseParameters,
  TGetCacheUpdatesFromResponseReturn,
} from './by-domain/_types'

export const getCacheUpdatesFromResponse = async ({
  cacheKeys,
  processedCaches,
}: {
  cacheKeys: TCurrentValuesMapCacheKeys
  processedCaches: TCurrentValuesMapProcessedCaches
}): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const primaryValueGroup = getPrimaryValueGroup()
  const showNdvi = !!selectedValueGroupsStore.getState().selectedValueGroups.ndvi

  if (isNil(primaryValueGroup)) return {}

  const commonArgs: TGetCacheUpdatesFromResponseParameters = { cacheKeys, primaryValueGroup, processedCaches }
  const promises: Promise<TGetCacheUpdatesFromResponseReturn>[] = []

  /* eslint-disable prettier/prettier, padding-line-between-statements */
  if (primaryValueGroup === 'air_temperature') promises.push(temperature.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'almond_bloom') promises.push(almondBloom.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'almond_hull_split') promises.push(almondHullSplit.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'alternaria') promises.push(alternaria.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'bee_hours') promises.push(beeHours.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'chill') promises.push(chill.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup.includes('degree_days_insect_id_')) promises.push(insectDegreeDays.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'dew_point') promises.push(dewPoint.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'evapotranspiration') promises.push(evapotranspiration.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'fruit_growth') promises.push(fruitGrowth.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'plant_stress') promises.push(plantStress.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'humidity') promises.push(humidity.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'irrigation_activity') promises.push(irrigationActivity.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'precipitation') promises.push(precipitation.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'soil') promises.push(soil.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'spray_conditions') promises.push(sprayConditions.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup.includes('trap_catches_insect_id_')) promises.push(insectTrapCatches.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'wet_bulb') promises.push(wetBulb.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'wind') promises.push(wind.getResponseAndShapeForCacheUpdate(commonArgs))
  if (primaryValueGroup === 'wind_machine') promises.push(windMachine.getResponseAndShapeForCacheUpdate(commonArgs))
  /* eslint-enable prettier/prettier, padding-line-between-statements */

  if (showNdvi) promises.push(ndvi.getResponseAndShapeForCacheUpdate(commonArgs))

  const resolvedResults = await Promise.allSettled(promises)
  const mappedResults = resolvedResults.map((r) => (r.status === 'fulfilled' ? r.value : {}))
  const processed = Object.assign({}, ...mappedResults)

  return processed
}
