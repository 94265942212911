import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconSunsetOutline = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 64 52.793')}>
      <path
        d="M 42.5 39 C 42.5 41.9 42.106 42.425 42.106 42.425 L 21.894 42.425 C 21.894 42.425 21.5 41.9 21.5 39 C 21.5 36.101 22.675 33.476 24.575 31.575 C 26.476 29.675 29.101 28.5 32 28.5 C 34.9 28.5 37.525 29.675 39.425 31.575 C 41.325 33.475 42.5 36.1 42.5 39 Z"
        strokeMiterlimit="10"
        style={{ stroke: 'currentColor', fill: 'currentColor', strokeWidth: 3 }}
        transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
      />
      <path
        d="M 43.52 27.48 L 47.91 23.09 M 20.48 27.52 L 16.09 23.13 M 15.71 39 L 9.5 39 M 54.5 39 L 48.29 39"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit="10"
        style={{ strokeWidth: 4, stroke: 'currentColor' }}
        transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
      />
      <polyline
        points="8.618 47.716 55.381 47.716"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ strokeWidth: 3, stroke: 'currentColor' }}
        transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
      />
      <path
        d="M 33.091 6.147 C 32.487 5.546 31.506 5.546 30.906 6.147 L 23.189 13.863 C 22.588 14.464 22.588 15.445 23.189 16.047 C 23.792 16.65 24.773 16.65 25.374 16.047 L 30.457 10.961 L 30.457 22.756 C 30.457 23.609 31.146 24.298 32.001 24.298 C 32.855 24.298 33.543 23.609 33.543 22.756 L 33.543 10.961 L 38.625 16.043 C 39.229 16.646 40.207 16.646 40.812 16.043 C 41.411 15.44 41.411 14.462 40.812 13.858 L 33.093 6.144 L 33.091 6.147 Z"
        style={{ strokeWidth: 0, transformOrigin: '31.9997px 14.9971px', fill: 'currentColor' }}
        transform="matrix(-1, 0, 0, -1, 0.000003814697, -0.000001907349)"
      />
    </svg>
  )
}
