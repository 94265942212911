import React from 'react'
import type { MOVE_POINT_STATE, SIZES, SIZES_SNAPPING } from '../useMovePoint/useMoveTracker'

export const GridLines = (props: {
  tracking: MOVE_POINT_STATE['tracking']
  sizes: SIZES
  sizesSnapping: SIZES_SNAPPING
}) => {
  if (!props.tracking) return null

  let type: keyof SIZES

  if (props.tracking === 'X1') type = 'x1'
  else if (props.tracking === 'X2') type = 'x2'
  else if (props.tracking === 'Y1') type = 'y1'
  else type = 'y2'

  let sizes: number | ReadonlyArray<number> = props.sizes[type]

  if (!Array.isArray(sizes)) return null

  if (!props.sizesSnapping[type]) {
    sizes = [sizes[0], sizes[sizes.length - 1]]
  }

  const isX = ['x1', 'x2'].includes(type)

  return (
    <>
      {sizes.map((size, index, arr) => {
        const style: React.CSSProperties = isX
          ? {
              position: 'fixed',
              left: size,
              width: 1,
              top: 0,
              bottom: 0,
              borderLeft: '1px dashed deeppink',
            }
          : {
              position: 'fixed',

              top: size,
              height: 1,
              right: 0,
              left: 0,
              borderTop: '1px dashed deeppink',
            }

        return (
          <div key={size} style={style}>
            <div
              style={{
                position: 'absolute',
                backgroundColor: 'deeppink',
                color: 'white',
                fontSize: 10,
                padding: 4,
                [isX ? 'top' : 'left']: 4,
              }}
            >
              {size}
              {index === 0 && ' (Min)'}
              {index === arr.length - 1 && ' (Max)'}
            </div>
          </div>
        )
      })}
    </>
  )
}
