import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import type {
  TActiveGateway,
  TActiveNode,
  TNodeLog,
  TNodeServiceIssueSummary,
  TNodeStatus,
  TPlannedGateway,
  TPlannedNode,
} from '../types'
import { actions } from './actions/equipmentStatusPanel'
import { selectors } from './selectors/equipmentStatusPanel'
import type { TEquipmentType, TNodeDeviceType } from './serviceCenterStore'

export type Filters = {
  searchKeywords: string
  equipmentTypes: TEquipmentType[]
  deviceTypes: TNodeDeviceType[]
  statuses: TNodeStatus[]
}

export type TEquipmentStatusPanelStore = {
  nodes: Array<TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway>
  nodeLogs: Record<string, TNodeLog>
  serviceIssues: Record<string, TNodeServiceIssueSummary[]>
  propertyId: number | null
  filters: Filters
}

export const INITIAL_FILTERS = {
  searchKeywords: '',
  equipmentTypes: [],
  deviceTypes: [],
  statuses: [],
}

const store = persistentInit<TEquipmentStatusPanelStore>({
  initialState: {
    nodes: [],
    nodeLogs: {},
    serviceIssues: {},
    propertyId: null,
    filters: INITIAL_FILTERS,
  },
  keysToPutInPersistentStorage: {
    nodes: false,
    nodeLogs: false,
    serviceIssues: false,
    propertyId: true,
    filters: true,
  },
  keysToPutInURL: {
    nodes: false,
    nodeLogs: false,
    serviceIssues: false,
    propertyId: true,
    filters: true,
  },
  storeName: 'equipmentStatusPanelStore',
})

export const equipmentStatusPanelStore = {
  ...store,
  actions,
  selectors,
}
