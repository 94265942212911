import { roundToDecimalPlaces } from '@semios/app-platform-common'
import type moment from 'moment-timezone'

export const getKcForDay = ({
  date,
  startMoment,
  endMoment,
  kcStart,
  kcEnd,
  linearInterpolation,
  kcMultiplier,
}: {
  date: moment.Moment
  startMoment: moment.Moment
  endMoment: moment.Moment
  kcStart: number
  kcEnd: number
  linearInterpolation: boolean
  kcMultiplier: number
}) => {
  const modifiedKcStart = kcStart * kcMultiplier

  if (!linearInterpolation) {
    return roundToDecimalPlaces(modifiedKcStart, 2)
  }

  const modifiedKcEnd = kcEnd * kcMultiplier
  const durationOfDateRange = endMoment.diff(startMoment, 'days')

  if (durationOfDateRange === 0) {
    return roundToDecimalPlaces(modifiedKcStart, 2)
  }

  const currentDateDifferenceFromStart = date.diff(startMoment, 'days')

  const interpolatedKc =
    ((modifiedKcEnd - modifiedKcStart) / durationOfDateRange) * currentDateDifferenceFromStart +
    modifiedKcStart

  return roundToDecimalPlaces(interpolatedKc, 2)
}
