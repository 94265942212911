import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const serviceCenterHelpGuidesGet = async () => {
  const { nodes, devices } = await apiFetch<
    routes.ServiceCenterHelpGuidesGet.Request,
    routes.ServiceCenterHelpGuidesGet.Response
  >({
    url: routes.ServiceCenterHelpGuidesGet.path,
    body: null,
  })

  return {
    devices,
    nodes,
  }
}
