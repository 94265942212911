import { useStateWithTimeout } from 'components/Select/useStateWithTimeout'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { TUnitSettingsProps, UnitSettingsType } from '../../types'
import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import UnitSettingsSelect from './UnitSettingsSelect'

export const UnitSettings: FC<TUnitSettingsProps> = ({ durationMillisecond }) => {
  const { speed, temperature, depth, rain, pressure } = userDetailsStore.getState()

  const [unitSettings, setUnitSettings] = useState<UnitSettingsType>({
    speed,
    temperature,
    depth,
    rain,
    pressure,
  })

  const { stateWithTimeout: depthState, setStateWithTimeout: setDepthState } =
    useStateWithTimeout(durationMillisecond)

  const { stateWithTimeout: rainState, setStateWithTimeout: setRainState } =
    useStateWithTimeout(durationMillisecond)

  const { stateWithTimeout: speedState, setStateWithTimeout: setSpeedState } =
    useStateWithTimeout(durationMillisecond)

  const { stateWithTimeout: temperatureState, setStateWithTimeout: setTemperatureState } =
    useStateWithTimeout(durationMillisecond)

  const { stateWithTimeout: pressureState, setStateWithTimeout: setPressureState } =
    useStateWithTimeout(durationMillisecond)

  const [hideErrorMessage, setHideErrorMessage] = useState(false)
  const onCloseButtonPress = () => setHideErrorMessage(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hideErrorMessage) setHideErrorMessage(false)
    }, durationMillisecond)

    return () => clearTimeout(timer)
  }, [hideErrorMessage])

  const showErrorMessage =
    depthState === 'error' || rainState === 'error' || speedState === 'error' || temperatureState === 'error'

  return (
    <div>
      <ErrorMessage
        onCloseButtonPress={onCloseButtonPress}
        showErrorMessage={showErrorMessage && !hideErrorMessage}
        message={translate.phrases.banyanApp('Your preferences could not be saved. Please try again.')}
      />
      <ItemHeader>{translate.phrases.banyanApp('Unit Settings')}</ItemHeader>
      <div
        css={{
          maxWidth: '360px',
          padding: '0 0 20px 20px',
          width: '90%',
        }}
      >
        <UnitSettingsSelect
          data={[
            { value: 'METRIC', label: translate.measurements.centimeters.unit() },
            { value: 'IMPERIAL', label: translate.measurements.inches.unit() },
          ]}
          detailsStoreKey={'depth'}
          label={translate.phrases.banyanApp('Depth')}
          unitValue={unitSettings.depth}
          unitSettings={unitSettings}
          setUnitSettings={setUnitSettings}
          stateWithTimeout={depthState}
          setStateWithTimeout={setDepthState}
        />

        <UnitSettingsSelect
          data={[
            { value: 'METRIC', label: translate.measurements.millimeters.unit() },
            { value: 'IMPERIAL', label: translate.measurements.inches.unit() },
          ]}
          detailsStoreKey={'rain'}
          label={translate.phrases.banyanApp('Rain & Irrigation Rate')}
          unitValue={unitSettings.rain}
          unitSettings={unitSettings}
          setUnitSettings={setUnitSettings}
          stateWithTimeout={rainState}
          setStateWithTimeout={setRainState}
        />
        <UnitSettingsSelect
          data={[
            { value: 'METRIC', label: translate.measurements.kilometersPerHour.unit() },
            { value: 'IMPERIAL', label: translate.measurements.milesPerHour.unit() },
          ]}
          detailsStoreKey={'speed'}
          label={translate.phrases.banyanApp('Speed')}
          unitValue={unitSettings.speed}
          unitSettings={unitSettings}
          setUnitSettings={setUnitSettings}
          stateWithTimeout={speedState}
          setStateWithTimeout={setSpeedState}
        />
        <UnitSettingsSelect
          data={[
            { value: 'METRIC', label: translate.measurements.degreesCelsius.unit() },
            { value: 'IMPERIAL', label: translate.measurements.degreesFahrenheit.unit() },
          ]}
          detailsStoreKey={'temperature'}
          label={translate.phrases.banyanApp('Temperature')}
          unitValue={unitSettings.temperature}
          unitSettings={unitSettings}
          setUnitSettings={setUnitSettings}
          stateWithTimeout={temperatureState}
          setStateWithTimeout={setTemperatureState}
        />

        <UnitSettingsSelect
          data={[
            { value: 'METRIC', label: translate.measurements.kilopascals.unit() },
            { value: 'IMPERIAL', label: translate.measurements.poundsPerSquareInch.unit() },
          ]}
          detailsStoreKey={'pressure'}
          label={translate.phrases.banyanApp('Pressure')}
          unitValue={unitSettings.pressure}
          unitSettings={unitSettings}
          setUnitSettings={setUnitSettings}
          stateWithTimeout={pressureState}
          setStateWithTimeout={setPressureState}
        />
      </div>
    </div>
  )
}
