import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconTornado = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 264 216')}>
      <g clipPath="url(#clip0_11_644)">
        <path
          d="M12 12H252"
          stroke="url(#paint0_linear_11_644)"
          strokeWidth="24"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M28 60H236"
          stroke="url(#paint1_linear_11_644)"
          strokeWidth="24"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M48 108H216"
          stroke="url(#paint2_linear_11_644)"
          strokeWidth="24"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M68 156H196"
          stroke="url(#paint3_linear_11_644)"
          strokeWidth="24"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M100 204H164"
          stroke="url(#paint4_linear_11_644)"
          strokeWidth="24"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_11_644" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint1_linear_11_644" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint2_linear_11_644" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint3_linear_11_644" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient id="paint4_linear_11_644" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <clipPath id="clip0_11_644">
          <rect width="264" height="216" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
