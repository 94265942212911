import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconCalculatorWhite = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 17 16')}>
      <rect x="2.1001" width="12.8" height="16" rx="1" fill="white" />
      <rect x="3.69995" y="1.6001" width="9.6" height="3.2" fill="#0E4DA4" />
      <rect x="3.69995" y="6.3999" width="2.4" height="1.6" rx="0.151515" fill="#0E4DA4" />
      <rect x="3.69995" y="9.6001" width="2.4" height="1.6" rx="0.151515" fill="#0E4DA4" />
      <rect x="3.69995" y="12" width="2.4" height="2.4" rx="0.151515" fill="#0E4DA4" />
      <rect x="7.69995" y="6.3999" width="1.6" height="1.6" rx="0.151515" fill="#0E4DA4" />
      <rect x="7.69995" y="9.6001" width="1.6" height="1.6" rx="0.151515" fill="#0E4DA4" />
      <rect x="10.8999" y="9.6001" width="2.4" height="4.8" rx="0.151515" fill="#0E4DA4" />
      <rect x="7.69995" y="12" width="1.6" height="2.4" rx="0.151515" fill="#0E4DA4" />
      <rect x="10.8999" y="6.3999" width="2.4" height="1.6" rx="0.151515" fill="#0E4DA4" />
    </svg>
  )
}
