import { createStyles } from '@mantine/core'
import { colors } from 'settings/colors'

const useStyles = createStyles(() => ({
  invalid: {
    borderColor: colors.red,
  },
}))

export const getErrorStyle = () => {
  const { classes } = useStyles()

  return classes.invalid
}
