import { Button } from 'components/Button/Button'
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'

export const ConfirmationSettingsModal = ({
  confirmModalOpened,
  setConfirmModalOpened,
  handleResetAndClose,
  handleUpdate,
  zIndex = SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 20,
}: {
  confirmModalOpened: boolean
  setConfirmModalOpened: Dispatch<SetStateAction<boolean>>
  handleResetAndClose: () => void
  handleUpdate: () => void
  zIndex?: number
}) => {
  return (
    <ConfirmationModal
      zIndex={zIndex}
      modalOpened={confirmModalOpened}
      closeModal={() => setConfirmModalOpened(false)}
      title={translate.phrases.banyanApp('Unsaved Changes')}
      content={
        <div>
          {translate.phrases.banyanApp("Any changes you have made won't be saved.")}
          <br />
          {translate.phrases.banyanApp('Select an option below.')}
        </div>
      }
      modalWindowSize={350}
      buttonContent={
        <div
          style={{
            color: colors.midnight,
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            width: '100%',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant={'negative'}
            style={{ gridColumn: 1, gridRow: 1, marginRight: 2, width: 160, color: colors.midnight }}
            onClick={() => {
              setConfirmModalOpened(false)

              handleResetAndClose()
            }}
          >
            {translate.phrases.banyanApp('Discard Changes')}
          </Button>
          <Button
            variant={'secondary'}
            css={{
              'gridColumn': 2,
              'gridRow': 1,
              'width': 160,
              'justifySelf': 'end',
              'borderColor': colors.primary,
              'color': colors.primary,
              '&:not([disabled],[data-loading="true"]):hover': {
                color: colors.primary,
                background: colors.white,
                shadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', // Matches mantine style
              },
            }}
            onClick={() => {
              setConfirmModalOpened(false)
            }}
          >
            {translate.phrases.banyanApp('Keep Editing')}
          </Button>
          <Button
            variant={'primary'}
            style={{
              gridArea: '2 / 1 / 2 / 3',
              marginTop: 10,
            }}
            onClick={() => {
              setConfirmModalOpened(false)

              handleUpdate()
            }}
          >
            {translate.phrases.banyanApp('Save Changes')}
          </Button>
        </div>
      }
    />
  )
}
