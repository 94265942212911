import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TAvailablePreset } from 'App/Map/types'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { setSelectedPreset } from 'utils/setSelectedPreset/setSelectedPreset'
import type { TNewPresetState } from '../Presets'

export const submitEditedPreset = async ({ newPresetState }: { newPresetState: TNewPresetState }) => {
  const editingIdHash = newPresetState.editingIdHash as NonNullable<TNewPresetState['editingIdHash']>

  userDetailsStore.setState((s) => ({
    ...s,
    availableUserDefinedPresets: {
      ...s.availableUserDefinedPresets,
      [editingIdHash]: {
        ...(s.availableUserDefinedPresets?.[editingIdHash] as TAvailablePreset),
        ...newPresetState,
      },
    },
  }))

  if (smallStore.getState().selectedPreset === editingIdHash) setSelectedPreset(editingIdHash)

  return apiFetch<routes.AppPresetsManagement.Request, routes.AppPresetsManagement.Response>({
    url: routes.AppPresetsManagement.path,
    body: {
      editedUserDefinedPresets: {
        [editingIdHash]: {
          name: newPresetState.name as string,
          dayOffsetDateFrom: newPresetState.dayOffsetDateFrom as number,
          dayOffsetDateTo: newPresetState.dayOffsetDateTo as number,
          selectedValueGroups: newPresetState.selectedValueGroups as string[],
          valueGroupForMap: newPresetState.valueGroupForMap as string,
        },
      },
    },
  }).catch(() => {
    // TODO: errorLogger
  })
}
