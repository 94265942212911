import type { ReactNode } from 'react'
import React from 'react'

// Yes, it's recursive >:)
export const extractTextFromReactNode = (node: ReactNode): string => {
  let text = ''

  if (typeof node === 'string' || typeof node === 'number') {
    text += node
  } else if (Array.isArray(node)) {
    node.forEach((child) => {
      text += extractTextFromReactNode(child)
    })
  } else if (React.isValidElement(node) && node.props.children) {
    text += extractTextFromReactNode(node.props.children)
  }

  return text
}
