import { ExpandableChevronIndicator } from 'components/ExpandableChevronIndicator/ExpandableChevronIndicator'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { useScreenSize } from 'utils/useScreenSize'

type TSettingsCollapsedItemButton = {
  sectionLabel?: string
  children: React.ReactNode
}

const WideScreenCollapsedButton = ({
  sectionLabel,
  collapsed,
  setCollapsed,
}: {
  sectionLabel: string
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 0 0 15px',
        height: 45,
        padding: '0 10px',
        backgroundColor: colors.white,
      }}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div
        css={{
          color: colors.midnight,
          fontSize: 16,
          fontWeight: 500,
          height: 45,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {sectionLabel}
      </div>
      <span css={{ paddingRight: 12 }}>
        <ExpandableChevronIndicator expanded={!collapsed} />
      </span>
    </div>
  )
}

const NarrowScreenCollapsedButton = ({
  sectionLabel,
  collapsed,
  setCollapsed,
}: {
  sectionLabel: string
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.grey200}`,
        padding: '18px 0',
        cursor: 'pointer',
      }}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div
        css={{
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {sectionLabel}
      </div>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <ExpandableChevronIndicator expanded={!collapsed} />
      </div>
    </div>
  )
}

export const SettingsCollapsedItems = ({ sectionLabel, children }: TSettingsCollapsedItemButton) => {
  const [collapsed, setCollapsed] = useState(false)
  const { isWideScreen } = useScreenSize()

  if (!sectionLabel) {
    return <>{children}</>
  }

  return (
    <>
      {isWideScreen ? (
        <WideScreenCollapsedButton
          sectionLabel={sectionLabel}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      ) : (
        <NarrowScreenCollapsedButton
          sectionLabel={sectionLabel}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      )}
      {!collapsed && children}
    </>
  )
}
