import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconControlTemperature = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 10 19')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 19"
      fill="currentColor"
    >
      <path
        d="M3.22668 18.2918C5.79059 18.7893 8.0101 16.8376 8.0101 14.3503C8.0101 13.0492 7.35955 11.8629 6.3646 11.1358V2.37258C6.3646 1.07149 5.33138 0 3.99203 0C2.69094 0 1.61945 1.07149 1.61945 2.37258V11.1358C0.433164 12.0159 -0.255648 13.5084 0.088758 15.1921C0.394897 16.7228 1.65772 17.9857 3.22668 18.2918ZM2.11693 11.8629L2.46133 11.595V2.41084C2.46133 1.56896 3.15015 0.918416 3.95376 0.918416C4.79564 0.918416 5.48445 1.60723 5.48445 2.41084V11.595L5.82886 11.8629C6.63247 12.4369 7.09168 13.3936 7.09168 14.3885C7.09168 15.2304 6.74728 16.034 6.17327 16.608C5.56099 17.182 4.79564 17.5264 3.95376 17.5264C2.3848 17.5264 1.16024 16.4167 0.892372 15.0391C0.701036 13.7762 1.16024 12.5517 2.11693 11.8629Z"
        fill="#464B53"
      />
      <path
        d="M3.99211 16.6061C4.60439 16.6061 5.1784 16.3765 5.59934 15.9556C6.02028 15.5347 6.24989 14.9606 6.24989 14.3484C6.24989 12.8177 4.9488 12.3202 4.60439 12.0141V5.54688H3.34157V12.0141C3.0737 12.3202 1.38993 12.9325 1.77261 14.8076C1.96394 15.8025 2.84409 16.6061 3.99211 16.6061Z"
        fill="#464B53"
      />
      <path
        d="M9.54061 2.60156H7.35938V3.48171H9.54061C9.77022 3.48171 9.96155 3.29037 9.96155 3.06077C9.99982 2.7929 9.80849 2.60156 9.54061 2.60156Z"
        fill="#464B53"
      />
      <path
        d="M9.11998 5.35844C9.11998 5.12884 8.92864 4.9375 8.69904 4.9375H7.39795V5.81765H8.69904C8.92864 5.77938 9.11998 5.58805 9.11998 5.35844Z"
        fill="#464B53"
      />
      <path
        d="M10.0001 7.65532C10.0001 7.42571 9.80879 7.23438 9.57919 7.23438H7.39795V8.11452H9.57919C9.80879 8.11452 10.0001 7.88492 10.0001 7.65532Z"
        fill="#464B53"
      />
    </svg>
  )
}
