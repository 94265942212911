export const reportDeleteQuery = ` mutation ReportDelete($id: ID!) {
  deleteReport(id: $id)
}
`

export const reportCreateQuery = `mutation ReportWeeklyGeneralCreate($data: ReportNewWeeklyGeneralInput!) {
  createWeeklyGeneralReport(data: $data) {
    id
  }
}
`

export const reportCreateTrapCatchesQuery = `mutation WeeklyTrapCatchReportCreate($data: ReportNewWeeklyTrapCatchesInput!) {
  createWeeklyTrapCatchesReport(data: $data) {
    id
  }
}`

export const reportUpdateTrapCatchesQuery = `mutation WeeklyTrapCatchReportUpdate($data: ReportUpdateWeeklyTrapCatchesInput!) {
  updateWeeklyTrapCatchesReport(data: $data) {
    id
  }
}`

export const reportUpdateQuery = `mutation ReportWeeklyGeneralUpdate($data: ReportUpdateWeeklyGeneralInput!) {
  updateWeeklyGeneralReport(data: $data) {
    id
  }
}`

export const getReports = `query Reports {
  user {
    reports {
      weeklyTrapCatches {
        id
        name
        notifyOwnerByEmail
        contacts {
          id
          name
          email
          reportsContactMethods
        }
        groups {
          id
          name
          reportsContactMethods
          contacts {
            id
            name
            email
          }
        }
        rules {
          dayOfWeek
          properties {
            id
          }
          startDate
          endDate
          pests {
            insect {
              id
              name
            }
            startDate
            endDate
          }
        }
      }
      weeklyGeneral {
        id
        name
        notifyOwnerByEmail
        contacts {
          id
          name
          email
          reportsContactMethods
        }
        groups {
          id
          name
          reportsContactMethods
          contacts {
            id
            name
            email
          }
        }
        rules {
          dayOfWeek
          properties {
            id
          }
          weather {
            startDate
            endDate
          }
          fireBlight {
            startDate
            endDate
          }
          pollination {
            startDate
            endDate
          }
          growingDegreeDays {
            startDate
            endDate
          }
          evapotranspiration {
            startDate
            endDate
          }
          irrigationActivity {
            startDate
            endDate
          }
          irrigationPlanner {
            startDate
            endDate
          }
          soilMoisture {
            startDate
            endDate
          }
          chill {
            startDate
            endDate
          }
          scout {
            startDate
            endDate
          }
          pests {
            insect {
              id
              name
            }
            startDate
            endDate
            trapCatches
            degreeDays
          }
        }
      }
    }
  }
}
`
