import { isNil } from 'lodash'
import { sortNumber } from 'utils/sortNumber'
import { unitConverter } from 'utils/unitConverter/unitConverter'

// TODO: the backend should instead be doing this
export const aggregateBlockDataFromTraps = ({
  valueType,
}: {
  valueType: { timeseries: { timestamp: string; value: number | null }[] }[]
}) => {
  const larvalBlockAgg: Record<string, { sum: number; numNonNullTraps: number }> = {}

  valueType?.forEach((d) => {
    d?.timeseries?.forEach((ts) => {
      const { timestamp, value: larvalTrend } = ts ?? {}

      if (!isNil(timestamp)) {
        larvalBlockAgg[timestamp] = larvalBlockAgg[timestamp] ?? {
          sum: null,
          numNonNullTraps: 0,
        }

        if (!isNil(larvalTrend)) {
          larvalBlockAgg[timestamp].sum = Number(larvalBlockAgg[timestamp].sum) + larvalTrend

          larvalBlockAgg[timestamp].numNonNullTraps += 1
        }
      }
    })
  })

  const timestampToCountDictionary = Object.entries(larvalBlockAgg).reduce(
    (acc: Record<string, number | null>, [key, { sum, numNonNullTraps }]) => {
      if (!isNil(sum)) acc[key] = sum / numNonNullTraps

      return acc
    },
    {},
  )

  return Object.entries(timestampToCountDictionary)
    .map(([timestamp, value]) => [+new Date(timestamp), unitConverter.insectLarvalTrend(value).value()])
    .sort(([a], [b]) => sortNumber(a, b))
}
