import { baseZIndex } from 'components/GridTable/consts'
import { colors } from 'settings/colors'
import type { GridTableColumn } from '../../types'
import { Cell } from './Cell/Cell'

export const Right = ({
  columns,
  widthForValueColumns,
}: {
  columns: GridTableColumn[]
  widthForValueColumns: number
}) => {
  return (
    <div
      css={{
        float: 'left',
        backgroundColor: colors.white,
        boxSizing: 'border-box',
        display: 'flex',
        zIndex: baseZIndex - 3,
        width: widthForValueColumns * columns.length,
        borderLeft: `2px solid ${colors.grey200}`,
        paddingTop: 20,
      }}
    >
      <div css={{ display: 'flex' }}>
        {columns.map((columns) => {
          return (
            <Cell key={columns.key} column={columns} widthForValueColumns={widthForValueColumns}>
              {columns.title}
            </Cell>
          )
        })}
      </div>
    </div>
  )
}
