import React from 'react'
import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconRefresh = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 25 24')}>
      <path
        d="M5.6 16.05C5.23333 15.4167 4.95833 14.7667 4.775 14.1C4.59167 13.4333 4.5 12.75 4.5 12.05C4.5 9.81667 5.275 7.91667 6.825 6.35C8.375 4.78333 10.2667 4 12.5 4H12.675L11.075 2.4L12.475 1L16.475 5L12.475 9L11.075 7.6L12.675 6H12.5C10.8333 6 9.41667 6.5875 8.25 7.7625C7.08333 8.9375 6.5 10.3667 6.5 12.05C6.5 12.4833 6.55 12.9083 6.65 13.325C6.75 13.7417 6.9 14.15 7.1 14.55L5.6 16.05ZM12.525 23L8.525 19L12.525 15L13.925 16.4L12.325 18H12.5C14.1667 18 15.5833 17.4125 16.75 16.2375C17.9167 15.0625 18.5 13.6333 18.5 11.95C18.5 11.5167 18.45 11.0917 18.35 10.675C18.25 10.2583 18.1 9.85 17.9 9.45L19.4 7.95C19.7667 8.58333 20.0417 9.23333 20.225 9.9C20.4083 10.5667 20.5 11.25 20.5 11.95C20.5 14.1833 19.725 16.0833 18.175 17.65C16.625 19.2167 14.7333 20 12.5 20H12.325L13.925 21.6L12.525 23Z"
        fill="currentColor"
      />
    </svg>
  )
}
