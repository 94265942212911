import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconControls = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 19 19')}>
      <path
        d="M18.2083 12.9952L17.0889 11.875L15.0417 13.923L12.9944 11.875L11.875 12.9952L13.9223 15.0417L11.875 17.0889L12.9944 18.2083L15.0417 16.1611L17.0889 18.2083L18.2083 17.0889L16.1611 15.0417L18.2083 12.9952Z"
        fill="currentColor"
      />
      <path
        d="M15.8333 0C14.9938 0.000942918 14.1889 0.334876 13.5952 0.928537C13.0015 1.5222 12.6676 2.3271 12.6667 3.16667C12.6696 3.73016 12.8246 4.2824 13.1155 4.76504L4.76504 13.1155C4.28239 12.8247 3.73015 12.6696 3.16667 12.6667C2.54036 12.6667 1.92812 12.8524 1.40736 13.2003C0.886607 13.5483 0.480727 14.0429 0.24105 14.6215C0.00137206 15.2001 -0.0613385 15.8368 0.060848 16.4511C0.183035 17.0654 0.48463 17.6296 0.927496 18.0725C1.37036 18.5154 1.93461 18.817 2.54888 18.9392C3.16316 19.0613 3.79987 18.9986 4.3785 18.759C4.95713 18.5193 5.4517 18.1134 5.79965 17.5926C6.14761 17.0719 6.33333 16.4596 6.33333 15.8333C6.33044 15.2698 6.17538 14.7176 5.88454 14.235L14.235 5.88454C14.7176 6.17534 15.2698 6.3304 15.8333 6.33333C16.6732 6.33333 17.4786 5.9997 18.0725 5.40584C18.6664 4.81197 19 4.00652 19 3.16667C19 2.32681 18.6664 1.52136 18.0725 0.927495C17.4786 0.33363 16.6732 0 15.8333 0ZM3.16667 17.4167C2.85351 17.4167 2.54739 17.3238 2.28702 17.1498C2.02664 16.9758 1.8237 16.7286 1.70386 16.4392C1.58402 16.1499 1.55266 15.8316 1.61376 15.5244C1.67485 15.2173 1.82565 14.9352 2.04708 14.7137C2.26852 14.4923 2.55064 14.3415 2.85778 14.2804C3.16491 14.2193 3.48327 14.2507 3.77258 14.3705C4.0619 14.4904 4.30918 14.6933 4.48316 14.9537C4.65714 15.2141 4.75 15.5202 4.75 15.8333C4.74952 16.2531 4.58255 16.6556 4.28572 16.9524C3.98889 17.2492 3.58645 17.4162 3.16667 17.4167ZM15.8333 4.75C15.5202 4.75 15.2141 4.65714 14.9537 4.48316C14.6933 4.30918 14.4904 4.0619 14.3705 3.77258C14.2507 3.48327 14.2193 3.16491 14.2804 2.85777C14.3415 2.55064 14.4923 2.26851 14.7137 2.04708C14.9352 1.82565 15.2173 1.67485 15.5244 1.61376C15.8316 1.55266 16.1499 1.58402 16.4393 1.70386C16.7286 1.8237 16.9758 2.02664 17.1498 2.28701C17.3238 2.54739 17.4167 2.85351 17.4167 3.16667C17.4162 3.58644 17.2492 3.98889 16.9524 4.28572C16.6556 4.58255 16.2531 4.74952 15.8333 4.75Z"
        fill="currentColor"
      />
      <path
        d="M3.16667 6.00842L1.11625 3.95801L0 5.07426L3.16667 8.24092L8.70833 2.69926L7.59208 1.58301L3.16667 6.00842Z"
        fill="currentColor"
      />
    </svg>
  )
}
