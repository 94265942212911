import type * as MantineTypes from '@mantine/core'
import type { PolymorphicComponentProps } from '@mantine/utils'
import React, { forwardRef } from 'react'
import { ButtonAsync } from './ButtonAsync/ButtonAsync'
import { ChildContent } from './ChildContent/ChildContent'
import { getButtonComponent } from './_utils/getButtonComponent'

export type MantineButtonProps = PolymorphicComponentProps<'button', MantineTypes.ButtonProps>

export type ButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'positive'
    | 'negative'
    | 'link'
    | 'link-negative'
    | 'list'
    | 'bare'
  async?: boolean
  asyncOptions?: {
    minimumVisualLoadingMilliseconds?: number
    successMilliseconds?: number
    errorMilliseconds?: number
    loadingContent?: React.ReactNode
    successContent?: React.ReactNode
    errorContent?: React.ReactNode
  }
} & Omit<MantineButtonProps, 'variant'>

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  if (props.async === true) return <ButtonAsync ref={ref} {...props} />

  const { variant, ...mantineButtonProps } = props
  const ButtonComponent = getButtonComponent(variant)

  return (
    <ButtonComponent
      {...mantineButtonProps}
      ref={ref}
      leftIcon={null} // icons are put into the children for consistency and to deal with some mantine styling issues
      rightIcon={null} // icons are put into the children for consistency and to deal with some mantine styling issues
      loaderProps={{ variant: 'dots', ...props.loaderProps }}
    >
      <ChildContent
        leftIcon={props.leftIcon}
        content={props.children}
        rightIcon={props.rightIcon}
        fullWidth={props.fullWidth}
      />
    </ButtonComponent>
  )
})

Button.displayName = 'Button'

export { Button }
