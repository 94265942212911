import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconFilter = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 16 13')}>
      <path
        d="M6.22222 10.0521H9.77778V8.74967H6.22222V10.0521ZM0 2.23752V3.53995H16V2.23752H0ZM2.66667 6.79603H13.3333V5.49359H2.66667V6.79603Z"
        fill="currentColor"
      />
    </svg>
  )
}
