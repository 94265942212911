import { createContext } from 'react'

interface ContextProps {
  setMap: (map: google.maps.Map | null) => void
  map: google.maps.Map | null
  setLoadingData: (value: boolean) => void
  loadingData: boolean
}

export const MapContext = createContext<ContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMap: () => {},
  map: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoadingData: () => {},
  loadingData: false,
})
