import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'

export const AddRowButton = ({ onClick, disabled }: { disabled: boolean; onClick: () => void }) => {
  return (
    <Button disabled={disabled} variant="tertiary" onClick={onClick}>
      {translate.phrases.banyanApp('Add Row')}
    </Button>
  )
}
