import type { FC } from 'react'
import type { TUserSettingsMenuScreenProps } from '../types'
import { SettingsItems } from './SettingsItems'
import { SettingsMenuSidePanel } from './SettingsMenuSidePanel'

export const UserSettingsMenuWideScreen: FC<TUserSettingsMenuScreenProps> = ({
  currentTab,
  setCurrentTab,
  durationMillisecond,
  searchText,
  setSearchText,
  filteredSettingItems,
}) => {
  return (
    <div css={{ display: 'flex', height: '100%' }}>
      <SettingsMenuSidePanel
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        searchText={searchText}
        setSearchText={setSearchText}
        filteredSettingItems={filteredSettingItems}
      />
      <SettingsItems
        currentTab={currentTab}
        durationMillisecond={durationMillisecond}
        setCurrentTab={setCurrentTab}
      />
    </div>
  )
}
