import { useMantineTheme } from '@mantine/core'
import { Button } from 'components/Button/Button'
import { IconChevron } from 'components/icons/IconChevron'
import React from 'react'
import { HelpGuideSection } from '../../types'
import { getHelpGuideSectionTitle } from '../../utils/getHelpGuideSectionTitle'

interface HelpGuideCardProps {
  title: string
  sections: HelpGuideSection[]
  expanded: boolean
  onToggleExpand(): void
  onSelectGuide(section: HelpGuideSection): void
}

export const HelpGuideCard: React.FC<HelpGuideCardProps> = ({
  title,
  sections,
  expanded,
  onToggleExpand,
  onSelectGuide,
}) => {
  const theme = useMantineTheme()

  return (
    <div
      css={{
        border: `1px solid ${theme.colors.grey[1]}`,
        borderRadius: 3,
        color: theme.colors.midnight[0],
        marginBottom: 10,
        lineHeight: '20px',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          backgroundColor: theme.colors.grey[0],
          padding: '14px 10px',
        }}
        onClick={onToggleExpand}
      >
        <div>{title}</div>

        <div>
          <span
            css={{
              display: 'inline-block',
              marginLeft: 10,
              transform: expanded ? 'rotate(180deg)' : undefined,
            }}
          >
            <IconChevron />
          </span>
        </div>
      </div>

      {expanded && (
        <div css={{ padding: '8px 5px', borderTop: `1px solid ${theme.colors.grey[1]}` }}>
          {Object.values(HelpGuideSection).map((section) => {
            if (!sections.includes(section)) return null

            return (
              <Button
                key={section}
                variant="link"
                css={{ display: 'block', margin: '12px 0' }}
                onClick={() => onSelectGuide(section)}
              >
                {getHelpGuideSectionTitle(section)}
              </Button>
            )
          })}
        </div>
      )}
    </div>
  )
}
