import type { MantineTheme, RadioProps } from '@mantine/core'
import { Radio as MantineRadio } from '@mantine/core'
import type { FC } from 'react'
import React from 'react'

export const Radio: FC<RadioProps> = (props) => {
  return (
    <MantineRadio
      size="sm"
      styles={(theme: MantineTheme) => {
        return {
          inner: {
            input: {
              'backgroundColor': theme.colors.grey[1],
              'borderColor': theme.colors.grey[1],
              'boxShadow': 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
              '&:checked': {
                backgroundColor: theme.colors.primary[0],
                borderColor: theme.colors.primary[0],
              },
              '&:disabled': {
                backgroundColor: theme.colors.grey[1],
                borderColor: theme.colors.grey[1],
              },
            },
            icon: {
              color: theme.colors.midnight[0],
            },
          },
          label: {
            color: theme.colors.midnight[0],
          },
        }
      }}
      {...props}
    />
  )
}
