import { Table } from '@mantine/core'
import type { TNodeDevices } from 'App/ServiceCenter/types'
import { translate } from 'i18n/i18n'
import React from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { flatNodeDevices } from '../../utils/flatNodeDevices'
import { getDeviceInstallationHeightLabel } from '../../utils/getDeviceInstallationHeightLabel'

interface DeviceListProps {
  nodeDevices: TNodeDevices | undefined
}

export const DeviceList: React.FC<DeviceListProps> = ({ nodeDevices }) => {
  const allDevices = fieldAssetStore.useSelector((s) => s.devices)
  const nodeDeviceArray = flatNodeDevices(nodeDevices)

  return (
    <div css={{ lineHeight: '24px' }}>
      <Table
        css={{
          'thead tr th:first-of-type,tbody tr td:first-of-type': {
            paddingLeft: 0,
          },
        }}
      >
        <thead>
          <tr>
            <th>{translate.phrases.placeholder('Device')}</th>
            <th>{translate.phrases.placeholder('Height')}</th>
          </tr>
        </thead>
        <tbody>
          {nodeDeviceArray.map((device) => (
            <tr key={`${device.connector}-${device.channel}`}>
              <td>{allDevices[device.source]?.name || device.source}</td>
              <td>{getDeviceInstallationHeightLabel(device.height)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
