import { MultiSelect } from './MultiSelect/MultiSelect'
import { MultiSelectAddItem } from './MultiSelect/MultiSelectAddItem'
import { MultiSelectWithGroup } from './MultiSelect/MultiSelectWithGroup'
import { MultiSelectWithSection } from './MultiSelect/MultiSelectWithSection'

export const SelectExamples = () => {
  return (
    <div css={{ padding: 12 }}>
      <MultiSelect />
      <MultiSelectWithGroup />
      <MultiSelectWithSection />
      <MultiSelectAddItem />
    </div>
  )
}
