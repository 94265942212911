import { css } from '@emotion/css'
import { faBars, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { ToggleButtonStateExample } from './ToggleButtonStateExample/ToggleButtonStateExample'

export const ToggleButtonExamples = () => {
  const toggleButtonWithIcons = () => {
    return (
      <div>
        <h4>With Icons</h4>
        <ToggleButton
          data={[
            {
              label: (
                <>
                  <FontAwesomeIcon icon={faBars} />
                  <span
                    className={css`
                      margin: 0 5px;
                    `}
                  >
                    Details
                  </span>
                </>
              ),
              value: 'details',
            },
            {
              label: (
                <>
                  <FontAwesomeIcon icon={faChartLine} />
                  <span
                    className={css`
                      margin: 0 5px;
                    `}
                  >
                    Graph
                  </span>
                </>
              ),
              value: 'graph',
            },
          ]}
          // eslint-disable-next-line no-console
          onChange={(activeTab) => console.log('activeTab : ', activeTab)}
          defaultValue={'details'}
        />
      </div>
    )
  }

  const toggleButtonWithoutIcons = () => {
    return (
      <div>
        <h4>Without Icons</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          // eslint-disable-next-line no-console
          onChange={(activeTab) => console.log('activeTab : ', activeTab)}
          defaultValue={'details'}
        />
      </div>
    )
  }

  const toggleButtonWithDefaultValue = () => {
    return (
      <div>
        <h4>With Default Value</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          // eslint-disable-next-line no-console
          onChange={(activeTab) => console.log('activeTab : ', activeTab)}
          defaultValue={'graph'}
        />
      </div>
    )
  }

  const toggleButtonWithoutDefaultValue = () => {
    return (
      <div>
        <h4>Without Default Value</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          // eslint-disable-next-line no-console
          onChange={(activeTab) => console.log('activeTab : ', activeTab)}
        />
      </div>
    )
  }

  const toggleButtonWithValue = () => {
    return (
      <div>
        <h4>With Value</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          // eslint-disable-next-line no-console
          onChange={(activeTab) => console.log('activeTab : ', activeTab)}
          value={'graph'}
        />
      </div>
    )
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
        `}
      >
        {toggleButtonWithIcons()}
        {toggleButtonWithoutIcons()}
        {toggleButtonWithDefaultValue()}
        {toggleButtonWithoutDefaultValue()}
        {toggleButtonWithValue()}
      </div>
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
        `}
      >
        <div
          className={css`
            width: 100%;
            margin: 20px 0 5px;
            font-size: 21px;
            font-weight: 500;
          `}
        >
          Toggle Button State Examples
        </div>
        <ToggleButtonStateExample />
      </div>
    </div>
  )
}
