import React from 'react'
import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconCamera = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 122 121')}>
      <path
        d="M95.9734 48.5945V39.6046H85.9516V30.6147H95.9734V21.6248H105.995V30.6147H116.017V39.6046H105.995V48.5945H95.9734ZM15.7996 111.524C13.0436 111.524 10.6843 110.644 8.72171 108.883C6.75913 107.123 5.77783 105.006 5.77783 102.534V48.5945C5.77783 46.1223 6.75913 44.0059 8.72171 42.2454C10.6843 40.4849 13.0436 39.6046 15.7996 39.6046H31.5838L40.8539 30.6147H70.9191V39.6046H45.2384L36.0936 48.5945H15.7996V102.534H95.9734V62.0794H105.995V102.534C105.995 105.006 105.014 107.123 103.051 108.883C101.089 110.644 98.7293 111.524 95.9734 111.524H15.7996ZM55.8865 95.7916C62.15 95.7916 67.4741 93.8251 71.8586 89.892C76.2431 85.9589 78.4353 81.183 78.4353 75.5643C78.4353 69.9456 76.2431 65.1697 71.8586 61.2366C67.4741 57.3035 62.15 55.337 55.8865 55.337C49.6229 55.337 44.2988 57.3035 39.9143 61.2366C35.5298 65.1697 33.3376 69.9456 33.3376 75.5643C33.3376 81.183 35.5298 85.9589 39.9143 89.892C44.2988 93.8251 49.6229 95.7916 55.8865 95.7916ZM55.8865 86.8017C52.3789 86.8017 49.4141 85.7154 46.9922 83.5429C44.5703 81.3703 43.3593 78.7108 43.3593 75.5643C43.3593 72.4178 44.5703 69.7583 46.9922 67.5857C49.4141 65.4132 52.3789 64.3269 55.8865 64.3269C59.3941 64.3269 62.3588 65.4132 64.7808 67.5857C67.2027 69.7583 68.4136 72.4178 68.4136 75.5643C68.4136 78.7108 67.2027 81.3703 64.7808 83.5429C62.3588 85.7154 59.3941 86.8017 55.8865 86.8017Z"
        fill="currentColor"
      />
    </svg>
  )
}
