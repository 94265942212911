import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconDirection = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 16 16')}>
      <path
        d="M16 8L8 0L0 7.99232L8 16L16 8ZM9.53698 9.92123V8H6.46302V10.3055H4.92603V6.46302H9.53698V4.54179L12.2267 7.23151L9.53698 9.92123Z"
        fill="currentColor"
      />
    </svg>
  )
}
