import { Input } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import { getSelectOptionsFromEmitters } from 'App/irrigation-scheduler/utils/getSelectOptionsFromEmitters'
import { mapPropertiesForPropertySelect } from 'App/irrigation-scheduler/utils/mapPropertiesForPropertySelect'
import { getIrrigationProperties } from 'App/irrigation-scheduler/utils/store/selectors/getIrrigationProperties'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { PropertySelect } from 'components/PropertySelect/PropertySelect'
import { translate } from 'i18n/i18n'
import { useEffect, useMemo, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { apiFetch } from 'utils/apiFetch'
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { useErrorMessage } from '../../ErrorMessage/useErrorMessage'

export const NewEmitterGroupModal = ({
  title,
  primaryButtonText,
  opened,
  onClose,
  selectedPropertyId,
  setSelectedPropertyId,
}: {
  title: string
  primaryButtonText: string
  opened: boolean
  onClose: () => void
  selectedPropertyId: number | null
  setSelectedPropertyId: (arg: number | null) => void
}) => {
  const [selectedIrrigationZoneEmitters, setSelectedIrrigationZoneEmitters] = useState<TOptions[]>([])
  const [groupName, setGroupName] = useState('')
  const { showErrorMessage, triggerErrorMessage, onCloseButtonPress } = useErrorMessage()
  const fass = fieldAssetStore.useSelector((s) => s)
  const selectedProperty = fass.properties?.[Number(selectedPropertyId)]
  const irrigationProperties = getIrrigationProperties(fass)

  useEffect(() => {
    setSelectedIrrigationZoneEmitters([])
  }, [selectedPropertyId])

  const irrigationZoneEmittersForProperty = useMemo(
    () => getSelectOptionsFromEmitters(selectedProperty?.irrigationZoneEmitters ?? {}),
    [selectedProperty?.irrigationZoneEmitters],
  )

  const data = useMemo(() => mapPropertiesForPropertySelect(irrigationProperties), [irrigationProperties])

  return (
    <ModalDrawer
      title={title}
      primaryButtonOnPress={async () => {
        if (!selectedPropertyId) return

        // check if group name is blank
        if (!groupName || groupName.trim() === '') {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} is required', {
              label: translate.phrases.banyanApp('Group Name'),
            }),
          )

          return
        }

        // check if group name already exists
        const groupsForProperty = Object.values(selectedProperty?.irrigationZoneEmitterGroups ?? {})

        if (
          groupsForProperty.some(
            (group) => group.groupName.trim().toLowerCase() === groupName.trim().toLowerCase(),
          )
        ) {
          triggerErrorMessage(
            translate.phrases.validation('{{label}} already exists', {
              label: translate.phrases.banyanApp('Group Name'),
            }),
          )

          return
        }

        const irrigationZoneEmitterIds = selectedIrrigationZoneEmitters.map(({ value }) => value as string)

        try {
          const response = await apiFetch<
            routes.IrrigationZoneEmitterGroupCreate.Request,
            routes.IrrigationZoneEmitterGroupCreate.Response
          >({
            url: 'irrigation-zone-emitter-group-create',
            body: {
              groupName: groupName,
              propertyId: Number(selectedPropertyId),
              irrigationZoneEmitterIds,
            },
          })

          const success = typeof response === 'number'

          if (success) {
            setGroupName('')

            setSelectedIrrigationZoneEmitters([])

            fieldAssetStore.setState((s) => {
              if (!s) return s

              return {
                ...s,
                properties: {
                  ...s.properties,
                  [String(selectedPropertyId)]: {
                    ...s.properties?.[Number(selectedPropertyId)],
                    irrigationZoneEmitterGroups: {
                      ...s.properties?.[Number(selectedPropertyId)].irrigationZoneEmitterGroups,
                      [response]: {
                        groupId: response,
                        groupName,
                        irrigationZoneEmitterIds,
                      },
                    },
                  },
                },
              }
            })

            onClose()
          } else {
            triggerErrorMessage(translate.phrases.banyanApp('Failed to create group'))
          }
        } catch (e) {
          triggerErrorMessage(translate.phrases.banyanApp('Failed to create group'))
        }
      }}
      primaryButtonText={primaryButtonText}
      primaryButtonDisabled={!groupName || !selectedIrrigationZoneEmitters.length || !selectedPropertyId}
      opened={opened}
      onClose={onClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      <ErrorMessage
        message={showErrorMessage}
        onCloseButtonPress={onCloseButtonPress}
        showErrorMessage={!!showErrorMessage}
      />
      <div css={{ margin: 30 }}>
        <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Group Name')}</div>
        <Input
          value={groupName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
          css={{ maxWidth: 300 }}
          maxLength={40}
        />
      </div>
      <div css={{ margin: 30 }}>
        <div css={{ marginBottom: 10 }}>{translate.phrases.banyanApp('Property')}</div>
        <PropertySelect
          clearable={false}
          data={data}
          disabled={isEmpty(data)}
          selectedPropertyId={selectedPropertyId}
          onChange={setSelectedPropertyId}
        />
      </div>
      <div css={{ margin: 30 }}>
        <div css={{ marginBottom: 10 }}>
          {translate.phrases.banyanApp('Select zone emitters to associate with this group')}
        </div>
        <MultiSelect
          data={irrigationZoneEmittersForProperty}
          disabled={isEmpty(irrigationZoneEmittersForProperty)}
          selectedData={selectedIrrigationZoneEmitters}
          onChange={setSelectedIrrigationZoneEmitters}
          placeholder={translate.phrases.banyanApp('Select zone emitters to associate with this group')}
        />
      </div>
    </ModalDrawer>
  )
}
