// since min prop in Mantine NumberInput causes a misleading tooltip to appear, we need to validate the value before setting it
export const getValidatedNumberInputValue = ({
  value,
  min,
  allowEmpty,
}: {
  value: '' | number
  min: number
  allowEmpty: boolean
}) => {
  if ((!allowEmpty && typeof value === 'string') || (typeof value === 'number' && value < min)) {
    return min
  }

  return value
}
