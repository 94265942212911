import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconOvercastHail = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 399 320')}>
      <g clipPath="url(#clip0_11_512)">
        <g clipPath="url(#clip1_11_512)">
          <g clipPath="url(#clip2_11_512)">
            <path
              d="M199.34 120.18C199.34 128.784 202.758 137.035 208.841 143.119C211.854 146.131 215.43 148.521 219.366 150.151C223.301 151.781 227.52 152.62 231.78 152.62H361.53V152.5C362.3 152.5 363.06 152.62 363.84 152.62C372.493 152.622 380.836 149.395 387.236 143.571C393.636 137.747 397.633 129.745 398.444 121.13C399.256 112.514 396.824 103.906 391.624 96.9895C386.425 90.0726 378.831 85.3446 370.33 83.7303C371.335 77.6443 370.58 71.3979 368.155 65.7262C365.73 60.0544 361.736 55.1934 356.641 51.715C351.547 48.2366 345.566 46.2857 339.4 46.0917C333.235 45.8978 327.143 47.469 321.84 50.6203C315.796 40.675 306.383 33.2296 295.313 29.6388C284.244 26.0481 272.253 26.5505 261.522 31.0547C250.791 35.5589 242.034 43.7656 236.844 54.1817C231.653 64.5977 230.374 76.531 233.24 87.8103C232.75 87.8103 232.24 87.7403 231.78 87.7403C227.52 87.7403 223.301 88.5794 219.366 90.2097C215.43 91.8399 211.854 94.2294 208.841 97.2418C202.758 103.325 199.34 111.577 199.34 120.18Z"
              fill="url(#paint0_linear_11_512)"
              stroke="#848B98"
              strokeMiterlimit="10"
            />
          </g>
          <g clipPath="url(#clip3_11_512)">
            <path
              d="M291.84 107C290.99 107 290.16 107.09 289.31 107.13C294.134 87.7023 291.853 67.1901 282.879 49.2967C273.905 31.4034 258.829 17.3083 240.373 9.55632C221.918 1.80437 201.299 0.906499 182.239 7.02481C163.179 13.1431 146.935 25.8744 136.44 42.9199C127.947 37.8427 118.26 35.11 108.366 35.0009C98.4722 34.8918 88.7266 37.4103 80.1244 42.2991C71.5222 47.1879 64.3714 54.272 59.4022 62.828C54.433 71.3841 51.8234 81.1056 51.8397 90.9999C51.8617 94.0432 52.1292 97.0797 52.6397 100.08C37.9919 102.897 24.9187 111.069 15.9697 123.003C7.02063 134.936 2.83752 149.776 4.23615 164.626C5.63479 179.477 12.5149 193.274 23.5346 203.327C34.5543 213.379 48.9235 218.967 63.8397 219C65.1897 219 66.5097 218.89 67.8397 218.8V219H291.84C306.692 219 320.936 213.1 331.438 202.598C341.94 192.096 347.84 177.852 347.84 163C347.84 148.148 341.94 133.904 331.438 123.402C320.936 112.9 306.692 107 291.84 107Z"
              fill="url(#paint1_linear_11_512)"
              stroke="#CAD6E8"
              strokeWidth="6"
              strokeMiterlimit="10"
            />
          </g>
        </g>
        <g clipPath="url(#clip4_11_512)">
          <path
            d="M132 205C129.627 205 127.307 205.704 125.333 207.022C123.36 208.341 121.822 210.215 120.913 212.408C120.005 214.601 119.768 217.013 120.231 219.341C120.694 221.669 121.836 223.807 123.515 225.485C125.193 227.164 127.331 228.306 129.659 228.769C131.987 229.232 134.399 228.995 136.592 228.087C138.785 227.178 140.659 225.64 141.978 223.667C143.296 221.693 144 219.373 144 217C144 213.817 142.736 210.765 140.485 208.515C138.235 206.264 135.183 205 132 205Z"
            fill="url(#paint2_linear_11_512)"
            stroke="#86C3DB"
            strokeMiterlimit="10"
          />
          <path
            d="M188 295C185.627 295 183.307 295.704 181.333 297.022C179.36 298.341 177.822 300.215 176.913 302.408C176.005 304.601 175.768 307.013 176.231 309.341C176.694 311.669 177.836 313.807 179.515 315.485C181.193 317.164 183.331 318.306 185.659 318.769C187.987 319.232 190.399 318.995 192.592 318.087C194.785 317.178 196.659 315.64 197.978 313.667C199.296 311.693 200 309.373 200 307C200 303.817 198.736 300.765 196.485 298.515C194.235 296.264 191.183 295 188 295Z"
            fill="url(#paint3_linear_11_512)"
            stroke="#86C3DB"
            strokeMiterlimit="10"
          />
          <path
            d="M244 235C241.627 235 239.307 235.704 237.333 237.022C235.36 238.341 233.822 240.215 232.913 242.408C232.005 244.601 231.768 247.013 232.231 249.341C232.694 251.669 233.836 253.807 235.515 255.485C237.193 257.164 239.331 258.306 241.659 258.769C243.987 259.232 246.399 258.995 248.592 258.087C250.785 257.178 252.659 255.64 253.978 253.667C255.296 251.693 256 249.373 256 247C256 243.817 254.736 240.765 252.485 238.515C250.235 236.264 247.183 235 244 235Z"
            fill="url(#paint4_linear_11_512)"
            stroke="#86C3DB"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_512"
          x1="251.58"
          y1="36.6203"
          x2="332.2"
          y2="176.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9CA3AF" />
          <stop offset="0.45" stopColor="#9CA3AF" />
          <stop offset="1" stopColor="#6B7280" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_512"
          x1="100.29"
          y1="30.6799"
          x2="233.48"
          y2="261.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F7FE" />
          <stop offset="0.45" stopColor="#F3F7FE" />
          <stop offset="1" stopColor="#DEEAFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_512"
          x1="126"
          y1="206.61"
          x2="138"
          y2="227.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_512"
          x1="182"
          y1="296.61"
          x2="194"
          y2="317.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11_512"
          x1="238"
          y1="236.61"
          x2="250"
          y2="257.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <clipPath id="clip0_11_512">
          <rect width="399" height="320" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_512">
          <rect width="398" height="222" fill="white" transform="translate(0.839844)" />
        </clipPath>
        <clipPath id="clip2_11_512">
          <rect width="200.26" height="126.12" fill="white" transform="translate(198.84 27)" />
        </clipPath>
        <clipPath id="clip3_11_512">
          <rect width="350" height="222" fill="white" transform="translate(0.839844)" />
        </clipPath>
        <clipPath id="clip4_11_512">
          <rect width="137" height="115" fill="white" transform="translate(119.5 204.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
