import type { FC } from 'react'
import { useSetStatusBarTheme } from 'utils/useSetStatusBarTheme'

export type StatusBarAreaProps = {
  backgroundColor?: string
  extraHeight?: number
  darkContent?: boolean
}

export const StatusBarArea: FC<StatusBarAreaProps> = (props) => {
  const { backgroundColor, darkContent } = props

  useSetStatusBarTheme({ darkContent })

  return (
    <div
      css={{
        height: 'env(safe-area-inset-top)',
        minHeight: '5px',
        marginTop: '-5px',
        backgroundColor: backgroundColor || 'transparent',
      }}
    ></div>
  )
}
