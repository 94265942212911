import { Avatar } from '@mantine/core'
import { colors } from 'settings/colors'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'

export const SelectedLayersCountBadge = ({ count }: { count?: number }) => {
  return (
    <span css={{ ...fadeInOutStylesMaker(!!count) }}>
      <Avatar styles={{ root: { padding: 2, marginLeft: 6, alignSelf: 'center' } }} radius="xl" size="sm">
        <span css={{ color: colors.midnight, fontSize: 14, fontWeight: 500 }}>{count ?? ''}</span>
      </Avatar>
    </span>
  )
}
