import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

type TFruitGrowthUnit = 'mm' | 'in' | 'µm'

type TFruitGrowthOptions = 'fruitGrowth' | 'fruitSize' | 'fruitSizeTarget'

const MM_PER_INCH = 25.4
const UM_PER_MM = 1000

const getConvertedValue = (
  inputUnit: TFruitGrowthUnit,
  outputUnit: TFruitGrowthUnit,
  fruitGrowthAmount: number | null = null,
) => {
  const shouldConvertMMToIN = inputUnit === 'mm' && outputUnit === 'in'
  const shouldConvertMMToUM = inputUnit === 'mm' && outputUnit === 'µm'
  const shouldConvertINToMM = inputUnit === 'in' && outputUnit === 'mm'
  const shouldConvertINToUM = inputUnit === 'in' && outputUnit === 'µm'
  const shouldConvertUMToMM = inputUnit === 'µm' && outputUnit === 'mm'
  const shouldConvertUMToIN = inputUnit === 'µm' && outputUnit === 'in'

  let convertedValue: number | null

  if (isNil(fruitGrowthAmount)) {
    convertedValue = null
  } else if (shouldConvertMMToIN) {
    convertedValue = fruitGrowthAmount / MM_PER_INCH
  } else if (shouldConvertMMToUM) {
    convertedValue = fruitGrowthAmount * UM_PER_MM
  } else if (shouldConvertINToMM) {
    convertedValue = fruitGrowthAmount * MM_PER_INCH
  } else if (shouldConvertINToUM) {
    convertedValue = fruitGrowthAmount * (MM_PER_INCH * UM_PER_MM)
  } else if (shouldConvertUMToMM) {
    convertedValue = fruitGrowthAmount / UM_PER_MM
  } else if (shouldConvertUMToIN) {
    convertedValue = fruitGrowthAmount / (MM_PER_INCH * UM_PER_MM)
  } else {
    convertedValue = fruitGrowthAmount
  }

  return convertedValue
}

const fruitDendrometerFunctionMaker =
  (option: TFruitGrowthOptions) =>
  (
    fruitGrowthAmount: number | null = null,
    {
      decimalPlaces,
      inputUnit = 'µm',
      outputUnit = userDetailsStore.getState().rain === 'IMPERIAL' ? 'in' : 'mm',
    }: {
      decimalPlaces?: number
      inputUnit?: TFruitGrowthUnit
      outputUnit?: TFruitGrowthUnit
    } = {},
  ) => {
    const defaultNumberOfDecimalPlaces = outputUnit === 'in' ? 3 : 2
    const decimalPlacesToUse = decimalPlaces ?? defaultNumberOfDecimalPlaces
    const convertedValue = getConvertedValue(inputUnit, outputUnit, fruitGrowthAmount)

    let unitSymbol: string

    if (outputUnit === 'in') {
      unitSymbol = translate.measurements.inches.unit()
    } else if (outputUnit === 'mm') {
      unitSymbol = translate.measurements.millimeters.unit()
    } else {
      unitSymbol = translate.measurements.micrometers.unit()
    }

    return {
      categoryTitle: () => {
        return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Fruit Growth'),
          unitSymbol,
        })
      },
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Fruit Growth'),
      defaultNumberOfDecimalPlaces: () => defaultNumberOfDecimalPlaces,
      suffix: () => unitSymbol,
      shortTitle: () => {
        if (option === 'fruitGrowth') {
          return translate.phrases.banyanApp('Fruit Growth')
        }

        if (option === 'fruitSize') {
          return translate.phrases.banyanApp('Fruit Size')
        }

        return translate.phrases.banyanApp('Target Fruit Size')
      },
      title: () => {
        if (option === 'fruitGrowth') {
          return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
            label: translate.phrases.banyanApp('Fruit Growth'),
            unitSymbol,
          })
        }

        if (option === 'fruitSize') {
          return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
            label: translate.phrases.banyanApp('Historical Fruit Size'),
            unitSymbol,
          })
        }

        return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Target Fruit Size'),
          unitSymbol,
        })
      },
      titleWithoutUnit: () => {
        if (option === 'fruitGrowth') {
          return translate.phrases.banyanApp('Fruit Growth')
        }

        if (option === 'fruitSize') {
          return translate.phrases.banyanApp('Historical Fruit Size')
        }

        return translate.phrases.banyanApp('Target Fruit Size')
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlacesToUse)
      },
      valueAsString: () => {
        if (outputUnit === 'in') {
          return translate.measurements.inches.value(convertedValue, decimalPlacesToUse)
        } else if (outputUnit === 'mm') {
          return translate.measurements.millimeters.value(convertedValue, decimalPlacesToUse)
        } else {
          return translate.measurements.micrometers.value(convertedValue, decimalPlacesToUse)
        }
      },
      valueWithSuffix: () => {
        if (outputUnit === 'in') {
          return translate.measurements.inches.valueWithUnit(convertedValue, decimalPlacesToUse)
        } else if (outputUnit === 'mm') {
          return translate.measurements.millimeters.valueWithUnit(convertedValue, decimalPlacesToUse)
        } else {
          return translate.measurements.micrometers.valueWithUnit(convertedValue, decimalPlacesToUse)
        }
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

export const fruitGrowth: Record<
  TFruitGrowthOptions,
  (
    fruitGrowthAmount?: number | null,
    option?: TUnitConverterOptionsWithUnits<TFruitGrowthUnit>,
  ) => TUnitConverterTypicalReturn
> = {
  fruitSize: fruitDendrometerFunctionMaker('fruitSize'),
  fruitGrowth: fruitDendrometerFunctionMaker('fruitGrowth'),
  fruitSizeTarget: fruitDendrometerFunctionMaker('fruitSizeTarget'),
}
