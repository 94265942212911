import type { GridTableContentSection } from 'components/GridTable/types'
import type { StackedChartSection } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const propertyLacksPermissionSectionMaker = ({
  id,
  propertyId = Number(selectedFieldAssetsStore.getState().property),
  title,
  titleChildren,
}: {
  id: StackedChartSection['id'] & GridTableContentSection['id']
  propertyId?: number
  title: StackedChartSection['title'] & GridTableContentSection['title']
  titleChildren: StackedChartSection['titleChildren'] & GridTableContentSection['titleChildren']
}): StackedChartSection & GridTableContentSection => {
  const propertyName =
    fieldAssetStore.getState()?.properties?.[propertyId]?.propertyName ??
    translate.phrases.banyanApp('Unknown Property')

  return {
    id,
    title,
    titleChildren,
    items: [],
    fullWidthItems: [
      {
        height: 150,
        content: (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {translate.phrases.banyanApp('You do not have access to this feature for {{propertyName}}', {
              propertyName,
            })}
          </div>
        ),
      },
    ],
    defaultCollapsed: true,
  }
}
