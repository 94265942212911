import { Stack, Text } from '@mantine/core'
import { translate } from 'i18n/i18n'

export const FinalReview = ({
  children,
  footer,
}: {
  children: React.ReactElement
  footer: React.ReactElement
}) => {
  return (
    <div css={{ padding: 10 }}>
      <Stack>
        <strong>{translate.phrases.placeholder('Review and Finalize Install')}</strong>
        <Text>{translate.phrases.placeholder('Double check that the equipment is installed properly.')}</Text>
        {children}
      </Stack>
      {footer}
    </div>
  )
}
