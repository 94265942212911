import type CssType from 'csstype'
import React from 'react'
import ReactDOM from 'react-dom/client'

// TODO make ComponentProps type not allow "optional" (undefined) props, because there seems to be an issue with React.cloneElement and/or reactRoot.render(reactNode) not picking up the change when a props goes from some value to undefined
export type ReactComponentOverlayView<ComponentProps extends Record<string, unknown>> =
  google.maps.OverlayView & {
    render: (props: ComponentProps) => void
  }

export type PREVENT =
  | 'MAP_HITS' // prevent zooming on double click, but allow zooming on mouse scroll
  | 'MAP_HITS_AND_GESTURES' // prevent zooming on double click and mouse scroll

const createDiv = (o: {
  maps: typeof google.maps
  prevent?: PREVENT
  transform?: CssType.Property.Transform
}) => {
  const div = document.createElement('div')

  div.style.position = 'absolute'

  div.style.transform = o.transform ?? 'translate(-50%, -50%)'

  const prevent = o.prevent ?? 'MAP_HITS'

  if (prevent === 'MAP_HITS_AND_GESTURES') {
    o.maps.OverlayView.preventMapHitsAndGesturesFrom(div)
  } else if (prevent === 'MAP_HITS') {
    o.maps.OverlayView.preventMapHitsFrom(div)
  }

  return div
}

// TODO refactor out the component, can be established once higher up
export const makeReactComponentOverlayView = <ComponentProps extends Record<string, unknown>>(options: {
  maps: typeof google.maps
  map: google.maps.Map
  component: React.FC<ComponentProps>
  latLng: google.maps.LatLng | google.maps.LatLngLiteral
  prevent?: PREVENT
  transform?: CssType.Property.Transform
}): ReactComponentOverlayView<ComponentProps> => {
  const div = createDiv(options)

  let reactNode: React.FunctionComponentElement<ComponentProps> | null = null
  let reactRoot: ReactDOM.Root | null = null

  const overlayView: ReactComponentOverlayView<ComponentProps> = Object.create(new options.maps.OverlayView())

  overlayView.setMap(options.map)

  overlayView.onAdd = () => {
    overlayView.getPanes()?.overlayMouseTarget.appendChild(div)
  }

  overlayView.draw = () => {
    const divPixel = overlayView.getProjection()?.fromLatLngToDivPixel(options.latLng)

    if (divPixel) {
      div.style.left = `${divPixel.x}px`

      div.style.top = `${divPixel.y}px`
    }
  }

  overlayView.render = (props: ComponentProps) => {
    // set the map, because it may have been removed if off-screen, etc.
    if (!overlayView.getMap()) {
      overlayView.setMap(options.map)
    }

    if (!reactNode) {
      reactNode = React.createElement(options.component, props)
    } else {
      reactNode = React.cloneElement(reactNode, props)
    }

    if (
      !reactRoot ||
      // @ts-ignore
      !reactRoot._internalRoot
      // https://github.com/facebook/react/blob/8e2bde6f2751aa6335f3cef488c05c3ea08e074a/packages/react-dom/src/client/ReactDOMRoot.js#L31
      // Note: _internalRoot is null when reactRoot is unmounted. (The unmount() happens in the onRemove below)
      // As far as I can tell, there is no other way check if reactRoot is unmounted, so we have to do it like this.
    ) {
      reactRoot = ReactDOM.createRoot(div)
    }

    reactRoot.render(reactNode)

    overlayView.draw()
  }

  overlayView.onRemove = () => {
    reactRoot?.unmount()

    div.parentNode?.removeChild(div)
  }

  return overlayView
}
