import { translate } from 'i18n/i18n'
import { colors, ndviChangeHeatmapScale, ndviStaticHeatmapScale } from 'settings/colors'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

export const NdviHeatmapRow = () => {
  const selectedNdviValueType = mapControlsStore.useSelector((s) => {
    return s.mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi?.valueType
  })

  const isMeasuringChange =
    selectedNdviValueType === 'imageryOverlayNDVIChange_sinceLastImage' ||
    selectedNdviValueType === 'imageryOverlayNDVIChange_sinceLastMonth'

  return (
    <div css={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div css={{ width: '100%', background: colors.grey50, borderRadius: 3, display: 'flex', padding: 10 }}>
        {(isMeasuringChange ? ndviChangeHeatmapScale : ndviStaticHeatmapScale).map((item, index) => (
          <div key={index} css={{ flex: 1, height: 12, background: item }} />
        ))}
      </div>
      <div
        css={{
          marginTop: 6,
          fontSize: 12,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {isMeasuringChange
            ? translate.phrases.banyanApp('Decreased')
            : translate.phrases.banyanApp('Absent')}
        </div>
        <div>
          {isMeasuringChange
            ? translate.phrases.banyanApp('Increased')
            : translate.phrases.banyanApp('Dense')}
        </div>
      </div>
    </div>
  )
}
