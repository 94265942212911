import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import type { TNodeDevices, TNodeServiceIssueSummary, TNodeType } from '../types'
import { TServiceIssueStatus } from '../types'
import { getNodeTypeLabel } from './getNodeTypeLabel'

export function getServiceIssueTitle(
  node: { nodeType: TNodeType; devices?: TNodeDevices },
  serviceIssue: TNodeServiceIssueSummary,
): string {
  if (serviceIssue.device) {
    const deviceInfo = serviceIssue.device.split('|').map((item) => item.trim())
    const source = deviceInfo[0].replace('_', '-')

    return `${source} - ${serviceIssue.nodeServiceIssueTypeTitle}`
  } else {
    const nodeTypeLabel = getNodeTypeLabel(node.nodeType)

    return `${nodeTypeLabel} - ${serviceIssue.nodeServiceIssueTypeTitle}`
  }
}

export function getServiceIssueStatusLabel(status: TServiceIssueStatus): string {
  switch (status) {
    case TServiceIssueStatus.CREATED:
      return translate.phrases.placeholder('Issue Detected - Not Reviewed')

    case TServiceIssueStatus.FLAGGED_FOR_SERVICE:
      return translate.phrases.placeholder('Flagged for Service - Action Required')

    case TServiceIssueStatus.SERVICE_COMPLETED:
      return translate.phrases.placeholder('Service Completed - Review Required')

    case TServiceIssueStatus.CLOSED_FIXED:
      return translate.phrases.placeholder('Issue Closed - Fixed')

    case TServiceIssueStatus.CLOSED_UNFIXED:
      return translate.phrases.placeholder('Issue Closed - Unfixed')

    default: {
      Sentry.captureException(new Error(`Service issue status: "${status}" has not been implemented`))

      return status
    }
  }
}
