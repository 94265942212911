import { Network } from '@capacitor/network'
import { omit } from 'lodash'
import { hubConnectorSettings } from 'settings/hubConnectorSettings'
import { errorCodes, FailedRequestReason, OfflineRequestError } from './errorCodes'

const DEFAULT_TIMEOUT = 30000 // 30 seconds

/**
 *
 * @param {string} url - the url of the post request on our API
 * @param {object} body - payload body for the request
 * @param {object} params - optional additional arguments for the request (method, headers, etc.)
 * @returns {Promise<object>} a promise of the API response
 */
export const apiFetch = async <RequestType, ResponseType>({
  url,
  body,
  params,
}: {
  url: string // TODO: use routes' paths when they are available
  body: RequestType
  params?: Omit<RequestInit, 'body'> & { timeout?: number }
}): Promise<ResponseType> => {
  let serializedBody: string | object | undefined | null = null

  if (body) {
    if (typeof body === 'string' || body instanceof FormData) {
      serializedBody = body
    } else {
      serializedBody = JSON.stringify(body)
    }
  }

  const { timeout = DEFAULT_TIMEOUT } = params || {}
  const controller = new AbortController()
  const timeoutRef = setTimeout(() => controller.abort(), timeout)

  const requestOptions = {
    headers: {
      'Content-Type': body === null ? 'null' : 'application/json',
      ...(await hubConnectorSettings.getAuthHeader()),
      'app-name': hubConnectorSettings.apiFetchHeaderAppName,
      'Accept-Encoding': 'gzip',
      'referer-url': window.location.href,
      ...params?.headers,
    },
    method: params?.method || 'POST',
    body: serializedBody,
    ...omit(params, 'headers', 'method'),
    signal: controller.signal,
  } as RequestInit

  const urlToUse = `${hubConnectorSettings.apiUrl}${`/${url}`.replace(/\/\/+/g, '/')}` // add a regex to delete extra slashes

  //The promise returned from fetch() won't reject on HTTP errors even if the response is an HTTP 404 or 500. Instead,
  //as soon as the server responds with headers, the promise will resolve (with the ok property of the response set to
  //false if the response isn't in the range 200–299). The promise will only reject on network failure or if anything
  //prevented the request from completing.
  return fetch(urlToUse, requestOptions)
    .then(errorCodes)
    .catch(async (error) => {
      if (controller.signal.aborted) {
        throw new OfflineRequestError(FailedRequestReason.TIMEOUT)
      } else {
        const { connected } = await Network.getStatus()

        if (!connected) {
          throw new OfflineRequestError(FailedRequestReason.OFFLINE)
        } else {
          throw error
        }
      }
    })
    .finally(() => clearTimeout(timeoutRef))
}

export async function handleOfflineError(f: () => Promise<void>) {
  await f().catch((error: Error) => {
    if (error instanceof OfflineRequestError) {
      // eslint-disable-next-line no-console
      console.warn('Offline request error', error.stack)
    } else {
      throw error
    }
  })
}
