import { Button, useMantineTheme } from '@mantine/core'
import { hoverCssKey } from 'components/Button/_utils/getButtonComponent'
import React, { forwardRef } from 'react'
import type { MantineButtonProps } from '../../Button'

const Positive = forwardRef<HTMLButtonElement, MantineButtonProps>((props, ref) => {
  const theme = useMantineTheme()

  return (
    <Button
      ref={ref}
      css={{
        'background': theme.colors.white[0],
        'height': 40,
        'padding': '0 15px',
        'border': `2px solid ${theme.colors.green[0]}`,
        'fontStyle': 'normal',
        'fontWeight': 700,
        'fontSize': 16,
        'lineHeight': '21px',
        'color': theme.colors.green[0],
        [`&:not([disabled],[data-loading="true"]):${hoverCssKey}`]: {
          background: theme.colors.white[0],
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          color: theme.colors.green[0],
        },
        '&[disabled],&[data-loading="true"]': {
          background: theme.colors.white[0],
          border: `2px solid ${theme.colors.grey[1]}`,
          color: theme.colors.grey[2],
          boxShadow: 'none',
          svg: {
            fill: theme.colors.grey[3],
          },
        },
        '&[data-loading="true"]::before': {
          background: 'unset',
        },
      }}
      {...props}
    />
  )
})

Positive.displayName = 'Positive'

export { Positive }
