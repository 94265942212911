import type { Chart, Point } from 'highcharts'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

const getAxisExtremes = (chart: Chart) => {
  return {
    xAxisMin: chart.xAxis[0].min || 0,
    xAxisMax: chart.xAxis[0].max || 0,
  }
}

const calculateVisibleSum = (chart: Chart, firstForecastTimestamp: number) => {
  const forecastTimestamp = firstForecastTimestamp || +new Date()
  const { xAxisMin, xAxisMax } = getAxisExtremes(chart)

  return chart.series.map((s) => {
    const filteredPoints = s.points.reduce<Record<number, Point>>((acc, point) => {
      if (!acc[point.x]) {
        acc[point.x] = point
      }

      return acc
    }, {})

    let sum = 0
    let forecastedSum = 0
    let validDataForSum = false
    let validDataForForecastedSum = false

    Object.values(filteredPoints).forEach(function (p) {
      if (p.x >= xAxisMin && p.x <= xAxisMax && !isNil(p.y)) {
        if (p.x < forecastTimestamp) {
          sum += p.y

          validDataForSum = true
        } else {
          forecastedSum += p.y

          validDataForForecastedSum = true
        }
      }
    })

    return {
      sum: validDataForSum ? sum : null,
      forecastedSum: validDataForForecastedSum ? forecastedSum : null,
    }
  })
}

export const renderCumulativeCaption = (chart: Chart, firstForecastTimestamp: number) => {
  const visualSums = calculateVisibleSum(chart, firstForecastTimestamp)
  const rainSum = visualSums?.[0]?.sum
  const rainForecastedSum = visualSums?.[0]?.forecastedSum
  const totalSum = rainSum === null || rainForecastedSum === null ? rainSum : rainSum + rainForecastedSum
  const inputAndOutputUnit = userDetailsStore.getState().rain === 'IMPERIAL' ? 'in' : 'mm'
  const { xAxisMin, xAxisMax } = getAxisExtremes(chart)

  const precipitationConverter = (sum: number | null): string =>
    unitConverter
      .precipitation(sum, {
        inputUnit: inputAndOutputUnit,
        outputUnit: inputAndOutputUnit,
      })
      .valueWithSuffix()

  const rainSumTitle = translate.phrases.banyanApp('Total Volume')
  const rainForecastedSumTitle = translate.phrases.banyanApp('Forecasted')

  const rainSumValuesTemplate =
    xAxisMin < firstForecastTimestamp
      ? `<div style="display: flex; flex-direction: column; padding-right: 50px;">
          <div style="margin: 0;">${rainSumTitle}</div>
          <p style="margin: 0; font-weight: bold; font-size: 18px;">${precipitationConverter(totalSum)}</p>
        </div>`
      : ''

  const rainForecastedSumValuesTemplate =
    xAxisMax >= firstForecastTimestamp
      ? `<div style="display: flex; flex-direction: column; padding-left: 20px;">
          <div style="margin: 0;">${rainForecastedSumTitle}</div>
          <p style="margin: 0; font-weight: bold; font-size: 18px;">${precipitationConverter(
            rainForecastedSum,
          )}</p>
        </div>`
      : ''

  const showVerticalBar = rainSumValuesTemplate && rainForecastedSumValuesTemplate
  const verticalBar = showVerticalBar ? 'border-right: 2px solid grey;' : ''

  const template = `<div style="display: flex; margin-left: 15px;">
        <div style="flex: 1; ${verticalBar}">${rainSumValuesTemplate}</div>
        <div style="flex: 1">${rainForecastedSumValuesTemplate}</div>
      </div>`

  chart.setCaption({
    text: template,
  })
}
