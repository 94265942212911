import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconOvercastNightDrizzle = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 399 336')}>
      <g clipPath="url(#clip0_11_522)">
        <g clipPath="url(#clip1_11_522)">
          <g clipPath="url(#clip2_11_522)">
            <path
              d="M160.62 107.4C113.45 107.4 75.21 69.67 75.21 23.14C75.2306 16.0029 76.1683 8.89807 78 2C35.27 6.61 2 42.33 2 85.73C2 132.27 40.24 170 87.41 170C106.215 170.073 124.515 163.919 139.455 152.499C154.395 141.079 165.135 125.035 170 106.87C166.885 107.214 163.754 107.39 160.62 107.4Z"
              fill="url(#paint0_linear_11_522)"
              stroke="#72B9D5"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <g clipPath="url(#clip3_11_522)">
            <g clipPath="url(#clip4_11_522)">
              <path
                d="M199.34 144.18C199.34 152.784 202.758 161.035 208.841 167.119C211.854 170.131 215.43 172.521 219.366 174.151C223.301 175.781 227.52 176.62 231.78 176.62H361.53V176.5C362.3 176.5 363.06 176.62 363.84 176.62C372.493 176.622 380.836 173.395 387.236 167.571C393.636 161.747 397.633 153.745 398.444 145.13C399.256 136.514 396.824 127.906 391.624 120.99C386.425 114.073 378.831 109.345 370.33 107.73C371.335 101.644 370.58 95.3979 368.155 89.7262C365.73 84.0544 361.736 79.1934 356.641 75.715C351.547 72.2366 345.566 70.2857 339.4 70.0917C333.235 69.8978 327.143 71.469 321.84 74.6203C315.796 64.675 306.383 57.2296 295.313 53.6388C284.244 50.0481 272.253 50.5505 261.522 55.0547C250.791 59.5589 242.034 67.7656 236.844 78.1817C231.653 88.5977 230.374 100.531 233.24 111.81C232.75 111.81 232.24 111.74 231.78 111.74C227.52 111.74 223.301 112.579 219.366 114.21C215.43 115.84 211.854 118.229 208.841 121.242C202.758 127.325 199.34 135.577 199.34 144.18Z"
                fill="url(#paint1_linear_11_522)"
                stroke="#848B98"
                strokeMiterlimit="10"
              />
            </g>
            <g clipPath="url(#clip5_11_522)">
              <path
                d="M291.84 131C290.99 131 290.16 131.09 289.31 131.13C294.134 111.702 291.853 91.1901 282.879 73.2967C273.905 55.4034 258.829 41.3083 240.373 33.5563C221.918 25.8044 201.299 24.9065 182.239 31.0248C163.179 37.1431 146.935 49.8744 136.44 66.9199C127.947 61.8427 118.26 59.11 108.366 59.0009C98.4722 58.8918 88.7266 61.4103 80.1244 66.2991C71.5222 71.1879 64.3714 78.272 59.4022 86.828C54.433 95.3841 51.8234 105.106 51.8397 115C51.8617 118.043 52.1292 121.08 52.6397 124.08C37.9919 126.897 24.9187 135.069 15.9697 147.003C7.02063 158.936 2.83752 173.776 4.23615 188.626C5.63479 203.477 12.5149 217.274 23.5346 227.327C34.5543 237.379 48.9235 242.967 63.8397 243C65.1897 243 66.5097 242.89 67.8397 242.8V243H291.84C306.692 243 320.936 237.1 331.438 226.598C341.94 216.096 347.84 201.852 347.84 187C347.84 172.148 341.94 157.904 331.438 147.402C320.936 136.9 306.692 131 291.84 131Z"
                fill="url(#paint2_linear_11_522)"
                stroke="#CAD6E8"
                strokeWidth="6"
                strokeMiterlimit="10"
              />
            </g>
          </g>
        </g>
        <g clipPath="url(#clip6_11_522)">
          <path
            d="M132 255C129.878 255 127.843 254.157 126.343 252.657C124.843 251.157 124 249.122 124 247V235C124 232.878 124.843 230.843 126.343 229.343C127.843 227.843 129.878 227 132 227C134.122 227 136.157 227.843 137.657 229.343C139.157 230.843 140 232.878 140 235V247C140 249.122 139.157 251.157 137.657 252.657C136.157 254.157 134.122 255 132 255Z"
            fill="url(#paint3_linear_11_522)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
          <path
            d="M188 335C185.878 335 183.843 334.157 182.343 332.657C180.843 331.157 180 329.122 180 327V315C180 312.878 180.843 310.843 182.343 309.343C183.843 307.843 185.878 307 188 307C190.122 307 192.157 307.843 193.657 309.343C195.157 310.843 196 312.878 196 315V327C196 329.122 195.157 331.157 193.657 332.657C192.157 334.157 190.122 335 188 335Z"
            fill="url(#paint4_linear_11_522)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
          <path
            d="M244 285C241.878 285 239.843 284.157 238.343 282.657C236.843 281.157 236 279.122 236 277V265C236 262.878 236.843 260.843 238.343 259.343C239.843 257.843 241.878 257 244 257C246.122 257 248.157 257.843 249.657 259.343C251.157 260.843 252 262.878 252 265V277C252 279.122 251.157 281.157 249.657 282.657C248.157 284.157 246.122 285 244 285Z"
            fill="url(#paint5_linear_11_522)"
            stroke="#0A5AD4"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_522"
          x1="34.67"
          y1="18.56"
          x2="119.21"
          y2="164.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_522"
          x1="251.58"
          y1="60.6203"
          x2="332.2"
          y2="200.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9CA3AF" />
          <stop offset="0.45" stopColor="#9CA3AF" />
          <stop offset="1" stopColor="#6B7280" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_522"
          x1="100.29"
          y1="54.6799"
          x2="233.48"
          y2="285.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F7FE" />
          <stop offset="0.45" stopColor="#F3F7FE" />
          <stop offset="1" stopColor="#DEEAFB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_522"
          x1="125.395"
          y1="229.575"
          x2="138.593"
          y2="252.432"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11_522"
          x1="181.398"
          y1="309.569"
          x2="194.598"
          y2="332.436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11_522"
          x1="237.396"
          y1="259.574"
          x2="250.595"
          y2="282.431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0B65ED" />
          <stop offset="0.45" stopColor="#0A5AD4" />
          <stop offset="1" stopColor="#0950BC" />
        </linearGradient>
        <clipPath id="clip0_11_522">
          <rect width="399" height="336" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_522">
          <rect width="398.84" height="246" fill="white" />
        </clipPath>
        <clipPath id="clip2_11_522">
          <rect width="172" height="172" fill="white" />
        </clipPath>
        <clipPath id="clip3_11_522">
          <rect width="398" height="222" fill="white" transform="translate(0.839844 24)" />
        </clipPath>
        <clipPath id="clip4_11_522">
          <rect width="200.26" height="126.12" fill="white" transform="translate(198.84 51)" />
        </clipPath>
        <clipPath id="clip5_11_522">
          <rect width="350" height="222" fill="white" transform="translate(0.839844 24)" />
        </clipPath>
        <clipPath id="clip6_11_522">
          <rect width="129" height="109" fill="white" transform="translate(123.5 226.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
