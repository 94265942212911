import { ActionIcon } from '@mantine/core'
import { IconSmsDisabled } from 'components/icons/IconSmsDisabled'
import { IconSmsEnabled } from 'components/icons/IconSmsEnabled'
import { ICON_SIZE } from './ContactList'

export const SmsIcon = ({
  isEnabled,
  handleSmsIconClick,
}: {
  isEnabled?: boolean
  handleSmsIconClick: () => void
}) => {
  return (
    <ActionIcon onClick={handleSmsIconClick}>
      <div css={{ fontSize: ICON_SIZE, display: 'flex', alignItems: 'center' }}>
        {isEnabled ? <IconSmsEnabled /> : <IconSmsDisabled />}
      </div>
    </ActionIcon>
  )
}
