import { isNil } from 'lodash'
import {
  generateDefaultHeatmapHighLowColors,
  mapControlsStore,
} from 'stores/mapControlsStore/mapControlsStore'
import { sortNumber } from 'utils/sortNumber'

export const setHeatmapExtremesFromArrayOfValues = ({
  heatmapColoring = generateDefaultHeatmapHighLowColors(),
  values,
}: {
  heatmapColoring?: ReturnType<typeof mapControlsStore['getState']>['heatmapColoring']
  values: (number | null)[]
}) => {
  const allValuesCleanedAndSorted = values.filter((v) => !isNil(v)).sort(sortNumber) as number[]

  if (allValuesCleanedAndSorted.length) {
    mapControlsStore.setState((s) => ({
      ...s,
      heatmapColoring,
      heatmapExtremes: s.manualExtremes
        ? s.heatmapExtremes
        : {
            min: allValuesCleanedAndSorted[0],
            max: allValuesCleanedAndSorted[allValuesCleanedAndSorted.length - 1],
          },
    }))
  } else {
    mapControlsStore.setState((s) => ({ ...s, heatmapColoring }))
  }
}
