import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { smallStore } from 'stores/smallStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { userCanCreateAlerts } from 'utils/userCanCreateAlerts'
import { userCanCreateReports } from 'utils/userCanCreateReports'
import { useScreenSize } from 'utils/useScreenSize'
import { userHasAnyAdminPermissions } from '../Admin/Admin'
import { UserSettingsMenuNarrowScreen } from './NarrowScreen/UserSettingsMenuNarrowScreen'
import type { TCurrentTabSelection, TSettingItem, TSettingSection } from './types'
import { UserSettingsMenuWideScreen } from './WideScreen/UserSettingsMenuWideScreen'

const DURATION_MILLISECOND = 3000

export const UserSettingsMenu = () => {
  const onClose = useCallback(() => {
    smallStore.setState((s) => ({
      ...s,
      showSettingsModal: false,
    }))

    // to prevent the settings menu changing as it's on its way out
    setTimeout(() => {
      smallStore.setState((s) => ({
        ...s,
        settingsModalSelectedTab: 'mainMenu',
      }))
    }, 150)
  }, [])

  const setCurrentTab = useCallback(
    (newTab: TCurrentTabSelection) =>
      smallStore.setState((s) => ({ ...s, settingsModalSelectedTab: newTab })),
    [],
  )

  const opened = smallStore.useSelector((s) => s.showSettingsModal)
  const currentTab = smallStore.useSelector((s) => s.settingsModalSelectedTab)
  const [searchText, setSearchText] = useState('')
  const { isWideScreen } = useScreenSize()

  useEffect(() => {
    if (opened) {
      setSearchText('')
    }
  }, [opened])

  const settingSections: TSettingSection[] = [
    {
      items: [
        {
          tabKey: 'myDetails',
          label: translate.phrases.banyanApp('Account Details'),
        },
      ],
    },
  ]

  const includeAlerts = userCanCreateAlerts()
  const includeReports = userCanCreateReports()

  const includeWaterManagement =
    checkAuthorization({ permission: 'EDIT_IRRIGATION_PLANNER', entity: 'FOR_ANY_ENTITY' }) ||
    checkAuthorization({ permission: 'EDIT_EVAPOTRANSPIRATION_COEFFICIENTS', entity: 'FOR_ANY_ENTITY' })

  if (includeAlerts || includeReports) {
    settingSections.push({
      items: [
        {
          tabKey: 'contactsAndGroups',
          label: translate.phrases.banyanApp('Contacts'),
        },
      ],
    })
  }

  settingSections.push({
    items: [
      {
        tabKey: 'unitSettings',
        label: translate.phrases.banyanApp('Unit Settings'),
      },
    ],
  })

  settingSections.push({
    items: [{ tabKey: 'presets', label: translate.phrases.banyanApp('Views') }],
  })

  if (includeAlerts) {
    settingSections.push({
      items: [
        {
          tabKey: 'alerts',
          label: translate.phrases.banyanApp('Alerts'),
        },
      ],
    })
  }

  if (includeReports) {
    settingSections.push({
      items: [
        {
          tabKey: 'reports',
          label: translate.phrases.banyanApp('Reports'),
        },
      ],
    })
  }

  if (includeWaterManagement) {
    const items: TSettingItem[] = []

    if (checkAuthorization({ permission: 'EDIT_IRRIGATION_PLANNER', entity: 'FOR_ANY_ENTITY' })) {
      items.push({
        tabKey: 'irrigationScheduler',
        label: translate.phrases.banyanApp('Irrigation Scheduler'),
      })

      items.push({
        tabKey: 'emitterConfiguration',
        label: translate.phrases.banyanApp('Emitter Configuration'),
      })
    }

    if (
      checkAuthorization({ permission: 'EDIT_EVAPOTRANSPIRATION_COEFFICIENTS', entity: 'FOR_ANY_ENTITY' })
    ) {
      items.push({
        tabKey: 'evapotranspiration',
        label: translate.phrases.banyanApp('Evapotranspiration'),
      })
    }

    settingSections.push({
      sectionLabel: translate.phrases.banyanApp('Water Management'),
      items,
    })
  }

  if (checkAuthorization({ permission: 'API_TOKEN_MANAGE_AND_USE', entity: '*' })) {
    settingSections.push({
      items: [
        {
          tabKey: 'semiosApi',
          label: translate.phrases.banyanApp('Semios API'),
        },
      ],
    })
  }

  const hasAnyAdminPermissions = userHasAnyAdminPermissions()

  if (Object.values(hasAnyAdminPermissions).some((v) => v)) {
    settingSections.push({
      items: [
        {
          tabKey: 'admin',
          label: translate.phrases.banyanApp('Admin'),
        },
      ],
    })
  }

  const filteredSettingItems = useMemo(() => {
    const result: TSettingSection[] = []

    settingSections.forEach((section) => {
      const items = Array.isArray(section.items) ? section.items : [section.items]
      const filteredItems = arrayOfObjectsSearch(items, searchText, ['label'])

      if (filteredItems.length > 0) {
        result.push({
          ...section,
          items: filteredItems,
        })
      }

      if (section.sectionLabel && filteredItems.length === 0) {
        const isLabelMatched = searchStringFromStringWithSpaces(searchText, section.sectionLabel)

        if (isLabelMatched) {
          result.push(section)
        }
      }
    })

    return result
  }, [searchText, settingSections])

  return (
    <ModalDrawer
      opened={opened}
      onClose={onClose}
      title={translate.phrases.banyanApp('Settings')}
      size={'70%'}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      {isWideScreen ? (
        <UserSettingsMenuWideScreen
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          durationMillisecond={DURATION_MILLISECOND}
          searchText={searchText}
          setSearchText={setSearchText}
          filteredSettingItems={filteredSettingItems}
        />
      ) : (
        <UserSettingsMenuNarrowScreen
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          durationMillisecond={DURATION_MILLISECOND}
          searchText={searchText}
          setSearchText={setSearchText}
          filteredSettingItems={filteredSettingItems}
        />
      )}
    </ModalDrawer>
  )
}
