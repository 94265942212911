import { Text, useMantineTheme } from '@mantine/core'
import { IconSensorConnected } from 'components/icons/IconSensorConnected'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { EDeviceStatus } from '../../types'

export const DeviceInstallationStatus: FC<{ status: EDeviceStatus | null }> = ({ status }) => {
  const theme = useMantineTheme()

  let label: string, color, icon

  switch (status) {
    case EDeviceStatus.PLANNED: {
      label = translate.phrases.placeholder('Planned')

      color = theme.colors.violet

      icon = undefined

      break
    }

    case EDeviceStatus.INSTALLED: {
      label = translate.phrases.placeholder('')

      color = theme.colors.gray

      icon = undefined

      break
    }

    case EDeviceStatus.DISCONNECTED: {
      label = translate.phrases.placeholder('No Signal')

      color = theme.colors.gray[5]

      icon = <IconSensorConnected />

      break
    }

    case EDeviceStatus.CONNECTED: {
      label = translate.phrases.placeholder('Reading')

      color = theme.colors.green

      icon = <IconSensorConnected />

      break
    }

    case EDeviceStatus.MISMATCHED: {
      label = translate.phrases.placeholder('Mismatched')

      color = theme.colors.orange

      icon = <IconSensorConnected />

      break
    }

    default: {
      label = ''

      color = theme.colors.gray

      icon = undefined
    }
  }

  return (
    <Text css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color }}>
      {label}

      <span
        css={{
          marginLeft: '4px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon}
      </span>
    </Text>
  )
}
