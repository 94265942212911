import type { EVENT_ID } from '../irrigationSchedulerStore'
import { irrigationSchedulerStore, TScheduledEventEnum } from '../irrigationSchedulerStore'

export const toggleEventForBatchDelete = (eventId: EVENT_ID) => {
  irrigationSchedulerStore.setState((isss) => {
    const events = { ...isss.batchDelete.events }

    if (events[eventId]) {
      delete events[eventId]
    } else {
      const scheduledEvent = isss.scheduledEvents[eventId]

      if (scheduledEvent && scheduledEvent.type === TScheduledEventEnum.SCHEDULED) {
        events[eventId] = { ...scheduledEvent, type: TScheduledEventEnum.SCHEDULED }
      } else if (scheduledEvent && scheduledEvent.type === TScheduledEventEnum.DRAFT_CREATE) {
        events[eventId] = { ...scheduledEvent, type: TScheduledEventEnum.DRAFT_CREATE }
      }
    }

    return {
      ...isss,
      batchDelete: {
        ...isss.batchDelete,
        events: events,
      },
    }
  })
}
