import { useState } from 'react'
import { MultiSelect } from '../../../../components/MultiSelect/MultiSelect'
import type { TOptions } from '../../../../components/MultiSelect/MultiSelect.types'

export const MultiSelectWithSection = () => {
  const recentlyUsedOptions = ['1001', '1003']
  const [selectedOptions, setSelectedOptions] = useState<TOptions[]>([])

  return (
    <>
      <h4>Multi Select with Sections</h4>
      <MultiSelect
        data={[
          { id: '1001', label: 'Apple', value: 'apple', group: 'Fruits' },
          { id: '1002', label: 'Orange', value: 'orange', group: 'Fruits' },
          { id: '1003', label: 'Grapes', value: 'grapes', group: 'Fruits' },
          { id: '1004', label: 'Carrot', value: 'carrot', group: 'Veggies' },
          { id: '1005', label: 'Onion', value: 'onion', group: 'Veggies' },
        ]}
        topSectionLabel={'RECENTLY USED'}
        topSectionItems={recentlyUsedOptions}
        bottomSectionLabel={'ALL ITEMS'}
        selectedData={selectedOptions}
        onChange={(selectedValues) => setSelectedOptions(selectedValues)}
        groupOptions={true}
        placeholder="Select Attribute"
        noOfValuesToDisplay={2}
        selectedValueDecorator={(value, index) => {
          return index && index >= 2 ? `+ ${value}` : `${value}`
        }}
      />
    </>
  )
}
