import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { mantineSelectSearchFilterProp } from 'utils/mantineSelectSearchFilterProp'
import { getStyles } from './getStyles'

export const OrganizationSelect = ({
  clearable = true,
  data,
  disabled = false,
  onChange,
  selectedOrganizationId,
}: {
  clearable?: boolean
  data: { value: string; label: string }[]
  disabled?: boolean
  onChange?: (propertyId: number) => void
  selectedOrganizationId: number | null
}) => {
  return (
    <Select
      autoComplete="off"
      allowDeselect={false}
      css={{ borderRadius: 3 }}
      clearable={clearable}
      data={data}
      disabled={disabled}
      filter={mantineSelectSearchFilterProp}
      onChange={
        onChange
          ? (propertyId) => {
              onChange(Number(propertyId))
            }
          : undefined
      }
      searchable
      size="sm"
      spellCheck="false"
      placeholder={translate.phrases.banyanApp('Select an Organization')}
      styles={getStyles(clearable, disabled)}
      value={String(selectedOrganizationId)}
      zIndex={1}
    />
  )
}
