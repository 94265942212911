import type { Point } from '@turf/helpers'
import React, { useMemo } from 'react'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import type { TPlannedGateway, TPlannedNode } from '../../types'
import { TNodeStatus } from '../../types'
import { getNodeDeviceTypes } from '../_utils/getNodeDeviceTypes'
import { NodeRenderer } from './renderers/NodeRenderer'

interface PlannedEquipmentLayerProps {
  plannedNodes: Array<TPlannedNode | TPlannedGateway>
}

export const PlannedEquipmentLayer: React.FC<PlannedEquipmentLayerProps> = ({ plannedNodes }) => {
  const selectedEquipmentTypes = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentTypes,
  )

  const selectedNodeDevices = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getMapEquipmentDevices,
  )

  const selectedEquipmentId = serviceCenterStore.useSelector(
    serviceCenterStore.selectors.getSelectedEquipmentId,
  )

  const shapedNodes = useMemo(() => {
    return plannedNodes.map((plannedNode) => ({
      id: plannedNode.id,
      nodeType: plannedNode.nodeType,
      deviceTypes: (plannedNode as TPlannedNode).devices
        ? getNodeDeviceTypes((plannedNode as TPlannedNode).devices)
        : [],
      geometry: JSON.parse(plannedNode.location as string) as Point,
      status: TNodeStatus.PLANNED,
    }))
  }, [plannedNodes])

  return (
    <NodeRenderer
      nodes={shapedNodes}
      selectedNodeId={selectedEquipmentId}
      dependencies={[selectedEquipmentTypes, selectedNodeDevices, selectedEquipmentId]}
    />
  )
}
