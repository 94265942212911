import type {
  TIrrigationSchedulerStoreState,
  TIrrigationZoneEmitterWithCurrentEmitterStatus,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import type { TFieldAssetStoreState } from 'stores/fieldAssetStore'
import { sortByKey } from 'utils/sortByKey'
import { sortByEmitterType } from '../../sortByEmitterType'

export const getVisibleIrrigationZoneEmitters = (
  fass: TFieldAssetStoreState,
  isss: TIrrigationSchedulerStoreState,
): TIrrigationZoneEmitterWithCurrentEmitterStatus[] => {
  const irrigationZoneEmitters =
    fass.properties?.[Number(isss.selectedPropertyId)]?.irrigationZoneEmitters || {}

  if (isss.selectedIrrigationZoneEmitterIds.length === 0) {
    return Object.entries(irrigationZoneEmitters)
      .map(([irrigationZoneEmitterId, irrigationZoneEmitter]) => ({
        ...irrigationZoneEmitter,
        irrigationZoneEmitterId: irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
        currentEmitterStatus:
          isss.currentEmitterStatus[irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId],
      }))
      .sort(sortByEmitterType)
      .sort(sortByKey('name'))
  }

  return Object.entries(irrigationZoneEmitters)
    .reduce<TIrrigationZoneEmitterWithCurrentEmitterStatus[]>(
      (acc, [irrigationZoneEmitterId, irrigationZoneEmitter]) => {
        if (
          isss.selectedIrrigationZoneEmitterIds.includes(
            irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
          )
        ) {
          acc.push({
            ...irrigationZoneEmitter,
            irrigationZoneEmitterId: irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
            currentEmitterStatus:
              isss.currentEmitterStatus[
                irrigationZoneEmitterId as TFieldAssetKeyTypes.TIrrigationZoneEmitterId
              ],
          })
        }

        return acc
      },
      [],
    )
    .sort(sortByEmitterType)
    .sort(sortByKey('name'))
}
