import { Input, Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import type { TCameraOrientation } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { LuresMultiSelect } from 'App/ServiceCenter/Shared/LuresMultiSelect'
import { NodeInfoHeader } from 'App/ServiceCenter/Shared/NodeInfoHeader/NodeInfoHeader'
import type { NodeConfigUpdateMeta } from 'App/ServiceCenter/utils/api/serviceCenterNodeConfigSet'
import { updateNodeConfig } from 'App/ServiceCenter/utils/updateNodeConfig'
import { translate } from 'i18n/i18n'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { showNotification } from 'utils/showNotification'
import { serviceCenterStore } from '../../store/serviceCenterStore'
import type { TActiveNode } from '../../types'
import { ConsumableType } from '../../types'
import { Footer } from '../Footer/Footer'

interface FormValues {
  lureIds: number[]
  insectId: number | null
  cameraOrientation: TCameraOrientation | null
}

interface Props {
  onClose: () => void
  onSubmit: () => void
}

export const TrapConfiguration: React.FC<Props> = ({ onClose, onSubmit }) => {
  const selectedNode = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentNode)
  const [isSaving, setIsSaving] = useState(false)
  const allInsects = fieldAssetStore.useSelector((s) => s.insects)
  const propertyId = (selectedNode as TActiveNode)?.propertyId
  const luresKeyById = serviceCenterStore.useSelector(serviceCenterStore.selectors.getLures)

  const getDefaultLures = () => {
    if (!_.isEmpty((selectedNode as TActiveNode)?.trap?.lures.active)) {
      return Object.values((selectedNode as TActiveNode)?.trap?.lures.active || {}).map((lure) => lure.lureId)
    } else if (!_.isEmpty((selectedNode as TActiveNode)?.trap?.lures.planned)) {
      return Object.values((selectedNode as TActiveNode)?.trap?.lures.planned || {}).map(
        (lure) => lure.lureId,
      )
    } else {
      return []
    }
  }

  const form = useForm<FormValues>({
    initialValues: {
      lureIds: getDefaultLures(),
      insectId: (selectedNode as TActiveNode)?.trap?.targetInsectId || null,
      cameraOrientation: (selectedNode as TActiveNode)?.trap?.cameraOrientation || 'HORIZONTAL',
    },
  })

  if (!allInsects || !selectedNode) return null

  const insectOptions = _.sortBy(
    Object.values(allInsects)
      .filter((insect) => insect.enabled)
      .map((insect) => ({ label: insect.name, value: insect.insectId })),
    'label',
  )

  const handleSubmit = async () => {
    if (!propertyId) return

    setIsSaving(true)

    const {
      values: { lureIds, insectId, cameraOrientation },
    } = form

    try {
      const payload = {
        nodeIdentifiers: [(selectedNode as TActiveNode).nodeIdentifier],
        propertyId,
        nodeType: (selectedNode as TActiveNode).nodeType,
        trap: {
          targetInsectId: insectId || null,
          cameraOrientation,
          lureIds,
          consumableTypes: [ConsumableType.LURE],
          consumableOperationType: lureIds.length ? 'SET' : 'REMOVE',
        },
      } as NodeConfigUpdateMeta

      await updateNodeConfig(payload)

      onSubmit()

      onClose()
    } catch (error) {
      setIsSaving(false)

      let errorMessage: string = error as string

      if (error instanceof Error) {
        errorMessage = error.message
      }

      notifications.show({ title: translate.phrases.placeholder('Error'), message: errorMessage })
    }

    setIsSaving(false)
  }

  useEffect(() => {
    handleOnValuesChange(form.values)
  }, [form.values])

  const handleOnValuesChange = (values: FormValues) => {
    const {
      lureIds: [primaryLureId],
      cameraOrientation,
    } = values

    if (primaryLureId && luresKeyById[primaryLureId].trapCameraOrientation !== cameraOrientation) {
      showNotification({
        type: 'warning',
        message: translate.phrases.placeholder(
          `The default camera orientation for ${luresKeyById[primaryLureId].lureName} is ${luresKeyById[primaryLureId].trapCameraOrientation}.`,
        ),
      })
    }
  }

  return (
    <div css={{ padding: 20 }}>
      <NodeInfoHeader node={selectedNode as TActiveNode} />
      <Input.Wrapper
        id="insectId"
        label={translate.phrases.placeholder('Target Pest')}
        css={{ marginTop: 20 }}
      >
        <Select
          clearable
          css={{ marginBottom: '16px' }}
          placeholder={translate.phrases.placeholder('Target Pest')}
          //@ts-ignore data prop takes only string as value but it works with number as well
          data={insectOptions}
          {...form.getInputProps('insectId')}
          onChange={(value) => {
            form.getInputProps('insectId').onChange(value)

            // when insect has changed, clear lures
            form.setFieldValue('lureIds', [])
          }}
          searchable
          styles={
            form.values.insectId
              ? // if <Select /> has "clearable" prop, MANTINE_SELECT_RIGHT_ICON_CHANGER doesn't work when the value is selected
                // so we need to modify the style manually here
                {
                  root: {
                    '& [aria-expanded="true"]': {
                      '& .mantine-Select-rightSection': {
                        transform: 'rotate(0deg)',
                      },
                    },
                  },
                  rightSection: {
                    transition: '0.3s ease all',
                    transform: 'rotate(180deg)',
                    marginRight: -3,
                    svg: {
                      color: `${colors.midnight} !important`,
                      transform: 'translateY(-3px)',
                      scale: '140%',
                      clipPath: 'polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%)',
                    },
                  },
                }
              : SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER
          }
        />
      </Input.Wrapper>

      <Input.Wrapper
        id="lureIds"
        css={{ marginTop: 20 }}
        label={translate.phrases.placeholder('Lure Type')}
        error={form.errors.lureIds}
      >
        <LuresMultiSelect targetInsectId={form.values?.insectId} {...form.getInputProps('lureIds')} />
      </Input.Wrapper>

      <Input.Wrapper
        id="cameraOrientation"
        css={{ marginTop: 20 }}
        label={translate.phrases.placeholder('Camera Orientation')}
        error={form.errors.lureIds}
      >
        <Select
          css={{ marginBottom: '16px' }}
          {...form.getInputProps('cameraOrientation')}
          data={[
            {
              label: translate.phrases.placeholder('Horizontal'),
              value: 'HORIZONTAL',
            },
            {
              label: translate.phrases.placeholder('Vertical'),
              value: 'VERTICAL',
            },
          ]}
          styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
        />
      </Input.Wrapper>

      <Footer
        onNext={handleSubmit}
        nextButtonLabel={translate.phrases.placeholder('Configure')}
        loading={isSaving}
        onPrevious={() => onClose()}
        showPreviousButton={true}
      />
    </div>
  )
}
