import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconHail = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 350 320')}>
      <g clipPath="url(#clip0_11_484)">
        <g clipPath="url(#clip1_11_484)">
          <path
            d="M291 107C290.15 107 289.32 107.09 288.47 107.13C293.294 87.7023 291.013 67.1901 282.039 49.2967C273.065 31.4034 257.989 17.3083 239.534 9.55632C221.078 1.80437 200.459 0.906499 181.399 7.02481C162.339 13.1431 146.095 25.8744 135.6 42.9199C127.108 37.8427 117.42 35.11 107.526 35.0009C97.6323 34.8918 87.8868 37.4103 79.2846 42.2991C70.6824 47.1879 63.5316 54.272 58.5624 62.828C53.5932 71.3841 50.9835 81.1056 50.9999 90.9999C51.0218 94.0432 51.2893 97.0797 51.7999 100.08C37.1521 102.897 24.0788 111.069 15.1298 123.003C6.18078 134.936 1.99767 149.776 3.39631 164.626C4.79495 179.477 11.675 193.274 22.6948 203.327C33.7145 213.379 48.0837 218.967 62.9999 219C64.3499 219 65.6699 218.89 66.9999 218.8V219H291C305.852 219 320.096 213.1 330.598 202.598C341.1 192.096 347 177.852 347 163C347 148.148 341.1 133.904 330.598 123.402C320.096 112.9 305.852 107 291 107Z"
            fill="url(#paint0_linear_11_484)"
            stroke="#CAD6E8"
            strokeWidth="6"
            strokeMiterlimit="10"
          />
        </g>
        <g clipPath="url(#clip2_11_484)">
          <path
            d="M119 205C116.627 205 114.307 205.704 112.333 207.022C110.36 208.341 108.822 210.215 107.913 212.408C107.005 214.601 106.768 217.013 107.231 219.341C107.694 221.669 108.836 223.807 110.515 225.485C112.193 227.164 114.331 228.306 116.659 228.769C118.987 229.232 121.399 228.995 123.592 228.087C125.785 227.178 127.659 225.64 128.978 223.667C130.296 221.693 131 219.373 131 217C131 213.817 129.736 210.765 127.485 208.515C125.235 206.264 122.183 205 119 205Z"
            fill="url(#paint1_linear_11_484)"
            stroke="#86C3DB"
            strokeMiterlimit="10"
          />
          <path
            d="M175 295C172.627 295 170.307 295.704 168.333 297.022C166.36 298.341 164.822 300.215 163.913 302.408C163.005 304.601 162.768 307.013 163.231 309.341C163.694 311.669 164.836 313.807 166.515 315.485C168.193 317.164 170.331 318.306 172.659 318.769C174.987 319.232 177.399 318.995 179.592 318.087C181.785 317.178 183.659 315.64 184.978 313.667C186.296 311.693 187 309.373 187 307C187 303.817 185.736 300.765 183.485 298.515C181.235 296.264 178.183 295 175 295Z"
            fill="url(#paint2_linear_11_484)"
            stroke="#86C3DB"
            strokeMiterlimit="10"
          />
          <path
            d="M231 235C228.627 235 226.307 235.704 224.333 237.022C222.36 238.341 220.822 240.215 219.913 242.408C219.005 244.601 218.768 247.013 219.231 249.341C219.694 251.669 220.836 253.807 222.515 255.485C224.193 257.164 226.331 258.306 228.659 258.769C230.987 259.232 233.399 258.995 235.592 258.087C237.785 257.178 239.659 255.64 240.978 253.667C242.296 251.693 243 249.373 243 247C243 243.817 241.736 240.765 239.485 238.515C237.235 236.264 234.183 235 231 235Z"
            fill="url(#paint3_linear_11_484)"
            stroke="#86C3DB"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_484"
          x1="99.4499"
          y1="30.6799"
          x2="232.64"
          y2="261.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F7FE" />
          <stop offset="0.45" stopColor="#F3F7FE" />
          <stop offset="1" stopColor="#DEEAFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_484"
          x1="113"
          y1="206.61"
          x2="125"
          y2="227.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_484"
          x1="169"
          y1="296.61"
          x2="181"
          y2="317.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11_484"
          x1="225"
          y1="236.61"
          x2="237"
          y2="257.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <clipPath id="clip0_11_484">
          <rect width="350" height="320" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_484">
          <rect width="350" height="222" fill="white" />
        </clipPath>
        <clipPath id="clip2_11_484">
          <rect width="137" height="115" fill="white" transform="translate(106.5 204.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
