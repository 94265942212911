import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconOrganization = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 22 11')}>
      <path
        d="M11 6.1875C12.4942 6.1875 13.8142 6.545 14.8867 7.0125C15.8767 7.4525 16.5 8.4425 16.5 9.515V11H5.5V9.52417C5.5 8.4425 6.12333 7.4525 7.11333 7.02167C8.18583 6.545 9.50583 6.1875 11 6.1875ZM3.66667 6.41667C4.675 6.41667 5.5 5.59167 5.5 4.58333C5.5 3.575 4.675 2.75 3.66667 2.75C2.65833 2.75 1.83333 3.575 1.83333 4.58333C1.83333 5.59167 2.65833 6.41667 3.66667 6.41667ZM4.7025 7.425C4.36333 7.37 4.02417 7.33333 3.66667 7.33333C2.75917 7.33333 1.8975 7.52583 1.11833 7.865C0.44 8.15833 0 8.81833 0 9.56083V11H4.125V9.52417C4.125 8.76333 4.33583 8.04833 4.7025 7.425ZM18.3333 6.41667C19.3417 6.41667 20.1667 5.59167 20.1667 4.58333C20.1667 3.575 19.3417 2.75 18.3333 2.75C17.325 2.75 16.5 3.575 16.5 4.58333C16.5 5.59167 17.325 6.41667 18.3333 6.41667ZM22 9.56083C22 8.81833 21.56 8.15833 20.8817 7.865C20.1025 7.52583 19.2408 7.33333 18.3333 7.33333C17.9758 7.33333 17.6367 7.37 17.2975 7.425C17.6642 8.04833 17.875 8.76333 17.875 9.52417V11H22V9.56083ZM11 0C12.5217 0 13.75 1.22833 13.75 2.75C13.75 4.27167 12.5217 5.5 11 5.5C9.47833 5.5 8.25 4.27167 8.25 2.75C8.25 1.22833 9.47833 0 11 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
