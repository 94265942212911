// TODO: probably should be in @semios/app-platform-common

import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'

const getCardinalDirections = () => {
  return [
    translate.phrases.abbreviationsCardinalDirections('N'),
    translate.phrases.abbreviationsCardinalDirections('NNE'),
    translate.phrases.abbreviationsCardinalDirections('NE'),
    translate.phrases.abbreviationsCardinalDirections('ENE'),
    translate.phrases.abbreviationsCardinalDirections('E'),
    translate.phrases.abbreviationsCardinalDirections('ESE'),
    translate.phrases.abbreviationsCardinalDirections('SE'),
    translate.phrases.abbreviationsCardinalDirections('SSE'),
    translate.phrases.abbreviationsCardinalDirections('S'),
    translate.phrases.abbreviationsCardinalDirections('SSW'),
    translate.phrases.abbreviationsCardinalDirections('SW'),
    translate.phrases.abbreviationsCardinalDirections('WSW'),
    translate.phrases.abbreviationsCardinalDirections('W'),
    translate.phrases.abbreviationsCardinalDirections('WNW'),
    translate.phrases.abbreviationsCardinalDirections('NW'),
    translate.phrases.abbreviationsCardinalDirections('NNW'),
  ]
}

export const getWindCardinalDirection = (angle?: number | null): string => {
  if (isNil(angle)) return translate.phrases.templates('-')

  const cleanedAngle = angle % 360
  const cardinalDirections = getCardinalDirections()
  const width = 360 / cardinalDirections.length

  // Catch wind directions out of bounds
  if (cleanedAngle >= 360 - width / 2.0 || cleanedAngle < width / 2.0) {
    return cardinalDirections[0]
  }

  const index = Math.floor((cleanedAngle + width / 2) / width)

  return cardinalDirections[index]
}
