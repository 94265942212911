import { Notification, useMantineTheme } from '@mantine/core'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { IconNetworkOffline } from 'components/icons/IconNetworkOffline'
import { translate } from 'i18n/i18n'
import React, { useEffect } from 'react'
import { Contents } from './Contents'

export const OfflineContentDownloadBanner = () => {
  const theme = useMantineTheme()
  const { downloadStatus, isBannerOpened } = serviceCenterStore.useSelector((s) => s.offlineContents)

  useEffect(() => {
    if (downloadStatus === 'success') {
      setTimeout(() => {
        handleClose()
      }, 3000)
    }
  }, [downloadStatus])

  const handleClose = () => {
    serviceCenterStore.actions.setIsOfflineContentBannerOpened(false)

    serviceCenterStore.actions.setOfflineContentsDownloadStatus('default')
  }

  if (!isBannerOpened) return null

  return (
    <Notification
      onClose={handleClose}
      icon={<IconNetworkOffline />}
      title={
        <span style={{ fontSize: '18px', display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '24px', display: 'inline-block', marginRight: '8px' }}>
            <IconNetworkOffline />
          </span>{' '}
          {translate.phrases.placeholder('Offline Functionality')}
        </span>
      }
      css={{
        position: 'absolute',
        paddingTop: 'calc(10px + env(safe-area-inset-top))',
        paddingBottom: 'calc(10px + env(safe-area-inset-bottom))',
        paddingRight: '10px',
        paddingLeft: '10px',
        top: 0,
        zIndex: 10000,
        width: '100%',
        border: 'none',
        borderRadius: 0,
      }}
      styles={{ icon: { backgroundColor: theme.colors.white[0] } }}
    >
      <Contents />
    </Notification>
  )
}
