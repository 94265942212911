import { Popover } from '@mantine/core'
import { IconQuestionMark } from 'components/icons/IconQuestionMark'
import { colors } from 'settings/colors'

export const QuestionToolTip = (props: { children: React.ReactNode }) => {
  return (
    <Popover
      position="right"
      withArrow
      shadow="md"
      radius={5}
      styles={{
        dropdown: {
          position: 'fixed',
        },
      }}
    >
      <Popover.Target>
        <div>
          <IconQuestionMark fillColor={colors.white} />
        </div>
      </Popover.Target>
      <Popover.Dropdown
        css={{ pointerEvents: 'none', color: colors.midnight, fontSize: 14, fontWeight: '400' }}
      >
        <div>{props.children}</div>
      </Popover.Dropdown>
    </Popover>
  )
}
