import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TEmitterType } from '@semios/app-platform-common'
import {
  getValueTypeFromEmitterType,
  IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
} from '@semios/app-platform-common'
import type { EAggregationInterval } from '@semios/app-platform-value-type-definitions'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { getPointLngLatsForIrrigationEmitterZoneId } from 'utils/getPointLngLatsForIrrigationEmitterZoneId'

export const getPressureSensorArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
  emitterType,
  valueTypeForIntervals,
  intervalToRequest,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  emitterType: TEmitterType
  valueTypeForIntervals: string
  intervalToRequest: EAggregationInterval
}): Partial<routes.Values.Request> => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.irrigationZoneEmitter) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: intervalToRequest,
  }

  const valuesRequestedForPoints: Record<string, typeof preferredAggregationInterval> = {}

  valuesRequestedForPoints[valueTypeForIntervals] = preferredAggregationInterval

  if (intervalToRequest !== 'DAILY') {
    const valueTypeForPressure = getValueTypeFromEmitterType(
      IRRIGATION_PRESSURE_VALUE_TYPE_PREFIX,
      emitterType,
    )

    valuesRequestedForPoints[valueTypeForPressure] = preferredAggregationInterval
  }

  const lngLatsForZone = getPointLngLatsForIrrigationEmitterZoneId({
    propertyId: Number(selectedFieldAssets.property),
    irrigationEmitterZoneId: selectedFieldAssets.irrigationZoneEmitter,
  })

  if (lngLatsForZone.length === 0) return {}

  return {
    points: {
      lngLats: lngLatsForZone,
      valuesRequested: valuesRequestedForPoints,
    },
  }
}
