import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, Indicator, Loader, Tooltip } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useMemo } from 'react'
import { alphaColorReplacer, colors } from 'settings/colors'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import type { useTrapImageData } from '../../_utils/useTrapImageData'

export const TrapImagesDatePicker = ({
  datePickerLoading,
  datesWithImages,
  datesWithTrapCatches,
  fullLoading,
  maxDate,
  minDate,
  prettyDateString,
  selectedDate,
  setSelectedDate,
  timezone,
}: ReturnType<typeof useTrapImageData> & {
  selectedDate: string
  setSelectedDate: Dispatch<SetStateAction<string>>
  timezone: string
}) => {
  const sortedImageDatesArray = useMemo(
    () => Object.keys(datesWithImages).sort(alphabeticalSort),
    [datesWithImages],
  )

  const decrementDate = useCallback(() => {
    const dateIndex = sortedImageDatesArray.findIndex((date: string) => date === selectedDate)

    if (dateIndex > 0) setSelectedDate(sortedImageDatesArray[dateIndex - 1])
  }, [sortedImageDatesArray, selectedDate, setSelectedDate])

  const incrementDate = useCallback(() => {
    const dateIndex = sortedImageDatesArray.findIndex((date: string) => date === selectedDate)

    if (dateIndex < sortedImageDatesArray.length - 1) setSelectedDate(sortedImageDatesArray[dateIndex + 1])
  }, [sortedImageDatesArray, selectedDate, setSelectedDate])

  const setDate = useCallback(
    (date: Date | null) => {
      setSelectedDate(
        moment
          .tz(date ?? new Date(), timezone)
          .startOf('day')
          .toISOString(),
      )
    },
    [datesWithImages, setSelectedDate, timezone],
  )

  const renderDaysWithData = useCallback(
    (d: Date) => {
      const currentDate = moment.tz(d, timezone).startOf('day')
      const trapCatchesForDay = datesWithTrapCatches[currentDate.toISOString()]

      if (!trapCatchesForDay) return <div>{translate.dates.format(currentDate, 'D')}</div>

      return (
        <Tooltip
          label={translate.phrases.templates('{{label}}: {{value}}', {
            label: translate.phrases.banyanApp('Trap Catches'),
            value: trapCatchesForDay.value,
          })}
          events={{ hover: !!trapCatchesForDay, focus: false, touch: false }}
        >
          <Indicator
            size={8}
            color={colors.lightYellow}
            styles={{ indicator: { marginTop: 2 } }}
            offset={-3}
            disabled={!trapCatchesForDay}
          >
            <div>{translate.dates.format(currentDate, 'D')}</div>
          </Indicator>
        </Tooltip>
      )
    },
    [datesWithImages],
  )

  return (
    <div css={{ display: 'flex', paddingBottom: 5, alignItems: 'center', justifyContent: 'center' }}>
      <ActionIcon
        css={{ color: colors.primary, border: `2px solid ${colors.primary}` }}
        disabled={moment.tz(selectedDate, timezone).isSame(moment.tz(minDate, timezone), 'day')}
        onClick={decrementDate}
        radius="xl"
        variant="outline"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </ActionIcon>
      <DatePickerInput
        icon={datePickerLoading && !fullLoading && <Loader size="xs" />}
        value={!selectedDate ? undefined : moment.tz(selectedDate, timezone).toDate()}
        onChange={setDate}
        clearable={false}
        renderDay={renderDaysWithData}
        excludeDate={(date) => !datesWithImages[moment.tz(date, timezone).startOf('day').toISOString()]}
        maxDate={!maxDate ? undefined : moment.tz(maxDate, timezone).endOf('day').toDate()}
        minDate={!minDate ? undefined : moment.tz(minDate, timezone).startOf('day').toDate()}
        /**
         * in moment formatting, "[]" allows custom characters.
         * We actually don't want to choose the selectedDate itself,
         * since it's tied to the start of the day. The image's
         * imageTakenOn property can be used instead, and since
         * they're bundled together, we can just pluck it out and
         * render it via a timezone-aware moment render
         */
        valueFormat={`[${prettyDateString}]`}
        styles={{
          wrapper: { padding: 5 },
          input: {
            paddingLeft: '6px !important',
            textAlign: 'center',
            width: 240,
            maxWidth: 'calc(100vw - 90px)',
          },
          day: {
            // these next 3 styles are a bit brittle, so if things aren't looking right, look here
            'margin': 2,
            'width': 36,
            'height': 36,
            // make it a bit more obvious that some days have no images
            ':disabled': {
              background: alphaColorReplacer(colors.grey200, 0.5),
              color: `${colors.grey500} !important`,
            },
            // by default, Mantine puts weekends in red for some reason
            '&[data-weekend]': {
              color: 'unset !important',
            },
            // for days outside of the selected month, e.g. May 31 when you're focussed on June
            '&[data-outside]': {
              color: `${colors.grey500} !important`,
            },
            ':focus': {
              outlineColor: 'transparent',
            },
          },
        }}
      />
      <ActionIcon
        css={{ color: colors.primary, border: `2px solid ${colors.primary}` }}
        disabled={moment.tz(selectedDate, timezone).isSame(moment.tz(maxDate, timezone), 'day')}
        onClick={incrementDate}
        radius="xl"
        variant="outline"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </ActionIcon>
    </div>
  )
}
