import { searchStringFromStringWithSpaces } from './searchStringFromStringWithSpaces'

export const mantineSelectSearchFilterProp = (
  value: string,
  item: object & { label?: string; group?: string },
) => {
  const fullStringToSearch = `${item?.label ?? ''}${item?.group ?? ''}`

  return searchStringFromStringWithSpaces(value, fullStringToSearch)
}
