import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconRightArrowCircle = () => (
  <svg {...makeBaseSvgIconProps('0 0 16 16')}>
    <path
      d="M8 4.5L7.38313 5.11687L9.82438 7.5625H4.5V8.4375H9.82438L7.38313 10.8831L8 11.5L11.5 8L8 4.5Z"
      fill="currentColor"
    />
    <circle cx="8" cy="8" r="7" stroke="currentColor" />
  </svg>
)
