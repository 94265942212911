import type { FC } from 'react'
import { colors } from 'settings/colors'

type IconProp = { size?: number }

export const CloseIcon: FC<IconProp> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1667 1.5275L13.6392 0L7.58333 6.05583L1.5275 0L0 1.5275L6.05583 7.58333L0 13.6392L1.5275 15.1667L7.58333 9.11083L13.6392 15.1667L15.1667 13.6392L9.11083 7.58333L15.1667 1.5275Z"
      fill={colors.grey800}
    />
  </svg>
)
