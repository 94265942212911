import { useComponentDefaultProps } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { translate } from 'i18n/i18n'
import { useEffect, useRef, useState } from 'react'
import { DateRangePickerDropdown } from './DateRangePickerDropdown'
import type { DateRangePickerWithFooterProps, DatesRangeValue, dropdownType } from './types'

export const MANTINE_DATE_RANGE_PICKER_WIDTH = 240

export const MantineDateRangePickerWithFooter = (props: DateRangePickerWithFooterProps) => {
  const defaultProps = {
    valueFormat: translate.dates.getMomentFormat('MMMM D, YYYY'),
    closeOnChange: false,
    sortDates: true,
    dropdownType: 'popover' as dropdownType,
    allowSingleDateInRange: true,
  }

  const [originalValue, setOriginalValue] = useState<DatesRangeValue>([null, null])
  const [value, setValue] = useState<DatesRangeValue>(props.defaultValue || [null, null])
  const [dropDownOpened, setDropdownOpened] = useState(false)
  const datePickerInputRef = useRef(null)

  useEffect(() => {
    setOriginalValue(value)
  }, [dropDownOpened])

  useEffect(() => {
    setValue(props.defaultValue || [null, null])

    setOriginalValue(props.defaultValue || [null, null])
  }, [props.defaultValue])

  const dismiss = () => {
    setValue(originalValue)

    setDropdownOpened(false)
  }

  const {
    allowSingleDateInRange,
    closeOnChange,
    defaultValue,
    Footer,
    maxDate,
    minDate,
    placeholder,
    styles,
    sortDates,
    renderDayWithValue,
    valueFormat,
  } = useComponentDefaultProps('DatePickerInput', defaultProps, props)

  return (
    <div css={{ height: 42, width: MANTINE_DATE_RANGE_PICKER_WIDTH }}>
      <div css={{ position: 'absolute', zIndex: 100 }}>
        <DatePickerInput<'range'>
          clearable={false}
          closeOnChange={closeOnChange}
          defaultValue={defaultValue}
          icon={props.icon}
          placeholder={placeholder}
          ref={datePickerInputRef}
          sortDates={sortDates}
          styles={styles}
          type="range"
          valueFormat={valueFormat}
          onClick={() => setDropdownOpened((prev) => !prev)}
          value={value}
          popoverProps={{
            opened: false, // Using a custom component, so don't use the popover.
          }}
          css={{ width: MANTINE_DATE_RANGE_PICKER_WIDTH }}
        />
        <DateRangePickerDropdown
          dismiss={dismiss}
          dropDownOpened={dropDownOpened}
          setDropdownOpened={setDropdownOpened}
          value={value}
          setValue={setValue}
          renderDayWithValue={renderDayWithValue}
          Footer={Footer}
          allowSingleDateInRange={allowSingleDateInRange}
          defaultValue={defaultValue}
          maxDate={maxDate}
          minDate={minDate}
          styles={props.styles}
        />
      </div>
    </div>
  )
}
