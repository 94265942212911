import { includeAggregateSwitch } from 'App/Map/MapControls/MapControlItems/MapControlItems'
import { isNil } from 'lodash'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesPointHaveValueType } from 'utils/doesFieldAssetHaveValueType'
import type { BlockScdsValuesCache } from '../caches/BlockScdsValuesCache/BlockScdsValuesCache'
import type { BlocksDefaultPolygonsCache } from '../caches/BlocksDefaultPolygonsCache/BlocksDefaultPolygonsCache'
import type { BlockValuesCache } from '../caches/BlockValuesCache/BlockValuesCache'
import type { ImageOverlaysCache } from '../caches/ImageOverlaysCache/ImageOverlaysCache'
import type { IrrigationZonesPolygonsCache } from '../caches/IrrigationZonesPolygonsCache/IrrigationZonesPolygonsCache'
import type { IrrigationZoneValuesCache } from '../caches/IrrigationZoneValuesCache/IrrigationZoneValuesCache'
import type { PropertyValuesCache } from '../caches/PropertyValuesCache/PropertyValuesCache'
import type { RegionValuesCache } from '../caches/RegionsValuesCache/RegionsValuesCache'
import type { StationValuesCache } from '../caches/StationValuesCache/StationValuesCache'
import type { TrapValuesCache } from '../caches/TrapValuesCache/TrapValuesCache'
import * as ndvi from './by-domain/ndvi'
import type { TCurrentValuesMapCacheKeys, TCurrentValuesMapProcessedCaches } from './by-domain/_types'
import { getCacheUpdatesFromResponse } from './getCacheUpdatesFromResponse'
import { getZoomThresholds, ZOOM_VISIBILITY } from './zoomVisibility'

const irrigationValueGroups = ['irrigation_activity']

export const updateMapWithAnyNeededCurrentValues = async ({
  propertyValuesCache,
  blockValuesCache,
  stationValuesCache,
  blockScdsValuesCache,
  blocksDefaultPolygonsCache,
  trapValuesCache,
  regionValuesCache,
  irrigationZonePolygonsCache,
  irrigationZoneValuesCache,
  imageOverlaysCache,
}: {
  blocksDefaultPolygonsCache: BlocksDefaultPolygonsCache
  blockScdsValuesCache: BlockScdsValuesCache
  blockValuesCache: BlockValuesCache
  propertyValuesCache: PropertyValuesCache
  stationValuesCache: StationValuesCache
  trapValuesCache: TrapValuesCache
  regionValuesCache: RegionValuesCache
  irrigationZonePolygonsCache: IrrigationZonesPolygonsCache
  irrigationZoneValuesCache: IrrigationZoneValuesCache
  imageOverlaysCache: ImageOverlaysCache
}) => {
  const primaryValueGroup = getPrimaryValueGroup()
  const { mapVisualValueGroup, mapVisuals, useAggregation } = mapControlsStore.getState()
  const blockCacheKey = 'STATIC'

  const imageOverlaysCacheKey = `${primaryValueGroup}_${
    mapVisualValueGroup?.[MAP_VISUAL.IMAGE_OVERLAY]?.ndvi?.valueType
  }`

  if (!primaryValueGroup) {
    const blockPolygons = blocksDefaultPolygonsCache.getItemsToProcess({
      cacheKey: blockCacheKey,
      shouldShowItem: (() => {
        const currentBounds = blocksDefaultPolygonsCache.map.getBounds()

        if (!currentBounds) return () => false

        return (o) => o.meta.bounds.intersects(currentBounds)
      })(),
    })

    const imageOverlayValues = imageOverlaysCache.getItemsToProcess({
      cacheKey: imageOverlaysCacheKey,
      shouldShowItem: (() => {
        if (!selectedValueGroupsStore.getState().selectedValueGroups.ndvi) return () => false

        const currentZoom = imageOverlaysCache.map.getZoom()

        if (currentZoom === undefined || getZoomThresholds(currentZoom).IMAGE_OVERLAY !== ZOOM_VISIBILITY.OK)
          return () => false

        const currentBounds = imageOverlaysCache.map.getBounds()

        if (!currentBounds) return () => false

        return (o) => o.meta.bounds.intersects(currentBounds)
      })(),
    })

    blocksDefaultPolygonsCache.process({
      itemsWithinViewThatNowHaveValues: [],
      cacheKey: blockCacheKey,
      itemIdsWithinView: blockPolygons.itemIdsWithinView,
    })

    const processedImageOverlays = await ndvi.getResponseAndShapeForCacheUpdate({
      cacheKeys: { imageOverlaysCacheKey } as TCurrentValuesMapCacheKeys,
      processedCaches: { imageOverlayValues } as TCurrentValuesMapProcessedCaches,
      primaryValueGroup: 'ndvi', // just to make typescript happy, isn't actually being used
    })

    if (processedImageOverlays.imageOverlays) imageOverlaysCache.process(processedImageOverlays.imageOverlays)
  }

  if (isNil(primaryValueGroup)) return

  const cacheKeys: TCurrentValuesMapCacheKeys = {
    blockCacheKey,
    blockValuesCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.BLOCK]?.[primaryValueGroup]?.valueType
    }`,
    propertyCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.PROPERTY]?.[primaryValueGroup]?.valueType
    }`,
    scdCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.SCD]?.[primaryValueGroup]?.valueType
    }`,
    stationCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.POINT]?.[primaryValueGroup]?.valueType
    }`,
    trapsCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.POINT]?.[primaryValueGroup]?.valueType
    }`,
    imageOverlaysCacheKey,
    irrigationZoneCacheKey: 'IRRIGATION_ZONE_STATIC',
    irrigationZoneValuesCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.IRRIGATION_ZONE]?.[primaryValueGroup]?.valueType
    }`,
    regionValuesCacheKey: `${primaryValueGroup}_${
      mapVisualValueGroup[MAP_VISUAL.REGION]?.[primaryValueGroup]?.valueType
    }`,
  }

  const blockPolygons = blocksDefaultPolygonsCache.getItemsToProcess({
    cacheKey: cacheKeys.blockCacheKey,
    shouldShowItem: (() => {
      if (irrigationValueGroups.includes(primaryValueGroup)) return () => false

      const currentBounds = blocksDefaultPolygonsCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const propertyValues = propertyValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.propertyCacheKey,
    shouldShowItem: (() => {
      const currentZoom = propertyValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).PROPERTY !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = propertyValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const blockValues = blockValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.blockValuesCacheKey,
    shouldShowItem: (() => {
      if (includeAggregateSwitch[primaryValueGroup] && !useAggregation) return () => false

      if (!mapVisuals.BLOCK || !mapVisualValueGroup[MAP_VISUAL.BLOCK]?.[primaryValueGroup]) return () => false

      const currentZoom = blockValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).BLOCK !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = blockValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const stationValues = stationValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.stationCacheKey,
    shouldShowItem: (() => {
      const currentZoom = stationValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).POINT !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = stationValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => {
        const isInBounds = currentBounds.contains(o.meta.latLng)
        const mapVisual = MAP_VISUAL.POINT

        const expectedValueTypeForThisStation =
          mapVisuals[mapVisual] && mapVisualValueGroup[mapVisual]?.[primaryValueGroup]

        if (isInBounds && expectedValueTypeForThisStation && expectedValueTypeForThisStation.valueType) {
          let valueType = expectedValueTypeForThisStation.valueType

          if (primaryValueGroup === 'soil') {
            // TODO SG this is hacky and not awesome but...
            valueType = 'soilAWC_eachDepth_pct'
          }

          if (o.meta.isOutOfBlock) {
            valueType = expectedValueTypeForThisStation.outOfBlockValueType ?? valueType
          }

          const doesStationHaveThisValueType = doesPointHaveValueType({
            propertyId: o.meta.propertyId,
            lngLat: o.meta.lngLat,
            currentValueToCheck: valueType,
          })

          return doesStationHaveThisValueType
        }

        return false
      }
    })(),
  })

  const blockScdsValues = blockScdsValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.scdCacheKey,
    shouldShowItem: (() => {
      if (includeAggregateSwitch[primaryValueGroup] && useAggregation) return () => false

      if (!mapVisuals.SCD || !mapVisualValueGroup[MAP_VISUAL.SCD]?.[primaryValueGroup]) return () => false

      const currentZoom = blockScdsValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).SCD !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = blockScdsValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const trapsValues = trapValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.trapsCacheKey,
    shouldShowItem: (() => {
      if (includeAggregateSwitch[primaryValueGroup] && useAggregation) return () => false

      if (!mapVisuals.TRAP || !mapVisualValueGroup[MAP_VISUAL.TRAP]?.[primaryValueGroup]) return () => false

      const currentZoom = trapValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).TRAP !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = trapValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) =>
        currentBounds.contains(o.meta.latLng) &&
        !!o.meta.insectId &&
        o.meta.insectId === Number(primaryValueGroup.replace('trap_catches_insect_id_', ''))
    })(),
  })

  const imageOverlayValues = imageOverlaysCache.getItemsToProcess({
    cacheKey: cacheKeys.imageOverlaysCacheKey,
    shouldShowItem: (() => {
      if (!selectedValueGroupsStore.getState().selectedValueGroups.ndvi) return () => false

      const currentZoom = imageOverlaysCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).IMAGE_OVERLAY !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = imageOverlaysCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const irrigationZonePolygons = irrigationZonePolygonsCache.getItemsToProcess({
    cacheKey: cacheKeys.irrigationZoneCacheKey,
    shouldShowItem: (() => {
      if (!irrigationValueGroups.includes(primaryValueGroup)) return () => false

      const currentBounds = irrigationZonePolygonsCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const irrigationZoneValues = irrigationZoneValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.irrigationZoneValuesCacheKey,
    shouldShowItem: (() => {
      if (
        !mapVisuals.IRRIGATION_ZONE ||
        !mapVisualValueGroup[MAP_VISUAL.IRRIGATION_ZONE]?.[primaryValueGroup]
      )
        return () => false

      const currentZoom = irrigationZoneValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).IRRIGATION_ZONE !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = irrigationZoneValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => o.meta.bounds.intersects(currentBounds)
    })(),
  })

  const regionValues = regionValuesCache.getItemsToProcess({
    cacheKey: cacheKeys.regionValuesCacheKey,
    shouldShowItem: (() => {
      if (!mapVisuals.REGION || !mapVisualValueGroup[MAP_VISUAL.REGION]?.[primaryValueGroup])
        return () => false

      const currentZoom = regionValuesCache.map.getZoom()

      if (currentZoom === undefined || getZoomThresholds(currentZoom).REGION !== ZOOM_VISIBILITY.OK)
        return () => false

      const currentBounds = regionValuesCache.map.getBounds()

      if (!currentBounds) return () => false

      return (o) => currentBounds.contains(o.meta.latLng)
    })(),
  })

  const processedCaches = {
    blockPolygons,
    blockScdsValues,
    blockValues,
    propertyValues,
    stationValues,
    trapsValues,
    imageOverlayValues,
    irrigationZonePolygons,
    irrigationZoneValues,
    regionValues,
  }

  const processed = await getCacheUpdatesFromResponse({
    cacheKeys,
    processedCaches,
  })

  if (primaryValueGroup !== 'irrigation_activity') {
    blocksDefaultPolygonsCache.process({
      itemsWithinViewThatNowHaveValues: [],
      cacheKey: cacheKeys.blockCacheKey,
      itemIdsWithinView: blockPolygons.itemIdsWithinView,
    })
  } else {
    irrigationZonePolygonsCache.process({
      itemsWithinViewThatNowHaveValues: [],
      cacheKey: cacheKeys.irrigationZoneCacheKey,
      itemIdsWithinView: irrigationZonePolygons.itemIdsWithinView,
    })

    if (processed.properties) propertyValuesCache.process(processed.properties)

    if (processed.irrigationZones) {
      irrigationZoneValuesCache.process(processed.irrigationZones)
    }

    if (processed.stations) stationValuesCache.process(processed.stations)
  }

  if (processed.properties) propertyValuesCache.process(processed.properties)

  if (processed.blocks) blockValuesCache.process(processed.blocks)

  if (processed.stations) stationValuesCache.process(processed.stations)

  if (processed.heatmapPoints) blockScdsValuesCache.process(processed.heatmapPoints)

  if (processed.traps) trapValuesCache.process(processed.traps)

  if (processed.regions) regionValuesCache.process(processed.regions)

  if (processed.imageOverlays) imageOverlaysCache.process(processed.imageOverlays)
}
