import { Button } from 'components/Button/Button'
import { IconTrashCan } from 'components/icons/IconTrashCan'
import { translate } from 'i18n/i18n'

export const SettingsDeleteButton = ({
  onClick,
  label = translate.phrases.banyanApp('Delete'),
}: {
  onClick: () => void
  label?: string
}) => {
  return (
    <Button onClick={onClick} leftIcon={<IconTrashCan />} variant="link">
      {label}
    </Button>
  )
}
