import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getTimezoneForSelectedPropertyOrRegion } from './getTimezoneForSelectedPropertyOrRegion'

export const useTimezoneForSelectedPropertyOrRegion = () => {
  const { properties, regions } = fieldAssetStore.useSelector((s) => ({
    properties: s?.properties,
    regions: s?.regions,
  }))

  const { propertyId, regionId } = selectedFieldAssetsStore.useSelector((s) => ({
    propertyId: s.property,
    regionId: s.region,
  }))

  return getTimezoneForSelectedPropertyOrRegion({ properties, propertyId, regions, regionId })
}
