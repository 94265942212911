import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconEmailEnabled = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 31 31')}>
      <rect x="0.404297" y="0.0585938" width="30" height="30" rx="15" fill="#464B53" />
      <path
        d="M21.0043 10.5088H9.8043C9.0343 10.5088 8.4113 11.0207 8.4113 11.6463L8.4043 18.4713C8.4043 19.0969 9.0343 19.6088 9.8043 19.6088H21.0043C21.7743 19.6088 22.4043 19.0969 22.4043 18.4713V11.6463C22.4043 11.0207 21.7743 10.5088 21.0043 10.5088ZM21.0043 12.7838L15.4043 15.6275L9.8043 12.7838V11.6463L15.4043 14.49L21.0043 11.6463V12.7838Z"
        fill="white"
      />
    </svg>
  )
}
