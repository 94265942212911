import { Checkbox } from 'components/Checkbox/Checkbox'
import { colors } from 'settings/colors'
import { useMultiSelectContext } from '../MultiSelect.context'
import type { TOptions } from '../MultiSelect.types'

export const Section = ({ isSelected, sectionData }: { isSelected: boolean; sectionData: TOptions }) => {
  const { optionValueDecorator, onSelectItem } = useMultiSelectContext()

  return (
    <div
      css={{
        'display': 'flex',
        'alignItems': 'center',
        'padding': '10px 10px',
        'color': colors.midnight,
        'fontSize': 14,
        'cursor': 'pointer',
        '&:hover': {
          background: colors.grey50,
          cursor: 'pointer',
        },
        'fontWeight': isSelected ? '700' : '400',
      }}
      onClick={() => onSelectItem(sectionData)}
    >
      <Checkbox css={{ marginRight: 10 }} readOnly checked={isSelected} />
      {/*  TODO: fix for ReactNode*/}
      {optionValueDecorator && typeof sectionData.label === 'string'
        ? optionValueDecorator(sectionData.label, sectionData)
        : sectionData.label}
    </div>
  )
}
