// this quickStringMatch function is a fun and efficient alternative to .match(), which
// returns a number >= 1 if a match, and 0 if no match
import { isNil } from 'lodash'

export const quickStringMatch = (input: string, searchForThis: string) => {
  const inputFormatted = input.toString().toLowerCase()

  return inputFormatted.length - inputFormatted.replace(searchForThis.toString().toLowerCase(), '').length
}

export const searchStringFromStringWithSpaces = (searchTerms: string, stringToSearch: string) => {
  if (isNil(stringToSearch)) return false

  let searchers = searchTerms.split(' ').filter((t) => t)

  if (Array.isArray(searchTerms)) searchers = searchTerms

  const foundTerms = searchers.map((st) => !!quickStringMatch(stringToSearch, st)).filter((found) => found)

  return foundTerms.length === searchers.length
}
