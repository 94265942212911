import { LoadingOverlay, Tabs } from '@mantine/core'
import { Authorization } from 'components/Authorization/Authorization'
import { InfoMessageBox } from 'components/InfoMessageBox/InfoMessageBox'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { checkAuthorization } from 'utils/checkAuthorization'
import type { TSettingsItemProps } from '../UserSettingsMenu/types'
import { Permissions } from './components/Permissions/Permissions'
import { Properties } from './components/Properties/Properties'
import { Regions } from './components/Regions/Regions'
import { Roles } from './components/Roles/Roles'
import { Users } from './components/Users/Users'
import { useAdminEntities } from './utils/useAdminEntites'

export const userHasAnyAdminPermissions = () => {
  const hasRegionsPermission = checkAuthorization({
    permission: 'ADMIN_VIEW_LIST_OF_ALL_REGIONS',
    entity: '*',
  })

  const hasUsersPermission = checkAuthorization([
    { permission: 'ADMIN_VIEW_LIST_OF_ALL_USERS', entity: '*' },
    { permission: 'EDIT_USER_ACCESS', entity: 'FOR_ANY_ENTITY' },
  ])

  const hasAllPermissionsPermission = checkAuthorization({
    permission: 'ADMIN_VIEW_LIST_OF_ALL_PERMISSIONS',
    entity: '*',
  })

  const hasAllRolesPermission = checkAuthorization({
    permission: 'ADMIN_VIEW_LIST_OF_ALL_ROLES',
    entity: '*',
  })

  const hasPropertiesPermission = checkAuthorization([
    { permission: 'ADMIN_VIEW_LIST_OF_ALL_PROPERTIES', entity: '*' },
  ])

  const hasOrgAdminPermission = checkAuthorization({
    permission: 'EDIT_USER_ACCESS',
    entity: 'FOR_ANY_ENTITY',
  })

  return {
    hasRegionsPermission,
    hasUsersPermission,
    hasAllPermissionsPermission,
    hasAllRolesPermission,
    hasPropertiesPermission,
    hasOrgAdminPermission,
  }
}

export const Admin = ({ setCurrentTab }: Partial<TSettingsItemProps>) => {
  type TTabKeys = keyof Omit<ReturnType<typeof useAdminEntities>['data'], 'ORG_ADMIN'>

  const [activeTab, setActiveTab] = useState<TTabKeys>('USERS')

  const {
    hasRegionsPermission,
    hasUsersPermission,
    hasAllPermissionsPermission,
    hasAllRolesPermission,
    hasPropertiesPermission,
    hasOrgAdminPermission,
  } = userHasAnyAdminPermissions()

  const userHasAnyPermission =
    hasRegionsPermission ||
    hasUsersPermission ||
    hasAllPermissionsPermission ||
    hasAllRolesPermission ||
    hasPropertiesPermission

  const { data, loading, setWatcher } = useAdminEntities(!userHasAnyPermission, {
    USERS: hasUsersPermission,
    PROPERTIES: hasPropertiesPermission || hasOrgAdminPermission,
    REGIONS: hasRegionsPermission,
    ROLES: hasAllRolesPermission || hasOrgAdminPermission,
    PERMISSIONS: hasAllPermissionsPermission,
    ORG_ADMIN: hasOrgAdminPermission,
  })

  useEffect(() => {
    if (userHasAnyPermission) {
      setWatcher((prev) => {
        const val = +new Date()

        return Object.fromEntries(
          Object.keys(prev).map((key) => {
            if (prev[key]) {
              return [key, prev[key]]
            }

            return [key, val]
          }),
        ) as typeof prev
      })
    }
  }, [userHasAnyPermission])

  const tabs: {
    label: string
    value: TTabKeys
    component: React.ReactNode
  }[] = []

  const handleRefresh = (keys: (keyof typeof data)[]) => {
    setWatcher((prev) => {
      const newWatcher = { ...prev }

      keys.forEach((key) => {
        newWatcher[key] = +new Date()
      })

      return newWatcher
    })
  }

  if (hasUsersPermission) {
    tabs.push({
      label: translate.phrases.banyanApp('Users'),
      value: 'USERS',
      component: (
        <Users
          users={data.USERS}
          orgAdmin={data.ORG_ADMIN?.[0] ?? null}
          loading={loading}
          properties={data.PROPERTIES}
          roles={data.ROLES}
          permissions={data.PERMISSIONS}
          onRefresh={() => handleRefresh(['USERS', 'ORG_ADMIN'])}
        />
      ),
    })
  }

  if (hasPropertiesPermission) {
    tabs.push({
      label: translate.phrases.banyanApp('Properties'),
      value: 'PROPERTIES',
      component: (
        <Properties
          properties={data.PROPERTIES}
          loading={loading}
          permissions={data.PERMISSIONS}
          onRefresh={() => handleRefresh(['PROPERTIES'])}
        />
      ),
    })
  }

  if (hasRegionsPermission) {
    tabs.push({
      label: translate.phrases.banyanApp('Regions'),
      value: 'REGIONS',
      component: (
        <Regions
          regions={data.REGIONS}
          permissions={data.PERMISSIONS}
          loading={loading}
          onRefresh={() => handleRefresh(['REGIONS'])}
        />
      ),
    })
  }

  if (hasAllRolesPermission) {
    tabs.push({
      label: translate.phrases.banyanApp('Roles'),
      value: 'ROLES',
      component: (
        <Roles
          roles={data.ROLES}
          permissions={data.PERMISSIONS}
          loading={loading}
          onRefresh={() => handleRefresh(['ROLES'])}
        />
      ),
    })
  }

  if (hasAllPermissionsPermission) {
    tabs.push({
      label: translate.phrases.banyanApp('Permissions'),
      value: 'PERMISSIONS',
      component: (
        <Permissions
          permissions={data.PERMISSIONS}
          loading={loading}
          onRefresh={() => handleRefresh(['PERMISSIONS'])}
        />
      ),
    })
  }

  return (
    <ModalDrawer
      opened={true}
      onClose={() => setCurrentTab && setCurrentTab('mainMenu')}
      title={translate.phrases.banyanApp('Admin')}
      isSecondaryModal={true}
      size={'70%'}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
    >
      <Authorization requires={userHasAnyPermission}>
        <div css={{ padding: '0 0 20px 20px' }}>
          <LoadingOverlay visible={loading} />
          {data.ORG_ADMIN.length > 0 && (
            <InfoMessageBox css={{ marginLeft: -20 }} dismissable>
              {translate.phrases.templates('{{labelA}} - {{labelB}}', {
                labelA: translate.phrases.banyanApp('Administering users for organization {{orgName}}', {
                  orgName: data.ORG_ADMIN[0].name,
                }),
                labelB: translate.phrases.banyanApp('{{usersCount}} of {{totalSeats}} seats used', {
                  usersCount: data.ORG_ADMIN[0].totalSeats - data.ORG_ADMIN[0].seatsRemaining,
                  totalSeats: data.ORG_ADMIN[0].totalSeats,
                }),
              })}
            </InfoMessageBox>
          )}

          {!loading && (
            <Tabs
              value={activeTab}
              onTabChange={(newTab: TTabKeys) => setActiveTab(newTab)}
              orientation="horizontal"
              keepMounted={false}
              styles={{
                tabsList: {
                  button: {
                    'fontSize': 15,
                    'padding': 10,
                    'color': colors.primary,
                    '&:hover': { background: 'transparent', fontWeight: 600 },
                    '&[data-active]': {
                      'fontWeight': 700,
                      'borderBottom': `4px solid ${colors.midnight}`,
                      '&:hover': { fontWeight: 700, borderColor: colors.midnight },
                    },
                    '&:not(:first-of-type)': { marginLeft: 20 },
                  },
                },
                panel: {
                  paddingTop: 10,
                },
              }}
            >
              <Tabs.List>
                {tabs.map((tab) => (
                  <Tabs.Tab key={tab.value} value={tab.value}>
                    {tab.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
              {tabs.map((tab) => (
                <Tabs.Panel key={tab.value} value={tab.value} css={{ padding: 5 }}>
                  {tab.component}
                </Tabs.Panel>
              ))}
            </Tabs>
          )}
        </div>
      </Authorization>
    </ModalDrawer>
  )
}
