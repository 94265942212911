import { colors } from 'settings/colors'

export const BadgeCell = ({
  backgroundColor,
  color = colors.midnight,
  children,
}: {
  backgroundColor: string
  color?: string
  children: React.ReactNode
}) => {
  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor,
        borderRadius: 3,
        color,
        display: 'flex',
        fontWeight: 'bold',
        justifyContent: 'center',
        padding: '4px 12px',
        transition: '0.2s ease all',
        width: 'max-content',
        minWidth: 74,
        maxWidth: '100%',
        height: 'max-content',
      }}
    >
      {children}
    </div>
  )
}
