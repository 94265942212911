import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isNil } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import {
  EAggregationInterval,
  VIEW_PESTS_TRAP_CATCHES_ID_insectId,
} from '@semios/app-platform-value-type-definitions'
import { getXDateFormat } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/makeCompareSeasonsSeriesFromRegularSeries'
import { makeRegionalSeriesFromRegularSeries } from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/_utils/makeRegionalSeriesFromRegularSeries'
import { blockLacksTrapsSectionMaker } from 'App/Map/PanelDetails/_utils/blockLacksTrapsSectionMaker'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { regionLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/regionLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { selectedRegionHasPermission } from 'App/Map/PanelDetails/_utils/selectedRegionHasPermission'
import type { TPestSectionCategory } from 'App/Map/PanelDetails/_utils/sortPestSections'
import type { StackedChartPestSection, TChartSeries } from 'components/StackedChart/types'
import type { TRGBAColorWithOpacityAtTheEnd } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type {
  selectedValueGroupsStore,
  TValueGroup,
} from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import type { TPossibleValuesToCheck } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { doesSelectedRegionHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX } from 'utils/insectRequestValueKeyPrefix'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { sortByKey } from 'utils/sortByKey'
import type { TUnitConverterTypicalReturn } from 'utils/unitConverter/types'
import { Summary } from './Summary'

const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY } as const

const getValueTypeIdForInsectId = (insectId: number) => {
  return `${INSECT_TRAP_CATCHES_VALUE_KEY_PREFIX}${insectId}` as keyof VV.DomainTypes.TrapCatchesInsect.TValuesReturnWithMetaIgnoringKeying['properties']
}

export const insectTrapCatchesValues = ({
  selectedValueGroups,
  doesTargetHaveValueTypes,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  doesTargetHaveValueTypes: ({ valuesCurrent, valuesTimeseries }: TPossibleValuesToCheck) => boolean
}) => {
  const valuesRequested: Partial<
    Record<
      VV.DomainTypes.TrapCatchesInsect.TTimeseriesValueTypeKeysMerged,
      typeof preferredAggregationInterval
    >
  > = {}

  Object.entries(selectedValueGroups).forEach(([valueGroup, isActive]) => {
    if (!!isActive && valueGroup.includes('trap_catches_insect_id_')) {
      const insectId = Number(valueGroup.split('_').slice(-1)[0])

      if (!selectedPropertyHasPermission({ permission: VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId) }))
        return

      valuesRequested[getValueTypeIdForInsectId(insectId)] = preferredAggregationInterval
    }
  })

  if (
    Object.keys(valuesRequested).length === 0 ||
    !doesTargetHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })
  )
    return {}

  return valuesRequested
}

export const insectTrapCatchesContent = ({
  insects,
  selectedValueGroups,
  selectedFieldAssets,
  targetScopeId,
  doesTargetHaveValueTypes,
  targetScope,
  targetScopeData,
  compareSeasonsTargetScopeData,
  compareRegionName,
  compareRegionsData,
  insectTrapCatchesConverter,
  CommonReturnItemsTitleChildren,
  commonReturnItemsIdPrefix,
  pestSectionCategory,
  showRegionalData,
}: {
  insects: Record<number, routes.UserAppStartup.TFieldAssetValueTypes.TInsect> | undefined
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
  targetScopeId: string
  doesTargetHaveValueTypes: ({ valuesCurrent, valuesTimeseries }: TPossibleValuesToCheck) => boolean
  targetScope: 'PROPERTY' | 'BLOCK' | 'REGION'
  targetScopeData:
    | routes.Values.Response['blocks']
    | routes.Values.Response['properties']
    | routes.Values.Response['regions']
  compareSeasonsTargetScopeData?: routes.Values.Response['blocks'] | routes.Values.Response['properties']
  compareRegionName?: string | null
  compareRegionsData?: NonNullable<routes.Values.Response['regions']>['string']
  insectTrapCatchesConverter: (
    numberOfCatches?: number | null,
    insectSettings?: { insectId?: number | undefined; decimalPlaces?: number | undefined },
  ) => TUnitConverterTypicalReturn
  CommonReturnItemsTitleChildren?: React.FC<{ valuesTimeseriesToFilterOn: string[] }>
  commonReturnItemsIdPrefix: string
  pestSectionCategory: TPestSectionCategory
  showRegionalData: boolean
}): StackedChartPestSection[] => {
  if (!insects || !targetScopeId) return []

  const { compareSeasonsInterval } = detailsPanelStore.getState()

  const trapCatchesContent: StackedChartPestSection[] = Object.values(insects)
    .filter((insect) => selectedValueGroups[`trap_catches_insect_id_${insect.insectId}` as TValueGroup])
    .map(({ insectId }) => {
      const permission = VIEW_PESTS_TRAP_CATCHES_ID_insectId(insectId)

      const hasPermission = selectedPropertyHasPermission({
        permission,
      })

      const hasRegionPermission = selectedRegionHasPermission({ permission, regionId: targetScopeId })
      const valueTypeKey = getValueTypeIdForInsectId(insectId)

      const commonReturnItems = {
        title: insectTrapCatchesConverter(null, { insectId }).titleWithoutUnit(),
        titleChildren: CommonReturnItemsTitleChildren ? (
          <CommonReturnItemsTitleChildren valuesTimeseriesToFilterOn={[valueTypeKey]} />
        ) : null,
        id: `${commonReturnItemsIdPrefix}-${insectId}-${targetScopeId}`,
        pestSectionCategory,
        insectId,
      }

      if (!hasPermission && !isUserOnlyAFreeRegionalUser()) {
        return { ...propertyLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!hasRegionPermission && isUserOnlyAFreeRegionalUser()) {
        return { ...regionLacksPermissionSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      if (!doesTargetHaveValueTypes({ valuesTimeseries: [valueTypeKey] })) {
        return { ...blockLacksTrapsSectionMaker(commonReturnItems), pestSectionCategory, insectId }
      }

      const series: TChartSeries[] = []
      const timestampToCountDictionary: Record<string, number | null> = {}
      const traps = targetScopeData?.[targetScopeId]?.values?.[valueTypeKey] ?? []
      const timeseries = Array.isArray(traps[0]?.timeseries) ? traps[0].timeseries : []

      timeseries.forEach((ts?: { timestamp: string; value: number | null }) => {
        const { timestamp, value } = ts ?? {}

        if (!isNil(timestamp)) {
          timestampToCountDictionary[timestamp] = timestampToCountDictionary[timestamp] ?? null

          if (!isNil(value)) {
            timestampToCountDictionary[timestamp] = value
          }
        }
      })

      const trapCatchesSeries: TChartSeries & { color?: TRGBAColorWithOpacityAtTheEnd } = {
        id: `insect-trap-catches-insect-id-${insectId}-series`,
        borderWidth: 2,
        name: `${insectTrapCatchesConverter().categoryTitleWithoutUnit()} ${
          targetScope === 'REGION' ? '(Avg Trap Catches)' : ''
        }`,
        tooltip: {
          valueDecimals: insectTrapCatchesConverter().defaultNumberOfDecimalPlaces(),
          valueSuffix: ` ${insectTrapCatchesConverter().suffix()}`,
        },
        yAxis: 0,
        data: Object.entries(timestampToCountDictionary).map(([timestamp, value]) => [
          +new Date(timestamp),
          insectTrapCatchesConverter(value).value(),
        ]),
        type: 'area',
        step: 'center',
        clip: false,
      }

      series.push(trapCatchesSeries)

      if (
        showRegionalData &&
        compareRegionsData &&
        doesSelectedRegionHaveValueTypes({
          selectedFieldAssets,
          valuesTimeseries: [`insectTrapCatches_${insectId}` as TValueGroup],
        })
      ) {
        const timestampToCountDictionaryRS: Record<string, number | null> = {}
        const trapsRS = compareRegionsData?.values?.[valueTypeKey] ?? []

        trapsRS[0]?.timeseries?.forEach((ts?: { timestamp: string; value: number | null }) => {
          const { timestamp, value } = ts ?? {}

          if (!isNil(timestamp)) {
            timestampToCountDictionaryRS[timestamp] = timestampToCountDictionaryRS[timestamp] ?? null

            if (!isNil(value)) {
              timestampToCountDictionaryRS[timestamp] = value
            }
          }
        })

        series.push(
          makeRegionalSeriesFromRegularSeries(trapCatchesSeries, {
            name: compareRegionName ? `${compareRegionName} (Avg Trap Catches)` : undefined,
            data: Object.entries(timestampToCountDictionaryRS).map(([timestamp, value]) => [
              +new Date(timestamp),
              insectTrapCatchesConverter(value).value(),
            ]),
          }),
        )
      }

      if (!showRegionalData && compareSeasonsInterval) {
        const timestampToCountDictionaryCS: Record<string, number | null> = {}
        const trapsCS = compareSeasonsTargetScopeData?.[targetScopeId]?.values?.[valueTypeKey] ?? []

        trapsCS[0]?.timeseries?.forEach((ts?: { timestamp: string; value: number | null }) => {
          const { timestamp, value } = ts ?? {}

          if (!isNil(timestamp)) {
            timestampToCountDictionaryCS[timestamp] = timestampToCountDictionaryCS[timestamp] ?? null

            if (!isNil(value)) {
              timestampToCountDictionaryCS[timestamp] = value
            }
          }
        })

        series.push(
          makeCompareSeasonsSeriesFromRegularSeries(trapCatchesSeries, {
            data: Object.entries(timestampToCountDictionaryCS).map(([timestamp, value]) => [
              +new Date(timestamp),
              insectTrapCatchesConverter(value).value(),
            ]),
          }),
        )
      }

      return {
        ...commonReturnItems,
        items: [
          {
            childrenUpper: (
              <div css={{ textAlign: 'right' }}>
                <Summary
                  insectId={insectId}
                  regionId={targetScope === 'REGION' ? targetScopeId : null}
                  propertyId={targetScope === 'PROPERTY' ? +targetScopeId : null}
                />
              </div>
            ),
            chartConfig: {
              semiosHighchartsAdditions: {
                id: insectTrapCatchesConverter(null, { insectId }).titleWithoutUnit(),
                firstForecastTimestamp: +new Date(),
              },
              yAxis: {
                tickInterval: 1,
                allowDecimals: false,
                min: 0,
              },
              chart: {
                type: 'line',
              },
              tooltip: {
                xDateFormat: getXDateFormat(),
              },
              series,
            },
          },
        ],
      }
    })
    .sort(sortByKey('title'))

  return trapCatchesContent
}
