import { Popover, Switch } from '@mantine/core'
import { IconDataSource } from 'components/icons/IconDataSource'
import { IconQuestionMark } from 'components/icons/IconQuestionMark'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { smallStore } from 'stores/smallStore'
import { updateUserProfile } from 'utils/updateUserProfile'
import { useViewportSizeWithCorrectInitialState } from 'utils/useViewportSizeWithCorrectInitialState'

export const DataSourceSwitch = () => {
  const { showDataSource, showDataSourceTooltip } = smallStore.useSelector((s) => ({
    showDataSource: s.showDataSource,
    showDataSourceTooltip: s.showDataSourceTooltip,
  }))

  const { width } = useViewportSizeWithCorrectInitialState()
  const fullSizePopover = width < 660

  const handleUseDataSourceChange = (newValue: boolean) => {
    smallStore.setState((s) => ({ ...s, showDataSource: newValue }))

    try {
      updateUserProfile({ showDataSource: newValue })
    } catch (error) {}
  }

  return (
    <>
      <Switch
        label={translate.phrases.banyanApp('Data Source')}
        checked={showDataSource}
        onChange={(e) => {
          handleUseDataSourceChange(e.currentTarget.checked)
        }}
        styles={{
          label: { color: 'white', cursor: 'unset', fontSize: 16 },
          track: {
            width: 36,
            height: 20,
            cursor: 'unset',
            backgroundColor: showDataSource ? `${colors.green} !important` : undefined,
            borderColor: showDataSource ? `${colors.green} !important` : undefined,
          },
        }}
      />
      <Popover
        position={fullSizePopover ? undefined : 'right'}
        withArrow
        shadow="md"
        opened={showDataSourceTooltip}
        radius={5}
        width={fullSizePopover ? '100%' : 452}
        styles={{
          dropdown: {
            position: 'fixed',
          },
        }}
      >
        <Popover.Target>
          <div
            onMouseEnter={() => smallStore.setState((prev) => ({ ...prev, showDataSourceTooltip: true }))}
            onMouseLeave={() => smallStore.setState((prev) => ({ ...prev, showDataSourceTooltip: false }))}
            css={{ marginLeft: 10 }}
          >
            <IconQuestionMark />
          </div>
        </Popover.Target>
        <Popover.Dropdown
          css={{ pointerEvents: 'none', color: colors.midnight, fontSize: 14, fontWeight: '400' }}
        >
          <div>
            <IconDataSource />
          </div>
          <br />
          <div>
            {translate.phrases.banyanApp(
              'When data from a device is unavailable, an estimate will be provided based on neighboring devices or 3rd party sources.',
            )}
          </div>
          <br />
          <div>
            {translate.phrases.banyanApp(
              'The Data Source indicator is only displayed when device data is unavailable. This feature is only available for temperature, humidity, wet bulb, and dew point at this time.',
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </>
  )
}
