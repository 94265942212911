import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconRainOutline = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 360 314')}>
      <g clipPath="url(#clip0_18_926)">
        <g clipPath="url(#clip1_18_926)">
          <path
            d="M296 223.999C310.852 223.999 325.096 218.099 335.598 207.597C346.1 197.095 352 182.851 352 167.999C352 153.147 346.1 138.903 335.598 128.401C325.096 117.899 310.852 111.999 296 111.999L293.5 112.099C298.422 92.6283 296.196 72.0376 287.227 54.0681C278.258 36.0985 263.141 21.9424 244.622 14.1716C226.102 6.40079 205.41 5.53084 186.304 11.7198C167.198 17.9088 150.946 30.746 140.5 47.8993C132.001 42.8508 122.317 40.1419 112.433 40.0478C102.548 39.9538 92.8147 42.4778 84.2215 47.3636C75.6282 52.2494 68.4813 59.3228 63.5069 67.8651C58.5325 76.4074 55.908 86.1142 55.8998 95.9993C55.9262 99.0158 56.1938 102.025 56.6998 104.999C41.9589 107.724 28.7718 115.868 19.7344 127.828C10.6969 139.788 6.46394 154.698 7.86861 169.622C9.27329 184.547 16.2139 198.405 27.3241 208.469C38.4344 218.533 52.9094 224.073 67.8998 223.999"
            stroke="currentColor"
            strokeWidth="15"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g clipPath="url(#clip2_18_926)">
          <path
            d="M124 260C121.878 260 119.843 259.157 118.343 257.657C116.843 256.157 116 254.122 116 252V212C116 209.878 116.843 207.843 118.343 206.343C119.843 204.843 121.878 204 124 204C126.122 204 128.157 204.843 129.657 206.343C131.157 207.843 132 209.878 132 212V252C132 254.122 131.157 256.157 129.657 257.657C128.157 259.157 126.122 260 124 260Z"
            fill="currentColor"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M180 313C177.878 313 175.843 312.157 174.343 310.657C172.843 309.157 172 307.122 172 305V265C172 262.878 172.843 260.843 174.343 259.343C175.843 257.843 177.878 257 180 257C182.122 257 184.157 257.843 185.657 259.343C187.157 260.843 188 262.878 188 265V305C188 307.122 187.157 309.157 185.657 310.657C184.157 312.157 182.122 313 180 313Z"
            fill="currentColor"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M236 278C233.878 278 231.843 277.157 230.343 275.657C228.843 274.157 228 272.122 228 270V230C228 227.878 228.843 225.843 230.343 224.343C231.843 222.843 233.878 222 236 222C238.122 222 240.157 222.843 241.657 224.343C243.157 225.843 244 227.878 244 230V270C244 272.122 243.157 274.157 241.657 275.657C240.157 277.157 238.122 278 236 278Z"
            fill="currentColor"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_18_926"
          x1="111.313"
          y1="210.05"
          x2="136.689"
          y2="253.921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="0.5" stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18_926"
          x1="167.31"
          y1="263.033"
          x2="192.685"
          y2="306.904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="0.5" stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_18_926"
          x1="223.332"
          y1="228.038"
          x2="248.707"
          y2="271.908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="0.5" stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <clipPath id="clip0_18_926">
          <rect width="360" height="314" fill="currentColor" />
        </clipPath>
        <clipPath id="clip1_18_926">
          <rect width="359" height="231" fill="currentColor" transform="translate(0.5 0.5)" />
        </clipPath>
        <clipPath id="clip2_18_926">
          <rect width="129" height="110" fill="currentColor" transform="translate(115.5 203.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
