import { useElementSize } from '@mantine/hooks'
import React from 'react'
import { colors } from 'settings/colors'
import { useScreenSize } from 'utils/useScreenSize'
import { baseZIndex } from '../consts'
import type { GridTableColumn, TopLeftCell } from '../types'
import { Left } from './Left/Left'
import { Right } from './Right/Right'

export const Top = ({
  addShadow,
  columns,
  tableWidth,
  topLeftCell,
  widthForLabelColumn,
  widthForValueColumns,
}: {
  addShadow: boolean
  columns: GridTableColumn[]
  tableWidth: number
  topLeftCell?: TopLeftCell
  widthForLabelColumn: number
  widthForValueColumns: number
}) => {
  const { height } = useElementSize()
  const { isWideScreen } = useScreenSize()

  return (
    <div
      css={{
        position: 'sticky',
        // let's make the upperChildren disappear out of the way on narrow devices
        top: isWideScreen ? 0 : -height,
        zIndex: baseZIndex + 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          display: 'flex',
          width: tableWidth,
          boxShadow: `0px 0px 25px 25px rgba(180, 179, 187, 0${addShadow ? '.7' : ''})`,
          transition: '200ms ease all',
          borderBottom: '1px solid #000000',
          borderTop: `1px solid ${colors.grey500}`,
          background: colors.white,
        }}
      >
        <Left widthForLabelColumn={widthForLabelColumn} topLeftCell={topLeftCell} />
        <Right widthForValueColumns={widthForValueColumns} columns={columns} />
      </div>
    </div>
  )
}
