import { almondBloom } from './by-domain/almondBloom'
import { almondHullSplit } from './by-domain/almondHullSplit'
import { alternaria } from './by-domain/alternaria'
import { beeHours } from './by-domain/beeHours'
import { chill } from './by-domain/chill'
import { dewPoint } from './by-domain/dewPoint'
import { evapotranspiration } from './by-domain/evapotranspiration'
import { fruitGrowth } from './by-domain/fruitGrowth'
import { humidity } from './by-domain/humidity'
import { insectDegreeDays } from './by-domain/insectDegreeDays'
import { insectLarvalTrend } from './by-domain/insectLarvalTrend'
import { insectTrapCatches } from './by-domain/insectTrapCatches'
import { plantStress } from './by-domain/plantStress'
import { precipitation } from './by-domain/precipitation'
import { soil } from './by-domain/soil'
import { temperature } from './by-domain/temperature'
import { walnutBlight } from './by-domain/walnutBlight'
import { wetBulb } from './by-domain/wetBulb'
import { wind } from './by-domain/wind'
import { windMachine } from './by-domain/windMachine'

export const unitConverter = {
  ...temperature,
  ...humidity,
  ...dewPoint,
  ...wetBulb,
  ...precipitation,
  ...evapotranspiration,
  ...wind,
  ...chill,
  ...fruitGrowth,
  ...soil,
  ...insectDegreeDays,
  ...insectLarvalTrend,
  ...insectTrapCatches,
  ...alternaria,
  ...walnutBlight,
  ...beeHours,
  ...almondBloom,
  ...almondHullSplit,
  ...windMachine,
  ...plantStress,
}

export type TUnitConverterFunction = typeof unitConverter[keyof typeof unitConverter]
