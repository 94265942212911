import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'
import type { TRGBAColorWithOpacityAtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'

type TIconCaretProps = {
  width?: string
  rotate?: boolean
  hovered?: boolean
  fillColor?: TRGBAColorWithOpacityAtTheEnd
  hoverColor?: TRGBAColorWithOpacityAtTheEnd
}

export const IconCaret = ({
  width,
  rotate = false,
  hovered = false,
  fillColor = colors.blue800,
  hoverColor = colors.white,
}: TIconCaretProps) => {
  const baseProps = { ...makeBaseSvgIconProps('0 0 12 6') }

  if (width) {
    baseProps.width = width
  }

  const css = {
    transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease',
  }

  return (
    <svg {...baseProps} css={css}>
      <path d="M5.5 6L10.2631 0H0.73686L5.5 6Z" fill={hovered ? hoverColor : fillColor} />
    </svg>
  )
}
