import { ActionIcon } from '@mantine/core'
import { IconEmailDisabled } from 'components/icons/IconEmailDisabled'
import { IconEmailEnabled } from 'components/icons/IconEmailEnabled'
import { ICON_SIZE } from './ContactList'

export const EmailIcon = ({
  isEnabled,
  handleEmailIconClick,
}: {
  isEnabled: boolean
  handleEmailIconClick: () => void
}) => {
  return (
    <ActionIcon onClick={handleEmailIconClick}>
      <div css={{ fontSize: ICON_SIZE, display: 'flex', alignItems: 'center' }}>
        {isEnabled ? <IconEmailEnabled /> : <IconEmailDisabled />}
      </div>
    </ActionIcon>
  )
}
