import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from '@mantine/core'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { translate } from 'i18n/i18n'
import { isEqual } from 'lodash'
import type { ChangeEventHandler } from 'react'
import React, { memo } from 'react'
import { colors } from 'settings/colors'
import { useSearchInput } from 'utils/useSearchInput'

const FilterListPopover = ({
  filterIsActive,
  onClear,
  optionsGroups,
  searchPlaceholder = translate.phrases.banyanApp('Search'),
  showSearch = true,
  width = 195,
}: {
  filterIsActive: boolean
  onClear: () => void
  optionsGroups: [
    {
      options: {
        key: string | number
        title: string
        checked: boolean
        onChange: ChangeEventHandler<HTMLInputElement>
      }[]
      title?: string
    },
  ]
  searchPlaceholder?: string
  showSearch?: boolean
  width?: number
}) => {
  const { SearchInput, searchString } = useSearchInput({ placeholder: searchPlaceholder })

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover position="bottom" withArrow shadow="md" withinPortal>
        <Popover.Target css={{ marginLeft: 5 }}>
          <FontAwesomeIcon
            icon={faFilter}
            css={{
              cursor: 'pointer',
              color: filterIsActive ? colors.primary : colors.grey800,
            }}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              width,
              maxWidth: width,
            }}
          >
            {!!showSearch && <div>{SearchInput}</div>}
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                height: 150,
                padding: '4px 0px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {optionsGroups.map((og) => {
                const optionsForThisOptionGroup = arrayOfObjectsSearch(og.options, searchString, ['title'])

                if (!optionsForThisOptionGroup.length) return null

                return (
                  <React.Fragment key={`${og.title}`}>
                    {!!og.title && <div css={{ fontWeight: 'bold' }}>{og.title}</div>}
                    {optionsForThisOptionGroup.map((o) => (
                      <div key={o.key} css={{ padding: '1px 0px 6px 12px' }}>
                        <Checkbox
                          css={{
                            '& label': { fontSize: '1rem', fontWeight: 'normal' },
                          }}
                          key={o.key}
                          label={o.title}
                          checked={o.checked}
                          onChange={o.onChange}
                        />
                      </div>
                    ))}
                  </React.Fragment>
                )
              })}
            </div>
            {!!onClear && filterIsActive && (
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="secondary"
                  css={{ border: '1px solid black' }}
                  onClick={() => {
                    onClear()
                  }}
                >
                  {translate.phrases.banyanApp('Clear')}
                </Button>
              </div>
            )}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}

const MemoizedFilterListPopover = memo(FilterListPopover, isEqual)

export { MemoizedFilterListPopover as FilterListPopover }
