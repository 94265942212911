import { colors } from 'settings/colors'

type BlockWithWeatherStationLabelProps = {
  label: string
  weatherStationLabel: string | null
}

export const BlockWithWeatherStationLabel = ({
  label,
  weatherStationLabel,
}: BlockWithWeatherStationLabelProps) => {
  return (
    <div>
      {label}
      <br />
      <span style={{ color: colors.grey500, fontSize: 'small' }}>{weatherStationLabel}</span>
    </div>
  )
}
