import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconClearNight = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 270 270')}>
      <g clipPath="url(#clip0_11_465)">
        <g clipPath="url(#clip1_11_465)">
          <path
            d="M252.25 168.63C178.13 168.63 118 109.35 118 36.21C118.056 24.9939 119.559 13.8319 122.47 3C55.29 10.25 3 66.37 3 134.58C3 207.71 63.09 267 137.21 267C199.69 267 252 224.82 267 167.79C262.102 168.338 257.178 168.618 252.25 168.63Z"
            fill="url(#paint0_linear_11_465)"
            stroke="#72B9D5"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_465"
          x1="54.33"
          y1="29.03"
          x2="187.18"
          y2="259.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#86C3DB" />
          <stop offset="0.45" stopColor="#86C3DB" />
          <stop offset="1" stopColor="#5EAFCF" />
        </linearGradient>
        <clipPath id="clip0_11_465">
          <rect width="270" height="270" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_465">
          <rect width="270" height="270" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
