import { numberToUUID } from '@capacitor-community/bluetooth-le'
import type { BleDeviceFactory } from '../../BleManager'
import { SemiosBleServiceUUID, SensorCommandStatus, SensorTypeIds } from '../../constants'
import type { Decoder, Encoder } from '../../types'
import { emptyPayload, numberToSemiosUUID } from '../../util/utility'
import { SemiosBleNode } from '../semiosBleNode'
import type { SdiSensorStatus } from './commands'
import {
  AllSdiSensorStatus,
  CurrentTimestamp,
  DetectSdi,
  FirmwareVersion,
  Humidity,
  ProgramSdiSensor,
  RequestAllSdiSensorStatus,
  RequestUplinkRebootMessage,
  Rj11SensorStatus,
  Temperature,
  Vital,
} from './commands'

const DeviceInfoServiceUUID = numberToUUID(0x180a)
const FirmwareVersionUUID = numberToUUID(0x2a26)
const SensorNotificationUUID = numberToSemiosUUID(0x0006)
const ProgramSdiSensorUUID = numberToSemiosUUID(0x0008)
const VitalUUID = numberToSemiosUUID(0x000a)
const TimestampUUID = numberToSemiosUUID(0x000b)
const SdiDetectionUUID = numberToSemiosUUID(0x0012)
const SdiSensorStatusUUID = numberToSemiosUUID(0x0013)
const UplinkRebootMessageUUID = numberToSemiosUUID(0x0014)
const Rj11DetectionUUID = numberToSemiosUUID(0x0015)

const UplinkRebootMessageEncoder: Encoder<RequestUplinkRebootMessage> = {
  type: RequestUplinkRebootMessage,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: UplinkRebootMessageUUID,
  },
  encode: function (): DataView {
    return emptyPayload()
  },
}

const HumidityDecoder: Decoder<Humidity> = {
  type: Humidity,
  notifiable: true,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SensorNotificationUUID,
  },
  decode: function (data: DataView): Humidity | null {
    if (data.getUint8(0) !== SensorCommandStatus.OK) {
      return null
    }

    if (data.getUint8(1) !== SensorTypeIds.ONBOARD_HUMIDITY) {
      return null
    }

    return {
      humidity: data.getUint16(4) / 100.0,
    }
  },
}

const TemperatureDecoder: Decoder<Temperature> = {
  type: Temperature,
  notifiable: true,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SensorNotificationUUID,
  },
  decode: function (data: DataView): Temperature | null {
    if (data.getUint8(0) !== SensorCommandStatus.OK) {
      return null
    }

    if (data.getUint8(1) !== SensorTypeIds.ONBOARD_TEMPERATURE) {
      return null
    }

    return {
      temperature: data.getUint16(4) / 100.0,
    }
  },
}

const CurrentTimestampDecoder: Decoder<CurrentTimestamp> & Encoder<CurrentTimestamp> = {
  type: CurrentTimestamp,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: TimestampUUID,
  },
  decode: function (data: DataView): CurrentTimestamp {
    const date = new Date()

    date.setTime(Number(data.getBigUint64(0)) * 1000)

    return new CurrentTimestamp(date)
  },
  encode: function (data: CurrentTimestamp): DataView {
    const buffer = new ArrayBuffer(8)
    const view = new DataView(buffer)

    view.setBigUint64(0, BigInt(data.timestampInSecond))

    return view
  },
}

const FirmwareDecoder: Decoder<FirmwareVersion> = {
  type: FirmwareVersion,
  uuid: {
    service: DeviceInfoServiceUUID,
    characteristic: FirmwareVersionUUID,
  },
  decode: function (data: DataView): FirmwareVersion | null {
    const decoder = new TextDecoder()
    const version = decoder.decode(data)

    return { version }
  },
}

const VitalDecoder: Decoder<Vital> = {
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: VitalUUID,
  },
  notifiable: true,
  decode: function (data: DataView): Vital | null {
    return {
      lithiumBattery: data.getUint16(0) / 100.0,

      solarBattery: data.getUint16(2) / 100.0,

      alkalineBattery: data.getUint16(4) / 100.0,

      rssi: data.getInt16(6),

      rsrp: data.getInt16(8),
    }
  },
  type: Vital,
}

const SdiDetectionDecoder: Decoder<DetectSdi> = {
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SdiDetectionUUID,
  },
  decode: function (data: DataView): DetectSdi | null {
    if (data.getInt8(0) !== SensorCommandStatus.OK) {
      return {
        commandStatus: data.getInt8(0),
        type: SensorTypeIds.UNKNOWN,
        address: '0',
      }
    }

    return {
      commandStatus: data.getInt8(0),

      type: data.getInt8(1),

      address: String.fromCharCode(data.getInt8(2)),
    }
  },
  type: DetectSdi,
}

const Rj11SensorStatusDecoder: Decoder<Rj11SensorStatus> = {
  type: Rj11SensorStatus,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: Rj11DetectionUUID,
  },
  decode: function (data: DataView): Rj11SensorStatus | null {
    if (data.getInt8(0) !== SensorCommandStatus.OK) {
      return {
        commandStatus: data.getInt8(0),
        sensorType: SensorTypeIds.UNKNOWN,
        sensorStatus: SensorCommandStatus.INVALID,
      }
    }

    return {
      commandStatus: data.getInt8(0),
      sensorType: data.getInt8(1),
      sensorStatus: data.getInt8(2),
    }
  },
}

const RequestAllSdiSensorStatusEncoder: Encoder<RequestAllSdiSensorStatus> = {
  type: RequestAllSdiSensorStatus,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SdiSensorStatusUUID,
  },
  encode: function (): DataView {
    return emptyPayload()
  },
}

const SdiSensorStatusDecoder: Decoder<AllSdiSensorStatus> = {
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: SdiSensorStatusUUID,
  },
  notifiable: true,
  decode: function (data: DataView): AllSdiSensorStatus | null {
    let idx = 0

    const commandStatus = data.getInt8(idx++)

    if (commandStatus !== SensorCommandStatus.OK) {
      return {
        commandStatus,
        sensorCount: 0,
        sensorStatus: [],
      }
    }

    const sensorCount = data.getUint8(idx++)
    const sensorStatus: SdiSensorStatus[] = []

    for (let i = 0; i < sensorCount; i++) {
      sensorStatus.push({
        address: String.fromCharCode(data.getUint8(idx++)),
        type: data.getUint8(idx++),
        status: data.getUint8(idx++),
      })
    }

    return {
      commandStatus,
      sensorCount,
      sensorStatus,
    }
  },
  type: AllSdiSensorStatus,
}

const ProgramSdiSensorEncoder: Encoder<ProgramSdiSensor> = {
  type: ProgramSdiSensor,
  uuid: {
    service: SemiosBleServiceUUID,
    characteristic: ProgramSdiSensorUUID,
  },
  encode: function (data: ProgramSdiSensor): DataView {
    const buffer = new ArrayBuffer(2)
    const view = new DataView(buffer)

    view.setInt8(0, data.isAdd ? 1 : 0)

    view.setInt8(1, data.address.charCodeAt(0))

    return view
  },
}

class LnrBleNode extends SemiosBleNode {}

const LnrBleNodeFactory: BleDeviceFactory = {
  nodeType: 'ln_r',
  creator: (deviceId, nodeId, notfications, listeners) => {
    return new LnrBleNode(deviceId, nodeId, 'ln_r', notfications, listeners)
  },
  convertors: [
    FirmwareDecoder,
    HumidityDecoder,
    VitalDecoder,
    TemperatureDecoder,
    CurrentTimestampDecoder,
    SdiDetectionDecoder,
    SdiSensorStatusDecoder,
    Rj11SensorStatusDecoder,
    ProgramSdiSensorEncoder,
    UplinkRebootMessageEncoder,
    RequestAllSdiSensorStatusEncoder,
  ],
}

export { LnrBleNode, LnrBleNodeFactory }
