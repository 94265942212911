import { useMantineTheme } from '@mantine/core'
import { Button } from 'components/Button/Button'
import { IconLeftArrowCircle } from 'components/icons/IconLeftArrowCircle'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { translate } from 'i18n/i18n'
import React from 'react'

interface FooterProps {
  onPrevious?: () => void
  onNext: () => void
  previousButtonLabel?: string
  previousButtonIcon?: React.ReactNode | null
  nextButtonLabel?: string
  nextButtonIcon?: React.ReactNode | null
  loading?: boolean
  showPreviousButton?: boolean
  disableNextButton?: boolean
}

export const Footer: React.FC<FooterProps> = ({
  onPrevious,
  onNext,
  nextButtonLabel,
  nextButtonIcon = <IconRightArrowCircle />,
  previousButtonLabel,
  previousButtonIcon = <IconLeftArrowCircle />,
  loading = false,
  showPreviousButton = false,
  disableNextButton = false,
}) => {
  const theme = useMantineTheme()
  const footerHeight = '60px'

  return (
    <div //padding for the footer, so it doesn't overlap with the content
      css={{
        '--footer_height': footerHeight,
        'display': 'absolute',
        'height': 'var(--footer_height)',
      }}
    >
      <div
        css={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          backgroundColor: theme.colors.grey[0],
          borderTop: `2px solid ${theme.colors.grey[1]}`,
          width: '100%',
          height: 'var(--footer_height)',
          padding: 10,
          paddingBottom: 'max(10px, env(safe-area-inset-bottom))',
          display: 'flex',
          justifyContent: showPreviousButton ? 'space-between' : 'flex-end',
          zIndex: 10,
        }}
      >
        {showPreviousButton && (
          <Button
            variant="secondary"
            leftIcon={previousButtonIcon}
            css={{ flex: '0 0 auto' }}
            onClick={onPrevious}
          >
            {previousButtonLabel || translate.phrases.placeholder('Back')}
          </Button>
        )}

        <Button
          variant="primary"
          rightIcon={nextButtonIcon}
          css={{ marginLeft: 10, flex: '0 0 auto' }}
          onClick={onNext}
          disabled={disableNextButton}
          loading={loading}
        >
          {nextButtonLabel || translate.phrases.placeholder('Next')}
        </Button>
      </div>
    </div>
  )
}
