import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const valueGroupsToUnitConverterFunctionForSaving: Record<
  TValueGroup,
  typeof unitConverter[keyof typeof unitConverter] | undefined // better to put undefined than to make this whole thing a partial
> = {
  air_temperature: (val?: number | null) =>
    unitConverter.temperatureInCanopy(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  almond_bloom: unitConverter.almondBloom,
  almond_hull_split: undefined,
  alternaria: unitConverter.alternariaCumulativeRolling7Days,
  atmospheric_pressure: undefined,
  bee_hours: (val?: number | null) => unitConverter.beeHours(val, undefined),
  chill: unitConverter.chillCumulativeHoursBelow45FSEP, // feels weird, since this'll change, but we'll change to using value-type definitions in the future
  conditions: undefined,
  wind_machine: unitConverter.windMachineTemperature,
  degree_days_insect_id_1: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_10: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_11: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_12: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_13: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_14: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_15: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_16: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_17: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_18: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_19: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_2: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_20: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_23: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_24: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_25: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_26: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_27: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_28: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_29: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_30: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_3: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_4: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_6: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_7: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_8: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  degree_days_insect_id_9: (val?: number | null) =>
    unitConverter.insectDegreeDaysCumulative(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  dew_point: (val?: number | null) =>
    unitConverter.dewPointInCanopy(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  equipment_status: undefined,
  evapotranspiration: (val?: number | null) =>
    unitConverter.evapotranspiration(val, {
      inputUnit: userDetailsStore.getState().rain === 'METRIC' ? 'mm' : 'in',
      outputUnit: 'mm',
    }),
  fruit_growth: unitConverter.fruitGrowth,
  plant_stress: unitConverter.plantWaterStress,
  humidity: unitConverter.humidityInCanopy,
  irrigation_activity: undefined,
  larval_trend_insect_id_1: undefined,
  larval_trend_insect_id_11: undefined,
  larval_trend_insect_id_20: undefined,
  larval_trend_insect_id_3: undefined,
  larval_trend_insect_id_6: undefined,
  larval_trend_insect_id_7: undefined,
  larval_trend_insect_id_9: undefined,
  leaf_wetness: undefined,
  ndvi: undefined,
  precipitation: (val?: number | null) =>
    unitConverter.precipitation(val, {
      inputUnit: userDetailsStore.getState().rain === 'METRIC' ? 'mm' : 'in',
      outputUnit: 'mm',
    }),
  soil: unitConverter.soilAwc,
  spray_conditions: undefined,
  trap_catches_insect_id_1: unitConverter.insectTrapCatches,
  trap_catches_insect_id_10: unitConverter.insectTrapCatches,
  trap_catches_insect_id_11: unitConverter.insectTrapCatches,
  trap_catches_insect_id_12: unitConverter.insectTrapCatches,
  trap_catches_insect_id_15: unitConverter.insectTrapCatches,
  trap_catches_insect_id_16: unitConverter.insectTrapCatches,
  trap_catches_insect_id_17: unitConverter.insectTrapCatches,
  trap_catches_insect_id_19: unitConverter.insectTrapCatches,
  trap_catches_insect_id_2: unitConverter.insectTrapCatches,
  trap_catches_insect_id_20: unitConverter.insectTrapCatches,
  trap_catches_insect_id_25: unitConverter.insectTrapCatches,
  trap_catches_insect_id_27: unitConverter.insectTrapCatches,
  trap_catches_insect_id_28: unitConverter.insectTrapCatches,
  trap_catches_insect_id_29: unitConverter.insectTrapCatches,
  trap_catches_insect_id_30: unitConverter.insectTrapCatches,
  trap_catches_insect_id_6: unitConverter.insectTrapCatches,
  trap_catches_insect_id_7: unitConverter.insectTrapCatches,
  trap_catches_insect_id_9: unitConverter.insectTrapCatches,
  walnut_blight: unitConverter.walnutBlightRPVCumulativeRolling7Days,
  wet_bulb: (val?: number | null) =>
    unitConverter.wetBulbInCanopy(val, {
      inputUnit: userDetailsStore.getState().temperature === 'METRIC' ? 'c' : 'f',
      outputUnit: 'c',
    }),
  wind: (val?: number | null) =>
    unitConverter.windSpeed(val, {
      inputUnit: userDetailsStore.getState().speed === 'METRIC' ? 'kph' : 'mph',
      outputUnit: 'mps',
    }),
}
