export const plotOptionsToHideInactiveSeriesHarderMaker = () => {
  return {
    series: {
      states: {
        inactive: {
          opacity: 0.08,
        },
      },
    },
  }
}
