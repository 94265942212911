import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import type { TFieldAssetValueTypes } from 'App/Map/types'
import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { useState } from 'react'
import { sortByKey } from 'utils/sortByKey'
import { DeleteEmitterGroupModal } from './DeleteEmitterGroupModal'
import { EditEmitterGroupModal } from './EditEmitterGroupModal'
import { PropertySection } from './PropertySection'

export const GroupsTab = ({
  filterPropertiesText,
  properties,
}: {
  filterPropertiesText: string
  properties: SharedTypes.TProperty[]
}) => {
  const [selectedZoneGroupEdit, setSelectedZoneGroupEdit] = useState<
    (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  >(null)

  const [selectedZoneGroupDelete, setSelectedZoneGroupDelete] = useState<
    (TFieldAssetValueTypes.TIrrigationZoneEmitterGroup & { propertyId: number }) | null
  >(null)

  const isSearchMatch = properties.some(
    (property) =>
      arrayOfObjectsSearch(Object.values(property.irrigationZoneEmitterGroups ?? {}), filterPropertiesText, [
        'groupName',
      ]).length > 0,
  )

  return (
    <>
      {isSearchMatch ? (
        properties.map((property) => (
          <PropertySection
            key={property.propertyId}
            propertyId={property.propertyId}
            propertyName={property.propertyName}
            zoneGroups={Object.values(property.irrigationZoneEmitterGroups ?? {}).sort(
              sortByKey('groupName'),
            )}
            filterPropertiesText={filterPropertiesText}
            setSelectedZoneGroupEdit={setSelectedZoneGroupEdit}
            setSelectedZoneGroupDelete={setSelectedZoneGroupDelete}
          />
        ))
      ) : (
        <SettingsSearchNotFound />
      )}
      <EditEmitterGroupModal
        selectedZoneGroupEdit={selectedZoneGroupEdit}
        setSelectedZoneGroupEdit={setSelectedZoneGroupEdit}
      />
      <DeleteEmitterGroupModal
        selectedZoneGroupDelete={selectedZoneGroupDelete}
        setSelectedZoneGroupDelete={setSelectedZoneGroupDelete}
      />
    </>
  )
}
