import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconFern = () => (
  <svg {...makeBaseSvgIconProps('0 0 16 19')}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.90486 3.48632C9.90486 2.25349 9.16757 0.48703 8.0001 0C6.83263 0.48703 6.09534 2.25349 6.09534 3.48632C6.09534 4.71915 6.83236 6.4856 8.0001 6.97263C9.1673 6.48588 9.90486 4.71942 9.90486 3.48632Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.922 9.50122C10.9417 10.3514 12.8538 10.1525 13.9252 9.53118C14.9966 8.91097 16.1556 7.32966 15.9828 5.98969C14.9637 5.14041 13.0518 5.3384 11.9804 5.95917C10.909 6.58051 9.7492 8.16126 9.922 9.50122Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9253 14.9568C14.9966 14.336 16.1556 12.7548 15.9828 11.4154C14.9637 10.5656 13.052 10.7635 11.9806 11.3849C10.9085 12.0056 9.74898 13.5869 9.92205 14.9268C10.9411 15.7769 12.8532 15.5773 13.9253 14.9568Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.07552 9.53119C3.1467 10.1525 5.05955 10.3514 6.07792 9.50124C6.25129 8.16187 5.09135 6.5806 4.02045 5.95927C2.94844 5.33851 1.03614 5.14025 0.017219 5.98979C-0.155596 7.32973 1.00406 8.911 2.07552 9.53119Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.07552 14.9567C3.1467 15.5774 5.05955 15.7768 6.07792 14.9268C6.25129 13.587 5.09135 12.0059 4.02045 11.3852C2.94927 10.7639 1.03614 10.5651 0.017219 11.4157C-0.155596 12.7549 1.00406 14.336 2.07552 14.9567Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85706 8.09728V18.5342C6.85706 18.7283 7.36763 18.8859 7.9995 18.8859C8.63055 18.8859 9.14277 18.7283 9.14277 18.5342V8.09728C9.14277 7.90318 8.63055 7.74561 7.9995 7.74561C7.36845 7.74599 6.85706 7.90356 6.85706 8.09728Z"
      fill="currentColor"
    />
  </svg>
)
