import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconWindMachine = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 21')}>
      <path
        d="M10.5 10.5C10.5 7.63636 12.8864 5.25 15.75 5.25C18.6136 5.25 21 7.63636 21 10.5H10.5ZM10.5 10.5C10.5 13.3636 8.11364 15.75 5.25 15.75C2.38636 15.75 0 13.3636 0 10.5H10.5ZM10.5 10.5C7.63636 10.5 5.25 8.11364 5.25 5.25C5.25 2.38636 7.63636 0 10.5 0V10.5ZM10.5 10.5C13.3636 10.5 15.75 12.8864 15.75 15.75C15.75 18.6136 13.3636 21 10.5 21V10.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
