import { NumberInput, Select, Switch } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import type { Rule } from '../../../../settings/alertSettings'
import { AlertsSettings, unitTempDisplay } from '../../../../settings/alertSettings'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'

export const FrostThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.frost)
  })

  const { belowTemperature: belowTemperatureRule, inversion: inversionRule } =
    alertRulesObjectFromArray(rules)

  const belowTemperature = belowTemperatureRule ? belowTemperatureRule.value : null
  const inversionTemperature = inversionRule ? inversionRule.value : null
  const inversionOperator = inversionRule ? inversionRule.operator : '>='
  const inversionActive = !!inversionRule
  // check if user-enterable fields are dirty
  const belowTemperatureIsDirty = belowTemperature !== null || triedToSubmit
  const inversionTemperatureIsDirty = inversionTemperature !== null || triedToSubmit
  // validate the user-entered values
  const belowTemperatureError = AlertsValidators.temperature({ temperature: belowTemperature })
  const inversionOperatorError = inversionActive && AlertsValidators.operator({ operator: inversionOperator })

  const inversionTemperatureError =
    inversionActive && AlertsValidators.temperatureInversion({ temperature: inversionTemperature })

  // attach a status
  const belowTemperatureValidateStatus = validateStatusErrorHelper(
    belowTemperatureIsDirty && !!belowTemperatureError,
  )

  const inversionTemperatureValidateStatus = validateStatusErrorHelper(
    !!inversionActive &&
      inversionTemperatureIsDirty &&
      (!!inversionOperatorError || !!inversionTemperatureError),
  )

  // attach an error message
  const belowTemperatureHelp = helpTextErrorHelper(belowTemperatureIsDirty && belowTemperatureError)

  const inversionTemperatureHelp = helpTextErrorHelper(
    inversionActive && inversionTemperatureIsDirty && (inversionOperatorError || inversionTemperatureError),
  )

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!belowTemperatureError && !inversionOperatorError && !inversionTemperatureError)
  }, [belowTemperature, inversionActive, inversionOperator, inversionTemperature])

  const unitTemp = unitTempDisplay()

  const setInversionActive = (truthVal: boolean) => {
    if (truthVal)
      updateAlertRules({
        rules,
        setRules,
        rulesToChange: { inversion: { operator: '>=', unit: 'inversion', value: null } },
      })
    else updateAlertRules({ rules, setRules, rulesToChange: { inversion: null } })
  }

  const errorStyle = getErrorStyle()

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when ground temperature is less than or equal to <numberInput /> {{unitTemp}}"
          c={{
            numberInput: (
              <TranslateComponentWrapper>
                <NumberInput
                  css={{ maxWidth: 80, margin: '0 5px' }}
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { belowTemperature: { value } } })
                  }
                  type="number"
                  classNames={{ input: belowTemperatureValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(belowTemperature) ? Number(belowTemperature) : ''}
                  precision={10}
                  removeTrailingZeros={true}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
          v={{ unitTemp }}
        />
      </TranslatePhraseWrapper>
      {belowTemperatureHelp && <ErrorTextWrapper>{belowTemperatureHelp}</ErrorTextWrapper>}
      <div
        css={{ marginTop: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Switch checked={inversionActive} onChange={() => setInversionActive(!inversionActive)} />
          <span css={{ cursor: 'pointer' }} onClick={() => setInversionActive(!inversionActive)}>
            &nbsp;{translate.phrases.banyanApp('and the inversion is')}&nbsp;
          </span>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...fadeInOutStylesMaker(inversionActive),
          }}
        >
          <TranslateComponentWrapper>
            <Select
              onChange={(value) =>
                updateAlertRules({ rules, setRules, rulesToChange: { inversion: { operator: value } } })
              }
              css={{ width: 'auto !important' }}
              value={inversionOperator}
              data={[
                { value: '>=', label: translate.phrases.banyanApp('greater than or equal to') },
                { value: '<=', label: translate.phrases.banyanApp('less than or equal to') },
              ]}
            />
            <div />
          </TranslateComponentWrapper>
          &nbsp;
          <TranslateComponentWrapper>
            <NumberInput
              onChange={(value) =>
                updateAlertRules({ rules, setRules, rulesToChange: { inversion: { value } } })
              }
              css={{ width: 80 }}
              type="number"
              classNames={{ input: inversionTemperatureValidateStatus ? errorStyle : undefined }}
              value={isNumberOrNumericString(inversionTemperature) ? Number(inversionTemperature) : ''}
              precision={10}
              removeTrailingZeros={true}
            />
            &nbsp;
            {unitTemp}
          </TranslateComponentWrapper>
        </div>
      </div>
      <div>{inversionTemperatureHelp && <ErrorTextWrapper>{inversionTemperatureHelp}</ErrorTextWrapper>}</div>
    </>
  )
}
