export const IconDataSource = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.792969" y="0.273621" width="21.1878" height="21.1878" rx="3" fill="#979797" />
      <circle cx="4.30419" cy="8.59416" r="1.66283" fill="#131313" />
      <circle cx="12.4924" cy="4.4251" r="1.66283" fill="#131313" />
      <circle cx="18.4695" cy="12.9864" r="1.66283" fill="#131313" />
      <path
        d="M13.6783 14.3944C14.3019 14.1112 15.5491 13.0479 15.5491 11.0608C15.5491 9.07366 14.3019 7.92322 13.6783 7.59639"
        stroke="black"
      />
      <path
        d="M8.5544 14.3944C7.9308 14.1112 6.68359 13.0479 6.68359 11.0608C6.68359 9.07366 7.9308 7.92322 8.5544 7.59639"
        stroke="black"
      />
      <path
        d="M9.46414 9.45978C9.19135 9.59343 8.64575 10.0951 8.64575 11.0328C8.64575 11.9704 9.19135 12.5133 9.46414 12.6675"
        stroke="black"
      />
      <path
        d="M12.9511 9.45978C13.2239 9.59343 13.7695 10.0951 13.7695 11.0328C13.7695 11.9704 13.2239 12.5133 12.9511 12.6675"
        stroke="black"
      />
      <path
        d="M8.32959 18.2333L11.0932 11.5515C11.1275 11.4685 11.2454 11.4694 11.2786 11.5528L13.9312 18.2333"
        stroke="black"
      />
    </svg>
  )
}
