import { Checkbox as MantineCheckbox, Grid, NumberInput } from '@mantine/core'
import { sortByKey } from '@semios/app-platform-common'
import { almondCultivarsSelector } from 'App/Map/_utils/almondCultivarsSelector'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import type { Rule } from '../../../../settings/alertSettings'
import { AlertsSettings } from '../../../../settings/alertSettings'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'

export const AlmondHullSplitThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  const almondCultivars = fieldAssetStore.useSelector(almondCultivarsSelector)

  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.almondHullSplit)
  })

  const { daysBefore: daysBeforeRule, cultivarId: cultivarIdRule } = alertRulesObjectFromArray(rules)
  const daysBefore = daysBeforeRule ? daysBeforeRule.value : null

  const cultivarId =
    cultivarIdRule && typeof cultivarIdRule.value === 'string'
      ? cultivarIdRule.value.split(',').map(String)
      : null

  // we store the cultivarIds as a CSV string, so we need to convert it back to an array of numbers
  // check if user-enterable fields are dirty
  const daysBeforeIsDirty = daysBefore !== null || triedToSubmit
  // validate the user-entered values
  const daysBeforeError = AlertsValidators.almondHullSplitDaysBefore({ daysBefore: daysBefore as number })
  const cultivarIdError = AlertsValidators.cultivarId({ cultivarId })
  // attach a status
  const daysBeforeValidateStatus = validateStatusErrorHelper(daysBeforeIsDirty && !!daysBeforeError)
  const cultivarIdValidateStatus = validateStatusErrorHelper(triedToSubmit && !!cultivarIdError)
  // NOTE: ^ evaluate triedToSubmit to show cultivar errors… an unusual case for this threshold
  // attach an error message
  const daysBeforeHelp = helpTextErrorHelper(daysBeforeIsDirty && daysBeforeError)
  const cultivarIdHelp = helpTextErrorHelper(triedToSubmit && cultivarIdError)
  // NOTE: ^ evaluate triedToSubmit to show cultivar errors… an unusual case for this threshold
  const errorStyle = getErrorStyle()

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!cultivarIdError && !daysBeforeError)
  }, [cultivarId, daysBefore])

  const checkboxOptions = almondCultivars
    .map((src) => {
      return {
        label: src.name,
        value: src.id,
      }
    })
    .sort(sortByKey('label'))

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when almond hull split will occur in <numberInput/> for the following varieties:"
          c={{
            numberInput: (
              <TranslateComponentWrapper>
                <NumberInput
                  css={{ width: 90, margin: '0 5px' }}
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { daysBefore: { value } } })
                  }
                  placeholder={translate.phrases.banyanApp('e.g. {{example}}', { example: 15 })}
                  type="number"
                  classNames={{ input: daysBeforeValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(daysBefore) ? Number(daysBefore) : ''}
                />
                <div>{`${translate.phrases.banyanApp('days')},`}</div>
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      {daysBeforeHelp && <ErrorTextWrapper>{daysBeforeHelp}</ErrorTextWrapper>}
      <div css={{ marginTop: 30 }}>
        <MantineCheckbox.Group
          onChange={(value) =>
            updateAlertRules({ rules, setRules, rulesToChange: { cultivarId: { value: value.join(',') } } })
          }
          value={cultivarId || []}
        >
          <Grid>
            {checkboxOptions.map((cbo) => {
              return (
                <Grid.Col span={3} key={cbo.value}>
                  <Checkbox
                    label={cbo.label}
                    value={cbo.value}
                    classNames={{ input: cultivarIdValidateStatus ? errorStyle : undefined }}
                  />
                </Grid.Col>
              )
            })}
          </Grid>
        </MantineCheckbox.Group>
      </div>
      {cultivarIdHelp && <ErrorTextWrapper>{cultivarIdHelp}</ErrorTextWrapper>}
    </>
  )
}
