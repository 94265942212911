import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconGroups = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 35 35')}>
      <rect x="0.881348" width="35" height="35" rx="17.5" fill="#464B53" />
      <path
        d="M18.3813 18.2188C19.9434 18.2188 21.3234 18.5925 22.4447 19.0812C23.4797 19.5412 24.1313 20.5762 24.1313 21.6975V23.25H12.6313V21.7071C12.6313 20.5762 13.283 19.5412 14.318 19.0908C15.4393 18.5925 16.8193 18.2188 18.3813 18.2188ZM10.7147 18.4583C11.7688 18.4583 12.6313 17.5958 12.6313 16.5417C12.6313 15.4875 11.7688 14.625 10.7147 14.625C9.66051 14.625 8.79801 15.4875 8.79801 16.5417C8.79801 17.5958 9.66051 18.4583 10.7147 18.4583ZM11.7976 19.5125C11.443 19.455 11.0884 19.4167 10.7147 19.4167C9.76593 19.4167 8.8651 19.6179 8.05051 19.9725C7.34135 20.2792 6.88135 20.9692 6.88135 21.7454V23.25H11.1938V21.7071C11.1938 20.9117 11.4143 20.1642 11.7976 19.5125ZM26.048 18.4583C27.1022 18.4583 27.9647 17.5958 27.9647 16.5417C27.9647 15.4875 27.1022 14.625 26.048 14.625C24.9938 14.625 24.1313 15.4875 24.1313 16.5417C24.1313 17.5958 24.9938 18.4583 26.048 18.4583ZM29.8813 21.7454C29.8813 20.9692 29.4213 20.2792 28.7122 19.9725C27.8976 19.6179 26.9968 19.4167 26.048 19.4167C25.6743 19.4167 25.3197 19.455 24.9651 19.5125C25.3484 20.1642 25.5688 20.9117 25.5688 21.7071V23.25H29.8813V21.7454ZM18.3813 11.75C19.9722 11.75 21.2563 13.0342 21.2563 14.625C21.2563 16.2158 19.9722 17.5 18.3813 17.5C16.7905 17.5 15.5063 16.2158 15.5063 14.625C15.5063 13.0342 16.7905 11.75 18.3813 11.75Z"
        fill="white"
      />
    </svg>
  )
}
