import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconClearDay = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 384 384')}>
      <g clipPath="url(#clip0_11_461)">
        <g clipPath="url(#clip1_11_461)">
          <path
            d="M192 276C238.392 276 276 238.392 276 192C276 145.608 238.392 108 192 108C145.608 108 108 145.608 108 192C108 238.392 145.608 276 192 276Z"
            fill="url(#paint0_linear_11_461)"
            stroke="#F8AF18"
            strokeWidth="6"
            strokeMiterlimit="10"
          />
          <path
            d="M192 61.66V12M192 372V322.34M284.17 99.83L319.28 64.72M64.72 319.28L99.83 284.17M99.83 99.83L64.72 64.72M319.28 319.28L284.17 284.17M61.66 192H12M372 192H322.34"
            stroke="#FBBF24"
            strokeWidth="24"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_461"
          x1="149.99"
          y1="119.24"
          x2="234.01"
          y2="264.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBBF24" />
          <stop offset="0.45" stopColor="#FBBF24" />
          <stop offset="1" stopColor="#F59E0B" />
        </linearGradient>
        <clipPath id="clip0_11_461">
          <rect width="384" height="384" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_461">
          <rect width="384" height="384" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
