import type {
  TActiveGateway,
  TActiveNode,
  TNodeLog,
  TNodeServiceIssueSummary,
  TPlannedGateway,
  TPlannedNode,
} from '../../types'
import type { Filters } from '../equipmentStatusPanelStore'
import { equipmentStatusPanelStore, INITIAL_FILTERS } from '../equipmentStatusPanelStore'

export const actions = {
  setNodes: (nodes: Array<TActiveNode | TActiveGateway | TPlannedNode | TPlannedGateway>) => {
    equipmentStatusPanelStore.setState((s) => ({ ...s, nodes }))
  },
  setNodeLogs: (nodeLogs: Record<string, TNodeLog>) => {
    equipmentStatusPanelStore.setState((s) => ({ ...s, nodeLogs }))
  },
  setServiceIssues: (serviceIssues: Record<string, TNodeServiceIssueSummary[]>) => {
    equipmentStatusPanelStore.setState((s) => ({ ...s, serviceIssues }))
  },
  setPropertyId: (propertyId: number) => {
    equipmentStatusPanelStore.setState((s) => ({ ...s, propertyId }))
  },
  setFilters: (filters: Partial<Filters>) => {
    equipmentStatusPanelStore.setState((s) => ({
      ...s,
      filters: {
        ...s.filters,
        ...filters,
      },
    }))
  },
  resetFilters: () => {
    equipmentStatusPanelStore.setState((s) => ({
      ...s,
      filters: { ...INITIAL_FILTERS },
    }))
  },
}
