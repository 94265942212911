import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import type { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import { getSoilAWCBackgroundColor } from 'App/Map/_utils/getSoilAWCBackgroundColor'
import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import { removeCmOrInFromSoilProbeDepths } from 'utils/removeCmOrInFromSoilProbeDepths'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { getValueType } from './_utils/getValueType'

const pointValueType = 'soilAWC_eachDepth_pct'

export type TSoilCurrentValueMapOptionSingleDepth = NonNullable<
  TFieldAssetValueTypes.TPoint['configuration']['soilProbeDepthsAvailable']
>[0]

export type TSoilCurrentValueMapOptionMultipleDepths = 'DEFAULT'

export type TSoilCurrentValueMapOption =
  | TSoilCurrentValueMapOptionSingleDepth
  | TSoilCurrentValueMapOptionMultipleDepths

const getValueFromDictionaryOfValues = ({
  lngLat,
  propertyId,
  selectedOption,
  value,
}: {
  lngLat: TFieldAssetKeyTypes.TLngLat
  propertyId: TFieldAssetKeyTypes.TPropertyId
  selectedOption: TSoilCurrentValueMapOption
  value: Record<number, number | null> | null
}): number | null => {
  if (isNil(value)) return null

  const defaultDepths =
    fieldAssetStore.getState().properties?.[propertyId]?.points?.[lngLat]?.configuration
      .soilDefaultProbeDepths ?? []

  const mappedDefaultDepths = defaultDepths.map(removeCmOrInFromSoilProbeDepths)

  const aggregate = Object.entries(value ?? {}).reduce(
    (a: { count: number; sum: number }, [depth, val]) => {
      if (isNil(val)) return a

      if (selectedOption === 'DEFAULT') {
        // we should only include default depths in here
        if (!mappedDefaultDepths.includes(Number(depth))) return a
      } else {
        // otherwise we should only include the selected depth
        // eslint-disable-next-line no-lonely-if
        if (Number(depth) !== removeCmOrInFromSoilProbeDepths(selectedOption)) return a
      }

      return { count: a.count + 1, sum: a.sum + Number(val) }
    },
    { count: 0, sum: 0 },
  )

  if (aggregate.count === 0) return null

  return aggregate.sum / aggregate.count
}

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  const returner: routes.ValuesCurrent.Request = {}
  const pointsToFetch: TFieldAssetKeyTypes.TLngLat[] = []

  if (!!processedCaches.stationValues.itemsWithinView.length) {
    processedCaches.stationValues.itemsWithinView.forEach((s) => {
      pointsToFetch.push(s.meta.lngLat)
    })
  }

  if (!!pointsToFetch.length) {
    returner.points = { lngLats: pointsToFetch, values: { [pointValueType]: true } }
  }

  return returner
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const currentMADByLnglatText: Record<TFieldAssetKeyTypes.TLngLat, number> = {}
  const selectedOption = getValueType(MAP_VISUAL.POINT, 'soil')

  Object.values(fieldAssetStore.getState()?.properties ?? {}).forEach((property) => {
    Object.values(property?.points ?? {}).forEach((soilStation) => {
      currentMADByLnglatText[soilStation.lngLat] =
        soilStation.configuration.soilCurrentManagementAllowableDepletionPercent || 70
    })
  })

  if (!!processedCaches.stationValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.stationValues.itemsWithinView.flatMap(
      (station) => {
        const value = getValueFromDictionaryOfValues({
          lngLat: station.meta.lngLat,
          propertyId: Number(station.meta.propertyId) as TFieldAssetKeyTypes.TPropertyId,
          selectedOption,
          value: response?.points?.[station.meta.lngLat]?.[pointValueType]?.value ?? {},
        })

        itemIdsWithinView.push(station.id)

        const roundedValueAsString = unitConverter.soilAwc(value, { decimalPlaces: 0 }).valueAsString()
        const roundedValueAsNumber = unitConverter.soilAwc(value, { decimalPlaces: 0 }).value()

        return {
          id: String(station.id),
          value: {
            [selectedOption]: {
              children: roundedValueAsString,
              getContainerCSS: () =>
                isNil(value)
                  ? {
                      background: colors.midnight,
                      color: 'white',
                    }
                  : {
                      backgroundColor: getSoilAWCBackgroundColor(
                        roundedValueAsNumber,
                        currentMADByLnglatText[station.meta.lngLat],
                      ),
                      color: colors.midnight,
                    },
            },
          },
        }
      },
    )

    returner.stations = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
      itemIdsWithinView,
    }
  }

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.map((property) => {
    return {
      id: String(property.id),
      value: {
        [pointValueType]: {
          children: property.meta.propertyName,
          onHover: false,
          getContainerCSS: () => ({ background: colors.midnight, color: 'white' }),
        },
      },
    }
  })

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  mapControlsStore.setState((s) => ({
    ...s,
    heatmapExtremes: s.manualExtremes
      ? s.heatmapExtremes
      : {
          min: 0,
          max: 100,
        },
  }))

  return returner
}
