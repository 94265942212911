import { SharedSettings } from '../settings/SharedSettings'

export const apiTokenGetter = (): string => {
  let token = ''

  try {
    token = localStorage.getItem(SharedSettings.LOCAL_STORAGE_KEYS.AUTH_TOKEN) || ''
  } catch (err) {
    // TODO: errorLogger
  }

  return token
}
