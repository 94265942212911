import * as Sentry from '@sentry/react'
import { apiTokenGetter } from './apiTokenGetter'
import { errorCodes } from './errorCodes'

export const fetchApiCustomer = <RequestType, ResponseType>({
  body,
  restApiUrl,
}: {
  body: RequestType
  restApiUrl?: string
}): Promise<ResponseType> => {
  const payload = {
    headers: {
      'Content-Type': body === null ? 'null' : 'application/json',
      'X-Token': apiTokenGetter(),
      'app-name': 'banyan-app',
      'Accept-Encoding': 'gzip',
      'referer-url': window.location.href,
    },
    method: 'POST',
    body: body ? JSON.stringify(body) : null,
  } as RequestInit

  return fetch(`${import.meta.env.VITE_API_CUSTOMER_URL}/${restApiUrl || 'graphql'}`, payload)
    .then(errorCodes)
    .catch((err) => {
      Sentry.captureException(err)

      const error = new Error(`Failed to process the request. Message: ${err.message}.`)

      throw error
    })
}
