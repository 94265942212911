import React from 'react'
import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconErrorCircle = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <path
        d="M14 6.80571L13.1943 6L10 9.19429L6.80571 6L6 6.80571L9.19429 10L6 13.1943L6.80571 14L10 10.8057L13.1943 14L14 13.1943L10.8057 10L14 6.80571Z"
        fill="white"
      />
    </svg>
  )
}
