import {
  FORECAST_NUMBER_OF_DAYS_FOR_REGIONAL_ONLY_USERS,
  HISTORICAL_NUMBER_OF_DAYS_FOR_REGIONAL_ONLY_USERS,
} from '@semios/app-platform-common'
import type { TAvailablePreset } from 'App/Map/types'
import moment from 'moment-timezone'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { getIsoWithoutTheZ } from 'utils/getIsoWithoutTheZ'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'

export const setDateFromAndDateTo = (preset: TAvailablePreset) => {
  const isUserFreeUser = isUserOnlyAFreeRegionalUser()

  const offsetDaysFrom = Math.min(
    preset.dayOffsetDateFrom,
    isUserFreeUser ? HISTORICAL_NUMBER_OF_DAYS_FOR_REGIONAL_ONLY_USERS : preset.dayOffsetDateFrom,
  )

  const offsetDaysTo = Math.min(
    preset.dayOffsetDateTo,
    isUserFreeUser ? FORECAST_NUMBER_OF_DAYS_FOR_REGIONAL_ONLY_USERS : preset.dayOffsetDateTo,
  )

  detailsPanelStore.setState((s) => ({
    ...s,
    compareSeasonsInterval: 0, // temporary to fix bug in useApiValuesTimeseries
    dateFrom: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).subtract(offsetDaysFrom, 'days').startOf('day')),
    dateTo: getIsoWithoutTheZ(moment.tz(moment.tz.guess()).add(offsetDaysTo, 'days').endOf('day')),
  }))
}
