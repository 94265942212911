import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const serviceCenterInstallationChecklistsGet = async () => {
  const { nodes, devices } = await apiFetch<
    routes.ServiceCenterInstallationChecklistsGet.Request,
    routes.ServiceCenterInstallationChecklistsGet.Response
  >({
    url: routes.ServiceCenterInstallationChecklistsGet.path,
    body: null,
  })

  return {
    devices,
    nodes,
  }
}
