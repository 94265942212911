import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconSmsDisabled = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 31 31')}>
      <rect x="0.976562" y="1.46094" width="29" height="29" rx="14.5" fill="#F8F8F8" />
      <rect x="0.976562" y="1.46094" width="29" height="29" rx="14.5" stroke="#E2E2E2" />
      <path
        d="M8.48356 10.3609C8.48356 9.59094 9.10656 8.96094 9.87656 8.96094H21.0766C21.8466 8.96094 22.4766 9.59094 22.4766 10.3609V18.7609C22.4766 19.5309 21.8466 20.1609 21.0766 20.1609H11.2766L8.47656 22.9609L8.48356 10.3609Z"
        fill="#B4B3BB"
      />
    </svg>
  )
}
