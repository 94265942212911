import { Button } from 'components/Button/Button'
import { IconDuplicate } from 'components/icons/IconDuplicate'
import { translate } from 'i18n/i18n'

export const SettingsDuplicateButton = ({
  onClick,
  label = translate.phrases.banyanApp('Duplicate'),
}: {
  onClick: () => void
  label?: string
}) => {
  return (
    <Button onClick={onClick} leftIcon={<IconDuplicate />} variant="link">
      {label}
    </Button>
  )
}
