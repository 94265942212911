import { isEqual } from 'lodash'
import { useEffect, useLayoutEffect, useState } from 'react'

const getScrollPosition = ({
  availableWidth,
  wrapperRef,
}: {
  availableWidth: number
  wrapperRef?: HTMLDivElement | null
}) => {
  const allTheWayLeft = wrapperRef?.scrollLeft === 0
  const allTheWayRight = Number(wrapperRef?.scrollLeft) + Number(wrapperRef?.clientWidth) >= availableWidth
  const allTheWayTop = wrapperRef?.scrollTop === 0

  let heightOfChildren = 0

  for (let i = 0; i < Number(wrapperRef?.childElementCount); i++) {
    if (wrapperRef?.children[i]) heightOfChildren += wrapperRef?.children[i].clientHeight
  }

  const allTheWayBottom = heightOfChildren - Number(wrapperRef?.scrollTop) === wrapperRef?.clientHeight

  return { allTheWayBottom, allTheWayLeft, allTheWayRight, allTheWayTop }
}

export const useIsScrolledToEdges = ({
  availableWidth,
  wrapperRef,
}: {
  availableWidth: number
  wrapperRef?: HTMLDivElement | null
}) => {
  const [scrolledStatus, setScrolledStatus] = useState(() =>
    getScrollPosition({ availableWidth, wrapperRef }),
  )

  useEffect(() => {
    setScrolledStatus(getScrollPosition({ availableWidth, wrapperRef }))
  }, [availableWidth, wrapperRef])

  useLayoutEffect(() => {
    let running: number | null = null

    const updateScrollWhenNecessary = () => {
      if (running === null) {
        running = window.requestAnimationFrame(() => {
          const newScrollPosition = getScrollPosition({ availableWidth, wrapperRef })

          if (!isEqual(newScrollPosition, scrolledStatus)) {
            setScrolledStatus(newScrollPosition)
          }

          running = null
        })
      }
    }

    wrapperRef?.addEventListener('scroll', updateScrollWhenNecessary)

    return () => wrapperRef?.removeEventListener('scroll', updateScrollWhenNecessary)
  }, [availableWidth, scrolledStatus, wrapperRef])

  return scrolledStatus
}
