import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEqual, set } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { fetchValuesData } from './fetchValuesData'
import type { TCachedValuesFieldAssetData } from './_types'

const pollIntervalMilliseconds = 10 * 60 * 1000 // ten minutes

function usePrevious<T>(value: T, shouldStore: boolean): T | undefined {
  const ref = useRef<T>()

  useEffect(() => {
    if (shouldStore) {
      ref.current = value
    }
  }, [value, shouldStore])

  return ref.current
}

export const useApiValuesTimeseries = ({
  args,
  preventFetch,
  watchers = [],
}: {
  args: routes.Values.Request
  preventFetch?: boolean
  watchers?: (string | number | boolean)[]
}): // TODO: would be nice if this were ResponseType probably
{
  data: TCachedValuesFieldAssetData
  loading: boolean
  error: boolean
  updateData: (pathToSet: string, dataToSet: unknown) => void
} => {
  const { dateFrom, dateTo, ...rest } = args
  const [error, setError] = useState(false)
  /**
   * if a user is quickly clicking around, we can ensure
   * we will always show a loader if we increment and
   * decrement the number of requests that are loading,
   * assuming that a number greater than 0 means that we
   * are currently loading data
   */
  const [lastFetchedDateFrom, setLastFetchedDateFrom] = useState<string>('')
  const [lastFetchedDateTo, setLastFetchedDateTo] = useState<string>('')
  const [numberOfRequestsLoading, setNumberOfRequestsLoading] = useState(0)
  const [locallyCachedValuesData, setLocallyCachedValuesData] = useState<TCachedValuesFieldAssetData>({})
  const [refreshTime, setRefreshTime] = useState(+new Date())

  const fetchAndSetValuesData = useCallback(
    async (cacheData = {}) => {
      setNumberOfRequestsLoading((rr) => rr + 1)

      try {
        const data = await fetchValuesData(args, cacheData)

        setLocallyCachedValuesData(data)
      } catch (err) {
        // TODO: errorLogger

        setError(true)
      } finally {
        setLastFetchedDateFrom(dateFrom)

        setLastFetchedDateTo(dateTo)

        setNumberOfRequestsLoading((rr) => rr - 1)
      }
    },
    [args],
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      const shouldPoll = !document.hidden && document.visibilityState === 'visible'

      if (shouldPoll) setRefreshTime(+new Date())
    }, pollIntervalMilliseconds)

    return () => clearInterval(intervalId)
  }, [])

  /**
   * we want to maintain a list of things to compare to
   * to see if we should trigger a refetch of the data.
   * If we find anything has changed, we will want
   * to refresh the data
   */
  const thingsToTriggerAFetch = useMemo(
    () => ({ dateFrom, dateTo, rest, watchers, refreshTime, preventFetch }),
    [dateFrom, dateTo, rest, watchers, refreshTime, preventFetch],
  )

  const previousThingsToTriggerAFetch = usePrevious(thingsToTriggerAFetch, !preventFetch)

  useEffect(() => {
    /**
     * TODO: in the detailsPanel, we want to set the compareSeasonsInterval
     * to 0 upon date change, but this state update gets pretty buggy
     * unless we set it at the same time as a date change. This means that
     * everywhere we do a date change we also need to set the
     * compareSeasonsInterval to 0. This check isn't quite right
     */
    // TODO: if our args change, then preventFetch changes from true to false, we won't refetch
    if (isEqual(thingsToTriggerAFetch, previousThingsToTriggerAFetch) || preventFetch) {
      return
    }

    const existingSizeOfCachedDataInBytes = new Blob([JSON.stringify(locallyCachedValuesData)], {
      type: 'application/json',
    }).size

    const shouldResetBecauseDatesHaveChanged = !isEqual(
      { dateFrom, dateTo },
      { dateFrom: lastFetchedDateFrom, dateTo: lastFetchedDateTo },
    )

    const shouldResetBecauseCacheLimitIsExceeded = existingSizeOfCachedDataInBytes > 1024 * 1024 * 10 // 10 MB
    const shouldResetBecauseWatchersHaveChanged = !isEqual(watchers, previousThingsToTriggerAFetch?.watchers)

    fetchAndSetValuesData(
      shouldResetBecauseDatesHaveChanged ||
        shouldResetBecauseCacheLimitIsExceeded ||
        shouldResetBecauseWatchersHaveChanged
        ? {}
        : locallyCachedValuesData,
    )
  }, [thingsToTriggerAFetch])

  const updateData = (pathToSet: string, dataToSet: unknown) => {
    setLocallyCachedValuesData((prevData) => {
      return set(prevData, pathToSet, dataToSet)
    })

    detailsPanelStore.setState((prev) => ({ ...prev, keyForRedrawing: new Date().toISOString() }))
  }

  return {
    data: locallyCachedValuesData,
    loading: numberOfRequestsLoading > 0,
    error,
    updateData,
  }
}
