import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

export const includeNdviSwitch: Record<TValueGroup, boolean> = {
  air_temperature: true,
  almond_bloom: true,
  almond_hull_split: true,
  alternaria: true,
  atmospheric_pressure: true,
  bee_hours: true,
  chill: true,
  conditions: true,
  degree_days_insect_id_1: true,
  degree_days_insect_id_10: true,
  degree_days_insect_id_11: true,
  degree_days_insect_id_12: true,
  degree_days_insect_id_13: true,
  degree_days_insect_id_14: true,
  degree_days_insect_id_15: true,
  degree_days_insect_id_16: true,
  degree_days_insect_id_17: true,
  degree_days_insect_id_18: true,
  degree_days_insect_id_19: true,
  degree_days_insect_id_2: true,
  degree_days_insect_id_20: true,
  degree_days_insect_id_23: true,
  degree_days_insect_id_24: true,
  degree_days_insect_id_25: true,
  degree_days_insect_id_26: true,
  degree_days_insect_id_27: true,
  degree_days_insect_id_28: true,
  degree_days_insect_id_29: true,
  degree_days_insect_id_30: true,
  degree_days_insect_id_3: true,
  degree_days_insect_id_4: true,
  degree_days_insect_id_6: true,
  degree_days_insect_id_7: true,
  degree_days_insect_id_8: true,
  degree_days_insect_id_9: true,
  dew_point: true,
  equipment_status: false,
  evapotranspiration: true,
  fruit_growth: true,
  plant_stress: true,
  humidity: true,
  irrigation_activity: true,
  larval_trend_insect_id_1: true,
  larval_trend_insect_id_11: true,
  larval_trend_insect_id_20: true,
  larval_trend_insect_id_3: true,
  larval_trend_insect_id_6: true,
  larval_trend_insect_id_7: true,
  larval_trend_insect_id_9: true,
  leaf_wetness: true,
  precipitation: true,
  soil: true,
  spray_conditions: true,
  trap_catches_insect_id_1: true,
  trap_catches_insect_id_10: true,
  trap_catches_insect_id_11: true,
  trap_catches_insect_id_12: true,
  trap_catches_insect_id_15: true,
  trap_catches_insect_id_16: true,
  trap_catches_insect_id_17: true,
  trap_catches_insect_id_19: true,
  trap_catches_insect_id_2: true,
  trap_catches_insect_id_20: true,
  trap_catches_insect_id_25: true,
  trap_catches_insect_id_27: true,
  trap_catches_insect_id_28: true,
  trap_catches_insect_id_29: true,
  trap_catches_insect_id_30: true,
  trap_catches_insect_id_6: true,
  trap_catches_insect_id_7: true,
  trap_catches_insect_id_9: true,
  walnut_blight: true,
  wet_bulb: true,
  wind: true,
  wind_machine: true,
  ndvi: true,
}
