const DEFAULT_YEAR_FOR_REPORTS = 1900

import moment from 'moment-timezone'

export const getDateFromReport = (
  date: string | null | undefined,
  timezone = moment.tz.guess(),
): Date | null => {
  if (date) {
    return moment.tz(`${DEFAULT_YEAR_FOR_REPORTS}-${date}`, timezone).toDate()
  }

  return null
}
