import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { mapApiEmitterTypeToLabel } from './mapApiEmitterTypeToLabel'

export const makeNameEmitterTypeLabel = ({
  name,
  emitterType,
}: {
  name: string
  emitterType: TFieldAssetKeyTypes.TEmitterType
}) => {
  return `${name} (${mapApiEmitterTypeToLabel(emitterType)})`
}
