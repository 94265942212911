import { css } from '@emotion/css'
import { faBars, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { useState } from 'react'

export const ToggleButtonStateExample = () => {
  const toggleButtonWithIcons = () => {
    const [, setActiveButton] = useState<string>()

    return (
      <div>
        <h4>With Icons</h4>
        <ToggleButton
          data={[
            {
              label: (
                <>
                  <FontAwesomeIcon icon={faBars} />
                  <span
                    className={css`
                      margin: 0 5px;
                    `}
                  >
                    Details
                  </span>
                </>
              ),
              value: 'details',
            },
            {
              label: (
                <>
                  <FontAwesomeIcon icon={faChartLine} />
                  <span
                    className={css`
                      margin: 0 5px;
                    `}
                  >
                    Graph
                  </span>
                </>
              ),
              value: 'graph',
            },
          ]}
          onChange={(button) => {
            setActiveButton(button)
          }}
          defaultValue={'details'}
        />
      </div>
    )
  }

  const toggleButtonWithoutIcons = () => {
    const [, setActiveButton] = useState<string>()

    return (
      <div>
        <h4>Without Icons</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          onChange={(button) => {
            setActiveButton(button)
          }}
          defaultValue={'details'}
        />
      </div>
    )
  }

  const toggleButtonWithDefaultValue = () => {
    const [, setActiveButton] = useState('graph')

    return (
      <div>
        <h4>With Default Value</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          onChange={(button) => {
            setActiveButton(button)
          }}
          defaultValue={'graph'}
        />
      </div>
    )
  }

  const toggleButtonWithoutDefaultValue = () => {
    const [, setActiveButton] = useState('graph')

    return (
      <div>
        <h4>Without Default Value</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          onChange={(button) => {
            setActiveButton(button)
          }}
        />
      </div>
    )
  }

  const toggleButtonWithValue = () => {
    const [activeButton, setActiveButton] = useState('graph')

    return (
      <div>
        <h4>With Value</h4>
        <ToggleButton
          data={[
            {
              label: 'Details',
              value: 'details',
            },
            {
              label: 'Graph',
              value: 'graph',
            },
          ]}
          onChange={(button) => {
            setActiveButton(button)
          }}
          value={activeButton}
        />
      </div>
    )
  }

  return (
    <div
      className={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
      `}
    >
      {toggleButtonWithIcons()}
      {toggleButtonWithoutIcons()}
      {toggleButtonWithDefaultValue()}
      {toggleButtonWithoutDefaultValue()}
      {toggleButtonWithValue()}
    </div>
  )
}
