import { Textarea, Tooltip } from '@mantine/core'
import type { TAdminRegion } from 'App/Map/Admin/utils/useAdminEntites'
import { Button } from 'components/Button/Button'
import { GoogleMap } from 'components/GoogleMap/GoogleMap'
import { IconPencil } from 'components/icons/IconPencil'
import { translate } from 'i18n/i18n'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { colors } from 'settings/colors'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'

const isNestedLatLngArray = (values: unknown[]): values is number[][] => {
  return !(values.length === 2 && typeof values[0] === 'number' && typeof values[1] === 'number')
}

const makeRegionGeometry = (
  map: google.maps.Map,
  polygonString: string,
  setNewGeometryError: (error: string | null) => void,
  centroidString?: string | null,
) => {
  if (!polygonString) return

  try {
    const { coordinates } = JSON.parse(polygonString) as {
      coordinates: Array<Array<number[] | Array<number[]>>>
    }

    const regionBounds = new google.maps.LatLngBounds()

    const regionLatLngs = coordinates[0].reduce((all, values) => {
      if (!isNestedLatLngArray(values)) {
        const [lng, lat] = values

        regionBounds.extend({ lat, lng })

        all.push(new google.maps.LatLng({ lat, lng }))
      } else {
        const latLngs = values.map(([lng, lat]) => {
          regionBounds.extend({ lat, lng })

          return new google.maps.LatLng({ lat, lng })
        })

        all.push(...latLngs)
      }

      return all
    }, [] as google.maps.LatLng[])

    map.fitBounds(regionBounds)

    const polygon = new google.maps.Polygon({ paths: regionLatLngs, map })

    polygon.setOptions({
      strokeColor: 'white',
      strokeWeight: 2,
      fillColor: colors.purple200,
    })

    if (centroidString) {
      //@ts-ignore
      const { lng, lat } = lngLatStringToLngLatObject(centroidString)

      new google.maps.Marker({
        position: { lat, lng },
        map,
      })
    }
  } catch {
    setNewGeometryError('Invalid geometry')
  }
}

export const RegionGeometry = ({
  regionGeometry,
  centroidString,
  setRegionGeometry,
  setPreventSave,
}: {
  regionGeometry: TAdminRegion['geometry'] | null
  centroidString?: TAdminRegion['centroid'] | null
  setRegionGeometry: (geometry: string) => void
  setPreventSave: (preventSave: boolean) => void
}) => {
  const [isEditing, setIsEditing] = useState(regionGeometry === null)
  const [newGeometry, setNewGeometry] = useState(regionGeometry)
  const [newGeometryError, setNewGeometryError] = useState<string | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)

  useEffect(() => {
    if (isEditing || newGeometryError) {
      setPreventSave(true)
    } else {
      setPreventSave(false)
    }
  }, [isEditing, newGeometryError])

  useEffect(() => {
    if (map && regionGeometry) {
      makeRegionGeometry(map, regionGeometry, setNewGeometryError, centroidString)
    }
  }, [regionGeometry, map])

  const mapOptions = useMemo<google.maps.MapOptions>(() => {
    return {
      center: { lat: 0, lng: 0 },
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeId: 'satellite',
      tilt: 0,
      minZoom: 3,
    }
  }, [])

  const onInit = useCallback(
    (map: google.maps.Map) => {
      if (regionGeometry) {
        makeRegionGeometry(map, regionGeometry, setNewGeometryError)
      }

      setMap(map)
    },
    [regionGeometry],
  )

  const handleGeometrySave = () => {
    if (isEditing && newGeometry) {
      try {
        const parsed = JSON.parse(newGeometry)

        if (parsed.type !== 'Polygon' && parsed.type !== 'MultiPolygon') {
          throw new Error('Invalid geometry type')
        }

        if (!Array.isArray(parsed.coordinates)) {
          throw new Error('Invalid coordinates')
        }
      } catch (err) {
        setNewGeometryError(
          translate.phrases.validation('{{label}} is invalid', {
            label: translate.phrases.banyanApp('Geometry string'),
          }),
        )

        return
      }

      setRegionGeometry(newGeometry)

      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }

  return (
    <>
      <GoogleMap mapOptions={mapOptions} height="400px" width="100%" onInit={onInit} />
      {/* TODO: translate this help text */}
      <Tooltip
        multiline
        width={250}
        label={translate.phrases.banyanApp(
          'Please enter a valid polygon string in GeoJSON format. The polygon must form a complete shape, with the first an last item being identical. https://geojson.io/ can be used to create a polygon string.',
        )}
      >
        <div css={{ width: 'fit-content' }}>
          <Button size="xs" variant="tertiary" onClick={handleGeometrySave}>
            {isEditing ? translate.phrases.banyanApp('Confirm') : <IconPencil />}
          </Button>
        </div>
      </Tooltip>

      {newGeometryError && (
        <div>
          {newGeometryError}{' '}
          <Button
            variant="bare"
            onClick={() => {
              setNewGeometry(regionGeometry)

              setNewGeometryError(null)
            }}
          >
            {translate.phrases.banyanApp('Discard Changes')}
          </Button>
        </div>
      )}
      <Textarea
        value={newGeometry ?? ''}
        autosize
        minRows={2}
        maxRows={isEditing ? undefined : 4}
        onChange={(e) => setNewGeometry(e.target.value)}
        disabled={!isEditing}
        placeholder='{"type":"Polygon","coordinates":[[[0,0],[0,1],[1,1],[1,0],[0,0]]]}'
      />
      {isEditing && (
        <Button variant="tertiary" size="xs" onClick={handleGeometrySave}>
          {translate.phrases.banyanApp('Confirm')}
        </Button>
      )}
    </>
  )
}
