import { Card, LoadingOverlay, Tabs } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch, sortByKey } from '@semios/app-platform-common'
import { Authorization } from 'components/Authorization/Authorization'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { HierarchicalSelection } from 'components/HierarchicalSelection/HierarchicalSelection'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { cloneDeep, groupBy } from 'lodash'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { ApiResponseError, OfflineRequestError } from 'utils/errorCodes'
import { showNotification } from 'utils/showNotification'
import type { TAdminPermission, TAdminProperty, TAdminUser, TRole } from '../../../utils/useAdminEntites'
import { CancelSaveButtonGroup } from '../../CancelSaveButtonGroup/CancelSaveButtonGroup'
import { EntityRow } from '../../EntityRow/EntityRow'
import { PermissionRow } from '../../PermissionRow/PermissionRow'
import { RoleRow } from '../../RoleRow/RoleRow'
import { EffectivePermissions } from './EffectivePermissions'
import { EntityPicker } from './EntityPicker'
import { PermissionsPicker } from './PermissionsPicker'
import { RolesPicker } from './RolesPicker'

type TPermissionEntityType = TAdminPermission['permissionTypes'][0]

const initialDataState = {
  userIds: [],
  roles: { rolesToDelete: [], rolesToCreate: [] },
  permissions: { permissionsToCreate: [], permissionsToDelete: [] },
}

export const PermissionsModal = ({
  opened,
  onClose,
  user,
  properties,
  roles,
  permissions,
}: {
  user: TAdminUser | null
  opened: boolean
  onClose: (hasChanges?: boolean) => void
  properties: TAdminProperty[]
  roles: TRole[]
  permissions: TAdminPermission[]
}) => {
  const { firstName, lastName, id: userId } = user || { name: '', id: 0 }
  const [loading, setIsLoading] = useState<boolean>(false)
  const [isConfirmCancelModalShowing, setIsConfirmCancelModalShowing] = useState<boolean>(false)
  const [showOnlyChecked, setShowOnlyChecked] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string | undefined>()

  const [permissionEntitySelection, setPermissionEntitySelection] =
    useState<TPermissionEntityType>('PROPERTY')

  const [activeRole, setActiveRole] = useState<TRole['id'] | null>(null)
  const [activePermission, setActivePermission] = useState<TAdminPermission['id'] | null>(null)

  const [dataToUpdate, setDataToUpdate] =
    useState<routes.AdminUpdateUserPermissions.Request>(initialDataState)

  const [entityToSelectedRoles, setEntityToSelectedRoles] = useState<Record<string, TRole['id'][]>>({})

  const [entityToSelectedPermissions, setEntityToSelectedPermissions] = useState<
    Record<string, TAdminPermission['id'][]>
  >({})

  const [userPermissionsData, setUserPermissionsData] =
    useState<routes.AdminGetUserWithPermissions.Response['data']>(null)

  useEffect(() => {
    async function loadUserPermissionsData() {
      try {
        setIsLoading(true)

        const response = await apiFetch<
          routes.AdminGetUserWithPermissions.Request,
          routes.AdminGetUserWithPermissions.Response
        >({ url: 'admin-get-user-with-permissions', body: { userId }, params: { timeout: 180000 } })

        const { data, errors } = response

        if (data) {
          setUserPermissionsData(data)

          const assignedRoles = data?.[0]?.roles || []

          setEntityToSelectedRoles(() => {
            const entityToSelectedRoles: Record<string, TRole['id'][]> = {}

            assignedRoles.forEach((role) => {
              entityToSelectedRoles[role.entityId] = entityToSelectedRoles[role.entityId] || []

              entityToSelectedRoles[role.entityId].push(role.roleId)
            })

            return entityToSelectedRoles
          })

          const assignedPermissions = data?.[0]?.permissions || []

          setEntityToSelectedPermissions(() => {
            const entityToSelectedPermissions: Record<string, TAdminPermission['id'][]> = {}

            assignedPermissions.forEach((permission) => {
              entityToSelectedPermissions[permission.entityId] =
                entityToSelectedPermissions[permission.entityId] || []

              entityToSelectedPermissions[permission.entityId].push(permission.permissionId)
            })

            return entityToSelectedPermissions
          })
        } else if (errors) {
          // TODO: specific error handling
          showNotification({
            type: 'error',
            message: translate.phrases.banyanApp('Error: could not load user data'),
          })
        }
      } catch (error) {
        if (error instanceof ApiResponseError && error.statusCode === 403) {
          showNotification({
            type: 'error',
            message: translate.phrases.banyanApp('No Permissions'),
          })
        } else if (!(error instanceof OfflineRequestError)) {
          showNotification({
            type: 'error',
            message: translate.phrases.banyanApp('Error: could not load user data'),
          })
        }
      } finally {
        setIsLoading(false)
      }
    }

    loadUserPermissionsData()
  }, [user])

  const rolesDataGroupedByType = (roles?.length > 0 && groupBy(roles, 'roleType')) || {
    PROPERTY: [],
    SYSTEM: [],
  }

  const permissionsDataGroupedByType = (permissions?.length > 0 &&
    groupBy(permissions, 'permissionTypes[0]')) || {
    PROPERTY: [],
    SYSTEM: [],
  }

  const assignedRoles =
    userPermissionsData && userPermissionsData[0]?.roles.length > 0
      ? groupBy(userPermissionsData[0]?.roles, 'roleType')
      : { PROPERTY: [], SYSTEM: [] }

  const assignedPermissions =
    userPermissionsData && userPermissionsData[0]?.permissions.length > 0
      ? groupBy(userPermissionsData[0]?.permissions, 'permissionType')
      : { PROPERTY: [], SYSTEM: [] }

  const roleIdPropertyGrouped =
    (assignedRoles && assignedRoles.PROPERTY?.length > 0 && groupBy(assignedRoles?.PROPERTY, 'roleId')) || {}

  const permissionIdPropertyGrouped =
    (assignedPermissions &&
      assignedPermissions.PROPERTY?.length > 0 &&
      groupBy(assignedPermissions?.PROPERTY, 'permissionId')) ||
    {}

  const modalTitle = translate.phrases.banyanApp('Set Roles/Permissions for {{firstName}} {{lastName}}', {
    firstName,
    lastName,
  })

  const hasSetUserPermissionsPermission = checkAuthorization({
    permission: 'ADMIN_SET_USERS_PERMISSIONS',
    entity: '*',
  })

  const hasSetUserRolesPermission = checkAuthorization({
    permission: 'ADMIN_SET_USERS_ROLES',
    entity: '*',
  })

  const handleEntitySwitch = (entityType: TPermissionEntityType) => {
    setPermissionEntitySelection(entityType)

    setActiveRole(null)

    setActivePermission(null)

    setSearchString(undefined)

    setShowOnlyChecked(false)
  }

  const onCancel = () => {
    const hasChanges =
      dataToUpdate.roles?.rolesToCreate?.length ||
      dataToUpdate.permissions?.permissionsToCreate?.length ||
      dataToUpdate.roles?.rolesToDelete?.length ||
      dataToUpdate.permissions?.permissionsToDelete?.length

    if (hasChanges) {
      setIsConfirmCancelModalShowing(true)
    } else {
      handleCloseAndResetState()
    }
  }

  const handleCloseAndResetState = (hasChanges?: boolean) => {
    setActiveRole(null)

    setActivePermission(null)

    setSearchString(undefined)

    setShowOnlyChecked(false)

    setPermissionEntitySelection('PROPERTY')

    setDataToUpdate(initialDataState)

    onClose(hasChanges)
  }

  const handleSave = async () => {
    const hasChanges =
      dataToUpdate.roles?.rolesToCreate?.length ||
      dataToUpdate.permissions?.permissionsToCreate?.length ||
      dataToUpdate.roles?.rolesToDelete?.length ||
      dataToUpdate.permissions?.permissionsToDelete?.length

    if (!hasChanges) {
      handleCloseAndResetState()

      return
    }

    setIsLoading(true)

    const params = dataToUpdate

    params.userIds = [userId]

    const result = await apiFetch<
      routes.AdminUpdateUserPermissions.Request,
      routes.AdminUpdateUserPermissions.Response
    >({ url: routes.AdminUpdateUserPermissions.path, body: params })

    if (result.data) {
      showNotification({
        type: 'success',
        message: translate.phrases.banyanApp('Permissions successfully updated'),
      })

      handleCloseAndResetState(true)
    } else {
      showNotification({
        type: 'success',
        message: translate.phrases.banyanApp('Permissions successfully updated'),
      })
    }

    setIsLoading(false)
  }

  const propertiesSelection = (type: 'ROLE' | 'PERMISSION') => {
    const selectedRoleOrPermission = type === 'ROLE' ? activeRole : activePermission

    if (!selectedRoleOrPermission) return null

    const entities = arrayOfObjectsSearch(properties, searchString ?? '', ['name', 'id'])
      .map((p) => {
        let checked = false

        if (type === 'ROLE' && activeRole) {
          checked = entityToSelectedRoles[p.id]?.includes(activeRole)
        }

        if (type === 'PERMISSION' && activePermission) {
          checked = entityToSelectedPermissions[p.id]?.includes(activePermission)
        }

        return {
          name: p.name,
          id: p.id,
          checked,
        }
      })
      .sort(sortByKey('name'))

    return (
      <HierarchicalSelection
        width="50%"
        title={translate.phrases.banyanApp('Properties')}
        placeholder={translate.phrases.banyanApp('Search for a property')}
        searchString={searchString ?? ''}
        setSearchString={setSearchString}
        setShowOnlyChecked={setShowOnlyChecked}
        showOnlyChecked={showOnlyChecked}
      >
        {entities
          .filter((e) => (showOnlyChecked ? e.checked : true))
          .map((entity) => (
            <EntityRow
              key={entity.id}
              entity={entity}
              setSelected={(selected) => {
                handleEntityRolePermissionClick({
                  isSelected: selected,
                  entityId: String(entity.id),
                  entityType: 'PROPERTY',
                  roleId: type === 'ROLE' && activeRole ? activeRole : undefined,
                  permissionId: type === 'PERMISSION' && activePermission ? activePermission : undefined,
                })
              }}
            />
          ))}
      </HierarchicalSelection>
    )
  }

  const handleEntityRolePermissionClick = ({
    isSelected,
    roleId,
    permissionId,
    entityId,
    entityType,
  }: {
    isSelected: boolean
    roleId?: TRole['id']
    permissionId?: TAdminPermission['id']
    entityId: string
    entityType: TPermissionEntityType
  }) => {
    if (isSelected) {
      if (roleId) {
        setEntityToSelectedRoles((prev) => ({
          ...prev,
          [entityId]: [...(prev['*'] || []), roleId],
        }))
      }

      if (permissionId) {
        setEntityToSelectedPermissions((prev) => ({
          ...prev,
          [entityId]: [...(prev['*'] || []), permissionId],
        }))
      }

      setDataToUpdate((prev) => {
        const updated = cloneDeep(prev)

        if (roleId) {
          updated.roles = updated.roles ?? { rolesToDelete: [], rolesToCreate: [] }

          updated.roles.rolesToCreate.push({
            roleId,
            entityId,
            roleType: entityType,
          })
        }

        if (permissionId) {
          updated.permissions?.permissionsToCreate.push({
            permissionId,
            entityId,
            permissionType: entityType,
          })
        }

        return updated
      })
    } else {
      if (roleId) {
        setEntityToSelectedRoles((prev) => ({
          ...prev,
          [entityId]: (prev[entityId] || []).filter((id) => id !== roleId),
        }))
      }

      if (permissionId) {
        setEntityToSelectedPermissions((prev) => ({
          ...prev,
          [entityId]: (prev[entityId] || []).filter((id) => id !== permissionId),
        }))
      }

      setDataToUpdate((prev) => {
        const updated = cloneDeep(prev)

        if (roleId) {
          updated.roles = updated.roles ?? { rolesToDelete: [], rolesToCreate: [] }

          const isInCreateList = prev?.roles?.rolesToCreate?.some(
            (rc) => rc.roleId === roleId && rc.entityId === entityId && entityType === rc.roleType,
          )

          if (isInCreateList && updated.roles?.rolesToCreate) {
            updated.roles.rolesToCreate = updated.roles.rolesToCreate.filter(
              (rc) => !(rc.roleId === roleId && rc.entityId === entityId && rc.roleType === entityType),
            )
          } else {
            updated.roles.rolesToDelete.push({
              roleId,
              entityId,
              roleType: entityType,
            })
          }
        }

        if (permissionId) {
          updated.permissions = updated.permissions ?? { permissionsToCreate: [], permissionsToDelete: [] }

          const isInCreateList = prev?.permissions?.permissionsToCreate?.some(
            (pc) =>
              pc.permissionId === permissionId &&
              pc.entityId === entityId &&
              entityType === pc.permissionType,
          )

          if (isInCreateList && updated.permissions?.permissionsToCreate) {
            updated.permissions.permissionsToCreate = updated.permissions.permissionsToCreate.filter((pc) => {
              return !(
                pc.permissionId === permissionId &&
                pc.entityId === entityId &&
                pc.permissionType === entityType
              )
            })
          } else {
            updated.permissions.permissionsToDelete.push({
              permissionId,
              entityId,
              permissionType: entityType,
            })
          }
        }

        return updated
      })
    }
  }

  const entityItems: { value: TPermissionEntityType; label: string }[] = [
    {
      label: translate.phrases.banyanApp('Properties'),
      value: 'PROPERTY',
    },
  ]

  if (checkAuthorization({ permission: 'ADMIN_SET_USERS_ROLES', entity: '*' })) {
    entityItems.push({
      label: translate.phrases.banyanApp('System'),
      value: 'SYSTEM',
    })
  }

  return (
    <ModalDrawer
      opened={opened}
      onClose={onClose}
      title={modalTitle}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 2}
      isSecondaryModal
      size={'70%'}
    >
      <LoadingOverlay visible={loading} />
      <div css={{ maxWidth: '100%', width: '100%' }}>
        <Card>
          <h3>{translate.phrases.banyanApp('Attach Roles/Permissions to User')}</h3>
          <Card.Section css={{ marginLeft: '20px' }}>
            <Tabs
              defaultValue="userRoles"
              styles={{
                tabsList: {
                  button: {
                    'fontSize': 15,
                    'padding': 10,
                    'color': colors.primary,
                    '&:hover': { background: 'transparent', fontWeight: 600 },
                    '&[data-active]': {
                      'fontWeight': 700,
                      'borderBottom': `4px solid ${colors.midnight}`,
                      '&:hover': { fontWeight: 700, borderColor: colors.midnight },
                    },
                    '&:not(:first-of-type)': { marginLeft: 20 },
                  },
                },
                panel: {
                  paddingTop: 10,
                },
              }}
            >
              <Tabs.List>
                <Tabs.Tab value="userRoles">{translate.phrases.banyanApp('Roles')}</Tabs.Tab>
                <Authorization requires={{ permission: 'ADMIN_SET_USERS_ROLES', entity: '*' }}>
                  <Tabs.Tab value="userPermissions">{translate.phrases.banyanApp('Permissions')}</Tabs.Tab>
                </Authorization>
              </Tabs.List>
              <Tabs.Panel value="userRoles" pt="xs">
                {roles && (
                  <div
                    css={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <EntityPicker
                      entityItems={entityItems}
                      currentEntityType={permissionEntitySelection}
                      setEntityType={handleEntitySwitch}
                    />

                    {permissionEntitySelection === 'SYSTEM' ? (
                      <HierarchicalSelection
                        width="50%"
                        title={translate.phrases.banyanApp('Roles')}
                        placeholder={translate.phrases.banyanApp('Search for a role')}
                        searchString={searchString ?? ''}
                        setSearchString={setSearchString}
                        setShowOnlyChecked={setShowOnlyChecked}
                        showOnlyChecked={showOnlyChecked}
                      >
                        {(
                          arrayOfObjectsSearch(rolesDataGroupedByType.SYSTEM, searchString ?? '', ['name']) ??
                          []
                        ).map((role) => {
                          const isSelected = entityToSelectedRoles['*']?.includes(role.id)

                          if (showOnlyChecked && !isSelected) return null

                          return (
                            <RoleRow
                              key={role.id}
                              role={role}
                              selected={isSelected}
                              hasEditPermission={hasSetUserRolesPermission}
                              setSelected={(selected) =>
                                handleEntityRolePermissionClick({
                                  isSelected: selected,
                                  roleId: role.id,
                                  entityId: '*',
                                  entityType: 'SYSTEM',
                                })
                              }
                            />
                          )
                        })}
                      </HierarchicalSelection>
                    ) : (
                      <>
                        <RolesPicker
                          width="35%"
                          roles={rolesDataGroupedByType.PROPERTY}
                          roleIdToCountOfEntities={Object.entries(roleIdPropertyGrouped).reduce(
                            (all, [roleId, role]) => {
                              all[roleId] = role.length

                              return all
                            },
                            {} as Record<string, number>,
                          )}
                          selectedRole={activeRole}
                          setSelectedRole={setActiveRole}
                        />
                        {propertiesSelection('ROLE')}
                      </>
                    )}
                  </div>
                )}
              </Tabs.Panel>
              <Authorization requires={{ permission: 'ADMIN_SET_USERS_ROLES', entity: '*' }}>
                <Tabs.Panel value="userPermissions" pt="xs">
                  {permissions && (
                    <div css={{ display: 'flex', flexWrap: 'wrap' }}>
                      <EntityPicker
                        entityItems={entityItems}
                        currentEntityType={permissionEntitySelection}
                        setEntityType={handleEntitySwitch}
                      />

                      {permissionEntitySelection === 'SYSTEM' ? (
                        <HierarchicalSelection
                          width="40vw"
                          title={translate.phrases.banyanApp('Permissions')}
                          placeholder={translate.phrases.banyanApp('Search for a permission')}
                          searchString={searchString ?? ''}
                          setSearchString={setSearchString}
                          showOnlyChecked={showOnlyChecked}
                          setShowOnlyChecked={setShowOnlyChecked}
                        >
                          {(
                            arrayOfObjectsSearch(permissionsDataGroupedByType.SYSTEM, searchString ?? '', [
                              'id',
                            ]).sort(sortByKey('id')) ?? []
                          ).map((permission) => {
                            const isSelected = entityToSelectedPermissions['*']?.includes(permission.id)

                            if (showOnlyChecked && !isSelected) return null

                            return (
                              <PermissionRow
                                key={permission.id}
                                permission={permission}
                                selected={isSelected}
                                setSelected={(selected) =>
                                  handleEntityRolePermissionClick({
                                    isSelected: selected,
                                    permissionId: permission.id,
                                    entityId: '*',
                                    entityType: 'SYSTEM',
                                  })
                                }
                                hasEditPermission={hasSetUserPermissionsPermission}
                              />
                            )
                          })}
                        </HierarchicalSelection>
                      ) : (
                        <>
                          <PermissionsPicker
                            width="300px"
                            permissions={permissionsDataGroupedByType.PROPERTY}
                            permissionIdToCountOfEntities={Object.entries(permissionIdPropertyGrouped).reduce(
                              (all, [permissionId, permission]) => {
                                all[permissionId] = permission.length

                                return all
                              },
                              {} as Record<string, number>,
                            )}
                            selectedPermission={activePermission}
                            setSelectedPermission={setActivePermission}
                          />
                          {propertiesSelection('PERMISSION')}
                        </>
                      )}
                    </div>
                  )}
                </Tabs.Panel>
              </Authorization>
            </Tabs>
          </Card.Section>
          <CancelSaveButtonGroup onCancel={onCancel} onSave={handleSave} />
        </Card>
        <EffectivePermissions adminUserResponse={userPermissionsData} properties={properties} />
        <ConfirmationSettingsModal
          confirmModalOpened={isConfirmCancelModalShowing}
          setConfirmModalOpened={setIsConfirmCancelModalShowing}
          handleResetAndClose={handleCloseAndResetState}
          handleUpdate={handleSave}
        />
      </div>
    </ModalDrawer>
  )
}
