import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconSunHot = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 406 406')}>
      <g clipPath="url(#clip0_11_591)">
        <mask
          id="mask0_11_591"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="406"
          height="406"
        >
          <path d="M0 0H406L0 406V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_11_591)">
          <g clipPath="url(#clip1_11_591)">
            <path
              d="M203 287C249.392 287 287 249.392 287 203C287 156.608 249.392 119 203 119C156.608 119 119 156.608 119 203C119 249.392 156.608 287 203 287Z"
              fill="url(#paint0_linear_11_591)"
              stroke="#F8AF18"
              strokeWidth="6"
              strokeMiterlimit="10"
            />
            <path
              d="M203 72.66V23M203 383V333.34M295.17 110.83L330.28 75.72M75.72 330.28L110.83 295.17M110.83 110.83L75.72 75.72M330.28 330.28L295.17 295.17M72.66 203H23M383 203H333.34"
              stroke="#FBBF24"
              strokeWidth="24"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
        </g>
        <path
          d="M144.48 302.79C155.91 290.79 168.94 303.21 180.37 291.21C191.8 279.21 178.76 266.8 190.19 254.8C201.62 242.8 214.65 255.21 226.08 243.21C237.51 231.21 224.48 218.8 235.9 206.8C247.32 194.8 260.37 207.21 271.8 195.21C283.23 183.21 270.19 170.8 281.62 158.8C293.05 146.8 306.09 159.21 317.52 147.21"
          stroke="url(#paint1_linear_11_591)"
          strokeWidth="12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M204.48 302.79C215.91 290.79 228.94 303.21 240.37 291.21C251.8 279.21 238.76 266.8 250.19 254.8C261.62 242.8 274.65 255.21 286.08 243.21C297.51 231.21 284.48 218.8 295.9 206.8C307.32 194.8 320.37 207.21 331.8 195.21C343.23 183.21 330.19 170.8 341.62 158.8C353.05 146.8 366.09 159.21 377.52 147.21"
          stroke="url(#paint2_linear_11_591)"
          strokeWidth="12"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_591"
          x1="160.99"
          y1="130.24"
          x2="245.01"
          y2="275.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBBF24" />
          <stop offset="0.45" stopColor="#FBBF24" />
          <stop offset="1" stopColor="#F59E0B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_591"
          x1="211.58"
          y1="191.37"
          x2="250.42"
          y2="258.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_591"
          x1="271.58"
          y1="191.37"
          x2="310.42"
          y2="258.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <clipPath id="clip0_11_591">
          <rect width="406" height="406" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_591">
          <rect width="384" height="384" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </svg>
  )
}
