import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconSloshingWaterInWaterDrop = () => (
  <svg {...makeBaseSvgIconProps('0 0 14 20')}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.23232 0.219805C6.34132 0.0883066 6.50365 0.00703163 6.66979 0.000655457C6.85279 -0.00829244 7.03792 0.0748316 7.15797 0.219805C7.15797 0.219805 8.70153 2.10437 10.2456 4.50366C11.7897 6.90295 13.3903 9.76033 13.3903 12.1149C13.3903 15.9808 10.3856 19.1285 6.69515 19.1285C3.00472 19.1285 0 15.9808 0 12.1149C0 9.76033 1.60061 6.90295 3.14469 4.50366C4.68876 2.10437 6.23232 0.219805 6.23232 0.219805ZM4.94338 12.6071C4.92583 12.5793 4.9066 12.5488 4.86928 12.5001C4.83193 12.5488 4.81269 12.5793 4.79513 12.6072C4.76965 12.6476 4.74771 12.6824 4.67907 12.7591C4.25249 13.2358 3.67227 13.8151 2.84044 13.8151C2.21658 13.8151 1.71641 13.4997 1.30614 13.1443C1.76772 15.8266 3.99872 17.8532 6.69523 17.8532C9.28982 17.8532 11.4498 15.9744 12.0209 13.4432C11.727 13.6602 11.3786 13.8151 10.9558 13.8151C10.124 13.8151 9.54371 13.2358 9.11715 12.7591C9.04847 12.6824 9.02654 12.6476 9.00105 12.6071C8.9835 12.5793 8.96427 12.5488 8.92695 12.5001C8.8896 12.5488 8.87036 12.5793 8.8528 12.6072C8.82732 12.6476 8.80538 12.6824 8.73674 12.7591C8.31016 13.2358 7.72993 13.8151 6.89811 13.8151C6.06629 13.8151 5.48604 13.2358 5.05948 12.7591C4.9908 12.6824 4.96887 12.6476 4.94338 12.6071Z"
      fill="currentColor"
    />
  </svg>
)
