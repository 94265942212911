import { css } from '@emotion/css'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import React from 'react'

export const MinMaxValueCell = ({ min, max }: { min: React.ReactNode; max: React.ReactNode }) => {
  if (isNil(min) && isNil(max)) return <>{translate.phrases.templates('-')}</>

  return (
    <div
      className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 0px 12px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 0px 12px;
        `}
      >
        <translate.Phrases.templates
          k="<label>{{label}}</label>: <value>{{value}}</value>"
          c={{
            label: <div />,
            value: <div css={{ marginLeft: 6 }} />,
          }}
          v={{
            label: translate.phrases.abbreviations('High'),
            value: isNil(max) ? translate.phrases.templates('-') : max,
          }}
        />
      </div>
      <div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 0px 12px;
          margin-top: 4px;
        `}
      >
        <translate.Phrases.templates
          k="<label>{{label}}</label>: <value>{{value}}</value>"
          c={{
            label: <div />,
            value: <div css={{ marginLeft: 6 }} />,
          }}
          v={{
            label: translate.phrases.abbreviations('Low'),
            value: isNil(min) ? translate.phrases.templates('-') : min,
          }}
        />
      </div>
    </div>
  )
}
