import { Text } from '@mantine/core'
import type { NotificationProps } from '@mantine/notifications'
import { notifications } from '@mantine/notifications'
import { CurrentLocation } from 'App/Map/_utils/CurrentLocation/CurrentLocation'
import { Button } from 'components/Button/Button'
import { GoogleMap } from 'components/GoogleMap/GoogleMap'
import { IconPin } from 'components/icons/IconPin'
import { WideHeader } from 'components/ModalDrawer/WideHeader/WideHeader'
import { translate } from 'i18n/i18n'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import svg64 from 'svg64'
import { NODE_STATUS_COLORS } from '../Map/_utils/getActiveNodeStatus'
import { Footer } from '../NodeInstallation/Footer/Footer'
import type { TNodeType } from '../types'
import { NO_RELOCATION_WARNING_NODE_TYPES } from '../utils/constants/nodeType'
import { defaultNotificationProps } from '../utils/defaultNotificationProps'

const ICON_WIDTH = 28
const ICON_HEIGHT = 40

const DefaultLocationMarker: React.FC<{
  map: google.maps.Map
  coordinates: { lat: number; lng: number }
}> = ({ map, coordinates }) => {
  const markerRef = useRef<null | google.maps.Marker>(null)
  const { viewBox, width, height, children: svgElement } = IconPin().props

  const SVGIconString = `
    <svg width="${width}" height="${height}" viewBox="${viewBox}" xmlns="http://www.w3.org/2000/svg" color="grey">
        ${renderToStaticMarkup(svgElement)}
    </svg>`

  useEffect(() => {
    if (!map) return

    const marker = new window.google.maps.Marker({
      map,
      position: coordinates,
      icon: {
        url: svg64(SVGIconString),
        scaledSize: new window.google.maps.Size(ICON_WIDTH, ICON_HEIGHT),
      },
    })

    markerRef.current = marker

    return () => {
      // Remove the marker when the component unmounts
      marker.setMap(null)
    }
  }, [map])

  return null
}

const RelocationMarker: React.FC = () => {
  // set marker to the center of the map (anchor point is the bottom center of the icon)
  return (
    <div
      css={{
        position: 'absolute',
        top: `calc(50% - ${ICON_HEIGHT}px)`,
        left: `calc(50% - ${ICON_WIDTH / 2}px)`,
        zIndex: 10000,
        color: NODE_STATUS_COLORS.planned,
        fontSize: `${ICON_HEIGHT}px`,
      }}
    >
      <IconPin />
    </div>
  )
}

const NodePositioningModal: React.FC<{
  mapDefaultCenter: google.maps.LatLngLiteral
  mapDefaultZoom?: number
  showDefaultLocationMarker?: boolean
  headerTitle?: string
  onClose: () => void
  onSubmit?: (mapCenter: google.maps.LatLngLiteral) => Promise<void> | void
  disableSubmitButtonOnDefaultLocation?: boolean
  nodeType?: TNodeType
}> = ({
  onSubmit,
  onClose,
  headerTitle,
  mapDefaultCenter,
  showDefaultLocationMarker = false,
  mapDefaultZoom,
  disableSubmitButtonOnDefaultLocation = false,
  nodeType,
}) => {
  const [hasMapMoved, setHasMapMoved] = useState(false)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (map) {
      const eventListener = map.addListener('center_changed', () => {
        setHasMapMoved(true)
      })

      return () => {
        eventListener.remove()
      }
    }
  }, [map])

  const mapOptions = useMemo(() => {
    return {
      mapTypeId: 'satellite',
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      minZoom: 3,
      zoom: mapDefaultZoom || 13,
      center: mapDefaultCenter,
      disableDoubleClickZoom: true,
      tilt: 0,
    }
  }, [])

  const handleResetPosition = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (mapDefaultCenter) {
      map?.setCenter(mapDefaultCenter)

      setHasMapMoved(false)
    }
  }

  const handleCancel = () => {
    onClose()
  }

  const handleSubmit = async () => {
    const mapCenter = map?.getCenter()?.toJSON()

    if (!mapCenter) return

    setIsSaving(true)

    await onSubmit?.(mapCenter)

    setIsSaving(false)
  }

  useEffect(() => {
    if (nodeType && NO_RELOCATION_WARNING_NODE_TYPES.includes(nodeType)) return

    const settings: NotificationProps = {
      ...defaultNotificationProps,
      id: 'confirm-location-banner',
      autoClose: false,
      message: (
        <>
          <Text>
            {translate.phrases.placeholder(
              'Moving equipment location after initial install could result in loss of data.',
            )}
          </Text>
        </>
      ),
    }

    notifications.show(settings)

    return () => {
      if (settings.id) notifications.hide(settings.id)
    }
  }, [])

  return (
    <div>
      {headerTitle && (
        <WideHeader
          title={headerTitle}
          onClose={onClose}
          isSecondaryModal={true}
          style={{
            top: 0,
            left: 0,
            zIndex: 1,
            position: 'relative',
          }}
        />
      )}
      {/* Google map takes up the whole screen */}
      <GoogleMap
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        width={'100vw'}
        height={'100vh'}
        mapOptions={mapOptions}
        onInit={setMap}
        defaultErrorMessage={translate.phrases.placeholder('Error loading map.')}
      />
      {/* // Show the default location marker sticks to the map */}
      {map && showDefaultLocationMarker && <DefaultLocationMarker map={map} coordinates={mapDefaultCenter} />}
      {/* //Always center the relocation marker */}
      <div
        css={{
          top: '50vh',
          left: '50vw',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
        }}
      >
        <RelocationMarker />
      </div>

      {/* Use relative positioning for the map tool buttons so they are affected by the header height */}
      <div css={{ position: 'relative' }}>
        <Button
          size="lg"
          css={{
            top: 0,
            left: 0,
            margin: '20px',
          }}
          onClickCapture={handleResetPosition}
          variant={'tertiary'}
          disabled={!hasMapMoved}
        >
          {translate.phrases.placeholder('Reset')}
        </Button>

        {map && (
          <CurrentLocation
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              margin: '20px',
            }}
            map={map}
          />
        )}
      </div>

      <Footer
        nextButtonLabel={translate.phrases.placeholder('Confirm')}
        nextButtonIcon={null}
        disableNextButton={disableSubmitButtonOnDefaultLocation && !hasMapMoved}
        showPreviousButton
        previousButtonLabel={translate.phrases.placeholder('Cancel')}
        previousButtonIcon={null}
        onPrevious={handleCancel}
        onNext={handleSubmit}
        loading={isSaving}
      />
    </div>
  )
}

export default NodePositioningModal
