import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { TScheduledEventEnum } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'

export const mapApiScheduledEventStatusToTScheduledEventEnum = (status: SharedTypes.EventStatus) => {
  switch (status) {
    case 'DRAFT': {
      // User has not published yet. (Gray bar)
      return TScheduledEventEnum.DRAFT_CREATE
    }

    case 'PUBLISHED': {
      // User has published (Green bar)
      return TScheduledEventEnum.SCHEDULED
    }

    case 'DELETED': {
      // An existing published event that has been marked for delete. (Red bar)
      // User would then need to publish for the delete to actually happen.
      return TScheduledEventEnum.DRAFT_DELETE
    }

    case 'STOPPED_IN_PROGRESS': {
      // Only applies to a running event that was stopped. It's the result of "splitting the event" that happens
      // when we stop a running event. It turns into an event with the original event start time that ends "now"
      // and it looks just like a normal published event in the UI (Green bar)
      return TScheduledEventEnum.STOPPED
    }

    case 'STOP_PENDING': {
      // Same as STOPPED_IN_PROGRESS but for wiseconn events
      return TScheduledEventEnum.STOPPED
    }

    case 'ZONE_STOPPED': {
      // Event is stopped because its irrigation zone is stopped.
      // TODO: Change bar color? (It's green right now like a published event along with a gray zone bar overtop of it to represent the stopped zone)
      return TScheduledEventEnum.STOPPED
    }

    case 'DELETION_AWAITING_CONTROL_PROVIDER_CONFIRMATION':
      // Event has been sent to control provider for deletion but it's
      //  still not yet removed from their system.
      return TScheduledEventEnum.PENDING_DELETE

    default: {
      throw new Error(`TODO: map API scheduled event status "${status}" to front end.`)
    }
  }
}
