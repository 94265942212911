import type { TIrrigationDeviceStatus } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { IrrigationCurrentStatusBadge } from '../IrrigationCurrentStatusBadge/IrrigationCurrentStatusBadge'

export const IrrigationActivityLabel = ({
  label,
  currentValueStatus,
  showPlanned = true,
}: {
  label: string
  currentValueStatus: TIrrigationDeviceStatus
  showPlanned?: boolean
}) => {
  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          padding: '2px',
          flex: 1,
          height: 'auto',
        }}
      >
        <div
          css={{
            color: colors.midnight,
            fontWeight: showPlanned ? 'bold' : 'normal',
            fontSize: showPlanned ? '14px' : '16px',
          }}
        >
          {label}
        </div>
        {showPlanned && (
          <div>
            <div css={{ padding: '7px 0 0 5px', fontSize: '14px' }}>
              {translate.phrases.banyanApp('Applied')}
            </div>
            <div css={{ padding: '5px 0 0 5px', fontSize: '14px' }}>
              {translate.phrases.banyanApp('Scheduled')}
            </div>
          </div>
        )}
      </div>
      {currentValueStatus && (
        <div
          css={{
            fontSize: '12px',
          }}
        >
          <IrrigationCurrentStatusBadge currentValueStatus={currentValueStatus} />
        </div>
      )}
    </div>
  )
}
