import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { isEmpty, isNil } from 'lodash'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
  TValuesCurrentPropertiesValueTypes,
  TValuesCurrentRegionValueTypes,
} from 'stores/mapControlsStore/types'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { smallStore } from 'stores/smallStore'
import { doesBlockHaveValueType } from 'utils/doesFieldAssetHaveValueType'
import { lngLatStringToLngLatObject } from 'utils/lngLatStringToLngLatObject'
import type { TUnitConverterFunction } from 'utils/unitConverter/unitConverter'
import type { TValueType } from '../../../caches/BlockScdsValuesCache/BlockScdsValuesCache'
import type {
  TCurrentValuesMapCacheKeys,
  TCurrentValuesMapProcessedCaches,
  TGetCacheUpdatesFromResponseReturn,
} from '../_types'
import { getHeatmapCSS } from './getHeatmapCSS'
import { getPropertyIdsForAllPropertiesInVisibleRegions } from './getPropertyIdsForAllPropertiesInVisibleRegions'
import { setHeatmapExtremesFromArrayOfValues } from './setHeatmapExtremesFromArrayOfValues'

const getIsDeviceReported = (source: string | null) => {
  if (source === '1') return true

  return false
}

const includeDataSource: Record<TValueGroup, boolean> = {
  air_temperature: true,
  almond_bloom: false,
  almond_hull_split: false,
  alternaria: false,
  atmospheric_pressure: false,
  bee_hours: false,
  chill: false,
  conditions: false,
  degree_days_insect_id_1: false,
  degree_days_insect_id_10: false,
  degree_days_insect_id_11: false,
  degree_days_insect_id_12: false,
  degree_days_insect_id_13: false,
  degree_days_insect_id_14: false,
  degree_days_insect_id_15: false,
  degree_days_insect_id_16: false,
  degree_days_insect_id_17: false,
  degree_days_insect_id_18: false,
  degree_days_insect_id_19: false,
  degree_days_insect_id_2: false,
  degree_days_insect_id_20: false,
  degree_days_insect_id_23: false,
  degree_days_insect_id_24: false,
  degree_days_insect_id_25: false,
  degree_days_insect_id_26: false,
  degree_days_insect_id_27: false,
  degree_days_insect_id_28: false,
  degree_days_insect_id_29: false,
  degree_days_insect_id_30: false,
  degree_days_insect_id_3: false,
  degree_days_insect_id_4: false,
  degree_days_insect_id_6: false,
  degree_days_insect_id_7: false,
  degree_days_insect_id_8: false,
  degree_days_insect_id_9: false,
  dew_point: true,
  equipment_status: false,
  evapotranspiration: false,
  fruit_growth: false,
  plant_stress: false,
  humidity: true,
  irrigation_activity: false,
  larval_trend_insect_id_1: false,
  larval_trend_insect_id_11: false,
  larval_trend_insect_id_20: false,
  larval_trend_insect_id_3: false,
  larval_trend_insect_id_6: false,
  larval_trend_insect_id_7: false,
  larval_trend_insect_id_9: false,
  leaf_wetness: false,
  ndvi: false,
  precipitation: false,
  soil: false,
  spray_conditions: false,
  trap_catches_insect_id_1: false,
  trap_catches_insect_id_10: false,
  trap_catches_insect_id_11: false,
  trap_catches_insect_id_12: false,
  trap_catches_insect_id_15: false,
  trap_catches_insect_id_16: false,
  trap_catches_insect_id_17: false,
  trap_catches_insect_id_19: false,
  trap_catches_insect_id_2: false,
  trap_catches_insect_id_20: false,
  trap_catches_insect_id_25: false,
  trap_catches_insect_id_27: false,
  trap_catches_insect_id_28: false,
  trap_catches_insect_id_29: false,
  trap_catches_insect_id_30: false,
  trap_catches_insect_id_6: false,
  trap_catches_insect_id_7: false,
  trap_catches_insect_id_9: false,
  walnut_blight: false,
  wet_bulb: true,
  wind: false,
  wind_machine: false,
}

const isWeatherStationValue: Record<TValueGroup, boolean> = {
  air_temperature: true,
  alternaria: false,
  almond_bloom: true,
  almond_hull_split: true,
  atmospheric_pressure: true,
  bee_hours: true,
  chill: true,
  conditions: true,
  degree_days_insect_id_1: true,
  degree_days_insect_id_10: true,
  degree_days_insect_id_11: true,
  degree_days_insect_id_12: true,
  degree_days_insect_id_13: true,
  degree_days_insect_id_14: true,
  degree_days_insect_id_15: true,
  degree_days_insect_id_16: true,
  degree_days_insect_id_17: true,
  degree_days_insect_id_18: true,
  degree_days_insect_id_19: true,
  degree_days_insect_id_2: true,
  degree_days_insect_id_20: true,
  degree_days_insect_id_23: true,
  degree_days_insect_id_24: true,
  degree_days_insect_id_25: true,
  degree_days_insect_id_26: true,
  degree_days_insect_id_27: true,
  degree_days_insect_id_28: true,
  degree_days_insect_id_29: true,
  degree_days_insect_id_30: true,
  degree_days_insect_id_3: true,
  degree_days_insect_id_4: true,
  degree_days_insect_id_6: true,
  degree_days_insect_id_7: true,
  degree_days_insect_id_8: true,
  degree_days_insect_id_9: true,
  dew_point: true,
  equipment_status: false,
  evapotranspiration: false,
  fruit_growth: false,
  plant_stress: false,
  humidity: true,
  irrigation_activity: false,
  larval_trend_insect_id_1: false,
  larval_trend_insect_id_11: false,
  larval_trend_insect_id_20: false,
  larval_trend_insect_id_3: false,
  larval_trend_insect_id_6: false,
  larval_trend_insect_id_7: false,
  larval_trend_insect_id_9: false,
  leaf_wetness: false,
  ndvi: false,
  precipitation: true,
  soil: false,
  spray_conditions: false,
  trap_catches_insect_id_1: false,
  trap_catches_insect_id_10: false,
  trap_catches_insect_id_11: false,
  trap_catches_insect_id_12: false,
  trap_catches_insect_id_15: false,
  trap_catches_insect_id_16: false,
  trap_catches_insect_id_17: false,
  trap_catches_insect_id_19: false,
  trap_catches_insect_id_2: false,
  trap_catches_insect_id_20: false,
  trap_catches_insect_id_25: false,
  trap_catches_insect_id_27: false,
  trap_catches_insect_id_6: false,
  trap_catches_insect_id_7: false,
  trap_catches_insect_id_9: false,
  trap_catches_insect_id_28: false,
  trap_catches_insect_id_29: false,
  trap_catches_insect_id_30: false,
  walnut_blight: false,
  wet_bulb: true,
  wind: true,
  wind_machine: true,
}

export type TGenerateUsualStyleGetCacheUpdatesFromResponseArgs = {
  cacheKeys: TCurrentValuesMapCacheKeys
  processedCaches: TCurrentValuesMapProcessedCaches
  response: routes.ValuesCurrent.Response
  block?: {
    decimalPlaces?: 0 | 1
    valueType: TValuesCurrentBlocksValueTypes
    unitConverterFunction: TUnitConverterFunction
    valueKey: string
  }
  inBlockPoint?: {
    decimalPlaces?: 0 | 1
    valueType: TValuesCurrentPointsValueTypes | TValuesCurrentHeatmapPointsValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  trapPoint?: {
    decimalPlaces?: 0 | 1
    valueType: TValuesCurrentPointsValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  outOfBlockPoint?: {
    decimalPlaces?: 0 | 1
    valueType: TValuesCurrentPointsValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  property?: {
    decimalPlaces?: 0 | 1
    valueType: TValuesCurrentPropertiesValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  region?: {
    decimalPlaces?: 0 | 1
    valueType: TValuesCurrentRegionValueTypes
    unitConverterFunction: TUnitConverterFunction
  }
  heatmapColoring?: TRGBAColorWith1AtTheEnd[]
  heatmapExtremesForAllPropertiesInVisibleRegions: boolean
}

/**
 * we can manually populate the SCDs for each block,
 * since we don't include SCDs in the user-app-startup
 */
export const populateSCDsForBlocks = (
  response: routes.ValuesCurrent.Response,
  processedCaches: TCurrentValuesMapProcessedCaches,
) => {
  const blocksToSCDs: Record<TFieldAssetKeyTypes.TBlockId, TFieldAssetKeyTypes.TLngLat[]> = {}

  Object.entries(response?.heatmap_points ?? {}).forEach(([lngLat, { blockIds }]) => {
    blockIds?.forEach((blockId) => {
      blocksToSCDs[blockId] = blocksToSCDs[blockId] ?? []

      blocksToSCDs[blockId].push(lngLat as TFieldAssetKeyTypes.TLngLat)
    })
  })

  processedCaches.blockScdsValues.itemsWithinView.forEach((block) => {
    block.meta.scds = !blocksToSCDs[Number(block.id)]
      ? []
      : blocksToSCDs[Number(block.id)].map((lngLat) => ({
          lngLat,
          googleLatLng: new google.maps.LatLng(lngLatStringToLngLatObject(lngLat)),
        }))
  })

  return processedCaches
}

export const generateUsualStyleGetCacheUpdatesFromResponse = ({
  cacheKeys,
  processedCaches: processedCachesArg,
  response,
  block: blockArg,
  inBlockPoint,
  outOfBlockPoint,
  trapPoint,
  property: propertyArg,
  heatmapColoring,
  heatmapExtremesForAllPropertiesInVisibleRegions,
  region: regionArg,
}: TGenerateUsualStyleGetCacheUpdatesFromResponseArgs): TGetCacheUpdatesFromResponseReturn => {
  const processedCaches = populateSCDsForBlocks(response, processedCachesArg)
  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const allValues: (number | null)[] = []
  const { showDataSource } = smallStore.getState()
  const { properties } = fieldAssetStore.getState()
  const primaryValueGroup = getPrimaryValueGroup()
  const isSourcePrimaryGroupSelected = !!includeDataSource[primaryValueGroup as TValueGroup]

  if (blockArg && !!processedCaches.blockValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockValues.itemsWithinView.flatMap((block) => {
      // @ts-ignore
      const { [blockArg.valueKey]: value } = response?.blocks?.[block.id]?.[blockArg.valueType] ?? {}

      if (primaryValueGroup === 'almond_bloom' && isNil(value)) {
        const blockCouldHaveValue = doesBlockHaveValueType({
          propertyId: block.meta.propertyId,
          blockId: Number(block.id),
          currentValueToCheck: blockArg.valueType,
        })

        if (!blockCouldHaveValue) {
          return []
        }
      } else if (isNil(value)) {
        return []
      }

      itemIdsWithinView.push(block.id)

      allValues.push(value)

      return {
        id: String(block.meta.blockId),
        value: {
          [blockArg.valueType]: {
            children: blockArg
              .unitConverterFunction(value, { decimalPlaces: blockArg.decimalPlaces ?? 0 })
              .valueAsString(),
            getContainerCSS: () => getHeatmapCSS({ value }),
          },
        },
      }
    })

    returner.blocks = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.blockValuesCacheKey,
      itemIdsWithinView,
    }
  }

  if (inBlockPoint && !!processedCaches.blockScdsValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockScdsValues.itemsWithinView.flatMap(
      (block) => {
        return {
          id: block.meta.blockId,
          value: block.meta.scds.reduce((acc: TValueType, { googleLatLng, lngLat }) => {
            // @ts-ignore
            const { value, source } = response?.heatmap_points?.[lngLat]?.[inBlockPoint.valueType] ?? {}
            const isDeviceReported = getIsDeviceReported(source)

            itemIdsWithinView.push(block.id)

            allValues.push(value)

            acc[googleLatLng.toUrlValue()] = {
              [inBlockPoint.valueType]: {
                children: inBlockPoint
                  .unitConverterFunction(value, { decimalPlaces: inBlockPoint.decimalPlaces ?? 0 })
                  .valueAsString(),
                getContainerCSS: () => getHeatmapCSS({ value }),
                shouldAddDataSourceOverlay:
                  !isNil(value) && !isDeviceReported && isSourcePrimaryGroupSelected && showDataSource,
              },
            }

            return acc
          }, {}),
        }
      },
    )

    returner.heatmapPoints = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.scdCacheKey,
      itemIdsWithinView: [...new Set(itemIdsWithinView)],
    }
  }

  if ((inBlockPoint || outOfBlockPoint) && !!processedCaches.stationValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.stationValues.itemsWithinView.flatMap(
      (station) => {
        let keyToUse = inBlockPoint?.valueType
        let decimalPlacesToUse = inBlockPoint?.decimalPlaces
        let unitConverterToUse = inBlockPoint?.unitConverterFunction

        if (station.meta.isOutOfBlock && outOfBlockPoint) {
          keyToUse = outOfBlockPoint.valueType

          decimalPlacesToUse = outOfBlockPoint.decimalPlaces ?? decimalPlacesToUse

          unitConverterToUse = outOfBlockPoint.unitConverterFunction
        }

        if (!keyToUse || !unitConverterToUse) return []

        // @ts-ignore TODO: coordinate types a bit better
        const value = response?.points?.[station.meta.lngLat]?.[keyToUse]?.value as number | null
        // @ts-ignore
        const source = response?.points?.[station.meta.lngLat]?.[keyToUse]?.source as string | null
        const isDeviceReported = getIsDeviceReported(source)

        const isVirtual =
          properties?.[station.meta.propertyId]?.points?.[station.meta.lngLat].isVirtual ?? false

        itemIdsWithinView.push(station.id)

        allValues.push(value)

        const children = unitConverterToUse(value, { decimalPlaces: decimalPlacesToUse ?? 0 }).valueAsString()

        return {
          id: String(station.id),
          value: {
            [keyToUse]: {
              children,
              getContainerCSS: () => getHeatmapCSS({ value }),
              getSize: () => {
                let size = outOfBlockPoint ? 36 : 40

                if (children.length > 3) size = size + (children.length - 3) * 6

                return size
              },
              shouldAddDataSourceOverlay:
                !isNil(value) &&
                !isVirtual &&
                !isDeviceReported &&
                isSourcePrimaryGroupSelected &&
                showDataSource,
              isWeatherStation: primaryValueGroup ? isWeatherStationValue[primaryValueGroup] : false,
            },
          },
        }
      },
    )

    returner.stations = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
      itemIdsWithinView,
    }
  }

  if (trapPoint && !!processedCaches.trapsValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.trapsValues.itemsWithinView.flatMap((trap) => {
      const value = response?.points?.[trap.meta.lngLat]?.[trapPoint.valueType]?.value as number | null

      itemIdsWithinView.push(trap.id)

      allValues.push(value)

      const children = trapPoint
        .unitConverterFunction(value, { decimalPlaces: trapPoint.decimalPlaces ?? 0 })
        .valueAsString()

      return {
        id: String(trap.id),
        value: {
          [trapPoint.valueType]: {
            children,
            getContainerCSS: () => getHeatmapCSS({ value }),
          },
        },
      }
    })

    returner.traps = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.trapsCacheKey,
      itemIdsWithinView,
    }
  }

  const willShowStations = !!returner.stations?.itemIdsWithinView.length
  const willShowBlocks = !!returner.blocks?.itemIdsWithinView.length
  const willShowHeatmapPoints = !!returner.heatmapPoints?.itemIdsWithinView.length
  const willShowTraps = !!returner.traps?.itemIdsWithinView.length
  const propertyIdsToValues: Record<TFieldAssetKeyTypes.TPropertyId, number | null> = {}
  const propertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))
  const regionIdsToValues: Record<TFieldAssetKeyTypes.TRegionId, number | null> = {}
  const regionIds = processedCaches.regionValues?.itemIdsWithinView || []

  if (regionArg && regionArg.valueType && smallStore.getState()?.showRegionalData) {
    regionIds.forEach((regionId) => {
      const valueTypeForRegion = response?.regions?.[regionId]?.[regionArg.valueType]

      let valueForRegion = null

      if (valueTypeForRegion && 'median_value' in valueTypeForRegion) {
        valueForRegion = valueTypeForRegion.median_value
      }

      regionIdsToValues[regionId] = valueForRegion
    })
  }

  if (propertyArg && propertyArg.valueType) {
    propertyIds.forEach((propertyId) => {
      const valueTypeForProperty = response?.properties?.[propertyId]?.[propertyArg.valueType]

      let valueForProperty = null

      if (valueTypeForProperty && 'value' in valueTypeForProperty) {
        valueForProperty = valueTypeForProperty.value
      }

      if (valueTypeForProperty && 'median_value' in valueTypeForProperty) {
        valueForProperty = valueTypeForProperty.median_value
      }

      if (typeof valueForProperty !== 'string' && !Array.isArray(valueForProperty)) {
        // @ts-ignore
        propertyIdsToValues[propertyId] = valueForProperty
      }
    })
  }

  // TODO: not sure about this logic
  const showValuesForRegionRatherThanName = regionArg && !isEmpty(regionIdsToValues)

  const regionItemsWithinViewThatNowHaveValues = processedCaches.regionValues?.itemsWithinView.flatMap(
    (region) => {
      if (!showValuesForRegionRatherThanName) {
        return {
          id: region.id,
          value: {
            [String(regionArg?.valueType)]: {
              children: null,
              onHover: false,
              getContainerCSS: () => ({}),
              baseZIndex: undefined,
            },
          },
        }
      }

      const value = regionIdsToValues[region.id]

      allValues.push(value)

      return {
        id: region.id,
        value: {
          [String(regionArg?.valueType)]: {
            children: regionArg
              .unitConverterFunction(value, { decimalPlaces: regionArg.decimalPlaces ?? 0 })
              .valueAsString(),
            onHover: true,
            getContainerCSS: () => {
              if (isNil(value)) {
                return { display: 'none' }
              }

              return getHeatmapCSS({ value })
            },
            baseZIndex: 1000,
          },
        },
      }
    },
  )

  const showValuesForPropertyRatherThanName =
    propertyArg &&
    !isEmpty(propertyIdsToValues) &&
    !willShowStations &&
    !willShowBlocks &&
    !willShowHeatmapPoints &&
    !willShowTraps

  const propertyItemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.flatMap(
    (property) => {
      if (!showValuesForPropertyRatherThanName) {
        return {
          id: String(property.id),
          value: {
            [String(propertyArg?.valueType)]: {
              children: property.meta.propertyName,
              onHover: false,
              getContainerCSS: () => ({ backgroundColor: colors.midnight, color: 'white' }),
              baseZIndex: undefined,
            },
          },
        }
      }

      const value = propertyIdsToValues[Number(property.id)]

      allValues.push(value)

      return {
        id: String(property.id),
        value: {
          [String(propertyArg?.valueType)]: {
            children: propertyArg
              .unitConverterFunction(value, { decimalPlaces: propertyArg.decimalPlaces ?? 0 })
              .valueAsString(),
            onHover: true,
            getContainerCSS: () => getHeatmapCSS({ value }),
            baseZIndex: isNil(value) ? -10 : undefined,
          },
        },
      }
    },
  )

  if (primaryValueGroup && regionArg) {
    returner.regions = {
      itemsWithinViewThatNowHaveValues: regionItemsWithinViewThatNowHaveValues ?? [],
      cacheKey: cacheKeys.regionValuesCacheKey,
      itemIdsWithinView: processedCaches.regionValues?.itemIdsWithinView ?? [],
    }
  }

  returner.properties = {
    itemsWithinViewThatNowHaveValues: propertyItemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  let valuesToUseForExtremes = allValues

  if (heatmapExtremesForAllPropertiesInVisibleRegions && propertyArg?.valueType) {
    const visiblePropertyIds = processedCaches.propertyValues.itemsWithinView.map((s) => Number(s.id))
    const allPropertyIdsInVisibleRegions = getPropertyIdsForAllPropertiesInVisibleRegions(visiblePropertyIds)

    if (allPropertyIdsInVisibleRegions.length > 1) {
      const valuesForAllPropertiesInVisibleRegion: number[] = []

      allPropertyIdsInVisibleRegions.forEach((propertyId) => {
        const valueTypeResponseForProperty = response?.properties?.[propertyId]?.[propertyArg?.valueType]

        if (!isNil(valueTypeResponseForProperty)) {
          const foundValueForProperty =
            'median_value' in valueTypeResponseForProperty
              ? valueTypeResponseForProperty.median_value
              : valueTypeResponseForProperty.value

          if (!isNil(foundValueForProperty) && typeof foundValueForProperty === 'number') {
            valuesForAllPropertiesInVisibleRegion.push(foundValueForProperty)
          }
        }
      })

      if (valuesForAllPropertiesInVisibleRegion.length > 1) {
        valuesToUseForExtremes = valuesForAllPropertiesInVisibleRegion
      }
    }
  }

  setHeatmapExtremesFromArrayOfValues({ values: valuesToUseForExtremes, heatmapColoring })

  return returner
}
