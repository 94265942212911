import { Select, Stack, TextInput } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import type { TAdminPermission, TRole } from 'App/Map/Admin/utils/useAdminEntites'
import { ConfirmationSettingsModal } from 'components/ConfirmationSettingsModal/ConfirmationSettingsModal'
import { HierarchicalSelection } from 'components/HierarchicalSelection/HierarchicalSelection'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { apiFetch } from 'utils/apiFetch'
import { showNotification } from 'utils/showNotification'
import { sortByKey } from 'utils/sortByKey'
import { CancelSaveButtonGroup } from '../../CancelSaveButtonGroup/CancelSaveButtonGroup'
import { PermissionRow } from '../../PermissionRow/PermissionRow'

export const ViewEditRoleModal = ({
  role,
  permissions: permissionsArg,
  opened,
  onClose,
}: {
  role: TRole | null
  permissions: TAdminPermission[]
  opened: boolean
  onClose: (hasChanges?: boolean) => void
}) => {
  const [selectedRole, setRole] = useState<TRole | null>(role)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setHasErrors] = useState<Partial<Record<keyof TRole, string | null>>>({})
  const [showConfirmCancelWithoutSaving, setShowConfirmCancelWithoutSaving] = useState(false)
  const [searchString, setSearchString] = useState<string | undefined>()
  const [showOnlyChecked, setShowOnlyChecked] = useState(false)
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(role?.permissions ?? [])
  const [permissions, setPermissions] = useState<TAdminPermission[]>(permissionsArg)
  const [fieldTouched, setFieldTouched] = useState<Partial<Record<keyof TRole, boolean>>>({})

  useEffect(() => {
    let filteredPermissions: typeof permissionsArg = []

    const roleType = selectedRole?.roleType

    if (roleType) {
      filteredPermissions = permissionsArg.filter((p) => p.permissionTypes.includes(roleType))
    }

    filteredPermissions = arrayOfObjectsSearch<TAdminPermission>(
      filteredPermissions ?? [],
      searchString ?? '',
      ['id'],
    )

    if (showOnlyChecked) {
      filteredPermissions = filteredPermissions.filter((p) => selectedPermissions.includes(p.id))
    }

    setPermissions(filteredPermissions)
  }, [searchString, showOnlyChecked, selectedPermissions, selectedRole])

  useEffect(() => {
    if (role && opened) {
      setRole({ ...role })

      setSelectedPermissions(role?.permissions ?? [])
    } else {
      setRole(null)
    }
  }, [role, opened])

  useEffect(() => {
    setHasChanges(!isEqual(selectedRole, role))

    setHasErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      const { name, description, roleType } = selectedRole || {}

      newErrors.name = name?.trim()?.length
        ? null
        : translate.phrases.validation('{{label}} is required', {
            label: translate.phrases.banyanApp('Name'),
          })

      newErrors.description = description?.trim()?.length
        ? null
        : translate.phrases.validation('{{label}} is required', {
            label: translate.phrases.banyanApp('Description'),
          })

      newErrors.roleType = roleType?.length
        ? null
        : translate.phrases.validation('{{label}} is required', {
            label: translate.phrases.banyanApp('Type'),
          })

      return newErrors
    })
  }, [selectedRole])

  const preventAutoCompleteProps = { autoComplete: 'off', name: 'searchHack' }

  const handleResetAndClose = (hasChanges?: boolean) => {
    setRole(null)

    setSelectedPermissions([])

    setFieldTouched({})

    setHasErrors({})

    onClose(hasChanges)
  }

  const handleClose = () => {
    if (hasChanges) {
      setShowConfirmCancelWithoutSaving(true)
    } else {
      handleResetAndClose()
    }
  }

  const handleUpdate = (key: keyof TRole, value: TRole[keyof TRole]) => {
    setRole((prev) => {
      if (prev) {
        return { ...prev, [key]: value }
      }

      return { [key]: value } as TRole
    })
  }

  const handleSave = async () => {
    const hasErrors = Object.values(errors).some(Boolean)

    if (hasErrors || !selectedRole) {
      return
    }

    const { id: roleId, description, roleType } = selectedRole

    const result = await apiFetch<routes.AdminUpsertRole.Request, routes.AdminUpsertRole.Response>({
      url: routes.AdminUpsertRole.path,
      body: {
        id: roleId,
        name: selectedRole.name,
        description,
        roleType,
        permissions: selectedPermissions,
      },
    }).catch(() => {
      showNotification({
        type: 'error',
        message: translate.phrases.validation('{{label}} could not be updated', {
          label: translate.phrases.banyanApp('Role'),
        }),
      })
    })

    if (result?.data) {
      showNotification({
        type: 'success',
        message: translate.phrases.banyanApp('Role successfully updated'),
      })
    } else {
      showNotification({
        type: 'error',
        message: translate.phrases.validation('{{label}} could not be updated', {
          label: translate.phrases.banyanApp('Role'),
        }),
      })
    }

    handleResetAndClose(true)
  }

  const modalTitle = selectedRole
    ? translate.phrases.banyanApp('View/Edit Role')
    : translate.phrases.banyanApp('Create New Role')

  return (
    <ModalDrawer
      title={modalTitle}
      opened={opened}
      onClose={handleClose}
      zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX + 10}
    >
      {
        <form css={{ padding: 10 }}>
          <Stack spacing="lg">
            <TextInput
              {...preventAutoCompleteProps}
              label={translate.phrases.banyanApp('Name')}
              required
              defaultValue={selectedRole?.name ?? ''}
              onChange={(e) => handleUpdate('name', e.target.value)}
              onBlur={() => setFieldTouched((prev) => ({ ...prev, name: true }))}
              error={fieldTouched.name && errors.name}
            />
            <TextInput
              {...preventAutoCompleteProps}
              autoComplete="off"
              label={translate.phrases.banyanApp('Description')}
              required
              defaultValue={selectedRole?.description ?? ''}
              onChange={(e) => handleUpdate('description', e.target.value)}
              onBlur={() => setFieldTouched((prev) => ({ ...prev, description: true }))}
              error={fieldTouched.description && errors.description}
            />
            <Select
              label={translate.phrases.banyanApp('Role Type')}
              required
              defaultValue={selectedRole?.roleType ?? ''}
              onChange={(value: TRole['roleType']) => handleUpdate('roleType', value)}
              onBlur={() => setFieldTouched((prev) => ({ ...prev, roleType: true }))}
              data={[
                { value: 'PROPERTY', label: translate.phrases.banyanApp('Property') },
                { value: 'SYSTEM', label: translate.phrases.banyanApp('System') },
              ]}
              error={fieldTouched.roleType && errors.roleType}
            />
            {selectedRole?.roleType && (
              <HierarchicalSelection
                searchString={searchString ?? ''}
                setSearchString={setSearchString}
                setShowOnlyChecked={setShowOnlyChecked}
                showOnlyChecked={showOnlyChecked}
                title={translate.phrases.banyanApp('Permissions')}
                width={'100%'}
              >
                {permissions.sort(sortByKey('id')).map((permission) => {
                  return (
                    <PermissionRow
                      key={permission.id}
                      permission={permission}
                      selected={selectedPermissions.includes(permission.id)}
                      hasEditPermission={true}
                      setSelected={(selected) => {
                        setSelectedPermissions((prev) => {
                          if (selected) {
                            return [...prev, permission.id]
                          }

                          return prev.filter((id) => id !== permission.id)
                        })
                      }}
                    />
                  )
                })}
              </HierarchicalSelection>
            )}
          </Stack>
          <CancelSaveButtonGroup
            onCancel={handleClose}
            onSave={handleSave}
            saveDisabled={Object.values(errors).some(Boolean)}
          />
        </form>
      }
      <ConfirmationSettingsModal
        confirmModalOpened={showConfirmCancelWithoutSaving}
        setConfirmModalOpened={setShowConfirmCancelWithoutSaving}
        handleResetAndClose={handleResetAndClose}
        handleUpdate={handleSave}
      />
    </ModalDrawer>
  )
}
