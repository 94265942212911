import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconContactRolodex = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 19 23')}>
      <path
        d="M17.1 0H1.9V1.91667H17.1V0ZM1.9 23H17.1V21.0833H1.9V23ZM17.1 3.83333H1.9C0.855 3.83333 0 4.69583 0 5.75V17.25C0 18.3042 0.855 19.1667 1.9 19.1667H17.1C18.145 19.1667 19 18.3042 19 17.25V5.75C19 4.69583 18.145 3.83333 17.1 3.83333ZM9.5 6.46875C10.678 6.46875 11.6375 7.43667 11.6375 8.625C11.6375 9.81333 10.678 10.7812 9.5 10.7812C8.322 10.7812 7.3625 9.81333 7.3625 8.625C7.3625 7.43667 8.322 6.46875 9.5 6.46875ZM14.25 16.2917H4.75V14.8542C4.75 13.2537 7.9135 12.4583 9.5 12.4583C11.0865 12.4583 14.25 13.2537 14.25 14.8542V16.2917Z"
        fill="currentColor"
      />
    </svg>
  )
}
