import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { isEmpty } from 'lodash'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TNodeDeviceType } from '../../store/serviceCenterStore'
import type { TNodeDevice, TNodeDevices } from '../../types'

export const DEVICE_GROUPS_PERMISSIONS: { [deviceGroupName: string]: TPermission } = {
  'Frost Fan': 'EDIT_SSC_NODE_DEVICE_FROST_FAN',
  'Leaf Temperature': 'EDIT_SSC_NODE_DEVICE_LEAF_WETNESS',
  'Leaf Wetness': 'EDIT_SSC_NODE_DEVICE_LEAF_WETNESS',
  'Plant Health': 'EDIT_SSC_NODE_DEVICE_DENDROMETER',
  'Soil Moisture': 'EDIT_SSC_NODE_DEVICE_SOIL',
  'Water Flow': 'EDIT_SSC_NODE_DEVICE_WATER_FLOW',
  'Weather': 'EDIT_SSC_NODE_DEVICE_WEATHER',
}

export const DEVICE_GROUPS_TYPES: { [deviceGroupName: string]: TNodeDeviceType } = {
  'Frost Fan': 'frost-fan',
  'Leaf Temperature': 'leaf-wetness',
  'Leaf Wetness': 'leaf-wetness',
  'Plant Health': 'dendrometer',
  'Soil Moisture': 'soil',
  'Water Flow': 'water-flow',
  'Weather': 'weather',
}

export function getNodeDeviceTypes(nodeDevices: TNodeDevices | undefined): TNodeDeviceType[] {
  const allDevices = fieldAssetStore.getState().devices

  if (!nodeDevices || isEmpty(allDevices)) return []

  const deviceSources = (
    Object.values(nodeDevices)
      .map((group) => Object.values(group))
      .flat() as TNodeDevice[]
  ).map((device: TNodeDevice) => device.source)

  const deviceTypes = deviceSources.map((source: string) => {
    const deviceType = DEVICE_GROUPS_TYPES[allDevices[source]?.deviceGroupName]

    if (!deviceType) {
      throw new Error(`Unknown device type for source: ${source}`)
    }

    return deviceType
  })

  return deviceTypes
}
