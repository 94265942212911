import { colors } from 'settings/colors'

export const Error = ({ size = 28, color = colors.red }: { size?: number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13" fill="white" stroke={color} strokeWidth="2" />
    <path
      d="M19.6 9.52802L18.472 8.40002L14 12.872L9.52802 8.40002L8.40002 9.52802L12.872 14L8.40002 18.472L9.52802 19.6L14 15.128L18.472 19.6L19.6 18.472L15.128 14L19.6 9.52802Z"
      fill={color}
    />
  </svg>
)
