import { globalLanguageStore, i18n } from 'i18n/i18n'
import { useEffect, useState } from 'react'

/**
 * The purpose of this hook is to force English for the self registration flow along with the landing-page-app
 * until we are able to localize that flow. (We don't want situations where parts of the UI in that
 * flow is translated and other parts are not.)
 * Also note that we have the forceEnglish option in landing-page-app
 */
export const useForceEnglish = () => {
  const globalLanguage = globalLanguageStore.useSelector((lng) => lng)
  const [isLoadingEnglish, setIsLoadingEnglish] = useState(true)

  useEffect(() => {
    if (globalLanguage !== 'en') {
      const setLanguageToEnglish = async () => {
        await i18n.globalLng.changeLanguage('en')

        setIsLoadingEnglish(false)
      }

      setLanguageToEnglish()
    } else {
      setIsLoadingEnglish(false)
    }
  }, [globalLanguage])

  return { isLoadingEnglish }
}
