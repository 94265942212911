// TODO: fix date range text when spanning multiple years
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'

export const makeDateRangeTextFromCompareSeasonsInterval = ({
  dateFrom,
  dateTo,
  interval,
  timezone,
}: {
  dateFrom: string
  dateTo: string
  interval: number
  timezone: string
}) => {
  const reportStartMoment = moment.tz(dateFrom, timezone).subtract(interval, 'year')
  const reportEndMoment = moment.tz(dateTo, timezone).subtract(interval, 'year')

  return translate.phrases.templates('{{dateFrom}} - {{dateTo}}', {
    dateFrom: translate.dates.format(reportStartMoment, 'MMM D, YYYY'),
    dateTo: translate.dates.format(reportEndMoment, 'MMM D, YYYY'),
  })
}
