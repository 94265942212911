import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconPause = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 22 22')}>
      <rect x="9" y="7" width="1" height="8" rx="0.5" fill="currentColor" />
      <rect x="12" y="7" width="1" height="8" rx="0.5" fill="currentColor" />
    </svg>
  )
}
