import { Badge } from '@mantine/core'
import { colors } from 'settings/colors'

export const CountBadge = ({ count }: { count: number | null }) => {
  return (
    <Badge
      css={{
        alignItems: 'center',
        borderRadius: '50%',
        color: colors.white,
        display: 'flex',
        fontSize: 12,
        height: 20,
        justifyContent: 'center',
        minWidth: 20,
      }}
      styles={{ root: { padding: 2, backgroundColor: colors.grey500, marginLeft: 10, alignSelf: 'center' } }}
      radius="xl"
      size="sm"
    >
      {count}
    </Badge>
  )
}
