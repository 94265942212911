import type { Polygon } from '@turf/helpers'
import { networkStore } from 'App/ServiceCenter/store/networkStore'
import { isEmpty } from 'lodash'
import { useContext, useEffect } from 'react'
import { getMapOverlaysPadding } from '../App/Map/CurrentValuesMap/_utils/getMapOverlaysPadding'
import { MapContext } from '../App/Map/MapContext/MapContext'
import { fieldAssetStore } from '../stores/fieldAssetStore'
import { selectedFieldAssetsStore } from '../stores/selectedFieldAssetsStore'

function waitIdle(map: google.maps.Map) {
  return new Promise<void>((resolve) => {
    const l = map?.addListener('idle', () => {
      l?.remove()

      resolve()
    })
  })
}

export const getPropertyBounds = (propertyId: number) => {
  const { properties } = fieldAssetStore.getState() ?? {}

  if (!properties || !window.google?.maps) return

  const propertyBounds = new google.maps.LatLngBounds()
  const propertyBlockCoords: { lng: number; lat: number }[] = []
  const { blocks } = properties[propertyId] ?? {}

  if (blocks) {
    Object.values(blocks).forEach((block) => {
      const { coordinates } = JSON.parse(block.geometry) as Polygon

      coordinates[0].forEach(([lng, lat]) => {
        propertyBlockCoords.push({ lng, lat })
      })
    })
  }

  if (!isEmpty(propertyBlockCoords)) {
    propertyBlockCoords.forEach((pb) => propertyBounds.extend(pb))
  }

  return propertyBounds
}

export const useMapCenteredOnProperties = () => {
  const { map } = useContext(MapContext)
  const selectedPropertyId = selectedFieldAssetsStore.useSelector((s) => s.property)
  const networkStatus = networkStore.getState().connectionStatus

  useEffect(() => {
    if (!selectedPropertyId || !map) return

    const propertyBounds = getPropertyBounds(selectedPropertyId)

    if (isEmpty(propertyBounds)) {
      return
    }

    const fitBounds = async () => {
      if (!networkStatus.connected) {
        // eslint-disable-next-line no-console
        console.log('Network is offline, zoom out to force map to draw the bounds and nodes')

        await waitIdle(map)

        //Trick here, to force the map to draw the boundes and nodes when the network is offline, we set the zoom out. (SSC-85)
        map.setZoom(0)

        await waitIdle(map)
      }

      map.fitBounds(propertyBounds, getMapOverlaysPadding())
    }

    fitBounds()
  }, [selectedPropertyId, map])
}
