import { Global } from '@emotion/react'
import { LoadingOverlay } from '@mantine/core'
import { IrrigationSchedulerLauncherLazy } from 'App/irrigation-scheduler/IrrigationSchedulerLauncherLazy'
import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { WeatherForecastModalLazy } from 'App/Map/WeatherForecastModal/WeatherForecastModalLazy'
import React, { useEffect, useMemo, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { useIsServiceCenterActive } from 'utils/useIsServiceCenterActive'
import { useSetStatusBarTheme } from 'utils/useSetStatusBarTheme'
import { containerId } from '../../index'
import { ServiceCenterDetailPanel } from '../ServiceCenter/DetailPanel/DetailPanel'
import { ServiceCenterMap } from '../ServiceCenter/Map/ServiceCenterMap'
import { CurrentValuesMap } from './CurrentValuesMap/CurrentValuesMap'
import { LayerNav } from './LayerNav/LayerNav'
import { MapControls } from './MapControls/MapControls'
import { MapDetailsPanel } from './MapDetailsPanel'
import { MapSearch } from './MapSearch/MapSearch'
import { PrivacyConsentScreen } from './PrivacyConsentScreen/PrivacyConsentScreen'
import { UserSettingsMenu } from './UserSettingsMenu/UserSettingsMenu'
import { loadAppStartupData } from './_utils/loadAppStartupData'
import { useHasAgreedToCurrentAcceptancePackage } from './_utils/useHasAgreedToCurrentAcceptancePackage'

export const Map = () => {
  const [hasAppStartupData, setHasAppStartupData] = useState(false)
  const properties = fieldAssetStore.useSelector((s) => s.properties ?? {})
  const propertyIdsKey = useMemo(() => Object.keys(properties).sort().join(','), [properties])
  const isServiceCenterActive = useIsServiceCenterActive()
  const showIrrigationSchedulerLauncher = irrigationSchedulerStore.useSelector((s) => s.showLauncher)

  const { hasAgreedToCurrentAcceptancePackage, hasAgreedToCurrentAcceptancePackageData } =
    useHasAgreedToCurrentAcceptancePackage()

  useSetStatusBarTheme({ darkContent: false })

  useEffect(() => {
    loadAppStartupData()
      .then(() => {
        setHasAppStartupData(true)
      })
      .catch((error) => {
        //TODO, what should we do here?
        // eslint-disable-next-line no-console
        console.error('Error loading app startup data', error)
      })
  }, [])

  if (!hasAgreedToCurrentAcceptancePackageData) {
    return (
      <div css={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}>
        <LoadingOverlay visible={true} />
      </div>
    )
  }

  if (hasAgreedToCurrentAcceptancePackageData && !hasAgreedToCurrentAcceptancePackage) {
    return <PrivacyConsentScreen />
  }

  if (showIrrigationSchedulerLauncher) {
    // TODO: Just hacking in Irrigation Scheduler for now like this because dont want the global styles
    return <IrrigationSchedulerLauncherLazy />
  }

  return (
    // putting a key on this will force-refresh the map when the user's field assets change
    <React.Fragment key={propertyIdsKey}>
      {/*Note: This Global styles component removes these styles when it unmounts :D */}
      <Global
        styles={{
          [`html,body,#${containerId}`]: {
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            touchAction: 'none',
          },
          body: {
            overflow: 'hidden', // This is a little hack to disable the pull-to-refresh behaviour introduced on ios 15
          },
        }}
      />
      <div
        css={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `linear-gradient(#ccc 1px, transparent 1px),
          linear-gradient(to right, #ccc 1px, transparent 1px)`,
          backgroundSize: '100px 100px',
        }}
      />
      {isServiceCenterActive ? (
        <>
          <ServiceCenterMap hasAppStartupData={hasAppStartupData} />
          <ServiceCenterDetailPanel />
        </>
      ) : (
        <>
          <CurrentValuesMap hasAppStartupData={hasAppStartupData} />
          <MapDetailsPanel />
        </>
      )}
      <LayerNav />
      <MapControls />
      <MapSearch />
      <WeatherForecastModalLazy />
      <UserSettingsMenu />
    </React.Fragment>
  )
}
