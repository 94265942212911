import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TEmitterType } from '@semios/app-platform-common'
import {
  getValueTypeFromEmitterType,
  IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX,
  IRRIGATION_PLANNED_VOLUME_VALUE_TYPE_PREFIX,
} from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import type { EAggregationInterval, TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'

type TIrrigationZoneEmittersKey = keyof NonNullable<
  routes.UserAppStartup.Response['properties']
>[number]['irrigationZoneEmitters']

type TIrrigationZoneEmitters = NonNullable<
  NonNullable<routes.UserAppStartup.Response['properties']>[number]['irrigationZoneEmitters']
>[TIrrigationZoneEmittersKey]

export const apiArgsForIrrigationZones = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!selectedValueGroups.irrigation_activity || !selectedFieldAssets.property) return {}

  const preferredAggregationInterval = {
    preferredAggregationInterval: 'DAILY' as EAggregationInterval.DAILY,
  }

  const property = fieldAssetStore.getState().properties?.[selectedFieldAssets.property]

  if (!property) return {}

  const irrigationZoneEmittersData: TIrrigationZoneEmitters[] = property.irrigationZoneEmitters
    ? Object.values(property.irrigationZoneEmitters)
    : []

  const valuesRequested: Record<string, typeof preferredAggregationInterval> = {}
  const irrigationZoneIds: TFieldAssetKeyTypes.TIrrigationZoneId[] = []

  irrigationZoneEmittersData.forEach((irrigationZoneEmitterData) => {
    const { irrigationZoneId, emitterType } = irrigationZoneEmitterData

    if (!irrigationZoneIds.includes(+irrigationZoneId)) irrigationZoneIds.push(+irrigationZoneId)

    const valueTypeForSemiosAppliedRequest = getValueTypeFromEmitterType(
      IRRIGATION_APPLIED_VOLUME_VALUE_TYPE_PREFIX,
      emitterType as TEmitterType,
    ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysForIrrigationZoneAppliedSemiosVolumeDuration

    const valueTypeForSemiosPlannedRequest = getValueTypeFromEmitterType(
      IRRIGATION_PLANNED_VOLUME_VALUE_TYPE_PREFIX,
      emitterType as TEmitterType,
    ) as VV.DomainTypes.Irrigation.TTimeseriesValueTypeKeysForIrrigationZonePlannedSemiosVolumeDuration

    valuesRequested[valueTypeForSemiosAppliedRequest] = preferredAggregationInterval

    valuesRequested[valueTypeForSemiosPlannedRequest] = preferredAggregationInterval
  })

  return {
    irrigationZones: {
      irrigationZoneIds,
      valuesRequested,
    },
  }
}
