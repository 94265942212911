import { css } from '@emotion/css'
import { Checkbox } from 'components/Checkbox/Checkbox'
import React, { useState } from 'react'

export const CheckboxExamples = () => {
  const checkboxLabel = 'Checkbox Label'
  const [checkbox, setCheckbox] = useState(true)

  return (
    <>
      {/* Checkbox Component Examples */}

      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 2rem 1rem;
        `}
      >
        <Checkbox label={checkboxLabel} checked={!checkbox} onChange={() => setCheckbox(!checkbox)} />
        <Checkbox label={checkboxLabel} checked={checkbox} onChange={() => setCheckbox(!checkbox)} />
        <Checkbox
          label={checkboxLabel}
          checked={!checkbox}
          onChange={() => setCheckbox(!checkbox)}
          disabled
        />
      </div>
    </>
  )
}
