import { translate } from 'i18n/i18n'
import { userDetailsStore } from 'stores/userDetailsStore'

export const useEmitterConfigurationUnit = (): {
  unit: string
  dbUnitPerHour: 'INCH' | 'MILLIMETER'
  min: number
  precision: number
} => {
  const rainUnit = userDetailsStore.useSelector((s) => s.rain)

  if (rainUnit === 'IMPERIAL') {
    return {
      unit: translate.measurements.inchesPerHour.unit(),
      dbUnitPerHour: 'INCH',
      min: 0.001,
      precision: 3,
    }
  }

  return {
    unit: translate.measurements.millimetersPerHour.unit(),
    dbUnitPerHour: 'MILLIMETER',
    min: 0.01,
    precision: 2,
  }
}
