import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'
import { colors } from 'settings/colors'

export const IconCurrentLocationMarker = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 60 60')}>
      <circle cx="30" cy="30" r="30" fill="url(#paint0_radial_1289_9690)" />
      <circle cx="30" cy="30" r="12.3233" fill="#F1F1F1" />
      <g filter="url(#filter0_d_1289_9690)">
        <circle cx="30" cy="30" r="8.5" fill={colors.primaryLight} style={{ transformOrigin: 'center' }}>
          <animateTransform
            attributeName="transform"
            type="scale"
            keyTimes="0;0.2;0.4;0.5;0.6;0.8;1"
            values="1;0.98;0.88;0.85;0.88;0.98;1"
            dur="3s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
      <defs>
        <filter
          id="filter0_d_1289_9690"
          x="15"
          y="15"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1289_9690" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1289_9690" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_1289_9690"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30 30) rotate(90.5824) scale(28.9185)"
        >
          <stop stopColor={colors.midnight} stopOpacity="0.9" />
          <stop offset="1" stopColor={colors.midnight} stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}
