import { Select } from 'components/Select/Select'
import { isNil } from 'lodash'
import type { FC } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { updateUserProfile } from 'utils/updateUserProfile'
import type { UnitSettingsSelectProps } from '../../types'

const UnitSettingsSelect: FC<UnitSettingsSelectProps> = ({
  data,
  detailsStoreKey,
  label,
  unitValue,
  unitSettings,
  setUnitSettings,
  stateWithTimeout,
  setStateWithTimeout,
}) => {
  const handleOnChange = async (newValue: string | null) => {
    if (isNil(newValue)) return

    try {
      if (setStateWithTimeout) setStateWithTimeout('loading')

      const newSettings = {
        ...unitSettings,
        [detailsStoreKey]: newValue,
      }

      setUnitSettings(newSettings)

      const response = await updateUserProfile({ ...newSettings })

      if (response) {
        //Success
        userDetailsStore.setState((prevState) => {
          return {
            ...prevState,
            [detailsStoreKey]: newValue,
          }
        })

        if (setStateWithTimeout) setStateWithTimeout('success')
      }
    } catch (error) {
      // Error
      setUnitSettings({
        ...unitSettings,
        [detailsStoreKey]: newValue === 'IMPERIAL' ? 'METRIC' : 'IMPERIAL',
      })

      if (setStateWithTimeout) setStateWithTimeout('error')
    }
  }

  return (
    <div css={{ margin: '20px 0' }}>
      <Select
        data={data}
        value={unitValue}
        handleOnChange={handleOnChange}
        stateWithTimeout={stateWithTimeout}
        label={label}
        labelStyle={{ fontSize: 14 }}
      />
    </div>
  )
}

export default UnitSettingsSelect
