import { Debug } from 'App/Map/LayerNav/Debug/Debug'
import { Divider } from 'components/SideNav/Divider/Divider'
import { Preferences } from 'components/SideNav/Preferences/Preferences'
import { SectionTitle } from 'components/SideNav/SectionTitle/SectionTitle'
import { SideNav } from 'components/SideNav/SideNav'
import { translate } from 'i18n/i18n'
import { layersNavigationMenuStore } from 'stores/layersNavigationMenuStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { Layers } from './Layers/Layers'
import { PresetLayers } from './PresetLayers/PresetLayers'
import { QuickLinks } from './QuickLinks/QuickLinks'

export const LayerNav = () => {
  const menuCollapsed = layersNavigationMenuStore.useSelector((s) => s.menuCollapsed)
  const enoughDataLoadedToShowTheUsualMenu = userDetailsStore.useSelector((s) => s.id)

  const handleToggleExpand = () => {
    layersNavigationMenuStore.setState((s) => ({ ...s, menuCollapsed: !menuCollapsed }))
  }

  return (
    <SideNav collapsed={menuCollapsed} onToggleExpand={handleToggleExpand}>
      {/* LAYERS */}
      {!!enoughDataLoadedToShowTheUsualMenu && (
        <>
          <Divider />
          <SectionTitle>{translate.phrases.banyanApp('LAYERS')}</SectionTitle>
          <PresetLayers />
          <Layers />
          <Divider />
          <SectionTitle>{translate.phrases.banyanApp('QUICK LINKS')}</SectionTitle>
          <QuickLinks />
          <Debug />
          <Divider />
          <SectionTitle>{translate.phrases.banyanApp('PREFERENCES')}</SectionTitle>
        </>
      )}
      <Preferences />
    </SideNav>
  )
}
