import type { CSSObject } from '@emotion/css'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { MapItemsCache } from 'components/GoogleMap/MapItemsCache'
import type { ReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import { makeReactComponentOverlayView } from 'components/GoogleMap/_utils/makeReactComponentOverlayView'
import type { TStationBadgeComponentProps } from './StationBadgeComponent/StationBadgeComponent'
import { StationBadgeComponent } from './StationBadgeComponent/StationBadgeComponent'

export type STATION_META = {
  propertyId: TFieldAssetKeyTypes.TPropertyId
  lngLat: TFieldAssetKeyTypes.TLngLat
  name: string
  latLng: google.maps.LatLng
  isOutOfBlock: boolean
}

// TODO: will eventually come from current values type
export type TStationsValueTypesToPropsDictionary = Record<
  string,
  {
    baseZIndex?: number
    children: React.ReactNode
    getContainerCSS: () => CSSObject
    getSize?: () => number | null
    shouldAddDataSourceOverlay?: boolean
    isWeatherStation?: boolean
  }
>

export class StationValuesCache extends MapItemsCache<
  STATION_META,
  TStationsValueTypesToPropsDictionary,
  ReactComponentOverlayView<TStationBadgeComponentProps>
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        return makeReactComponentOverlayView({
          component: StationBadgeComponent,
          latLng: o.meta.latLng,
          maps: o.maps,
          map: o.map,
        })
      },
      renderOverlay: (o) => {
        o.overlay.render({ ...o, valueTypesToPropsDictionary: o.value })
      },
      hideOverlay: (o) => {
        o.overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
