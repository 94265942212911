import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconHamburgerMenu = () => (
  <svg {...makeBaseSvgIconProps('0 0 20 16')}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H20V3.2H0V0ZM0 6.4H20V9.6H0V6.4ZM0 12.8H20V16H0V12.8Z"
      fill="currentColor"
    />
  </svg>
)
