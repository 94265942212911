import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VC } from '@semios/app-platform-value-type-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { AlmaSummary } from 'components/AlmaSummary/AlmaSummary'
import { userDetailsStore } from 'stores/userDetailsStore'

type TResponseKey = keyof VC.DomainTypes.SidekickStatements.TCurrentValuesReturnIgnoringKeying['properties']

export const Summary = ({
  propertyId,
  regionId,
  insectId,
}: {
  propertyId?: TFieldAssetKeyTypes.TPropertyId | null
  regionId?: TFieldAssetKeyTypes.TRegionId | null
  insectId: TFieldAssetKeyTypes.TInsectId
}) => {
  if (!regionId && !propertyId) return null

  const valueTypeKey = `degree_days_insect_id_${insectId}_sidekick` as TResponseKey

  let params: routes.ValuesCurrent.Request = {}

  if (propertyId) {
    params = {
      properties: {
        propertyIds: [propertyId],
        values: { [valueTypeKey]: true },
      },
    }
  } else if (regionId) {
    params = {
      regions: {
        regionIds: [regionId],
        values: { [valueTypeKey]: true },
      },
    }
  }

  return (
    <AlmaSummary<routes.ValuesCurrent.Response>
      context="details-panel"
      queryParams={params}
      getStatementId={(data: routes.ValuesCurrent.Response) => {
        if (propertyId) {
          return data?.properties?.[propertyId]?.[valueTypeKey]?.value?.uuid
        }

        if (regionId) {
          return data?.regions?.[regionId]?.[valueTypeKey]?.value?.uuid
        }
      }}
      renderFunction={(data: routes.ValuesCurrent.Response) => {
        let dataObject = null

        if (propertyId) {
          dataObject = data?.properties?.[propertyId]?.[valueTypeKey]?.value?.statements
        } else if (regionId) {
          dataObject = data?.regions?.[regionId]?.[valueTypeKey]?.value?.statements
        }

        if (dataObject && 'imperialStatement' in dataObject) {
          const userTemperatureUnits = userDetailsStore.getState()?.temperature
          const statementToUse = userTemperatureUnits === 'IMPERIAL' ? 'imperialStatement' : 'metricStatement'

          return <p>{dataObject[statementToUse]}</p>
        }
      }}
    ></AlmaSummary>
  )
}
