import type { CSSObject } from '@mantine/core'
import type { BaseSelectStylesNames } from '@mantine/core/lib/Select/types'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'

export const getStyles = (
  clearable: boolean,
  disabled: boolean,
): Partial<Record<BaseSelectStylesNames, CSSObject>> => {
  return clearable ? getClearableStyles() : getNonClearableStyles(disabled)
}

const getClearableStyles = (): Partial<Record<BaseSelectStylesNames, CSSObject>> => {
  return {
    dropdown: { marginTop: -6, fontWeight: 'normal' },
    input: { ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.input, paddingRight: 28 },
    item: { minHeight: 30 },
  }
}

const getNonClearableStyles = (disabled: boolean): Partial<Record<BaseSelectStylesNames, CSSObject>> => {
  // TODO: Should customize a mantine <Select /> in our components folder that we then use through the app that deals with all these style tweaks.
  return {
    ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER,
    rightSection: {
      ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.rightSection,
      svg: {
        ...SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER.rightSection.svg,
        color: `${disabled ? 'rgb(238,238,238)' : colors.midnight} !important`,
      },
    },
  }
}
