import type { CSSObject } from '@emotion/css'
import { colors } from 'settings/colors'
import { Checkbox } from '../../../Checkbox/Checkbox'
import { useMultiSelectContext } from '../../MultiSelect.context'
import type { TOptions } from '../../MultiSelect.types'

export const Default = () => {
  const { options, isSelectedValue, onSelectItem, optionValueDecorator } = useMultiSelectContext()

  return (
    <>
      {options.map((option: TOptions, i: number) => {
        const isSelected = isSelectedValue(option)
        const extraStyles: CSSObject = {}

        return (
          <li
            key={`option${i}`}
            css={{
              'padding': 10,
              'display': 'flex',
              'color': colors.midnight,
              'fontSize': 14,
              'fontWeight': isSelected ? '700' : '400',
              '&:hover': {
                background: colors.grey50,
                cursor: 'pointer',
              },
              ...extraStyles,
            }}
            onClick={() => onSelectItem(option)}
          >
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox css={{ marginRight: 10 }} readOnly checked={isSelected} />
              {/*  TODO: fix for ReactNode*/}
              {optionValueDecorator && typeof option.label === 'string'
                ? optionValueDecorator(option.label, option)
                : option.label}
            </div>
          </li>
        )
      })}
    </>
  )
}
