import { Badge, Code, Loader } from '@mantine/core'
import React from 'react'
import { useApiREST } from 'utils/useApiREST'
import { appInfo } from '../../../../../appInfo'

export const AppInfo: React.FC = () => {
  /**
   * TODO: The check for `isSemiosEmployeeOrTester` on the front end and the check that happens on
   * the server for api-info are different. We should refactor at some point, but for now this works fine
   * for employees. (QA Testers would just see a null response for the api-info)
   */
  const response = useApiREST({ url: 'api-info', body: null })

  let apiInfo = null

  if (response.error) {
    apiInfo = <Badge color="red">{'Error'}</Badge>
  } else if (response.loading) {
    apiInfo = <Loader size={'xs'} />
  } else {
    apiInfo = <Code block>{JSON.stringify(response.data, null, 2)}</Code>
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: 16, padding: '0 16px 16px 16px' }}>
      <div>
        <div css={{ fontWeight: 'bold' }}>{'banyan-app'}</div>
        <Code block>{JSON.stringify(appInfo, null, 2)}</Code>
      </div>
      <div>
        <div css={{ fontWeight: 'bold' }}>{'banyan-api'}</div>
        {apiInfo}
      </div>
    </div>
  )
}
