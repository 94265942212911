import { useCallback, useEffect, useState } from 'react'

export const useErrorMessage = (duration = 3000) => {
  const [showErrorMessage, setShowErrorMessage] = useState<string>('')

  const onCloseButtonPress = useCallback(() => {
    setShowErrorMessage('')
  }, [])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (showErrorMessage) {
      timer = setTimeout(() => {
        setShowErrorMessage('')
      }, duration)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [showErrorMessage, duration])

  const triggerErrorMessage = useCallback((errorMessage: string) => {
    setShowErrorMessage(errorMessage)
  }, [])

  return {
    showErrorMessage,
    triggerErrorMessage,
    onCloseButtonPress,
  }
}
