import { OfflineRequestError } from 'utils/errorCodes'
import { ApiRequestQueue, ApiRequestType } from './api/queue'
import type { NodeDeviceRemoveMeta } from './api/serviceCenterNodeDeviceRemove'
import { serviceCenterNodeDeviceRemove } from './api/serviceCenterNodeDeviceRemove'

export async function removeNodeDevice(params: {
  nodeIdentifier: string
  device: NodeDeviceRemoveMeta['device']
}): Promise<void> {
  let unsynced = true

  // Add device to node
  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(
      ApiRequestType.REMOVE_NODE_DEVICE,
      params as NodeDeviceRemoveMeta,
    )

    if (status === 'COMPLETED') unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  if (unsynced) {
    serviceCenterNodeDeviceRemove.callback(params as NodeDeviceRemoveMeta)
  }
}
