import type { OVERLAY_VALUE_META } from 'App/Map/CurrentValuesMap/caches/ImageOverlaysCache/ImageOverlaysCache'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { MapCard } from 'components/MapCard/MapCard'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

export const NDVI = (props: OVERLAY_VALUE_META & { onClose: () => void }) => {
  const timezone = getTimezoneForSelectedPropertyOrRegion()

  const selectedNdviValue = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi?.valueType,
  )

  const imageDateMoment = moment.tz(props.image_date, timezone)
  const previousImageDateMoment = moment.tz(props.previous_image_date, timezone)
  const differenceInDays = imageDateMoment.diff(previousImageDateMoment, 'days')

  return (
    <MapCard
      latLng={props.latLng.toJSON()}
      title={props.blockName}
      onClose={props.onClose}
      footer={null}
      popoverWidth={'max-content'}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 15,
          fontSize: 14,
        }}
      >
        {props.src ? (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 15,
            }}
          >
            {!props.previous_image_date && (
              <div>
                {translate.phrases.banyanApp('Latest Image: {{value}}', {
                  value: translate.dates.format(moment.tz(props.image_date, timezone), 'MMM D, YYYY'),
                })}
              </div>
            )}
            {props.previous_image_date && (
              <div>
                {translate.phrases.banyanApp('{{date1}} to {{date2}} ({{numberOfDays}} days)', {
                  date1: translate.dates.format(moment.tz(previousImageDateMoment, timezone), 'MMM D, YYYY'),
                  date2: translate.dates.format(moment.tz(imageDateMoment, timezone), 'MMM D, YYYY'),
                  numberOfDays: differenceInDays,
                })}
              </div>
            )}
            {!props.previous_image_date && (
              <>
                <div>
                  <translate.Phrases.banyanApp
                    k="Avg NDVI: <strong>{{value}}</strong>"
                    v={{ value: props.ndvi_mean || 0 }}
                  />
                </div>
                <div>
                  <translate.Phrases.banyanApp
                    k="Uniformity: <strong>{{value}}{{unit}}</strong>"
                    v={{ value: props.uniformity || 0, unit: '%' }}
                  />
                </div>
              </>
            )}
            {props.previous_image_date && (
              <>
                <div>
                  <translate.Phrases.banyanApp
                    k="Change in Avg NDVI: <strong>{{value}}</strong>"
                    v={{
                      value: (Number(props.ndvi_mean_change) > 0 ? '+' : '') + props.ndvi_mean_change || 0,
                    }}
                  />
                </div>

                <div>
                  <translate.Phrases.banyanApp
                    k="Change in Uniformity: <strong>{{value}}{{unit}}</strong>"
                    v={{
                      value: (Number(props.uniformity_change) > 0 ? '+' : '') + props.uniformity_change || 0,
                      unit: '%',
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 200,
            }}
          >
            {selectedNdviValue === 'imageryOverlayNDVI_current'
              ? translate.phrases.banyanApp('No imagery available in the past 30 days.')
              : translate.phrases.banyanApp('Unable to show comparison based on available imagery.')}
          </div>
        )}
      </div>
    </MapCard>
  )
}
