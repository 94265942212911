import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { checkAuthorization } from 'utils/checkAuthorization'

export const PropertiesSubtitle = ({
  permission,
  propertyIds,
}: {
  permission: TPermission
  propertyIds: string[] | null
}) => {
  if (!propertyIds || !propertyIds.length) return null

  const { properties } = fieldAssetStore.getState()

  const propertyNamesJoined = propertyIds
    .filter((entity) => checkAuthorization({ permission: permission, entity: Number(entity) }))
    .map((p) => (properties ? properties[Number(p)]?.propertyName : null))
    .join(' | ')

  return (
    <span
      css={{ marginLeft: 20, fontSize: 14, fontWeight: 'normal', color: colors.grey500 }}
    >{`(${propertyNamesJoined})`}</span>
  )
}
