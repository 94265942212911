import React from 'react'
import { colors } from 'settings/colors'
import { fadeInOutStylesMaker } from 'utils/fadeInOutStylesMaker'

interface CollapseButtonProps {
  menuCollapsed: boolean
  onToggleExpand: () => void
  otherStyleProps?: React.CSSProperties
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  menuCollapsed,
  onToggleExpand,
  otherStyleProps,
}) => {
  return (
    <svg
      css={{
        ...fadeInOutStylesMaker(!menuCollapsed, '125ms ease all'),
        transition: '125ms all',
        cursor: 'pointer',
        zIndex: 6,
        position: 'fixed',
        margin: '0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        left: 270,
        top: 'calc(24px + env(safe-area-inset-top))',
        ...otherStyleProps,
      }}
      onClick={onToggleExpand}
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.5"
        cy="16.5"
        r="15.5"
        transform="rotate(-90 16.5 16.5)"
        fill={colors.midnight}
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M24.1667 10.5275L22.6392 9L16.5833 15.0558L10.5275 9L9 10.5275L15.0558 16.5833L9 22.6392L10.5275 24.1667L16.5833 18.1108L22.6392 24.1667L24.1667 22.6392L18.1108 16.5833L24.1667 10.5275Z"
        fill="white"
      />
    </svg>
  )
}
