export const sortNumber = (a: number | null, b: number | null) => {
  if (a === b) {
    // equal items sort equally
    return 0
  } else if (a === null) {
    // nulls sort after anything else
    return -1
  } else if (b === null) {
    return 1
  } else if (a > b) {
    return 1
  } else if (a < b) {
    return -1
  }

  return 0
}
