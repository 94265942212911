import { useInterval } from '@mantine/hooks'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import * as Sentry from '@sentry/react'
import { networkStore } from 'App/ServiceCenter/store/networkStore'
import { translate } from 'i18n/i18n'
import { useCallback, useEffect, useState } from 'react'
import { apiFetch } from 'utils/apiFetch'

export const useCheckForHubMaintenance = () => {
  const [maintenanceMessage, setMaintenanceMessage] = useState<null | string>(null)
  const connected = networkStore.useSelector((s) => s.connectionStatus.connected)

  const checkForMaintenance = useCallback(async () => {
    let response: routes.HubMaintenance.Response

    // Check #1: Is the API up?

    try {
      response = await apiFetch<routes.HubMaintenance.Request, routes.HubMaintenance.Response>({
        url: routes.HubMaintenance.path,
        body: null,
      })
    } catch (error) {
      Sentry.captureException(error)

      setMaintenanceMessage(
        translate.phrases.banyanApp(
          'Our system is undergoing maintenance. Some features may be temporarily unavailable.',
        ),
      )

      return
    }

    // Check #2: Are we doing maintenance?

    if (response.hubMaintenance === 'true') {
      setMaintenanceMessage(
        translate.phrases.banyanApp(
          'Our system is undergoing maintenance. Some features may be temporarily unavailable.',
        ),
      )

      return
    }

    // If we made it this far, things are ok.

    setMaintenanceMessage(null)
  }, [])

  const checkForMaintenanceInterval = useInterval(checkForMaintenance, 180000) // 3 minutes

  const startCheckingForMaintenance = useCallback(() => {
    checkForMaintenance()

    checkForMaintenanceInterval.start()
  }, [])

  const stopCheckingForMaintenance = useCallback(() => {
    checkForMaintenanceInterval.stop()

    setMaintenanceMessage(null)
  }, [])

  useEffect(() => {
    // Only check for maintenance if the user is connected to the internet
    if (connected) {
      startCheckingForMaintenance()
    } else {
      stopCheckingForMaintenance()
    }

    return () => {
      stopCheckingForMaintenance()
    }
  }, [connected])

  return maintenanceMessage
}
