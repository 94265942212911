import { appInfo } from './appInfo'

if (appInfo.useHubspot) {
  const addCssToHideHubspotChatWidget = () => {
    const styleElement = document.createElement('style')
    const css = 'div#hubspot-messages-iframe-container { display: none !important; }'

    styleElement.append(document.createTextNode(css))

    document.head.append(styleElement)
  }

  const addHubspotTrackingCode = () => {
    /**
     * The code below is just adding a script like this to our document body:
     * <!-- Start of HubSpot Embed Code -->
     * <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2522436.js"></script>
     * <!-- End of HubSpot Embed Code -->
     */

    const scriptElement = document.createElement('script')

    scriptElement.type = 'text/javascript'

    scriptElement.id = 'hs-script-loader'

    scriptElement.async = true

    scriptElement.defer = true

    scriptElement.src = '//js.hs-scripts.com/2522436.js'

    document.body.append(scriptElement)
  }

  /**
   * When adding the hubspot tracking code, it brings along their chat widget. It adds a little button in the
   * bottom right corner of the screen that opens a live chat widget. We don't want it, so we'll just hide it
   * with some css.
   * There's probably some way to disable the chat widget for certain domains or URL in the hubspot admin UI...
   * but this seems easier to just hide it ourselves here in source code for our deployment environments.
   */

  addCssToHideHubspotChatWidget()

  addHubspotTrackingCode()
}
