import { Divider } from '@mantine/core'
import { isNdviValueType } from 'App/Map/CurrentValuesMap/caches/ImageOverlaysCache/_utils/isNdviValueType'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { NdviHeatmapRow } from '../../HeatmapControlsRow/NdviHeatmapRow/NdviHeatmapRow'
import { NDVIDropdown } from '../../ValueRow/NDVIDropdown/NDVIDropdown'
import { NdviSwitch } from './NdviSwitch/NdviSwitch'

export const NdviMapControls = () => {
  const showNdvi = selectedValueGroupsStore.useSelector((s) => !!s?.selectedValueGroups?.ndvi)

  const showHeatmapRow = mapControlsStore.useSelector((s) => {
    const ndviValueType = s.mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi?.valueType

    return isNdviValueType(ndviValueType)
  })

  return (
    <div
      css={{
        marginTop: 12,
      }}
    >
      <Divider />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
          color: HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD ? colors.midnight : colors.white,
          marginTop: 12,
          width: '100%',
        }}
      >
        <NdviSwitch />
        {showNdvi && (
          <>
            <NDVIDropdown />
            {showHeatmapRow && <NdviHeatmapRow />}
          </>
        )}
      </div>
    </div>
  )
}
