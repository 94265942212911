import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { FC } from 'react'
import { colors } from 'settings/colors'
import type { TSettingItemNarrowProps } from '../types'

export const SettingItem: FC<TSettingItemNarrowProps> = ({ setCurrentTab, tabKey, label, isSubItem }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.grey200}`,
        padding: '18px 0',
        cursor: 'pointer',
      }}
      onClick={() => setCurrentTab(tabKey)}
    >
      <div
        css={{
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          paddingLeft: isSubItem ? 20 : 0,
        }}
      >
        {label}
      </div>
      <div>
        <FontAwesomeIcon icon={faAngleRight} size="lg" color={colors.grey800} />
      </div>
    </div>
  )
}
