import type { VV } from '@semios/app-platform-value-type-definitions'
import type { ESprayRecommendation } from 'App/Map/types'
import { getSprayConditionsTitleAndColor } from 'App/Map/_utils/getSprayConditionsTitleAndColor'
import type { XrangePointOptionsObject } from 'highcharts'
import { isNil } from 'lodash'
import moment from 'moment-timezone'

type TTimeseries =
  VV.DomainTypes.SprayConditions.TValuesReturnWithMetaIgnoringKeying['blocks']['sprayConditions'][number]['timeseries']

const statusMapper = (status: ESprayRecommendation | null) => {
  return {
    ...getSprayConditionsTitleAndColor(status),
    y: 0,
    pointWidth: status === 'IDEAL' ? 14 : 24,
  }
}

export const makeIntervals = ({
  interval,
  timeseries,
  timezone,
}: {
  interval: 'day' | 'hour'
  timeseries?: TTimeseries
  timezone: string
}): (XrangePointOptionsObject & { value: ESprayRecommendation | null })[] => {
  if (isNil(timeseries) || !timeseries.length || !timeseries[0]) return []

  const intervals: (XrangePointOptionsObject & { value: ESprayRecommendation | null; pointWidth: number })[] =
    [
      {
        value: timeseries[0].value,
        x: +new Date(timeseries[0].timestamp),
        x2: +new Date(timeseries[0].timestamp),
        ...statusMapper(timeseries[0].value),
      },
    ]

  timeseries.forEach((ts, i, originalArray) => {
    const lastItem = intervals[intervals.length - 1]
    const onTheLastIteration = i === originalArray.length - 1

    if (ts.value === lastItem.value) {
      if (onTheLastIteration) {
        lastItem.x2 = +moment.tz(ts.timestamp, timezone).add(1, interval).subtract(1, 'millisecond')
      }
    } else {
      lastItem.x2 = +new Date(+new Date(ts.timestamp) - 1)

      const newItem: typeof intervals[number] = {
        value: ts.value,
        x: +new Date(ts.timestamp),
        x2: +new Date(ts.timestamp),
        ...statusMapper(ts.value),
      }

      if (onTheLastIteration) {
        newItem.x2 = +moment.tz(ts.timestamp, timezone).add(1, interval).subtract(1, 'millisecond')
      }

      intervals.push(newItem)
    }
  })

  return intervals.sort((a, b) => {
    // to make the lines look a bit tidier
    const aIdeal = a.value?.includes('IDEAL')
    const bIdeal = b.value?.includes('IDEAL')

    if (aIdeal && !bIdeal) return -1

    if (!aIdeal && bIdeal) return 1

    return 0
  })
}
