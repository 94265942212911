import type { SoilValueType } from '../soil'

export const checkIfSoilChartHasData = (
  chartData: { timeseries: { value?: SoilValueType }[] }[] | undefined,
) => {
  return chartData?.some(({ timeseries }) => {
    return (
      timeseries.filter(({ value }: { value?: SoilValueType }) => {
        if (value === null) return false

        if (typeof value === 'number') {
          return value > 0
        } else if (typeof value === 'object') {
          return Object.values(value ?? {}).some((v) => v && v > 0)
        }

        // we shouldn't make it here, but let's add a negative case just in case
        return false
      }).length > 0
    )
  })
}
