import type { Dispatch, SetStateAction } from 'react'
import { SettingsSearchNotFound } from '../../../components/SettingsSearchNotFound/SettingsSearchNotFound'
import type { ModifiedZone } from '../EmitterConfiguration'
import { useZonesFilter } from '../hooks/useZonesFilter'
import type { TEmitterConfiguration } from '../types'
import { EmitterConfigurationSection } from './EmitterConfigurationSection/EmitterConfigurationSection'

type TEmitterConfigurationList = {
  modifiedZones: ModifiedZone[]
  setModifiedZones: Dispatch<SetStateAction<ModifiedZone[]>>
  emitterConfigurations: TEmitterConfiguration
}

export const EmitterConfigurationList = ({
  modifiedZones,
  setModifiedZones,
  emitterConfigurations,
}: TEmitterConfigurationList) => {
  const { filterComponent, filteredAndSortedList, isSearchMatch } = useZonesFilter({
    initialProperties: Object.values(emitterConfigurations),
  })

  return (
    <div css={{ paddingBottom: 62 }}>
      <div css={{ padding: '5px 20px 20px 20px' }}>{filterComponent}</div>
      {isSearchMatch ? (
        filteredAndSortedList.map((item) => {
          const { propertyId, propertyName, irrigationZones } = item

          return (
            <EmitterConfigurationSection
              key={propertyId}
              propertyName={propertyName}
              propertyId={propertyId}
              irrigationZones={irrigationZones}
              modifiedZones={modifiedZones}
              setModifiedZones={setModifiedZones}
            />
          )
        })
      ) : (
        <SettingsSearchNotFound />
      )}
    </div>
  )
}
