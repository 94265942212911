import { Button, LoadingOverlay, Popover } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { Authorization } from 'components/Authorization/Authorization'
import { IconEdit } from 'components/icons/IconEdit'
import { IconTrashCan } from 'components/icons/IconTrashCan'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { apiFetch } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { showNotification } from 'utils/showNotification'
import { natComparator } from 'utils/sort-by-key'
import { useSearchInput } from 'utils/useSearchInput'
import type { TAdminPermission, TRole } from '../../utils/useAdminEntites'
import { ActionButton } from '../ActionButton/ActionButton'
import { ViewEditRoleModal } from './ViewEditRoleModal/ViewEditRoleModal'

export const Roles = ({
  roles,
  permissions,
  loading,
  onRefresh,
}: {
  roles: TRole[]
  permissions: TAdminPermission[]
  loading: boolean
  onRefresh: () => void
}) => {
  const PAGE_SIZE = 25
  const [roleFormOpen, setRoleFormOpen] = useState(false)
  const [selectedRole, setSelectedRole] = useState<TRole | null>(null)
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)
  const [paginationResetKey, setPaginationResetKey] = useState(`${Math.random()}`)
  const open = (id: string) => setHoveredItem(id)
  const close = () => setHoveredItem(null)

  const userFirstNameLastInitialReturner = (user: TRole['createdBy'] | TRole['updatedBy']) => {
    let returner = translate.phrases.banyanApp('Unknown')

    try {
      const { firstName, lastName } = user || {}

      if (firstName || lastName) returner = `${firstName || ''} ${lastName?.[0] || ''}`
    } catch (err) {}

    return returner
  }

  const columns = [
    {
      title: translate.phrases.banyanApp('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: translate.phrases.banyanApp('Role Type'),
      dataIndex: 'roleType',
      key: 'roleType',
      width: 120,
    },
    {
      title: translate.phrases.banyanApp('Permissions'),
      dataIndex: 'permissions',
      key: 'permissions',
      render: (value: string[], record: TRole) => {
        if (isEmpty(value)) return 0

        const isOpen = hoveredItem === String(record.id)

        return (
          <Popover withArrow shadow="md" radius={5} opened={isOpen} position="right">
            <Popover.Target>
              <div onMouseEnter={() => open(String(record.id))} onMouseLeave={close} css={{ marginLeft: 5 }}>
                <span css={{ marginLeft: 5, textDecoration: 'underline dotted' }}>{value.length}</span>
              </div>
            </Popover.Target>
            <Popover.Dropdown
              onMouseEnter={() => open(String(record.id))}
              onMouseLeave={close}
              css={{ pointerEvents: 'auto', color: colors.midnight, fontSize: 14, fontWeight: '400' }}
            >
              <div css={{ display: 'flex', flexDirection: 'column', overflow: 'auto', maxHeight: '50vh' }}>
                {value.map((permission: string, index: number) => (
                  <div key={`${record.id}-${record.name}-${index}`}>{permission}</div>
                ))}
              </div>
            </Popover.Dropdown>
          </Popover>
        )
      },
      width: 120,
    },
    {
      title: translate.phrases.banyanApp('Last Modified At'),
      dataIndex: 'createdAt',
      key: 'lastModifiedAt',
      render: (value: string, record: TRole) =>
        moment
          .tz(record.updatedAt || record.createdAt, 'America/Los_Angeles')
          .format('ddd, MMM D YYYY, h:mm a'),
      sorter: (a: TRole, b: TRole) =>
        +new Date(b.updatedAt || b.createdAt) - +new Date(a.updatedAt || a.createdAt),
      width: 195,
    },
    {
      title: translate.phrases.banyanApp('Last Modified By'),
      dataIndex: 'createdBy',
      key: 'lastModifiedBy',
      render: (value: string, record: TRole) =>
        userFirstNameLastInitialReturner(record.updatedAt ? record.updatedBy : record.createdBy),
      sorter: (a: TRole, b: TRole) => {
        let aComparer = userFirstNameLastInitialReturner(a.updatedAt ? a.updatedBy : a.createdBy)
        let bComparer = userFirstNameLastInitialReturner(b.updatedAt ? b.updatedBy : b.createdBy)

        return natComparator(aComparer, bComparer)
      },
      width: 155,
    },
    {
      title: translate.phrases.banyanApp('Actions'),
      key: 'actions',
      dataIndex: 'actions',
      width: 80,
      render: (_: string, record: TRole) => {
        const userHasEditPermission = checkAuthorization({
          permission: 'ADMIN_SET_ROLE_DEFINITIONS',
          entity: '*',
        })

        if (!userHasEditPermission) return null

        return (
          <ActionButton
            menuItems={[
              {
                icon: <IconEdit />,
                children: (
                  <span
                    onClick={() => {
                      setSelectedRole(record)

                      setRoleFormOpen(true)
                    }}
                  >
                    {translate.phrases.banyanApp('View/Edit Role')}
                  </span>
                ),
              },
              {
                icon: <IconTrashCan />,
                children: (
                  <span
                    onClick={() => {
                      openConfirmModal({
                        styles: {
                          root: {
                            padding: 10,
                          },
                        },
                        title: translate.phrases.banyanApp(
                          'Are you sure you want to delete the role {{recordName}}?',
                          { recordName: record.name },
                        ),
                        labels: {
                          confirm: translate.phrases.banyanApp('Delete'),
                          cancel: translate.phrases.banyanApp('Cancel'),
                        },
                        onCancel: () => null,
                        onConfirm: async () => {
                          await apiFetch<routes.AdminDeleteRole.Request, routes.AdminDeleteRole.Response>({
                            url: routes.AdminDeleteRole.path,
                            body: {
                              roleId: record.id,
                            },
                          })
                            .then((result) => {
                              if (result) {
                                if (result.errors.length) {
                                  showNotification({
                                    message: translate.phrases.validation('{{label}} could not be deleted', {
                                      label: translate.phrases.banyanApp('Role'),
                                    }),
                                    type: 'error',
                                  })
                                } else {
                                  showNotification({
                                    message: translate.phrases.banyanApp('Role deleted'),
                                    type: 'success',
                                  })
                                }
                              }

                              return result
                            })
                            .catch(() => {
                              showNotification({
                                message: translate.phrases.validation('{{label}} could not be deleted', {
                                  label: translate.phrases.banyanApp('Role'),
                                }),
                                type: 'error',
                              })
                            })
                            .finally(() => {
                              onRefresh()
                            })
                        },
                      })
                    }}
                  >
                    {translate.phrases.banyanApp('Delete')}
                  </span>
                ),
              },
            ]}
          />
        )
      },
    },
  ]

  const { SearchInput, searchString } = useSearchInput({
    placeholder: translate.phrases.banyanApp('Search for a role'),
  })

  useEffect(() => {
    setPaginationResetKey(`${Math.random()}`)
  }, [searchString])

  const displayedData = arrayOfObjectsSearch(roles, searchString, ['name'])

  return (
    <>
      <LoadingOverlay visible={loading} />
      {!loading && roles.length && (
        <>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <div css={{ margin: 10 }}>{SearchInput}</div>
            </div>

            <Authorization requires={{ permission: 'ADMIN_SET_ROLE_DEFINITIONS', entity: '*' }}>
              <Button
                variant="filled"
                onClick={() => {
                  setSelectedRole(null)

                  setRoleFormOpen(true)
                }}
              >
                <span>{translate.phrases.banyanApp('Create New Role')}</span>
              </Button>
            </Authorization>
          </div>

          <SummaryTable
            autoSortColumns={['name', 'roleType', 'permissions', 'createdAt', 'createdBy']}
            columns={columns}
            data={displayedData}
            pageSize={PAGE_SIZE}
            paginationResetKey={paginationResetKey}
          />
        </>
      )}
      <ViewEditRoleModal
        permissions={permissions}
        role={selectedRole}
        opened={roleFormOpen}
        onClose={(hasChanges) => {
          setRoleFormOpen(false)

          if (hasChanges) {
            onRefresh()
          }
        }}
      />
    </>
  )
}
