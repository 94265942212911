import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { sortByKey } from '@semios/app-platform-common'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { colors } from 'settings/colors'
import { RadioWrapper } from '../../../Alerts/ModalDrawerCreateOrEditAlert/AlertForm/Coverage/RadioWrapper/RadioWrapper'
import { ErrorTextWrapper } from '../../components/ErrorTextWrapper'

const propertyOptionGenerator = (properties: TProperty[]) => {
  const propertiesData = properties
    .map((property: TProperty) => ({
      label: property.propertyName,
      value: property.propertyId.toString(),
      id: `${property.propertyId}-PROPERTY`,
    }))
    .sort(sortByKey('label'))

  return [...propertiesData]
}

export const ReportCoverage = ({
  selectedReportProperties,
  setSelectedReportProperties,
  properties,
  coverageType,
  setCoverageType,
  setCoverageIsValid,
  triedToSubmit,
}: {
  setSelectedReportProperties: Dispatch<SetStateAction<TOptions[]>>
  properties: TProperty[]
  selectedReportProperties: TOptions[]
  coverageType: string
  setCoverageType: Dispatch<SetStateAction<string>>
  setCoverageIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  const typeIsAll = coverageType === 'all'
  const typeIsProperties = coverageType === 'properties'

  let helpText = ''
  let validateStatusError = false

  if (coverageType === 'properties' && selectedReportProperties.length === 0 && triedToSubmit) {
    helpText = translate.phrases.banyanApp('At least one property must be selected')

    validateStatusError = true
  }

  useEffect(() => {
    setCoverageIsValid(!validateStatusError)
  }, [coverageType, selectedReportProperties, validateStatusError])

  const sortedSelectedProperties = selectedReportProperties.sort((a, b) => {
    const valueA = typeof a.label === 'string' ? a.label : String(a.label)
    const valueB = typeof b.label === 'string' ? b.label : String(b.label)

    return valueA.localeCompare(valueB)
  })

  return (
    <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
      <h4>{translate.phrases.banyanApp('Coverage')}</h4>
      <RadioWrapper
        checked={typeIsAll}
        onClick={() => {
          setCoverageType('all')

          setSelectedReportProperties([])
        }}
        title={translate.phrases.banyanApp('Traps Across All Properties')}
      />
      <RadioWrapper
        checked={typeIsProperties}
        onClick={() => setCoverageType('properties')}
        title={translate.phrases.banyanApp('Specific Properties')}
      >
        <MultiSelect
          data={propertyOptionGenerator(properties)}
          selectedData={sortedSelectedProperties}
          placeholder={translate.phrases.banyanApp('Select Properties')}
          onChange={(selectedValues: TOptions[]) => {
            setSelectedReportProperties(selectedValues.map((option) => option))
          }}
          disabled={!typeIsProperties}
        />
      </RadioWrapper>
      {validateStatusError && <ErrorTextWrapper>{helpText}</ErrorTextWrapper>}
    </div>
  )
}
