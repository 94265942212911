import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconUser = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 22 22')}>
      <path
        d="M11 11C14.025 11 16.5 8.525 16.5 5.5C16.5 2.475 14.025 0 11 0C7.975 0 5.5 2.475 5.5 5.5C5.5 8.525 7.975 11 11 11ZM22 20.4325C22 18.205 20.68 16.225 18.645 15.345C16.3075 14.3275 13.7225 13.75 11 13.75C8.2775 13.75 5.6925 14.3275 3.355 15.345C1.32 16.225 0 18.205 0 20.4325V22H22V20.4325Z"
        fill="currentColor"
      />
    </svg>
  )
}
