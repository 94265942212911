import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconSummary = () => (
  <svg {...makeBaseSvgIconProps('0 0 27 19')}>
    <path
      d="M1 1H26V16.811C26 17.0577 25.8903 17.2942 25.6949 17.4687C25.4996 17.6431 25.2346 17.7411 24.9583 17.7411H2.04167C1.7654 17.7411 1.50045 17.6431 1.3051 17.4687C1.10975 17.2942 1 17.0577 1 16.811V1Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 6.58057H26" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 12.1606H26" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.29199 6.58057V17.7413" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
