import { LoadingOverlay, Paper, Select, Stack, Text, TextInput } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import { appMetaDataMaker } from 'App/Authentication/_utils/appMetaMaker'
import { useForceEnglish } from 'App/SelfRegistration/utils/useForceEnglish'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { IconRightArrowCircle } from 'components/icons/IconRightArrowCircle'
import { SemiosLogoColor } from 'components/SemiosLogo/SemiosLogoColor'
import { globalLanguageStore, translate } from 'i18n/i18n'
import React, { useState } from 'react'
import { colors } from 'settings/colors'
import { apiFetch } from 'utils/apiFetch'
import { setTokenAndPushHome } from 'utils/setTokenAndPushHome'
import { useKeyboardVisible } from 'utils/useKeyboardVisible'
import { z } from 'zod'
import { CountryLabel } from './components/CountryLabel/CountryLabel'
import { countryDictionary } from './components/CountryLabel/utils/countryDictionary'

export const SelfRegistration = () => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [triedToSubmit, setTriedToSubmit] = useState(false)
  const path = window.location.pathname
  const selfRegistrationHash = path.match(/\/self-register\/(.*)/)?.[1] ?? ''
  const { isKeyboardVisible } = useKeyboardVisible()
  const keyboardAvoiding = isKeyboardVisible ? { marginTop: '-12vh' } : {}
  const globalLanguage = globalLanguageStore.useSelector((lng) => lng)
  const { isLoadingEnglish } = useForceEnglish() // TODO: Remove once localization is ready for landing-page-app and hub self registration flow

  const form = useForm({
    validate: zodResolver(
      routes.SelfRegistrationSubmission.requestSchema(translate.phrases.validation)
        .omit({ selfRegistrationHash: true, appMeta: true })
        .extend({ confirmPassword: z.string().min(1) })
        .refine((data) => data.password === data.confirmPassword, {
          message: translate.phrases.banyanApp('Passwords do not match'),
          path: ['confirmPassword'],
        }),
    ),
    validateInputOnBlur: true,
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      language: globalLanguage as routes.SelfRegistrationSubmission.Request['language'],
      country: '' as routes.SelfRegistrationSubmission.Request['country'],
      unitSystem: '' as routes.SelfRegistrationSubmission.Request['unitSystem'],
      optInForMarketingEmails: false,
    } as Omit<routes.SelfRegistrationSubmission.Request, 'selfRegistrationHash' | 'appMeta'> & {
      confirmPassword: string
    },
  })

  if (isLoadingEnglish) {
    return <LoadingOverlay visible={true} />
  }

  return (
    <div
      css={{
        padding:
          'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        ...keyboardAvoiding,
      }}
    >
      <div css={{ paddingLeft: 30, marginTop: 33, height: 91 }}>
        <SemiosLogoColor width={272} />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '65vh',
        }}
      >
        <LoadingOverlay visible={submitLoading} />

        <Paper radius="md" p="xl" css={{ width: 420 }}>
          <Text size={18} weight={700}>
            {translate.phrases.banyanApp('We need a few more details to get you started')}
          </Text>
          <form
            css={{ marginTop: 25 }}
            onSubmit={form.onSubmit((values) => {
              const { confirmPassword, ...restOfThingsToSubmitFromTheForm } = values

              setSubmitLoading(true)

              setErrors([])

              apiFetch<routes.SelfRegistrationSubmission.Request, routes.SelfRegistrationSubmission.Response>(
                {
                  url: routes.SelfRegistrationSubmission.path,
                  body: {
                    ...restOfThingsToSubmitFromTheForm,
                    selfRegistrationHash,
                    appMeta: appMetaDataMaker(),
                  },
                },
              )
                .then((res) => {
                  if (!!res.errors.length) {
                    setErrors(
                      res.errors.map(({ messageTranslationString }) =>
                        translate.phrases.placeholder(messageTranslationString),
                      ),
                    )
                  } else if (!res.data || !res.data.token) {
                    setErrors([translate.phrases.banyanApp('Sorry… Something went wrong.')])
                  } else {
                    setTokenAndPushHome(res.data.token)
                  }
                })
                .catch(() => {
                  setErrors([translate.phrases.banyanApp('Sorry… Something went wrong.')])
                })
                .finally(() => {
                  setSubmitLoading(false)
                })
            })}
          >
            <Stack>
              <TextInput
                label={translate.phrases.banyanApp('Email Address')}
                placeholder="your@email.com"
                {...form.getInputProps('email')}
              />
              <TextInput
                label={translate.phrases.validation('Password')}
                type="password"
                {...form.getInputProps('password')}
              />
              <TextInput
                label={translate.phrases.banyanApp('Confirm Password')}
                type="password"
                {...form.getInputProps('confirmPassword')}
              />
              <TextInput
                label={translate.phrases.validation('First Name')}
                {...form.getInputProps('firstName')}
              />
              <TextInput
                label={translate.phrases.validation('Last Name')}
                {...form.getInputProps('lastName')}
              />
              <Select
                data={(
                  [
                    'AU',
                    'CA',
                    'DE',
                    'ES',
                    'FR',
                    'IT',
                    'NL',
                    'US',
                    'Other',
                  ] as routes.SelfRegistrationSubmission.Request['country'][]
                ).map((country) => ({
                  label:
                    country === 'Other'
                      ? translate.phrases.banyanApp('Other')
                      : countryDictionary[country].name,
                  value: country,
                }))}
                itemComponent={CountryLabel}
                label={translate.phrases.validation('Country')}
                {...form.getInputProps('country')}
              />
              <Select
                data={['IMPERIAL', 'METRIC'].map((unitSystem) => ({
                  label: unitSystem, //unitSystemLabels[unitSystem],
                  value: unitSystem,
                }))}
                label={translate.phrases.validation('Unit System')}
                {...form.getInputProps('unitSystem')}
              />
              <Checkbox
                label={translate.phrases.banyanApp(
                  'Yes, I would like to receive product updates, tips on how to use the app, exclusive offers, and promotional emails. I understand that I can unsubscribe at any time.',
                )}
                {...form.getInputProps('optInForMarketingEmails')}
              />
              {errors.length ? (
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 12,
                    color: colors.red,
                    fontStyle: 'italic',
                    fontSize: 14,
                  }}
                >
                  {errors.map((e, i) => (
                    <div key={i}>{e}</div>
                  ))}
                </div>
              ) : null}
              <Button
                variant="primary"
                type="submit"
                disabled={triedToSubmit && !isEmpty(form.errors)}
                onClick={() => {
                  form.validate()

                  setTriedToSubmit(true)
                }}
                loading={submitLoading}
              >
                <div
                  css={{
                    gap: 12,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {translate.phrases.banyanApp('Complete Registration')}
                  <div css={{ fontSize: 22 }}>
                    <IconRightArrowCircle />
                  </div>
                </div>
              </Button>
            </Stack>
          </form>
        </Paper>
      </div>
    </div>
  )
}
