import React, { useState } from 'react'
import { MapContext } from './MapContext'

interface Props {
  children: React.ReactNode
}

export const MapProvider: React.FC<Props> = ({ children }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [loadingData, setLoadingData] = useState(false)

  return (
    <MapContext.Provider
      value={{
        setMap,
        map,
        loadingData,
        setLoadingData,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}
