import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Group } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { getErrorStyle } from 'App/Map/UserSettingsMenu/Shared/Alerts/_utils/getErrorStyle'
import { ErrorText } from 'components/ErrorText/ErrorText'
import { IconClose } from 'components/icons/IconClose'
import { NumberInput } from 'components/NumberInput/NumberInput'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import type { TKcRangeRow } from '../../NewOrEditKcModelModal'
import { getIsDateExcluded } from './_utils/getIsDateExcluded'

export const KcRange = ({
  index,
  dates,
  setDates,
  setKcValue,
  kcValue,
  kcRanges,
  disabled,
  removeRow,
  timezone,
}: {
  index: number
  dates: [Date | null, Date | null]
  setDates: (dates: [Date | null, Date | null]) => void
  setKcValue: (kcValue: number) => void
  kcValue?: number
  kcRanges: TKcRangeRow[]
  disabled: boolean
  removeRow: () => void
  timezone: string
}) => {
  const [isValid, setIsValid] = useState(true)

  const validateInput = (value: number | '') => {
    setIsValid(typeof value === 'number' && value >= 0)
  }

  const errorStyle = getErrorStyle()

  return (
    <>
      <Group css={{ marginBottom: 20 }} align={'center'}>
        <div css={{ minWidth: 240, maxWidth: 600 }}>
          {index === 0 && (
            <div css={{ height: 20, marginBottom: 5 }}>{translate.phrases.banyanApp('Date Range')}</div>
          )}
          <DatePickerInput
            disabled={disabled}
            rightSection={<FontAwesomeIcon css={{ color: colors.grey800, fontSize: 16 }} icon={faCalendar} />}
            placeholder={translate.phrases.banyanApp('Select Date Range')}
            type="range"
            value={dates}
            onChange={setDates}
            mx="auto"
            miw={240}
            excludeDate={(date) => getIsDateExcluded({ date, timezone, index, dateRanges: kcRanges })}
            valueFormat={translate.dates.getMomentFormat('MMM D, YYYY')}
            allowSingleDateInRange
          />
        </div>
        <div>
          {index === 0 && (
            <div css={{ height: 20, marginBottom: 5 }}>{translate.phrases.unitSymbols('Kc')}</div>
          )}
          <NumberInput
            onChange={(value) => {
              setKcValue(value as number)

              validateInput(value)
            }}
            disabled={disabled}
            precision={4}
            maw={100}
            removeTrailingZeros
            step={0.0001}
            value={kcValue}
            classNames={{ input: !isValid ? errorStyle : undefined }}
          />
        </div>
        {!isValid && (
          <div css={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
            <ErrorText>{translate.phrases.banyanApp('KC value must be greater or equal to 0')}</ErrorText>
          </div>
        )}
        {kcRanges.length > 1 ? (
          <div>
            {index === 0 && <div css={{ height: 20, marginBottom: 5 }} />}
            <div
              onClick={removeRow}
              css={{
                alignItems: 'center',
                color: colors.red,
                cursor: 'pointer',
                display: 'flex',
                gap: 10,
                marginTop: 5,
              }}
            >
              <IconClose />
              <div>{translate.phrases.banyanApp('Remove')}</div>
            </div>
          </div>
        ) : (
          // width of <CloseIcon /> is 16px
          <div css={{ width: 16 }}></div>
        )}
      </Group>
    </>
  )
}
