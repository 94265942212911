import type { NumberInputProps as MantineNumberInputProps } from '@mantine/core'
import type { ReactNode } from 'react'
import { isValidElement } from 'react'
import { SpacingPopover } from './SpacingPopover'

type TEmitterConfigurationInputTemplate = Omit<MantineNumberInputProps, 'type'> & {
  label?: string
  unit?: string | ReactNode
  showSpacingPopover?: boolean
  children: ReactNode
}

export const EmitterConfigurationInputTemplate = ({
  label,
  unit,
  showSpacingPopover = false,
  children,
}: TEmitterConfigurationInputTemplate) => {
  return (
    <div>
      <div css={{ fontWeight: 500, fontSize: 16, marginBottom: 5, display: 'flex', flexDirection: 'row' }}>
        {label && label}
        {showSpacingPopover && <SpacingPopover />}
      </div>
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
        {children}
        {typeof unit === 'string' && <div css={{ fontWeight: 400, fontSize: 16 }}>{unit}</div>}
        {isValidElement(unit) && unit}
      </div>
    </div>
  )
}
