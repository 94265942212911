import { Notification, Text } from '@mantine/core'
import type { FC } from 'react'
import { colors } from 'settings/colors'
import { SuccessIcon } from '../SuccessIcon'

type SuccessToastProps = {
  showSuccessNotification: boolean
  onSuccessClose: () => void
  successText: string
}

export const SuccessToast: FC<SuccessToastProps> = ({
  showSuccessNotification,
  successText,
  onSuccessClose,
}) => {
  return showSuccessNotification ? (
    <div
      css={{
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        marginBottom: 20,
      }}
    >
      <Notification
        color="green"
        css={{ backgroundColor: colors.grey50, maxWidth: 480, marginLeft: 20, marginRight: 20 }}
        radius="md"
        icon={
          <div onClick={onSuccessClose}>
            <SuccessIcon />
          </div>
        }
        onClose={onSuccessClose}
        withCloseButton={false}
      >
        <Text size={14} color={colors.midnight}>
          {successText}
        </Text>
      </Notification>
    </div>
  ) : null
}
