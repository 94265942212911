import { LoadingOverlay, Switch } from '@mantine/core'
import { getZoomThresholds, ZOOM_VISIBILITY } from 'App/Map/CurrentValuesMap/_utils/zoomVisibility'
import { translate } from 'i18n/i18n'
import { useContext } from 'react'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { mapStore } from 'stores/mapStore'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'
import { MapContext } from '../../MapContext/MapContext'
import { EquipmentStatusFilter } from '../EquipmentStatusFilter/EquipmentStatusFilter'
import { HeatmapControlsRow } from '../HeatmapControlsRow/HeatmapControlsRow'
import { AggregateSwitch } from './AggregateSwitch/AggregateSwitch'
import { includeNdviSwitch } from './includeNdviSwitch'
import { NdviMapControls } from './NdviMapControls/NdviMapControls'

export const includeAggregateSwitch: Record<TValueGroup, boolean> = {
  air_temperature: true,
  almond_bloom: false,
  almond_hull_split: true,
  alternaria: false,
  atmospheric_pressure: true,
  bee_hours: false,
  chill: true,
  conditions: false,
  degree_days_insect_id_1: true,
  degree_days_insect_id_10: true,
  degree_days_insect_id_11: true,
  degree_days_insect_id_12: true,
  degree_days_insect_id_13: true,
  degree_days_insect_id_14: true,
  degree_days_insect_id_15: true,
  degree_days_insect_id_16: true,
  degree_days_insect_id_17: true,
  degree_days_insect_id_18: true,
  degree_days_insect_id_19: true,
  degree_days_insect_id_2: true,
  degree_days_insect_id_20: true,
  degree_days_insect_id_23: true,
  degree_days_insect_id_24: true,
  degree_days_insect_id_25: true,
  degree_days_insect_id_26: true,
  degree_days_insect_id_27: true,
  degree_days_insect_id_28: true,
  degree_days_insect_id_29: true,
  degree_days_insect_id_30: true,
  degree_days_insect_id_3: true,
  degree_days_insect_id_4: true,
  degree_days_insect_id_6: true,
  degree_days_insect_id_7: true,
  degree_days_insect_id_8: true,
  degree_days_insect_id_9: true,
  dew_point: true,
  equipment_status: false,
  evapotranspiration: true,
  fruit_growth: false,
  plant_stress: false,
  humidity: true,
  irrigation_activity: false,
  larval_trend_insect_id_1: false,
  larval_trend_insect_id_11: false,
  larval_trend_insect_id_20: false,
  larval_trend_insect_id_3: false,
  larval_trend_insect_id_6: false,
  larval_trend_insect_id_7: false,
  larval_trend_insect_id_9: false,
  leaf_wetness: false,
  precipitation: false,
  soil: false,
  spray_conditions: false,
  trap_catches_insect_id_1: true,
  trap_catches_insect_id_10: true,
  trap_catches_insect_id_11: true,
  trap_catches_insect_id_12: true,
  trap_catches_insect_id_15: true,
  trap_catches_insect_id_16: true,
  trap_catches_insect_id_17: true,
  trap_catches_insect_id_19: true,
  trap_catches_insect_id_2: true,
  trap_catches_insect_id_20: true,
  trap_catches_insect_id_25: true,
  trap_catches_insect_id_27: true,
  trap_catches_insect_id_28: true,
  trap_catches_insect_id_29: true,
  trap_catches_insect_id_30: true,
  trap_catches_insect_id_6: true,
  trap_catches_insect_id_7: true,
  trap_catches_insect_id_9: true,
  walnut_blight: true,
  wet_bulb: true,
  wind: false,
  wind_machine: false,
  ndvi: false,
}

const replaceAggregateToggleWithHeatmapToggle: { [key in TValueGroup]?: boolean } = {
  almond_hull_split: true,
  evapotranspiration: true,
}

const excludeMapLabelSwitch: { [key in TValueGroup]?: boolean } = {
  equipment_status: true,
}

export const MapControlItems = () => {
  const { loadingData } = useContext(MapContext)
  const primaryValueGroup = usePrimaryValueGroup()
  const showMapBadgeNames = mapControlsStore.useSelector((s) => s.showMapBadgeNames)

  let filterRow: React.ReactNode = <HeatmapControlsRow />

  if (primaryValueGroup === 'equipment_status') filterRow = <EquipmentStatusFilter />

  const showSwitchRow =
    !excludeMapLabelSwitch[primaryValueGroup as TValueGroup] ||
    includeAggregateSwitch[primaryValueGroup as TValueGroup]

  const zoom = mapStore.useSelector((s) => s.zoom)
  const { IMAGE_OVERLAY } = getZoomThresholds(zoom)
  const ndviInView = IMAGE_OVERLAY === ZOOM_VISIBILITY.OK

  return (
    <>
      <LoadingOverlay
        visible={loadingData}
        loaderProps={{ color: colors.primaryLight }}
        overlayColor={colors.midnight}
      />

      {showSwitchRow && (
        <div css={{ flexWrap: 'nowrap', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          {excludeMapLabelSwitch[primaryValueGroup as TValueGroup] || (
            <div>
              <Switch
                label={translate.phrases.banyanApp('Map Labels')}
                checked={showMapBadgeNames}
                onChange={(event) =>
                  mapControlsStore.setState((s) => ({
                    ...s,
                    showMapBadgeNames: event.currentTarget.checked,
                  }))
                }
                size="xs"
                styles={{
                  label: {
                    color: HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD ? colors.midnight : colors.white,
                    cursor: 'pointer',
                    fontSize: 13,
                    paddingLeft: '0.5rem',
                  },
                  track: {
                    cursor: 'pointer',
                    backgroundColor: showMapBadgeNames ? `${colors.green} !important` : undefined,
                    borderColor: showMapBadgeNames ? `${colors.green} !important` : undefined,
                  },
                }}
              />
            </div>
          )}

          <AggregateSwitch
            includeAggregateSwitch={!!includeAggregateSwitch[primaryValueGroup as TValueGroup]}
            displayAsHeatmapSwitch={replaceAggregateToggleWithHeatmapToggle[primaryValueGroup as TValueGroup]}
          />
        </div>
      )}
      {filterRow}
      {!primaryValueGroup && <NdviMapControls />}
      {!!includeNdviSwitch[primaryValueGroup as TValueGroup] && ndviInView && <NdviMapControls />}
    </>
  )
}
