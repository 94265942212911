import { Button } from 'components/Button/Button'
import type { FC } from 'react'
import { colors } from 'settings/colors'

type NarrowFooterProps = {
  primaryButtonDisabled?: boolean
  primaryButtonText?: string
  primaryButtonOnPress?: () => void
}

export const NarrowFooter: FC<NarrowFooterProps> = ({
  primaryButtonDisabled,
  primaryButtonText,
  primaryButtonOnPress,
}) => {
  if (!primaryButtonText) return null

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        borderTop: `1px solid ${colors.grey200}`,
        padding: '10px 20px',
        paddingBottom: 'max(10px, env(safe-area-inset-bottom))',
        backgroundColor: colors.grey50,
      }}
    >
      <Button
        disabled={primaryButtonDisabled}
        variant="primary"
        css={{ width: '100%' }}
        onClick={primaryButtonOnPress}
      >
        {primaryButtonText}
      </Button>
    </div>
  )
}
