import * as FullStory from '@fullstory/browser'
import { appInfo } from './appInfo'

if (appInfo.useFullStory) {
  FullStory.init({
    orgId: 'JYJKV',
    script: 'edge.fullstory.com/s/fs.js',
  })

  FullStory.event('appInfo', appInfo)
}
