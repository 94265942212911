import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'

export const countryT = (country: string) => {
  const countries = translate.phrases.countries

  /* eslint-disable padding-line-between-statements */
  if (country === 'AU') return countries('Australia')
  if (country === 'CA') return countries('Canada')
  if (country === 'CL') return countries('Chile')
  if (country === 'DE') return countries('Germany')
  if (country === 'ES') return countries('Spain')
  if (country === 'FR') return countries('France')
  if (country === 'IT') return countries('Italy')
  if (country === 'NL') return countries('Netherlands')
  if (country === 'RO') return countries('Romania')
  if (country === 'US') return countries('United States')
  /* eslint-enable padding-line-between-statements */

  // if we made it this far, it's not implemented...

  Sentry.captureException(`${JSON.stringify({ country })} has not been implemented`)

  return country
}
