import { css } from '@emotion/css'
import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { WindMachineAutoStartStopSettings } from '../../../WindMachineAutoMenu/WideScreen/WindMachineAutoStartStopSettings'

export const GroupControlFilters = ({
  content,
  fetchData,
}: {
  content: TWindMachineDetailsElement
  fetchData: () => void
}) => {
  const selectedProperty = selectedFieldAssetsStore.useSelector((s) => s.property)

  if (
    !checkAuthorization({
      permission: 'PUBLISH_FROST_FAN_OPERATIONAL_DEVICE_SETTINGS',
      entity: Number(selectedProperty),
    })
  ) {
    return null
  }

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px 0px 20px 0px',
        margin: '0 10px',
        borderBottom: 'solid 1px #E2E2E2',
      })}
    >
      <WindMachineAutoStartStopSettings content={content} fetchData={fetchData} />
    </div>
  )
}
