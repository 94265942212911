import { useElementSize } from '@mantine/hooks'
import { sortByKey } from '@semios/app-platform-common'
import { SettingsDeleteButton } from 'App/Map/UserSettingsMenu/components/SettingsDeleteButton/SettingsDeleteButton'
import { SettingsDuplicateButton } from 'App/Map/UserSettingsMenu/components/SettingsDuplicateButton/SettingsDuplicateButton'
import { SettingsEditButton } from 'App/Map/UserSettingsMenu/components/SettingsEditButton/SettingsEditButton'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { colors } from 'settings/colors'
import type { Alert } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { calculateTextWidth } from 'utils/calculateTextWidth'
import { fetchApiCustomer } from 'utils/fetchApiCustomer'
import { showNotification } from 'utils/showNotification'
import { showConfirmSettingModal } from '../../../_utils/showConfirmSettingModal'
import type { TModalDrawerForm } from '../../Alerts'
import { mapAlertIdTypeToLabel } from '../../mapAlertIdTypeToLabel'
import { alertDeleteQuery } from '../../queries/queries'
import { ContactsRow } from './ContactsRow/ContactsRow'
import { CoverageColumn } from './CoverageColumn/CoverageColumn'
import { FrequencyAndScheduling } from './FrequencyAndScheduling/FrequencyAndScheduling'
import { RulesRow } from './RulesRow/RulesRow'
import { WeatherForecastRulesRow } from './WeatherForecastRulesRow/WeatherForecastRulesRow'

type DeleteAlertResponse = {
  data: {
    deleteAlert: boolean
  }
}

type DeleteWeatherForecastAlertResponse = {
  id: string
  deleleAt: string
}[]

export const AlertCard = ({
  alert,
  setSelectedAlert,
  setModalDrawerForm,
}: {
  alert: Alert
  setSelectedAlert: Dispatch<SetStateAction<Alert | null>>
  setModalDrawerForm: Dispatch<SetStateAction<TModalDrawerForm>>
}) => {
  const {
    name,
    rules,
    contacts,
    groups,
    type,
    properties,
    insect,
    sleepMinutes,
    frequencyMinutes,
    startDate,
    endDate,
    id,
  } = alert

  const { ref: contactRef, width: contactWidth } = useElementSize()
  const { ref: coverageRef, width: coverageWidth } = useElementSize()
  const { ref: alertCardRef, width: alertCardWidth } = useElementSize()
  const alerts = userDetailsStore.useSelector((s) => s.alerts)

  const handleDeleteAlert = async (id: string) => {
    try {
      const response: DeleteAlertResponse = await fetchApiCustomer({
        body: {
          query: alertDeleteQuery,
          variables: {
            id,
          },
        },
      })

      const success = response?.data?.deleteAlert

      if (success) {
        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('Alert successfully deleted'),
        })

        const newAlerts = alerts.filter((a) => a.id !== id)

        userDetailsStore.setState((s) => {
          return {
            ...s,
            alerts: newAlerts,
          }
        })
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to delete alert'),
        })
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to delete alert'),
      })
    }
  }

  const handleDeleteWeatherForecastAlert = async (id: string) => {
    try {
      const response: DeleteWeatherForecastAlertResponse = await fetchApiCustomer({
        body: { id },
        restApiUrl: 'weather-forecast-alert-delete',
      })

      const responseId = response?.[0].id ? String(response?.[0].id) : null

      if (responseId) {
        showNotification({
          type: 'success',
          message: translate.phrases.banyanApp('Alert successfully deleted'),
        })

        const newAlerts = alerts.filter((a) => a.id !== id)

        userDetailsStore.setState((s) => {
          return {
            ...s,
            alerts: newAlerts,
          }
        })
      } else {
        showNotification({
          type: 'error',
          message: translate.phrases.banyanApp('Failed to delete alert'),
        })
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: translate.phrases.banyanApp('Failed to delete alert'),
      })
    }
  }

  const buttonsShouldGoToNextLine = alertCardWidth - coverageWidth < 380
  const nameTextWidth = calculateTextWidth({ fontSize: '16px', fontWeight: '700', text: name })
  const nameShouldGoToNextLine = alertCardWidth - contactWidth - 20 < nameTextWidth
  const sortedContactNames = contacts?.sort(sortByKey('name')).map((c) => c.name) || []
  const sortedGroupNames = groups?.sort(sortByKey('name')).map((g) => g.name) || []
  const groupAndContactNameList = [...sortedContactNames, ...sortedGroupNames]

  return (
    <div
      css={{
        border: `1px solid ${colors.grey200}`,
        padding: 15,
        marginBottom: 5,
        color: colors.midnight,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 3,
      }}
      ref={alertCardRef}
    >
      {nameShouldGoToNextLine ? (
        <>
          <div css={{ marginLeft: 10, display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
            <ContactsRow contactNameList={groupAndContactNameList} />
          </div>
          <div
            css={{
              fontWeight: '700',
              fontSize: 16,
            }}
          >
            {name}
          </div>
        </>
      ) : (
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              fontWeight: '700',
              fontSize: 16,
            }}
          >
            {name}
          </div>
          <div css={{ marginLeft: 10 }}>
            <div ref={contactRef}>
              <ContactsRow contactNameList={groupAndContactNameList} />
            </div>
          </div>
        </div>
      )}
      <div css={{ margin: '20px 0' }}>
        {type.id === 'weatherForecastAlert' ? (
          <WeatherForecastRulesRow rules={rules} />
        ) : (
          <RulesRow
            rules={rules}
            alertTypeId={type.id}
            insectId={insect?.id ? Number(insect.id) : undefined}
          />
        )}
        <FrequencyAndScheduling
          sleepMinutes={sleepMinutes}
          frequencyMinutes={frequencyMinutes}
          startDate={startDate}
          endDate={endDate}
          rules={rules}
        />
      </div>
      <div css={{ display: 'flex', alignItems: 'center', gap: 5 }}>
        <div>{`${translate.phrases.banyanApp('Type')}:`}</div>
        <div css={{ fontWeight: '700' }}>{mapAlertIdTypeToLabel(type.id)}</div>
      </div>

      <div
        css={{
          display: 'flex',
          flexDirection: buttonsShouldGoToNextLine ? 'column' : 'row',
          justifyContent: buttonsShouldGoToNextLine ? 'left' : 'space-between',
        }}
      >
        <div css={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <div>{`${translate.phrases.banyanApp('Coverage')}:`}</div>
          <div ref={coverageRef}>
            <CoverageColumn propertyIds={properties?.map((p) => Number(p.id))} />
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: buttonsShouldGoToNextLine ? 20 : 0,
          }}
        >
          <SettingsDeleteButton
            onClick={() => {
              showConfirmSettingModal({
                title: translate.phrases.banyanApp('Delete Alert'),
                content: (
                  <div>
                    <p>
                      {translate.phrases.banyanApp(
                        'Are you sure you want to delete {{alertName}} as an alert?',
                        { alertName: name },
                      )}
                    </p>
                  </div>
                ),
                onConfirm: () =>
                  type.id === 'weatherForecastAlert'
                    ? handleDeleteWeatherForecastAlert(id)
                    : handleDeleteAlert(id),
                confirmButtonText: translate.phrases.banyanApp('Delete Alert'),
              })
            }}
          />
          <SettingsEditButton
            onClick={() => {
              setSelectedAlert(alert)

              setModalDrawerForm('editAlert')
            }}
          />
          <SettingsDuplicateButton
            onClick={() => {
              // @ts-ignore: id is undefined for duplicate alert
              setSelectedAlert({ ...alert, id: undefined })

              setModalDrawerForm('editAlert')
            }}
          />
        </div>
      </div>
    </div>
  )
}
