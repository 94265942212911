import type { TValuesCurrent, TValuesTimeseries } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TPointCategory, TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { getSelectedRegion } from './getSelectedRegion'

export type TPossibleValuesToCheck = {
  valuesCurrent?: TValuesCurrent[]
  valuesTimeseries?: TValuesTimeseries[]
}

export const doesSelectedPropertyHaveValueTypes = ({
  valuesCurrent,
  valuesTimeseries,
}: TPossibleValuesToCheck): boolean => {
  const selectedFieldAssetState = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssetState.property
  const fieldAssets = fieldAssetStore.getState().properties
  const property = selectedProperty ? fieldAssets?.[selectedProperty] : null

  if (!property) return false

  if (valuesCurrent) {
    return valuesCurrent.some((vt) => property.valuesCurrent.includes(vt))
  }

  if (valuesTimeseries) {
    return valuesTimeseries.some((vt) => property.valuesTimeseries.includes(vt))
  }

  return false
}

export const doesSelectedPointHaveValueTypes = ({
  valuesCurrent,
  valuesTimeseries,
  pointCategory,
}: TPossibleValuesToCheck & {
  pointCategory?: TPointCategory
}): boolean => {
  const selectedFieldAssetState = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssetState.property

  const selectedPoint = pointCategory
    ? selectedFieldAssetState[pointCategory]
    : selectedFieldAssetState.lngLat

  const fieldAssets = fieldAssetStore.getState().properties
  const property = selectedProperty ? fieldAssets?.[selectedProperty] : null

  if (!property) return false

  const point = selectedPoint ? property.points?.[selectedPoint] : null

  if (!point) return false

  if (valuesCurrent) {
    return valuesCurrent.some((vt) => point.valuesCurrent.includes(vt))
  }

  if (valuesTimeseries) {
    return valuesTimeseries.some((vt) => point.valuesTimeseries.includes(vt))
  }

  return false
}

export const doesSelectedBlockHaveValueTypes = ({
  valuesCurrent,
  valuesTimeseries,
}: TPossibleValuesToCheck): boolean => {
  const selectedFieldAssetState = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssetState.property
  const selectedBlock = selectedFieldAssetState.block
  const fieldAssets = fieldAssetStore.getState().properties
  const property = selectedProperty ? fieldAssets?.[selectedProperty] : null

  if (!property) return false

  const block = selectedBlock ? property.blocks?.[selectedBlock] : null

  if (!block) return false

  if (valuesCurrent) {
    return valuesCurrent.some((vt) => block.valuesCurrent.includes(vt))
  }

  if (valuesTimeseries) {
    return valuesTimeseries.some((vt) => block.valuesTimeseries.includes(vt))
  }

  return false
}

export const doesSelectedIrrigationZoneHaveValueTypes = ({
  valuesCurrent,
  valuesTimeseries,
}: TPossibleValuesToCheck): boolean => {
  const selectedFieldAssetState = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssetState.property
  const selectedIrrigationZone = selectedFieldAssetState.irrigationZoneEmitter
  const fieldAssets = fieldAssetStore.getState().properties
  const property = selectedProperty ? fieldAssets?.[selectedProperty] : null

  if (!property) return false

  const irrigationZone = selectedIrrigationZone
    ? property.irrigationZoneEmitters?.[selectedIrrigationZone]
    : null

  if (!irrigationZone) return false

  if (valuesCurrent) {
    return valuesCurrent.some((vt) => irrigationZone.valuesCurrent.includes(vt))
  }

  if (valuesTimeseries) {
    return valuesTimeseries.some((vt) => irrigationZone.valuesTimeseries.includes(vt))
  }

  return false
}

export const doesSelectedTrapHaveValueTypes = ({
  valuesCurrent,
  valuesTimeseries,
}: TPossibleValuesToCheck): boolean => {
  const selectedFieldAssetState = selectedFieldAssetsStore.getState()
  const selectedProperty = selectedFieldAssetState.property
  const selectedTrap = selectedFieldAssetState.trapPoint
  const fieldAssets = fieldAssetStore.getState().properties
  const property = selectedProperty ? fieldAssets?.[selectedProperty] : null

  if (!property) return false

  const trap = selectedTrap ? property.points?.[selectedTrap] : null

  if (!trap) return false

  if (valuesCurrent) {
    return valuesCurrent.some((vt) => trap.valuesCurrent.includes(vt))
  }

  if (valuesTimeseries) {
    return valuesTimeseries.some((vt) => trap.valuesTimeseries.includes(vt))
  }

  return false
}

export const doesSelectedRegionHaveValueTypes = ({
  selectedFieldAssets,
  valuesCurrent,
  valuesTimeseries,
}: {
  valuesCurrent?: TValuesCurrent[]
  valuesTimeseries?: TValuesTimeseries[]
  selectedFieldAssets?: TSelectedFieldAssetsStoreState
}): boolean => {
  const selectedRegion = getSelectedRegion({
    selectedFieldAssets,
    selectedProperty: selectedFieldAssetsStore.getState().property,
  })

  if (!selectedRegion) return false

  const region = fieldAssetStore.getState()?.regions?.[String(selectedRegion)]

  if (!region) return false

  if (valuesCurrent) {
    return valuesCurrent.some((vt) => region.valuesCurrent.includes(vt))
  }

  if (valuesTimeseries) {
    return valuesTimeseries.some((vt) => region.valuesTimeseries.includes(vt))
  }

  return false
}
