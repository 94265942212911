import type { ConnectionStatus } from '@capacitor/network'
import { Network } from '@capacitor/network'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'
import { actions } from './actions/networkStore'
import { selectors } from './selectors/networkStore'

export enum NetworkStatusNotification {
  OFFLINE = 'OFFLINE',
  UPLOADING = 'UPLOADING',
  SYNC_SUCCESS = 'SYNC_SUCCESS',
  SYNC_ERROR = 'SYNC_ERROR',
}

export type TNetworkStore = {
  connectionStatus: ConnectionStatus
  networkStatusNotification: NetworkStatusNotification | null
}

const store = persistentInit<TNetworkStore>({
  initialState: {
    connectionStatus: {
      connected: true,
      connectionType: 'unknown',
    },
    networkStatusNotification: null,
  },
  keysToPutInPersistentStorage: {
    connectionStatus: false,
    networkStatusNotification: false,
  },
  keysToPutInURL: {
    connectionStatus: false,
    networkStatusNotification: false,
  },
  storeName: 'networkStore',
})

export const networkStore = {
  ...store,
  actions,
  selectors,
}

Network.addListener('networkStatusChange', async (status) => {
  networkStore.setState((s) => ({
    ...s,
    connectionStatus: status,
    // Show notification popup when connection is lost
    networkStatusNotification: !status.connected ? NetworkStatusNotification.OFFLINE : null,
  }))
})

export const initNetworkStatus = async () => {
  const status = await Network.getStatus()

  networkStore.setState((s) => ({
    ...s,
    connectionStatus: status,
    // Show notification popup when connection is lost
    networkStatusNotification: status.connected ? null : NetworkStatusNotification.OFFLINE,
  }))
}
