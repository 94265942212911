import { EAggregationInterval } from 'App/Map/types'
import { chooseAmongstUnAggHourlyAndDaily } from '../../../chooseAmongstUnAggHourlyAndDaily'

export const getColSize = () => {
  const aggregationInterval = chooseAmongstUnAggHourlyAndDaily()

  // 36e5 is 1 hour in milliseconds
  if (!aggregationInterval) return 36e5 / 6

  if (aggregationInterval === EAggregationInterval.DAILY) {
    return 36e5 * 24
  }

  return 36e5
}
