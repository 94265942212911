import { MapContext } from 'App/Map/MapContext/MapContext'
import NodePositioningModal from 'App/ServiceCenter/NodePositioningModal'
import type { FC } from 'react'
import { useContext } from 'react'
import type { GeoJSONPoint, GeoJSONPosition } from 'wellknown'
import type { InstallationStepProp } from '../types'

//we ONLY allow the user to change the location of the planned node before activating it
export const LocationConfirmation: FC<InstallationStepProp<GeoJSONPoint>> = ({
  payload,
  onSubmit,
  onClose,
}) => {
  const { map } = useContext(MapContext)

  if (!map) return null

  const mapCenter = map.getCenter()?.toJSON()
  const mapZoom = map.getZoom()

  const plannedLocation: google.maps.LatLngLiteral | undefined =
    (payload.location && {
      lng: payload.location?.coordinates[0],
      lat: payload.location?.coordinates[1],
    }) ||
    undefined

  const mapCameraCenter = plannedLocation || mapCenter || { lat: 0, lng: 0 }

  const handleSubmit = async (location: google.maps.LatLngLiteral) => {
    if (!location) return

    const coordinates = [location.lng, location.lat] as GeoJSONPosition

    const geolocation = {
      type: 'Point',
      coordinates: coordinates,
    } as GeoJSONPoint

    await onSubmit(geolocation)

    onClose(true)
  }

  return (
    <NodePositioningModal
      nodeType={payload?.nodeType}
      showDefaultLocationMarker={!!plannedLocation}
      mapDefaultCenter={mapCameraCenter}
      mapDefaultZoom={mapZoom}
      onSubmit={handleSubmit}
      onClose={() => onClose(false)}
    />
  )
}
