import type { CSSObject } from '@emotion/css'
import { Accordion, useMantineTheme } from '@mantine/core'
import { colors } from 'settings/colors'
import { Checkbox } from '../../../Checkbox/Checkbox'
import { useMultiSelectContext } from '../../MultiSelect.context'
import type { TOptions } from '../../MultiSelect.types'

export const Group = () => {
  const {
    isSelectedValue,
    onSelectItem,
    optionValueDecorator,
    selectionLimit,
    selectedValues,
    setSelectedValues,
    onChange,
    groupedObject,
    groupValues,
  } = useMultiSelectContext()

  const theme = useMantineTheme()

  const fadeOutSelection = (item: TOptions) => {
    if (selectionLimit === -1 || selectionLimit !== selectedValues.length) {
      return false
    }

    return !isSelectedValue(item)
  }

  const checkGroup = ({ key, mode = 'some' }: { key: string; mode?: 'all' | 'some' }): boolean => {
    const isValueSelected = (el: TOptions): boolean => {
      return selectedValues.some((selectedValue) => selectedValue.value === el.value)
    }

    if (mode === 'all') {
      return groupedObject[key].every(isValueSelected)
    }

    return groupedObject[key].some(isValueSelected)
  }

  const selectGroupItems = (key: string) => {
    const isValueSelected = (item: TOptions): boolean => {
      return selectedValues.some((selectedValue) => selectedValue.value === item.value)
    }

    const allItemsSelected = checkGroup({ key, mode: 'all' })

    const updatedValues = allItemsSelected
      ? selectedValues.filter(
          (selectedValue) => !groupedObject[key].some((item) => item.value === selectedValue.value),
        )
      : [...selectedValues, ...groupedObject[key].filter((item) => !isValueSelected(item))]

    setSelectedValues(updatedValues)

    onChange(updatedValues)
  }

  return (
    <>
      {Object.keys(groupedObject).map((groupKey) => {
        return (
          <Accordion
            key={groupKey}
            multiple={true}
            defaultValue={groupValues}
            styles={{ label: { padding: 0 } }}
          >
            <Accordion.Item value={groupKey}>
              <Accordion.Control
                css={{
                  color: theme.colors.midnight[0],
                  padding: '10px 15px',
                  fontSize: 14,
                }}
              >
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    css={{ marginRight: 10 }}
                    readOnly
                    checked={checkGroup({ key: groupKey, mode: 'all' })}
                    indeterminate={
                      checkGroup({ key: groupKey, mode: 'some' }) &&
                      !checkGroup({ key: groupKey, mode: 'all' })
                    }
                    onClick={(event) => {
                      event.stopPropagation()

                      event.preventDefault()

                      selectGroupItems(groupKey)
                    }}
                  />
                  {groupKey}
                </div>
              </Accordion.Control>

              <Accordion.Panel>
                {groupedObject[groupKey].map((option, i) => {
                  const isSelected = isSelectedValue(option)
                  const extraStyles: CSSObject = {}

                  if (fadeOutSelection(option)) {
                    extraStyles.pointerEvents = 'none'

                    extraStyles.opacity = 0.5
                  }

                  return (
                    <div
                      key={`option${i}`}
                      css={{
                        'padding': 10,
                        'display': 'flex',
                        'color': colors.midnight,
                        'fontSize': 14,
                        'fontWeight': isSelected ? '700' : '400',
                        '&:hover': {
                          background: colors.grey50,
                          cursor: 'pointer',
                        },
                        ...extraStyles,
                      }}
                      onClick={() => onSelectItem(option)}
                    >
                      <div css={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox css={{ marginRight: 10 }} readOnly checked={isSelected} />
                        {/*  TODO: fix for ReactNode*/}
                        {optionValueDecorator && typeof option.label === 'string'
                          ? optionValueDecorator(option.label, option)
                          : option.label}
                      </div>
                    </div>
                  )
                })}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )
      })}
    </>
  )
}
