import { ActionIcon } from '@mantine/core'
import { closeAllModals, openModal } from '@mantine/modals'
import type { ModalSettings } from '@mantine/modals/lib/context'
import { Button } from 'components/Button/Button'
import { IconClose } from 'components/icons/IconClose'
import { translate } from 'i18n/i18n'
import { omit } from 'lodash'
import React from 'react'
import { colors } from 'settings/colors'

export const showConfirmSettingModal = (o: {
  title: React.ReactNode
  content: React.ReactNode
  onConfirm: () => void
  confirmButtonText?: string
  noCancelButton?: boolean
  modalSettings?: ModalSettings
}) => {
  openModal({
    styles: () => ({ header: { alignItems: 'flex-start' } }),
    zIndex: 9999,
    size: '350px',
    padding: 0,
    title: (
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '15px 15px 0px 15px',
          fontSize: '18px',
        }}
      >
        <ActionIcon
          css={{ color: colors.grey800, marginRight: 8 }}
          onClick={() => closeAllModals()}
          radius="xl"
        >
          <IconClose />
        </ActionIcon>
        <span css={{ fontWeight: 'bold' }}>{o.title}</span>
      </div>
    ),
    closeOnEscape: false,
    closeOnClickOutside: false,
    withCloseButton: false,
    centered: true,
    children: (
      <div>
        <div css={{ padding: '0px 15px' }}>{o.content}</div>
        <div
          css={{
            display: 'flex',
            justifyContent: o.noCancelButton ? 'flex-end' : 'space-between',
            alignItems: 'center',
            borderTop: `1px solid ${colors.grey200}`,
            padding: '10px',
            backgroundColor: colors.grey50,
          }}
        >
          {o.noCancelButton ? null : (
            <Button
              variant="negative"
              onClick={() => closeAllModals()}
              css={{
                'color': colors.black,
                '&:not([disabled],[data-loading="true"]):hover': {
                  color: colors.black,
                },
                'borderColor': colors.black,
              }}
            >
              {translate.phrases.banyanApp('Cancel')}
            </Button>
          )}
          <div>
            <Button
              onClick={() => {
                o.onConfirm()

                closeAllModals()
              }}
              variant="negative"
              css={{
                'color': colors.black,
                'borderColor': colors.red,
                '&:not([disabled],[data-loading="true"]):hover': {
                  color: colors.black,
                },
                'flexGrow': 1,
              }}
            >
              {o.confirmButtonText}
            </Button>
          </div>
        </div>
      </div>
    ),
    ...omit(o.modalSettings, ['title', 'children']),
  })
}
