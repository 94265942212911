import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconHeatmap = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 16')}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9193 3.83038C20.9193 5.94585 19.2044 7.66076 17.0889 7.66076C14.9735 7.66076 13.2585 5.94585 13.2585 3.83038C13.2585 1.71491 14.9735 0 17.0889 0C19.2044 0 20.9193 1.71491 20.9193 3.83038Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1435 10.4589C14.1435 13.3067 11.835 15.6152 8.98726 15.6152C6.13951 15.6152 3.83105 13.3067 3.83105 10.4589C3.83105 7.61119 6.13951 5.30273 8.98726 5.30273C11.835 5.30273 14.1435 7.61119 14.1435 10.4589Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.59828 3.38909C5.59828 4.93484 4.345 6.18812 2.79924 6.18812C1.25327 6.18812 0 4.93484 0 3.38909C0 1.84312 1.25327 0.589844 2.79924 0.589844C4.345 0.589844 5.59828 1.84312 5.59828 3.38909Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0359 13.4053C20.0359 14.4629 19.1784 15.3204 18.1208 15.3204C17.063 15.3204 16.2056 14.4629 16.2056 13.4053C16.2056 12.3477 17.063 11.4902 18.1208 11.4902C19.1785 11.4902 20.0359 12.3477 20.0359 13.4053Z"
        fill="currentColor"
      />
    </svg>
  )
}
