import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { isNil } from '@semios/app-platform-common'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import type { TColumn } from 'components/SummaryTable/SummaryTable.types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { DownloadCell } from './DownloadCell/DownloadCell'
import { PermitNumberTitle } from './PermitNumberTitle/PermitNumberTitle'
import { PropertyCell } from './PropertyCell/PropertyCell'
import { PropertyTitle } from './PropertyTitle/PropertyTitle'
import { TypeTitle } from './TypeTitle/TypeTitle'

export const ReportsTable = ({
  dataAfterFiltersAndSearch,
  files,
  filterActiveForPermitNumbers,
  filterActiveForProperties,
  filterActiveForTypes,
  filteredPermitNumbers,
  filteredProperties,
  filteredTypes,
  properties,
  searchString,
  setDownloadedFiles,
  setFilteredPermitNumbers,
  setFilteredProperties,
  setFilteredTypes,
}: {
  dataAfterFiltersAndSearch: routes.PheromonePurRecDownload.Response['files']
  files: routes.PheromonePurRecDownload.Response['files']
  filterActiveForPermitNumbers: boolean
  filterActiveForProperties: boolean
  filterActiveForTypes: boolean
  filteredPermitNumbers: { [permitNumber: string]: boolean }
  filteredProperties: { [propertyId: string]: boolean }
  filteredTypes: { [type: string]: boolean }
  properties: TProperty[]
  searchString: string
  setDownloadedFiles: Dispatch<SetStateAction<Record<string, boolean>>>
  setFilteredPermitNumbers: Dispatch<SetStateAction<{ [permitNumber: string]: boolean }>>
  setFilteredProperties: Dispatch<SetStateAction<{ [propertyId: string]: boolean }>>
  setFilteredTypes: Dispatch<SetStateAction<{ [type: string]: boolean }>>
}) => {
  const [paginationResetKey, setPaginationResetKey] = useState(`${Math.random()}`)

  const columns: TColumn[] = useMemo(
    () => [
      {
        title: translate.phrases.banyanApp('Download'),
        key: 'pdfURL',
        dataIndex: 'pdfURL',
        width: 100,
        render: (value: string) => (
          <DownloadCell pdfUrl={value as string} setDownloadedFiles={setDownloadedFiles} />
        ),
      },
      {
        title: (
          <TypeTitle
            data={files}
            filterActiveForTypes={filterActiveForTypes}
            filteredTypes={filteredTypes}
            setFilteredTypes={setFilteredTypes}
          />
        ),
        key: 'type',
        dataIndex: 'type',
        width: 90,
      },
      {
        title: translate.phrases.banyanApp('Date Created'),
        key: 'dateCreatedYYYYMMDD',
        dataIndex: 'dateCreatedYYYYMMDD',
        width: 140,
        render: (value: string, row: typeof files[number]) =>
          moment
            .tz(
              value,
              properties.find((property) => property.propertyId === row.propertyIds[0])?.timezone || 'UTC',
            )
            .format(translate.dates.getMomentFormat('MMM D, YYYY')),
      },
      {
        title: translate.phrases.banyanApp('Date Applied'),
        key: 'dateAppliedYYYYMMDD',
        dataIndex: 'dateAppliedYYYYMMDD',
        width: 140,
        render: (value: string, row: typeof files[number]) =>
          isNil(value)
            ? translate.phrases.abbreviations('Not Applicable')
            : moment
                .tz(
                  value,
                  properties.find((property) => property.propertyId === row.propertyIds[0])?.timezone ||
                    'UTC',
                )
                .format(translate.dates.getMomentFormat('MMM D, YYYY')),
      },
      {
        title: translate.phrases.banyanApp('Year'),
        key: 'year',
        dataIndex: 'year',
        width: 70,
        render: (value: string) => (isNil(value) ? translate.phrases.abbreviations('Not Applicable') : value),
      },
      {
        title: (
          <PermitNumberTitle
            data={files}
            filterActiveForPermitNumbers={filterActiveForPermitNumbers}
            filteredPermitNumbers={filteredPermitNumbers}
            setFilteredPermitNumbers={setFilteredPermitNumbers}
          />
        ),
        key: 'permitNumber',
        dataIndex: 'permitNumber',
        width: 170,
      },
      {
        title: translate.phrases.banyanApp('Label #'),
        key: 'labelNumber',
        dataIndex: 'labelNumber',
        width: 80,
        render: (value: string) => (isNil(value) ? translate.phrases.abbreviations('Not Applicable') : value),
      },
      {
        title: translate.phrases.banyanApp('Version #'),
        key: 'versionNumber',
        dataIndex: 'versionNumber',
        width: 100,
        render: (value: string) => (isNil(value) ? translate.phrases.abbreviations('Not Applicable') : value),
      },
      {
        title: (
          <PropertyTitle
            data={files}
            filterActiveForProperties={filterActiveForProperties}
            filteredProperties={filteredProperties}
            setFilteredProperties={setFilteredProperties}
          />
        ),
        key: 'propertyIds',
        dataIndex: 'propertyIds',
        width: 250,
        render: (propertyIds: number[]) => <PropertyCell properties={properties} propertyIds={propertyIds} />,
      },
    ],
    [
      files,
      filterActiveForPermitNumbers,
      filterActiveForProperties,
      filterActiveForTypes,
      filteredPermitNumbers,
      filteredProperties,
      filteredTypes,
      properties,
      setDownloadedFiles,
      setFilteredPermitNumbers,
      setFilteredProperties,
      setFilteredTypes,
    ],
  )

  useEffect(() => {
    setPaginationResetKey(`${Math.random()}`)
  }, [searchString, filteredPermitNumbers, filteredProperties, filteredTypes])

  return (
    <SummaryTable
      autoSortColumns={['dateCreatedYYYYMMDD', 'dateAppliedYYYYMMDD', 'permitNumber', 'type']}
      columns={columns}
      data={dataAfterFiltersAndSearch}
      pageSize={10}
      paginationResetKey={paginationResetKey}
    />
  )
}
