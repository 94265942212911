import { Switch } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { Authorization } from 'components/Authorization/Authorization'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { colors } from 'settings/colors'
import { checkAuthorization } from 'utils/checkAuthorization'
import type { TReportStartEnd } from '../../../_utils/formTypesAndStyles'
import { getDateFromReport } from '../../../_utils/getDateFromReport'
import { PropertiesSubtitle } from './PropertiesSubtitle'

export const WaterManagement = ({
  evapotranspiration,
  setEvapotranspiration,
  evapotranspirationStartEnd,
  setEvapotranspirationBorerStartEnd,
  selectedReportProperties,
  irrigationActivity,
  setIrrigationActivity,
  irrigationActivityStartEnd,
  setIrrigationActivityStartEnd,
  soilMoisture,
  setSoilMoisture,
  soilMoistureStartEnd,
  setSoilMoistureStartEnd,
}: {
  evapotranspiration: boolean
  setEvapotranspiration: Dispatch<SetStateAction<boolean>>
  evapotranspirationStartEnd: TReportStartEnd | null
  setEvapotranspirationBorerStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  selectedReportProperties: TOptions[]
  irrigationActivity: boolean
  setIrrigationActivity: Dispatch<SetStateAction<boolean>>
  irrigationActivityStartEnd: TReportStartEnd | null
  setIrrigationActivityStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  soilMoisture: boolean
  setSoilMoisture: Dispatch<SetStateAction<boolean>>
  soilMoistureStartEnd: TReportStartEnd | null
  setSoilMoistureStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
}) => {
  const propertyIds = selectedReportProperties
    ? Object.values(selectedReportProperties).map((obj) => obj.value as string)
    : null

  const shouldShowEvapotranspiration = checkAuthorization(
    (propertyIds || []).map((entity: string) => ({
      entity: Number(entity),
      permission: 'CREATE_REPORT_EVAPOTRANSPIRATION' as TPermission,
    })),
  )

  const shouldShowIrrigationActivity = checkAuthorization(
    (propertyIds || []).map((entity: string) => ({
      entity: Number(entity),
      permission: 'CREATE_REPORT_IRRIGATION_ACTIVITY' as TPermission,
    })),
  )

  const shouldShowSoilMoisture = checkAuthorization(
    (propertyIds || []).map((entity: string) => ({
      entity: Number(entity),
      permission: 'CREATE_REPORT_SOIL_MOISTURE' as TPermission,
    })),
  )

  return (
    <Authorization
      requires={shouldShowSoilMoisture || shouldShowIrrigationActivity || shouldShowEvapotranspiration}
    >
      <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
        <h3 css={{ fontWeight: '500' }}>{translate.phrases.banyanApp('Water Management')}</h3>

        {shouldShowEvapotranspiration ? (
          <div>
            <h4>
              {translate.phrases.banyanApp('Evapotranspiration')}
              {PropertiesSubtitle({
                permission: 'CREATE_REPORT_EVAPOTRANSPIRATION' as TPermission,
                propertyIds: propertyIds,
              })}
            </h4>
            <Switch
              size="md"
              color="lime"
              label={translate.phrases.banyanApp('Include evapotranspiration summary')}
              styles={{
                label: { cursor: 'unset', fontSize: 16 },
                track: {
                  width: 36,
                  cursor: 'unset',
                  backgroundColor: evapotranspiration ? `${colors.green} !important` : undefined,
                  borderColor: evapotranspiration ? `${colors.green} !important` : undefined,
                },
              }}
              checked={evapotranspiration}
              onChange={() => setEvapotranspiration(!evapotranspiration)}
            />

            <div css={{ marginTop: '15px', display: 'flex' }}>
              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select start date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(evapotranspirationStartEnd?.startDate)}
                onChange={(e) =>
                  setEvapotranspirationBorerStartEnd((prevState) => ({
                    ...prevState,
                    startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                disabled={!evapotranspiration}
              />

              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select end date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(evapotranspirationStartEnd?.endDate)}
                onChange={(e) =>
                  setEvapotranspirationBorerStartEnd((prevState) => ({
                    ...prevState,
                    endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px' }}
                disabled={!evapotranspiration}
              />
            </div>
          </div>
        ) : null}
        {shouldShowIrrigationActivity ? (
          <div>
            <h4>
              {translate.phrases.banyanApp('Irrigation Activity')}
              {PropertiesSubtitle({
                permission: 'CREATE_REPORT_IRRIGATION_ACTIVITY' as TPermission,
                propertyIds: propertyIds,
              })}
            </h4>
            <Switch
              size="md"
              color="lime"
              label={translate.phrases.banyanApp('Include irrigation activity for the previous week')}
              styles={{
                label: { cursor: 'unset', fontSize: 16 },
                track: {
                  width: 36,
                  cursor: 'unset',
                  backgroundColor: irrigationActivity ? `${colors.green} !important` : undefined,
                  borderColor: irrigationActivity ? `${colors.green} !important` : undefined,
                },
              }}
              checked={irrigationActivity}
              onChange={() => setIrrigationActivity(!irrigationActivity)}
            />

            <div css={{ marginTop: '15px', display: 'flex' }}>
              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select start date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(irrigationActivityStartEnd?.startDate)}
                onChange={(e) =>
                  setIrrigationActivityStartEnd((prevState) => ({
                    ...prevState,
                    startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                disabled={!irrigationActivity}
              />

              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select end date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(irrigationActivityStartEnd?.endDate)}
                onChange={(e) =>
                  setIrrigationActivityStartEnd((prevState) => ({
                    ...prevState,
                    endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px' }}
                disabled={!irrigationActivity}
              />
            </div>
          </div>
        ) : null}
        {shouldShowSoilMoisture ? (
          <div>
            <h4>
              {translate.phrases.banyanApp('Soil Moisture')}
              {PropertiesSubtitle({
                permission: 'CREATE_REPORT_SOIL_MOISTURE' as TPermission,
                propertyIds: propertyIds,
              })}
            </h4>
            <Switch
              size="md"
              color="lime"
              label={translate.phrases.banyanApp('Include soil moisture summary')}
              styles={{
                label: { cursor: 'unset', fontSize: 16 },
                track: {
                  width: 36,
                  cursor: 'unset',
                  backgroundColor: soilMoisture ? `${colors.green} !important` : undefined,
                  borderColor: soilMoisture ? `${colors.green} !important` : undefined,
                },
              }}
              checked={soilMoisture}
              onChange={() => setSoilMoisture(!soilMoisture)}
            />

            <div css={{ marginTop: '15px', display: 'flex' }}>
              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select start date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(soilMoistureStartEnd?.startDate)}
                onChange={(e) =>
                  setSoilMoistureStartEnd((prevState) => ({
                    ...prevState,
                    startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
                disabled={!soilMoisture}
              />

              <DatePickerInput
                valueFormat={translate.dates.getMomentFormat('MMM D')}
                clearable={false}
                placeholder={translate.phrases.banyanApp('Select end date')}
                monthLabelFormat={translate.dates.getMomentFormat('MMM')}
                value={getDateFromReport(soilMoistureStartEnd?.endDate)}
                onChange={(e) =>
                  setSoilMoistureStartEnd((prevState) => ({
                    ...prevState,
                    endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
                  }))
                }
                css={{ maxWidth: '100%', width: '240px' }}
                disabled={!soilMoisture}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Authorization>
  )
}
