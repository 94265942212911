import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconEdit = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 12 13')}>
      <path
        d="M9.70446 4.94221L7.05779 2.29554L0 9.35333V12H2.64667L9.70446 4.94221ZM11.7936 2.85311C12.0688 2.57786 12.0688 2.13322 11.7936 1.85796L10.142 0.20644C9.86679 -0.0688134 9.42214 -0.0688134 9.14689 0.20644L7.76356 1.58977L10.4102 4.23644L11.7936 2.85311Z"
        fill="currentColor"
      />
    </svg>
  )
}
