import React, { useState } from 'react'

interface ToggleSymbolValueProps {
  children: React.ReactNode
  value: number | string
}

export const ToggleSymbolValue: React.FC<ToggleSymbolValueProps> = ({ children, value }) => {
  const [showValue, setShowValue] = useState(false)

  return (
    <div css={{ cursor: 'pointer' }} onClick={() => setShowValue(!showValue)}>
      {showValue ? value : children}
    </div>
  )
}
