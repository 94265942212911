import { colors } from 'settings/colors'

export const Success = ({ size = 28, color = colors.green }: { size?: number; color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14" r="13" fill="white" stroke={color} strokeWidth="2" />
    <path
      d="M11.7727 17.7015L8.19318 14.2537L7 15.403L11.7727 20L22 10.1493L20.8068 9L11.7727 17.7015Z"
      fill={color}
    />
  </svg>
)
