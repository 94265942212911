import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'

const keyMaker = (o: {
  country: string | null
  gAdministrativeAreaLevel1: string | null
  gAdministrativeAreaLevel2: string | null
}): `${string}_${string}_${string}` => {
  return `${o.country}_${o.gAdministrativeAreaLevel1}_${o.gAdministrativeAreaLevel2}`
}

/**
 * this utility function looks at all the properties that are in
 * view, and finds every property that is within the same region
 */
export const getPropertyIdsForAllPropertiesInVisibleRegions = (
  propertyIdsInView: TFieldAssetKeyTypes.TPropertyId[],
): TFieldAssetKeyTypes.TPropertyId[] => {
  const allProperties = fieldAssetStore.getState().properties
  // we will build a dictionary like country_state_county: true
  const regionsInView: Record<`${string}_${string}_${string}`, true> = {}

  propertyIdsInView.forEach((propertyId) => {
    const foundProperty = allProperties?.[propertyId]

    if (!foundProperty) return

    regionsInView[keyMaker(foundProperty)] = true
  })

  const propertyIdsThatAreInTheSameRegionAsTheRegionsOnMapCover: TFieldAssetKeyTypes.TPropertyId[] = []

  Object.values(allProperties ?? {}).forEach((property) => {
    if (regionsInView[keyMaker(property)]) {
      propertyIdsThatAreInTheSameRegionAsTheRegionsOnMapCover.push(property.propertyId)
    }
  })

  return propertyIdsThatAreInTheSameRegionAsTheRegionsOnMapCover
}
