import { Tabs } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import type { Contact, Group } from 'stores/userDetailsStore'
import { useScreenSize } from 'utils/useScreenSize'
import { SettingsCreateNewButton } from '../../components/SettingsCreateNewButton/SettingsCreateNewButton'
import { SettingsFooterContainer } from '../../components/SettingsFooterContainer/SettingsFooterContainer'
import { ItemHeader } from '../../WideScreen/ItemHeader/ItemHeader'
import { Contacts } from './Contacts/Contacts'
import { ModalDrawerCreateOrEditContact } from './Contacts/ModalDrawerCreateOrEditContact'
import { Groups } from './Groups/Groups'
import { ModalDrawerCreateOrEditGroup } from './Groups/ModalDrawerCreateOrEditGroup'
import { useRelativeNotification } from './_utils/useRelativeNotification'

const DEFAULT_TAB = 'contacts'

type TActiveTab = 'contacts' | 'groups'

type TModalDrawerForm = 'createContact' | 'createGroup' | 'editContact' | 'editGroup' | null

export const ContactsAndGroups = () => {
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
  const [modalDrawerForm, setModalDrawerForm] = useState<TModalDrawerForm>(null)
  const { isWideScreen } = useScreenSize()
  const { RelativeNotification, showRelativeNotification } = useRelativeNotification()

  const onEdit = () => {
    setModalDrawerForm(activeTab === 'contacts' ? 'editContact' : 'editGroup')
  }

  return (
    <>
      <ItemHeader>{translate.phrases.banyanApp('Contacts')}</ItemHeader>
      <div css={{ margin: '0px 20px', paddingBottom: 60 }}>
        {isWideScreen && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            {RelativeNotification}
            <div css={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
              <SettingsCreateNewButton
                label={
                  activeTab === 'contacts'
                    ? translate.phrases.banyanApp('New Contact')
                    : translate.phrases.banyanApp('New Group')
                }
                onClick={() => {
                  setModalDrawerForm(activeTab === 'contacts' ? 'createContact' : 'createGroup')
                }}
              />
            </div>
          </div>
        )}
        <div>
          <Tabs
            defaultValue="contacts"
            styles={{
              tabsList: {
                button: {
                  'fontSize': 15,
                  'padding': 10,
                  'color': colors.primary,
                  '&:hover': { background: 'transparent', fontWeight: 600 },
                  '&[data-active]': {
                    'fontWeight': 700,
                    'borderBottom': `4px solid ${colors.midnight}`,
                    '&:hover': { fontWeight: 700, borderColor: colors.midnight },
                  },
                  '&:not(:first-of-type)': { marginLeft: 20 },
                },
              },
            }}
            onTabChange={(val: TActiveTab) => setActiveTab(val)}
          >
            <Tabs.List>
              <Tabs.Tab value="contacts">{translate.phrases.banyanApp('Contacts')}</Tabs.Tab>
              <Tabs.Tab value="groups">{translate.phrases.banyanApp('Groups')}</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="contacts" pt="xs">
              <Contacts
                onEdit={onEdit}
                setSelectedContact={setSelectedContact}
                showRelativeNotification={showRelativeNotification}
              />
            </Tabs.Panel>

            <Tabs.Panel value="groups" pt="xs">
              <Groups
                onEdit={onEdit}
                setSelectedGroup={setSelectedGroup}
                showRelativeNotification={showRelativeNotification}
              />
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
      {!isWideScreen && (
        <SettingsFooterContainer>
          <SettingsCreateNewButton
            label={
              activeTab === 'contacts'
                ? translate.phrases.banyanApp('New Contact')
                : translate.phrases.banyanApp('New Group')
            }
            onClick={() => {
              setModalDrawerForm(activeTab === 'contacts' ? 'createContact' : 'createGroup')
            }}
            css={{ width: '100%' }}
          />
        </SettingsFooterContainer>
      )}
      <ModalDrawerCreateOrEditContact
        opened={modalDrawerForm === 'createContact' || modalDrawerForm === 'editContact'}
        isEdit={modalDrawerForm === 'editContact'}
        onClose={() => setModalDrawerForm(null)}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        showRelativeNotification={showRelativeNotification}
      />
      <ModalDrawerCreateOrEditGroup
        opened={modalDrawerForm === 'createGroup' || modalDrawerForm === 'editGroup'}
        isEdit={modalDrawerForm === 'editGroup'}
        onClose={() => setModalDrawerForm(null)}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        showRelativeNotification={showRelativeNotification}
      />
    </>
  )
}
