import type { TRGBAColorWithOpacityAtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'

type ApplyToAllButtonProps = {
  buttonText: string
  applyToAll: () => void
  buttonColor?: TRGBAColorWithOpacityAtTheEnd
}

export const ApplyToAllButton = ({
  buttonText,
  applyToAll,
  buttonColor = colors.primary,
}: ApplyToAllButtonProps) => {
  return (
    <span
      onClick={applyToAll}
      css={{ margin: 0, padding: 0, textDecoration: 'underline', color: buttonColor }}
    >
      {buttonText}
    </span>
  )
}
