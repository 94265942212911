import React from 'react'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { DetailsPanelDateRange } from '../../DetailsPanelDateRange/DetailsPanelDateRange'

export const DateRangeRowSelfRegisteredUser = (props: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
}) => {
  return (
    <React.Fragment>
      <DetailsPanelDateRange selectedValueGroups={props.selectedValueGroups} isSelfRegisteredUser={true} />
    </React.Fragment>
  )
}
