import { isEmpty } from '@semios/app-platform-common'
import type { TPhrases } from '@semios/app-platform-i18n-core'
import { i18n } from 'i18n/i18n'
import { userDetailsStore } from 'stores/userDetailsStore'

let phrases: ReturnType<typeof i18n.getDataByLanguage>

export const getTranslatableTemplateForValueTypeGroup = (
  valueTypeGroup: string,
): TPhrases['sidekick'] | null => {
  if (!phrases) {
    const userLanguage = userDetailsStore.getState().language

    phrases = i18n.getDataByLanguage(isEmpty(userLanguage) ? 'en' : userLanguage)
  }

  const sidekickPhrases = phrases?.sidekick ?? {}
  const foundKey = Object.keys(sidekickPhrases).find((key) => key.startsWith(`${valueTypeGroup}::`))

  if (foundKey) {
    return foundKey as TPhrases['sidekick']
  }

  return null
}
