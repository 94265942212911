import type { GridTableContentSection } from 'components/GridTable/types'
import type { StackedChartSection } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'

export type TZoneEmitterLacksPermissionArgs = {
  id: StackedChartSection['id'] & GridTableContentSection['id']
  name: string
  title: StackedChartSection['title'] & GridTableContentSection['title']
  titleChildren: StackedChartSection['titleChildren'] & GridTableContentSection['titleChildren']
}

export const zoneEmitterLacksPermissionSectionMaker = ({
  id,
  name,
  title,
  titleChildren,
}: TZoneEmitterLacksPermissionArgs): StackedChartSection & GridTableContentSection => {
  return {
    id,
    title,
    titleChildren,
    items: [],
    fullWidthItems: [
      {
        height: 150,
        content: (
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {translate.phrases.banyanApp(
              'You do not have access to this feature for selected {{name}} and emitter type',
              {
                name,
              },
            )}
          </div>
        ),
      },
    ],
    defaultCollapsed: true,
  }
}
