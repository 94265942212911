import type { Contact, Group } from 'stores/userDetailsStore'
import type { TPestSelected, TReportStartEnd } from '../../_utils/formTypesAndStyles'
import type { Rule, trapRule } from './reportSettings'

export type TVariables = {
  id?: string
  name: string | null
  notifyOwnerByEmail: boolean
  contacts: string[]
  groups: string[]
  rules: {
    dayOfWeek: string | null
    propertyIds: string[] | null
    weather: TReportStartEnd | null
    fireBlight: TReportStartEnd | null
    pollination: TReportStartEnd | null
    growingDegreeDays: TReportStartEnd | null
    evapotranspiration: TReportStartEnd | null
    irrigationActivity: TReportStartEnd | null
    irrigationPlanner: TReportStartEnd | null
    chill: TReportStartEnd | null
    scout: TReportStartEnd | null
    pests: TPestSelected | null
  }
}

export type TTrapVariables = {
  id?: string
  name: string | null
  notifyOwnerByEmail: boolean
  contacts: string[]
  groups: string[]
  rules: {
    dayOfWeek: string | null
    propertyIds: string[] | null
    startDate?: string | null
    endDate?: string | null
    pests: TPestSelected | null
  }
}

export const getReportVariablesForQuery = ({
  reportId, // <-- the presence of 'reportId' will determine if this call will create a new report or modify an existing one
  contacts,
  groups,
  name,
  notifyOwnerByEmail,
  rules,
}: {
  reportId?: string
  contacts: Contact[]
  groups: Group[]
  name: string | null
  notifyOwnerByEmail: boolean
  rules: Rule
}) => {
  let variables: TVariables = {
    id: reportId,
    contacts: contacts.map((c) => c.id),
    groups: groups.map((g) => g.id),
    name,
    notifyOwnerByEmail,
    rules,
  }

  let pests = variables?.rules?.pests ? variables?.rules?.pests : {}
  let pestObject = Object.values(pests)

  if (pests) {
    variables.rules.pests = [
      ...new Set(
        pestObject.map((pest) => ({
          insectId: pest?.insectId,
          degreeDays: pest?.degreeDays,
          trapCatches: pest?.trapCatches,
          endDate: pest?.endDate,
          startDate: pest?.startDate,
        })),
      ),
    ]
  } else {
    variables.rules.pests = []
  }

  return variables
}

export const getTrapReportVariablesForQuery = ({
  reportId, // <-- the presence of 'reportId' will determine if this call will create a new report or modify an existing one
  contacts,
  groups,
  name,
  notifyOwnerByEmail,
  rules,
}: {
  reportId?: string
  contacts: Contact[]
  groups: Group[]
  name: string | null
  notifyOwnerByEmail: boolean
  rules: trapRule
}) => {
  let variables: TTrapVariables = {
    id: reportId,
    contacts: contacts.map((c) => c.id),
    groups: groups.map((g) => g.id),
    name,
    notifyOwnerByEmail,
    rules,
  }

  let pests = variables?.rules?.pests ? variables?.rules?.pests : {}
  let pestObject = Object.values(pests)

  if (pestObject.length > 0) {
    variables.rules.pests = [
      ...new Set(
        pestObject.map((pest) => ({
          insectId: pest?.insectId,
          endDate: pest?.endDate,
          startDate: pest?.startDate,
        })),
      ),
    ]
  } else {
    variables.rules.pests = null
  }

  return variables
}
