import type { TFieldAssetStoreState } from 'stores/fieldAssetStore'

const almondHullSplitCultivarIds = [72, 73, 74, 75, 76, 77, 78, 80, 82, 85, 87, 88]

export const almondCultivarsSelector = (s: TFieldAssetStoreState) => {
  const almondCrop = s.crops ? s.crops[2] : null

  if (almondCrop) {
    const almondCultivarsArray = Object.entries(almondCrop.cultivars).map(([id, name]) => {
      return { id, name }
    })

    return almondCultivarsArray.filter((c) => almondHullSplitCultivarIds.includes(Number(c.id)))
  }

  return []
}
