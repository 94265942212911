import type { TNodeType } from '../types'

type NodeWithType =
  | { nodeType: TNodeType; gatewayType?: never }
  | { nodeType?: never; gatewayType: TNodeType }

type NetworkType = 'SN' | 'DN' | 'LN' | 'EX'

export function getNodeNetworkType(node: NodeWithType | TNodeType): NetworkType {
  let networkType

  if (typeof node === 'string') {
    networkType = node.match(/(dn|ln|ex)_/)
  } else {
    networkType = (node.nodeType || node.gatewayType).match(/(dn|ln|ex)_/)
  }

  if (networkType) {
    return networkType[1].toUpperCase() as NetworkType
  } else {
    return 'SN'
  }
}

export function isDistributedNode(node: NodeWithType | TNodeType): boolean {
  if (typeof node === 'string') {
    return node.startsWith('dn_')
  }

  return (node.nodeType || node.gatewayType).startsWith('dn_')
}

export function isExternalNode(node: NodeWithType | TNodeType): boolean {
  if (!node) return false

  if (typeof node === 'string') {
    return node.startsWith('ex_')
  }

  return (node.nodeType || node.gatewayType).startsWith('ex_')
}

export function isLoraNode(node: NodeWithType | TNodeType): boolean {
  if (typeof node === 'string') {
    return node.startsWith('ln_')
  }

  return (node.nodeType || node.gatewayType).startsWith('ln_')
}

export function isSemiosGatewayType(node: NodeWithType | TNodeType): boolean {
  if (typeof node === 'string') {
    return node.endsWith('gtwy') && !isExternalNode(node)
  }

  return (node.nodeType || node.gatewayType).endsWith('gtwy') && !isExternalNode(node)
}
