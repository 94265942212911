import { Checkbox } from 'components/Checkbox/Checkbox'
import { colors } from 'settings/colors'

const rowWrapperStyle = {
  'alignItems': 'center',
  'border': `1px solid ${colors.grey200}`,
  'display': 'flex',
  'padding': '4px 10px',
  'transition': '0.15s ease all',
  '&:hover': {
    background: colors.subtleBackground,
  },
}

export type TEntity = {
  id: number | string
  name: string
  checked: boolean
}

export const EntityRow = ({
  entity,
  setSelected,
}: {
  entity: TEntity
  setSelected: (selected: boolean) => void
}) => {
  return (
    <div
      css={{
        ...rowWrapperStyle,
        cursor: 'pointer',
        borderTop: undefined,
      }}
      onClick={() => {
        setSelected(!entity.checked)
      }}
    >
      <>
        <Checkbox defaultChecked={entity.checked} css={{ marginRight: 12 }} />
        <div
          css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >{`${entity.name} (${entity.id})`}</div>
      </>
    </div>
  )
}
