import type { GalleryPhoto, Photo } from '@capacitor/camera'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { CapacitorException } from '@capacitor/core'
import { Group, Image, Stack } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { Button } from 'components/Button/Button'
import { IconCamera } from 'components/icons/IconCamera'
import { translate } from 'i18n/i18n'
import React from 'react'
import { createErrorModal } from '../utils/createCommonModals'

interface ImageUploadProps {
  onlyCamera?: boolean
  image?: Photo | GalleryPhoto
  setImage: (image: Photo | GalleryPhoto) => void
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ onlyCamera, image, setImage }) => {
  const handleTakePhoto = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.DataUrl,
      })

      setImage(image)
    } catch (error) {
      handlePermissionsError(error)
    }
  }

  const handleSelectPhoto = async () => {
    try {
      const { photos } = await Camera.pickImages({ limit: 1 })

      setImage(photos[0])
    } catch (error) {
      handlePermissionsError(error)
    }
  }

  const imageSrc = (image as Photo)?.dataUrl || (image as GalleryPhoto)?.webPath

  return (
    <Group position="center">
      <Stack>
        <Image
          src={imageSrc}
          height={'60vh'}
          width={'90vw'}
          fit={'contain'}
          withPlaceholder
          placeholder={
            <span style={{ fontSize: 160 }}>
              <IconCamera />
            </span>
          }
        />
        <Group spacing="xs" position="center">
          <Button variant="primary" onClick={() => handleTakePhoto()}>
            {translate.phrases.placeholder('Use Camera')}
          </Button>
          {!(onlyCamera === true) && (
            <Button variant="secondary" onClick={() => handleSelectPhoto()}>
              {translate.phrases.placeholder('Select Photo')}
            </Button>
          )}
        </Group>
      </Stack>
    </Group>
  )
}

function handlePermissionsError(error: unknown) {
  if (error instanceof CapacitorException) {
    if (error.message.match('denied')) {
      //permission denied
      AboveAllModal.open(
        createErrorModal(translate.phrases.placeholder('Permission required'), (error as Error).message),
      )

      return
    }

    if (error.message.match('cancelled')) {
      //cancelled
      return
    }
  }

  Sentry.captureException(error)

  throw error
}
