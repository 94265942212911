import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { FilterListPopover } from 'components/FilterListPopover/FilterListPopover'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'

export const PermitNumberTitle = ({
  data,
  filterActiveForPermitNumbers,
  filteredPermitNumbers,
  setFilteredPermitNumbers,
}: {
  data: routes.PheromonePurRecDownload.Response['files']
  filterActiveForPermitNumbers: boolean
  filteredPermitNumbers: { [permitNumber: string]: boolean }
  setFilteredPermitNumbers: Dispatch<SetStateAction<{ [permitNumber: string]: boolean }>>
}) => {
  const permitNumbersInData: { [permitNumber: string]: boolean } = data.reduce(
    (a: { [permitNumber: string]: boolean }, b) => {
      a[b.permitNumber] = true

      return a
    },
    {},
  )

  return (
    <div css={{ display: 'flex' }}>
      {translate.phrases.banyanApp('Permit Number')}
      {data && !!data.length && (
        <FilterListPopover
          filterIsActive={filterActiveForPermitNumbers}
          optionsGroups={[
            {
              options: Object.keys(permitNumbersInData)
                .map((permitNumber: string) => ({
                  key: permitNumber,
                  title: permitNumber,
                  checked: !!filteredPermitNumbers[permitNumber],
                  onChange: () =>
                    setFilteredPermitNumbers({
                      ...filteredPermitNumbers,
                      [permitNumber]: !filteredPermitNumbers[permitNumber],
                    }),
                }))
                .sort(sortByKey('title')),
            },
          ]}
          searchPlaceholder={translate.phrases.banyanApp('Search for a permit number')}
          onClear={() => setFilteredPermitNumbers({})}
        />
      )}
    </div>
  )
}
