import { routes, SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { cloneDeep } from 'lodash'
import { apiFetch } from 'utils/apiFetch'
import { getIdentifier } from '../getIdentifier'
import type { NodeDeviceAddOrInstallMeta } from './serviceCenterNodeDeviceInstall'

const request = async (meta: NodeDeviceAddOrInstallMeta): Promise<NodeDeviceAddOrInstallMeta> => {
  await apiFetch<routes.ServiceCenterNodeDeviceAdd.Request, routes.ServiceCenterNodeDeviceAdd.Response>({
    url: routes.ServiceCenterNodeDeviceAdd.path,
    body: meta,
    params: {
      method: 'POST',
    },
  })

  return meta
}

const callback = (response: void, meta: NodeDeviceAddOrInstallMeta) => {
  const { nodeIdentifier, connector, channel } = meta
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const updatedNode = cloneDeep(activeNodes.find((node) => getIdentifier(node) === nodeIdentifier))

  if (updatedNode) {
    if (!updatedNode.devices) updatedNode.devices = {}

    if (!updatedNode.devices[connector]) updatedNode.devices[connector] = {}

    // Add new device to node
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    updatedNode.devices[connector]![channel] = {
      ...meta,
      installationStatus: SharedTypes.NodeDeviceInstallationStatus.INSTALLED,
    }

    serviceCenterStore.actions.updateActiveNode(updatedNode)
  }
}

export const serviceCenterNodeDeviceAdd = {
  request,
  callback,
}
