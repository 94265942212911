import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconControlBattery = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 9 17')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 17"
      fill="currentColor"
    >
      <path
        d="M7.225 1.66667H5.83334V0H2.5V1.66667H1.10833C0.5 1.66667 0 2.16667 0 2.775V5H4.55834L5 4.16667V5H8.33334V2.775C8.33334 2.16667 7.83334 1.66667 7.225 1.66667Z"
        fill="#B4B3BB"
      />
      <path
        d="M5 8.75H6.66667L3.33333 15V10.4167H1.66667L4.55834 5H0V15.5583C0 16.1667 0.5 16.6667 1.10833 16.6667H7.21667C7.83334 16.6667 8.33334 16.1667 8.33334 15.5583V5H5V8.75Z"
        fill="#464B53"
      />
    </svg>
  )
}
