import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconCalendar = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 15 16')}>
      <path
        d="M13.5 1.45455H12.75V0H11.25V1.45455H3.75V0H2.25V1.45455H1.5C0.675 1.45455 0 2.10909 0 2.90909V14.5455C0 15.3455 0.675 16 1.5 16H13.5C14.325 16 15 15.3455 15 14.5455V2.90909C15 2.10909 14.325 1.45455 13.5 1.45455ZM13.5 14.5455H1.5V6.54545H13.5V14.5455ZM13.5 5.09091H1.5V2.90909H13.5V5.09091Z"
        fill="currentColor"
      />
    </svg>
  )
}
