import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { i18n } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { IntercomMethod } from 'react-use-intercom'
import { registeredStores } from 'stores/_utils/persistentStore/persistentStore'
import { apiFetch } from 'utils/apiFetch'
import { appInfo } from './appInfo'

declare global {
  interface Window {
    appInfo: typeof appInfo
    apiFetch: typeof apiFetch
    i18n: typeof i18n
    moment: typeof moment
    Capacitor: {
      getPlatform: () => string
    }
    Intercom: (method: IntercomMethod, ...args: never[]) => void
    stores: typeof registeredStores
    // TODO: Make irrigationSchedulerStore like the other stores
    irrigationSchedulerStore: typeof irrigationSchedulerStore
  }
}

window.appInfo = appInfo

window.apiFetch = apiFetch

window.i18n = i18n

window.moment = moment

window.stores = registeredStores

// TODO: Make irrigationSchedulerStore like the other stores
window.irrigationSchedulerStore = irrigationSchedulerStore
