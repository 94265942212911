import { OfflineRequestError } from 'utils/errorCodes'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import type { NodeRemoveMeta } from './api/serviceCenterNodeRemove'
import { serviceCenterNodeRemove } from './api/serviceCenterNodeRemove'

export async function removeNode(param: NodeRemoveMeta) {
  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.REMOVE_NODE, param)

    if (status === ApiRequestStatus.COMPLETED) return
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      throw error
    }
  }

  serviceCenterNodeRemove.callback(param)
}
