import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconArrowDown = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M17.5 6.25L10 13.75L2.5 6.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
