import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconHistory = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 80 80')}>
      <path
        d="M36.25 18.75C36.25 16.6719 37.9219 15 40 15C42.0781 15 43.75 16.6719 43.75 18.75V38L57.0781 46.875C58.7969 48.0313 59.2656 50.3594 57.9844 52.0781C56.9688 53.7969 54.6406 54.2656 52.9219 52.9844L37.9219 42.9844C36.875 42.4219 36.25 41.25 36.25 39.8594V18.75ZM40 0C62.0937 0 80 17.9063 80 40C80 62.0937 62.0937 80 40 80C17.9063 80 0 62.0937 0 40C0 17.9063 17.9063 0 40 0ZM7.5 40C7.5 57.9531 22.0469 72.5 40 72.5C57.9531 72.5 72.5 57.9531 72.5 40C72.5 22.0469 57.9531 7.5 40 7.5C22.0469 7.5 7.5 22.0469 7.5 40Z"
        fill="currentColor"
      />
    </svg>
  )
}
