import type { NumberInputProps as MantineNumberInputProps } from '@mantine/core'
import { NumberInput } from 'components/NumberInput/NumberInput'
import type { ReactNode } from 'react'
import { useEmitterConfigurationUnit } from '../hooks/useEmitterConfigurationUnit'
import { EmitterConfigurationInputTemplate } from './EmitterConfigurationInputTemplate'

type TEmitterConfigurationNumberInput = Omit<MantineNumberInputProps, 'type'> & {
  label?: string
  unit?: string | ReactNode
  showSpacingPopover?: boolean
}

export const EmitterConfigurationNumberInput = ({
  label,
  unit,
  showSpacingPopover = false,
  ...numberInputProps
}: TEmitterConfigurationNumberInput) => {
  const { precision } = useEmitterConfigurationUnit()

  return (
    <EmitterConfigurationInputTemplate label={label} unit={unit} showSpacingPopover={showSpacingPopover}>
      <NumberInput
        hideControls
        css={{ maxWidth: 210 }}
        precision={precision}
        removeTrailingZeros={true}
        {...numberInputProps}
      />
    </EmitterConfigurationInputTemplate>
  )
}
