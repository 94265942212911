import { LoadingOverlay } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TLngLat } from '@semios/app-platform-banyan-route-definitions/dist/routes/userAppStartup/fieldAssetKeyTypes'
import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { isNil } from '@semios/app-platform-common'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { WindMachineViewDetails } from 'App/Map/PanelDetails/_utils/by-domain/windMachine/WindMachineViewDetailsMenu/WideScreen/WindMachineViewDetails'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { smallStore } from 'stores/smallStore'
import { apiFetch } from 'utils/apiFetch'
import { GroupControlFilters } from './MenuComponents/GroupControlFilters/GroupControlFilters'
import type { TWMDetails } from './types'

const possibleValueTypesToRequest: VV.DomainTypes.WindMachine.TTimeseriesValueTypeKeysMerged[] = [
  'windMachineActivity',
  'windMachineActivityIntervals',
  'windMachineBattery',
  'windMachineCycles',
  'windMachineFuel',
  'windMachineRPM',
  'windMachineRunTime',
  'windMachineSettings',
  'windMachineTemperature',
]

export const WindMachineViewDetailsMenu: FC = () => {
  const opened = smallStore.useSelector((s) => s.showWindMachineViewDetails)
  const [dataDate, setDataDate] = useState<Record<string, string | null>>({})
  const [windMachineStoppedAt, setWindMachineStoppedAt] = useState<Record<string, number | null>>({})
  const [isAuto, setIsAuto] = useState<Record<string, boolean>>({})
  const [wmData, setWmData] = useState<TWMDetails>({} as TWMDetails)
  const [loading, setLoading] = useState(false)

  const wmState = {
    dataDate,
    setDataDate,
    windMachineStoppedAt,
    setWindMachineStoppedAt,
    isAuto,
    setIsAuto,
  }

  const { property } = selectedFieldAssetsStore.useSelector((s) => s)
  const { properties } = fieldAssetStore.getState()

  const selectedLngLats =
    isNil(properties) || isNil(property)
      ? []
      : Object.values(properties[property]?.points || []).reduce((acc: string[], point) => {
          const typesOnPointOfWmType = possibleValueTypesToRequest.filter((valueType) =>
            point.valuesTimeseries.includes(valueType),
          )

          if (typesOnPointOfWmType.length > 0) acc.push(point.lngLat)

          return acc
        }, [])

  const fetchData = async () => {
    setLoading(true)

    try {
      if (isNil(selectedLngLats) || selectedLngLats.length === 0) return

      const res = await apiFetch<routes.WindMachine.Request, routes.WindMachine.Response>({
        url: routes.WindMachine.path,
        body: {
          windMachineDetails: {
            lngLats: selectedLngLats,
          },
        },
      })

      if (res.windMachineDetails !== undefined) {
        const windMachineData = res.windMachineDetails

        setWmData(windMachineData)
      } else {
        return
      }
    } catch (error) {
      return
    }

    setLoading(false)
  }

  useEffect(() => {
    if (opened) fetchData()
  }, [opened])

  useEffect(() => {
    const autoInitialState: Record<string, boolean> = {}
    const dateInitialState: Record<string, string | null> = {}

    if (!isNil(wmData) && !isNil(property)) {
      wmData[property]?.windMachineDetails.forEach((wmDetails) => {
        const key = `POINT(${wmDetails.geom.coordinates[0]} ${wmDetails.geom.coordinates[1]})` as TLngLat

        dateInitialState[key] = wmDetails.equipmentStatus.date

        if (
          wmDetails?.applicationSettingsNew?.status === 'new' &&
          wmDetails?.applicationSettingsNew?.settings?.auto !== undefined
        ) {
          autoInitialState[key] = !!wmDetails?.applicationSettingsNew?.settings?.auto
        } else {
          autoInitialState[key] = !!wmDetails.equipmentStatus.autoMode
        }
      })

      setIsAuto(autoInitialState)

      setDataDate(dateInitialState)
    } else {
      setIsAuto({})

      setDataDate({})
    }
  }, [wmData, property])

  const wmDataForProperty = (!isNil(property) && wmData?.[property]) || ({} as TWindMachineDetailsElement)

  return (
    <div>
      <ModalDrawer
        opened={opened}
        onClose={() => smallStore.setState((s) => ({ ...s, showWindMachineViewDetails: false }))}
        title={translate.phrases.placeholder('Wind Machine Controls')}
        size={'500px'}
        zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
      >
        <LoadingOverlay visible={loading} zIndex={13} />
        <GroupControlFilters content={wmDataForProperty} fetchData={fetchData} />
        <WindMachineViewDetails data={wmDataForProperty} state={wmState} fetchData={fetchData} />
      </ModalDrawer>
    </div>
  )
}
