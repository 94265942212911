import type { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import { makeNameEmitterTypeLabel } from 'utils/makeNameEmitterTypeLabel'
import { sortByKey } from 'utils/sortByKey'

export const getSelectOptionsFromEmitters = (
  emitters: Record<
    TFieldAssetKeyTypes.TIrrigationZoneEmitterId,
    TFieldAssetValueTypes.TIrrigationZoneEmitter
  >,
) => {
  return Object.values(emitters)
    .map((e) => ({ value: e.irrigationZoneEmitterId, label: makeNameEmitterTypeLabel(e) }))
    .sort(sortByKey('label'))
}
