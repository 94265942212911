import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import type { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

type TBeeHoursUnit = 'h'

export const beeHours: Record<
  'beeHours',
  (
    beeHoursAmount?: number | null,
    options?: TUnitConverterOptionsWithUnits<TBeeHoursUnit>,
  ) => TUnitConverterTypicalReturn
> = {
  beeHours: (
    beeHours: number | null = null,
    {
      decimalPlaces,
    }: {
      decimalPlaces?: number
    } = {},
  ) => {
    const defaultNumberOfDecimalPlaces = 0

    let convertedValue: number | null

    if (isNil(beeHours)) {
      convertedValue = null
    } else {
      convertedValue = Number(beeHours)
    }

    const decimalPlacesToUse = decimalPlaces ?? defaultNumberOfDecimalPlaces
    const label = translate.phrases.banyanApp('Bee Hours')
    const unitSymbol = translate.measurements.hours.unit()
    const labelAndUnit = translate.phrases.templates('{{label}} ({{unitSymbol}})', { label, unitSymbol })

    return {
      categoryTitle: () => labelAndUnit,
      categoryTitleWithoutUnit: () => label,
      defaultNumberOfDecimalPlaces: () => defaultNumberOfDecimalPlaces,
      suffix: () => unitSymbol,
      shortTitle: () => label,
      title: () => labelAndUnit,
      titleWithoutUnit: () => label,
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlacesToUse)
      },
      valueAsString: () => translate.measurements.hours.value(convertedValue, decimalPlacesToUse),
      valueWithSuffix: () => translate.measurements.hours.valueWithUnit(convertedValue, decimalPlacesToUse),
      valueWithNoRounding: () => convertedValue,
    }
  },
}
