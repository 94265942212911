import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import {
  DEFAULT_MAX_LOW_STRESS,
  DEFAULT_MIN_HIGH_STRESS,
} from 'App/Map/PanelDetails/StackemCharts/_utils/by-domain/plantStress/PlantWaterStress/plantWaterStress'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { getValueType } from './_utils/getValueType'

const valueGroup = 'plant_stress'
const heatmapExtremesForAllPropertiesInVisibleRegions = false
const pointValueType = 'plantStress_yesterday_signalTS5'

type TPlantStressSetting = { maxLowStress: number; minHighStress: number }

type TPlantStressSettingsByLngLats = Record<string, TPlantStressSetting>

const getPlantStressContainerCss = (value: string | null) => {
  switch (value) {
    case 'HIGH':
      return {
        background: colors.plantStressHigh,
        color: colors.midnight,
      }

    case 'MEDIUM':
      return {
        background: colors.plantStressMedium,
        color: colors.midnight,
      }

    case 'LOW':
      return {
        background: colors.plantStressLow,
        color: colors.midnight,
      }

    default:
      return {
        background: colors.midnight,
        color: colors.white,
      }
  }
}

const getPlantStressSize = (children: string | null) => {
  let size = 40

  if (children?.length && children.length > 3) size = size + (children.length - 3) * 6

  return size
}

const getChildrenText = (children: string | null) => {
  switch (children) {
    case 'LOW':
      return translate.phrases.banyanApp('Low')

    case 'MEDIUM':
      return translate.phrases.banyanApp('Med')

    case 'HIGH':
      return translate.phrases.banyanApp('High')

    default:
      return '-'
  }
}

const getPlantStressValue = (settingsValue: TPlantStressSetting | null, value: number | null) => {
  if (!settingsValue || value === null) return null

  const { maxLowStress, minHighStress } = settingsValue

  if (value < Number(maxLowStress)) return 'LOW'

  if (value > Number(minHighStress)) return 'HIGH'

  return 'MEDIUM'
}

const getPlantStressSettingsForLngLats = async (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
) => {
  let lngLats = processedCaches.stationValues.itemsWithinView.map((station) => station.meta.lngLat as string)

  if (isEmpty(lngLats)) {
    const propertyIds = processedCaches.propertyValues.itemsWithinView.map((property) => Number(property.id))
    const properties = fieldAssetStore.getState().properties

    lngLats = propertyIds.reduce<string[]>((acc, pid) => {
      const propertyPointsObj = properties?.[pid]?.points || {}

      acc = acc.concat(Object.keys(propertyPointsObj).map(String))

      return acc
    }, [])
  }

  const response = await apiFetch<
    routes.FieldAssetSettingsGet.Request,
    routes.FieldAssetSettingsGet.Response
  >({
    url: routes.FieldAssetSettingsGet.path,
    body: {
      plantStress: { lngLats },
    },
  })

  const { plantStress } = response

  if (!plantStress || 'error' in plantStress) return {}

  const plantStressSettingsByLngLats = plantStress.reduce<TPlantStressSettingsByLngLats>(
    (acc, { maxLowStress, minHighStress, pointLnglat }) => {
      acc[pointLnglat] = {
        maxLowStress: maxLowStress ?? DEFAULT_MAX_LOW_STRESS,
        minHighStress: minHighStress ?? DEFAULT_MIN_HIGH_STRESS,
      }

      return acc
    },
    {},
  )

  return plantStressSettingsByLngLats
}

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const plantStressSettingsPromise = getPlantStressSettingsForLngLats(processedCaches)

  const currentValuesPromise = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  const [response, plantStressSettingsByLngLats] = await Promise.all([
    currentValuesPromise,
    plantStressSettingsPromise,
  ])

  const returner: TGetCacheUpdatesFromResponseReturn = {}

  if (!!processedCaches.stationValues.itemsWithinView.length) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.stationValues.itemsWithinView.flatMap(
      (station) => {
        const value = response?.points?.[station.meta.lngLat]?.plantStress_yesterday_signalTS5?.value ?? null
        const settingsValue = plantStressSettingsByLngLats[station.meta.lngLat]
        const plantStressValue = getPlantStressValue(settingsValue, value)

        itemIdsWithinView.push(station.id)

        const children = getChildrenText(plantStressValue)

        return {
          id: String(station.id),
          value: {
            [pointValueType]: {
              children,
              getContainerCSS: () => getPlantStressContainerCss(plantStressValue),
              getSize: () => getPlantStressSize(children),
            },
          },
        }
      },
    )

    returner.stations = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.stationCacheKey,
      itemIdsWithinView,
    }
  }

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.map((property) => {
    return {
      id: String(property.id),
      value: {
        [pointValueType]: {
          children: property.meta.propertyName,
          onHover: false,
          getContainerCSS: () => getPlantStressContainerCss(null),
        },
      },
    }
  })

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  return returner
}
