import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconStar = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 24 24')}>
      <mask
        id="mask0_9296_64083"
        css={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9296_64083)">
        <path
          d="M5.825 22L7.45 14.975L2 10.25L9.2 9.625L12 3L14.8 9.625L22 10.25L16.55 14.975L18.175 22L12 18.275L5.825 22Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
