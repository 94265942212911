import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from '@semios/app-platform-banyan-route-definitions/src/routes/userAppStartup'
import { EAggregationInterval } from '@semios/app-platform-value-type-definitions'
import { AwcLegendItems } from 'components/AwcLegendItems/AwcLegendItems'
import type { StackedChartSectionItem, TChartSeries } from 'components/StackedChart/types'
import { lineChartTooltipFormatter } from 'components/StackedChart/_utils/lineChartTooltipFormatter/lineChartTooltipFormatter'
import type { AxisLabelsFormatterContextObject } from 'highcharts'
import { SOIL_MOISTURE_COLORS_ARRAY } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import { getXDateFormat } from '../../_utils/getXDateFormat'
import { soilPlotOptions } from '../soilPlotOptions'
import { makeAWCZones } from './awcZones'
import { getAwcChartMinMax } from './getAwcChartMinMax'
import { IrrigationEventsLegendItems } from './IrrigationEventsLegendItems'
import { makeSoilSeries } from './makeSoilSeries'

export const makeAwcChart = ({
  data,
  compareSeasonsData,
  soilStationLngLat,
  aggregationInterval,
  availableEmittersForStation,
  chartId,
  compareSeasonsInterval,
  firstForecastTimestamp,
  soilStationFavoriteDepths,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
  soilStationLngLat: string | null
  aggregationInterval: string
  availableEmittersForStation: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]
  chartId: string
  compareSeasonsInterval: number
  firstForecastTimestamp: number
  soilStationFavoriteDepths: routes.UserAppStartup.TFieldAssetValueTypes.TSoilSensorDepths[]
}): StackedChartSectionItem => {
  const soilMoisture = data?.points?.[String(soilStationLngLat)]?.values?.soilMoisture || []

  const soilAwcLineData = makeSoilSeries({
    data,
    compareSeasonsData,
    soilStationLngLat,
    valueType: 'soilMoisture',
    soilStationFavoriteDepths,
  })

  const awcZones =
    compareSeasonsInterval === 0 && soilMoisture[0]
      ? makeAWCZones({ awcData: soilMoisture, forecastStartsAt: firstForecastTimestamp })
      : {
          zonesAWCValues: [],
          linesAWCValues: [],
          forecastedLinesAWCValues: [],
          forecastedZonesAWCValues: [],
        }

  const soilAwcLineSeriesWithZones =
    awcZones?.zonesAWCValues && soilAwcLineData.length > 0
      ? ([
          ...soilAwcLineData,
          ...Object.values(awcZones.zonesAWCValues),
          ...Object.values(awcZones.linesAWCValues),
          ...Object.values(awcZones.forecastedLinesAWCValues),
          ...Object.values(awcZones.forecastedZonesAWCValues),
        ] as TChartSeries[])
      : []

  const { yAxisMinToUse, yAxisMaxToUse } = getAwcChartMinMax({
    compareSeasonsData,
    data,
    soilStationLngLat,
  })

  const showSelectedSoilDepths = detailsPanelStore.getState().showSelectedSoilDepths

  return {
    chartConfig: {
      semiosHighchartsAdditions: {
        id: chartId,
        firstForecastTimestamp,
      },
      chart: {
        type: 'line',
        marginTop: 5,
        // tooltip is too big with compare seasons, make chart bigger to accommodate
        height: compareSeasonsInterval ? 350 : 250,
      },
      colors: SOIL_MOISTURE_COLORS_ARRAY,
      series: soilAwcLineSeriesWithZones,
      plotOptions: soilPlotOptions,
      xAxis: {
        plotBands: [{}],
      },
      tooltip: {
        formatter: function (tooltip) {
          if (!this.x || this.x < firstForecastTimestamp) {
            return lineChartTooltipFormatter(
              this,
              tooltip,
              firstForecastTimestamp,
              // filter out predicted AWC when in the past
              (s) => s.userOptions.id !== 'forecasted-soilMoisture',
            )
          } else {
            return lineChartTooltipFormatter(
              this,
              tooltip,
              firstForecastTimestamp,
              (s) =>
                compareSeasonsInterval > 0 ||
                // filter out average AWC and all depth series when in the future
                (s.userOptions.id !== 'averageSelected-soilMoisture' &&
                  !s.userOptions.id.includes('in-soilMoisture') &&
                  !s.userOptions.id.includes('mm-soilMoisture')),
            )
          }
        },
        xDateFormat: getXDateFormat(aggregationInterval === EAggregationInterval.DAILY),
      },
      yAxis: {
        gridLineColor: 'transparent',
        min: yAxisMinToUse,
        max: yAxisMaxToUse,
        tickInterval: 10,
        labels: {
          formatter: function (this: AxisLabelsFormatterContextObject) {
            if (this.value === 0 || this.value === 50 || this.value === 100 || this.value === 150) {
              return `${this.value}`
            } else {
              return ''
            }
          },
        },
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        enabled: showSelectedSoilDepths,
      },
    },
    childrenUpper: <IrrigationEventsLegendItems emitters={availableEmittersForStation} />,
    childrenLower: awcZones?.zonesAWCValues && (
      <AwcLegendItems
        legendItems={awcZones.zonesAWCValues}
        seriesLegendOn={!!showSelectedSoilDepths}
        displayRootZoneWarning={soilStationFavoriteDepths.length === 0}
      />
    ),
  }
}
