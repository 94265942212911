import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import type {
  TValuesCurrentHeatmapPointsValueTypes,
  TValuesCurrentPointsValueTypes,
} from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { getPrimaryValueGroup } from 'stores/selectedValueGroupsStore/getPrimaryValueGroup'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { usePrimaryValueGroup } from 'utils/usePrimaryValueGroup'

const updateMapVisuals = (
  newValueType: TValuesCurrentPointsValueTypes & TValuesCurrentHeatmapPointsValueTypes,
) => {
  const primaryValueGroup = getPrimaryValueGroup() as TValueGroup

  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.TRAP]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.TRAP],
        [primaryValueGroup]: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.TRAP][primaryValueGroup] ?? { mItem: 'M_Trap' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.BLOCK]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.BLOCK],
        [primaryValueGroup]: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.BLOCK][primaryValueGroup] ?? { mItem: 'M_Property' }),
          valueType: newValueType,
        },
      },
      [MAP_VISUAL.PROPERTY]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.PROPERTY],
        [primaryValueGroup]: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.PROPERTY][primaryValueGroup] ?? { mItem: 'M_Property' }),
          valueType: newValueType,
        },
      },
    },
  }))
}

export const InsectTrapCatchesSecondaryDropdown = () => {
  const primaryValueGroup = usePrimaryValueGroup()

  const { insectId, value } = mapControlsStore.useSelector((s) => ({
    insectId: !primaryValueGroup ? null : Number(primaryValueGroup.replace('trap_catches_insect_id_', '')),
    value: !primaryValueGroup
      ? null
      : s.mapVisualValueGroup?.[MAP_VISUAL.TRAP]?.[primaryValueGroup]?.valueType,
  }))

  const properties = fieldAssetStore.useSelector((s) => s.properties)
  const hasSouthernHemisphereProperties = Object.values(properties ?? {}).some((p) => p.isSouthernHemisphere)
  const hasNorthernHemisphereProperties = Object.values(properties ?? {}).some((p) => !p.isSouthernHemisphere)

  const data: { value: TValuesCurrentPointsValueTypes; label: string }[] = useMemo(() => {
    const returner: { value: TValuesCurrentPointsValueTypes; label: string }[] = [
      {
        value: `insectTrapCatches_todaySum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Today'),
      },
      {
        value: `insectTrapCatches_previous2DaysSum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Last {{count}} Days', { count: 2 }),
      },
      {
        value: `insectTrapCatches_previous3DaysSum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Last {{count}} Days', { count: 3 }),
      },
      {
        value: `insectTrapCatches_previous7DaysSum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Last {{count}} Days', { count: 7 }),
      },
      {
        value: `insectTrapCatches_previous14DaysSum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Last {{count}} Days', { count: 14 }),
      },
      {
        value: `insectTrapCatches_previous30DaysSum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Last {{count}} Days', { count: 30 }),
      },
      {
        value: `insectTrapCatches_previous60DaysSum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Last {{count}} Days', { count: 60 }),
      },
      {
        value: `insectTrapCatches_sinceBiofixSum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Since Biofix'),
      },
    ]

    if (hasNorthernHemisphereProperties) {
      returner.push({
        value: `insectTrapCatches_sinceJan1Sum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Since {{date}}', {
          date: translate.dates.format(moment.tz('UTC').month(0).date(1), 'MMM D'), // Jan 1
        }),
      })
    }

    if (hasSouthernHemisphereProperties) {
      returner.push({
        value: `insectTrapCatches_sinceJul1Sum_insectId${insectId}` as TValuesCurrentPointsValueTypes,
        label: translate.phrases.banyanApp('Since {{date}}', {
          date: translate.dates.format(moment.tz('UTC').month(6).date(1), 'MMM D'), // Jul 1
        }),
      })
    }

    return returner
  }, [insectId, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
