import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { TUnitConverterOptionsWithUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES = 1

type TWetBulbUnit = 'c' | 'f'

type TCanopy = 'ABOVE' | 'IN' | 'BELOW'

const wetBulbFunctionMaker =
  (canopy: TCanopy) =>
  (
    wetBulbTemperature: number | null = null,
    {
      decimalPlaces = DEFAULT_DECIMAL_PLACES,
      inputUnit = 'c',
      outputUnit = userDetailsStore.getState().temperature === 'IMPERIAL' ? 'f' : 'c',
    }: TUnitConverterOptionsWithUnits<TWetBulbUnit> = {},
  ) => {
    let convertedValue: number | null

    const shouldConvertFromCToF = inputUnit === 'c' && outputUnit === 'f'
    const shouldConvertFromFToC = inputUnit === 'f' && outputUnit === 'c'

    if (isNil(wetBulbTemperature)) {
      convertedValue = null
    } else if (shouldConvertFromCToF) {
      convertedValue = wetBulbTemperature * 1.8 + 32
    } else if (shouldConvertFromFToC) {
      convertedValue = (wetBulbTemperature - 32) / 1.8
    } else {
      // assume there is no conversion required
      convertedValue = Number(wetBulbTemperature)
    }

    const unitSymbol =
      outputUnit === 'c'
        ? translate.measurements.degreesCelsius.unit()
        : translate.measurements.degreesFahrenheit.unit()

    return {
      categoryTitle: () =>
        translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Wet Bulb'),
          unitSymbol,
        }),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Wet Bulb'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
      suffix: () => unitSymbol,
      shortTitle: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.banyanApp('Above Canopy')
        }

        if (canopy === 'IN') {
          return translate.phrases.banyanApp('In Canopy')
        }

        return translate.phrases.banyanApp('Below Canopy')
      },
      title: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
            labelA: translate.phrases.banyanApp('Wet Bulb'),
            labelB: translate.phrases.banyanApp('Above Canopy'),
            unitSymbol,
          })
        }

        if (canopy === 'IN') {
          return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
            labelA: translate.phrases.banyanApp('Wet Bulb'),
            labelB: translate.phrases.banyanApp('In Canopy'),
            unitSymbol,
          })
        }

        return translate.phrases.templates('{{labelA}} ({{labelB}}) {{unitSymbol}}', {
          labelA: translate.phrases.banyanApp('Wet Bulb'),
          labelB: translate.phrases.banyanApp('Below Canopy'),
          unitSymbol,
        })
      },
      titleWithoutUnit: () => {
        if (canopy === 'ABOVE') {
          return translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Wet Bulb'),
            labelB: translate.phrases.banyanApp('Above Canopy'),
          })
        }

        if (canopy === 'IN') {
          return translate.phrases.templates('{{labelA}} ({{labelB}})', {
            labelA: translate.phrases.banyanApp('Wet Bulb'),
            labelB: translate.phrases.banyanApp('In Canopy'),
          })
        }

        return translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Wet Bulb'),
          labelB: translate.phrases.banyanApp('Below Canopy'),
        })
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        return outputUnit === 'c'
          ? translate.measurements.degreesCelsius.value(convertedValue, decimalPlaces)
          : translate.measurements.degreesFahrenheit.value(convertedValue, decimalPlaces)
      },
      valueWithSuffix: () => {
        return outputUnit === 'c'
          ? translate.measurements.degreesCelsius.valueWithUnit(convertedValue, decimalPlaces)
          : translate.measurements.degreesFahrenheit.valueWithUnit(convertedValue, decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

type TWetBulbOptions = 'wetBulbAboveCanopy' | 'wetBulbInCanopy' | 'wetBulbBelowCanopy'

export const wetBulb: Record<
  TWetBulbOptions,
  (
    wetBulbTemperature?: number | null,
    options?: TUnitConverterOptionsWithUnits<TWetBulbUnit>,
  ) => TUnitConverterTypicalReturn
> = {
  wetBulbAboveCanopy: wetBulbFunctionMaker('ABOVE'),
  wetBulbInCanopy: wetBulbFunctionMaker('IN'),
  wetBulbBelowCanopy: wetBulbFunctionMaker('BELOW'),
}
