import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconVP4 = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 25 18')}>
      <path d="M2.50037 6.00006H22.5002" stroke="currentColor" strokeWidth="2" />
      <ellipse cx="12.4998" cy="3.60006" rx="3.74997" ry="3.6" fill="currentColor" />
      <ellipse cx="3.74991" cy="6.00002" rx="3.74997" ry="3.6" fill="currentColor" />
      <ellipse cx="21.25" cy="6.00002" rx="3.74997" ry="3.6" fill="currentColor" />
      <rect x="11.2494" y="6.00006" width="2.49998" height="12" fill="currentColor" />
    </svg>
  )
}
