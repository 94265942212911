import { css } from '@emotion/css'
import { Notification, Text, useMantineTheme } from '@mantine/core'
import type { FC } from 'react'
import { colors } from 'settings/colors'
import { WarningIcon } from '../WarningIcon'

type ErrorToastProps = {
  showErrorNotification: boolean
  onErrorClose: () => void
  errorText: string
}

export const ErrorToast: FC<ErrorToastProps> = ({ showErrorNotification, errorText, onErrorClose }) => {
  const theme = useMantineTheme()

  return showErrorNotification ? (
    <div
      className={css`
        z-index: 9999;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0px;
        right: 0px;
        margin-bottom: 20px;
      `}
    >
      <Notification
        color="red"
        className={css`
          background-color: ${theme.colors.midnight[0]};
          max-width: 480px;
          margin-left: 20px;
          margin-right: 20px;
        `}
        radius="md"
        icon={
          <div onClick={onErrorClose}>
            <WarningIcon />
          </div>
        }
        withCloseButton={false}
      >
        <Text size={14} color={colors.white}>
          {errorText}
        </Text>
      </Notification>
    </div>
  ) : null
}
