import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { getErrorStyle } from 'App/Map/UserSettingsMenu/Shared/Alerts/_utils/getErrorStyle'
import { ErrorText } from 'components/ErrorText/ErrorText'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { getConvertedWaterVolume } from 'utils/getConvertedWaterVolume'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'
import { useScreenSize } from 'utils/useScreenSize'
import { EmitterConfigurationNumberInput } from '../../../components/EmitterConfigurationNumberInput'
import type { ModifiedZone } from '../../../EmitterConfiguration'
import { useEmitterConfigurationUnit } from '../../../hooks/useEmitterConfigurationUnit'

type TEmitterConfigurationZoneDetail = {
  id: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
  flowRate: number
  unitPerHour: string
  emitterType: TFieldAssetKeyTypes.TEmitterType
  zoneId: number
  modifiedZones: ModifiedZone[]
  setModifiedZones: Dispatch<SetStateAction<ModifiedZone[]>>
  propertyId: number
}

export const EmitterConfigurationZoneDetail = ({
  id,
  flowRate,
  unitPerHour,
  emitterType,
  zoneId,
  modifiedZones,
  setModifiedZones,
  propertyId,
}: TEmitterConfigurationZoneDetail) => {
  const { isWideScreen } = useScreenSize()
  const { unit, dbUnitPerHour, precision } = useEmitterConfigurationUnit()

  const convertedFlowRateValue =
    getConvertedWaterVolume({
      flowUnitPerHour: unitPerHour,
      volumeUnitToDisplay: dbUnitPerHour,
      waterApplied: flowRate,
      decimalPlacesToRound: precision,
    }) || flowRate

  const [numberValue, setNumberValue] = useState<number | ''>(convertedFlowRateValue)

  // Update the default value when the flow rate changes from bulk edit
  useEffect(() => {
    setNumberValue(convertedFlowRateValue)
  }, [convertedFlowRateValue])

  useEffect(() => {
    if (!modifiedZones.find((zone) => zone.id === id)) {
      setNumberValue(convertedFlowRateValue)
    }
  }, [modifiedZones])

  const errorStyle = getErrorStyle()
  const isValid = Number(numberValue) > 0

  return (
    <>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div>{mapApiEmitterTypeToLabel(emitterType)}</div>
        </div>
        <div>
          <EmitterConfigurationNumberInput
            unit={unit}
            value={numberValue}
            css={{ width: isWideScreen ? 140 : 70 }}
            onChange={(value) => {
              setNumberValue(value)

              if (value !== convertedFlowRateValue) {
                const newModifiedZone = {
                  emitterType,
                  id,
                  zoneId,
                  propertyId,
                  flowRate: Number(value),
                }

                setModifiedZones((prev) => {
                  const otherZones = prev.filter((zone) => zone.id !== id)

                  return [...otherZones, newModifiedZone]
                })
              } else {
                setModifiedZones((prev) => prev.filter((zone) => zone.id !== id))
              }
            }}
            classNames={{ input: !isValid ? errorStyle : undefined }}
          />
        </div>
      </div>
      {!isValid && (
        <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
          <ErrorText>{translate.phrases.banyanApp('Value must be greater than 0')}</ErrorText>
        </div>
      )}
    </>
  )
}
