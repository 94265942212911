import { Text } from '@mantine/core'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import { DeviceInstallationStatus } from 'App/ServiceCenter/DevicesManagement/DevicesList/DeviceInstallationStatus/DeviceInstallationStatus'
import { useLnrNodeStatus } from 'App/ServiceCenter/DevicesManagement/useLnrNodeStatus'
import type { EquipmentActivationPayload } from 'App/ServiceCenter/NodeInstallation/types'
import type { TNodeDeviceChannels, TNodeDevicePort } from 'App/ServiceCenter/types'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { NodeSummary } from '../../Overview/NodeSummary/NodeSummary'

export const ConnectionStatus: React.FC<{
  payload: Partial<EquipmentActivationPayload>
  isBleRequired: boolean
  footer: React.ReactElement
}> = ({
  payload,
  isBleRequired,
  footer,
}: {
  payload: Partial<EquipmentActivationPayload>
  isBleRequired: boolean
  footer: React.ReactElement
}) => {
  const { nodeIdentifier, nodeType, devices: nodeDevices } = payload
  const allDeviceModels = fieldAssetStore.useSelector((s) => s.devices)

  const allDeviceSources = Object.entries(nodeDevices || {}).flatMap(
    ([connector, value]: [string, TNodeDeviceChannels]) => {
      return Object.entries(value).map(([chan, device]) => {
        return {
          connector: connector as TNodeDevicePort,
          channel: chan,
          installationStatus: device.installationStatus,
          source: device.source,
        }
      })
    },
  )

  const { adapterStatus, loraNetworkStatus, isChecking, requestCheck, getDeviceStatus } = useLnrNodeStatus(
    isBleRequired,
    payload.nodeIdentifier || '',
  )

  const connectionStatus = isBleRequired ? { bleStatus: adapterStatus, loraNetworkStatus } : undefined

  return (
    <div css={{ padding: '10px' }}>
      <NodeSummary nodeId={nodeIdentifier} nodeType={nodeType} connectionStatus={connectionStatus}>
        <>
          {nodeDevices && allDeviceSources && (
            <>
              <h4 css={{ marginBottom: 10, fontWeight: 500 }}>{translate.phrases.placeholder('Devices')}</h4>
              <div css={{ lineHeight: '24px' }}>
                {allDeviceSources.map((device) => (
                  <div
                    key={`${device.connector}-${device.channel}`}
                    css={{
                      marginTop: '8px',
                      marginBottom: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>{allDeviceModels[device.source]?.name || device.source}</Text>
                    <DeviceInstallationStatus
                      status={getDeviceStatus(device.connector, parseInt(device.channel), device)}
                    />
                  </div>
                ))}
                {adapterStatus === AdapterStatus.CONNECTED && (
                  <Button
                    css={{ width: '100%' }}
                    variant="tertiary"
                    onClick={requestCheck}
                    loading={isChecking}
                  >
                    {translate.phrases.placeholder('Re-Scan Connections')}
                  </Button>
                )}
              </div>
              <p>{allDeviceSources.length === 0 && translate.phrases.placeholder('No devices')}</p>
            </>
          )}
        </>
      </NodeSummary>
      {footer}
    </div>
  )
}
