import type { TChartSeries } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { alphaColorReplacer, colors } from 'settings/colors'
import { getAwcPoints } from './getAwcPoints'

const {
  zoneBorderLine,
  idealSaturatedBoundary,
  awcZoneSaturated,
  awcZoneIdeal,
  awcZoneDeficitModerate,
  awcZoneDeficitHigh,
  awcZoneDeficitExtreme,
} = colors

export const makeAWCZones = ({
  awcData,
  forecastStartsAt,
}: {
  awcData: {
    metadata: {
      depth: string
      MADDates: { startDate: string; soilMoisture: number }[]
    }
    timeseries: { timestamp: string }[]
  }[]
  forecastStartsAt: number
}): Record<string, (TChartSeries & { fillColor: string })[]> => {
  const {
    mappedSaturatedPoints,
    mappedExtremeDeficitPoints,
    mappedIdealPoints,
    mappedModerateDeficitPoints,
    mappedHighDeficitPoints,
    forecastedMappedSaturatedPoints,
    forecastedMappedExtremeDeficitPoints,
    forecastedMappedIdealPoints,
    forecastedMappedModerateDeficitPoints,
    forecastedMappedHighDeficitPoints,
  } = getAwcPoints(awcData, forecastStartsAt)

  const commonProperties = {
    fillColor: 'black',
    enableMouseTracking: false,
    name: '',
    visible: true,
    yAxis: 0,
    isADepthSeries: false,
    rainSeries: false,
    showInLegend: false,
    hideFromTooltip: true,
    states: {
      // don't fade out these series when hovering over the chart
      inactive: {
        opacity: 1,
      },
    },
  }

  const linesAWC = {
    idealSaturated: {
      ...commonProperties,
      color: idealSaturatedBoundary,
      dashStyle: 'solid',
      data: mappedSaturatedPoints,
      lineWidth: 2,
      // Make spline series to make the line solid when in the forecast zone
      // while keeping other series as dashed
      type: 'spline',
      width: 4,
      zIndex: 2,
      zoneAxis: 'x',
      id: 'idealSaturatedLineAWC',
    },
    idealSaturatedHighlight: {
      ...commonProperties,
      color: zoneBorderLine,
      dashStyle: 'solid',
      data: mappedSaturatedPoints,
      lineWidth: 3.25,
      // Make spline series to make the line solid when in the forecast zone
      // while keeping other series as dashed
      type: 'spline',
      width: 4,
      zIndex: 1,
      zoneAxis: 'x',
      id: 'idealSaturatedHighlightLineAWC',
    },
  }

  const zonesAWC = {
    avg: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Root Zone Avg'),
      color: 'black',
      fillColor: 'black',
      dashStyle: 'solid',
      data: [],
      lineWidth: 0,
      threshold: 0,
      type: 'line',
      width: 4,
      zIndex: 0,
      zoneAxis: 'x',
      id: 'avgZoneAWC',
    },
    saturated: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Saturated'),
      color: zoneBorderLine,
      fillColor: awcZoneSaturated,
      data: mappedSaturatedPoints,
      lineWidth: 0,
      threshold: Infinity,
      type: 'area',
      width: 4,
      zIndex: 0,
      zoneAxis: 'x',
      id: 'saturatedZoneAWC',
    },
    ideal: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Ideal'),
      color: zoneBorderLine,
      fillColor: awcZoneIdeal,
      data: mappedIdealPoints,
      lineWidth: 0.25,
      type: 'arearange',
      zIndex: 0,
      id: 'idealZoneAWC',
    },
    moderateDeficit: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Moderate Deficit'),
      color: zoneBorderLine,
      fillColor: awcZoneDeficitModerate,
      data: mappedModerateDeficitPoints,
      lineWidth: 0,
      type: 'arearange',
      zIndex: 0,
      id: 'moderateDeficitZoneAWC',
    },
    highDeficit: {
      ...commonProperties,
      name: translate.phrases.banyanApp('High Deficit'),
      color: zoneBorderLine,
      fillColor: awcZoneDeficitHigh,
      data: mappedHighDeficitPoints,
      lineWidth: 0.25,
      type: 'arearange',
      visible: true,
      zIndex: 0,
      id: 'highDeficitZoneAWC',
    },
    extremeDeficit: {
      ...commonProperties,
      name: translate.phrases.banyanApp('Extreme Deficit'),
      color: zoneBorderLine,
      fillColor: awcZoneDeficitExtreme,
      data: mappedExtremeDeficitPoints,
      lineWidth: 0.25,
      threshold: -Infinity,
      type: 'area',
      zIndex: 0,
      zoneAxis: 'x',
      id: 'extremeDeficitZoneAWC',
    },
  }

  const forecastedLinesAWC = {
    idealSaturated: {
      ...linesAWC.idealSaturated,
      data: forecastedMappedSaturatedPoints,
      fillColor: zoneBorderLine,
      id: 'idealSaturatedLineAWCForecasted',
    },
    idealSaturatedHighlight: {
      ...linesAWC.idealSaturatedHighlight,
      fillColor: zoneBorderLine,
      data: forecastedMappedSaturatedPoints,
      id: 'idealSaturatedHighlightLineAWCForecasted',
    },
  }

  const forecastedZonesAWC = {
    saturated: {
      ...zonesAWC.saturated,
      data: forecastedMappedSaturatedPoints,
      fillColor: alphaColorReplacer(awcZoneSaturated, 0.6),
      id: 'saturatedZoneAWCForecasted',
    },
    ideal: {
      ...zonesAWC.ideal,
      data: forecastedMappedIdealPoints,
      fillColor: alphaColorReplacer(awcZoneIdeal, 0.6),
      id: 'idealZoneAWCForecasted',
    },
    moderateDeficit: {
      ...zonesAWC.moderateDeficit,
      data: forecastedMappedModerateDeficitPoints,
      fillColor: alphaColorReplacer(awcZoneDeficitModerate, 0.6),
      id: 'moderateDeficitZoneAWCForecasted',
    },
    highDeficit: {
      ...zonesAWC.highDeficit,
      data: forecastedMappedHighDeficitPoints,
      fillColor: alphaColorReplacer(awcZoneDeficitHigh, 0.6),
      id: 'highDeficitZoneAWCForecasted',
    },
    extremeDeficit: {
      ...zonesAWC.extremeDeficit,
      data: forecastedMappedExtremeDeficitPoints,
      fillColor: alphaColorReplacer(awcZoneDeficitExtreme, 0.6),
      id: 'extremeDeficitZoneAWCForecasted',
    },
  }

  const zonesAWCValues = Object.values(zonesAWC) as (TChartSeries & { fillColor: string })[]
  const linesAWCValues = Object.values(linesAWC) as (TChartSeries & { fillColor: string })[]

  const forecastedZonesAWCValues = Object.values(forecastedZonesAWC) as (TChartSeries & {
    fillColor: string
  })[]

  const forecastedLinesAWCValues = Object.values(forecastedLinesAWC) as (TChartSeries & {
    fillColor: string
  })[]

  return { zonesAWCValues, linesAWCValues, forecastedZonesAWCValues, forecastedLinesAWCValues }
}
