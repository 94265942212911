import { NumberInput } from '@mantine/core'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { AlertsValidators } from '../../../../AlertValidators/AlertValidators'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { InfoPopover } from '../../../../components/InfoPopover'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import type { Rule } from '../../../../settings/alertSettings'
import { AlertsSettings } from '../../../../settings/alertSettings'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'

export const TrapCatchesThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.trapCatches)
  })

  const { trapCatches: trapCatchesRule, numberOfDays: numberOfDaysRule } = alertRulesObjectFromArray(rules)
  const trapCatches = trapCatchesRule ? trapCatchesRule.value : null
  const numberOfDays = numberOfDaysRule ? numberOfDaysRule.value : null
  // check if user-enterable fields are dirty
  const trapCatchesIsDirty = trapCatches !== null || triedToSubmit
  const numberOfDaysIsDirty = numberOfDays !== null || triedToSubmit
  // validate the user-entered values
  const trapCatchesError = AlertsValidators.trapCatches({ trapCatches })
  const numberOfDaysError = AlertsValidators.numberOfDays({ numberOfDays })
  // attach a status
  const trapCatchesValidateStatus = validateStatusErrorHelper(trapCatchesIsDirty && !!trapCatchesError)
  const numberOfDaysValidateStatus = validateStatusErrorHelper(numberOfDaysIsDirty && !!numberOfDaysError)
  // attach an error message
  const trapCatchesHelp = helpTextErrorHelper(trapCatchesIsDirty && trapCatchesError)
  const numberOfDaysHelp = helpTextErrorHelper(numberOfDaysIsDirty && numberOfDaysError)
  const errorStyle = getErrorStyle()

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!trapCatchesError && !numberOfDaysError)
  }, [trapCatches, numberOfDays])

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when trap catches are greater than or equal to <numberInput1/> summing up the last <numberInput2/> days"
          c={{
            numberInput1: (
              <TranslateComponentWrapper>
                <NumberInput
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { trapCatches: { value } } })
                  }
                  placeholder={translate.phrases.banyanApp('e.g. {{example}}', { example: 3 })}
                  css={{ width: 80, margin: '0 5px' }}
                  type="number"
                  classNames={{ input: trapCatchesValidateStatus ? errorStyle : undefined }}
                  value={trapCatches ? Number(trapCatches) : ''}
                />
                <div />
              </TranslateComponentWrapper>
            ),
            numberInput2: (
              <TranslateComponentWrapper>
                <NumberInput
                  onChange={(value) =>
                    updateAlertRules({ rules, setRules, rulesToChange: { numberOfDays: { value } } })
                  }
                  placeholder={translate.phrases.banyanApp('e.g. {{example}}', { example: 7 })}
                  css={{ width: 80, margin: '0 5px' }}
                  type="number"
                  classNames={{ input: numberOfDaysValidateStatus ? errorStyle : undefined }}
                  value={numberOfDays ? Number(numberOfDays) : ''}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
        <InfoPopover
          content={
            <div>
              {translate.phrases.banyanApp('Only trap counts on or after Jan 1 of this year are evaluated')}
            </div>
          }
        />
      </TranslatePhraseWrapper>
      <div>
        {trapCatchesHelp && <ErrorTextWrapper>{trapCatchesHelp}</ErrorTextWrapper>}
        {numberOfDaysHelp && <ErrorTextWrapper>{numberOfDaysHelp}</ErrorTextWrapper>}
      </div>
    </>
  )
}
