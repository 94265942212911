import { Capacitor } from '@capacitor/core'

const numberToSemiosUUID = (value: number): string => {
  return `85fa${value.toString(16).padStart(4, '0')}-75e7-4361-b35f-9074c1ad985c`
}

function timeout<T>(ms: number, errorMessage = 'Operation timed out'): Promise<T> {
  return new Promise((_, reject) => setTimeout(() => reject(new Error(errorMessage)), ms))
}

function withTimeout<T>(promise: Promise<T>, ms: number): Promise<T> {
  return Promise.race<T>([promise, timeout<T>(ms)])
}

const isBleSupported = () => {
  return Capacitor.isNativePlatform()
}

function emptyPayload(): DataView {
  //At least 1 byte is required for the payload
  const buffer = new ArrayBuffer(1)
  const view = new DataView(buffer)

  view.setInt8(0, 0)

  return new DataView(buffer)
}

export { numberToSemiosUUID, withTimeout, isBleSupported, emptyPayload }
