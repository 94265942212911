import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconThumbDown = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 30 30')}>
      <path
        d="M10.2928 8.52374H17.571C18.4605 8.52374 19.1883 9.26579 19.1883 10.1727V18.4176C19.1883 18.8711 19.0104 19.2834 18.7193 19.5802L13.3901 25.0136L12.5329 24.1479C12.3145 23.9253 12.177 23.6119 12.177 23.2739L12.2013 23.0101L12.9695 19.2421H7.86676C6.9772 19.2421 6.24939 18.5001 6.24939 17.5932V15.9442C6.24939 15.7298 6.28983 15.5319 6.3626 15.3423L8.80483 9.52962C9.04743 8.93599 9.6216 8.52374 10.2928 8.52374Z"
        fill="#5C5C5C"
      />
      <path d="M20.8057 18.4176V8.52374H24.0404V18.4176H20.8057Z" fill="#5C5C5C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM29.25 15C29.25 22.8701 22.8701 29.25 15 29.25C7.12994 29.25 0.75 22.8701 0.75 15C0.75 7.12994 7.12994 0.75 15 0.75C22.8701 0.75 29.25 7.12994 29.25 15Z"
        fill="#5C5C5C"
      />
    </svg>
  )
}
