import type { FC } from 'react'
import { colors } from 'settings/colors'
import type { TSettingItemProps } from '../../types'

export const SettingItem: FC<TSettingItemProps> = ({
  tabKey,
  setCurrentTab,
  isSelected,
  label,
  isSubItem = false,
}) => {
  return (
    <div
      css={{
        'cursor': 'pointer',
        'margin': '0 0 0 15px',
        'height': 45,
        'display': 'flex',
        'alignItems': 'center',
        'borderRadius': '3px 0 0 3px',
        '&:hover': {
          backgroundColor: colors.grey50,
        },
        'backgroundColor': isSelected ? colors.grey50 : 'white',
        'color': colors.midnight,
        'fontSize': 16,
        'fontWeight': isSelected ? '700' : '500',
        'paddingLeft': isSubItem ? 30 : 10,
      }}
      onClick={() => {
        setCurrentTab(tabKey)
      }}
    >
      {label}
    </div>
  )
}
