import type { MenuItemProps } from '@mantine/core'
import { Menu, UnstyledButton } from '@mantine/core'
import { IconHorizontalDotMenu } from 'components/icons/IconHorizontalDotMenu'

type TActionButtonProps = {
  menuItems: MenuItemProps[]
}

export const ActionButton = (props: TActionButtonProps) => {
  if (props.menuItems.length === 0) return <></>

  return (
    <div>
      <Menu width={200} shadow="md">
        <Menu.Target>
          <UnstyledButton>
            <IconHorizontalDotMenu />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          {props.menuItems.map((item, i) => (
            <Menu.Item key={i} {...item} />
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
