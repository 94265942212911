import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { useScreenOrientation } from 'stores/smallStore'
import type { TSelectedTab } from '../../stores/detailsPanelStore'
import { useScreenSize } from '../../utils/useScreenSize'
import BottomPanel from './Bottom/Bottom'
import RightPanel from './Right/Right'

export interface TabPane {
  key: TSelectedTab
  label: string
  icon: React.ReactNode
}

type DetailPanelProps = {
  children: ({
    width,
    height,
    isPanelExpanded,
  }: {
    width: number
    height: number
    isPanelExpanded: boolean
  }) => React.ReactNode
  tabs?: TabPane[]
  wrapperId?: string
}

export const DetailPanel: FC<DetailPanelProps> = ({ children, wrapperId, tabs }) => {
  const { isWideScreen, screenWidth, screenHeight } = useScreenSize()
  const [isPanelExpanded, setIsPanelExpanded] = useState(false)
  const screenOrientation = useScreenOrientation()

  let PanelToUse = screenOrientation === 'landscape' ? RightPanel : BottomPanel

  //TODO, do we still need this?
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // Fix an issue with Mantine's useViewportSize hook not updating the viewport size after the keyboard is hidden on iOS
      const keyboardDidHide = Keyboard.addListener('keyboardDidHide', () => {
        window.dispatchEvent(new Event('resize'))
      })

      return () => {
        keyboardDidHide.remove()
      }
    }
  }, [])

  return (
    <div css={{ position: 'fixed', zIndex: 3 }} id={wrapperId}>
      <PanelToUse
        viewportSize={{ height: screenHeight, width: screenWidth }}
        allowDragContentForTouch={false}
        handleSize={isWideScreen ? 125 : 60}
        backgroundColor={colors.white}
        debugShowSnapPointsOnDrag={false}
        isPanelExpanded={isPanelExpanded}
        setIsPanelExpanded={setIsPanelExpanded}
        tabs={tabs}
      >
        {children}
      </PanelToUse>
    </div>
  )
}
