import type { TFieldAssetKeyTypes, TValuesCurrent, TValuesTimeseries } from 'App/Map/types'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const doesPointHaveValueType = ({
  propertyId,
  lngLat,
  currentValueToCheck,
  timeseriesValueToCheck,
}: {
  propertyId: TFieldAssetKeyTypes.TPropertyId
  lngLat: TFieldAssetKeyTypes.TLngLat
  currentValueToCheck?: TValuesCurrent
  timeseriesValueToCheck?: TValuesTimeseries
}): boolean => {
  const point = fieldAssetStore.getState()?.properties?.[propertyId]?.points?.[lngLat]

  if (!point) return false

  if (currentValueToCheck) {
    return point.valuesCurrent.includes(currentValueToCheck)
  }

  if (timeseriesValueToCheck) {
    return point.valuesTimeseries.includes(timeseriesValueToCheck)
  }

  return false
}

export const doesBlockHaveValueType = ({
  propertyId,
  blockId,
  currentValueToCheck,
  timeseriesValueToCheck,
}: {
  propertyId: TFieldAssetKeyTypes.TPropertyId
  blockId: TFieldAssetKeyTypes.TBlockId
  currentValueToCheck?: TValuesCurrent
  timeseriesValueToCheck?: TValuesTimeseries
}): boolean => {
  const block = fieldAssetStore.getState()?.properties?.[propertyId].blocks?.[blockId]

  if (!block) return false

  if (currentValueToCheck) {
    return block.valuesCurrent.includes(currentValueToCheck)
  }

  if (timeseriesValueToCheck) {
    return block.valuesTimeseries.includes(timeseriesValueToCheck)
  }

  return false
}

export const doesPropertyHaveValueType = ({
  propertyId,
  currentValueToCheck,
  timeseriesValueToCheck,
}: {
  propertyId: TFieldAssetKeyTypes.TPropertyId
  currentValueToCheck?: TValuesCurrent
  timeseriesValueToCheck?: TValuesTimeseries
}): boolean => {
  const property = fieldAssetStore.getState()?.properties?.[propertyId]

  if (!property) return false

  if (currentValueToCheck) {
    return property.valuesCurrent.includes(currentValueToCheck)
  }

  if (timeseriesValueToCheck) {
    return property.valuesTimeseries.includes(timeseriesValueToCheck)
  }

  return false
}
