import type {
  EVENT_ID,
  ISOString,
  NOTE,
  NOTE_ID,
  TIrrigationSchedulerStoreState,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import {
  getInitialState,
  irrigationSchedulerStore,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import type { TFieldAssetKeyTypes, TIrrigationDeviceStatus } from 'App/Map/types'
import { eventsCreate } from './eventsCreate'
import { eventsGet } from './eventsGet'
import { eventsUpdateAndOrDelete } from './eventsUpdateAndOrDelete'
import { launch } from './launch'
import { pulseEventsCreate } from './pulseEventsCreate'
import { pulseEventsDelete } from './pulseEventsDelete'
import { pulseEventsUpdate } from './pulseEventsUpdate'
import { selectScheduledEventsForBatchDelete } from './selectScheduledEventsForBatchDelete'
import { setDateView } from './setDateView'
import { setSelectedPropertyIdAndClearSelectedIrrigationZoneEmitterIds } from './setSelectedPropertyIdAndClearSelectedIrrigationZoneEmitterIds'
import { toggleEventForBatchDelete } from './toggleEventForBatchDelete'
import { togglePulseEventForBatchDelete } from './togglePulseEventForBatchDelete'
import { updateMostRecentApiResponseTimestamp } from './updateMostRecentApiResponseTimestamp'

const closeRightPanel: Pick<
  TIrrigationSchedulerStoreState,
  | 'eventCreate'
  | 'eventPanel'
  | 'pulseEventPanel'
  | 'showPublishScheduleConfirmation'
  | 'noteCreate'
  | 'noteEdit'
> = {
  eventCreate: null,
  eventPanel: null,
  pulseEventPanel: null,
  showPublishScheduleConfirmation: false,
  noteCreate: null,
  noteEdit: null,
}

export const actions = {
  main: {
    openIrrigationScheduler: () => {
      irrigationSchedulerStore.setState((s) => ({ ...s, visible: true }))
    },
    closeIrrigationScheduler: () => {
      irrigationSchedulerStore.setState(() => getInitialState())
    },
    closeRightPanel: () => {
      irrigationSchedulerStore.setState((s) => ({
        ...s,
        ...closeRightPanel,
      }))
    },
    setShowLauncher: (showLauncher: boolean) => {
      irrigationSchedulerStore.setState((s) => ({ ...s, showLauncher }))
    },
    launch,
    setSelectedPropertyIdAndClearSelectedIrrigationZoneEmitterIds,
    setSelectedIrrigationZoneEmitterIds: (
      irrigationZoneEmitterIds: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[],
    ) => {
      irrigationSchedulerStore.setState((s) => ({
        ...s,
        selectedIrrigationZoneEmitterIds: irrigationZoneEmitterIds,
      }))
    },
    showPeakHours: (show: boolean) => {
      irrigationSchedulerStore.setState((s) => ({ ...s, showPeakHours: show }))
    },
    setDateFrom: (fn: (s: TIrrigationSchedulerStoreState) => ISOString) => {
      irrigationSchedulerStore.setState((s) => {
        return { ...s, dateFrom: fn(s) }
      })
    },
    setStoppedIrrigationZoneEmitters: (
      items: Array<{
        irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
        timestamp: ISOString
      }>,
    ) => {
      irrigationSchedulerStore.setState((s) => {
        return {
          ...s,
          stoppedIrrigationZoneEmitters: items.reduce(
            (acc, { irrigationZoneEmitterId, timestamp }) => ({
              ...acc,
              [irrigationZoneEmitterId]: timestamp,
            }),
            {},
          ),
        }
      })
    },
    setEmitterStatus: (
      items: Array<{
        irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
        currentEmitterStatus: TIrrigationDeviceStatus
      }>,
    ) => {
      irrigationSchedulerStore.setState((s) => {
        return {
          ...s,
          currentEmitterStatus: items.reduce(
            (acc, { irrigationZoneEmitterId, currentEmitterStatus }) => ({
              ...acc,
              [irrigationZoneEmitterId]: currentEmitterStatus,
            }),
            {},
          ),
        }
      })
    },
  },
  scheduledEventPanel: {
    openScheduledEventFormCreate: () => {
      irrigationSchedulerStore.setState((s) => ({
        ...s,
        ...closeRightPanel,
        eventCreate: {},
      }))
    },
    openEventPanel: ({ eventId }: { eventId: EVENT_ID }) => {
      irrigationSchedulerStore.setState((s) => ({
        ...s,
        ...closeRightPanel,
        eventPanel: { eventId },
      }))
    },
    openPulseEventPanel: ({ pulseEventId }: { pulseEventId: EVENT_ID }) => {
      irrigationSchedulerStore.setState((s) => ({
        ...s,
        ...closeRightPanel,
        pulseEventPanel: { pulseEventId },
      }))
    },
  },
  publishPanel: {
    open: () => irrigationSchedulerStore.setState((s) => ({ ...s, showPublishScheduleConfirmation: true })),
  },
  notes: {
    openPanelForEdit: (noteId: NOTE_ID) =>
      irrigationSchedulerStore.setState((s) => ({
        ...s,
        ...closeRightPanel,
        noteEdit: { id: noteId },
      })),
    openPanelForCreate: (irrigationZoneEmitterId: TFieldAssetKeyTypes.TIrrigationZoneEmitterId) =>
      irrigationSchedulerStore.setState((s) => ({
        ...s,
        ...closeRightPanel,
        noteCreate: { irrigationZoneEmitterId },
      })),
    createNote: (note: NOTE) => {
      irrigationSchedulerStore.setState((s) => {
        return { ...s, notes: { ...s.notes, [note.id]: note } }
      })
    },
    editNote: (o: { noteId: NOTE_ID; noteText: string }) => {
      irrigationSchedulerStore.setState((s) => {
        return { ...s, notes: { ...s.notes, [o.noteId]: { ...s.notes[o.noteId], note: o.noteText } } }
      })
    },
    deleteNote: (noteId: NOTE_ID) => {
      irrigationSchedulerStore.setState((s) => {
        const newState = { ...s }

        delete newState.notes[noteId]

        return newState
      })
    },
  },
  batchDeleteMode: {
    deselectAllEventsMarkedForBatchDelete: () => {
      irrigationSchedulerStore.setState((s) => {
        return {
          ...s,
          batchDelete: {
            ...s.batchDelete,
            events: {},
          },
        }
      })
    },
    openBatchDeleteMode: () => {
      irrigationSchedulerStore.setState((s) => ({ ...s, batchDelete: { visible: true, events: {} } }))
    },
    closeBatchDeleteMode: () => {
      irrigationSchedulerStore.setState((s) => ({ ...s, batchDelete: { visible: false, events: {} } }))
    },
    selectScheduledEventsForBatchDelete,
    toggleEventForBatchDelete,
    togglePulseEventForBatchDelete,
  },
  api: {
    eventsCreate,
    eventsGet,
    eventsUpdateAndOrDelete,
    pulseEventsCreate,
    pulseEventsUpdate,
    pulseEventsDelete,
    updateMostRecentApiResponseTimestamp,
  },
  debug: {
    setItem: (item: keyof TIrrigationSchedulerStoreState['debug'], enable: boolean) => {
      irrigationSchedulerStore.setState((s) => {
        return {
          ...s,
          debug: {
            ...s.debug,
            [item]: enable,
          },
        }
      })
    },
  },
  settings: {
    setDateView,
  },
}
