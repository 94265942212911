import { LoadingOverlay } from '@mantine/core'
import { TrapImagesDatePicker } from 'App/Map/CurrentValuesMap/caches/TrapValuesCache/TrapBadgeComponent/TrapImageViewer/TrapImagesDatePicker/TrapImagesDatePicker'
import { TrapImageWithBoundingBoxes } from 'App/Map/CurrentValuesMap/caches/TrapValuesCache/TrapBadgeComponent/TrapImageViewer/TrapImageWithBoundingBoxes/TrapImageWithBoundingBoxes'
import { useTrapImageData } from 'App/Map/CurrentValuesMap/caches/TrapValuesCache/TrapBadgeComponent/_utils/useTrapImageData'
import type { TRAP_META } from 'App/Map/CurrentValuesMap/caches/TrapValuesCache/TrapValuesCache'
import { useTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/useTimezoneForSelectedPropertyOrRegion'
import { DirectionButton } from 'App/ServiceCenter/SelectedEquipmentCard/DirectionButton/DirectionButton'
import { MapCard } from 'components/MapCard/MapCard'
import { translate } from 'i18n/i18n'
import { isEmpty, isNil } from 'lodash'
import React from 'react'
import { colors } from 'settings/colors'
import { HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD } from 'settings/hubConnectorEnvironment'
import { SharedSettings } from 'settings/SharedSettings'
import { ButtonStackemChartsModal } from 'swagworld/components/ButtonStackemChartsModal'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'

export const Trap = (props: TRAP_META & { onClose: () => void }) => {
  const timezone = useTimezoneForSelectedPropertyOrRegion()

  const trapImageData = useTrapImageData({
    insectId: props.insectId,
    lngLat: props.lngLat,
    preventFetch: false,
    timezone,
  })

  const probablyNoDataForThisTrap = !trapImageData.fullLoading && isEmpty(trapImageData.datesWithImages)
  const { imageHash } = trapImageData.datesWithImages?.[trapImageData.selectedDate] ?? {}
  const imgSrc = `${SharedSettings.TRAP_IMAGE_PREFIX_URL}${imageHash}.${SharedSettings.TRAP_IMAGE_FILE_SUFFIX}`

  return (
    <MapCard
      latLng={props.latLng.toJSON()}
      onClose={props.onClose}
      title={props.name}
      footer={
        HUB_CONNECTOR_ENVIRONMENT_IS_SWAGWORLD ? (
          <ButtonStackemChartsModal />
        ) : (
          <div css={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
            <DirectionButton latLng={props.latLng.toJSON()} />
          </div>
        )
      }
      targetCSS={{
        width: 44,
        height: 39,
        transform: 'translate(-50%, 0%)',
      }} // TODO: This assumes same width, height, and transform as TrapMapIcon in TrapBadgeComponent
    >
      <div
        css={{
          padding: '0 20px 6px 20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingOverlay visible={trapImageData.fullLoading} overlayOpacity={1} zIndex={1} />
        {!isNil(props.insectId) && (
          <div css={{ fontSize: '16px' }}>{getInsectNameFromId(props.insectId)}</div>
        )}
        {probablyNoDataForThisTrap && <h3>{translate.phrases.banyanApp('No data found.')}</h3>}
        <div css={{ display: probablyNoDataForThisTrap ? 'none' : 'unset' }}>
          <TrapImagesDatePicker
            datePickerLoading={trapImageData.datePickerLoading}
            datesWithImages={trapImageData.datesWithImages}
            datesWithTrapCatches={trapImageData.datesWithTrapCatches}
            fullLoading={trapImageData.fullLoading}
            maxDate={trapImageData.maxDate}
            minDate={trapImageData.minDate}
            prettyDateString={trapImageData.prettyDateString}
            selectedDate={trapImageData.selectedDate}
            setSelectedDate={trapImageData.setSelectedDate}
            timezone={timezone}
          />
        </div>
        {!!imageHash && (
          <>
            <TrapImageWithBoundingBoxes
              catchCount={Number(trapImageData.datesWithImages?.[trapImageData.selectedDate]?.value ?? 0)}
              imageHash={imageHash}
              imgSrc={imgSrc}
            />
            <a
              css={{
                paddingTop: 6,
                display: 'inline-block',
                fontSize: 13,
                fontWeight: 700,
                color: colors.primary,
              }}
              href={imgSrc}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate.phrases.banyanApp('Download image (without annotations)')}
            </a>
          </>
        )}
      </div>
    </MapCard>
  )
}
