import type { MouseEventHandler } from 'react'

export const IconAndTitle = ({
  icon,
  onClick,
  right,
  subtitle,
  title,
  disabled,
}: {
  icon?: React.ReactNode
  onClick?: MouseEventHandler<HTMLDivElement>
  right?: React.ReactNode
  subtitle?: boolean
  title: React.ReactNode
  disabled?: boolean
}) => {
  const iconSize = !!subtitle ? 20 : 22
  const titleSize = !!subtitle ? 14 : 16
  const wrapperHeight = !!subtitle ? 40 : 45

  const getCursorStyle = () => {
    if (disabled) return 'not-allowed'

    return onClick ? 'pointer' : 'unset'
  }

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: getCursorStyle(),
        opacity: disabled ? 0.3 : 1,
      }}
      onClick={!disabled ? onClick : undefined}
    >
      <div
        css={{
          display: 'flex',
          height: wrapperHeight,
          alignItems: 'center',
        }}
      >
        {!!icon && (
          <div
            css={{
              fontSize: iconSize,
              width: iconSize,
              height: iconSize,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {icon}
          </div>
        )}
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 16,
            marginTop: 2,
            fontWeight: 400,
            fontSize: titleSize,
          }}
        >
          {title}
        </div>
      </div>
      {!!right && <div>{right}</div>}
    </div>
  )
}
