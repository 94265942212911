import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import React, { useEffect, useMemo } from 'react'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { sortByKey } from 'utils/sortByKey'
import { DEVICE_TYPES, getDeviceTypeLabel } from '../Map/_utils/deviceTypes'
import { DEVICEABLE_EQUIPMENTS, EQUIPMENT_TYPES, getEquipmentTypeLabel } from '../Map/_utils/equipmentTypes'
import { equipmentStatusPanelStore } from '../store/equipmentStatusPanelStore'
import type { TEquipmentType, TNodeDeviceType } from '../store/serviceCenterStore'

interface Props {
  selectedEquipmentTypes: TEquipmentType[]
  selectedEquipmentDevices: TNodeDeviceType[]
  dropdownPosition?: 'top' | 'bottom'
  onChange: (selectedEquipmentTypes: TEquipmentType[], selectedEquipmentDevices: TNodeDeviceType[]) => void
  selectedPropertyId?: number | null
}

export const EquipmentTypesAndDevicesMultiSelect: React.FC<Props> = ({
  selectedEquipmentTypes,
  selectedEquipmentDevices,
  dropdownPosition,
  onChange,
}) => {
  const NODES_GROUP_LABEL = translate.phrases.placeholder('Equipment')
  const DEVICES_GROUP_LABEL = translate.phrases.placeholder('Sensors')
  const selectedMapPropertyId = selectedFieldAssetsStore.useSelector((s) => s.property)

  const selectedStatusPanelPropertyId = equipmentStatusPanelStore.useSelector(
    equipmentStatusPanelStore.selectors.getPropertyId,
  )

  const propertyIdToFilterEquipmentTypes = selectedStatusPanelPropertyId || selectedMapPropertyId

  const handleSelectionChange = (selectedOptions: TOptions[]) => {
    const selectedEquipmentTypes = selectedOptions
      .filter((option) => option.group === NODES_GROUP_LABEL)
      .map((option) => option.value) as TEquipmentType[]

    const selectedDevices = selectedOptions
      .filter((option) => option.group === DEVICES_GROUP_LABEL)
      .map((option) => option.value) as TNodeDeviceType[]

    onChange(selectedEquipmentTypes, selectedDevices)
  }

  const selectedOptions = useMemo(() => {
    if (!propertyIdToFilterEquipmentTypes) return []

    return [
      ...selectedEquipmentTypes.map(
        (value) =>
          ({ value, label: getEquipmentTypeLabel(value, true), group: NODES_GROUP_LABEL } as TOptions),
      ),
      ...selectedEquipmentDevices.map(
        (value) => ({ value, label: getDeviceTypeLabel(value), group: DEVICES_GROUP_LABEL } as TOptions),
      ),
    ]
  }, [selectedEquipmentTypes, selectedEquipmentDevices])

  const data: { value: string; label: string }[] = useMemo(() => {
    if (!propertyIdToFilterEquipmentTypes) return []

    let options: { label: string; value: TEquipmentType | TNodeDeviceType; group: string }[] = [
      ...EQUIPMENT_TYPES.map((equipmentType) => ({
        label: getEquipmentTypeLabel(equipmentType as TEquipmentType, true),
        value: equipmentType,
        group: NODES_GROUP_LABEL,
      })).sort(sortByKey('label')),
    ]

    let filteredOptions = options.filter((option) => {
      const { value: equipmentType } = option

      switch (equipmentType) {
        case 'dispenser':
          return (
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_DISPENSERS',
              entity: propertyIdToFilterEquipmentTypes,
            }) ||
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_PLANNED_DISPENSERS',
              entity: propertyIdToFilterEquipmentTypes,
            })
          )

        case 'gateway':
          return (
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_GATEWAYS',
              entity: propertyIdToFilterEquipmentTypes,
            }) ||
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_PLANNED_GATEWAYS',
              entity: propertyIdToFilterEquipmentTypes,
            })
          )

        case 'repeater':
          return (
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_REPEATERS',
              entity: propertyIdToFilterEquipmentTypes,
            }) ||
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_PLANNED_REPEATERS',
              entity: propertyIdToFilterEquipmentTypes,
            })
          )

        case 'station':
          return (
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_STATIONS',
              entity: propertyIdToFilterEquipmentTypes,
            }) ||
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_PLANNED_STATIONS',
              entity: propertyIdToFilterEquipmentTypes,
            })
          )

        case 'trap':
          return (
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_ACTIVE_TRAPS',
              entity: propertyIdToFilterEquipmentTypes,
            }) ||
            checkAuthorization({
              permission: 'VIEW_SSC_NODE_PLANNED_TRAPS',
              entity: propertyIdToFilterEquipmentTypes,
            })
          )

        default:
          return false
      }
    })

    if (selectedEquipmentTypes.some((type) => DEVICEABLE_EQUIPMENTS.includes(type))) {
      filteredOptions = filteredOptions.concat([
        ...DEVICE_TYPES.map((deviceType) => ({
          label: getDeviceTypeLabel(deviceType as TNodeDeviceType),
          value: deviceType,
          group: DEVICES_GROUP_LABEL,
        })).sort(sortByKey('label')),
      ])
    }

    return filteredOptions
  }, [selectedEquipmentTypes, propertyIdToFilterEquipmentTypes])

  useEffect(() => {
    // reset filter when property changes
    onChange([], [])
  }, [propertyIdToFilterEquipmentTypes])

  return (
    <MultiSelect
      disabled={!propertyIdToFilterEquipmentTypes}
      data={data}
      dropdownPosition={dropdownPosition}
      groupOptions={true}
      onChange={handleSelectionChange}
      placeholder={translate.phrases.placeholder('Filter by types')}
      selectedData={selectedOptions}
    />
  )
}
