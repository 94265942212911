import { SimpleGrid, Switch } from '@mantine/core'
import { useState } from 'react'
import { colors } from '../../settings/colors'

// Dynamically import all files in the components/icons folder
const iconModules = import.meta.glob('components/icons/*.tsx', { eager: true })

const icons = Object.keys(iconModules).map((path) => {
  // Extract icon name from path
  const name = path.match(/^(.*)\/([^/]*).tsx$/)?.[2]

  return {
    name,
    // @ts-ignore - Get named import from module
    Icon: iconModules[path][name],
  }
})

export const IconsExample = () => {
  const [dark, setDark] = useState(false)
  const backgroundColor = dark ? colors.midnight : colors.white
  const textColor = dark ? colors.white : colors.midnight

  return (
    <div css={{ backgroundColor, color: textColor, padding: 30 }}>
      <h2
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 0,
          paddingBottom: 20,
          borderBottom: '1px solid silver',
        }}
      >
        {icons.length} Icons{' '}
        <Switch
          onLabel="DARK"
          offLabel="LIGHT"
          size="xl"
          checked={dark}
          color="gray"
          onChange={(event) => setDark(event.currentTarget.checked)}
          css={{ marginLeft: 10 }}
        />
      </h2>

      <SimpleGrid cols={8}>
        {icons.map(({ name, Icon }) => (
          <div
            key={name}
            css={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 120,
                height: 120,
                fontSize: 40,
              }}
            >
              <Icon />
            </div>

            <div css={{ fontSize: 14 }}>{name}</div>
          </div>
        ))}
      </SimpleGrid>
    </div>
  )
}
