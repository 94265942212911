import type { TFieldAssetKeyTypes } from 'App/Map/types'
import polylabel from 'polylabel'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { getArgsForAnchorCoordinates } from '../getArgsForAnchorCoordinates'

export type TSetByArg = { irrigationZoneEmitter: TFieldAssetKeyTypes.TIrrigationZoneEmitterId }

export const setBy = (args: TSetByArg): Partial<TSelectedFieldAssetsStoreState> | null => {
  const { irrigationZoneEmitter } = args
  const allProperties = fieldAssetStore.getState()?.properties

  const property = Object.values(allProperties ?? {}).find((propertyData) => {
    return !!propertyData?.irrigationZoneEmitters?.[irrigationZoneEmitter]
  })

  if (!property || !property?.irrigationZoneEmitters?.[irrigationZoneEmitter]) {
    return null
  }

  const { coordinates } = JSON.parse(property.irrigationZoneEmitters[irrigationZoneEmitter].geometry)
  const [lng, lat] = polylabel([coordinates[0][0]], 1.0)
  const anchorCoordinates = { lng, lat }

  const argsForAnchorCoordinates = getArgsForAnchorCoordinates({
    anchorCoordinates,
    property,
    // we need to update PTs whenever irrigationZoneEmitter changes.
    // Need to pass the new value from here otherwise it is stale with getState
    args,
  })

  // overriding irrigation zone here because multiple zones in the store may have same coordinates
  // with only difference in emitter type
  // in that case closest by coordinates will set wrong value
  return { ...argsForAnchorCoordinates, irrigationZoneEmitter: irrigationZoneEmitter }
}
