import { NumberInput, Select } from '@mantine/core'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { validateStatusErrorHelper } from '../../../../../_utils/validateStatusErrorHelper'
import { AlertsValidators, isNumberOrNumericString } from '../../../../AlertValidators/AlertValidators'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import { TranslateComponentWrapper } from '../../../../components/TranslateComponentWrapper'
import { TranslatePhraseWrapper } from '../../../../components/TranslatePhraseWrapper'
import type { Rule } from '../../../../settings/alertSettings'
import { AlertsSettings, unitTempDisplay } from '../../../../settings/alertSettings'
import { getErrorStyle } from '../../../../_utils/getErrorStyle'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { helpTextErrorHelper } from '../../_utils/helpTextErrorHelper'
import { updateAlertRules } from '../../_utils/updateAlertRules'

/**
 * "Temperature" is the most complicated of thresholds, as it has the ability to have
 * belowTemperature or aboveTemperature as its sole rules. (note that is an inclusive-or).
 *
 * it has a mixture of state and derivation from rules at the alert level, in order to
 * facilitate neither temperature-measurement-height checkbox being active without losing
 * the operator/temperature that would be set were at least one height active. This
 * complexity in state makes it prone to introducing errors that are hard to
 * predict/recognize, so avoid referring to this component if you're making a regular
 * threshold that doesn't require state
 */
export const TemperatureThreshold = ({
  rules,
  setRules,
  setThresholdIsValid,
  triedToSubmit,
}: {
  rules: Rule[]
  setRules: Dispatch<SetStateAction<Rule[]>>
  setThresholdIsValid: Dispatch<SetStateAction<boolean>>
  triedToSubmit: boolean
}) => {
  const [temperature, setTemperature] = useState<Rule['value']>(null)
  const [temperatureOperator, setTemperatureOperator] = useState<string | null>('>=')

  // note: this one can only be done on initial render… this workaround will have to
  // be refactored if we ever add a create new alert button for temperature alerts
  useEffect(() => {
    if (!rules || !rules.length) setRules(AlertsSettings.emptyRules.temperature)
  }, [])

  // set the value of the temperature + operator from the existing rules upon mount, if applicable
  useEffect(() => {
    try {
      setTemperature(rules[0].value)
    } catch (err) {}

    try {
      setTemperatureOperator(rules[0].operator)
    } catch (err) {}
  }, [])

  const temperatureRules = alertRulesObjectFromArray(rules)
  const { belowTemperature: belowTemperatureRule, aboveTemperature: aboveTemperatureRule } = temperatureRules
  const hasBelowTemperature = !!belowTemperatureRule
  const hasAboveTemperature = !!aboveTemperatureRule
  // check if user-enterable fields are dirty
  const temperatureIsDirty = temperature !== null || triedToSubmit
  const temperatureTypeIsDirty = temperatureIsDirty // use temperatureIsDirty for the temperatureType check, since they should be prepopulated in a valid state anyway
  // validate the user-entered values
  const temperatureError = AlertsValidators.temperature({ temperature })
  const temperatureOperatorError = AlertsValidators.operator({ operator: temperatureOperator })

  const temperatureTypeError = AlertsValidators.temperatureTypes({
    belowTemperature: hasBelowTemperature,
    aboveTemperature: hasAboveTemperature,
  })

  // attach a status
  const temperatureValidateStatus = validateStatusErrorHelper(temperatureIsDirty && !!temperatureError)

  const temperatureTypeValidateStatus = validateStatusErrorHelper(
    temperatureTypeIsDirty && !!temperatureTypeError,
  )

  // attach an error message
  const temperatureHelp = helpTextErrorHelper(temperatureIsDirty && temperatureError)
  const temperatureTypeHelp = helpTextErrorHelper(temperatureTypeIsDirty && temperatureTypeError)
  const errorStyle = getErrorStyle()

  // update the rules upon changes
  useEffect(() => {
    setThresholdIsValid(!temperatureError && !temperatureOperatorError && !temperatureTypeError)
  }, [temperature, temperatureOperator, hasBelowTemperature, hasAboveTemperature])

  const toggleTemperatureType = (type: 'belowTemperature' | 'aboveTemperature') => {
    updateAlertRules({
      rules,
      setRules,
      rulesToChange: {
        [type]: temperatureRules[type]
          ? null
          : { value: temperature, operator: temperatureOperator, unit: 'temperature' },
      },
    })
  }

  const unitTemp = unitTempDisplay()

  return (
    <>
      <TranslatePhraseWrapper>
        <translate.Phrases.banyanApp
          k="Send alert when the temperature is <select/> <numberInput/> {{unitTemp}}"
          v={{ unitTemp }}
          c={{
            select: (
              <TranslateComponentWrapper>
                <Select
                  onChange={(val) => {
                    setTemperatureOperator(val)

                    let ruleAttributes = { value: temperature, operator: val, unit: 'temperature' }
                    let rulesToUpdate: Record<string, Partial<Rule>> = {}

                    if (hasBelowTemperature) rulesToUpdate.belowTemperature = ruleAttributes

                    if (hasAboveTemperature) rulesToUpdate.aboveTemperature = ruleAttributes

                    updateAlertRules({
                      rules,
                      setRules,
                      rulesToChange: { ...rulesToUpdate },
                    })
                  }}
                  css={{ margin: '0 5px' }}
                  value={temperatureOperator}
                  data={[
                    { value: '>=', label: translate.phrases.banyanApp('greater than or equal to') },
                    { value: '<=', label: translate.phrases.banyanApp('less than or equal to') },
                  ]}
                />
                <div />
              </TranslateComponentWrapper>
            ),
            numberInput: (
              <TranslateComponentWrapper>
                <NumberInput
                  onChange={(val) => {
                    setTemperature(val)

                    let ruleAttributes = { value: val, operator: temperatureOperator, unit: 'temperature' }
                    let rulesToUpdate: Record<string, Partial<Rule>> = {}

                    if (hasBelowTemperature) rulesToUpdate.belowTemperature = ruleAttributes

                    if (hasAboveTemperature) rulesToUpdate.aboveTemperature = ruleAttributes

                    updateAlertRules({
                      rules,
                      setRules,
                      rulesToChange: { ...rulesToUpdate },
                    })
                  }}
                  css={{ width: 80, margin: '0 5px' }}
                  type="number"
                  classNames={{ input: temperatureValidateStatus ? errorStyle : undefined }}
                  value={isNumberOrNumericString(temperature) ? Number(temperature) : ''}
                  precision={10}
                  removeTrailingZeros={true}
                />
                <div />
              </TranslateComponentWrapper>
            ),
          }}
        />
      </TranslatePhraseWrapper>
      {temperatureHelp && <ErrorTextWrapper>{temperatureHelp}</ErrorTextWrapper>}

      <div css={{ marginTop: 15 }}>
        <span>{translate.phrases.banyanApp('at the following measurement heights:')}</span>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <div css={{ padding: '20px 20px 0 0' }}>
            <Checkbox
              checked={!!hasBelowTemperature}
              onChange={() => toggleTemperatureType('belowTemperature')}
              label={translate.phrases.banyanApp('Ground Temperature')}
              classNames={{ input: temperatureTypeValidateStatus ? errorStyle : undefined }}
            />
          </div>
          <div css={{ padding: '20px 20px 0 0' }}>
            <Checkbox
              checked={hasAboveTemperature}
              onChange={() => toggleTemperatureType('aboveTemperature')}
              label={translate.phrases.banyanApp('Above Canopy Temperature')}
              classNames={{ input: temperatureTypeValidateStatus ? errorStyle : undefined }}
            />
          </div>
        </div>
        {temperatureTypeHelp && <ErrorTextWrapper>{temperatureTypeHelp}</ErrorTextWrapper>}
      </div>
    </>
  )
}
