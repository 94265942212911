import type { ScanResult } from '@capacitor-community/bluetooth-le'
import { BleClient, ScanMode } from '@capacitor-community/bluetooth-le'
import _ from 'lodash'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { ConnectingTimeout, DecoderMap, EncoderMap, ScanningTimeout, SemiosBleServiceUUID } from './constants'
import {
  BleAdapterStatusInvalidError,
  BleDeviceInvalidError,
  BleDeviceNotFoundError,
  BleUnknownError,
  BleUnsupportedDeviceError,
} from './errors'
import type { SemiosBleNode } from './models/semiosBleNode'
import type { Convertor, Decoder, DTO, DtoConstructor, Encoder, ScanOptions, UUID } from './types'
import { AdapterStatus } from './types'
import { withTimeout } from './util/utility'

const deviceTypeMap = new Map<string, BleDeviceFactory>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function registerDecoder(...decoders: Decoder<any>[]) {
  decoders.forEach((d) => {
    DecoderMap.set(d.type, d)
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function registerEncoder(...encoders: Encoder<any>[]) {
  encoders.forEach((e) => {
    EncoderMap.set(e.type, e)
  })
}

function isDecoder(value: Convertor<DTO>): value is Decoder<DTO> {
  return (value as Decoder<DTO>).decode !== undefined
}

function isEncoder(value: Convertor<DTO>): value is Encoder<DTO> {
  return (value as Encoder<DTO>).encode !== undefined
}

function registerDeviceType(factory: BleDeviceFactory) {
  deviceTypeMap.set(factory.nodeType, factory)

  registerDecoder(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...factory.convertors.filter((convertor) => isDecoder(convertor)).map((d) => d as Decoder<any>),
  )

  registerEncoder(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...factory.convertors.filter((convertor) => isEncoder(convertor)).map((e) => e as Encoder<any>),
  )
}

interface BleContextProps {
  startScan: (options?: ScanOptions) => Promise<void>
  startScanByNodeId: (nodeId: string) => Promise<{ deviceId: string; deviceName: string }>
  stopScan: () => Promise<void>
  connectDevice: (deviceId: string, deviceName: string) => Promise<void>
  connectDeviceByNodeId: (nodeId: string) => Promise<void>
  disconnectDevice: (deviceId: string) => Promise<void>
  deferDisconnect: () => Promise<void>
  adapterStatus: AdapterStatus
  scannedDevices: Map<string, ScanResult>
  connectedDevice: SemiosBleNode | null
}

type BleDeviceFactory = {
  nodeType: string
  creator: (
    deviceId: string,
    nodeId: string,
    notfications: Map<DtoConstructor, DTO>,
    listeners: Map<DtoConstructor, { timestamp: number; callback: () => void }>,
  ) => SemiosBleNode
  convertors: Convertor<DTO>[]
}

const BleManager = createContext<BleContextProps>({
  startScan: Promise.reject,
  startScanByNodeId: Promise.reject,
  stopScan: Promise.reject,
  connectDevice: Promise.reject,
  connectDeviceByNodeId: Promise.reject,
  disconnectDevice: Promise.reject,
  deferDisconnect: Promise.reject,
  scannedDevices: new Map(),
  adapterStatus: AdapterStatus.DISABLED,
  connectedDevice: null,
})

function BleManagerProvider({
  children,
  factories,
}: {
  children: React.ReactNode
  factories: BleDeviceFactory[]
}) {
  const [status, setStatus] = useState<AdapterStatus>(AdapterStatus.DISABLED) //Might consider to use useRef instead
  const [scanResult, setScanResult] = useState<Map<string, ScanResult>>(new Map())
  const [connectedDevice, setConnectedDevice] = useState<SemiosBleNode | null>(null)
  const shouldDisconnect = useRef<boolean>(false)

  const startScan = async (options?: ScanOptions) => {
    if (status !== AdapterStatus.IDLE) {
      throw new BleAdapterStatusInvalidError(status)
    }

    try {
      setScanResult(new Map())

      await BleClient.requestLEScan(
        { allowDuplicates: true, scanMode: ScanMode.SCAN_MODE_LOW_LATENCY },
        (result) => {
          //filter results with serviceUUID, nodeType, nodeId
          if (
            (options?.nodeType && result.localName?.toLowerCase()?.includes(options.nodeType)) ||
            (options?.nodeId && result.localName?.includes(options.nodeId)) ||
            (!options?.nodeId && !options?.nodeType && result.uuids?.includes(SemiosBleServiceUUID))
          ) {
            setScanResult((prev) => {
              return new Map([...prev, [result.device.deviceId, result]])
            })
          }
        },
      )

      setStatus(AdapterStatus.SCANNING)
    } catch (error) {
      throw new BleUnknownError(error)
    }
  }

  const stopScan = async () => {
    try {
      await BleClient.stopLEScan()

      setStatus(AdapterStatus.IDLE)
    } catch (error) {
      throw new BleUnknownError(error)
    }
  }

  const startScanByNodeId = async (nodeId: string): Promise<{ deviceId: string; deviceName: string }> => {
    if (status !== AdapterStatus.IDLE) {
      throw new BleAdapterStatusInvalidError(status)
    }

    let res: ScanResult | undefined

    try {
      setStatus(AdapterStatus.SCANNING)

      res = await withTimeout(
        new Promise<ScanResult>((resolve, reject) => {
          BleClient.requestLEScan(
            { allowDuplicates: true, scanMode: ScanMode.SCAN_MODE_LOW_LATENCY },
            (result) => {
              if (result.localName?.includes(nodeId)) {
                resolve(result)
              }
            },
          ).catch((e) => {
            reject(e)
          })
        }),
        ScanningTimeout,
      )
    } catch (error) {
      throw new BleDeviceNotFoundError(nodeId)
    } finally {
      try {
        await BleClient.stopLEScan()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to stop scan', e)
      }

      setStatus(AdapterStatus.IDLE)

      setConnectedDevice(null)
    }

    if (!res.localName) {
      throw new BleDeviceInvalidError(res.device.deviceId, res.localName)
    }

    return { deviceId: res.device.deviceId, deviceName: res.localName }
  }

  const connectToDevice = async (deviceId: string, deviceName: string) => {
    return await internalConnectDevice(deviceId, deviceName)
  }

  const connectByNodeId = async (nodeId: string) => {
    const deviceMeta = await startScanByNodeId(nodeId)

    await internalConnectDevice(deviceMeta.deviceId, deviceMeta.deviceName)
  }

  const internalDisconnectDevice = async (deviceId: string) => {
    try {
      await BleClient.disconnect(deviceId)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to disconnect from device', e)
    }

    shouldDisconnect.current = false

    setStatus(AdapterStatus.IDLE)

    setConnectedDevice(null)
  }

  const internalConnectDevice = async (deviceId: string, deviceName?: string) => {
    try {
      if (status !== AdapterStatus.IDLE) {
        throw new BleAdapterStatusInvalidError(status)
      }

      setStatus(AdapterStatus.CONNECTING)

      await BleClient.connect(
        deviceId,
        () => {
          setStatus(AdapterStatus.IDLE)

          setConnectedDevice(null)
        },
        { timeout: ConnectingTimeout },
      )

      const [advNodeType, nodeId] = deviceName?.split(' ') || [] //LN_R 123456
      const nodeType = advNodeType?.toLowerCase()

      await BleClient.discoverServices(deviceId)

      const definition = deviceTypeMap.get(nodeType)

      if (!definition) {
        throw new BleUnsupportedDeviceError(nodeType)
      }

      const device = definition.creator(deviceId, nodeId, new Map(), new Map())

      const initNotifications = _.groupBy(
        definition.convertors
          .filter((c) => isDecoder(c) && c.notifiable === true)
          .map((d) => d as Decoder<DTO>),
        (key) => JSON.stringify(key.uuid),
      )

      for (const [key, decoder] of Object.entries(initNotifications)) {
        const uuid = JSON.parse(key) as UUID

        //Enabling notifications
        await BleClient.startNotifications(deviceId, uuid.service, uuid.characteristic, (d: DataView) => {
          for (const def of decoder) {
            const res = def.decode(d)

            if (res !== null) {
              setConnectedDevice((prev) => {
                if (!prev) {
                  return null
                }

                prev.notfications.set(def.type, res)

                return definition.creator(prev.deviceId, prev.nodeId, prev.notfications, prev.listeners)
              })

              // eslint-disable-next-line no-console
              console.log('Notification received', res)

              device.listeners.get(def.type)?.callback()

              break
            }
          }
        })
      }

      if (shouldDisconnect.current) {
        // eslint-disable-next-line no-console
        console.error('shouldDisconnect')

        internalDisconnectDevice(deviceId)

        return
      }

      setStatus(AdapterStatus.CONNECTED)

      setConnectedDevice(device)
    } catch (error) {
      internalDisconnectDevice(deviceId)

      throw error
    }
  }

  const disconnectDevice = async (deviceId: string) => {
    try {
      await BleClient.disconnect(deviceId)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to disconnect from device', error)
    } finally {
      setStatus(AdapterStatus.IDLE)

      setConnectedDevice(null)
    }
  }

  const deferDisconnect = async () => {
    shouldDisconnect.current = true
  }

  useEffect(() => {
    const init = async () => {
      factories.forEach((t) => {
        registerDeviceType(t)
      })

      await BleClient.initialize()

      await BleClient.startEnabledNotifications((isBluetoothEnabled) => {
        setStatus((prev) => {
          if (isBluetoothEnabled) {
            return AdapterStatus.IDLE
          } else if (!isBluetoothEnabled) {
            return AdapterStatus.DISABLED
          } else {
            return prev
          }
        })
      })

      setStatus((await BleClient.isEnabled()) ? AdapterStatus.IDLE : AdapterStatus.DISABLED)
    }

    const cleanup = async () => {
      await BleClient.stopLEScan()

      //Warning: edge case, if is connectedDevice.deviceId isn't existed(connecting). It will not disconnect

      if (connectedDevice) {
        await BleClient.disconnect(connectedDevice.deviceId)
      }

      await BleClient.stopEnabledNotifications()
    }

    init()

    return () => {
      cleanup()
    }
  }, [])

  return (
    <BleManager.Provider
      value={{
        startScan: startScan,
        startScanByNodeId: startScanByNodeId,
        stopScan: stopScan,
        connectDevice: connectToDevice,
        connectDeviceByNodeId: connectByNodeId,
        disconnectDevice: disconnectDevice,
        deferDisconnect: deferDisconnect,
        adapterStatus: status,
        scannedDevices: scanResult,
        connectedDevice: connectedDevice,
      }}
    >
      {children}
    </BleManager.Provider>
  )
}

const useBleManager = () => {
  return useContext(BleManager)
}

export { useBleManager, BleManagerProvider, BleDeviceFactory }
