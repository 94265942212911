import type {
  TBlockETData,
  TSelectedKcConfigurationMap,
} from 'App/Map/UserSettingsMenu/Shared/EvapotranspirationSettings/types'
import type { Dispatch, SetStateAction } from 'react'
import { Row } from './Row/Row'

export const Rows = ({
  data,
  dirtyBlockIds,
  setDirtyBlockIds,
  selectedValueMap,
  setSelectedValueMap,
}: {
  data: TBlockETData[]
  dirtyBlockIds: number[]
  setDirtyBlockIds: Dispatch<SetStateAction<number[]>>
  selectedValueMap: TSelectedKcConfigurationMap
  setSelectedValueMap: Dispatch<SetStateAction<TSelectedKcConfigurationMap>>
}) => {
  const blockIdsForCrops = data.reduce<Record<number, number[]>>((acc, curr) => {
    return {
      ...acc,
      [curr.cropId]: [...(acc[curr.cropId] ?? []), Number(curr.blockId)],
    }
  }, {})

  const rows = data.map((block) => {
    return (
      <Row
        key={block.blockId}
        block={block}
        blockIdsOfOtherValidCropBlocks={blockIdsForCrops[block.cropId]}
        selectedValue={selectedValueMap[Number(block.blockId)]}
        setSelectedValueMap={setSelectedValueMap}
        isDirty={dirtyBlockIds.includes(Number(block.blockId))}
        setDirtyBlockIds={setDirtyBlockIds}
      />
    )
  })

  return <>{rows}</>
}
