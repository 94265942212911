import { Badge, Code, Divider, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Button } from 'components/Button/Button'
import { IconChevron } from 'components/icons/IconChevron'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React from 'react'
import { useTimezoneForSelectedPropertyOrRegion } from '../../../Map/PanelDetails/_utils/useTimezoneForSelectedPropertyOrRegion'
import type { TApiRequest } from '../../utils/api/queue'
import { ApiRequestStatus } from '../../utils/api/queue'

interface RequestCardProps {
  request: TApiRequest
  onRetry: () => void
  onCancel: () => void
}

export const RequestCard: React.FC<RequestCardProps> = ({ request, onRetry, onCancel }) => {
  const [opened, { toggle }] = useDisclosure(false)
  const timezone = useTimezoneForSelectedPropertyOrRegion()
  const theme = useMantineTheme()

  let statusColor = theme.colors.yellow[0]

  if (request.status === ApiRequestStatus.PROCESSING) statusColor = theme.colors.green[0]

  if (request.status === ApiRequestStatus.ERROR) statusColor = theme.colors.red[0]

  return (
    <div
      css={{
        border: `1px solid ${theme.colors.grey[1]}`,
        borderRadius: 3,
        color: theme.colors.grey[3],
        marginBottom: 10,
        lineHeight: '20px',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          backgroundColor: theme.colors.grey[0],
          padding: '14px 10px',
        }}
        onClick={toggle}
      >
        <div>{request.type}</div>

        <div>
          <Badge variant="light" css={{ color: theme.colors.white[0], backgroundColor: statusColor }}>
            {request.status}
          </Badge>
          <span
            css={{
              display: 'inline-block',
              marginLeft: 10,
              transform: opened ? 'rotate(180deg)' : undefined,
            }}
          >
            <IconChevron />
          </span>
        </div>
      </div>

      {opened && (
        <div css={{ padding: 10, borderTop: `1px solid ${theme.colors.grey[1]}` }}>
          <dl
            css={{
              marginTop: 0,
              dt: {
                fontWeight: 'bold',
              },
              dd: {
                marginInlineStart: 0,
                marginBottom: 10,
              },
            }}
          >
            <dt>{translate.phrases.placeholder('Time')}</dt>
            <dd>{new Date(request.createdAt).toLocaleString()}</dd>

            {request.preRequestError && (
              <>
                <dt>{translate.phrases.placeholder('Pre-request Check')}</dt>
                <dd>
                  {typeof request.preRequestError === 'string'
                    ? request.preRequestError
                    : translate.phrases.placeholder('Failed').toUpperCase()}
                </dd>
              </>
            )}

            {request.error && (
              <>
                <dt>{translate.phrases.placeholder('Error')}</dt>
                <dd>{request.error.message}</dd>
              </>
            )}

            {!!request.retryCount && (
              <>
                <dt>{translate.phrases.placeholder('Retries')}</dt>
                <dd>
                  {request.retryCount}{' '}
                  {request.lastRetryAt ? ` (${moment.tz(request.lastRetryAt, timezone).fromNow()})` : ''}
                </dd>
              </>
            )}
          </dl>

          <Code block>{JSON.stringify(request.meta, null, ' ')}</Code>

          <Divider my="sm" variant="dashed" />

          <Code>
            {translate.phrases.templates('{{label}}: {{value}}', {
              label: translate.phrases.placeholder('Id'),
              value: request.id,
            })}
          </Code>

          <div css={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
            <Button css={{ marginRight: 10 }} variant="negative" onClick={onCancel}>
              {translate.phrases.placeholder('Cancel')}
            </Button>

            <Button
              variant="secondary"
              onClick={onRetry}
              loading={request.status === ApiRequestStatus.PROCESSING}
            >
              {translate.phrases.placeholder('Retry')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
