import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'

export const minutesToHoursAndMinutes = (numOfMinutes: number) => {
  const duration = moment.duration(Math.round(numOfMinutes), 'minutes')
  // Always display the total hours, ignoring the fraction because we'll output those as 0-59 minutes.
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()

  if (minutes === 0) return translate.phrases.banyanApp('{{hours}}h', { hours })

  if (hours === 0) return translate.phrases.banyanApp('{{minutes}}m', { minutes })

  return translate.phrases.banyanApp('{{hours}}h {{minutes}}m', { hours, minutes })
}
