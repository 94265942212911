import React from 'react'
import { colors } from 'settings/colors'

export const SectionTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      css={{
        color: colors.grey500,
        fontSize: 11,
        fontWeight: 700,
        margin: '12px 0px',
      }}
    >
      {children}
    </div>
  )
}
