import { generateFlatNavigationList } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateFlatNavigationList'
import { sortSections } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateNavigationMenu'
import { makePrettySectionName } from 'App/Map/LayerNav/Layers/generateNavigationMenu/generateNavigationMenuGroupedBySection'
import { useMemo } from 'react'
import { defaultMapVisualValueGroup } from 'stores/mapControlsStore/mapVisualValueGroup'
import type { TValueGroup } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { alphabeticalSort } from 'utils/alphabeticalSort'

export const useOptionsToRenderOut = () => {
  return useMemo(() => {
    const flatNavigationList = generateFlatNavigationList()
    const layersWithDefaultsOnMaps: Partial<Record<TValueGroup, true>> = {}

    Object.values(defaultMapVisualValueGroup).forEach((dmvvg) => {
      Object.entries(dmvvg).forEach(([valueGroup, hasSomething]) => {
        if (!!hasSomething) layersWithDefaultsOnMaps[valueGroup as TValueGroup] = true
      })
    })

    return Object.values(flatNavigationList)
      .flatMap((values) => {
        if (!values) return []

        const layers = values.childLayers
          ? Object.values(values.childLayers).map((cl) => ({
              label: cl.translatedTitle,
              value: cl.key as TValueGroup,
            }))
          : [{ label: values.translatedTitle, value: values.key as TValueGroup }]

        const layersWithDefaults = layers.filter(({ value }) => layersWithDefaultsOnMaps[value])

        if (!layersWithDefaults.length) return []

        return layersWithDefaults.map(({ label, value }) => ({ sectionRaw: values.section, label, value }))
      })
      .sort((a, b) => sortSections(a.sectionRaw, b.sectionRaw) || alphabeticalSort(a.label, b.label))
      .map(({ sectionRaw, label, value }) => ({
        section: makePrettySectionName(sectionRaw),
        label: label,
        value: value,
      }))
  }, [])
}
