import { APP_PLATFORM_VERSION } from '@semios/app-platform-version'

export const appInfo = {
  APP_PLATFORM_VERSION: APP_PLATFORM_VERSION,
  apiCustomerURL: import.meta.env.VITE_API_CUSTOMER_URL,
  apiURL: import.meta.env.VITE_API_URL,
  deploymentDate: import.meta.env.VITE_DEPLOYMENT_DATE,
  gitBranchName: import.meta.env.VITE_GIT_BRANCH_NAME,
  gitCommitHash: import.meta.env.VITE_GIT_COMMIT_HASH,
  useFullStory: import.meta.env.VITE_USE_FULLSTORY === 'true',
  useHubspot: import.meta.env.VITE_USE_HUBSPOT === 'true',
  useSentry: import.meta.env.VITE_USE_SENTRY === 'true',
}
