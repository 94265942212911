import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconQrCodeScan = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 16 16')}>
      <path
        d="M6 3.6V6H3.6V3.6H6ZM7.2 2.4H2.4V7.2H7.2V2.4ZM6 10V12.4H3.6V10H6ZM7.2 8.8H2.4V13.6H7.2V8.8ZM12.4 3.6V6H10V3.6H12.4ZM13.6 2.4H8.8V7.2H13.6V2.4ZM8.8 8.8H10V10H8.8V8.8ZM10 10H11.2V11.2H10V10ZM11.2 8.8H12.4V10H11.2V8.8ZM8.8 11.2H10V12.4H8.8V11.2ZM10 12.4H11.2V13.6H10V12.4ZM11.2 11.2H12.4V12.4H11.2V11.2ZM12.4 10H13.6V11.2H12.4V10ZM12.4 12.4H13.6V13.6H12.4V12.4ZM16 4H14.4V1.6H12V0H16V4ZM16 16V12H14.4V14.4H12V16H16ZM0 16H4V14.4H1.6V12H0V16ZM0 0V4H1.6V1.6H4V0H0Z"
        fill="currentColor"
      />
    </svg>
  )
}
