import { routes } from '@semios/app-platform-banyan-route-definitions'
import { keyBy } from 'lodash'
import { apiFetch } from 'utils/apiFetch'

export const serviceCenterDevicesGet = async () => {
  const devices = await apiFetch<
    routes.ServiceCenterDevicesGet.Request,
    routes.ServiceCenterDevicesGet.Response
  >({
    url: routes.ServiceCenterDevicesGet.path,
    body: null,
    params: {
      method: 'POST',
    },
  })

  return keyBy(devices, 'source')
}
