import { Button } from 'components/Button/Button'
import { IconCalculatorWhite } from 'components/icons/IconCalculatorWhite'
import { IconPencil } from 'components/icons/IconPencil'
import { BackIcon } from 'components/ModalDrawer/BackIcon/BackIcon'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { smallStore } from 'stores/smallStore'
import { useScreenSize } from 'utils/useScreenSize'

type TEmitterConfigurationHeader = {
  onRateCalculatorButtonClick: () => void
  onBulkEditButtonClick: () => void
}

const WideScreenHeader = ({
  onRateCalculatorButtonClick,
  onBulkEditButtonClick,
}: TEmitterConfigurationHeader) => {
  return (
    <div
      css={{
        borderBottom: `1px solid ${colors.grey200}`,
        padding: 20,
        margin: 20,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
      }}
    >
      <div
        css={{
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        {translate.phrases.banyanApp('Emitter Configuration')}
      </div>
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
        <Button
          variant="secondary"
          leftIcon={
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <IconPencil />
            </div>
          }
          onClick={onBulkEditButtonClick}
        >
          {translate.phrases.banyanApp('Bulk Edit')}
        </Button>
        <Button
          variant="primary"
          leftIcon={
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <IconCalculatorWhite />
            </div>
          }
          onClick={onRateCalculatorButtonClick}
        >
          {translate.phrases.banyanApp('Rate Calculator')}
        </Button>
      </div>
    </div>
  )
}

const NarrowScreenHeader = ({
  onRateCalculatorButtonClick,
  onBulkEditButtonClick,
}: TEmitterConfigurationHeader) => {
  const onClose = () => {
    smallStore.setState((s) => ({ ...s, settingsModalSelectedTab: 'mainMenu' }))
  }

  return (
    <div
      css={{
        boxSizing: 'content-box',
        paddingTop: 'max(18px, calc(8px + env(safe-area-inset-top)))',
        marginBottom: 18,
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 10,
        backgroundColor: colors.grey50,
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div css={{ paddingLeft: 20 }} onClick={onClose}>
          <BackIcon />
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 35,
            flex: 1,
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {translate.phrases.banyanApp('Emitter Configuration')}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 10,
          borderBottom: `1px solid ${colors.grey200}`,
          padding: '15px 0px',
        }}
      >
        <Button
          variant="secondary"
          leftIcon={
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <IconPencil />
            </div>
          }
          onClick={onBulkEditButtonClick}
        >
          {translate.phrases.banyanApp('Bulk Edit')}
        </Button>
        <Button
          variant="primary"
          leftIcon={
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <IconCalculatorWhite />
            </div>
          }
          onClick={onRateCalculatorButtonClick}
        >
          {translate.phrases.banyanApp('Rate Calculator')}
        </Button>
      </div>
    </div>
  )
}

export const EmitterConfigurationHeader = ({
  onRateCalculatorButtonClick,
  onBulkEditButtonClick,
}: TEmitterConfigurationHeader) => {
  const { isWideScreen } = useScreenSize()

  return isWideScreen ? (
    <WideScreenHeader
      onRateCalculatorButtonClick={onRateCalculatorButtonClick}
      onBulkEditButtonClick={onBulkEditButtonClick}
    />
  ) : (
    <NarrowScreenHeader
      onRateCalculatorButtonClick={onRateCalculatorButtonClick}
      onBulkEditButtonClick={onBulkEditButtonClick}
    />
  )
}
