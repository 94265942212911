import { SensorCommandStatus, SensorTypeIds } from '../../constants'
import { DTO } from '../../types'

class Humidity extends DTO {
  humidity = 0.0
}

class Vital extends DTO {
  lithiumBattery = 0.0
  solarBattery = 0.0
  alkalineBattery = 0.0
  rssi = 0 //lora signal strength
  rsrp = 0
}

class FirmwareVersion extends DTO {
  version = 'n/a'
}

class Temperature extends DTO {
  temperature = 0.0
}

class DetectSdi extends DTO {
  commandStatus = SensorCommandStatus.INVALID // 0 - success, 2 - busy, 3 - no sensor, -1 - invalid
  type = SensorTypeIds.UNKNOWN
  address = '0'
}

type SdiSensorStatus = {
  address: string
  type: SensorTypeIds
  status: number // 0-ok, 1-unmatched, others-not connected
}

class ProgramSdiSensor extends DTO {
  isAdd = true
  address = '0'
}

class RequestUplinkRebootMessage extends DTO {}

class RequestAllSdiSensorStatus extends DTO {}

class AllSdiSensorStatus extends DTO {
  commandStatus = SensorCommandStatus.INVALID // 0 - success, 2 - busy, 3 - no sensor, -1 - invalid
  sensorCount = 0
  sensorStatus: SdiSensorStatus[] = []
}

class Rj11SensorStatus extends DTO {
  commandStatus = SensorCommandStatus.INVALID // 0 - success, 2 - busy, -1 - invalid
  sensorType = SensorTypeIds.UNKNOWN
  sensorStatus = 3 // 0-ok, 3 - not connected
}

class CurrentTimestamp extends DTO {
  constructor(timestamp?: Date) {
    super()

    if (timestamp) {
      this.timestampInSecond = Math.floor(timestamp.getTime() / 1000)
    }
  }

  getDate() {
    return new Date(this.timestampInSecond * 1000)
  }
  timestampInSecond = 0
}

export {
  AllSdiSensorStatus,
  SdiSensorStatus,
  Rj11SensorStatus,
  ProgramSdiSensor,
  Temperature,
  Humidity,
  FirmwareVersion,
  CurrentTimestamp,
  RequestUplinkRebootMessage,
  RequestAllSdiSensorStatus,
  Vital,
  DetectSdi,
}
