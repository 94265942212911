import type { Dispatch, SetStateAction } from 'react'
import { Fragment } from 'react'
import { colors } from 'settings/colors'
import { Separator } from '../../../components/Separator'
import type { ModifiedZone } from '../../../EmitterConfiguration'
import type { TFlowRate, TKeyFlowRate } from '../../../types'
import { EmitterConfigurationZoneDetail } from './EmitterConfigurationZoneDetail'

type TEmitterConfigurationZone = {
  flowRate: Record<TKeyFlowRate, TFlowRate>
  zoneId: number
  zoneName: string
  modifiedZones: ModifiedZone[]
  setModifiedZones: Dispatch<SetStateAction<ModifiedZone[]>>
  propertyId: number
}

export const EmitterConfigurationZone = ({
  flowRate,
  zoneId,
  zoneName,
  modifiedZones,
  setModifiedZones,
  propertyId,
}: TEmitterConfigurationZone) => {
  const flowRateValues = Object.values(flowRate)

  return (
    <div css={{ padding: 18, border: `1px solid ${colors.grey200}`, borderRadius: '3px 3px 0 0' }}>
      <div css={{ fontSize: 16, fontWeight: 500, marginBottom: 10 }}>{zoneName}</div>
      {flowRateValues.map((rate, index) => {
        const { id, emitterType, unitPerHour, flowRate: flowRateNumber } = rate

        return (
          <Fragment key={id}>
            <EmitterConfigurationZoneDetail
              id={id}
              flowRate={flowRateNumber}
              unitPerHour={unitPerHour}
              emitterType={emitterType}
              zoneId={zoneId}
              modifiedZones={modifiedZones}
              setModifiedZones={setModifiedZones}
              propertyId={propertyId}
            />
            {index < flowRateValues.length - 1 && (
              <div css={{ margin: '15px 5px' }}>
                <Separator />
              </div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
