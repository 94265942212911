import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import type {
  TUnitConverterOptionsWithoutUnits,
  TUnitConverterOptionsWithUnits,
  TUnitConverterTypicalReturn,
} from '../types'

const DEFAULT_DECIMAL_PLACES_DEPTH = 0
const DEFAULT_DECIMAL_PLACES_AWC = 1
const DEFAULT_DECIMAL_PLACES_TEMPERATURE = 1
const DEFAULT_DECIMAL_PLACES_IONIC = 0

type TSoilTemperatureUnit = 'c' | 'f'

type TSoilDepthUnit = 'cm' | 'in'

const soilTemperature = (
  soilTemperature: number | null = null,
  {
    decimalPlaces = DEFAULT_DECIMAL_PLACES_TEMPERATURE,
    inputUnit = 'c',
    outputUnit = userDetailsStore.getState().temperature === 'IMPERIAL' ? 'f' : 'c',
  }: TUnitConverterOptionsWithUnits<TSoilTemperatureUnit> = {},
) => {
  let convertedValue: number | null

  const shouldConvertFromCToF = inputUnit === 'c' && outputUnit === 'f'
  const shouldConvertFromFToC = inputUnit === 'f' && outputUnit === 'c'

  if (isNil(soilTemperature)) {
    convertedValue = null
  } else if (shouldConvertFromCToF) {
    convertedValue = soilTemperature * 1.8 + 32
  } else if (shouldConvertFromFToC) {
    convertedValue = (soilTemperature - 32) / 1.8
  } else {
    // assume there is no conversion required
    convertedValue = Number(soilTemperature)
  }

  const unitSymbol =
    outputUnit === 'c'
      ? translate.measurements.degreesCelsius.unit()
      : translate.measurements.degreesFahrenheit.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Soil Temperature'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Soil Temperature'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_TEMPERATURE,
    suffix: () => unitSymbol,
    shortTitle: () => translate.phrases.banyanApp('Soil Temperature'),
    title: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Soil Temperature'),
        unitSymbol,
      }),
    titleWithoutUnit: () => translate.phrases.banyanApp('Soil Temperature'),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return outputUnit === 'c'
        ? translate.measurements.degreesCelsius.value(convertedValue, decimalPlaces)
        : translate.measurements.degreesFahrenheit.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return outputUnit === 'c'
        ? translate.measurements.degreesCelsius.valueWithUnit(convertedValue, decimalPlaces)
        : translate.measurements.degreesFahrenheit.valueWithUnit(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

const soilDepth = (
  depthAmount: number | null = null,
  {
    decimalPlaces = DEFAULT_DECIMAL_PLACES_DEPTH,
    inputUnit = 'cm',
    outputUnit = userDetailsStore.getState().depth === 'IMPERIAL' ? 'in' : 'cm',
  }: TUnitConverterOptionsWithUnits<TSoilDepthUnit> = {},
) => {
  let convertedValue: number | null

  const shouldConvertFromCMToIN = inputUnit === 'cm' && outputUnit === 'in'
  const shouldConvertFromINToCM = inputUnit === 'in' && outputUnit === 'cm'

  if (isNil(depthAmount)) {
    convertedValue = null
  } else if (shouldConvertFromCMToIN) {
    // even though 2.54 is more correct, we'll use 2.5 since it looks nicer
    convertedValue = Math.abs(depthAmount / 2.5)
  } else if (shouldConvertFromINToCM) {
    convertedValue = Math.abs(Number(depthAmount) * 2.5)
  } else {
    convertedValue = Math.abs(Number(depthAmount))
  }

  const unitSymbol =
    outputUnit === 'in' ? translate.measurements.inches.unit() : translate.measurements.centimeters.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Soil Depth'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Soil Depth'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_DEPTH,
    suffix: () => unitSymbol,
    shortTitle: () => translate.phrases.banyanApp('Soil Depth'),
    title: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Soil Depth'),
        unitSymbol,
      }),
    titleWithoutUnit: () => translate.phrases.banyanApp('Soil Depth'),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => {
      return outputUnit === 'in'
        ? translate.measurements.inches.value(convertedValue, decimalPlaces)
        : translate.measurements.centimeters.value(convertedValue, decimalPlaces)
    },
    valueWithSuffix: () => {
      return outputUnit === 'in'
        ? translate.measurements.inches.valueWithUnit(convertedValue, decimalPlaces)
        : translate.measurements.centimeters.valueWithUnit(convertedValue, decimalPlaces)
    },
    valueWithNoRounding: () => convertedValue,
  }
}

const soilIonicContent = (
  ionicContent: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_TEMPERATURE }: TUnitConverterOptionsWithoutUnits = {},
) => {
  let ionicContentConvertedValue: number | null

  if (isNil(ionicContent)) {
    ionicContentConvertedValue = null
  } else {
    ionicContentConvertedValue = Number(ionicContent)
  }

  return {
    categoryTitle: () => translate.phrases.banyanApp('Ionic Content'),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Ionic Content'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_IONIC,
    suffix: () => '',
    shortTitle: () => translate.phrases.banyanApp('Ionic Content'),
    title: () => translate.phrases.banyanApp('Ionic Content'),
    titleWithoutUnit: () => translate.phrases.banyanApp('Ionic Content'),
    value: () => {
      if (isNil(ionicContentConvertedValue)) return null

      return +ionicContentConvertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () =>
      `${ionicContentConvertedValue?.toFixed(decimalPlaces) ?? translate.phrases.templates('-')}`,
    valueWithSuffix: () => {
      if (isNil(ionicContentConvertedValue)) return translate.phrases.templates('-')

      return `${ionicContentConvertedValue.toFixed(decimalPlaces)}`
    },
    valueWithNoRounding: () => ionicContentConvertedValue,
  }
}

const soilAwc = (
  soilAWC: number | null = null,
  { decimalPlaces = DEFAULT_DECIMAL_PLACES_TEMPERATURE }: TUnitConverterOptionsWithoutUnits = {},
) => {
  let convertedValue: number | null

  if (isNil(soilAWC)) {
    convertedValue = null
  } else {
    convertedValue = Number(soilAWC)
  }

  const unitSymbol = translate.measurements.percentage.unit()

  return {
    categoryTitle: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Available Water Content'),
        unitSymbol,
      }),
    categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Available Water Content'),
    defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_AWC,
    suffix: () => unitSymbol,
    shortTitle: () => translate.phrases.abbreviations('Available Water Content'),
    title: () =>
      translate.phrases.templates('{{label}} ({{unitSymbol}})', {
        label: translate.phrases.banyanApp('Available Water Content'),
        unitSymbol,
      }),
    titleWithoutUnit: () => translate.phrases.banyanApp('Available Water Content'),
    value: () => {
      if (isNil(convertedValue)) return null

      return +convertedValue?.toFixed(decimalPlaces)
    },
    valueAsString: () => translate.measurements.percentage.value(convertedValue, decimalPlaces),
    valueWithSuffix: () => translate.measurements.percentage.valueWithUnit(convertedValue, decimalPlaces),
    valueWithNoRounding: () => convertedValue,
  }
}

type TSoilOptions = 'soilTemperature' | 'soilIonicContent' | 'soilAwc' | 'soilDepth'

export const soil: Record<
  TSoilOptions,
  (value?: number | null, options?: TUnitConverterOptionsWithoutUnits) => TUnitConverterTypicalReturn
> = {
  soilTemperature,
  soilIonicContent,
  soilAwc,
  soilDepth,
}
