import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { checkAuthorization } from 'utils/checkAuthorization'
import { getSelectedRegion } from 'utils/getSelectedRegion'

export const selectedRegionHasPermission = ({
  permission,
  regionId = getSelectedRegion({}),
}: {
  permission: TPermission
  regionId?: string | null
}) => {
  if (!regionId) return false

  return checkAuthorization({ permission, entity: regionId })
}
