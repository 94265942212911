import type { TPhrases } from '@semios/app-platform-i18n-core'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'

export const getInsectNameFromId = (insectId?: TFieldAssetKeyTypes.TInsectId) => {
  const fallBackName = translate.phrases.banyanApp('Unknown Insect')

  if (!insectId) return fallBackName

  const englishInsectName = fieldAssetStore.getState()?.insects?.[insectId]?.name

  if (!englishInsectName) return fallBackName

  return translate.phrases.dbInsectName(englishInsectName as TPhrases['dbInsectName'])
}
