import type { TChartSeries } from 'components/StackedChart/types'
import { isEmpty } from 'lodash'
import { colors } from 'settings/colors'
import { RootZoneWarning } from './RootZoneWarning'

export const AwcLegendItems = ({
  legendItems,
  seriesLegendOn = false,
  displayRootZoneWarning = false,
}: {
  legendItems: (TChartSeries & { fillColor: string })[]
  seriesLegendOn?: boolean
  displayRootZoneWarning?: boolean
}) => {
  const fontSize = 12

  const colorStyles = (color: string, fontSize: number, type?: string) => ({
    margin: '0px 4px 0px 10px',
    height: type === 'line' ? 2 : fontSize,
    width: fontSize,
    background: color,
    display: 'inline-block',
    borderRadius: '3px',
  })

  if (isEmpty(legendItems)) return null

  return (
    <>
      {displayRootZoneWarning && <RootZoneWarning />}
      <div
        css={{
          marginLeft: 'auto',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginTop: !seriesLegendOn ? '20px' : '0px',
        }}
      >
        {Object.entries(legendItems).map(([legendItem, content], i) => {
          const { name, color, fillColor, type } = content
          const selectedColor = fillColor ?? color ?? colors.midnight

          return (
            <div css={{ display: 'flex', alignItems: 'center' }} key={`${legendItem}_${i}`}>
              <span css={{ ...colorStyles(selectedColor as string, fontSize, type) }} />
              <span css={{ fontSize: '12px' }}>{name}</span>
            </div>
          )
        })}
      </div>
    </>
  )
}
