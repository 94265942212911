import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TDevice } from 'App/ServiceCenter/types'
import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'

export type ImperialXorMetric = 'IMPERIAL' | 'METRIC'

const storeName = 'fieldAssetsStore'

export type TFieldAssetStoreState = {
  crops: routes.UserAppStartup.Response['crops']
  devices: Record<string, TDevice>
  diseases: routes.UserAppStartup.Response['diseases']
  fieldAssetDataMD5Hash?: string
  insects: routes.UserAppStartup.Response['insects']
  properties: routes.UserAppStartup.Response['properties']
  regions: routes.UserAppStartup.Response['regions']
  regionsGeometries: Record<string, string>
}

export const fieldAssetStore = persistentInit<TFieldAssetStoreState>({
  initialState: {
    crops: {},
    devices: {},
    diseases: {},
    fieldAssetDataMD5Hash: 'NOTHINGHERE',
    insects: {},
    properties: {},
    regions: {},
    regionsGeometries: {},
  },
  keysToPutInPersistentStorage: {
    crops: true,
    devices: true,
    diseases: true,
    fieldAssetDataMD5Hash: true,
    insects: true,
    properties: true,
    regions: true,
    regionsGeometries: false,
  },
  keysToPutInURL: {
    crops: false,
    devices: false,
    diseases: false,
    fieldAssetDataMD5Hash: false,
    insects: false,
    properties: false,
    regions: false,
    regionsGeometries: false,
  },
  storeName,
})
