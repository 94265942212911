import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'
import type { TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES = 1

type TInsectDegreeDaysUnit = 'c' | 'f'

const insectDegreeDaysFunctionMaker =
  ({ cumulative }: { cumulative: boolean }) =>
  (
    degreeDaysAccumulatedTemperature: number | null = null,
    {
      insectId = -1,
      decimalPlaces = DEFAULT_DECIMAL_PLACES,
      inputUnit = 'c',
      outputUnit = userDetailsStore.getState().temperature === 'IMPERIAL' ? 'f' : 'c',
    }: {
      insectId?: number
      decimalPlaces?: number
      inputUnit?: TInsectDegreeDaysUnit
      outputUnit?: TInsectDegreeDaysUnit
    } = {},
  ) => {
    let convertedValue: number | null

    const shouldConvertFromCToF = inputUnit === 'c' && outputUnit === 'f'
    const shouldConvertFromFToC = inputUnit === 'f' && outputUnit === 'c'

    if (isNil(degreeDaysAccumulatedTemperature)) {
      convertedValue = null
    } else if (shouldConvertFromCToF) {
      convertedValue = degreeDaysAccumulatedTemperature * 1.8
    } else if (shouldConvertFromFToC) {
      convertedValue = degreeDaysAccumulatedTemperature / 1.8
    } else {
      // assume there is no conversion required
      convertedValue = Number(degreeDaysAccumulatedTemperature)
    }

    const unitSymbol =
      outputUnit === 'c'
        ? translate.measurements.degreeDaysCelsius.unit()
        : translate.measurements.degreeDaysFahrenheit.unit()

    return {
      categoryTitle: () =>
        translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('Degree Days'),
          unitSymbol,
        }),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Degree Days'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES,
      suffix: () => unitSymbol,
      shortTitle: () => {
        if (cumulative) {
          return translate.phrases.banyanApp('{{insectName}} Cumulative Degree Days', {
            insectName: getInsectNameFromId(insectId),
          })
        }

        return translate.phrases.banyanApp('{{insectName}} Degree Days', {
          insectName: getInsectNameFromId(insectId),
        })
      },
      title: () => {
        if (cumulative) {
          return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
            label: translate.phrases.banyanApp('{{insectName}} Cumulative Degree Days', {
              insectName: getInsectNameFromId(insectId),
            }),
            unitSymbol,
          })
        }

        return translate.phrases.templates('{{label}} ({{unitSymbol}})', {
          label: translate.phrases.banyanApp('{{insectName}} Degree Days', {
            insectName: getInsectNameFromId(insectId),
          }),
          unitSymbol,
        })
      },
      titleWithoutUnit: () => {
        if (cumulative) {
          return translate.phrases.banyanApp('{{insectName}} Cumulative Degree Days', {
            insectName: getInsectNameFromId(insectId),
          })
        }

        return translate.phrases.banyanApp('{{insectName}} Degree Days', {
          insectName: getInsectNameFromId(insectId),
        })
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        return outputUnit === 'c'
          ? translate.measurements.degreeDaysCelsius.value(convertedValue, decimalPlaces)
          : translate.measurements.degreeDaysFahrenheit.value(convertedValue, decimalPlaces)
      },
      valueWithSuffix: () => {
        return outputUnit === 'c'
          ? translate.measurements.degreeDaysCelsius.valueWithUnit(convertedValue, decimalPlaces)
          : translate.measurements.degreeDaysFahrenheit.valueWithUnit(convertedValue, decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

type TInsectDegreeDaysOptions = 'insectDegreeDays' | 'insectDegreeDaysCumulative'

export const insectDegreeDays: Record<
  TInsectDegreeDaysOptions,
  (
    degreeDaysAccumulatedTemperature?: number | null,
    options?: {
      insectId?: number
      decimalPlaces?: number
      outputUnit?: TInsectDegreeDaysUnit
      inputUnit?: TInsectDegreeDaysUnit
    },
  ) => TUnitConverterTypicalReturn
> = {
  insectDegreeDays: insectDegreeDaysFunctionMaker({ cumulative: false }),
  insectDegreeDaysCumulative: insectDegreeDaysFunctionMaker({ cumulative: true }),
}
