import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconNotification = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M10 20C11.1 20 12 19.0769 12 17.9487H8C8 19.0769 8.89 20 10 20ZM16 13.8462V8.71795C16 5.56923 14.36 2.93333 11.5 2.2359V1.53846C11.5 0.68718 10.83 0 10 0C9.17 0 8.5 0.68718 8.5 1.53846V2.2359C5.63 2.93333 4 5.55897 4 8.71795V13.8462L2 15.8974V16.9231H18V15.8974L16 13.8462Z"
        fill="currentColor"
      />
    </svg>
  )
}
