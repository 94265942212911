import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconStethoscope = () => (
  <svg {...makeBaseSvgIconProps('0 0 15 21')}>
    <path
      d="M3.37591 1H1.47394C1.19986 1 0.981127 1.2246 0.996969 1.49822C1.06859 2.73526 1.32948 5.95955 2.15568 7.522C3.14951 9.40143 5.6407 9.82131 5.83708 9.85171C5.84624 9.85313 5.85253 9.85422 5.86168 9.85575C6.03665 9.885 8.04388 10.157 9.44658 7.522C10.6068 5.34242 10.692 2.53296 10.6768 1.45167C10.6732 1.19638 10.4642 1 10.2089 1C9.86886 1 9.27359 1 8.23143 1"
      stroke="currentColor"
      strokeWidth="1.09149"
      strokeLinejoin="round"
    />
    <path
      d="M12.4234 14.8745C13.5707 14.8745 14.5007 13.9073 14.5007 12.7141C14.5007 11.5209 13.5707 10.5537 12.4234 10.5537C11.2761 10.5537 10.346 11.5209 10.346 12.7141C10.346 13.9073 11.2761 14.8745 12.4234 14.8745Z"
      fill="currentColor"
    />
    <path
      d="M5.8606 10.9956V16.6762C5.8606 18.5119 7.34871 20 9.18439 20V20C11.0201 20 12.5082 18.5119 12.5082 16.6762V13.5881"
      stroke="currentColor"
      strokeWidth="1.20519"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
