import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconDuplicate = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M15.5556 0H8.88889C6.44444 0 4.44444 2 4.44444 4.44444C2 4.44444 0 6.44444 0 8.88889V15.5556C0 18 2 20 4.44444 20H11.1111C13.5556 20 15.5556 18 15.5556 15.5556C18 15.5556 20 13.5556 20 11.1111V4.44444C20 2 18 0 15.5556 0ZM13.3333 15.5556C13.3333 16.7778 12.3333 17.7778 11.1111 17.7778H4.44444C3.22222 17.7778 2.22222 16.7778 2.22222 15.5556V8.88889C2.22222 7.66667 3.22222 6.66667 4.44444 6.66667H5.55556H11.1111C12.3333 6.66667 13.3333 7.66667 13.3333 8.88889V14.4444V15.5556ZM17.7778 11.1111C17.7778 12.3333 16.7778 13.3333 15.5556 13.3333V8.88889C15.5556 6.44444 13.5556 4.44444 11.1111 4.44444H6.66667C6.66667 3.22222 7.66667 2.22222 8.88889 2.22222H15.5556C16.7778 2.22222 17.7778 3.22222 17.7778 4.44444V11.1111Z"
        fill="currentColor"
      />
    </svg>
  )
}
