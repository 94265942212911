import {
  Input,
  LoadingOverlay,
  Radio,
  SegmentedControl,
  Textarea,
  Timeline,
  useMantineTheme,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import * as Sentry from '@sentry/react'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { showNotification } from 'utils/showNotification'
import { sortByKey } from 'utils/sortByKey'
import { checkAuthorization } from '../../../../utils/checkAuthorization'
import { openHelpGuideModal } from '../../HelpGuideModal/HelpGuideModal'
import { ToggleSymbolValue } from '../../ToggleSymbolValue/ToggleSymbolValue'
import type {
  TNodeServiceIssueDetail,
  TResolutionActionActual,
  TServiceIssueResolutionAction,
} from '../../types'
import { TResolutionType, TServiceIssueStatus } from '../../types'
import { addResolutionActionsToNodeServiceIssue } from '../../utils/addResolutionActionsToNodeServiceIssue'
import { serviceCenterNodeServiceIssueDetailGet } from '../../utils/api/serviceCenterNodeServiceIssueDetailGet'
import { serviceCenterServiceIssueResolutionActionsGet } from '../../utils/api/serviceCenterServiceIssueResolutionActionsGet'
import { renderNodeDeviceLabel } from '../../utils/renderNodeDeviceLabel'
import { updateNodeServiceIssueStatus } from '../../utils/updateNodeServiceIssueStatus'
import { getServiceIssueLabelIcon } from '../List/List'
import { checkHasIssueUpdatePermission } from '../NodeMaintenanceModal'

interface ServiceIssueDetailProps {
  serviceIssueId: number
  issueTypeId: number

  onIssueChange(): void
}

const getResolutionActionLabel = (actionTitle: string, resolutionType: TResolutionType) => {
  if (resolutionType === TResolutionType.REPAIR) {
    return actionTitle
  } else if (resolutionType === TResolutionType.SWAP) {
    return actionTitle.length
      ? translate.phrases.placeholder('Swap ({{reason}})', { reason: actionTitle })
      : translate.phrases.placeholder('Swap')
  } else if (resolutionType === TResolutionType.NO_ISSUE) {
    return translate.phrases.placeholder('No Issue')
  }
}

export const ServiceIssueDetail: React.FC<ServiceIssueDetailProps> = ({
  serviceIssueId,
  issueTypeId,
  onIssueChange,
}) => {
  const theme = useMantineTheme()
  const allDevices = fieldAssetStore.useSelector((s) => s.devices)
  const [serviceIssue, setServiceIssue] = useState<TNodeServiceIssueDetail | null>(null)
  const [resolutionType, setResolutionType] = useState<TResolutionType | null>(null)
  const [loading, setLoading] = useState(false)
  const form = useForm<{ resolutionActionId: string | null; resolutionDescription: string }>()

  const [resolutionActions, setResolutionActions] = useState<Record<string, TServiceIssueResolutionAction[]>>(
    {},
  )

  const fetchIssueDetail = useCallback(async (serviceIssueId: number) => {
    setLoading(true)

    try {
      const serviceIssueDetail = await serviceCenterNodeServiceIssueDetailGet(serviceIssueId)

      setServiceIssue(serviceIssueDetail)
    } catch (error) {
      Sentry.captureException(error)

      showNotification({
        type: 'error',
        message: translate.phrases.placeholder('Error: could not load service issue!'),
      })
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    // Fetch issue detail on initial load
    fetchIssueDetail(serviceIssueId)
  }, [serviceIssueId])

  useEffect(() => {
    const fetchResolutionActions = async () => {
      try {
        const resolutionActions = await serviceCenterServiceIssueResolutionActionsGet(issueTypeId)

        setResolutionActions(resolutionActions)
      } catch (error) {
        Sentry.captureException(error)

        showNotification({
          type: 'error',
          message: translate.phrases.placeholder('Error: could not load resolution actions!'),
        })
      }
    }

    // Fetch resolution actions on initial load
    if (
      checkAuthorization({
        permission: 'VIEW_SSC_SERVICE_ISSUE_RESOLUTION_ACTIONS',
        entity: '*',
      })
    )
      fetchResolutionActions()
  }, [issueTypeId])

  useEffect(() => {
    // Reset resolution action field when a resolution type is selected
    if (resolutionType) form.setFieldValue('resolutionActionId', null)
  }, [resolutionType])

  const handleSubmit = async (newStatus?: TServiceIssueStatus) => {
    setLoading(true)

    if (!serviceIssue) return

    let { resolutionActionId, resolutionDescription } = form.values

    let updatedServiceIssue = {
      ...serviceIssue,
      resolutionActionsActual: [...(serviceIssue.resolutionActionsActual || [])],
    }

    try {
      // If NO_ISSUE type is selected, find corresponding resolution action
      if (resolutionType === TResolutionType.NO_ISSUE) {
        resolutionActionId = resolutionActions[TResolutionType.NO_ISSUE][0].issueResolutionActionId.toString()
      }

      if (resolutionType && resolutionActionId) {
        await addResolutionActionsToNodeServiceIssue(
          serviceIssue.serviceIssueId,
          [+resolutionActionId],
          resolutionDescription,
        )

        updatedServiceIssue.resolutionActionsActual = [
          ...updatedServiceIssue.resolutionActionsActual,
          {
            resolutionActionId: +resolutionActionId,
            actionTitle:
              resolutionActions[resolutionType]?.find(
                (action) => resolutionActionId && action.issueResolutionActionId === +resolutionActionId,
              )?.resolutionTitle || '',
            actionType: resolutionType,
            resolvedOn: new Date().toISOString(),
          } as TResolutionActionActual,
        ]

        updatedServiceIssue.resolutionDescription = resolutionDescription
      }

      if (newStatus) {
        await updateNodeServiceIssueStatus(serviceIssue.serviceIssueId, newStatus)

        updatedServiceIssue.serviceIssueStatus = newStatus
      }

      setServiceIssue(updatedServiceIssue)

      // Reset fields
      setResolutionType(null)

      form.setFieldValue('resolutionActionId', null)

      // Reload updated service issue
      fetchIssueDetail(serviceIssueId)

      // Notify parent component of the issue change (will trigger node service issues reload)
      onIssueChange()
    } catch (error) {
      Sentry.captureException(error)

      showNotification({
        type: 'error',
        message: translate.phrases.placeholder('Error: could not update service issue!'),
      })
    } finally {
      setLoading(false)
    }
  }

  const isServiceCompleted = !!serviceIssue?.serviceCompletedOn

  const canEditIssue =
    checkHasIssueUpdatePermission() &&
    (serviceIssue?.serviceIssueStatus === TServiceIssueStatus.CREATED ||
      serviceIssue?.serviceIssueStatus === TServiceIssueStatus.FLAGGED_FOR_SERVICE)

  const canSubmitFix =
    !!resolutionType && (!!form.values.resolutionActionId || resolutionType === TResolutionType.NO_ISSUE)

  const canReopenIssue = serviceIssue?.serviceIssueStatus === TServiceIssueStatus.SERVICE_COMPLETED
  const issueDevice = serviceIssue?.devices?.find((device) => device.selected) || null

  const history = useMemo(() => {
    const history: {
      title: string
      description?: string | null
      datetime: string
      actionIcon?: JSX.Element | null
    }[] = []

    if (serviceIssue?.reportedOn) {
      history.push({
        title: translate.phrases.placeholder('Issue Created'),
        datetime: serviceIssue.reportedOn,
        actionIcon: (
          <span style={{ fontSize: 24, paddingTop: 4 }}>
            {getServiceIssueLabelIcon(TServiceIssueStatus.CREATED)}
          </span>
        ),
      })
    }

    if (serviceIssue?.flaggedForServiceOn) {
      history.push({
        title: translate.phrases.placeholder('Issue flagged for service'),
        datetime: serviceIssue.flaggedForServiceOn,
        actionIcon: (
          <span style={{ fontSize: 24, paddingTop: 4 }}>
            {getServiceIssueLabelIcon(TServiceIssueStatus.FLAGGED_FOR_SERVICE)}
          </span>
        ),
      })
    }

    serviceIssue?.resolutionActionsActual?.forEach((resolutionAction, index) => {
      history.push({
        title: getResolutionActionLabel(resolutionAction.actionTitle, resolutionAction.actionType) || '',
        description:
          serviceIssue.resolutionActionsActual &&
          index === serviceIssue.resolutionActionsActual.length - 1 &&
          serviceIssue.resolutionDescription
            ? serviceIssue.resolutionDescription
            : null,
        datetime: resolutionAction.resolvedOn,
      })
    })

    if (serviceIssue?.serviceCompletedOn) {
      history.push({
        title: translate.phrases.placeholder('Service Completed'),
        datetime: serviceIssue.serviceCompletedOn,
        actionIcon: (
          <span style={{ fontSize: 24, paddingTop: 4 }}>
            {getServiceIssueLabelIcon(TServiceIssueStatus.SERVICE_COMPLETED)}
          </span>
        ),
      })
    }

    if (serviceIssue?.issueConclusionOn) {
      history.push({
        title: translate.phrases.placeholder('Issue Closed'),
        datetime: serviceIssue.issueConclusionOn,
        actionIcon: (
          <span
            style={{
              fontSize: 14,
              paddingTop: 2,
            }}
          >
            {getServiceIssueLabelIcon(serviceIssue.serviceIssueStatus)}
          </span>
        ),
      })
    }

    return history.sort(sortByKey('datetime'))
  }, [serviceIssue?.resolutionActionsActual])

  const remainingSuggestedActions = useMemo(() => {
    return serviceIssue?.resolutionActionsSuggested?.filter(
      (suggestedAction) =>
        !serviceIssue.resolutionActionsActual?.some(
          (actualAction) => actualAction.resolutionActionId === suggestedAction.resolutionActionId,
        ),
    )
  }, [serviceIssue?.resolutionActionsActual, serviceIssue?.resolutionActionsSuggested])

  return (
    <div
      css={{
        padding: 10,
        marginBottom: 62,
        minHeight: '100%',
      }}
    >
      <LoadingOverlay visible={loading} />

      <div
        style={{
          backgroundColor: theme.colors.grey[0],
          padding: 10,
          paddingBottom: 0,
          marginBottom: 10,
        }}
      >
        <div css={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10 }}>
          <span css={{ fontWeight: 'bold' }}>{translate.phrases.placeholder('Reported Issue')}</span>
          <span css={{ textAlign: 'right' }}>{serviceIssue?.nodeServiceIssueTypeTitle}</span>
        </div>

        {!!issueDevice && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: 10,
            }}
          >
            <span css={{ fontWeight: 'bold' }}>{translate.phrases.placeholder('Device')}</span>
            <span css={{ textAlign: 'right' }}>{renderNodeDeviceLabel(issueDevice, allDevices)}</span>
          </div>
        )}

        {serviceIssue?.issueDescription && (
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: 10,
            }}
          >
            <span css={{ fontWeight: 'bold', marginRight: 10 }}>
              {translate.phrases.placeholder('Description')}
            </span>
            <span css={{ textAlign: 'justify' }}>{serviceIssue?.issueDescription}</span>
          </div>
        )}
      </div>

      {!!history.length && (
        <>
          <h4 css={{ margin: '20px 0 10px' }}>{translate.phrases.placeholder('History')}</h4>
          <Timeline bulletSize={24}>
            {history.map(({ title, description, datetime, actionIcon }) => (
              <Timeline.Item
                key={datetime}
                title={
                  <span style={{ color: actionIcon ? theme.colors.grey[2] : theme.colors.midnight[0] }}>
                    {title}
                  </span>
                }
                bullet={actionIcon}
                bulletSize={24}
              >
                {!!description && (
                  <div
                    css={{
                      fontSize: 12,
                      color: theme.colors.grey[2],
                      marginBottom: 5,
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {description}
                  </div>
                )}
                <span
                  style={{
                    fontSize: 12,
                    color: actionIcon ? theme.colors.grey[2] : theme.colors.midnight[0],
                  }}
                >
                  <ToggleSymbolValue value={moment.tz(datetime, moment.tz.guess()).format('lll z')}>
                    {moment.tz(datetime, 'UTC').fromNow()}
                  </ToggleSymbolValue>
                </span>
              </Timeline.Item>
            ))}
          </Timeline>
        </>
      )}

      {!!remainingSuggestedActions?.length && !isServiceCompleted && (
        <>
          <h4 css={{ margin: '20px 0 10px' }}>{translate.phrases.placeholder('Suggested Actions')}</h4>
          <Timeline bulletSize={24} active={0} styles={{ itemBullet: { fontWeight: 600 } }}>
            {remainingSuggestedActions.map(({ resolutionActionId, actionTitle, actionType }, index) => {
              const resolutionAction = resolutionActions[actionType]?.find(
                (action) => action.issueResolutionActionId === resolutionActionId,
              )

              return (
                <Timeline.Item
                  key={resolutionActionId}
                  title={
                    <div css={{ position: 'relative', top: resolutionAction?.resolutionDescription ? 0 : 5 }}>
                      {getResolutionActionLabel(resolutionAction?.resolutionTitle || actionTitle, actionType)}
                    </div>
                  }
                  bullet={index + 1}
                  lineVariant="dotted"
                >
                  {resolutionAction?.resolutionDescription && (
                    <div css={{ fontSize: 12, color: theme.colors.grey[2] }}>
                      {resolutionAction?.resolutionDescription}
                    </div>
                  )}
                </Timeline.Item>
              )
            })}
          </Timeline>
        </>
      )}

      <form>
        {canEditIssue && (
          <>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 10,
              }}
            >
              <h4>{translate.phrases.placeholder('Action')}</h4>
              <Button variant="link" onClick={() => openHelpGuideModal()}>
                {translate.phrases.placeholder('Assembly & Install Guides')}
              </Button>
            </div>
            <SegmentedControl
              fullWidth
              value={resolutionType as string}
              onChange={(t) => setResolutionType(t as TResolutionType)}
              data={[
                {
                  label: translate.phrases.placeholder('Repair'),
                  value: TResolutionType.REPAIR,
                  disabled: !Object.keys(resolutionActions).includes(TResolutionType.REPAIR),
                },
                {
                  label: translate.phrases.placeholder('Swap'),
                  value: TResolutionType.SWAP,
                  disabled: !Object.keys(resolutionActions).includes(TResolutionType.SWAP),
                },
                {
                  label: translate.phrases.placeholder('No Issue'),
                  value: TResolutionType.NO_ISSUE,
                  disabled: !Object.keys(resolutionActions).includes(TResolutionType.NO_ISSUE),
                },
              ]}
            />
          </>
        )}

        {!!resolutionType && (
          <div css={{ marginTop: 10 }}>
            {resolutionType !== TResolutionType.NO_ISSUE && (
              <Radio.Group {...form.getInputProps('resolutionActionId')}>
                {resolutionActions[resolutionType]
                  ?.filter((resolutionAction) => !resolutionAction.deprecated)
                  // sort by resolution title and then by order number
                  .sort(sortByKey('resolutionTitle'))
                  .sort(sortByKey('orderNumber'))
                  .map((resolutionAction) => (
                    <Radio
                      styles={{
                        root: {
                          padding: '5px 0',
                        },
                        radio: {
                          cursor: 'pointer',
                        },
                        label: {
                          cursor: 'pointer',
                        },
                      }}
                      key={resolutionAction.issueResolutionActionId}
                      value={resolutionAction.issueResolutionActionId.toString()}
                      label={
                        <>
                          <div css={{ fontSize: '14px', fontWeight: 600 }}>
                            {resolutionAction.resolutionTitle}
                          </div>

                          {resolutionAction.resolutionDescription && (
                            <div css={{ fontSize: 12, color: theme.colors.grey[2] }}>
                              {resolutionAction.resolutionDescription}
                            </div>
                          )}
                        </>
                      }
                    />
                  ))}
              </Radio.Group>
            )}

            <Input.Wrapper label={translate.phrases.placeholder('Additional Notes')} css={{ marginTop: 20 }}>
              <Textarea
                css={{ margin: '10px auto', display: 'block' }}
                {...form.getInputProps('resolutionDescription')}
              />
            </Input.Wrapper>
          </div>
        )}

        {(canEditIssue || canReopenIssue) && (
          <div
            css={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              backgroundColor: theme.colors.grey[0],
              borderTop: `2px solid ${theme.colors.grey[1]}`,
              width: '100%',
              padding: 10,
              paddingBottom: 'max(10px, env(safe-area-inset-bottom))',
              display: 'flex',
              justifyContent: 'space-between',
              zIndex: 10,
            }}
          >
            {canEditIssue && (
              <>
                <Button
                  variant={serviceIssue?.resolutionActionsActual?.length ? 'secondary' : 'primary'}
                  css={{ flex: '0 1 auto' }}
                  onClick={() => handleSubmit()}
                  disabled={!canSubmitFix}
                  fullWidth={true}
                  loading={loading}
                >
                  {translate.phrases.placeholder('Submit Fix')}
                </Button>

                {!!serviceIssue?.resolutionActionsActual?.length && (
                  <Button
                    variant="primary"
                    css={{ marginLeft: 10, flex: '0 1 auto' }}
                    onClick={() => handleSubmit(TServiceIssueStatus.SERVICE_COMPLETED)}
                    fullWidth={true}
                    loading={loading}
                  >
                    {translate.phrases.placeholder('Complete Service')}
                  </Button>
                )}
              </>
            )}

            {canReopenIssue && (
              <Button
                variant="primary"
                css={{ flex: '0 1 auto' }}
                onClick={() => handleSubmit(TServiceIssueStatus.FLAGGED_FOR_SERVICE)}
                fullWidth={true}
                loading={loading}
              >
                {translate.phrases.placeholder('Re-open Issue')}
              </Button>
            )}
          </div>
        )}
      </form>
    </div>
  )
}
