import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'

export const getFixedValueOrUnknown = (value: number | null | undefined, valueDecimals?: number) => {
  if (value === undefined) return undefined

  if (isNil(value) || !isFinite(value)) return translate.phrases.templates('-')

  if (!isNil(valueDecimals)) return value.toFixed(valueDecimals)

  return `${value}`
}
