import { IconContact } from 'components/icons/IconContact'
import { IconGroups } from 'components/icons/IconGroups'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import type { AlertContactMethod, Contact, Group } from 'stores/userDetailsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { ContactRow } from './ContactRow'
import { EmailIcon } from './EmailIcon'
import { SmsIcon } from './SmsIcon'

type ContactListProps = {
  contacts: Contact[]
  groups: Group[]
  setContacts: Dispatch<SetStateAction<Contact[]>>
  setGroups: Dispatch<SetStateAction<Group[]>>
  notifyOwnerByEmail: boolean
  notifyOwnerBySMS: boolean
  setNotifyOwnerByEmail: Dispatch<SetStateAction<boolean>>
  setNotifyOwnerBySMS: Dispatch<SetStateAction<boolean>>
  contactsIsValid: boolean
}

type THandleIconClick = {
  contactType: 'contact' | 'group'
  alertsContactMethods: AlertContactMethod[] | null
  id: string
  iconType: 'email' | 'sms'
}

export const ICON_SIZE = 30

export const ContactList = ({
  contacts,
  groups,
  setContacts,
  setGroups,
  notifyOwnerByEmail,
  notifyOwnerBySMS,
  setNotifyOwnerByEmail,
  setNotifyOwnerBySMS,
  contactsIsValid,
}: ContactListProps) => {
  const { firstName, lastName, email: userEmail, smsNumber: userSMS } = userDetailsStore.useSelector((s) => s)

  const handleIconClick = ({ contactType, alertsContactMethods, id, iconType }: THandleIconClick) => {
    if (contactType === 'contact') {
      const isSelected = alertsContactMethods ? alertsContactMethods.includes(iconType) : false

      const newContacts = contacts.map((contact) => {
        if (contact.id === id) {
          const newAlertcontactMethods: AlertContactMethod[] = isSelected
            ? (alertsContactMethods || []).filter((method: AlertContactMethod) => method !== iconType)
            : [...(alertsContactMethods || []), iconType]

          return {
            ...contact,
            alertsContactMethods: newAlertcontactMethods,
          }
        }

        return contact
      })

      setContacts(newContacts)
    }

    if (contactType === 'group') {
      const isSelected = alertsContactMethods ? alertsContactMethods.includes(iconType) : false

      const newGroups = groups.map((group) => {
        if (group.id === id) {
          const newAlertcontactMethods: AlertContactMethod[] = isSelected
            ? (alertsContactMethods || []).filter((method: AlertContactMethod) => method !== iconType)
            : [...(alertsContactMethods || []), iconType]

          return {
            ...group,
            alertsContactMethods: newAlertcontactMethods,
          }
        }

        return group
      })

      setGroups(newGroups)
    }
  }

  return (
    <div>
      {/** OWNER */}
      <ContactRow
        key="owner"
        name={`${translate.phrases.banyanApp('Me')} (${firstName} ${lastName})`}
        leftIcon={<IconContact />}
        email={
          userEmail ? (
            <EmailIcon
              isEnabled={notifyOwnerByEmail}
              handleEmailIconClick={() => {
                setNotifyOwnerByEmail(!notifyOwnerByEmail)
              }}
            />
          ) : null
        }
        sms={
          userSMS ? (
            <SmsIcon
              isEnabled={notifyOwnerBySMS}
              handleSmsIconClick={() => {
                setNotifyOwnerBySMS(!notifyOwnerBySMS)
              }}
            />
          ) : null
        }
        isError={false}
      />
      {/** CONTACTS */}
      {contacts.map((contact) => {
        const { alertsContactMethods, sms, email } = contact
        const isEmailEnabled = alertsContactMethods?.includes('email')
        const isSMSEnabled = alertsContactMethods?.includes('sms')
        const isError = !contactsIsValid && !isEmailEnabled && !isSMSEnabled

        return (
          <ContactRow
            key={contact.id}
            name={contact.name}
            leftIcon={<IconContact />}
            email={
              email ? (
                <EmailIcon
                  isEnabled={isEmailEnabled}
                  handleEmailIconClick={() => {
                    handleIconClick({
                      contactType: 'contact',
                      alertsContactMethods,
                      id: contact.id,
                      iconType: 'email',
                    })
                  }}
                />
              ) : null
            }
            sms={
              sms ? (
                <SmsIcon
                  isEnabled={isSMSEnabled}
                  handleSmsIconClick={() => {
                    handleIconClick({
                      contactType: 'contact',
                      alertsContactMethods,
                      id: contact.id,
                      iconType: 'sms',
                    })
                  }}
                />
              ) : null
            }
            isError={isError}
          />
        )
      })}
      {/** GROUPS */}
      {groups.map((group) => {
        const { alertsContactMethods } = group
        const isEmailEnabled = alertsContactMethods ? alertsContactMethods.includes('email') : false
        const isSMSEnabled = alertsContactMethods ? alertsContactMethods.includes('sms') : false

        return (
          <ContactRow
            key={group.id}
            name={`${group.name} (${group.contacts.length})`}
            leftIcon={<IconGroups />}
            email={
              <EmailIcon
                isEnabled={isEmailEnabled}
                handleEmailIconClick={() => {
                  handleIconClick({
                    contactType: 'group',
                    alertsContactMethods,
                    id: group.id,
                    iconType: 'email',
                  })
                }}
              />
            }
            sms={
              <SmsIcon
                isEnabled={isSMSEnabled}
                handleSmsIconClick={() => {
                  handleIconClick({
                    contactType: 'group',
                    alertsContactMethods,
                    id: group.id,
                    iconType: 'sms',
                  })
                }}
              />
            }
            isError={false}
          />
        )
      })}
    </div>
  )
}
