import { NfcScanner } from 'components/NfcScanner/NfcScanner'
import { QrCodeScanner } from 'components/QrCodeScanner/QrCodeScanner'
import { useEffect, useState } from 'react'

export const useScannerSupportCheck = () => {
  const [isSupportQR, setIsSupportQR] = useState(false)
  const [isSupportNfc, setIsSupportNfc] = useState(false)

  useEffect(() => {
    const checkSupportScan = async () => {
      setIsSupportQR(await QrCodeScanner.isSupported())

      setIsSupportNfc(await NfcScanner.isSupported())
    }

    checkSupportScan()
  }, [])

  return {
    NFC: {
      supported: isSupportNfc,
    },
    QR: {
      supported: isSupportQR,
    },
  }
}
