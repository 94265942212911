import { translate } from 'i18n/i18n'

export const administrativeAreaLevel1T = ({
  country,
  gAdministrativeAreaLevel1: l1,
}: {
  country: string
  gAdministrativeAreaLevel1: string
}) => {
  const administrationAreaLevel1 = translate.phrases.administrationAreaLevel1

  /* eslint-disable padding-line-between-statements */
  if (country === 'AU' && l1 === 'NSW') return administrationAreaLevel1('New South Wales (AU)')
  if (country === 'AU' && l1 === 'SA') return administrationAreaLevel1('South Australia (AU)')
  if (country === 'AU' && l1 === 'VIC') return administrationAreaLevel1('Victoria (AU)')
  if (country === 'CA' && l1 === 'BC') return administrationAreaLevel1('British Columbia (CA)')
  if (country === 'CA' && l1 === 'ON') return administrationAreaLevel1('Ontario (CA)')
  if (country === 'US' && l1 === 'AZ') return administrationAreaLevel1('Arizona (US)')
  if (country === 'US' && l1 === 'CA') return administrationAreaLevel1('California (US)')
  if (country === 'US' && l1 === 'ID') return administrationAreaLevel1('Idaho (US)')
  if (country === 'US' && l1 === 'MS') return administrationAreaLevel1('Mississippi (US)')
  if (country === 'US' && l1 === 'OR') return administrationAreaLevel1('Oregon (US)')
  if (country === 'US' && l1 === 'TN') return administrationAreaLevel1('Tennessee (US)')
  if (country === 'US' && l1 === 'TX') return administrationAreaLevel1('Texas (US)')
  if (country === 'US' && l1 === 'WA') return administrationAreaLevel1('Washington (US)')
  /* eslint-enable padding-line-between-statements */

  // if we made it this far, it's not implemented...

  return `${l1} (${country})`
}
