import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconBluetooth = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 13 20')}>
      <path
        d="M12.3404 5.71L6.79646 0H5.82554V7.59L1.369 3L0 4.41L5.42746 10L0 15.59L1.369 17L5.82554 12.41V20H6.79646L12.3404 14.29L8.16546 10L12.3404 5.71ZM7.76738 3.83L9.59272 5.71L7.76738 7.59V3.83ZM9.59272 14.29L7.76738 16.17V12.41L9.59272 14.29Z"
        fill="currentColor"
      />
    </svg>
  )
}
