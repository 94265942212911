export const TranslatePhraseWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  )
}
