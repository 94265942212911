import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import type { TValuesCurrentPointsValueTypes } from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

const updateMapVisuals = (newValueType: TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL.POINT]: {
        ...s.mapVisualValueGroup[MAP_VISUAL.POINT],
        fruit_growth: {
          ...(s.mapVisualValueGroup[MAP_VISUAL.POINT].fruit_growth ?? { mItem: 'M_InBlockPoint' }),
          valueType: newValueType,
        },
      },
    },
  }))
}

export const FruitGrowthSecondaryDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.fruit_growth?.valueType,
  )

  const data: { value: TValuesCurrentPointsValueTypes; label: string }[] = useMemo(() => {
    return [
      {
        value: 'fruitSize_um',
        label: translate.phrases.banyanApp('Fruit Size'),
      },
      {
        value: 'fruitGrowth_last7DaysSum_um',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Fruit Growth'),
          labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 7 }),
        }),
      },
      {
        value: 'fruitGrowth_last14DaysSum_um',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Fruit Growth'),
          labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 14 }),
        }),
      },
      {
        value: 'fruitGrowth_last30DaysSum_um',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Fruit Growth'),
          labelB: translate.phrases.banyanApp('Last {{count}} Days', { count: 30 }),
        }),
      },
      {
        value: 'fruitGrowth_next14DaysSum_um',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Fruit Growth'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 14 }),
        }),
      },
      {
        value: 'fruitGrowthTarget_next14DaysSum_um',
        label: translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Fruit Growth Target'),
          labelB: translate.phrases.banyanApp('Next {{count}} Days', { count: 14 }),
        }),
      },
    ]
  }, [value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
