import { ActionIcon, Notification } from '@mantine/core'
import { IconWarning } from 'components/icons/IconWarning'
import type { FC } from 'react'
import React, { useState } from 'react'
import { colors } from 'settings/colors'
import { smallStore } from 'stores/smallStore'

export const MaintenanceBanner: FC<{ children: React.ReactNode }> = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const safeArea = smallStore.useSelector((s) => s.safeArea)

  if (isCollapsed) {
    return (
      <ActionIcon
        css={{
          position: 'fixed',
          top: safeArea.insets.top + 8,
          left: safeArea.insets.left + 8,
          zIndex: 10001,
          border: `3px solid ${colors.white}`,
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
          width: 32,
          height: 32,
          backgroundColor: colors.white,
        }}
        onClick={() => setIsCollapsed(false)}
        radius={'xl'}
        variant={'transparent'}
      >
        <span css={{ fontSize: 28, color: colors.red, position: 'absolute', top: -1 }}>
          <IconWarning />
        </span>
      </ActionIcon>
    )
  } else {
    return (
      <Notification
        icon={
          <span css={{ fontSize: 28, color: colors.red, backgroundColor: colors.white, paddingTop: 6 }}>
            <IconWarning />
          </span>
        }
        css={{
          position: 'fixed',
          paddingTop: safeArea.insets.top + 10,
          top: 0,
          zIndex: 10001,
          width: '100%',
          border: 'none',
          borderRadius: 0,
        }}
        onClose={() => setIsCollapsed(true)}
      >
        {props.children}
      </Notification>
    )
  }
}
