import type { routes } from '@semios/app-platform-banyan-route-definitions'
import {
  irrigationSchedulerStore,
  TScheduledEventEnum,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'

export const pulseEventsDelete = (apiEvents: routes.IrrigationSchedulerEventsDelete.Response) => {
  const pulseEventsToDelete: number[] = []
  const pulseEventsToMarkAsDeletedDraft: number[] = []

  const pulseEventsMap = apiEvents.reduce<
    Record<number, routes.IrrigationSchedulerEventsDelete.Response[number]>
  >((acc, apiEvent) => {
    return {
      ...acc,
      [apiEvent.eventId]: apiEvent,
    }
  }, {})

  apiEvents.forEach((event) => {
    if (!event.pulseEventId) return

    // when there is no status, it means the event is deleted and should be removed from the store
    if (!event.status) {
      !pulseEventsToDelete.includes(event.pulseEventId) && pulseEventsToDelete.push(event.pulseEventId)
    } else {
      !pulseEventsToMarkAsDeletedDraft.includes(event.pulseEventId) &&
        pulseEventsToMarkAsDeletedDraft.push(event.pulseEventId)
    }
  })

  irrigationSchedulerStore.setState((isss) => {
    const scheduledPulseIrrigationEvents = { ...isss.scheduledPulseIrrigationEvents }

    pulseEventsToDelete.forEach((pulseEventId) => {
      delete scheduledPulseIrrigationEvents[pulseEventId]
    })

    pulseEventsToMarkAsDeletedDraft.forEach((pulseEventId) => {
      if (scheduledPulseIrrigationEvents[pulseEventId]) {
        const updatedPulseEvents = scheduledPulseIrrigationEvents[pulseEventId].pulseIrrigationEvents.map(
          (event) => {
            const status = mapApiScheduledEventStatusToTScheduledEventEnum(
              pulseEventsMap[Number(event.eventId)]?.status,
            )

            return {
              ...event,
              status,
            }
          },
        )

        scheduledPulseIrrigationEvents[pulseEventId] = {
          ...scheduledPulseIrrigationEvents[pulseEventId],
          type: TScheduledEventEnum.DRAFT_DELETE,
          pulseIrrigationEvents: updatedPulseEvents,
        }
      }
    })

    return {
      ...isss,
      scheduledPulseIrrigationEvents,
    }
  })
}
