import { createStyles } from '@mantine/core'
import type { TLanguage } from '@semios/app-platform-i18n-core'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { colors } from 'settings/colors'
import type { Contact } from 'stores/userDetailsStore'

export type GroupFormType = {
  name: string
  contacts: TOptions[]
}

export type CreateGroupResponse = {
  data: {
    createGroup: string
  }
}

export type UpdateGroupResponse = {
  data: {
    updateGroup: string
  }
}

export type ContactFormType = {
  name: string
  email: string
  sms: string
  groups: TOptions[]
  language: TLanguage
}

export type CreateContactResponse = {
  data: {
    createContact: Contact
  }
}

export type EditContactResponse = {
  data: {
    updateContact: Contact
  }
}

export const useStyles = createStyles(() => ({
  invalid: {
    borderColor: colors.red,
  },
}))
