import type { TFieldAssetValueTypes } from 'App/Map/types'
import { mapApiEmitterTypeToLabel } from 'utils/mapApiEmitterTypeToLabel'

export const sortByEmitterType = (
  a: TFieldAssetValueTypes.TIrrigationZoneEmitter,
  b: TFieldAssetValueTypes.TIrrigationZoneEmitter,
) => {
  if (a.emitterType === b.emitterType) return 0

  return mapApiEmitterTypeToLabel(a.emitterType) > mapApiEmitterTypeToLabel(b.emitterType) ? 1 : -1
}
