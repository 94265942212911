import { Loader } from '@googlemaps/js-api-loader'
import { translate } from 'i18n/i18n'
import type { ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { colorLog } from '../../utils/colorLog'

type GoogleMaps = typeof google.maps

export type GoogleMapProps = {
  mapOptions: google.maps.MapOptions
  height: number | string
  width: number | string
  onInit: (map: google.maps.Map, maps: typeof google.maps) => (() => unknown) | void
  onError?: (error: unknown) => void
  defaultErrorMessage?: ReactNode
  style?: React.CSSProperties
}

export const GoogleMap: React.FC<GoogleMapProps> = (props) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [error, setError] = useState<boolean>(false)
  //#region useGoogleMaps
  const [googleMaps, setGoogleMaps] = useState<GoogleMaps | null>(null)

  useEffect(() => {
    let subscribed = true

    if (window.google?.maps) {
      Promise.resolve(window.google.maps).then((maps: GoogleMaps) => {
        colorLog('white', `google.maps (window) ${maps.version}`)

        subscribed && setGoogleMaps(maps)
      })
    } else {
      const locale = translate.getGoogleMapLocale()

      new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
        language: locale.language,
        region: locale.region,
        version: 'quarterly', // https://developers.google.com/maps/documentation/javascript/versions
      })
        .load()
        .then((google) => {
          colorLog('white', `google.maps (loader) ${google.maps.version}`)

          subscribed && setGoogleMaps(google.maps)
        })
        .catch((error) => {
          if (subscribed) {
            setError(true)

            if (props.onError) {
              props.onError(error)
            }
          }
        })
    }

    return () => {
      subscribed = false
    }
  }, [])
  //#endregion

  //#region Map: create
  useEffect(() => {
    if (!map && googleMaps && divRef.current) {
      setMap(new googleMaps.Map(divRef.current, props.mapOptions))
    }
  })
  //#endregion

  //#region Map: update
  useEffect(() => {
    if (map && googleMaps && divRef.current) {
      map.setOptions(props.mapOptions)
    }
  }, [props.mapOptions])
  //#endregion

  //#region Map: onInit
  useEffect(() => {
    let destructor: (() => unknown) | void

    if (map && googleMaps) {
      destructor = props.onInit(map, googleMaps)
    }

    return () => {
      if (destructor) {
        destructor()
      }
    }
  }, [map])
  //#endregion

  return (
    <div
      css={{
        '.gm-style iframe + div': {
          border: 'none !important',
          // ^ This is a hack to fix Google Maps issue https://issuetracker.google.com/issues/182937497
          // The issue seems to have started occurring again in 3.55. (I tested 3.54, and it didn't happen.)
        },
        ...props.style,
        'height': props.height,
        'width': props.width,
      }}
      ref={divRef}
    >
      {error && (
        <div
          style={{
            ...props.style,
            height: props.height,
            width: props.width,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.defaultErrorMessage ? props.defaultErrorMessage : null}
        </div>
      )}
    </div>
  )
}
