import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import { moderateDeficitThreshold } from './moderateDeficitThreshold'

const { saturatedZone, idealZone, moderateDeficitZone, highDeficitZone, extremeDeficitZone } = colors
const extremeDeficit = 0
const saturated = 100

export const getSoilAWCBackgroundColor = (value: number | null, madValue = 70): string => {
  if (isNil(value)) return 'transparent'

  const moderateDeficit = madValue * (1 - moderateDeficitThreshold)

  if (value < extremeDeficit) {
    return extremeDeficitZone
  } else if (value < moderateDeficit) {
    return highDeficitZone
  } else if (value < madValue) {
    return moderateDeficitZone
  } else if (value < saturated) {
    return idealZone
  } else {
    return saturatedZone
  }
}
