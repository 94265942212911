import type { TMomentFormat } from '@semios/app-platform-i18n-core'
import Highcharts from 'highcharts/highstock'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'

// TODO: we should replace this when updating to Mantine 6
function formatTime(time: number, format: TMomentFormat, timezone?: string): string {
  if (!timezone) {
    return translate.dates.format(moment.tz(time, moment.tz.guess()), format)
  }

  return translate.dates.format(moment.tz(time, timezone), format)
}

// TODO: i18n - We still need to wire up / ensure that all the dateTimeLabelFormats and xDateFormat options in our various highchart options are implemented here.
const customHightchartsDateFormats: Record<'Z' | 'X' | 'D' | 'C', Highcharts.TimeFormatCallbackFunction> = {
  /**
   * this funky looking thing here is a dictionary of our
   * own hacky custom highcharts date formats. High charts
   * by default gives nice dates, but they could be nicer,
   * so this allows us to reference these dates conveniently
   * where we can take special date format considerations
   * into account conditionally based on a user's settings,
   * etc.
   * Basically they'd get called with the regular Highcharts
   * syntax for dates, e.g. { xDateFormat: '%Z' for more info, see
   * https://api.highcharts.com/class-reference/Highcharts.Time
   */
  Z: function (time) {
    // @ts-ignore highcharts' type isn't quite correct here
    const timezone: string | undefined = this?.options?.timezone

    return formatTime(time, 'ddd, MMM D, h:mm a (z)', timezone)
  },
  X: function (time) {
    // @ts-ignore highcharts' type isn't quite correct here
    const timezone: string | undefined = this?.options?.timezone

    return formatTime(time, 'MMM D, h:mm a (z)', timezone)
  },
  D: function (time) {
    // @ts-ignore highcharts' type isn't quite correct here
    const timezone: string | undefined = this?.options?.timezone

    return formatTime(time, 'ddd, MMM D, YYYY', timezone)
  },
  C: function (time) {
    // @ts-ignore highcharts' type isn't quite correct here
    const timezone: string | undefined = this?.options?.timezone

    return formatTime(time, 'MMM D', timezone)
  },
}

Highcharts.dateFormats = customHightchartsDateFormats

Highcharts.AST.bypassHTMLFiltering = true

export type TSemiosCustomHighchartsDateFormats = `%${keyof typeof customHightchartsDateFormats}`
