import { isEmpty, sortByKey } from '@semios/app-platform-common'
import { PropertySelect } from 'components/PropertySelect/PropertySelect'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import type { TPropertyZoneData } from '../types'

type TZonesFilter = {
  initialProperties: TPropertyZoneData[]
}

export const useZonesFilter = ({ initialProperties }: TZonesFilter) => {
  const [filterZoneText, setfilterZoneText] = useState('')
  const [selectedPropertyId, setSelectedPropertyId] = useState<null | number>(null)
  const propertiesFromFass = fieldAssetStore.useSelector((s) => s.properties)

  const propertyZoneDataWithLabel = initialProperties
    .map((p) => ({
      value: p.propertyId.toString(),
      label: propertiesFromFass?.[Number(p.propertyId)]?.propertyName ?? '',
    }))
    .sort(sortByKey('label'))

  const getFilteredProperties = () => {
    let result = [...initialProperties]

    if (selectedPropertyId) {
      result = result.filter((p) => p.propertyId === selectedPropertyId)
    }

    if (filterZoneText) {
      result = result.map((p) => {
        const filteredIrrigationZones = Object.fromEntries(
          Object.entries(p.irrigationZones).filter(([, val]) => {
            const isZoneName = searchStringFromStringWithSpaces(filterZoneText, val.zoneName)

            const isEmitterType = Object.values(val.flowRate).some((f) =>
              searchStringFromStringWithSpaces(filterZoneText, f.emitterType),
            )

            return isZoneName || isEmitterType
          }),
        )

        return {
          ...p,
          irrigationZones: filteredIrrigationZones,
        }
      })
    }

    return result.filter((p) => Object.values(p.irrigationZones).length > 0)
  }

  const filteredAndSortedList = getFilteredProperties()
    .map((p) => ({
      ...p,
      propertyName: propertiesFromFass?.[Number(p.propertyId)]?.propertyName ?? '',
      irrigationZones: Object.values(p.irrigationZones).sort(sortByKey('zoneName')),
    }))
    .sort(sortByKey('propertyName'))

  return {
    filterComponent: (
      <div css={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
        <div css={{ flexGrow: 2 }}>
          <SearchTextInput
            placeholder={translate.phrases.banyanApp('Filter zones or emitter types')}
            searchText={filterZoneText}
            setSearchText={setfilterZoneText}
            clearable={true}
          />
        </div>
        <div css={{ flexGrow: 1 }}>
          <PropertySelect
            selectedPropertyId={selectedPropertyId}
            onChange={setSelectedPropertyId}
            disabled={isEmpty(initialProperties)}
            data={propertyZoneDataWithLabel}
            clearable={true}
          />
        </div>
      </div>
    ),
    filteredAndSortedList,
    isSearchMatch: filteredAndSortedList.length > 0,
  }
}
