import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { getIdAndEmitterTypeFromZoneEmitterTypeKey } from 'App/Map/_utils/irrigationZoneEmitterTypeKeyUtil'

export const checkIfDuplicateEmitters = (emitters: TFieldAssetKeyTypes.TIrrigationZoneEmitterId[]) => {
  const emitterTypes = emitters.map((emitter) => {
    return getIdAndEmitterTypeFromZoneEmitterTypeKey(emitter).emitterType
  })

  return new Set(emitterTypes).size !== emitterTypes.length
}
