import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { VV } from '@semios/app-platform-value-type-definitions'
import { DropdownSelectorPoint } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorPoint/DropdownSelectorPoint'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import type { StackedChartSection, StackedChartSectionItem } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { upperYAxisTitleMaker } from 'utils/upperYAxisTitleMaker'
import { checkPermission } from './soil'
import { awcHeatmapColorAxisFormatter } from './_utils/awcHeatmapColorAxisFormatter'
import { checkIfSoilChartHasData } from './_utils/checkIfSoilChartHasData'
import { getColSize } from './_utils/getColSize'
import { makeAwcHeatmapSeries } from './_utils/makeAwcHeatmapSeries'
import { PREDICTION_KEY } from './_utils/predictionKey'
import { soilAWCHeatmapStops } from './_utils/soilAWCHeatmapStops'

const possibleValueTypes: VV.DomainTypes.Soil.TTimeseriesValueTypeKeysMerged[] = ['soilMoisture']
const pointCategory = 'soilPoint'

export const awcHeatmap = ({
  data,
  soilStationLngLat,
}: {
  data: routes.Values.Response
  soilStationLngLat: string | null
}): StackedChartSection => {
  const commonReturnItems = {
    title: translate.phrases.banyanApp('Infiltration Map'),
    titleChildren: (
      <DropdownSelectorPoint pointCategory={pointCategory} valuesTimeseriesToFilterOn={possibleValueTypes} />
    ),
    id: 'stackem-soil-awc-heatmap',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const { soilMoisture } = data?.points?.[String(soilStationLngLat)]?.values || {}

  const soilMoistureWithPredictionRemoved = (soilMoisture ?? []).filter(
    ({ metadata }) => metadata.depth !== PREDICTION_KEY,
  )

  const chartHasSomeData = checkIfSoilChartHasData(soilMoistureWithPredictionRemoved)

  const mappedCategories = soilMoistureWithPredictionRemoved
    ?.sort((a, b) => Number(a.metadata.depth) - Number(b.metadata.depth))
    .map(({ metadata: { depth } }) => unitConverter.soilDepth(Number(depth)).value() || 0)

  const firstForecastTimestamp = +new Date()
  const soilAwcHeatmapData = makeAwcHeatmapSeries({ data: soilMoistureWithPredictionRemoved }) ?? []

  // @ts-ignore TS is angry at my colorAxis formatter
  const soilAwcHeatmapChart = {
    chartConfig: {
      semiosHighchartsAdditions: {
        id: commonReturnItems.id,
        firstForecastTimestamp: firstForecastTimestamp,
      },
      colorAxis: {
        labels: { formatter: awcHeatmapColorAxisFormatter },
        min: 0,
        max: 100,
        stops: soilAWCHeatmapStops,
      },
      chart: {
        type: 'heatmap',
      },
      series: [
        {
          id: 'Soil-AWC-Heatmap-series',
          data: chartHasSomeData ? soilAwcHeatmapData : [],
          colsize: getColSize(),
          type: 'heatmap',
          visible: true,
        },
      ],
      tooltip: {
        enabled: false,
      },
      yAxis: {
        categories: mappedCategories,
        min: 0,
        max: mappedCategories && mappedCategories.length - 1,
        title: upperYAxisTitleMaker(unitConverter.soilDepth().categoryTitle()),
      },
    },
  } as StackedChartSectionItem

  return {
    ...commonReturnItems,
    items: [soilAwcHeatmapChart],
  }
}
