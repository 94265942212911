import { faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LoadingOverlay } from '@mantine/core'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { checkAuthorization } from 'utils/checkAuthorization'
import { useSearchInput } from 'utils/useSearchInput'
import type { TAdminPermission, TAdminProperty } from '../../utils/useAdminEntites'
import { ActionButton } from '../ActionButton/ActionButton'
import PermissionsModal from './PermissionsModal/PermissionsModal'

export const Properties = ({
  properties,
  loading,
  permissions,
  onRefresh,
}: {
  properties: TAdminProperty[]
  loading: boolean
  permissions: TAdminPermission[]
  onRefresh: () => void
}) => {
  const userHasPropertyPermissions =
    checkAuthorization({
      permission: 'ADMIN_SET_PROPERTY_PERMISSIONS',
      entity: '*',
    }) &&
    checkAuthorization({
      permission: 'ADMIN_SET_PROPERTY_ROLES',
      entity: '*',
    })

  const [paginationResetKey, setPaginationResetKey] = useState(`${Math.random()}`)
  const PAGE_SIZE = 25
  const [selectedProperty, setSelectedProperty] = useState<TAdminProperty | null>(null)
  const [userRolePermissionFormOpen, setUserRolePermissionFormOpen] = useState(false)

  const actionsColumn = [
    {
      title: translate.phrases.banyanApp('Actions'),
      key: 'actions',
      dataIndex: 'actions',
      width: 80,
      render: (_: string, record: TAdminProperty) => {
        return (
          <ActionButton
            menuItems={[
              {
                icon: <FontAwesomeIcon icon={faShieldAlt} />,
                children: (
                  <span
                    onClick={() => {
                      setSelectedProperty(record)

                      setUserRolePermissionFormOpen(true)
                    }}
                  >
                    {'View/Edit Permissions'}
                  </span>
                ),
              },
            ]}
          />
        )
      },
    },
  ]

  const columns = [
    {
      title: translate.phrases.banyanApp('ID'),
      dataIndex: 'id',
      key: 'id',
      width: 130,
    },
    {
      title: translate.phrases.banyanApp('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 330,
    },
    {
      title: translate.phrases.banyanApp('Country'),
      dataIndex: 'country',
      key: 'country',
      width: 120,
    },
    {
      title: translate.phrases.banyanApp('State'),
      dataIndex: 'gAdministrativeAreaLevel1',
      key: 'gAdministrativeAreaLevel1',
      width: 120,
    },
    {
      title: translate.phrases.banyanApp('Type'),
      dataIndex: 'type',
      key: 'type',
      render: (value: string) => `${((value || '')[0] || '').toUpperCase()}${(value || '').slice(1)}`, // handle undefineds annoyingly
      width: 120,
    },
    ...(userHasPropertyPermissions ? actionsColumn : []),
  ]

  const { SearchInput, searchString } = useSearchInput({
    placeholder: translate.phrases.banyanApp('Search for a property'),
  })

  const displayedData = arrayOfObjectsSearch(properties, searchString, ['id', 'name'])

  useEffect(() => {
    setPaginationResetKey(`${Math.random()}`)
  }, [searchString])

  return (
    <>
      <LoadingOverlay visible={loading} />
      {!loading && properties.length && (
        <>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div css={{ margin: 10 }}>{SearchInput}</div>
          </div>
          <SummaryTable
            autoSortColumns={['name', 'country', 'gAdministrativeAreaLevel1', 'type']}
            columns={columns}
            data={displayedData}
            pageSize={PAGE_SIZE}
            paginationResetKey={paginationResetKey}
          />
        </>
      )}
      {selectedProperty && (
        <PermissionsModal
          property={selectedProperty}
          permissions={permissions}
          opened={userRolePermissionFormOpen}
          onClose={(hasChanges) => {
            setUserRolePermissionFormOpen(false)

            if (hasChanges) {
              onRefresh()
            }
          }}
        />
      )}
    </>
  )
}
