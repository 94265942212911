import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconArrowUp = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M2.5 13.75L10 6.25L17.5 13.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
