import React, { useCallback } from 'react'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TActiveNode, TPlannedNode } from '../types'
import { isPlannedNode } from '../utils/isPlannedNode'
import { ActiveNodeDetail } from './ActiveNodeDetail/ActiveNodeDetail'
import { PlannedNodeDetail } from './PlannedNodeDetail/PlannedNodeDetail'

export const SelectedEquipmentCard: React.FC = () => {
  const selectedNode = serviceCenterStore.useSelector(serviceCenterStore.selectors.getSelectedEquipmentNode)

  const onClose = useCallback(() => {
    serviceCenterStore.actions.unselectEquipment()
  }, [])

  if (!selectedNode) return null

  if (isPlannedNode(selectedNode)) {
    return <PlannedNodeDetail node={selectedNode as TPlannedNode} onClose={onClose} />
  } else {
    return <ActiveNodeDetail node={selectedNode as TActiveNode} onClose={onClose} />
  }
}
