import { Checkbox as MantineCheckbox, Loader, NumberInput, Table } from '@mantine/core'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import type * as TFieldAssetKeyTypes from '@semios/app-platform-banyan-route-definitions/dist/routes/userAppStartup/fieldAssetKeyTypes'
import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { Button } from 'components/Button/Button'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { IconControlTemperature } from 'components/icons/IconControlsInfo/IconControlTemperature'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { apiFetch } from 'utils/apiFetch'
import { sortByKey } from 'utils/sortByKey'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const WindMachineAutoStartStopSettings = ({
  content,
  fetchData,
}: {
  content: TWindMachineDetailsElement
  fetchData: () => void
}) => {
  const isImperial = userDetailsStore.getState().temperature === 'IMPERIAL'
  const [value, setValue] = useState<string[]>([])
  const [startTemp, setStartTemp] = useState<number>(isImperial ? 36 : 2)
  const [stopTemp, setStopTemp] = useState<number>(isImperial ? 40 : 4)
  const [stateUpdated, setStateUpdated] = useState({ start: false, stop: false })
  const [windMachineStartStopOpened, setWindMachineStartStopOpened] = useState(false)
  const property = selectedFieldAssetsStore.useSelector((s) => s.property)

  const handleChange = (newValue: number, name: string) => {
    if (isNil(newValue)) return

    switch (name) {
      case 'startTemp':
        setStartTemp(newValue)

        setStateUpdated((prev) => ({ ...prev, start: true }))

        break

      case 'stopTemp':
        setStopTemp(newValue)

        setStateUpdated((prev) => ({ ...prev, stop: true }))

        break

      default:
        break
    }
  }

  const handleOnClick = async () => {
    if (!stateUpdated.start && !stateUpdated.stop) return

    try {
      const settings: { tempStart?: number; tempStop?: number } = {}
      const inputUnit = isImperial ? 'f' : 'c'

      const cleanedStartTemp = unitConverter
        .windMachineTemperature(startTemp, { inputUnit, outputUnit: 'c', decimalPlaces: 3 })
        .value()

      const cleanedStopTemp = unitConverter
        .windMachineTemperature(stopTemp, { inputUnit, outputUnit: 'c', decimalPlaces: 3 })
        .value()

      if (isNil(cleanedStartTemp) || isNil(cleanedStopTemp)) throw new Error('Invalid Temperature Conversion')

      settings.tempStart = cleanedStartTemp

      settings.tempStop = cleanedStopTemp

      const request = await apiFetch<routes.WindMachine.Request, routes.WindMachine.Response>({
        url: routes.WindMachine.path,
        body: {
          windMachineSettings: {
            lngLats: value,
            settings,
          },
        } as routes.WindMachine.Request,
      })

      if (isNil(request?.windMachineSettings)) throw new Error('Failed to update settings')

      fetchData()
    } catch (error) {
      return
    }
  }

  const confirmChangesStart = (intentional: boolean) => {
    if (intentional) handleOnClick()

    setWindMachineStartStopOpened(false)
  }

  const windMachineItems =
    content?.windMachineDetails
      ?.map((windMachine) => {
        const lngLat = `POINT(${windMachine.geom.coordinates[0]} ${windMachine.geom.coordinates[1]})`

        return {
          name: windMachine.equipmentDeviceNumber,
          lngLat,
          tempStart: windMachine.equipmentSettings.tempStart,
          tempStop: windMachine.equipmentSettings.tempStop,
          equipmentSettings: windMachine.equipmentSettings,
          applicationSettingsNewSettings: windMachine.applicationSettingsNew.settings,
          wmStatus: windMachine.applicationSettingsNew.status,
        }
      })
      .sort(sortByKey('name')) || []

  const renderWindMachines = windMachineItems.map(
    (item: {
      name: string
      lngLat: string
      tempStart: number | null
      tempStop: number | null
      equipmentSettings: { tempStart: number; tempStop: number }
      applicationSettingsNewSettings: { tempStart: number; tempStop: number }
      wmStatus: string | null
    }) => {
      const wmNewSettings = item?.applicationSettingsNewSettings

      let showLoading = true

      if ((isNil(wmNewSettings.tempStart) && isNil(wmNewSettings.tempStop)) || item.wmStatus === 'onDevice') {
        showLoading = false
      } else if (item.wmStatus === 'new') {
        showLoading =
          wmNewSettings?.tempStart !== item.equipmentSettings?.tempStart ||
          wmNewSettings?.tempStop !== item.equipmentSettings?.tempStop
      }

      let wmName: string | undefined

      const itemLngLat = item.lngLat as TFieldAssetKeyTypes.TLngLat

      if (!isNil(property) && !isNil(itemLngLat))
        wmName = fieldAssetStore.getState().properties?.[property]?.points?.[itemLngLat].name || item?.name

      if (isNil(wmName)) wmName = ''

      return (
        <tr
          key={item.lngLat}
          onClick={(e) => {
            e.stopPropagation()

            setValue((prev) =>
              prev.includes(item.lngLat)
                ? prev.filter((position) => position !== item.lngLat)
                : [...prev, item.lngLat],
            )
          }}
          style={{ height: 60 }}
        >
          <td>
            <Checkbox label={wmName} value={item.lngLat} style={{ zIndex: -1, position: 'relative' }} />
          </td>
          <td>
            {showLoading && <Loader size={10} style={{ marginRight: 4 }} />}
            {unitConverter.windMachineTemperature(item.tempStart).valueWithSuffix()}
          </td>
          <td>
            {showLoading && <Loader size={10} style={{ marginRight: 4 }} />}
            {unitConverter.windMachineTemperature(item.tempStop).valueWithSuffix()}
          </td>
        </tr>
      )
    },
  )

  return (
    <>
      <ModalDrawer
        title={translate.phrases.placeholder('Set Start/Stop Temperatures')}
        opened={windMachineStartStopOpened}
        onClose={() => confirmChangesStart(false)}
        primaryButtonText={
          value.length > 0
            ? translate.phrases.placeholder('Apply to {{numDevices}} Devices', { numDevices: value.length })
            : translate.phrases.placeholder('Select Devices')
        }
        primaryButtonOnPress={() => confirmChangesStart(true)}
        primaryButtonDisabled={value.length === 0}
        size={'500px'}
        zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
      >
        <div
          css={{
            padding: 20,
            color: colors.midnight,
            height: '100%',
            overflow: 'hidden',
            display: 'grid',
            gridTemplateRows: '145px',
          }}
        >
          <div style={{ gridRow: 1 }}>
            <h3 style={{ marginTop: 0 }}>{translate.phrases.placeholder('Set Start/Stop Temperatures')}</h3>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingRight: 20,
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '10px 0px',
                  fontSize: '12px',
                }}
              >
                <label
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '10px',
                  }}
                >
                  {translate.phrases.placeholder('Start Temperature')}
                </label>
                <NumberInput
                  css={{ maxWidth: 100 }}
                  value={startTemp}
                  onChange={(e: number) => handleChange(e, 'startTemp')}
                  styles={{
                    input: {
                      padding: 0,
                      textAlign: 'center',
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                      border: 'solid 2px #B4B3BB',
                    },
                  }}
                  precision={2}
                  hideControls
                />
                <span
                  css={{
                    display: 'flex',
                    padding: '10px',
                    alignItems: 'center',
                    background: '#B4B3BB',
                    borderTopRightRadius: '3px',
                    borderBottomRightRadius: '3px',
                  }}
                >
                  {unitConverter.windMachineTemperature().suffix()}
                </span>
              </div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '10px',
                  fontSize: '12px',
                }}
              >
                <label
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '10px',
                  }}
                >
                  {translate.phrases.placeholder('Stop Temperature')}
                </label>
                <NumberInput
                  css={{ maxWidth: 100 }}
                  value={stopTemp}
                  onChange={(e: number) => handleChange(e, 'stopTemp')}
                  styles={{
                    input: {
                      padding: 0,
                      textAlign: 'center',
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                      border: 'solid 2px #B4B3BB',
                    },
                  }}
                  precision={2}
                  hideControls
                />
                <span
                  css={{
                    display: 'flex',
                    padding: '10px',
                    alignItems: 'center',
                    background: '#B4B3BB',
                    borderTopRightRadius: '3px',
                    borderBottomRightRadius: '3px',
                  }}
                >
                  {unitConverter.windMachineTemperature().suffix()}
                </span>
              </div>
            </div>
            <h3>{translate.phrases.placeholder('For the following devices')}</h3>
          </div>

          <div style={{ gridRow: 2, overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none' }}>
            <MantineCheckbox.Group value={value} onChange={setValue} css={{ width: 500 }}>
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: '50%', textAlign: 'start' }}>
                      {translate.phrases.banyanApp('Wind Machine')}
                    </th>
                    <th style={{ width: '25%', textAlign: 'start' }}>
                      <span style={{ paddingRight: 5 }}>
                        <IconControlTemperature />
                      </span>
                      {translate.phrases.banyanApp('Start')}
                    </th>
                    <th style={{ width: '25%', textAlign: 'start' }}>
                      <span style={{ paddingRight: 5 }}>
                        <IconControlTemperature />
                      </span>
                      {translate.phrases.placeholder('Stop')}
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderBottom: '0.0625rem solid #dee2e6', overflowY: 'auto' }}>
                  {renderWindMachines}
                </tbody>
              </Table>
            </MantineCheckbox.Group>
          </div>
        </div>
      </ModalDrawer>
      <Button
        css={{ width: '100%' }}
        variant={'tertiary'}
        onClick={() => setWindMachineStartStopOpened(true)}
      >
        {translate.phrases.placeholder('Auto Start/Stop Settings')}
      </Button>
    </>
  )
}
