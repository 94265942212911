import { persistentInit } from 'stores/_utils/persistentStore/persistentStore'

const storeName = 'mapStore'

export const mapStore = persistentInit<{
  bounds: google.maps.LatLngBoundsLiteral | undefined
  center: google.maps.LatLngLiteral | undefined
  zoom: number
  currentLocation: google.maps.LatLngLiteral | null
}>({
  initialState: {
    bounds: undefined,
    center: undefined,
    zoom: 14,
    currentLocation: null,
  },
  keysToPutInPersistentStorage: {
    bounds: true,
    center: true,
    zoom: true,
    currentLocation: false,
  },
  keysToPutInURL: {
    bounds: true,
    center: true,
    zoom: true,
    currentLocation: false,
  },
  storeName,
})
