import HC_more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import HEATMAP from 'highcharts/modules/heatmap'
import NO_DATA_TO_DISPLAY from 'highcharts/modules/no-data-to-display'
import HC_xrange from 'highcharts/modules/xrange'

HEATMAP(Highcharts)

// chart type area and arearange
HC_more(Highcharts)

// chart type xrange
HC_xrange(Highcharts)

NO_DATA_TO_DISPLAY(Highcharts)

window.Highcharts = Highcharts

// add our custom date definitions to highcharts
import './setCustomHighchartsDateFormats.ts'

export { Highcharts }
