import { Button, LoadingOverlay, Popover } from '@mantine/core'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { Authorization } from 'components/Authorization/Authorization'
import { IconEdit } from 'components/icons/IconEdit'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { translate } from 'i18n/i18n'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { checkAuthorization } from 'utils/checkAuthorization'
import { natComparator } from 'utils/sort-by-key'
import { useSearchInput } from 'utils/useSearchInput'
import type { TAdminPermission, TAdminRegion } from '../../utils/useAdminEntites'
import { ActionButton } from '../ActionButton/ActionButton'
import { ViewEditRegionModal } from './ViewEditRegionModal/ViewEditRegionModal'

export const Regions = ({
  regions,
  permissions,
  loading,
  onRefresh,
}: {
  regions: TAdminRegion[]
  permissions: TAdminPermission[]
  loading: boolean
  onRefresh: () => void
}) => {
  const PAGE_SIZE = 25
  const [regionFormOpem, setRegionFormOpen] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState<TAdminRegion | null>(null)
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)
  const [paginationResetKey, setPaginationResetKey] = useState(`${Math.random()}`)
  const open = (id: string) => setHoveredItem(id)
  const close = () => setHoveredItem(null)

  const userFirstNameLastInitialReturner = (user: TAdminRegion['createdBy'] | TAdminRegion['updatedBy']) => {
    let returner = translate.phrases.banyanApp('Unknown')

    try {
      const { firstName, lastName } = user || {}

      if (firstName || lastName) returner = `${firstName || ''} ${lastName?.[0] || ''}`
    } catch (err) {}

    return returner
  }

  const columns = [
    {
      title: translate.phrases.banyanApp('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: translate.phrases.banyanApp('Permissions'),
      dataIndex: 'permissions',
      key: 'permissions',
      render: (value: string[], record: TAdminRegion) => {
        if (isEmpty(value)) return 0

        const isOpen = hoveredItem === String(record.id)

        return (
          <Popover withArrow shadow="md" radius={5} opened={isOpen} position="right">
            <Popover.Target>
              <div onMouseEnter={() => open(String(record.id))} onMouseLeave={close} css={{ marginLeft: 5 }}>
                <span css={{ marginLeft: 5, textDecoration: 'underline dotted' }}>{value.length}</span>
              </div>
            </Popover.Target>
            <Popover.Dropdown
              onMouseEnter={() => open(String(record.id))}
              onMouseLeave={close}
              css={{ pointerEvents: 'auto', color: colors.midnight, fontSize: 14, fontWeight: '400' }}
            >
              <div css={{ display: 'flex', flexDirection: 'column', overflow: 'auto', maxHeight: '50vh' }}>
                {value.map((permission: string, index: number) => (
                  <div key={`${record.id}-${record.name}-${index}`}>{permission}</div>
                ))}
              </div>
            </Popover.Dropdown>
          </Popover>
        )
      },
      width: 120,
    },
    {
      title: translate.phrases.banyanApp('Centroid'),
      dataIndex: 'centroid',
      key: 'centroid',
      width: 150,
    },
    {
      title: translate.phrases.banyanApp('Timezone'),
      dataIndex: 'timezone',
      key: 'timezone',
      width: 150,
    },
    {
      title: translate.phrases.banyanApp('Last Modified At'),
      dataIndex: 'createdAt',
      key: 'lastModifiedAt',
      render: (value: string, record: TAdminRegion) =>
        moment
          .tz(record.updatedAt || record.createdAt, 'America/Los_Angeles')
          .format('ddd, MMM D YYYY, h:mm a'),
      sorter: (a: TAdminRegion, b: TAdminRegion) =>
        +new Date(b.updatedAt || b.createdAt) - +new Date(a.updatedAt || a.createdAt),
      width: 195,
    },
    {
      title: translate.phrases.banyanApp('Last Modified By'),
      dataIndex: 'createdBy',
      key: 'lastModifiedBy',
      render: (value: string, record: TAdminRegion) =>
        userFirstNameLastInitialReturner(record.updatedAt ? record.updatedBy : record.createdBy),
      sorter: (a: TAdminRegion, b: TAdminRegion) => {
        let aComparer = userFirstNameLastInitialReturner(a.updatedAt ? a.updatedBy : a.createdBy)
        let bComparer = userFirstNameLastInitialReturner(b.updatedAt ? b.updatedBy : b.createdBy)

        return natComparator(aComparer, bComparer)
      },
      width: 155,
    },
    {
      title: translate.phrases.banyanApp('Actions'),
      key: 'actions',
      dataIndex: 'actions',
      width: 80,
      render: (_: string, record: TAdminRegion) => {
        const userHasEditPermission = checkAuthorization({
          permission: 'ADMIN_CREATE_REGIONS',
          entity: '*',
        })

        const userHasDeletePermission = userHasEditPermission // current business rule has same permission

        if (!userHasEditPermission && !userHasDeletePermission) return null

        return (
          <ActionButton
            menuItems={[
              {
                icon: <IconEdit />,
                children: (
                  <span
                    onClick={() => {
                      setSelectedRegion(record)

                      setRegionFormOpen(true)
                    }}
                  >
                    {translate.phrases.banyanApp('View/Edit Region')}
                  </span>
                ),
              },
            ]}
          />
        )
      },
    },
  ]

  const { SearchInput, searchString } = useSearchInput({
    placeholder: translate.phrases.banyanApp('Search for a region'),
  })

  useEffect(() => {
    setPaginationResetKey(`${Math.random()}`)
  }, [searchString])

  const displayedData = arrayOfObjectsSearch(regions, searchString, ['name'])

  return (
    <>
      <LoadingOverlay visible={loading} />
      {!loading && regions.length && (
        <>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <div css={{ margin: 10 }}>{SearchInput}</div>
            </div>

            <Authorization requires={{ permission: 'ADMIN_CREATE_REGIONS', entity: '*' }}>
              <Button
                variant="filled"
                onClick={() => {
                  setSelectedRegion(null)

                  setRegionFormOpen(true)
                }}
              >
                <span>{translate.phrases.banyanApp('Create New Region')}</span>
              </Button>
            </Authorization>
          </div>

          <SummaryTable
            autoSortColumns={['name', 'roleType', 'permissions', 'createdAt', 'createdBy']}
            columns={columns}
            data={displayedData}
            pageSize={PAGE_SIZE}
            paginationResetKey={paginationResetKey}
          />
        </>
      )}
      <ViewEditRegionModal
        permissions={permissions.filter((p) => p.permissionTypes.includes('REGION'))}
        region={selectedRegion}
        opened={regionFormOpem}
        onClose={(hasChanges) => {
          setRegionFormOpen(false)

          if (hasChanges) {
            onRefresh()
          }
        }}
      />
    </>
  )
}
