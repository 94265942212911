import { Badge, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import React, { useMemo } from 'react'
import { MultiSelect } from '../../../components/MultiSelect/MultiSelect'
import type { TOptions } from '../../../components/MultiSelect/MultiSelect.types'
import { getNodeStatusLabel, NODE_STATUS_COLORS } from '../Map/_utils/getActiveNodeStatus'
import { TNodeStatus } from '../types'

type EquipmentStatusOption = TOptions & {
  value: TNodeStatus
  color: string
}

interface EquipmentStatusMultiSelectProps {
  selectedStatuses: TNodeStatus[]
  dropdownPosition?: 'top' | 'bottom'
  onChange: (selectedStatuses: TNodeStatus[]) => void
}

export const EquipmentStatusMultiSelect: React.FC<EquipmentStatusMultiSelectProps> = ({
  selectedStatuses,
  dropdownPosition,
  onChange,
}) => {
  const theme = useMantineTheme()

  const equipmentStatuses: EquipmentStatusOption[] = useMemo(
    () => [
      {
        value: TNodeStatus.ACTIVE,
        label: getNodeStatusLabel(TNodeStatus.ACTIVE),
        color: NODE_STATUS_COLORS.active,
      },
      {
        value: TNodeStatus.PLANNED,
        label: getNodeStatusLabel(TNodeStatus.PLANNED),
        color: NODE_STATUS_COLORS.planned,
      },
      {
        value: TNodeStatus.PENDING,
        label: getNodeStatusLabel(TNodeStatus.PENDING),
        color: NODE_STATUS_COLORS.pending,
      },
      {
        value: TNodeStatus.ERROR,
        label: getNodeStatusLabel(TNodeStatus.ERROR),
        color: NODE_STATUS_COLORS.error,
      },
      {
        value: TNodeStatus.OFFLINE,
        label: getNodeStatusLabel(TNodeStatus.OFFLINE),
        color: NODE_STATUS_COLORS.offline,
      },
    ],
    [],
  )

  const selectedOptions = useMemo(
    () =>
      selectedStatuses.map(
        (value) =>
          ({ value, label: getNodeStatusLabel(value), color: NODE_STATUS_COLORS[value] } as TOptions),
      ),
    [selectedStatuses],
  )

  const handleSelectionChange = (selectedOptions: TOptions[]) => {
    const statuses = selectedOptions.map((option) => option.value) as TNodeStatus[]

    onChange(statuses)
  }

  return (
    <MultiSelect
      data={equipmentStatuses}
      dropdownPosition={dropdownPosition}
      onChange={handleSelectionChange}
      placeholder={translate.phrases.placeholder('Filter by status')}
      selectedData={selectedOptions}
      optionValueDecorator={(label, option) => (
        <Badge
          css={{
            color: theme.colors.midnight,
            backgroundColor: (option as EquipmentStatusOption).color,
            fontSize: '12px',
            fontWeight: 500,
            textTransform: 'none',
          }}
        >
          {label}
        </Badge>
      )}
    />
  )
}
