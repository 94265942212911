import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconHorizontalDotMenu = () => (
  <svg {...makeBaseSvgIconProps('0 0 25 10')}>
    <path
      d="M3.125 0C1.40625 0 0 1.35 0 3C0 4.65 1.40625 6 3.125 6C4.84375 6 6.25 4.65 6.25 3C6.25 1.35 4.84375 0 3.125 0ZM21.875 0C20.1562 0 18.75 1.35 18.75 3C18.75 4.65 20.1562 6 21.875 6C23.5938 6 25 4.65 25 3C25 1.35 23.5938 0 21.875 0ZM12.5 0C10.7812 0 9.375 1.35 9.375 3C9.375 4.65 10.7812 6 12.5 6C14.2188 6 15.625 4.65 15.625 3C15.625 1.35 14.2188 0 12.5 0Z"
      fill="currentColor"
    />
  </svg>
)
