// TODO: should use .startsWith
export const routeRequiresAuth = (route: string = window.location.pathname) => {
  if (route.toLowerCase().startsWith('/ui-examples')) return false

  if (route.toLowerCase().startsWith('/login')) return false

  if (route.toLowerCase().startsWith('/reset')) return false

  return true
}
