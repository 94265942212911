import { sortByKey } from '@semios/app-platform-common'
import type { TPhrases } from '@semios/app-platform-i18n-core'
import { SelectDropdown } from 'components/SelectDropdown/SelectDropdown'
import { translate } from 'i18n/i18n'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { getAvailablePestTypes } from 'utils/getAvailablePestTypes'
import { AlertSection } from '../../../components/AlertSection'
import { mapAlertIdTypeToLabel } from '../../../mapAlertIdTypeToLabel'
import type { AlertTypeId } from '../../../settings/alertSettings'
import { AlertsSettings, PEST_TYPES_WITH_TRAP_CATCH_ALERTS } from '../../../settings/alertSettings'

export const AlertTypeAndPestType = ({
  alertTypeId,
  insectId,
  onSetAlertTypeId,
  setInsectId,
  alertId,
  defaultSettingsButton,
}: {
  alertTypeId: AlertTypeId | null
  insectId: number | null
  onSetAlertTypeId: (value: AlertTypeId) => void
  setInsectId: Dispatch<SetStateAction<number | null>>
  alertId: string | null
  defaultSettingsButton: ReactNode
}) => {
  const { pestTypes } = fieldAssetStore.useSelector((s) => ({
    pestTypes: s.insects,
  }))

  let shouldShowPestSelection = false

  if (alertTypeId === 'degreeDays' || alertTypeId === 'trapCatches') shouldShowPestSelection = true

  const alertTypeData = AlertsSettings.alertTypes.map((type) => ({
    value: type,
    label: mapAlertIdTypeToLabel(type),
  }))

  const availablePestTypes = getAvailablePestTypes({ alertTypeId })

  const pestFilteredOptionsByAlertTypeId = pestTypes
    ? Object.values(pestTypes).filter((pt) => {
        if (!availablePestTypes.some((availablePestType) => availablePestType === pt.insectId)) return false

        if (alertTypeId === 'trapCatches') return PEST_TYPES_WITH_TRAP_CATCH_ALERTS[pt?.insectId]

        if (alertTypeId === 'degreeDays') return true

        return false
      })
    : []

  const pestSelectOptions = pestFilteredOptionsByAlertTypeId
    .map((pt) => ({
      value: pt.insectId.toString(),
      label: translate.phrases.dbInsectName(pt.name as TPhrases['dbInsectName']),
    }))
    .sort(sortByKey('label'))

  return (
    <AlertSection title={translate.phrases.banyanApp('Type')}>
      <SelectDropdown
        data={alertTypeData}
        placeholder={translate.phrases.banyanApp('Choose an alert type')}
        value={alertTypeId}
        onChange={onSetAlertTypeId}
        disabled={alertId ? true : false}
      />
      {shouldShowPestSelection && (
        <SelectDropdown
          disabled={alertId ? true : false}
          searchable={true}
          onChange={(newInsectId) => setInsectId(Number(newInsectId))}
          placeholder={translate.phrases.banyanApp('Choose a pest type')}
          css={{ width: '100%', marginTop: 20 }}
          value={insectId ? String(insectId) : null}
          data={pestSelectOptions}
        />
      )}
      {defaultSettingsButton && defaultSettingsButton}
    </AlertSection>
  )
}
