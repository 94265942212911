import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'

export const PropertyOrRegionNameHeader = () => {
  // const propertyId = Number(selectedFieldAssetsStore.useSelector((s) => s.property))

  const { regionId, propertyId } = selectedFieldAssetsStore.useSelector((s) => ({
    regionId: s.region,
    propertyId: s.property,
  }))

  let name = ''

  if (propertyId) {
    name = fieldAssetStore.useSelector(
      (s) => s.properties?.[propertyId]?.propertyName ?? translate.phrases.banyanApp('Unknown Property'),
    )
  } else if (regionId) {
    name = fieldAssetStore.useSelector(
      (s) => s.regions?.[String(regionId)]?.name ?? translate.phrases.banyanApp('Unknown Region'),
    )
  }

  return (
    <div css={{ color: colors.midnight, fontSize: 18, fontWeight: 'bold', paddingBottom: 10, paddingTop: 5 }}>
      {name}
    </div>
  )
}
