import { Checkbox as MantineCheckbox } from '@mantine/core'
import { colors } from 'settings/colors'

export const Checkbox = ({ checked, disabled }: { checked: boolean; disabled?: boolean }) => {
  return (
    <MantineCheckbox
      color="gray.0"
      checked={checked}
      disabled={!!disabled}
      styles={{
        root: { cursor: 'pointer' },
        body: { cursor: 'pointer' },
        labelWrapper: { cursor: 'pointer' },
        label: { color: '#ffffff', cursor: 'pointer' },
        input: {
          // TODO: colors after revamp
          backgroundColor: !!disabled ? 'rgba(112, 112, 112, 1) !important' : `${colors.white} !important`,
          border: 'none',
          cursor: 'pointer',
        },
        icon: { 'color': colors.midnight, '& > path': { color: colors.midnight } },
      }}
      onChange={() => null}
    />
  )
}
