export const normalizeSoilMoistureHeatmap = (
  val: number,
  min: number,
  max: number,
  normalizedRange = 100,
  step: number,
) => {
  // Shift values from 0 - max
  const shiftedMin = val - min
  const shiftedMax = max - min
  const shiftedMinOverShiftedMax = shiftedMin / shiftedMax

  if (isNaN(shiftedMinOverShiftedMax)) {
    return val + step * normalizedRange
  }

  return Math.max(0, Math.min(1, shiftedMinOverShiftedMax) * normalizedRange) + step * normalizedRange
}
