import { Switch } from '@mantine/core'
import { IconTorchOff } from 'components/icons/IconTorchOff'
import { IconTorchOn } from 'components/icons/IconTorchOn'
import type { CSSProperties } from 'react'

export const TorchSwitch = (props: {
  style: CSSProperties
  checked: boolean
  onChecked: (check: boolean) => void
}) => {
  return (
    <Switch
      size="xl"
      color="green.0"
      style={props.style}
      styles={{
        track: {
          boxShadow: '0px 5px 4px rgba(74, 74, 104, 0.2)',
        },
      }}
      checked={props.checked}
      onChange={(event) => props.onChecked(event.currentTarget.checked)}
      onLabel={
        <div css={{ color: 'white', fontSize: 20 }}>
          <IconTorchOn />
        </div>
      }
      offLabel={
        <div css={{ color: '#0B1424', fontSize: 20 }}>
          <IconTorchOff />
        </div>
      }
    />
  )
}
