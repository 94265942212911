import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconCircleLeft = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 33 33')}>
      <circle cx="16.5" cy="16.5" r="15.5" stroke="#464B53" strokeWidth="2" />
      <path
        d="M19 23L13 17L19 11"
        stroke="#464B53"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
