import { translate } from 'i18n/i18n'
import { createElement } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

// targetWidth is an imperfect but compromise solution aimed at ensuring that
// the heights of the SummaryTableHierarchicalLabel and SummaryTableHierarchicalContent are consistent,
// so that when the title is too long and causes a line break,
// the indented content and indented title can be perfectly aligned.
export const calTotalSectionHeight = (
  mainTitle: string,
  indentedTitles: { key: string | number; name: string }[],
  targetWidth: number,
) => {
  const summaryTableLabelHtml = renderToStaticMarkup(
    createElement(SummaryTableHierarchicalLabel, {
      mainTitle,
      indentedTitles,
    }),
  )

  const adjustmentWidth = 4
  const padding = 15
  const border = 1
  const hiddenContainer = document.createElement('div')

  hiddenContainer.className = 'hidden-container-insect-trap-summary-table-label'

  hiddenContainer.style.width = `${targetWidth - padding * 2 - border * 2}px`

  hiddenContainer.innerHTML = summaryTableLabelHtml

  document.body.appendChild(hiddenContainer)

  const offsetHeight = hiddenContainer.offsetHeight

  document.body.removeChild(hiddenContainer)

  return {
    height: offsetHeight,
    labelMinWidth: targetWidth + adjustmentWidth,
    valueMinWidth: targetWidth,
  }
}

const HierarchicalMainTitle = ({ mainTitle, isHidden }: { mainTitle: string; isHidden?: boolean }) => {
  return (
    <div
      css={{
        width: '100%',
        margin: '0 0 10px 0',
        display: 'flex',
        fontSize: '14px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p
        css={{
          margin: '0px',
          fontWeight: 'bold',
          paddingRight: '20px',
          visibility: isHidden ? 'hidden' : 'visible',
        }}
      >
        {mainTitle}
      </p>
    </div>
  )
}

const HierarchicalIndentedTitle = ({
  indentedTitle,
  isHidden,
}: {
  indentedTitle: string
  isHidden?: boolean
}) => {
  return (
    <div
      css={{
        width: '100%',
        margin: '0 0 10px 0',
        fontSize: '14px',
        fontWeight: 'normal',
        visibility: isHidden ? 'hidden' : 'visible',
      }}
    >
      {indentedTitle}
    </div>
  )
}

export const SummaryTableHierarchicalLabel = ({
  mainTitle,
  indentedTitles,
}: {
  mainTitle: string
  indentedTitles: { key: string | number; name: string }[]
}) => {
  return (
    <div
      css={{
        padding: '30px 0',
      }}
    >
      <HierarchicalMainTitle mainTitle={mainTitle} />
      <div
        css={{
          paddingLeft: '10px',
        }}
      >
        {indentedTitles.map((title) => (
          <HierarchicalIndentedTitle indentedTitle={title.name} key={title.key} />
        ))}
      </div>
    </div>
  )
}

export const SummaryTableHierarchicalContent = ({
  mainTitle,
  indentedDataPoints,
}: {
  mainTitle: string
  indentedDataPoints: { timestamp: string; value: number | null; name: string }[]
}) => {
  return (
    <div
      css={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 'max-content',
        minWidth: 74,
        maxWidth: '100%',
        height: 'max-content !important',
        padding: '30px 15px',
      }}
    >
      <HierarchicalMainTitle mainTitle={mainTitle} isHidden={true} />
      <div
        css={{
          paddingLeft: '10px',
        }}
      >
        {indentedDataPoints.map((point, index) => {
          return (
            <div
              css={{
                position: 'relative',
              }}
              key={index}
            >
              <HierarchicalIndentedTitle indentedTitle={point.name} isHidden={true} />
              <div
                css={{
                  position: 'absolute',
                  top: 0,
                  left: '-10px',
                  right: 0,
                  bottom: 0,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {point.value === null ? translate.phrases.templates('-') : point.value}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
