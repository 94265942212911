import type { routes } from '@semios/app-platform-banyan-route-definitions'
import type { TFieldAssetKeyTypes } from 'App/Map/types'
import { translate } from 'i18n/i18n'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { sortByKey } from 'utils/sortByKey'
import type { TSearchResultsItem } from '../types'
import { countryT } from './countryT'

export const getSearchResultsCountries = ({
  properties,
  searchText,
}: {
  properties: NonNullable<routes.UserAppStartup.Response['properties']>
  searchText: string
}): TSearchResultsItem[] => {
  const countryPossibilities = Object.values(properties)
    .sort(sortByKey('propertyName'))
    .reduce((a: Record<string, TFieldAssetKeyTypes.TPropertyId[]>, b) => {
      const translatedCountry = countryT(b.country)

      a[translatedCountry] = a[translatedCountry] ?? []

      a[translatedCountry].push(b.propertyId)

      return a
    }, {})

  const countryResults: {
    country: string
    propertyIds: TFieldAssetKeyTypes.TPropertyId[]
  }[] = []

  Object.entries(countryPossibilities).forEach(([country, propertyIds]) => {
    if (searchStringFromStringWithSpaces(searchText, country)) {
      countryResults.push({ country, propertyIds })
    }
  })

  return countryResults.sort(sortByKey('country')).map((o) => ({
    type: 'COUNTRY',
    label: o.country,
    propertyIds: o.propertyIds,
    value: `COUNTRY-${o.country}`,
    group: translate.phrases.templates('{{label}} ({{count}})', {
      label: translate.phrases.banyanApp('Country'),
      count: countryResults.length,
    }),
  }))
}
