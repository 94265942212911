import { OfflineRequestError } from 'utils/errorCodes'
import { stringify as stringifyWKT } from 'wellknown'
import type { PlannedNodeActivationPayload } from '../NodeInstallation/types'
import { serviceCenterStore } from '../store/serviceCenterStore'
import type { TActiveNode } from '../types'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import { getIdentifier } from './getIdentifier'
import { isSemiosGatewayType } from './getNodeNetworkType'

export async function installPlannedNode(payload: PlannedNodeActivationPayload): Promise<TActiveNode> {
  let unsynced = true

  // Install planned node
  try {
    if (isSemiosGatewayType(payload.nodeType)) {
      throw new Error('Gateway installation is not supported yet!')
    }

    const updatedPayload = {
      ...payload,
      pseudonodeId: +payload.pseudonodeId, //TODO, we need to extract pseudogatewayId from id
      location: stringifyWKT(payload.location),
    }

    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.INSTALL_NODE, updatedPayload)

    if (status === ApiRequestStatus.COMPLETED) unsynced = false
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) throw error
  }

  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())

  let activeNode: TActiveNode

  if (unsynced) {
    // If the request has not been completed yet, update the local state
    const updatedActiveNodes = [...activeNodes].filter(
      (node) => getIdentifier(node) !== payload.nodeIdentifier,
    )

    activeNode = {
      ...payload,
      name: payload.name || null,
      location: JSON.stringify(payload.location),
    }

    updatedActiveNodes.push(activeNode)

    serviceCenterStore.actions.setActiveNodes(updatedActiveNodes)
  } else {
    // If request has been completed, find the newly installed active node
    activeNode = activeNodes.find((node) => getIdentifier(node) === payload.nodeIdentifier) as TActiveNode

    if (!activeNode) throw new Error('Could not find newly installed active node!')
  }

  // Select newly installed active node
  serviceCenterStore.actions.selectEquipment(payload.nodeIdentifier, 'active')

  // Remove installed planned node from list
  serviceCenterStore.actions.removePlannedNodes([payload.pseudonodeId])

  return activeNode
}
