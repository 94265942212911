import type {
  SCHEDULED_CONTINUOUS_EVENT,
  SCHEDULED_PULSE_EVENT,
  TIrrigationSchedulerStoreState,
} from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { TScheduledEventEnum } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import { getPermissionForSelectedProperty } from 'App/irrigation-scheduler/utils/store/selectors/getPermissionForSelectedProperty'
import type { TFieldAssetStoreState } from 'stores/fieldAssetStore'
import type { TUserDetailsStoreState } from 'stores/userDetailsStore'

export const getEventActions = (
  fass: TFieldAssetStoreState,
  isss: TIrrigationSchedulerStoreState,
  udss: TUserDetailsStoreState,
  event: SCHEDULED_CONTINUOUS_EVENT | SCHEDULED_PULSE_EVENT,
  eventIs3rdPartyControlled: boolean,
): {
  canEdit: boolean
  canDelete: boolean
  canStop: boolean
  isHappeningNow: boolean
  isInTheFuture: boolean
} => {
  const permissions = getPermissionForSelectedProperty(fass, isss, udss)
  const now = Date.now()
  const isHappeningNow = event.dateFrom.isBefore(now) && event.dateTo.isAfter(now)
  const isInTheFuture = event.dateFrom.isAfter(now)

  const canStop =
    permissions.publish &&
    eventIs3rdPartyControlled &&
    event.type === TScheduledEventEnum.SCHEDULED &&
    isHappeningNow

  const cannotStop = !canStop

  const canEdit =
    cannotStop &&
    permissions.edit &&
    [TScheduledEventEnum.DRAFT_CREATE, TScheduledEventEnum.SCHEDULED].includes(event.type) &&
    isInTheFuture

  const canDelete =
    cannotStop &&
    permissions.edit &&
    [TScheduledEventEnum.DRAFT_CREATE, TScheduledEventEnum.SCHEDULED].includes(event.type) &&
    (isInTheFuture || isHappeningNow)

  return {
    canEdit,
    canDelete,
    canStop,
    isHappeningNow,
    isInTheFuture,
  }
}
