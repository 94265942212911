import { OfflineRequestError } from 'utils/errorCodes'
import { ApiRequestQueue, ApiRequestStatus, ApiRequestType } from './api/queue'
import type { GatewayRepositionMeta } from './api/serviceCenterGatewayReposition'
import { serviceCenterGatewayReposition } from './api/serviceCenterGatewayReposition'

export async function repositionActiveGateway(param: GatewayRepositionMeta) {
  try {
    // Request may or may not have been completed by the time the promise resolves
    const { status } = await ApiRequestQueue.createRequest(ApiRequestType.REPOSITION_ACTIVE_GATEWAY, param)

    if (status === ApiRequestStatus.COMPLETED) return
  } catch (error) {
    // If the request failed because the app is offline, don't throw an error
    if (!(error instanceof OfflineRequestError)) {
      throw error
    }
  }

  serviceCenterGatewayReposition.callback(param)
}
