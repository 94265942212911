import { Checkbox as MantineCheckBox } from '@mantine/core'
import type { TProperty } from '@semios/app-platform-banyan-route-definitions/src/shared-types'
import { sortByKey } from '@semios/app-platform-common'
import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { MultiSelect } from 'components/MultiSelect/MultiSelect'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { constantCaseify } from 'utils/constantCaseify'
import { getBlocksFromProperties } from 'utils/getBlocksFromProperties'
import { getPointsByValuesCurrentKey } from 'utils/getPointsByValuesCurrentKey'
import { AlertSection } from '../../../../components/AlertSection'
import { ErrorTextWrapper } from '../../../../components/ErrorTextWrapper'
import type { AlertTypeId, Rule } from '../../../../settings/alertSettings'
import { AlertsSettings } from '../../../../settings/alertSettings'
import type { WeatherForecastType } from '../../../ModalDrawerCreateOrEditAlert'
import { alertRulesObjectFromArray } from '../../_utils/alertRulesObjectFromArray'
import { updateAlertRules } from '../../_utils/updateAlertRules'
import { RadioWrapper } from '../RadioWrapper/RadioWrapper'

const CREATE_alertTypeId_ALERT = (alertTypeId: AlertTypeId) => {
  if (alertTypeId === 'weatherForecastAlert') return 'CREATE_WEATHER_FORECAST_ALERT'

  return `CREATE_${constantCaseify(alertTypeId)}_ALERT`
}

const multiSelectDataBuilder = ({
  includeBlocks,
  includeStations,
  properties,
  stationTypeKey,
}: {
  includeBlocks: boolean
  includeStations: boolean
  properties: TProperty[]
  stationTypeKey: string
}) => {
  let stationsToReturn: TOptions[] = []
  let blocksToReturn: TOptions[] = []

  if (includeStations) {
    stationsToReturn = getPointsByValuesCurrentKey({
      properties: properties,
      key: stationTypeKey,
    })
      .map((station) => ({
        label: station.name,
        value: `${station.propertyId}&${station.geom}`,
        group: station.propertyName,
        id: station.geom,
      }))
      .sort(sortByKey('label'))
  }

  if (includeBlocks) {
    blocksToReturn = getBlocksFromProperties({ properties })
      .map((block) => ({
        label: block.name,
        value: `${block.propertyId}&${block.blockId}`,
        group: block.propertyName,
        id: String(block.blockId),
      }))
      .sort(sortByKey('label'))
  }

  return [...stationsToReturn, ...blocksToReturn].sort(sortByKey('group'))
}

export const WeatherForecastCoverage = ({
  alertTypeId,
  coverageBlocks,
  coverageIsValid,
  coverageProperties,
  coverageStations,
  coverageType,
  insectId,
  rules,
  setCoverageBlocks,
  setCoverageIsValid,
  setCoverageProperties,
  setCoverageStations,
  setCoverageType,
  setRules,
  triedToSubmit,
  weatherForecastThresholdType,
}: {
  alertTypeId: AlertTypeId
  coverageBlocks: string[]
  coverageIsValid: boolean
  coverageProperties: string[]
  coverageStations: string[]
  coverageType: string
  insectId: number | null
  rules: Rule[]
  setCoverageBlocks: Dispatch<SetStateAction<string[]>>
  setCoverageIsValid: Dispatch<SetStateAction<boolean>>
  setCoverageProperties: Dispatch<SetStateAction<string[]>>
  setCoverageStations: Dispatch<SetStateAction<string[]>>
  setCoverageType: Dispatch<SetStateAction<string>>
  setRules: Dispatch<SetStateAction<Rule[]>>
  triedToSubmit: boolean
  weatherForecastThresholdType: WeatherForecastType
}) => {
  // includeAllDevices-handling
  const { includeAllDevices: includeAllDevicesRule, measurementHeights: measurementHeightsRule } =
    alertRulesObjectFromArray(rules)

  const measurementHeights = measurementHeightsRule
    ? (measurementHeightsRule.value as string[])
    : ['aboveCanopyTemperature']

  const includeAllDevices =
    includeAllDevicesRule && includeAllDevicesRule.value === true ? includeAllDevicesRule.value : false

  const typeIsAll = coverageType === 'all'
  const typeIsProperties = coverageType === 'properties'
  const typeIsStationsOrBlocks = coverageType === 'stationsOrBlocks'
  const stationTypeKey = AlertsSettings.stationTypeKey[alertTypeId]

  useEffect(() => {
    if (coverageType === 'all') setCoverageIsValid(true)

    if (coverageType === 'stationsOrBlocks')
      setCoverageIsValid(!!coverageStations.length || !!coverageBlocks.length)

    if (coverageType === 'properties') setCoverageIsValid(!!coverageProperties.length)

    if (
      alertTypeId === 'weatherForecastAlert' &&
      weatherForecastThresholdType === AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE &&
      measurementHeights
    )
      setCoverageIsValid(measurementHeights?.length > 0)
  }, [coverageProperties, coverageStations, coverageType])

  let allWording = includeAllDevices
    ? translate.phrases.banyanApp('Stations/Blocks Across All Properties')
    : translate.phrases.banyanApp('Stations Across All Properties')

  const getHelpText = () => {
    switch (coverageType) {
      case 'properties':
        return translate.phrases.banyanApp('Please select at least one of your properties')

      case 'stationsOrBlocks':
        if (includeAllDevices)
          return translate.phrases.banyanApp('Please select at least one of your stations/blocks')

        return translate.phrases.banyanApp('Please select at least one of your stations')

      default:
        return ''
    }
  }

  let validateStatusError
  let helpText = ''

  if (!coverageIsValid && triedToSubmit && coverageType !== 'all') {
    helpText = getHelpText()

    validateStatusError = true
  }

  const properties = fieldAssetStore.useSelector((s) => s?.properties ?? {})

  const filteredPropertiesByPermission = Object.values(properties).filter((property: TProperty) => {
    let pestsFilter: string | null = null

    if (alertTypeId === 'trapCatches') pestsFilter = 'insectTrapCatches'

    if (alertTypeId === 'degreeDays') pestsFilter = 'insectDegreeDays'

    if (pestsFilter !== null) {
      const isPropertyValid = Object.values(property.points || {}).some((point) => {
        return point.valuesCurrent.some(
          (value) => value.includes(pestsFilter as string) && value.includes(`insectId${insectId}`),
        )
      })

      return (
        isPropertyValid &&
        checkAuthorization({
          permission: CREATE_alertTypeId_ALERT(alertTypeId) as TPermission,
          entity: property.propertyId,
        })
      )
    }

    return checkAuthorization({
      permission: CREATE_alertTypeId_ALERT(alertTypeId) as TPermission,
      entity: property.propertyId,
    })
  })

  const propertiesData = filteredPropertiesByPermission
    .map((property) => ({
      label: property.propertyName,
      value: property.propertyId.toString(),
    }))
    .sort(sortByKey('label'))

  const getSelectedData = (options: TOptions[], coverage: string[]) => {
    const selectedData = options.filter((option) => coverage.includes(option.value as string))

    return selectedData
  }

  return (
    <AlertSection title={translate.phrases.banyanApp('Coverage')}>
      <div css={{ display: 'grid', gridTemplateColumns: '100%', gridGap: 24 }}>
        <RadioWrapper checked={typeIsAll} onClick={() => setCoverageType('all')} title={allWording} />
        {/* PROPERTIES */}
        {!!AlertsSettings.allowCoverageProperties[alertTypeId] && (
          <RadioWrapper
            checked={typeIsProperties}
            onClick={() => setCoverageType('properties')}
            title={translate.phrases.banyanApp('Specific Properties')}
          >
            {typeIsProperties && (
              <MultiSelect
                data={propertiesData}
                placeholder={typeIsProperties ? translate.phrases.banyanApp('Select Properties') : ''}
                onChange={(selectedValues: TOptions[]) => {
                  setCoverageProperties(selectedValues.map((option) => option.value as string))

                  setCoverageStations([])

                  setCoverageBlocks([])
                }}
                selectedData={typeIsProperties ? getSelectedData(propertiesData, coverageProperties) : []}
                disabled={!typeIsProperties}
                error={validateStatusError && typeIsProperties}
              />
            )}
          </RadioWrapper>
        )}
        {/* STATIONS/BLOCKS/ALL-DEVICES */}
        {(() => {
          /**
           * we have to merge stations and blocks in the same dropdown. This can be a
           * bit confusing, but luckily station geoms are very long—50 character hex
           * strings—and block ids are comparatively short integers. The keys in the
           * tree follow the pattern "propertyId-stationGeom" or "propertyId-blockId"
           * so we can just assume from those. Note this only works assuming geoms for
           * stations and blockIds for blocks, and blockIds to be short, so this will
           * need to be changed if that reality doesn't hold anymore
           */
          const stationsOrBlocksOnChange = (newStationsOrBlocks: TOptions[]) => {
            const { newBlocks, newStations } = newStationsOrBlocks.reduce(
              (a, b) => {
                if ((b.value as string).split('&')[1].length > 30) a.newStations.push(b.value as string)
                else a.newBlocks.push(b.value as string)

                return a
              },
              {
                newBlocks: [] as string[],
                newStations: [] as string[],
              },
            )

            setCoverageBlocks(newBlocks)

            setCoverageStations(newStations)

            setCoverageProperties([])
          }

          if (includeAllDevices) {
            const stationOrBlocksData = multiSelectDataBuilder({
              includeBlocks: true,
              includeStations: true,
              properties: filteredPropertiesByPermission,
              stationTypeKey,
            })

            return (
              <RadioWrapper
                checked={typeIsStationsOrBlocks}
                onClick={() => setCoverageType('stationsOrBlocks')}
                title={translate.phrases.banyanApp('Specific Stations/Blocks')}
              >
                {typeIsStationsOrBlocks && (
                  <MultiSelect
                    data={stationOrBlocksData}
                    placeholder={
                      typeIsStationsOrBlocks ? translate.phrases.banyanApp('Select stations/blocks') : ''
                    }
                    onChange={(selectedValues: TOptions[]) => {
                      stationsOrBlocksOnChange(selectedValues)
                    }}
                    selectedData={
                      typeIsStationsOrBlocks
                        ? getSelectedData(stationOrBlocksData, [...coverageStations, ...coverageBlocks])
                        : []
                    }
                    groupOptions={true}
                    disabled={!typeIsStationsOrBlocks}
                    error={validateStatusError && typeIsStationsOrBlocks}
                  />
                )}
              </RadioWrapper>
            )
          }

          if (!!AlertsSettings.allowCoverageStations[alertTypeId]) {
            const stationOrBlocksData = multiSelectDataBuilder({
              includeBlocks: false,
              includeStations: true,
              properties: filteredPropertiesByPermission,
              stationTypeKey,
            })

            return (
              <RadioWrapper
                checked={typeIsStationsOrBlocks}
                onClick={() => setCoverageType('stationsOrBlocks')}
                title={translate.phrases.banyanApp('Specific Stations')}
              >
                {typeIsStationsOrBlocks && (
                  <MultiSelect
                    data={stationOrBlocksData}
                    placeholder={typeIsStationsOrBlocks ? translate.phrases.banyanApp('Select stations') : ''}
                    onChange={(selectedValues: TOptions[]) => {
                      stationsOrBlocksOnChange(selectedValues)
                    }}
                    selectedData={
                      typeIsStationsOrBlocks ? getSelectedData(stationOrBlocksData, coverageStations) : []
                    }
                    groupOptions={true}
                    disabled={!typeIsStationsOrBlocks}
                    error={validateStatusError && typeIsStationsOrBlocks}
                  />
                )}
              </RadioWrapper>
            )
          }

          if (!!AlertsSettings.allowCoverageBlocks[alertTypeId]) {
            const stationOrBlocksData = multiSelectDataBuilder({
              includeBlocks: true,
              includeStations: false,
              properties: filteredPropertiesByPermission,
              stationTypeKey,
            })

            return (
              <RadioWrapper
                checked={typeIsStationsOrBlocks}
                onClick={() => setCoverageType('stationsOrBlocks')}
                title={translate.phrases.banyanApp('Specific Blocks')}
              >
                {typeIsStationsOrBlocks && (
                  <MultiSelect
                    data={stationOrBlocksData}
                    placeholder={typeIsStationsOrBlocks ? translate.phrases.banyanApp('Select blocks') : ''}
                    onChange={(selectedValues: TOptions[]) => {
                      stationsOrBlocksOnChange(selectedValues)
                    }}
                    selectedData={
                      typeIsStationsOrBlocks ? getSelectedData(stationOrBlocksData, coverageBlocks) : []
                    }
                    groupOptions={true}
                    disabled={!typeIsStationsOrBlocks}
                    error={validateStatusError && typeIsStationsOrBlocks}
                  />
                )}
              </RadioWrapper>
            )
          }

          // in cases with nothing more granular than property—e.g. trapCatches—return null here
          return null
        })()}

        {weatherForecastThresholdType === AlertsSettings.weatherForecastAlertThresholdItems.TEMPERATURE && (
          <>
            <div>{translate.phrases.banyanApp('At the following measurement height:')}</div>
            <MantineCheckBox.Group
              onChange={(val) => {
                let updateRule = null

                if (val.length > 0) updateRule = { value: val, operator: '=' }

                updateAlertRules({
                  rules,
                  setRules,
                  rulesToChange: { measurementHeights: updateRule },
                })
              }}
              value={measurementHeights}
            >
              <Checkbox
                value="aboveCanopyTemperature"
                label="Above Canopy Temperature"
                css={{ marginBottom: 15 }}
              />
              <Checkbox value="inCanopyTemperature" label="In Canopy Temperature" />
            </MantineCheckBox.Group>

            <Checkbox
              checked={!!includeAllDevices}
              onChange={() =>
                updateAlertRules({
                  rules,
                  setRules,
                  // rule values are always strings, so let's make that boolean a string
                  rulesToChange: {
                    includeAllDevices: { value: !includeAllDevices, operator: '=' },
                  },
                })
              }
              label={translate.phrases.banyanApp('Include all devices')}
            />
          </>
        )}

        {helpText && <ErrorTextWrapper>{helpText}</ErrorTextWrapper>}
      </div>
    </AlertSection>
  )
}
