import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { smallStore } from 'stores/smallStore'

export const PresetDropdownContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', maxHeight: 400 }}>
      <div
        css={{
          flex: 1,
          overflowY: 'auto',
          /**
           * this inner shadow isn't great, since hovered/selected items in the
           * select list display over top of it, but it helps a bit at least
           */
          background: `
            linear-gradient(${colors.white} 33%, rgba(0,0,0, 0)),
            linear-gradient(${colors.white}, ${colors.white} 66%) 0 100%,
            radial-gradient(farthest-side at 50% 0, rgba(34,34,34, 0.5), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(34,34,34, 0.5), rgba(0,0,0,0)) 0 100%;
          `,
          backgroundColor: colors.white,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'local, local, scroll, scroll',
          backgroundSize: '100% 20px, 100% 20px, 100% 10px, 100% 10px',
        }}
      >
        <div>{children}</div>
      </div>
      <div css={{ display: 'flex', width: '100%', justifyContent: 'center', padding: '4px 12px' }}>
        <Button
          size="xs"
          variant="tertiary"
          onClick={() =>
            smallStore.setState((s) => ({
              ...s,
              showSettingsModal: true,
              settingsModalSelectedTab: 'presets',
            }))
          }
        >
          {translate.phrases.banyanApp('Manage Views')}
        </Button>
      </div>
    </div>
  )
}
