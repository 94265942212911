import isReadonlyArray from 'utils/isReadonlyArray'

export const snap = (
  val: number,
  snapAmount: number | ReadonlyArray<number>,
  moveDirection?: 'UP_OR_LEFT' | 'DOWN_OR_RIGHT',
): number => {
  if (isReadonlyArray(snapAmount)) {
    if (snapAmount.length === 0) return val

    if (snapAmount.length === 1) return snapAmount[0]

    const clonedSnapAmount = [...snapAmount]

    clonedSnapAmount.sort((a, b) => a - b)

    const foundIndex = clonedSnapAmount.findIndex((snapTo) => val <= snapTo)
    const snapCeil = clonedSnapAmount[foundIndex]

    if (foundIndex === 0) return snapCeil

    if (foundIndex < 0) return clonedSnapAmount[clonedSnapAmount.length - 1]

    const snapFloor = clonedSnapAmount[foundIndex - 1]

    if (moveDirection === 'DOWN_OR_RIGHT') return snapCeil

    if (moveDirection === 'UP_OR_LEFT') return snapFloor

    const awayFromCeil = snapCeil - val
    const awayFromFloor = val - snapFloor

    if (awayFromCeil <= awayFromFloor) return snapCeil

    return snapFloor
  }

  const snappedCeil = Math.ceil(val / snapAmount) * snapAmount
  const snappedFloor = Math.floor(val / snapAmount) * snapAmount

  if (moveDirection === 'DOWN_OR_RIGHT') return snappedCeil

  if (moveDirection === 'UP_OR_LEFT') return snappedFloor

  // If moved closer to the ceil... then snap to the ceil
  if (val >= snappedCeil - snapAmount / 2) return snappedCeil

  // otherwise, snap to the floor
  return snappedCeil - snapAmount // Same as Math.floor(val / snapAmount) * snapAmount
}
