import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import type { ESprayRecommendation } from 'App/Map/types'
import { getSprayConditionsTitleAndColor } from 'App/Map/_utils/getSprayConditionsTitleAndColor'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { colors } from 'settings/colors'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentPointsValueTypes,
} from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'

const valueType: TValuesCurrentBlocksValueTypes | TValuesCurrentPointsValueTypes =
  'sprayConditions_currentHour'

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  const returner: routes.ValuesCurrent.Request = {}

  if (!!processedCaches.blockValues.itemsWithinView.length) {
    returner.blocks = {
      blockIds: processedCaches.blockValues.itemsWithinView.map((b) => Number(b.id)),
      values: { [valueType]: true },
    }
  }

  if (!!processedCaches.propertyValues.itemsWithinView.length) {
    returner.properties = {
      propertyIds: processedCaches.propertyValues.itemsWithinView.map((p) => Number(p.id)),
      values: { [valueType]: true },
    }
  }

  return returner
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  const returner: TGetCacheUpdatesFromResponseReturn = {}
  const willShowBlocks = !!processedCaches.blockValues.itemsWithinView.length

  if (willShowBlocks) {
    const itemIdsWithinView: string[] = []

    const itemsWithinViewThatNowHaveValues = processedCaches.blockValues.itemsWithinView.flatMap((block) => {
      const value = response?.blocks?.[block.id]?.[valueType]?.value

      if (isNil(value)) return []

      itemIdsWithinView.push(block.id)

      // @ts-ignore TODO: consolidate that enum type better
      const { color: backgroundColor, name } = getSprayConditionsTitleAndColor(value ?? null)

      return {
        id: String(block.meta.blockId),
        value: {
          [valueType]: {
            children: name,
            getContainerCSS: () => ({ backgroundColor, color: colors.midnight }),
          },
        },
      }
    })

    returner.blocks = {
      itemsWithinViewThatNowHaveValues,
      cacheKey: cacheKeys.blockValuesCacheKey,
      itemIdsWithinView,
    }
  }

  const itemsWithinViewThatNowHaveValues = processedCaches.propertyValues.itemsWithinView.map((property) => {
    const showPropertyNameOnly = willShowBlocks

    if (showPropertyNameOnly) {
      return {
        id: String(property.id),
        value: {
          [valueType]: {
            children: property.meta.propertyName,
            onHover: false,
            getContainerCSS: () => ({ backgroundColor: colors.midnight, color: 'white' }),
            baseZIndex: undefined,
          },
        },
      }
    }

    const value = response?.properties?.[property.id]?.[valueType]?.value as ESprayRecommendation | undefined
    const hasValue = value !== undefined
    const sprayConditionsTitleAndColorInCaseWeHaveAValue = getSprayConditionsTitleAndColor(value ?? null)
    const backgroundColor = hasValue ? sprayConditionsTitleAndColorInCaseWeHaveAValue.color : colors.midnight
    const color = hasValue ? colors.midnight : colors.white

    return {
      id: String(property.id),
      value: {
        [valueType]: {
          children: hasValue
            ? sprayConditionsTitleAndColorInCaseWeHaveAValue.name
            : translate.phrases.templates('-'),
          onHover: true,
          getContainerCSS: () => ({ backgroundColor, color }),
          baseZIndex: isNil(value) ? -10 : 1,
        },
      },
    }
  })

  returner.properties = {
    itemsWithinViewThatNowHaveValues,
    cacheKey: cacheKeys.propertyCacheKey,
    itemIdsWithinView: processedCaches.propertyValues.itemIdsWithinView,
  }

  return returner
}
