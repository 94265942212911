import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import type { ModifiedZone } from '../../EmitterConfiguration'
import type { TZoneFlowRateByEmitterType } from '../../types'
import { EmitterConfigurationZone } from './EmitterConfigurationZone/EmitterConfigurationZone'

type TEmitterConfigurationSection = {
  propertyId: number
  propertyName: string
  irrigationZones: TZoneFlowRateByEmitterType[]
  modifiedZones: ModifiedZone[]
  setModifiedZones: Dispatch<SetStateAction<ModifiedZone[]>>
}

export const EmitterConfigurationSection = ({
  propertyId,
  propertyName,
  irrigationZones,
  modifiedZones,
  setModifiedZones,
}: TEmitterConfigurationSection) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <div>
      <SectionTitleBar
        key={propertyName}
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        title={propertyName}
      />
      <div
        css={{
          padding: collapsed ? 0 : '20px',
          opacity: collapsed ? 0 : 1,
          height: collapsed ? 0 : '100%',
          overflow: collapsed ? 'hidden' : 'unset',
          boxSizing: 'border-box',
          borderCollapse: 'collapse',
          display: 'flex',
          gap: collapsed ? 0 : 20,
          flexDirection: 'column',
        }}
      >
        {irrigationZones.map((zone) => {
          const { flowRate, zoneId, zoneName } = zone

          return (
            <EmitterConfigurationZone
              key={zoneId}
              flowRate={flowRate}
              zoneId={zoneId}
              zoneName={zoneName}
              modifiedZones={modifiedZones}
              setModifiedZones={setModifiedZones}
              propertyId={propertyId}
            />
          )
        })}
      </div>
    </div>
  )
}
