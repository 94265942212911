import { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { cloneDeep } from 'lodash'
import { apiFetch } from 'utils/apiFetch'
import type { TNodeDevice } from '../../types'
import { getIdentifier } from '../getIdentifier'
import type { MetaWithNodeIdentifier } from './queue'

export type NodeDeviceRemoveMeta = MetaWithNodeIdentifier & {
  device: Required<Pick<TNodeDevice, 'connector' | 'channel'>>
}

const request = async (meta: NodeDeviceRemoveMeta): Promise<NodeDeviceRemoveMeta> => {
  await apiFetch<routes.ServiceCenterNodeDeviceRemove.Request, routes.ServiceCenterNodeDeviceRemove.Response>(
    {
      url: routes.ServiceCenterNodeDeviceRemove.path,
      body: {
        nodeIdentifier: meta.nodeIdentifier,
        ...meta.device,
      },
      params: {
        method: 'POST',
      },
    },
  )

  return meta
}

const callback = (meta: NodeDeviceRemoveMeta) => {
  const { nodeIdentifier, device } = meta
  const activeNodes = serviceCenterStore.selectors.getActiveNodes(serviceCenterStore.getState())
  const existingActiveNodes = [...activeNodes].filter((node) => getIdentifier(node) !== nodeIdentifier)
  const updatedNode = cloneDeep(activeNodes.find((node) => getIdentifier(node) === nodeIdentifier))

  if (updatedNode) {
    if (!updatedNode.devices) updatedNode.devices = {}

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    delete updatedNode.devices[device.connector]![device.channel]

    serviceCenterStore.actions.setActiveNodes([...existingActiveNodes, updatedNode])
  }
}

export const serviceCenterNodeDeviceRemove = {
  request,
  callback,
}
