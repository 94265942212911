import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconControlRPM = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 12 12')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      fill="currentColor"
    >
      <path
        d="M8.26333 3.00005C7.60498 3.00005 6.99561 3.10598 6.45726 3.30145L6.74811 0.416098C6.77343 0.171414 6.55804 -0.0275673 6.31242 0.00313536C4.44448 0.237507 3.00004 1.81975 3.00004 3.73667C3.00004 4.39502 3.10598 5.00439 3.30145 5.54274L0.416098 5.25189C0.171414 5.22658 -0.0275673 5.44196 0.00313536 5.68758C0.237507 7.55553 1.81975 8.99996 3.73667 8.99996C4.39502 8.99996 5.00439 8.89402 5.54274 8.69856L5.25189 11.5839C5.22728 11.8284 5.44196 12.0276 5.68758 11.9969C7.55552 11.7625 8.99996 10.1803 8.99996 8.26333C8.99996 7.60498 8.89402 6.99561 8.69855 6.45726L11.5839 6.74812C11.8286 6.77273 12.0276 6.55804 11.9969 6.31242C11.7625 4.44448 10.1803 3.00005 8.26333 3.00005ZM6 6.74999C5.85167 6.74999 5.70666 6.706 5.58333 6.62359C5.45999 6.54118 5.36387 6.42405 5.3071 6.28701C5.25034 6.14997 5.23548 5.99917 5.26442 5.85369C5.29336 5.7082 5.36479 5.57457 5.46968 5.46968C5.57457 5.36479 5.7082 5.29336 5.85368 5.26442C5.99917 5.23548 6.14997 5.25034 6.28701 5.3071C6.42405 5.36387 6.54118 5.45999 6.62359 5.58333C6.706 5.70667 6.74999 5.85167 6.74999 6C6.74999 6.19891 6.67097 6.38967 6.53032 6.53032C6.38967 6.67097 6.19891 6.74999 6 6.74999Z"
        fill="#464B53"
      />
    </svg>
  )
}
