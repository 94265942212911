import React from 'react'

export function renderWithDefaultValue<T>(
  value: T,
  defaultValue = '-',
  suffix?: string,
): T | string | JSX.Element {
  if (typeof value === 'number') return value + (suffix ? ' ' + suffix : '')

  if (value) {
    if (typeof value === 'string') return value + (suffix ? ' ' + suffix : '')
    else
      return (
        <>
          {value}
          {suffix ? ' ' + suffix : ''}
        </>
      )
  }

  return defaultValue
}
