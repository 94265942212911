import { VV } from '@semios/app-platform-value-type-definitions'
import type { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { getClosestBlockInPropertyForAPoint } from 'utils/getClosestBlockInPropertyForAPoint'
import { getClosestIrrigationZoneEmitterInPropertyForAPoint } from 'utils/getClosestIrrigationZoneEmitterInPropertyForAPoint'
import { getClosestLngLatBasedFieldAssetInPropertyForAPoint } from 'utils/getClosestLngLatBasedFieldAssetInPropertyForAPoint'
import { getAnchorCoordinatesForBlock } from './by-field-asset/block'

const settings = VV.S

const valueGroupsForInBlockWeather = [
  'humidity',
  'dew_point',
  'wet_bulb',
  'atmospheric_pressure',
  'chill',
  'leaf_wetness',
  'air_temperature',
  'spray_conditions',
  'air_temperature',
  'weather_conditions',
]

const valueGroupsForOOBWeather = ['precipitation', 'wind']

const inBlockWeatherSettings = settings.weatherSettings.filter((s) =>
  valueGroupsForInBlockWeather.includes(s.valueTypeGroupId),
)

const ooBWeatherSettings = settings.weatherSettings.filter((s) =>
  valueGroupsForOOBWeather.includes(s.valueTypeGroupId),
)

const soilSettings = settings.soilSettings
const fruitGrowthSettings = settings.allSettings.filter((s) => s.valueTypeGroupId === 'fruit_growth')
const plantStressSettings = settings.allSettings.filter((s) => s.valueTypeGroupId === 'plant_stress')
const windMachineSettings = settings.allSettings.filter((s) => s.valueTypeGroupId === 'wind_machine')

export const getArgsForAnchorCoordinates = ({
  anchorCoordinates: anchorCoordsRaw,
  property,
  args,
}: {
  anchorCoordinates: { lng: number; lat: number }
  property: TFieldAssetValueTypes.TProperty
  args?: {
    irrigationZoneEmitter?: TFieldAssetKeyTypes.TIrrigationZoneEmitterId
    block?: TFieldAssetKeyTypes.TBlockId
  }
}): Partial<TSelectedFieldAssetsStoreState> => {
  const irrigationZoneEmitter =
    args?.irrigationZoneEmitter ??
    getClosestIrrigationZoneEmitterInPropertyForAPoint({
      anchorCoordinates: anchorCoordsRaw,
      property,
    })

  const block =
    args?.block ?? getClosestBlockInPropertyForAPoint({ anchorCoordinates: anchorCoordsRaw, property })

  const { primaryValueGroup } = selectedValueGroupsStore.getState()

  let anchorCoordinates = anchorCoordsRaw

  if (args?.block && args.block) {
    const blockAnchorCoords = getAnchorCoordinatesForBlock(args.block, property)

    anchorCoordinates = blockAnchorCoords ?? anchorCoordinates
  }

  return {
    property: property.propertyId,
    block,
    lngLat: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: Object.values(property.points || []),
      block,
    }),
    irrigationZoneEmitter,
    weatherPoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllPointsForWeather(Object.values(property.points || {})),
      block,
    }),
    outOfBlockPoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllPointsForOOBWeather(Object.values(property.points || {})),
      block,
    }),
    soilPoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllLngLatsForSoil(Object.values(property.points || {})),
      block,
    }),
    fruitGrowthPoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllLngLatsForFruitGrowth(Object.values(property.points || {})),
      block,
    }),
    plantStressPoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllLngLatsForPlantStress(Object.values(property.points || {})),
      block,
    }),
    trapPoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllPointsForTraps(Object.values(property.points || {}), primaryValueGroup),
      block,
    }),
    pressureTransducerPoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllPointsForPressure(Object.values(property.points || {}), irrigationZoneEmitter),
    }),
    windMachinePoint: getClosestLngLatBasedFieldAssetInPropertyForAPoint({
      anchorCoordinates,
      fieldAssetValues: getAllPointsForWindMachine(Object.values(property.points ?? {})),
      block,
    }),
  }
}

const getAllPointsForWeather = (points: TFieldAssetValueTypes.TPoint[]) => {
  return points.filter((p) => inBlockWeatherSettings.some((v) => p.valuesTimeseries?.includes(v.id)))
}

const getAllPointsForOOBWeather = (points: TFieldAssetValueTypes.TPoint[]) => {
  return points.filter((p) => ooBWeatherSettings.some((v) => p.valuesTimeseries?.includes(v.id)))
}

const getAllLngLatsForSoil = (points: TFieldAssetValueTypes.TPoint[]) => {
  return points.filter((p) => soilSettings.some((v) => p.valuesTimeseries?.includes(v.id)))
}

const getAllLngLatsForFruitGrowth = (points: TFieldAssetValueTypes.TPoint[]) => {
  return points.filter((p) => fruitGrowthSettings.some((v) => p.valuesTimeseries?.includes(v.id)))
}

const getAllLngLatsForPlantStress = (points: TFieldAssetValueTypes.TPoint[]) => {
  return points.filter((p) => plantStressSettings.some((v) => p.valuesTimeseries?.includes(v.id)))
}

const getAllPointsForTraps = (points: TFieldAssetValueTypes.TPoint[], primaryValueGroup: string | null) => {
  const isInsectValueGroup = primaryValueGroup?.includes('insect_id')

  if (!isInsectValueGroup || !primaryValueGroup) {
    return points.filter((p) => p.configuration.trapInsectIds)
  }

  const [, insectId] = primaryValueGroup.split('insect_id_')

  return points.filter((p) => p.configuration.trapInsectIds?.includes(Number(insectId)))
}

const getAllPointsForPressure = (
  points: TFieldAssetValueTypes.TPoint[],
  irrigationZoneEmitterId?: TFieldAssetKeyTypes.TIrrigationZoneEmitterId | null,
) => {
  return points.filter(
    (p) =>
      p.configuration.irrigationEmitterTypesAvailable &&
      irrigationZoneEmitterId &&
      p.irrigationEmitterZoneIds?.includes(irrigationZoneEmitterId),
  )
}

const getAllPointsForWindMachine = (points: TFieldAssetValueTypes.TPoint[]) => {
  return points.filter((p) => windMachineSettings.some((v) => p.valuesTimeseries?.includes(v.id)))
}
