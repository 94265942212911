import { useMantineTheme } from '@mantine/core'

export const LoadingMessage = ({ loadingMessage }: { loadingMessage?: React.ReactNode }) => {
  const theme = useMantineTheme()

  return (
    <div
      css={{
        position: 'relative',
        display: 'block',
        padding: 0,
        margin: 0,
        border: `1px solid ${theme.colors.grey[2]}`,
        borderRadius: 4,
        maxHeight: '40vh',
        overflowY: 'auto',
      }}
    >
      <span
        css={{
          padding: 10,
          display: 'block',
          fontSize: 14,
          color: theme.colors.grey[2],
        }}
      >
        {loadingMessage}
      </span>
    </div>
  )
}
