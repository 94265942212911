import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconThunderstormsDay = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 363 369')}>
      <g clipPath="url(#clip0_11_619)">
        <g clipPath="url(#clip1_11_619)">
          <g clipPath="url(#clip2_11_619)">
            <path
              d="M98 138C120.091 138 138 120.091 138 98C138 75.9086 120.091 58 98 58C75.9086 58 58 75.9086 58 98C58 120.091 75.9086 138 98 138Z"
              fill="url(#paint0_linear_11_619)"
              stroke="#F8AF18"
              strokeWidth="4"
              strokeMiterlimit="10"
            />
            <path
              d="M98 31.38V6M98 190V164.62M145.11 50.89L163.05 33M33 163.05L50.94 145.11M50.94 50.89L33 33M163.1 163.1L145.16 145.16M6 98H31.38M190 98H164.62"
              stroke="#FBBF24"
              strokeWidth="12"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
          <g clipPath="url(#clip3_11_619)">
            <path
              d="M304 143C303.15 143 302.32 143.09 301.47 143.13C306.294 123.702 304.013 103.19 295.039 85.2967C286.065 67.4034 270.989 53.3083 252.534 45.5563C234.078 37.8044 213.459 36.9065 194.399 43.0248C175.339 49.1431 159.095 61.8744 148.6 78.9199C140.108 73.8427 130.42 71.11 120.526 71.0009C110.632 70.8918 100.887 73.4103 92.2846 78.2991C83.6824 83.1879 76.5316 90.272 71.5624 98.828C66.5932 107.384 63.9835 117.106 63.9999 127C64.0218 130.043 64.2893 133.08 64.7999 136.08C50.1521 138.897 37.0788 147.069 28.1298 159.003C19.1808 170.936 14.9977 185.776 16.3963 200.626C17.7949 215.477 24.675 229.274 35.6948 239.327C46.7145 249.379 61.0837 254.967 75.9999 255C77.3499 255 78.6699 254.89 79.9999 254.8V255H304C318.852 255 333.096 249.1 343.598 238.598C354.1 228.096 360 213.852 360 199C360 184.148 354.1 169.904 343.598 159.402C333.096 148.9 318.852 143 304 143Z"
              fill="url(#paint1_linear_11_619)"
              stroke="#CAD6E8"
              strokeWidth="6"
              strokeMiterlimit="10"
            />
          </g>
        </g>
        <g clipPath="url(#clip4_11_619)">
          <path
            d="M172 184L140 280H172L156 360L236.01 248H188L220.01 184H172Z"
            fill="url(#paint2_linear_11_619)"
            stroke="#F6A823"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_619"
          x1="78"
          y1="63.35"
          x2="118"
          y2="132.65"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBBF24" />
          <stop offset="0.45" stopColor="#FBBF24" />
          <stop offset="1" stopColor="#F59E0B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11_619"
          x1="112.45"
          y1="66.6799"
          x2="245.64"
          y2="297.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F3F7FE" />
          <stop offset="0.45" stopColor="#F3F7FE" />
          <stop offset="1" stopColor="#DEEAFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11_619"
          x1="145.9"
          y1="199.07"
          x2="218.11"
          y2="324.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7B23B" />
          <stop offset="0.45" stopColor="#F7B23B" />
          <stop offset="1" stopColor="#F59E0B" />
        </linearGradient>
        <clipPath id="clip0_11_619">
          <rect width="363" height="369" fill="white" />
        </clipPath>
        <clipPath id="clip1_11_619">
          <rect width="363" height="258" fill="white" />
        </clipPath>
        <clipPath id="clip2_11_619">
          <rect width="196" height="196" fill="white" />
        </clipPath>
        <clipPath id="clip3_11_619">
          <rect width="350" height="222" fill="white" transform="translate(13 36)" />
        </clipPath>
        <clipPath id="clip4_11_619">
          <rect width="102.66" height="186.75" fill="white" transform="translate(137.23 182)" />
        </clipPath>
      </defs>
    </svg>
  )
}
