import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconFeedback = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 20')}>
      <path
        d="M13.0504 16.9824L9.4133 17.8863C9.2049 17.9379 8.98396 17.8784 8.83195 17.7292C8.67975 17.5801 8.61883 17.3633 8.6712 17.1585L9.59587 13.5911C9.62296 13.4865 9.67843 13.3911 9.75626 13.3149L15.3891 7.78211V1.65828C15.388 1.2185 15.2094 0.796916 14.8927 0.486222C14.5757 0.175354 14.1462 0.000548525 13.6983 0H7.46309V3.7036C7.46218 4.46211 7.15504 5.18918 6.60894 5.72537C6.0627 6.26155 5.32219 6.56297 4.54993 6.56388H0V18.3398C0.000545547 18.7799 0.178751 19.2019 0.495875 19.5133C0.812832 19.8245 1.24253 19.9995 1.69078 20H13.698C14.1463 19.9995 14.576 19.8245 14.8929 19.5133C15.2101 19.2019 15.3883 18.7799 15.3888 18.3398V14.8049L13.3318 16.8248C13.2541 16.9014 13.1571 16.956 13.0504 16.9824ZM10.7388 14.045L10.1091 16.4757L12.5876 15.8597L19.0035 9.54523L17.1557 7.73057L10.7388 14.045ZM20.3993 7.10434L19.6405 6.35921C19.4937 6.22172 19.2988 6.14494 19.0959 6.14494C18.8931 6.14494 18.698 6.22172 18.5512 6.35921L18.0193 6.88205L19.8674 8.6967L20.3993 8.17387C20.5439 8.03209 20.625 7.83961 20.625 7.6391C20.625 7.43859 20.5439 7.2461 20.3993 7.10434Z"
        fill="currentColor"
      />
    </svg>
  )
}
