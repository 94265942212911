import { Capacitor } from '@capacitor/core'
import type { NfcTagScannedEvent, ScanSessionErrorEvent } from '@semios/capacitor-nfc'
import { Nfc, NfcUtils } from '@semios/capacitor-nfc'
import type { ScanResult } from 'components/QrCodeScanner/QrCodeScanner'
import { translate } from 'i18n/i18n'
import { useEffect } from 'react'
import { init } from 'stores/_utils/simple-store'

const store = init<ScannerProps>({ isScanning: false, scanResult: null })

interface ScannerProps {
  isScanning: boolean
  scanResult: ScanResult | null
}

const utils = new NfcUtils()

const startScan = async (callback: (result: ScanSessionErrorEvent | NfcTagScannedEvent | null) => void) => {
  await Nfc.addListener('nfcTagScanned', (result: NfcTagScannedEvent) => {
    callback(result)
  })

  await Nfc.addListener('scanSessionCanceled', () => {
    callback(null)
  })

  await Nfc.addListener('scanSessionError', (result: ScanSessionErrorEvent) => {
    callback(result)
  })

  await Nfc.startScanSession()
}

const stopScan = async () => {
  await Nfc.removeAllListeners()

  await Nfc.stopScanSession()
}

const scanSingleNfcTag = async () => {
  const res = await new Promise((resolve) => {
    startScan((result) => {
      resolve(result)
    })
  })

  await stopScan()

  //Canceled
  if (res === null) return null

  try {
    const payload = (res as NfcTagScannedEvent).nfcTag.message?.records[0].payload

    if (!payload) {
      return {
        value: undefined,
        error: translate.phrases.placeholder('No Nfc payload'),
      }
    }

    const formattedText = utils.convertBytesToString({ bytes: payload }).text.slice(3) //first 2 characters are the language code
    //Ref: https://stackoverflow.com/questions/59515271/why-android-nfc-reader-adds-en-before-the-message

    return {
      value: formattedText,
      error: (res as ScanSessionErrorEvent).message,
    }
  } catch (e) {
    return {
      value: undefined,
      error: (res as ScanSessionErrorEvent).message,
    }
  }
}

const isNfcSupported = async () => {
  try {
    const { isSupported } = await Nfc.isSupported()

    return isSupported
  } catch (e) {
    return false
  }
}

const openSettings = async () => {
  await Nfc.openSettings()
}

const checkPermissions = async () => {
  const { nfc } = await Nfc.checkPermissions()

  return nfc
}

const requestPermissions = async () => {
  const { nfc } = await Nfc.requestPermissions()

  return nfc
}

const isEnabled = async () => {
  if (Capacitor.getPlatform() !== 'android') {
    return true
  }

  const { isEnabled } = await Nfc.isEnabled()

  return isEnabled
}

const Scanner = () => {
  const onScan = (arg: ScanResult) => {
    store.setState((state) => ({ ...state, isScanning: false, scanResult: arg }))
  }

  const onError = async (reason: string) => {
    onScan({ status: 'error', value: reason })
  }

  const onCancel = async () => {
    try {
      await stopScan()
    } catch (e) {}

    onScan({ status: 'cancelled', value: '' })
  }

  useEffect(() => {
    const tryToScanSingleNfcTag = async () => {
      if (!(await isNfcSupported())) {
        onError(translate.phrases.placeholder('Not supported'))

        return
      }

      if ((await checkPermissions()) !== 'granted') {
        if ((await requestPermissions()) !== 'granted') {
          onError(translate.phrases.placeholder('Permission not granted'))

          return
        }
      }

      if ((await isEnabled()) === false) {
        const openSetting = confirm(
          translate.phrases.placeholder('NFC is disabled. Do you want to open settings to enable it?'),
        )

        if (openSetting) {
          openSettings()
        }

        onError(translate.phrases.placeholder('NFC not enabled'))

        return
      }

      const result = await scanSingleNfcTag()

      if (result === null) {
        onCancel()
      } else if (result.value) {
        onScan({ status: 'success', value: result.value })
      } else {
        onError(result.error)
      }
    }

    tryToScanSingleNfcTag()

    return () => {
      stopScan()
    }
  }, [])

  return <></>
}

const Overlay = () => {
  const settings = store.useSelector((settings) => settings)

  return settings.isScanning ? <Scanner /> : null
}

export const NfcScanner = {
  startScan: () => {
    store.setState(() => ({ isScanning: true, scanResult: null }))
  },
  stopScan: () => {
    store.setState(() => ({ isScanning: false, scanResult: { status: 'cancelled', value: '' } }))
  },
  useScanResult: () => {
    return store.useSelector((state) => state.scanResult)
  },
  useIsScanning: () => {
    return store.useSelector((state) => state.isScanning)
  },
  isSupported: isNfcSupported,
  Overlay,
}
