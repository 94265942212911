import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { memo } from 'react'
import { colors } from 'settings/colors'

const activeColor = colors.grey500
const inactiveColor = colors.midnight

const SortArrows = ({ sortOrder, isBeingSorted }: { sortOrder: number; isBeingSorted: boolean }) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        right: 8,
        top: 'calc(50% - 2px)',
      }}
    >
      <FontAwesomeIcon
        icon={faSortUp}
        css={{
          margin: '-6px 0px',
          color: isBeingSorted && sortOrder === 1 ? activeColor : inactiveColor,
          transition: '0.3s ease all',
        }}
      />
      <FontAwesomeIcon
        icon={faSortDown}
        css={{
          margin: '-6px 0px',
          color: isBeingSorted && sortOrder === -1 ? activeColor : inactiveColor,
          transition: '0.3s ease all',
        }}
      />
    </div>
  )
}

const MemoizedSortArrows = memo(SortArrows) as typeof SortArrows

export { MemoizedSortArrows as SortArrows }
