import { Indicator, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { isEmpty } from '@semios/app-platform-common'
import { IconChevron } from 'components/icons/IconChevron'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { Authorization } from '../../../../../components/Authorization/Authorization'
import { fieldAssetStore } from '../../../../../stores/fieldAssetStore'
import { DEVICE_TYPE_ICONS } from '../../../Map/_utils/deviceTypes'
import { getActiveNodeStatus, NODE_STATUS_COLORS } from '../../../Map/_utils/getActiveNodeStatus'
import { DEVICE_GROUPS_TYPES, getNodeDeviceTypes } from '../../../Map/_utils/getNodeDeviceTypes'
import type { TNodeDeviceType } from '../../../store/serviceCenterStore'
import type { TDeviceLog, TNodeDevicePort, TNodeDevices, TNodeType } from '../../../types'
import { TNodeStatus } from '../../../types'
import { renderNodeDeviceLabel } from '../../../utils/renderNodeDeviceLabel'

interface DevicesProps {
  nodeType: TNodeType
  configuredDevices: TNodeDevices
  deviceLogs: TDeviceLog[] | undefined
}

enum TNodeDeviceStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  CONFIGURED = 'configured',
  UNKNOWN = 'unknown',
}

export const DEVICE_STATUS_COLORS: { [key in TNodeDeviceStatus]: string } = {
  [TNodeDeviceStatus.ONLINE]: '#29CD6B',
  [TNodeDeviceStatus.OFFLINE]: '#EB4C4C',
  [TNodeDeviceStatus.CONFIGURED]: '#EB4C4C',
  [TNodeDeviceStatus.UNKNOWN]: '#EB4C4C',
}

export const DevicesLogs: React.FC<DevicesProps> = ({ nodeType, configuredDevices, deviceLogs }) => {
  const [opened, { toggle }] = useDisclosure(false)
  const theme = useMantineTheme()
  const allDevices = fieldAssetStore.useSelector((s) => s.devices)
  const nodeDeviceTypes = getNodeDeviceTypes(configuredDevices)
  const DEVICE_PORTS = ['rj11-0', 'stereo-0', 'stereo-1', 'stereo-2', 'sdi-0', 'sdi-1', 'sdi-2', 'sdi-3']
  const TWO_DAYS_AGO = moment.tz().subtract(2, 'days')

  const shapedNodeDevices = useMemo(() => {
    // Wait for devices list to be loaded
    if (isEmpty(allDevices)) return []

    return DEVICE_PORTS.map((port) => {
      const [connector, channel] = port.split('-')
      const configuredDevice = configuredDevices?.[connector as TNodeDevicePort]?.[+channel]
      const deviceLog = deviceLogs?.find((log) => log.connector === connector && log.channel === +channel)
      const logStatus = deviceLog ? getActiveNodeStatus({ stamp: deviceLog.stamp, nodeType }) : 'unknown'

      const deviceLogLastTwoDays = deviceLogs?.find(
        (listLog) =>
          listLog.connector === connector &&
          listLog.channel === +channel &&
          moment.tz(listLog.stamp, 'UTC') > TWO_DAYS_AGO,
      )

      let deviceStatus: TNodeDeviceStatus = TNodeDeviceStatus.UNKNOWN
      let infoText = ''
      let source: string | undefined
      let height

      if (!deviceLog && configuredDevice) {
        source = configuredDevice.source

        height = configuredDevice.height

        infoText = translate.phrases.placeholder(
          'Device is configured in the cloud, but has not reported yet',
        )

        deviceStatus = TNodeDeviceStatus.CONFIGURED
      } else if (deviceLogLastTwoDays && !configuredDevice) {
        source = deviceLogLastTwoDays.source

        infoText = translate.phrases.placeholder(
          "Device included in a log, but it's not configured in the cloud",
        )

        deviceStatus = TNodeDeviceStatus.UNKNOWN
      } else if (deviceLog && configuredDevice) {
        source = configuredDevice.source

        height = configuredDevice.height

        if (logStatus !== TNodeStatus.ACTIVE) {
          infoText = translate.phrases.placeholder(
            'Device is configured in the cloud, but has not reported since {{timeAgo}}',
            {
              timeAgo: moment.tz(deviceLog.stamp, 'UTC').fromNow(),
            },
          )

          deviceStatus = TNodeDeviceStatus.OFFLINE
        } else {
          deviceStatus = TNodeDeviceStatus.ONLINE
        }
      }

      const deviceType = source && DEVICE_GROUPS_TYPES[allDevices[source]?.deviceGroupName]

      return {
        port,
        source,
        height,
        deviceStatus,
        logStatus,
        logStamp: deviceLog?.stamp,
        deviceType,
        infoText,
      }
    }).filter(({ source }) => !!source)
  }, [configuredDevices, deviceLogs, nodeType, allDevices])

  return (
    <div
      css={{
        border: `1px solid ${theme.colors.grey[1]}`,
        borderRadius: 3,
        backgroundColor: theme.colors.grey[0],
        color: theme.colors.grey[3],
        marginTop: 10,
        marginBottom: 10,
        lineHeight: '20px',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '14px 20px',
        }}
        onClick={toggle}
      >
        <div css={{ display: 'flex' }}>
          <h4 css={{ fontSize: '14px', margin: 0, marginRight: 30 }}>
            {translate.phrases.placeholder(
              nodeDeviceTypes.length > 1 ? 'Linked Devices ({{count}})' : 'Linked Device ({{count}})',
              {
                count: nodeDeviceTypes.length.toString(),
              },
            )}
          </h4>

          <div css={{ display: 'flex' }}>
            {shapedNodeDevices.map(({ deviceType, port, deviceStatus }) => {
              const Icon = DEVICE_TYPE_ICONS[deviceType as TNodeDeviceType]

              return (
                <div key={port} css={{ marginRight: 10, fontSize: '20px' }}>
                  <Indicator
                    color={DEVICE_STATUS_COLORS[deviceStatus as TNodeDeviceStatus]}
                    withBorder
                    disabled={deviceStatus === TNodeDeviceStatus.ONLINE}
                  >
                    {!!Icon && <Icon />}
                  </Indicator>
                </div>
              )
            })}
          </div>
        </div>

        <div>
          <span
            css={{
              display: 'inline-block',
              marginLeft: 10,
              transform: opened ? 'rotate(180deg)' : undefined,
            }}
          >
            <IconChevron />
          </span>
        </div>
      </div>

      {opened && (
        <div css={{ padding: 10, borderTop: `1px solid ${theme.colors.grey[1]}`, fontSize: '12px' }}>
          <div>
            {shapedNodeDevices.map(
              ({ port, source, height, deviceStatus, logStatus, logStamp, infoText }) => (
                <div
                  key={port}
                  css={{
                    'marginLeft': 30,
                    'marginTop': -20,
                    'marginBottom': 10,
                    '&:last-child': { marginBottom: 0 },
                    '::before': {
                      content: '""',
                      height: 14,
                      width: 14,
                      backgroundColor: DEVICE_STATUS_COLORS[deviceStatus as TNodeDeviceStatus],
                      border: '2px solid white',
                      borderRadius: '50%',
                      display: 'inline-block',
                      position: 'relative',
                      top: 22,
                      left: -20,
                    },
                  }}
                >
                  <div css={{ fontWeight: 500 }}>
                    {renderNodeDeviceLabel(
                      {
                        source,
                        height,
                        port,
                      },
                      allDevices,
                    )}
                  </div>
                  <Authorization
                    requires={{ permission: 'VIEW_SSC_NODE_LAST_LOG', entity: 'FOR_ANY_ENTITY' }}
                  >
                    <div>
                      {translate.phrases.placeholder('Last Log:')}{' '}
                      <span css={{ color: NODE_STATUS_COLORS[logStatus as TNodeStatus], fontWeight: 500 }}>
                        {logStamp
                          ? moment.tz(logStamp, 'UTC').fromNow()
                          : translate.phrases.placeholder('Never')}
                      </span>
                      {infoText && <div css={{ color: theme.colors.grey[2] }}>{infoText}</div>}
                    </div>
                  </Authorization>
                </div>
              ),
            )}
          </div>
        </div>
      )}
    </div>
  )
}
