export const soilAWCHeatmapStops: [number, string][] = [
  [0, 'rgba(239, 4, 0, 1)'],
  [0.1875, 'rgba(255, 146, 144, 1)'],
  [0.3125, 'rgba(255, 146, 144, 1)'],
  [0.4375, 'rgba(255, 247, 146, 1)'],
  [0.5625, 'rgba(255, 247, 146, 1)'],
  [0.6875, 'rgba(77, 197, 77, 1)'],
  [0.8125, 'rgba(77, 197, 77, 1)'],
  [1, 'rgba(164, 233, 255, 1)'],
]
