import { sortByKey } from '@semios/app-platform-common'
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput'
import { useState } from 'react'
import type { Alert } from 'stores/userDetailsStore'
import { searchStringFromStringWithSpaces } from 'utils/searchStringFromStringWithSpaces'
import { mapAlertIdTypeToLabel } from '../mapAlertIdTypeToLabel'

export const useSearchAlerts = ({
  initialList,
  placeholder,
}: {
  initialList: Alert[]
  placeholder: string
}) => {
  const [searchText, setSearchText] = useState('')

  const filteredList = initialList.filter((item: Alert) => {
    return (
      searchStringFromStringWithSpaces(searchText, mapAlertIdTypeToLabel(item.type.id)) ||
      searchStringFromStringWithSpaces(searchText, item.name)
    )
  })

  const filteredAndSortedList = filteredList.sort(sortByKey('createdAt', -1))

  return {
    SearchInputComponent: (
      <div css={{ margin: '10px 0px' }}>
        <SearchTextInput
          placeholder={placeholder}
          searchText={searchText}
          setSearchText={setSearchText}
          clearable={true}
        />
      </div>
    ),
    filteredAndSortedList,
    isSearchMatch: filteredAndSortedList.length > 0,
  }
}
