import { css } from '@emotion/css'
import { renderToString } from 'react-dom/server'
import { EnumWeatherCondition } from 'utils/weather-conditions/types'
import { weatherConditionToIcon } from 'utils/weather-conditions/weatherConditionToIcon'
import { weatherConditionToLabel } from 'utils/weather-conditions/weatherConditionToLabel'

const cssWrapper = css({ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 6 })
const cssLabel = css({ fontSize: '0.25em', fontWeight: 'normal', textAlign: 'center' })
const cssWrapperComponentString = css({ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 6 })
const cssLabelComponentString = css({ fontSize: '0.5em', fontWeight: 'bold', textAlign: 'center' })
const cssImage = css({ height: '1em', width: '1em' })

export const WeatherCondition = (props: { weatherCondition: EnumWeatherCondition | null }) => {
  if (!props.weatherCondition) {
    return <div />
  }

  const Icon = weatherConditionToIcon(props.weatherCondition)
  const label = weatherConditionToLabel(props.weatherCondition)

  return (
    <div className={cssWrapper}>
      <Icon />
      <div className={cssLabel}>{label}</div>
    </div>
  )
}

export const weatherConditionToComponentString = (weatherCondition: string | null) => {
  if (!weatherCondition) return ''

  if (!Object.keys(EnumWeatherCondition).includes(weatherCondition)) return ''

  const Icon = weatherConditionToIcon(weatherCondition as EnumWeatherCondition)
  const iconAsBase64String = btoa(renderToString(<Icon />))
  const label = weatherConditionToLabel(weatherCondition as EnumWeatherCondition)

  return `
    <div class="${cssWrapperComponentString}">
      <img class="${cssImage}" src="data:image/svg+xml;charset=UTF-8;base64,${iconAsBase64String}" />
      <div class="${cssLabelComponentString}">${label}</div>
    </div>
  `
}
