import { IconFeedback } from 'components/icons/IconFeedback'
import { IconGear } from 'components/icons/IconGear'
import { IconHelp } from 'components/icons/IconHelp'
import { DataSourceSwitch } from 'components/SideNav/Preferences/DataSourceSwitch/DataSourceSwitch'
import { translate } from 'i18n/i18n'
import { SharedSettings } from 'settings/SharedSettings'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { checkAuthorization } from 'utils/checkAuthorization'
import { isUserOnlyAFreeRegionalUser } from 'utils/isUserOnlyAFreeRegionalUser'
import { HelpCenter } from '../HelpCenter/HelpCenter'
import { IconAndTitle } from '../IconAndTitle/IconAndTitle'
import { UserNameWithLogout } from './UserNameWithLogout/UserNameWithLogout'

export const Preferences = () => {
  const enoughDataLoadedToShowTheUsualMenu = userDetailsStore.useSelector((s) => s.id)

  return (
    <>
      {checkAuthorization({ permission: 'EDIT_SERVICE_CENTER', entity: '*' }) ? (
        <HelpCenter />
      ) : (
        <a
          css={{ color: 'unset', textDecoration: 'none' }}
          href={SharedSettings.HELP_CENTER_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconAndTitle icon={<IconHelp />} title={translate.phrases.banyanApp('Help')} />
        </a>
      )}
      <a
        css={{ color: 'unset', textDecoration: 'none' }}
        href={SharedSettings.SEND_FEEDBACK_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconAndTitle icon={<IconFeedback />} title={translate.phrases.banyanApp('Send Feedback')} />
      </a>
      {isUserOnlyAFreeRegionalUser() ? null : (
        <div
          css={{
            display: 'flex',
            height: 45,
            alignItems: 'center',
            marginLeft: -12,
          }}
        >
          <DataSourceSwitch />
        </div>
      )}

      {!!enoughDataLoadedToShowTheUsualMenu && (
        <>
          <IconAndTitle
            icon={<IconGear />}
            onClick={() => smallStore.setState((s) => ({ ...s, showSettingsModal: true }))}
            title={translate.phrases.banyanApp('Settings')}
          />
          <UserNameWithLogout />
        </>
      )}
    </>
  )
}
