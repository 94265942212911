import { Alert } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import type { ESprayRecommendation } from 'App/Map/types'
import { getSprayConditionsTitleAndColor } from 'App/Map/_utils/getSprayConditionsTitleAndColor'
import { IconInfo } from 'components/icons/IconInfo'
import { ModalDrawer } from 'components/ModalDrawer/ModalDrawer'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { TDropdownSelectorBlockProps } from '../DropdownSelectorBlock/DropdownSelectorBlock'
import { DropdownSelectorBlock } from '../DropdownSelectorBlock/DropdownSelectorBlock'
import type { TDropdownSelectorPropertyProps } from '../DropdownSelectorProperty/DropdownSelectorProperty'
import { DropdownSelectorProperty } from '../DropdownSelectorProperty/DropdownSelectorProperty'
import { SprayConditionRow } from './SprayConditionRow/SprayConditionRow'

// TODO: these types should come from value-type definitions after the big refactor
type TEnvironmentalConsiderationsItemArrayElement =
  | { min: number; max: number }
  | { min: null; max: number }
  | { min: number; max: null }

export type TEnvironmentalConsiderations =
  | [TEnvironmentalConsiderationsItemArrayElement, ...TEnvironmentalConsiderationsItemArrayElement[]]
  | [{ min: null; max: null }]

export type TPossibleEnvironmentalMeasurementType =
  | 'humidityAverage'
  | 'precipitation'
  | 'temperatureAverage'
  | 'windSpeedAverage'

type TEnvironmentalConsiderationsDictionary = Partial<
  Record<TPossibleEnvironmentalMeasurementType, TEnvironmentalConsiderations>
>

export type TSprayConditionsRulesForARegion = Record<
  ESprayRecommendation,
  TEnvironmentalConsiderationsDictionary
>

const modalWidth = 650

export const SprayConditionsTitleChildren = (
  props: (TDropdownSelectorBlockProps | TDropdownSelectorPropertyProps) & {
    propertiesXorBlocks: 'PROPERTIES' | 'BLOCKS'
    sprayConditionsRules?: TSprayConditionsRulesForARegion
  },
) => {
  const [opened, { close, open }] = useDisclosure(false)
  const propertyId = selectedFieldAssetsStore.useSelector((s) => s.property)

  const propertyName = fieldAssetStore.useSelector(
    (s) => s.properties?.[Number(propertyId)]?.propertyName ?? '',
  )

  const conditionsLabelsAndColors = {
    IDEAL: getSprayConditionsTitleAndColor('IDEAL' as ESprayRecommendation),
    AVOID: getSprayConditionsTitleAndColor('AVOID' as ESprayRecommendation),
    DO_NOT: getSprayConditionsTitleAndColor('DO_NOT' as ESprayRecommendation),
  }

  return (
    <>
      {!!props.sprayConditionsRules && (
        <>
          <ModalDrawer
            size={modalWidth}
            opened={opened}
            onClose={close}
            title={translate.phrases.templates('{{label}}: {{value}}', {
              label: translate.phrases.banyanApp('Spray Conditions'),
              value: propertyName,
            })}
            zIndex={SharedSettings.DEFAULT_MODAL_DRAWER_Z_INDEX}
          >
            <div css={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontWeight: 'normal',
                  padding: 12,
                  maxWidth: modalWidth,
                }}
              >
                <SprayConditionRow
                  backgroundColor={conditionsLabelsAndColors.IDEAL.color}
                  rules={props.sprayConditionsRules.IDEAL}
                  title={conditionsLabelsAndColors.IDEAL.name}
                />
                <div css={{ height: 18 }} />
                <SprayConditionRow
                  backgroundColor={conditionsLabelsAndColors.AVOID.color}
                  rules={props.sprayConditionsRules.AVOID}
                  title={conditionsLabelsAndColors.AVOID.name}
                />
                <div css={{ height: 18 }} />
                <SprayConditionRow
                  backgroundColor={conditionsLabelsAndColors.DO_NOT.color}
                  rules={props.sprayConditionsRules.DO_NOT}
                  title={conditionsLabelsAndColors.DO_NOT.name}
                />
                <Alert css={{ backgroundColor: colors.lightYellow, marginTop: 12 }}>
                  {translate.phrases.banyanApp(
                    'Always read and follow label directions to minimize risk of exposure. The spray forecast reflects forecast conditions. Always check current conditions to minimize risk of exposure.',
                  )}
                </Alert>
              </div>
            </div>
          </ModalDrawer>
          <div css={{ color: colors.grey800, cursor: 'pointer', fontSize: 14 }} onClick={open}>
            <IconInfo />
          </div>
        </>
      )}
      {props.propertiesXorBlocks === 'BLOCKS' ? (
        <DropdownSelectorBlock {...props} />
      ) : (
        <DropdownSelectorProperty {...props} />
      )}
    </>
  )
}
