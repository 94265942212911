import type { Decoder, DtoConstructor, Encoder } from './types'

const SemiosBleServiceUUID = '85fa0001-75e7-4361-b35f-9074c1ad985c'
const ReadTimeout = 60000
const WriteTimeout = 60000
const ScanningTimeout = 60000
const ConnectingTimeout = 60000
const writeAndNotifyTimeout = 60000
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DecoderMap = new Map<DtoConstructor, Decoder<any>>()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EncoderMap = new Map<DtoConstructor, Encoder<any>>()

enum SensorTypeIds {
  SDI_DECAGON_VP4 = 0x01,
  SDI_SENTEK_S60 = 0x03,
  SDI_SENTEK_DEVICE_ID_S30 = 0x09,
  SDI_SENTEK_DEVICE_ID_S90 = 0x0a,
  SDI_SENTEK_DEVICE_ID_S120 = 0x0b,
  UART_DAVIS_VP2 = 0x02,
  ANALOG_ADC = 0x04,
  ONBOARD_HUMIDITY = 0x05,
  ONBOARD_TEMPERATURE = 0x06,
  ONBOARD_PRESSURE = 0x07,
  ONBOARD_ACCELERATION = 0x08,
  UNKNOWN = 0xff,
}

enum SensorCommandStatus {
  OK = 0,
  BUSY = 2,
  NO_SENSOR_DETECTED = 3,
  INVALID = -1,
}

//hard coded mapping of sensor type in BLE to sensor type in API
const SensorTypeMapping = new Map<number, string>([
  [SensorTypeIds.SDI_DECAGON_VP4, 'DECAGON_VP4'],
  [SensorTypeIds.SDI_SENTEK_S60, 'SENTEK_DDT'],
  [SensorTypeIds.SDI_SENTEK_DEVICE_ID_S30, 'SENTEK_DDT'],
  [SensorTypeIds.SDI_SENTEK_DEVICE_ID_S90, 'SENTEK_DDT'],
  [SensorTypeIds.SDI_SENTEK_DEVICE_ID_S120, 'SENTEK_DDT'],
  [SensorTypeIds.UART_DAVIS_VP2, 'DAVIS_VP2'],
  [SensorTypeIds.UNKNOWN, 'UNKNOWN'],
])

export {
  SemiosBleServiceUUID,
  ReadTimeout,
  WriteTimeout,
  writeAndNotifyTimeout,
  ScanningTimeout,
  ConnectingTimeout,
  DecoderMap,
  EncoderMap,
  SensorTypeMapping,
  SensorTypeIds,
  SensorCommandStatus,
}
