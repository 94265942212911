import { makeBaseSvgIconProps } from './_utils/makeBaseSvgIconProps'

export const IconTorchOff = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 23')}>
      <path
        d="M17.5029 9.2H13.2905L15.951 11.822L17.5029 9.2ZM17.5029 0H5.83431V1.8515L12.9872 8.901L17.5029 0ZM1.64527 0.989L0 2.6105L5.83431 8.3605V12.65H9.33489V23L13.5123 15.9275L18.3547 20.7L20 19.0785L1.64527 0.989Z"
        fill="currentColor"
      />
    </svg>
  )
}
