import { Capacitor } from '@capacitor/core'
import type { TextInputProps } from '@mantine/core'
import { Group, Stack, TextInput } from '@mantine/core'
import { useScannerSupportCheck } from 'App/ServiceCenter/utils/hooks/useScannerSupportCheck'
import { Button } from 'components/Button/Button'
import { IconNfcScan } from 'components/icons/IconNfcScan'
import { IconQrCodeScan } from 'components/icons/IconQrCodeScan'
import { NfcScanner } from 'components/NfcScanner/NfcScanner'
import type { ScanResult } from 'components/QrCodeScanner/QrCodeScanner'
import { QrCodeScanner } from 'components/QrCodeScanner/QrCodeScanner'
import { translate } from 'i18n/i18n'
import { forwardRef, useEffect, useState } from 'react'

const NodeIdentifierField = forwardRef<
  HTMLInputElement,
  TextInputProps & React.RefAttributes<HTMLInputElement>
>((props, ref) => {
  const scannerSupport = useScannerSupportCheck()
  const qrScanResult: ScanResult | null = QrCodeScanner.useScanResult()
  const nfcScanResult: ScanResult | null = NfcScanner.useScanResult()
  const nfcIsScanning = NfcScanner.useIsScanning()
  const { error, ...restProps } = props
  const [scanError, setScanError] = useState<string>()

  const handleScanResult = (result: ScanResult | null) => {
    if (!result) {
      return
    }

    if (props.onChange) {
      if (result.status === 'success') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props.onChange({ target: { value: result.value } } as any) //Simulate event
      } else if (result.status === 'error') {
        setScanError(result.value)
      }
    }
  }

  useEffect(() => {
    handleScanResult(qrScanResult)
  }, [qrScanResult])

  useEffect(() => {
    handleScanResult(nfcScanResult)
  }, [nfcScanResult])

  useEffect(() => {
    setScanError(undefined) // Clear Scan error on value change
  }, [props.value])

  useEffect(() => {
    return () => {
      NfcScanner.stopScan()
    }
  }, [])

  return (
    <Stack>
      <TextInput
        ref={ref}
        {...restProps}
        onFocus={(e) => {
          setScanError(undefined) // Clear Scan error on focus

          props.onFocus && props.onFocus(e)
        }}
        error={error || scanError}
      />
      {
        // remove this line when merging https://github.com/semiosBIO/app-platform/pull/662
        Capacitor.getPlatform() !== 'web' && (
          <Group grow>
            {scannerSupport.QR.supported && (
              <>
                <Button
                  variant="tertiary"
                  onClick={() => {
                    setScanError(undefined) // Clear Scan error on focus

                    QrCodeScanner.startScan()
                  }}
                  rightIcon={<IconQrCodeScan />}
                >
                  {translate.phrases.placeholder('Scan QR code')}
                </Button>
              </>
            )}
            {scannerSupport.NFC.supported && (
              <Button
                variant="tertiary"
                onClick={() => {
                  setScanError(undefined) // Clear Scan error on focus

                  return !nfcIsScanning ? NfcScanner.startScan() : NfcScanner.stopScan()
                }}
                rightIcon={<IconNfcScan />}
              >
                {nfcIsScanning
                  ? translate.phrases.placeholder('Scanning...')
                  : translate.phrases.placeholder('Scan NFC')}
              </Button>
            )}
          </Group>
        )
      }
    </Stack>
  )
})

NodeIdentifierField.displayName = 'NodeIdentifierField'

export { NodeIdentifierField }
