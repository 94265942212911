import { BleClient } from '@capacitor-community/bluetooth-le'
import { Text } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { useBleManager } from 'App/ServiceCenter/BluetoothLowEnergy/BleManager'
import { RequestUplinkRebootMessage } from 'App/ServiceCenter/BluetoothLowEnergy/models/LnrNode/commands'
import { AdapterStatus } from 'App/ServiceCenter/BluetoothLowEnergy/types'
import { AboveAllModal } from 'components/AboveAllModalOverlay/AboveAllModalOverlay'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { memo, useEffect, useRef, useState } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { isSemiosEmployeeOrTester } from 'utils/isSemiosEmployeeOrTester'

const BleDeviceConnectionPlugin = ({ nodeIdentifier: nodeId }: { nodeIdentifier?: string }) => {
  const isEmployeeOrTester = userDetailsStore.useSelector((s) => isSemiosEmployeeOrTester(s))
  const bleManager = useBleManager()
  const bleStatus = useRef<AdapterStatus>(bleManager.adapterStatus)
  const bleDeviceId = useRef<string | undefined>(bleManager.connectedDevice?.deviceId)
  const [shouldConnected, setShouldConnected] = useState<boolean>(false) //BLE connection keep alive

  bleStatus.current = bleManager.adapterStatus

  bleDeviceId.current = bleManager.connectedDevice?.deviceId

  const cleanup = async (status: AdapterStatus, deviceId?: string) => {
    try {
      if (status === AdapterStatus.SCANNING) {
        await bleManager.stopScan()
      } else if (status === AdapterStatus.CONNECTING) {
        await bleManager.deferDisconnect()
      } else if (status === AdapterStatus.CONNECTED && deviceId) {
        await bleManager.disconnectDevice(deviceId)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error: ', e)

      Sentry.captureException(e)
    }
  }

  useEffect(() => {
    if (nodeId) {
      if (shouldConnected) {
        if (bleManager.adapterStatus === AdapterStatus.DISABLED) {
          AboveAllModal.open({
            modalId: 'ble-disabled',
            title: translate.phrases.placeholder('Enable Bluetooth'),
            centered: true,
            withCloseButton: false,
            children: (
              <>
                <Text size="sm">
                  {translate.phrases.placeholder(
                    'This device requires a bluetooth connection in order to proceed. Please be sure to enable bluetooth in your mobile device settings.',
                  )}
                </Text>
                <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      BleClient.openBluetoothSettings()
                    }}
                    css={{ width: '100%', margin: 10, height: 40 }}
                  >
                    {translate.phrases.placeholder('Go To Settings')}
                  </Button>
                </div>
              </>
            ),
          })
        } else if (bleManager.adapterStatus === AdapterStatus.IDLE) {
          AboveAllModal.open({
            modalId: 'reconnect',
            title: translate.phrases.placeholder('Bluetooth Connection Failed'),
            centered: true,
            withCloseButton: false,
            children: (
              <>
                <Text size="sm">
                  {translate.phrases.placeholder(
                    'Bluetooth Failed to connect. Please make sure that you have bluetooth turned on in your mobile device. ',
                  )}
                </Text>
                <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      setShouldConnected(false)

                      AboveAllModal.close('reconnect')
                    }}
                    css={{ width: '100%', margin: 10 }}
                  >
                    {translate.phrases.placeholder('Cancel')}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      bleManager.connectDeviceByNodeId(nodeId)
                    }}
                    css={{ width: '100%', margin: 10 }}
                  >
                    {translate.phrases.placeholder('Try Again')}
                  </Button>
                </div>
              </>
            ),
          })
        } else {
          AboveAllModal.close('reconnect')
        }
      } else {
        AboveAllModal.close('reconnect')
      }
    }
  }, [shouldConnected, nodeId, bleManager.adapterStatus])

  useEffect(() => {
    if (nodeId) {
      bleManager.connectDeviceByNodeId(nodeId)
    }
  }, [nodeId])

  useEffect(() => {
    if (bleManager.connectedDevice) {
      bleManager.connectedDevice.write(new RequestUplinkRebootMessage())
    }
  }, [bleManager.connectedDevice?.deviceId])

  useEffect(() => {
    //if adapter is trying to connect, we should keep trying to connect
    if (bleManager.adapterStatus > AdapterStatus.IDLE) {
      setShouldConnected(true)
    }
  }, [bleManager.adapterStatus])

  useEffect(() => {
    return () => {
      const status = bleStatus.current
      const deviceId = bleDeviceId.current

      //We want to cleanup the connection based on current status, that's why we use ref
      cleanup(status, deviceId)
    }
  }, [nodeId])

  return (
    <>
      {isEmployeeOrTester && (
        <div
          css={{
            position: 'absolute',
            opacity: 0.6,
            backgroundColor: '#fff',
            zIndex: 10,
            top: 'env(safe-area-inset-top)',
            right: 60,
            width: 150,
          }}
        >
          <Text size={'xs'}>{'node ID: ' + nodeId}</Text>
          <Text size={'xs'}>{'device ID: ' + bleDeviceId.current}</Text>
          <Text size={'xs'}>{'adapter status:' + AdapterStatus[bleManager.adapterStatus]}</Text>
        </div>
      )}
    </>
  )
}

export const BleDeviceConnectionKeeper = memo(BleDeviceConnectionPlugin)
