import { Tooltip } from '@mantine/core'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import type { TRole } from 'App/Map/Admin/utils/useAdminEntites'
import { HierarchicalSelection } from 'components/HierarchicalSelection/HierarchicalSelection'
import { IconInfoLight } from 'components/icons/IconInfoLight'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'

const rowWrapperStyle = {
  alignItems: 'center',
  border: '1px solid rgb(233, 233, 233)',
  display: 'flex',
  padding: '4px 10px',
  transition: '0.15s ease all',
}

const selectedRowStyle = {
  'border': `3px solid ${colors.blue600}`,
  'background': colors.blue300,
  'color': colors.black,
  '&:hover': {
    background: colors.grey500,
  },
}

const regularRowStyle = {
  '&:hover': {
    background: colors.subtleBackground,
  },
}

const RoleRow = ({
  role,
  count,
  isSelected,
  setIsSelected,
}: {
  role: TRole
  count: number
  isSelected: boolean
  setIsSelected: (isSelected: boolean) => void
}) => {
  const extraRowStyle = isSelected ? selectedRowStyle : regularRowStyle

  return (
    <div
      css={{
        ...rowWrapperStyle,
        ...extraRowStyle,
        cursor: 'pointer',
      }}
      onClick={() => setIsSelected(!isSelected)}
    >
      <div css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <Tooltip label={role.description} withArrow position={'right'}>
          <span css={{ cursor: 'help', paddingRight: '5px' }}>
            <IconInfoLight />
          </span>
        </Tooltip>
        {count ? (
          <span>
            <strong>{`(${count}) ${role.name}`}</strong>
          </span>
        ) : (
          role.name
        )}
      </div>
    </div>
  )
}

export const RolesPicker = ({
  roles: rolesArg,
  selectedRole,
  roleIdToCountOfEntities,
  setSelectedRole,
  width,
}: {
  roles: TRole[]
  width: string
  selectedRole: TRole['id'] | null
  roleIdToCountOfEntities: Record<string, number>
  setSelectedRole: (role: TRole['id'] | null) => void
}) => {
  const [searchString, setSearchString] = useState<string | undefined>()
  const [roles, setRoles] = useState<TRole[]>(rolesArg)

  useEffect(() => {
    const searchedRoles = arrayOfObjectsSearch(rolesArg, searchString ?? '', ['name'])

    setRoles(searchedRoles)
  }, [searchString])

  return (
    <HierarchicalSelection
      width={width}
      searchString={searchString ?? ''}
      setSearchString={setSearchString}
      title={translate.phrases.banyanApp('Roles')}
      placeholder={translate.phrases.banyanApp('Search for a role')}
    >
      {roles.map((role) => (
        <RoleRow
          key={role.id}
          role={role}
          count={roleIdToCountOfEntities[role.id]}
          isSelected={role.id === selectedRole}
          setIsSelected={(isSelected) => setSelectedRole(isSelected ? role.id : null)}
        />
      ))}
    </HierarchicalSelection>
  )
}
