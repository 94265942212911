import { Button } from 'components/Button/Button'
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal'
import type { RootZoneDepthsConfirmationModalProps } from 'components/RootZoneDepthsModal/types'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'

export const RootZoneDepthsConfirmationModal = ({
  setConfirmModalOpened,
  confirmModalOpened,
  resetState,
  updateStationsData,
  savingDisabled,
}: RootZoneDepthsConfirmationModalProps) => {
  return (
    <ConfirmationModal
      title={translate.phrases.banyanApp('Unsaved Changes')}
      content={
        <div style={{ marginBottom: '30px', marginLeft: '15px', marginRight: '15px' }}>
          {translate.phrases.banyanApp("Any changes you have made won't be saved.")}
          <br />
          {translate.phrases.banyanApp('Select an option below.')}
        </div>
      }
      modalWindowSize={480}
      buttonContent={
        <div
          style={{
            color: colors.midnight,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant={'negative'}
            style={{ gridColumn: 1, gridRow: 1, marginRight: 2, width: 160, color: colors.midnight }}
            onClick={() => {
              setConfirmModalOpened(false)

              detailsPanelStore.setState((prev) => {
                return {
                  ...prev,
                  soilSettingsOpened: false,
                }
              })

              resetState()
            }}
          >
            {translate.phrases.banyanApp('Discard Changes')}
          </Button>
          <Button
            variant={'primary'}
            disabled={savingDisabled}
            css={{
              gridColumn: 2,
              gridRow: 1,
              width: 160,
              justifySelf: 'end',
            }}
            onClick={() => {
              updateStationsData()

              setConfirmModalOpened(false)

              detailsPanelStore.setState((prev) => {
                return {
                  ...prev,
                  soilSettingsOpened: false,
                }
              })
            }}
          >
            {translate.phrases.banyanApp('Save Changes')}
          </Button>
        </div>
      }
      modalOpened={confirmModalOpened}
      closeModal={() => {
        setConfirmModalOpened(false)
      }}
    />
  )
}
