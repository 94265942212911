/**
 * this function generates a standardized list of tags that will
 * wrap certain elements of our line chart tooltip in order for
 * us to access or mutate inner bits of the tooltip, e.g. to insert
 * weather images for conditions or to conditionally remove a suffix.
 */
const tags = {
  valueSuffix: 'valueSuffix',
  value: 'value',
  valueMin: 'valueMin',
  valueAverage: 'valueAverage',
  valueMax: 'valueMax',
  compareSeasonsValueSuffix: 'compareSeasonsValueSuffix',
  compareSeasonsValue: 'compareSeasonsValue',
  compareSeasonsValueMin: 'compareSeasonsValueMin',
  compareSeasonsValueAverage: 'compareSeasonsValueAverage',
  compareSeasonsValueMax: 'compareSeasonsValueMax',
} as const

export type TTooltipContentsHelperTag = keyof typeof tags

type THelperTagsMakerReturn = Record<TTooltipContentsHelperTag, { opening: string; closing: string }>

export const helperTagsMaker = ({ seriesId }: { seriesId: string }) => {
  return Object.keys(tags).reduce((a: Partial<THelperTagsMakerReturn>, b) => {
    a[b as TTooltipContentsHelperTag] = {
      opening: `<span id="${seriesId}:${b}Opening"></span>`,
      closing: `<span id=${seriesId}:${b}Closing></span>`,
    }

    return a
  }, {}) as THelperTagsMakerReturn
}
