import { Accordion, Card } from '@mantine/core'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { sortByKey } from '@semios/app-platform-common'
import { translate } from 'i18n/i18n'
import { keyBy } from 'lodash'
import type { TAdminProperty } from '../../../utils/useAdminEntites'

export const EffectivePermissions = ({
  adminUserResponse,
  properties,
}: {
  adminUserResponse: routes.AdminGetUserWithPermissions.Response['data']
  properties: TAdminProperty[]
}) => {
  const effectivePermissions = (adminUserResponse && adminUserResponse[0]?.effectivePermissions) || []
  const propertiesById = keyBy(properties, 'id')

  type TEffectivePermissionsByProperty = {
    [propertyId: string]: {
      propertyName: TAdminProperty['name']
      propertyId: TAdminProperty['id']
      permissions: {
        permissionId: string
        description: string
      }[]
    }
  }

  const effectivePermissionsGroupedByProperty = effectivePermissions.reduce((all, effectivePermission) => {
    const { permissionId, description, entityIds, permissionType } = effectivePermission

    if (permissionType === 'PROPERTY') {
      entityIds.forEach((entityId) => {
        const property = propertiesById[entityId]

        if (property) {
          if (!all[entityId]) {
            all[entityId] = {
              propertyId: property.id,
              propertyName: property.name,
              permissions: [],
            }
          }

          all[entityId].permissions.push({ permissionId, description })
        }
      })
    }

    return all
  }, {} as TEffectivePermissionsByProperty)

  const sortedPropertiesWithPermissions = Object.values(effectivePermissionsGroupedByProperty).sort(
    sortByKey('propertyName'),
  )

  return (
    <Card css={{ marginTop: 24 }}>
      <h3>{translate.phrases.banyanApp('Effective Permissions Currently Attached to User')}</h3>
      <Card.Section css={{ marginLeft: '20px' }}>
        {effectivePermissions && (
          <div>
            <span>
              {translate.phrases.banyanApp('System Permissions ({{count}})', {
                count: effectivePermissions.filter((permission) => permission.permissionType === 'SYSTEM')
                  .length,
              })}
            </span>
            <br />
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              <ul>
                {effectivePermissions
                  .filter((permission) => permission.permissionType === 'SYSTEM')
                  .sort()
                  .map(({ permissionId, description }) => (
                    <li key={permissionId}>
                      <span css={{ fontWeight: 'bold' }}>{permissionId}</span>
                      {' - '}
                      {description}
                    </li>
                  ))}
              </ul>
            </div>
            <br />
            <br />
          </div>
        )}
      </Card.Section>
      <Card.Section css={{ marginLeft: '20px' }}>
        {effectivePermissions && properties && (
          <div>
            <span>
              {translate.phrases.banyanApp('Property Permissions ({{count}})', {
                count: Object.keys(effectivePermissionsGroupedByProperty).length,
              })}
            </span>
            <br />
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              <Accordion>
                {sortedPropertiesWithPermissions.map(({ propertyId, propertyName, permissions }) => {
                  return (
                    <Accordion.Item key={propertyId} value={propertyName}>
                      <Accordion.Control>{`${propertyName} (${
                        Object.values(permissions).length
                      })`}</Accordion.Control>
                      <Accordion.Panel>
                        {permissions.map(({ permissionId, description }) => (
                          <div key={permissionId}>
                            <span css={{ fontWeight: 'bold' }}>{permissionId}</span>
                            {' - '}
                            {String(description)}
                          </div>
                        ))}
                      </Accordion.Panel>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>
            <br />
            <br />
          </div>
        )}
      </Card.Section>
      {effectivePermissions.length === 0 && <h4>{translate.phrases.banyanApp('No Permissions')}</h4>}
    </Card>
  )
}
