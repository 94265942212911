import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconPencil = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 10 10')}>
      <path
        d="M8.08705 4.11851L5.88149 1.91295L0 7.79444V10H2.20556L8.08705 4.11851ZM9.82797 2.37759C10.0573 2.14821 10.0573 1.77768 9.82797 1.5483L8.4517 0.172034C8.22232 -0.0573445 7.85179 -0.0573445 7.62241 0.172034L6.46964 1.32481L8.6752 3.53036L9.82797 2.37759Z"
        fill="currentColor"
      />
    </svg>
  )
}
