import { useViewportSize as useViewportSizeMantine } from '@mantine/hooks'

/**
 * Mantine's useViewportSize returns { width: 0, height: 0 } from its
 * initial state, which can cause some rendering bugs. Mantine's hook
 * is otherwise pretty good, so if we're getting the dimensions from
 * Mantine as width: 0, height: 0, let's instead return our window's
 * dimensions
 */
export const useViewportSizeWithCorrectInitialState = () => {
  const viewportSizeMantine = useViewportSizeMantine()

  return {
    width: viewportSizeMantine.width || window.innerWidth || 0,
    height: viewportSizeMantine.height || window.innerHeight || 0,
  }
}
