import { ActionIcon } from '@mantine/core'
import { colors } from 'settings/colors'
import type { TSelectedTab } from 'stores/detailsPanelStore'
import { useScreenOrientation } from 'stores/smallStore'
import { useScreenSize } from 'utils/useScreenSize'

const BORDER_RADIUS = 3

export const TabSelectionButton = (props: {
  width: number
  height: number
  handleClick: (value: TSelectedTab) => void
  label: string
  value: TSelectedTab
  icon: React.ReactNode
  placement: 'right' | 'bottom'
  selected?: boolean
}) => {
  const color = props.selected ? colors.midnight : colors.white
  const { isWideScreen } = useScreenSize()
  const screenOrientation = useScreenOrientation()
  const largeSize = screenOrientation === 'landscape' && isWideScreen

  return (
    <ActionIcon
      css={{
        'width': props.width,
        'height': props.height,
        'backgroundColor': props.selected ? colors.white : 'transparent',
        'borderBottomLeftRadius': props.placement === 'right' ? BORDER_RADIUS : 0,
        'borderBottomRightRadius': 0,
        'borderTopRightRadius': props.placement === 'bottom' ? BORDER_RADIUS : 0,
        'borderTopLeftRadius': BORDER_RADIUS,
        ':hover': {
          backgroundColor: props.selected ? colors.white : colors.grey800,
        },
        'padding': 6,
      }}
      onClick={() => props.handleClick(props.value)}
    >
      <div
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div css={{ color }}>{props.icon}</div>
        <div
          css={{
            fontSize: largeSize ? 16 : 12,
            marginTop: largeSize ? 6 : 2,
            color,
          }}
        >
          {props.label}
        </div>
      </div>
    </ActionIcon>
  )
}
