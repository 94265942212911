import { colors } from 'settings/colors'

export const TrapMapIcon = ({
  fillColor = colors.midnight,
  fontColor = colors.white,
  innerText,
  strokeColor,
  onClick,
}: {
  fillColor?: string
  fontColor?: string
  innerText?: string
  strokeColor?: string
  onClick?: () => void
  width?: number
}) => {
  const fontSize = 18 - 2 * (String(innerText ?? '').length - 1)

  return (
    <svg
      width={44}
      height={39}
      viewBox="0 0 44 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M21.2509 5.85474L9.17774 14.4929C8.95517 14.6522 8.7887 14.8779 8.70221 15.1378L1.86232 35.6921C1.583 36.5314 2.20673 37.3983 3.09004 37.3983H40.9013C41.8048 37.3983 42.4303 36.4942 42.113 35.6468L34.415 15.0845C34.3303 14.858 34.1839 14.6597 33.9924 14.5122L22.792 5.8821C22.3404 5.53406 21.7146 5.52295 21.2509 5.85474Z"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="2"
      />
      <text css={{ textAnchor: 'middle', fontWeight: 700, fontSize, fill: fontColor }} x="50%" y="78%">
        {innerText}
      </text>
    </svg>
  )
}
