import type { EWindMachineStatus } from 'App/Map/types'
import { translate } from 'i18n/i18n'

export const StatusTile = ({ status }: { status: EWindMachineStatus }) => {
  let tileColor = 'rgba(11, 20, 36, 1)'

  if (status === 'ON') {
    tileColor = 'rgba(41, 205, 107, 1)'
  } else if (status === 'OFF') {
    tileColor = 'rgba(11, 20, 36, 1)'
  } else if (status === 'WARM UP') {
    tileColor = 'rgba(0, 122, 49, 1)'
  } else if (status === 'ERROR') {
    tileColor = 'rgba(235, 76, 76, 1)'
  } else if (status === 'WARNING') {
    tileColor = 'rgba(255, 171, 0, 1)'
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '2px 10px 2px 10px',
          background: tileColor,
          borderRadius: '16px',
          position: 'relative',
        }}
      >
        <p
          css={{
            color: 'white',
            margin: '0px',
          }}
        >
          {status ? status : translate.phrases.placeholder('N/A')}
        </p>
      </div>
    </div>
  )
}
