/* eslint-disable no-restricted-syntax */
import { getWindMachineActivityTitleAndColor } from 'App/Map/_utils/getWindMachineActivityTitleAndColor'
import type { XrangePointOptionsObject } from 'highcharts'
import { isNil } from 'lodash'
import moment from 'moment-timezone'

const statusMapper = (status: string) => {
  return {
    ...getWindMachineActivityTitleAndColor(status),
    y: 1,
    pointWidth: status === 'OFF' ? 10 : 24,
  }
}

export const makeIntervals = ({
  timeseries,
}: {
  timeseries?: { timestamp: string; value: { start: string; end: string; value: string } }[]
}): (XrangePointOptionsObject & { value: string })[] => {
  if (isNil(timeseries) || !timeseries.length) return []

  const intervals: (XrangePointOptionsObject & { value: string; pointWidth: number })[] = []

  timeseries
    .sort(
      (a: { value: { start: string } }, b: { value: { start: string } }) =>
        parseInt(a.value.start) - parseInt(b.value.start),
    )
    .forEach((ts) => {
      const start = moment.tz(ts.value.start, moment.tz.guess()).format('x')
      const stop = moment.tz(ts.value.end, moment.tz.guess()).format('x')

      const newItem: typeof intervals[number] = {
        value: ts.value.value,
        x: parseFloat(start) + 1,
        x2: parseFloat(stop),
        ...statusMapper(ts.value.value),
      }

      intervals.push(newItem)
    })

  return intervals
}
