import { Input, Switch } from '@mantine/core'
import { IconSearch } from 'components/icons/IconSearch'
import { translate } from 'i18n/i18n'

type THierarchicalSelectionProps = {
  children: React.ReactNode
  height?: number | string
  placeholder?: string
  searchString?: string
  setSearchString?: (searchString?: string) => void
  setShowOnlyChecked?: (showOnlyChecked: boolean) => void
  showOnlyChecked?: boolean
  title?: string
  width?: number | string
}

export const HierarchicalSelection = ({
  children,
  height = 300,
  placeholder,
  searchString,
  setSearchString,
  setShowOnlyChecked,
  showOnlyChecked,
  title,
  width = 300,
}: THierarchicalSelectionProps) => {
  return (
    <div
      className="hierarchical-selection"
      css={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: height,
        maxWidth: width,
        minHeight: height,
      }}
    >
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          padding: '4px 10px',
          transition: '0.15s ease all',
        }}
      >
        <div css={{ fontWeight: 'bold', fontSize: 16 }}>{title}</div>
        {!!setSearchString && (
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value ?? '')}
            placeholder={placeholder}
            css={{ marginLeft: 10 }}
            value={searchString ?? ''}
            icon={<IconSearch />}
          />
        )}
        {!!setShowOnlyChecked && (
          <div
            css={{
              display: 'flex',
              marginLeft: 6,
              whiteSpace: 'nowrap',
              borderRadius: 3,
              padding: '4px 8px 4px 4px',
            }}
          >
            <Switch
              checked={showOnlyChecked}
              label={<div>{translate.phrases.banyanApp('Show Only Checked')}</div>}
              onChange={() => setShowOnlyChecked(!showOnlyChecked)}
            />
          </div>
        )}
      </div>
      <div css={{ overflow: 'auto' }}>{children}</div>
    </div>
  )
}
