import type { TWindMachineDetailsElement } from '@semios/app-platform-banyan-route-definitions/dist/routes/windMachine'
import { StatusTile } from '../DeviceStatus/StatusTile'

export const DeviceHeader = ({
  windMachineName,
  currentWindMachineValues,
}: {
  windMachineName: string
  currentWindMachineValues: TWindMachineDetailsElement['windMachineDetails'][0]
}) => {
  // When warm up is added to chart use this
  // const wmStopThreshold = wmDetailsData?.equipmentSettings.calibrate1 || 150
  const wmWarmupThreshold = currentWindMachineValues.equipmentSettings.calibrate2 || 350
  const wmRPM = currentWindMachineValues.equipmentStatus.rpm

  let run = 0

  if (wmRPM > wmWarmupThreshold) run = 1

  let currentStatus = 'N/A'

  if (run === 1) currentStatus = 'ON'
  else if (run === 0) currentStatus = 'OFF'

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 10px 0px 10px',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <h2
          css={{
            fontSize: '16px',
            margin: '0 10px 0 0',
          }}
        >
          {windMachineName}
        </h2>
        <StatusTile status={currentStatus} auto={1} />
        <p
          css={{
            marginTop: '0',
            marginBottom: '0',
            fontSize: '10px',
            color: '#464B53',
          }}
        ></p>
      </div>
    </div>
  )
}
