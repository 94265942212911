export const defaultDates = {
  northernHemisphere: {
    chillEndDate: '03-31',
    chillStartDate: '12-01',
    evapotranspirationEndDate: '12-31',
    evapotranspirationStartDate: '01-01',
    fireBlightEndDate: '06-30',
    fireBlightStartDate: '03-01',
    growingDegreeDaysEndDate: '12-31',
    growingDegreeDaysStartDate: '01-01',
    irrigationActivityEndDate: '12-31',
    irrigationActivityStartDate: '01-01',
    irrigationPlannerEndDate: '12-31',
    irrigationPlannerStartDate: '01-01',
    pestEndDate: '10-31', // Note: default not based on specific pest id
    pestStartDate: '03-01', // Note: default not based on specific pest id
    pollinationEndDate: '06-30',
    pollinationStartDate: '02-01',
    scoutEndDate: '10-31',
    scoutStartDate: '02-01',
    soilMoistureEndDate: '12-31',
    soilMoistureStartDate: '01-01',
    weatherEndDate: '12-31',
    weatherStartDate: '01-01',
  },
  southernHemisphere: {
    chillEndDate: '09-30',
    chillStartDate: '06-01',
    evapotranspirationEndDate: '06-30',
    evapotranspirationStartDate: '07-01',
    fireBlightEndDate: '12-31',
    fireBlightStartDate: '09-01',
    growingDegreeDaysEndDate: '06-30',
    growingDegreeDaysStartDate: '07-01',
    irrigationActivityEndDate: '06-30',
    irrigationActivityStartDate: '07-01',
    irrigationPlannerEndDate: '06-30',
    irrigationPlannerStartDate: '07-01',
    pestEndDate: '04-30', // Note: default not based on specific pest id
    pestStartDate: '09-01', // Note: default not based on specific pest id
    pollinationEndDate: '12-31',
    pollinationStartDate: '08-01',
    scoutEndDate: '04-30',
    scoutStartDate: '08-01',
    soilMoistureEndDate: '06-30',
    soilMoistureStartDate: '07-01',
    weatherEndDate: '06-30',
    weatherStartDate: '07-01',
  },
}
