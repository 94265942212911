import { isEmpty } from '@semios/app-platform-common'
import { Button } from 'components/Button/Button'
import { translate } from 'i18n/i18n'
import { isEqual } from 'lodash'
import type { Moment } from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import { colors } from 'settings/colors'
import type { AlertTypeId, Rule } from '../../settings/alertSettings'
import { AlertsSettings } from '../../settings/alertSettings'

export const DefaultSettingsButton = ({
  alertTypeId,
  insectId,
  frequencyMinutes,
  hasTypeAndPest,
  sleepMinutes,
  startDate,
  endDate,
  limitedSchedule,
  rules,
  sleep,
  setStartDate,
  setEndDate,
  setFrequencyMinutes,
  setRules,
  setSleep,
  setSleepMinutes,
  setLimitedSchedule,
}: {
  alertTypeId: AlertTypeId | null
  insectId: number | null
  hasTypeAndPest: boolean
  sleep: boolean
  sleepMinutes: number | null
  startDate: Moment | null
  endDate: Moment | null
  frequencyMinutes: number | null
  limitedSchedule: boolean
  rules: Rule[]
  setStartDate: Dispatch<SetStateAction<Moment | null>>
  setEndDate: Dispatch<SetStateAction<Moment | null>>
  setFrequencyMinutes: Dispatch<SetStateAction<number | null>>
  setRules: Dispatch<SetStateAction<Rule[]>>
  setSleep: Dispatch<SetStateAction<boolean>>
  setSleepMinutes: Dispatch<SetStateAction<number | null>>
  setLimitedSchedule: Dispatch<SetStateAction<boolean>>
}) => {
  const defaultSettingsForAlertType =
    alertTypeId &&
    AlertsSettings.defaultSettings[alertTypeId as keyof typeof AlertsSettings.defaultSettings] &&
    AlertsSettings.defaultSettings[alertTypeId as keyof typeof AlertsSettings.defaultSettings]({
      insectId,
    })

  const showDefaultSettingsButton =
    hasTypeAndPest && defaultSettingsForAlertType && !isEmpty(defaultSettingsForAlertType)

  if (!showDefaultSettingsButton) return null

  // a function for disabling the button when the current configuration matches the output of default settings for the alertTypeId
  const defaultSettingsMatchCurrentConfigurationChecker = () => {
    if (
      defaultSettingsForAlertType.frequencyMinutes &&
      !(defaultSettingsForAlertType.frequencyMinutes === frequencyMinutes)
    )
      return false

    if (
      defaultSettingsForAlertType.sleepMinutes &&
      !(sleepMinutes && sleep && defaultSettingsForAlertType.sleepMinutes === sleepMinutes)
    )
      return false

    if (
      defaultSettingsForAlertType.startDate &&
      !(startDate && +defaultSettingsForAlertType.startDate === +startDate)
    )
      return false

    if (
      defaultSettingsForAlertType.endDate &&
      !(endDate && +defaultSettingsForAlertType.endDate === +endDate)
    )
      return false

    if ((defaultSettingsForAlertType.endDate || defaultSettingsForAlertType.startDate) && !limitedSchedule)
      return false

    if (defaultSettingsForAlertType.rules && !isEqual(rules, defaultSettingsForAlertType.rules)) return false

    return true
  }

  const configurationMatchesDefaults =
    defaultSettingsForAlertType && defaultSettingsMatchCurrentConfigurationChecker()

  return (
    <Button
      disabled={configurationMatchesDefaults}
      onClick={() => {
        // set schedule, if applicable
        if (defaultSettingsForAlertType.startDate || defaultSettingsForAlertType.endDate)
          setLimitedSchedule(true)

        if (defaultSettingsForAlertType.startDate) setStartDate(defaultSettingsForAlertType.startDate)

        if (defaultSettingsForAlertType.endDate) setEndDate(defaultSettingsForAlertType.endDate)

        // set frequency, if applicable
        if (defaultSettingsForAlertType.frequencyMinutes)
          setFrequencyMinutes(defaultSettingsForAlertType.frequencyMinutes)

        // set sleep, if applicable
        if (defaultSettingsForAlertType.sleepMinutes) setSleep(true)

        if (defaultSettingsForAlertType.sleepMinutes)
          setSleepMinutes(defaultSettingsForAlertType.sleepMinutes)

        if (defaultSettingsForAlertType.rules) setRules(defaultSettingsForAlertType.rules)
      }}
      variant="negative"
      css={{
        'color': colors.primary,
        '&:not([disabled],[data-loading="true"]):hover': {
          color: colors.primary,
        },
        'borderColor': colors.primary,
        'marginTop': 20,
      }}
    >
      {translate.phrases.banyanApp('Configure Alert With Default Settings')}
    </Button>
  )
}
