import { useMantineTheme } from '@mantine/core'

// Transform RSSI (-XX dBm) to a signal level (1-5)
export function getRSSILevel(rssi: number): number {
  if (rssi > 0) throw new Error('RSSI must be negative')

  if (rssi > -60) {
    // Great signal
    return 5
  } else if (rssi > -70) {
    // Good signal
    return 4
  } else if (rssi > -80) {
    // Okay signal
    return 3
  } else if (rssi > -90) {
    // Poor signal
    return 2
  } else if (rssi === -255) {
    // No signal/unknown
    return 0
  } else {
    // Bad signal
    return 1
  }
}

export const getSignalStrengthColors: (lv: number) => string = (lv: number) => {
  const theme = useMantineTheme()

  const SIGNAL_STRENGTH_COLORS: { [lv: number]: string } = {
    5: theme.colors.green[0],
    4: theme.colors.green[0],
    3: theme.colors.orange[6],
    2: theme.colors.orange[6],
    1: theme.colors.red[0],
  }

  return SIGNAL_STRENGTH_COLORS[lv]
}
