import type { TPermission } from '@semios/app-platform-value-type-definitions'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import { checkAuthorization } from 'utils/checkAuthorization'

export const selectedPropertyHasPermission = ({
  permission,
  propertyId = Number(selectedFieldAssetsStore.getState().property),
}: {
  permission: TPermission
  propertyId?: number
}) => {
  return checkAuthorization({ permission, entity: propertyId })
}
