import { routes } from '@semios/app-platform-banyan-route-definitions'
import { isEmpty } from '@semios/app-platform-common'
import { generateDefaultHeatmapHighLowColors } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { apiFetch } from 'utils/apiFetch'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import type { TGetCacheUpdatesFromResponseParameters, TGetCacheUpdatesFromResponseReturn } from './_types'
import { generateUsualStyleAPIArgs } from './_utils/generateUsualStyleAPIArgs'
import { generateUsualStyleGetCacheUpdatesFromResponse } from './_utils/generateUsualStyleGetCacheUpdatesFromResponse'
import { getValueType } from './_utils/getValueType'

const valueGroup = 'fruit_growth'
const heatmapExtremesForAllPropertiesInVisibleRegions = false

const makeApiArgs = (
  processedCaches: TGetCacheUpdatesFromResponseParameters['processedCaches'],
): routes.ValuesCurrent.Request => {
  return generateUsualStyleAPIArgs({
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
    },
  })
}

export const getResponseAndShapeForCacheUpdate = async ({
  cacheKeys,
  processedCaches,
}: TGetCacheUpdatesFromResponseParameters): Promise<TGetCacheUpdatesFromResponseReturn> => {
  const args = makeApiArgs(processedCaches)

  if (isEmpty(args)) return {}

  const response = await apiFetch<routes.ValuesCurrent.Request, routes.ValuesCurrent.Response>({
    url: routes.ValuesCurrent.path,
    body: args,
  })

  return generateUsualStyleGetCacheUpdatesFromResponse({
    cacheKeys,
    heatmapExtremesForAllPropertiesInVisibleRegions,
    processedCaches,
    response,
    inBlockPoint: {
      valueType: getValueType(MAP_VISUAL.POINT, valueGroup),
      unitConverterFunction: (val?: number | null) =>
        unitConverter.fruitGrowth(val, {
          decimalPlaces: unitConverter.fruitGrowth().defaultNumberOfDecimalPlaces() - 1,
        }),
    },
    heatmapColoring: generateDefaultHeatmapHighLowColors().reverse(),
  })
}
