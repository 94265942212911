import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconLayers = () => (
  <svg {...makeBaseSvgIconProps('0 0 21 22')}>
    <path
      d="M10.4883 19.0813L1.89 12.4709L0 13.9245L10.5 22L21 13.9245L19.0983 12.4594L10.4883 19.0813ZM10.5 16.151L19.0867 9.54064L21 8.07551L10.5 0L0 8.07551L1.90167 9.54064L10.5 16.151Z"
      fill="currentColor"
    />
  </svg>
)
