import * as Sentry from '@sentry/react'
import { Button } from 'components/Button/Button'
import { IconPower } from 'components/icons/IconPower'
import { translate } from 'i18n/i18n'
import { useIntercom } from 'react-use-intercom'
import { apiFetch } from 'utils/apiFetch'
import { unauthenticateAppState } from 'utils/unauthenaticateAppState'

export const ButtonSignOut = () => {
  const intercom = useIntercom()

  return (
    <Button
      variant="link"
      css={{ color: 'white' }}
      onClick={async () => {
        try {
          await apiFetch({ url: 'logout', body: {} })
        } catch (error) {
          Sentry.captureException(error)
        }

        intercom.shutdown()

        await unauthenticateAppState()
      }}
      size="lg"
    >
      <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IconPower />
        <div css={{ marginLeft: 12 }}>{translate.phrases.banyanApp('Sign Out')}</div>
      </div>
    </Button>
  )
}
