import { Alert, useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import React from 'react'
import { IconWarning } from '../../../../components/icons/IconWarning'
import type { TApiRequest } from '../../utils/api/queue'
import { ApiRequestStatus, ApiRequestType } from '../../utils/api/queue'
import { apiRequestQueueStore } from '../../utils/api/queue/apiRequestQueueStore'

interface FailedRequestErrorProps {
  request: TApiRequest
}

export const FailedRequestError: React.FC<FailedRequestErrorProps> = ({ request }) => {
  const theme = useMantineTheme()

  let title = translate.phrases.placeholder('Sync Error')

  if (request.type === ApiRequestType.INSTALL_NODE)
    title = translate.phrases.placeholder('Installation Error')
  else if (request.type === ApiRequestType.INSTALL_NODE_DEVICE)
    title = translate.phrases.placeholder('Device Installation Error')
  else if (
    request.type === ApiRequestType.PRE_UPLOAD_IMAGE ||
    request.type === ApiRequestType.UPLOAD_IMAGE ||
    request.type === ApiRequestType.ADD_NODE_IMAGES
  )
    title = translate.phrases.placeholder('Image Upload Error')

  return (
    <Alert
      icon={
        <span css={{ color: theme.colors.red[0] }}>
          <IconWarning />
        </span>
      }
      title={title}
      variant="light"
      styles={{
        root: {
          backgroundColor: 'rgb(255, 245, 245)',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
        },
        wrapper: { alignItems: 'center' },
        title: { fontSize: 16, marginBottom: 0, color: theme.colors.red[0] },
        message: { fontSize: 14 },
      }}
      withCloseButton={request.status === ApiRequestStatus.ERROR}
      onClose={() => apiRequestQueueStore.actions.unqueueRequest(request.id)}
    >
      {request.error?.message}
    </Alert>
  )
}
