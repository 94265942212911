import type { TTooltipFormatterContextObject } from 'components/StackedChart/types'

export const groupTooltipRowsIntoColumns = (
  points: TTooltipFormatterContextObject[],
): TTooltipFormatterContextObject[][] => {
  const numberOfRows = points.length
  const rowsForTwoColumns = Math.floor((numberOfRows - 1) / 2)
  const groupedRows: TTooltipFormatterContextObject[][] = []

  for (let i = 0; i < numberOfRows; i++) {
    const isInSecondHalf = i > rowsForTwoColumns
    const groupIndex = isInSecondHalf ? i - rowsForTwoColumns - 1 : i

    if (!groupedRows[groupIndex]) {
      groupedRows[groupIndex] = []
    }

    groupedRows[groupIndex].push(points[i])
  }

  return groupedRows
}
