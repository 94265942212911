import { removeCmOrInFromSoilProbeDepths } from 'utils/removeCmOrInFromSoilProbeDepths'
import { unitConverter } from 'utils/unitConverter/unitConverter'

export const soilUnitConverter = (item: string) => {
  const value = removeCmOrInFromSoilProbeDepths(item)
  const valueUnitConverted = unitConverter.soilDepth(value).valueWithSuffix()
  const valueFinalFormat = '-' + valueUnitConverted

  return valueFinalFormat
}
