import { MAP_DETAILS_PANEL_WRAPPER_ID } from 'App/Map/MapDetailsPanel'
import { getScreenSize } from 'utils/getScreenSize'

const collapsedWidescreenPanelWidth = 125
const widescreenNavigationMenuWidth = 300

export const getMapOverlaysPadding = () => {
  const isWideScreen = getScreenSize().isWideScreen

  if (!isWideScreen) {
    return
  } else {
    let rightPanelWidth = collapsedWidescreenPanelWidth

    /**
     * let's attempt to determine the width of the details panel. This
     * is likely to break if we do certain things, so for safety, let's
     * put it in a try-catch
     */
    try {
      // we'll start by finding the panel
      const detailPanelContainingDiv = document.getElementById(MAP_DETAILS_PANEL_WRAPPER_ID)

      if (detailPanelContainingDiv) {
        // next we'll find the first child of the panel, which is where the content of the panel goes
        const panelInnards = detailPanelContainingDiv.firstChild as HTMLDivElement | null

        if (panelInnards) {
          // now we'll try to determine if the panel is open, which would entail its x value being left of the edge of the screen less the width of the panel's tab buttons
          const panelIsOpen =
            panelInnards.getBoundingClientRect().x < window.innerWidth - collapsedWidescreenPanelWidth - 20 // add 20 for safety

          // if the panel's open, take its width and the width of the collapsed panel's buttons, otherwise assume it's closed
          rightPanelWidth = panelIsOpen
            ? panelInnards.clientWidth + collapsedWidescreenPanelWidth
            : collapsedWidescreenPanelWidth
        }
      }
    } catch (err) {}

    return { left: widescreenNavigationMenuWidth, right: rightPanelWidth }
  }
}
