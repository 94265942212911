import { translate } from 'i18n/i18n'

export const ComingSoonItem = ({ translatedTitle }: { translatedTitle: string }) => (
  <div css={{ display: 'flex', flexDirection: 'column', color: 'rgba(112, 112, 112, 1)' }}>
    <div>{translatedTitle}</div>
    <div>
      <em css={{ fontSize: 11 }}>{translate.phrases.banyanApp('Coming Soon')}</em>
    </div>
  </div>
)
