import { Menu } from '@mantine/core'
import type { ButtonProps } from 'components/Button/Button'
import { Button } from 'components/Button/Button'
import { IconCaret } from 'components/icons/IconCaret'
import type { ReactNode } from 'react'
import { useState } from 'react'

type DropdownButtonProps = {
  options: { content: ReactNode; onClick: () => void }[]
  showCaretIcon?: boolean
} & ButtonProps

export const DropdownButton = (props: DropdownButtonProps) => {
  const { options, showCaretIcon = true, ...buttonProps } = props
  const [hovered, setHovered] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <div>
      <Menu
        position={'bottom-start'}
        shadow="md"
        width={'auto'}
        offset={1}
        withinPortal={true}
        onChange={setDropdownOpen}
      >
        <Menu.Target>
          <div onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Button
              {...buttonProps}
              rightIcon={showCaretIcon && <IconCaret hovered={hovered} rotate={dropdownOpen} />}
            />
            {!showCaretIcon && <Button {...buttonProps} />}
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {options.map((option, index) => (
            <div key={index}>
              <Menu.Item onClick={option.onClick}>{option.content}</Menu.Item>
              {index < options.length - 1 && <Menu.Divider />}
            </div>
          ))}
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}
