import { Tooltip } from '@mantine/core'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import type { TAdminPermission } from 'App/Map/Admin/utils/useAdminEntites'
import { HierarchicalSelection } from 'components/HierarchicalSelection/HierarchicalSelection'
import { IconInfoLight } from 'components/icons/IconInfoLight'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { sortByKey } from 'utils/sortByKey'

const rowWrapperStyle = {
  alignItems: 'center',
  border: '1px solid rgb(233, 233, 233)',
  display: 'flex',
  padding: '4px 10px',
  transition: '0.15s ease all',
}

const selectedRowStyle = {
  'border': `3px solid ${colors.blue600}`,
  'background': colors.blue300,
  'color': colors.black,
  '&:hover': {
    background: colors.grey500,
  },
}

const regularRowStyle = {
  '&:hover': {
    background: colors.subtleBackground,
  },
}

const PermissionRow = ({
  permission,
  count,
  isSelected,
  setIsSelected,
}: {
  permission: TAdminPermission
  count: number
  isSelected: boolean
  setIsSelected: (isSelected: boolean) => void
}) => {
  const extraRowStyle = isSelected ? selectedRowStyle : regularRowStyle

  return (
    <div
      css={{
        ...rowWrapperStyle,
        ...extraRowStyle,
        cursor: 'pointer',
      }}
      onClick={() => setIsSelected(!isSelected)}
    >
      <div css={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <Tooltip label={permission.description} withArrow position={'right'}>
          <span css={{ cursor: 'help', paddingRight: '5px' }}>
            <IconInfoLight />
          </span>
        </Tooltip>
        {count ? (
          <span>
            <strong>{`(${count}) ${permission.id}`}</strong>
          </span>
        ) : (
          permission.id
        )}
      </div>
    </div>
  )
}

export const PermissionsPicker = ({
  permissions: permissionsArg,
  permissionIdToCountOfEntities,
  selectedPermission,
  setSelectedPermission,
  width,
}: {
  permissions: TAdminPermission[]
  permissionIdToCountOfEntities: Record<string, number>
  selectedPermission: TAdminPermission['id'] | null
  setSelectedPermission: (permission: TAdminPermission['id'] | null) => void
  width: string
}) => {
  const [searchString, setSearchString] = useState<string | undefined>()
  const [permissions, setPermissions] = useState<TAdminPermission[]>(permissionsArg)

  useEffect(() => {
    const searchedRoles = arrayOfObjectsSearch(permissionsArg, searchString ?? '', ['id'])

    setPermissions(searchedRoles)
  }, [searchString, permissionsArg])

  return (
    <HierarchicalSelection
      width={width}
      title={translate.phrases.banyanApp('Permissions')}
      placeholder={translate.phrases.banyanApp('Search for a permission')}
      searchString={searchString ?? ''}
      setSearchString={setSearchString}
    >
      {permissions.sort(sortByKey('id')).map((permission) => (
        <PermissionRow
          key={permission.id}
          permission={permission}
          count={permissionIdToCountOfEntities[permission.id] ?? 0}
          isSelected={permission.id === selectedPermission}
          setIsSelected={(isSelected) => setSelectedPermission(isSelected ? permission.id : null)}
        />
      ))}
    </HierarchicalSelection>
  )
}
