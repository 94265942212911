import { SharedTypes } from '@semios/app-platform-banyan-route-definitions'

export function flatNodeDevices(nodeDevices?: SharedTypes.TNodeDevices): SharedTypes.TNodeDevice[] {
  if (!nodeDevices) return []

  const devices: SharedTypes.TNodeDevice[] = []

  Object.keys(nodeDevices).forEach((connector) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Object.keys(nodeDevices[connector as keyof SharedTypes.TNodeDevices]!).forEach((channel) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const device = nodeDevices[connector as keyof SharedTypes.TNodeDevices]![+channel]

      devices.push({
        ...device,
        connector: connector as keyof SharedTypes.TNodeDevices,
        channel: +channel,
      })
    })
  })

  return devices
}

export function getPlannedDevices(nodeDevices?: SharedTypes.TNodeDevices): SharedTypes.TNodeDevice[] {
  if (!nodeDevices) return []

  const devicesArray = flatNodeDevices(nodeDevices)

  return devicesArray.filter(
    (device) => device.installationStatus === SharedTypes.NodeDeviceInstallationStatus.PLANNED,
  )
}
