import { useEffect, useState } from 'react'
import type { TStateWithTimeout } from './Select'

export const useStateWithTimeout = (durationMillisecond: number) => {
  const [stateWithTimeout, setStateWithTimeout] = useState<TStateWithTimeout>('default')

  useEffect(() => {
    const timer = setTimeout(() => {
      if (stateWithTimeout === 'success' || stateWithTimeout === 'error') setStateWithTimeout('default')
    }, durationMillisecond)

    return () => clearTimeout(timer)
  }, [stateWithTimeout])

  return {
    stateWithTimeout,
    setStateWithTimeout,
  }
}
