import { css } from '@emotion/css'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextInput } from '@mantine/core'
import React, { useCallback, useState } from 'react'
import { colors } from 'settings/colors'

export const InputExamples = () => {
  const [inputText, setInputText] = useState('')

  const focusedInput = useCallback((inputElement: HTMLInputElement) => {
    if (inputElement) {
      inputElement.focus()
    }
  }, [])

  return (
    <>
      {/* Input Component Examples */}

      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 2rem 1rem;
        `}
      >
        <TextInput
          label={'Input Label'}
          placeholder={'Placeholder Text'}
          value={inputText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputText(event?.target?.value)}
          error={null}
        />

        <TextInput
          ref={focusedInput}
          label={'Input Label'}
          value={inputText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputText(event?.target?.value)}
          error={null}
        />

        <TextInput
          label={'Input Label'}
          value={inputText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputText(event?.target?.value)}
          error={null}
          disabled
        />

        <TextInput
          label={'Input Label'}
          placeholder={'Placeholder Text'}
          value={inputText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputText(event?.target?.value)}
          error={'This field is required'}
          required
          styles={{ input: { borderColor: colors.red } }}
        />

        <TextInput
          label={'Input Label'}
          value={inputText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputText(event?.target?.value)}
          error={null}
          styles={{ input: { borderColor: colors.green } }}
          rightSection={<FontAwesomeIcon icon={faCheckCircle} css={{ fontSize: 16, color: colors.green }} />}
        />
      </div>
    </>
  )
}
