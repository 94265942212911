import { Select } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { useMemo } from 'react'
import { SharedSettings } from 'settings/SharedSettings'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import type {
  TValuesCurrentBlocksValueTypes,
  TValuesCurrentPointsValueTypes,
} from 'stores/mapControlsStore/types'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'

const updateMapVisuals = (newValueType: TValuesCurrentBlocksValueTypes & TValuesCurrentPointsValueTypes) => {
  mapControlsStore.setState((s) => ({
    ...s,
    mapVisualValueGroup: {
      ...s.mapVisualValueGroup,
      [MAP_VISUAL?.IMAGE_OVERLAY]: {
        ...s.mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY],
        ndvi: {
          ...(s.mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi ?? { mItem: 'M_Block' }),
          valueType: newValueType && newValueType,
        },
      },
    },
  }))
}

export const NDVIDropdown = () => {
  const value = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL?.IMAGE_OVERLAY]?.ndvi?.valueType,
  )

  const properties = fieldAssetStore.useSelector((s) => s.properties)

  const data = useMemo(() => {
    const returner: { value: TValuesCurrentBlocksValueTypes | ''; label: string; group: string }[] = [
      {
        value: 'imageryOverlayNDVI_current',
        label: translate.phrases.banyanApp('Latest Vegetation Index'),
        group: translate.phrases.banyanApp('Vegetation Index'),
      },
      {
        value: 'imageryOverlayNDVIChange_sinceLastImage',
        label: translate.phrases.banyanApp('Short Term Change'),
        group: translate.phrases.banyanApp('Vegetation Index'),
      },
      {
        value: 'imageryOverlayNDVIChange_sinceLastMonth',
        label: translate.phrases.banyanApp('Long Term Change'),
        group: translate.phrases.banyanApp('Vegetation Index'),
      },
      {
        value: 'imageryOverlayRGB_current',
        label: translate.phrases.banyanApp('Latest Visible Crop'),
        group: translate.phrases.banyanApp('Visible Crop'),
      },
    ]

    return returner
  }, [properties, value])

  return (
    <Select
      data={data}
      value={value}
      onChange={updateMapVisuals}
      styles={SharedSettings.MANTINE_SELECT_RIGHT_ICON_CHANGER}
      withinPortal
    />
  )
}
