import { translate } from 'i18n/i18n'
import { mapControlsStore } from 'stores/mapControlsStore/mapControlsStore'
import { MAP_VISUAL } from 'stores/mapControlsStore/types'
import { smallStore } from 'stores/smallStore'
import { userDetailsStore } from 'stores/userDetailsStore'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { DefaultHeatmapRow } from '../DefaultHeatmapRow/DefaultHeatmapRow'

export const IrrigationActivityHeatmapRow = () => {
  const appliedIrrigationActivityDisplay = smallStore.useSelector((s) => s.appliedIrrigationActivityDisplay)

  const pressureTransducerIrrigationActivityValueType = mapControlsStore.useSelector(
    (s) => s.mapVisualValueGroup?.[MAP_VISUAL.POINT]?.irrigation_activity?.valueType,
  )

  // to get refreshes on unit changes working well
  userDetailsStore.useSelector((s) => s.rain)

  let suffix = translate.phrases.banyanApp('Hours')

  // TODO: this could be wired up to the store, but also, we should replace our unitConverter with some sort of general valueTypeHelpers
  if (appliedIrrigationActivityDisplay === 'VOLUME') suffix = unitConverter.precipitation().suffix()

  if (pressureTransducerIrrigationActivityValueType?.endsWith('_status')) return null

  return <DefaultHeatmapRow suffix={suffix} />
}
