import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconControlsTab = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 32 27')}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 27"
      fill="none"
    >
      <path
        d="M4.46151 7.92303C6.37325 7.92303 7.92302 6.37325 7.92302 4.46151C7.92302 2.54977 6.37325 1 4.46151 1C2.54977 1 1 2.54977 1 4.46151C1 6.37325 2.54977 7.92303 4.46151 7.92303Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.46191 7.92188V26.3833"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9996 21.7707C17.9113 21.7707 19.4611 20.2209 19.4611 18.3092C19.4611 16.3974 17.9113 14.8477 15.9996 14.8477C14.0879 14.8477 12.5381 16.3974 12.5381 18.3092C12.5381 20.2209 14.0879 21.7707 15.9996 21.7707Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 1V14.8461"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.7695V26.3849"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5387 14.8449C29.4504 14.8449 31.0002 13.2951 31.0002 11.3834C31.0002 9.47165 29.4504 7.92188 27.5387 7.92188C25.6269 7.92188 24.0771 9.47165 24.0771 11.3834C24.0771 13.2951 25.6269 14.8449 27.5387 14.8449Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5381 14.8477V26.386"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5381 1V7.92303"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
