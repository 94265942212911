import type { TAvailablePreset } from 'App/Map/types'
import { useMemo } from 'react'
import { userDetailsStore } from 'stores/userDetailsStore'
import { sortByKey } from 'utils/sortByKey'
import { ItemRow } from './ItemRow/ItemRow'

export const UserDefinedPresets = ({
  duplicatePreset,
  editPreset,
}: {
  duplicatePreset: (preset: TAvailablePreset) => void
  editPreset: (preset: TAvailablePreset) => void
}) => {
  const availableUserDefinedPresets = userDetailsStore.useSelector((s) => s.availableUserDefinedPresets)

  const presetsToMap = useMemo(() => {
    return Object.values(availableUserDefinedPresets ?? {}).sort(sortByKey('name'))
  }, [availableUserDefinedPresets])

  return (
    <>
      {presetsToMap.map((preset) => (
        <ItemRow
          key={preset.idHash}
          duplicatePreset={duplicatePreset}
          editPreset={editPreset}
          preset={preset}
        />
      ))}
    </>
  )
}
