import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconChevronCircle = () => (
  <svg {...makeBaseSvgIconProps('0 0 27 27')}>
    <path
      d="M18 12.2729L13.9091 16.3639L9.81818 12.2729"
      stroke="currentColor"
      strokeWidth="0.1em"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="13.5"
      cy="13.5"
      r="12.5"
      transform="rotate(90 13.5 13.5)"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)
