export const SelectedValuesBubble = ({ value }: { value: number }) => {
  if (!value) return null

  return (
    <div
      css={{
        background: '#0e4da4',
        borderRadius: '10px',
        color: 'white',
        padding: '3px 8px',
      }}
    >
      {value}
    </div>
  )
}
