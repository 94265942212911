import { computeDistanceBetweenTwoPoints } from 'utils/computeDistanceBetweenTwoPoints'

export const sortByClosestPoints =
  (anchorCoordinates: { lng: number; lat: number }) =>
  (a: { lng: number; lat: number }, b: { lng: number; lat: number }) => {
    return (
      computeDistanceBetweenTwoPoints(anchorCoordinates, a) -
      computeDistanceBetweenTwoPoints(anchorCoordinates, b)
    )
  }
