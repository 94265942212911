import type { ModalProps } from '@mantine/core'
import { Modal } from '@mantine/core'
import type { ModalSettings } from '@mantine/modals/lib/context'
import { cloneDeep } from 'lodash'
import React from 'react'
import { init } from 'stores/_utils/simple-store'

const modalStore = init<Map<string, ModalSettings & { group?: string }>>(new Map())

const openModal = (props: Omit<ModalSettings, 'opened'> & { group?: string }) => {
  modalStore.setState((prev) => {
    const updatedModal = prev ? cloneDeep(prev) : new Map()
    const group = props.group || 'default'
    const existingModal = updatedModal.get(group)

    if (existingModal) {
      existingModal.onClose?.()

      updatedModal.delete(group)
    }

    updatedModal.set(group, { ...props })

    return updatedModal
  })
}

const closeModal = (modalId: string) => {
  modalStore.setState((prev) => {
    const updatedModal = prev ? cloneDeep(prev) : new Map()

    const setting = Array.from(updatedModal).find(
      ([, value]: [string, ModalSettings]) => value.modalId === modalId,
    )

    if (setting) {
      const [group, existingModal] = setting

      existingModal.onClose?.()

      updatedModal.delete(group)
    }

    return updatedModal
  })
}

const Overlay: React.FC = () => {
  const modalSettings = modalStore.useSelector((settings) => settings)

  let index = 1000

  const modals = Array.from(modalSettings).map(([key, value]: [string, ModalSettings]) => {
    const { styles, modalId, onClose, ...props } = value

    const overrideProp: Omit<ModalProps, 'onClose' | 'opened'> = {
      ...props,
      closeButtonProps: {
        iconSize: 'xl',
      },
      styles: {
        ...styles,
        close: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...((styles as any)?.close || {}),
          order: -1, // make sure the close button is on the left
          marginRight: 10, //erase the default margin
          marginLeft: 0, //erase the default margin
          scale: '1.5', // make the close button bigger
        },
        header: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...((styles as any)?.header || {}),
          justifyContent: 'start', // make sure the close button is on the left
        },
        root: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...((styles as any)?.root || {}),
          position: 'fixed',
          zIndex: index++, // make sure this is great than any other native or custom modals,
        },
      },
    }

    return {
      id: modalId,
      group: key,
      onClose: onClose,
      overrideProp,
    }
  })

  return (
    <>
      {modals.map((modal) => (
        <Modal
          opened
          key={modal.id}
          onClose={() => {
            if (modal.id) {
              closeModal(modal.id)
            }
          }}
          {...modal.overrideProp}
        />
      ))}
    </>
  )
}

export const AboveAllModal = {
  close: closeModal,
  open: openModal,
  Overlay: Overlay,
}
