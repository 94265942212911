import { moderateDeficitThreshold } from 'App/Map/_utils/moderateDeficitThreshold'
import { PREDICTION_KEY } from './predictionKey'

type Point = {
  x: number
  y: number
  type: string
}

type RangePoint = {
  x: number
  high: number
  low: number
  type: string
}

const fc = 100
const pwp = 0

const sortDates = (a: { startDate: string }, b: { startDate: string }) =>
  +new Date(a.startDate) - +new Date(b.startDate)

const mapPoints = (timeseries: number[], value: number): Point[] =>
  timeseries.map((stamp) => ({
    x: stamp,
    y: value,
    type: 'area',
  }))

const mapMadPoints = (
  timeseries: number[],
  madDates: { startDate: string; soilMoisture: number }[],
): Point[] =>
  timeseries.map((stamp) => {
    const madValue = madDates.find((madDate) => +new Date(madDate.startDate) <= stamp)?.soilMoisture

    return { x: stamp, y: madValue ? Number(madValue) : 70, type: 'area' }
  })

export const getAwcPoints = (
  awcData: {
    metadata: {
      depth: string
      MADDates: {
        startDate: string
        soilMoisture: number
      }[]
    }
    timeseries: {
      timestamp: string
    }[]
  }[],
  forecastStartsAt: number,
) => {
  const {
    metadata: { MADDates },
  } = awcData[0]

  const nonForecastedTimeseries =
    awcData.find(({ metadata }) => metadata.depth !== PREDICTION_KEY)?.timeseries ?? []

  const forecastedTimeseries = (
    awcData.find(({ metadata }) => metadata.depth === PREDICTION_KEY)?.timeseries ?? []
  ).map(({ timestamp }) => +new Date(timestamp))

  const pastTimesseries = nonForecastedTimeseries.map(({ timestamp }) => +new Date(timestamp))

  const pastTimeseriesWithForecastedSeries =
    nonForecastedTimeseries.length > 0 && forecastedTimeseries.length > 0
      ? [...pastTimesseries, forecastStartsAt]
      : pastTimesseries

  const forecastedSeriesWithLastPastTimestamp =
    pastTimesseries.length > 0 && forecastedTimeseries.length > 0
      ? [forecastStartsAt, ...forecastedTimeseries]
      : forecastedTimeseries

  const sortedMadDates = MADDates?.sort(sortDates) || []
  const madPoints = mapMadPoints(pastTimeseriesWithForecastedSeries, sortedMadDates)
  const mappedSaturatedPoints = mapPoints(pastTimeseriesWithForecastedSeries, fc)
  const mappedExtremeDeficitPoints = mapPoints(pastTimeseriesWithForecastedSeries, pwp)
  const forecastedMadPoints = mapMadPoints(forecastedSeriesWithLastPastTimestamp, sortedMadDates)
  const forecastedMappedSaturatedPoints = mapPoints(forecastedSeriesWithLastPastTimestamp, fc)
  const forecastedMappedExtremeDeficitPoints = mapPoints(forecastedSeriesWithLastPastTimestamp, pwp)

  const mappedIdealPoints: RangePoint[] = mappedSaturatedPoints.map(({ x, y }, i) => ({
    x,
    high: y,
    low: madPoints[i].y,
    type: 'area',
  }))

  const forecastedMappedIdealPoints: RangePoint[] = forecastedMappedSaturatedPoints.map(({ x, y }, i) => ({
    x,
    high: y,
    low: forecastedMadPoints[i].y,
    type: 'area',
  }))

  const mappedModerateDeficitPoints: RangePoint[] = madPoints.map(({ x, y }) => {
    const low = y * (1 - moderateDeficitThreshold)

    return {
      x,
      high: y,
      low,
      type: 'area',
    }
  })

  const forecastedMappedModerateDeficitPoints: RangePoint[] = forecastedMadPoints.map(({ x, y }) => {
    const low = y * (1 - moderateDeficitThreshold)

    return {
      x,
      high: y,
      low,
      type: 'area',
    }
  })

  const mappedHighDeficitPoints: RangePoint[] = mappedExtremeDeficitPoints.map(({ x, y }, i) => ({
    x,
    low: y,
    high: mappedModerateDeficitPoints[i].low,
    type: 'area',
  }))

  const forecastedMappedHighDeficitPoints: RangePoint[] = forecastedMappedExtremeDeficitPoints.map(
    ({ x, y }, i) => ({
      x,
      low: y,
      high: forecastedMappedModerateDeficitPoints[i].low,
      type: 'area',
    }),
  )

  return {
    mappedSaturatedPoints,
    mappedExtremeDeficitPoints,
    mappedIdealPoints,
    mappedModerateDeficitPoints,
    mappedHighDeficitPoints,
    forecastedMappedSaturatedPoints,
    forecastedMappedExtremeDeficitPoints,
    forecastedMappedIdealPoints,
    forecastedMappedModerateDeficitPoints,
    forecastedMappedHighDeficitPoints,
  }
}
