export const computeDistanceBetweenTwoPoints = (
  point1: { lng: number; lat: number },
  point2: { lng: number; lat: number },
) => {
  if (!window.google) return 0

  if (!google?.maps?.geometry?.spherical?.computeDistanceBetween || !google?.maps?.LatLng) return 0

  return google?.maps?.geometry?.spherical?.computeDistanceBetween(
    new google.maps.LatLng({ lng: point1.lng, lat: point1.lat }),
    new google.maps.LatLng({ lng: point2.lng, lat: point2.lat }),
  )
}
