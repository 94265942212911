import { isNil } from 'lodash'
import { colors } from 'settings/colors'

const { idealBadge, moderateDeficitBadge, extremeDeficitBadge } = colors
const DEFAULT_THRESHOLD = 7

export const getAlternariaStatus = (dsv7Days: number, thresholdParam?: number | null) => {
  const threshold = isNil(thresholdParam) ? DEFAULT_THRESHOLD : thresholdParam

  let fillColor = 'transparent'

  if (isNil(dsv7Days)) return fillColor

  const riskPercentage = dsv7Days / threshold

  if (riskPercentage < 0.2) {
    fillColor = idealBadge
  } else if (riskPercentage >= 0.2 && riskPercentage < 0.8) {
    fillColor = moderateDeficitBadge
  } else {
    fillColor = extremeDeficitBadge
  }

  return fillColor
}
