import { colors } from 'settings/colors'

export type StaticAssetsLinkProps = {
  asset: 'sula' | 'privacy' | 'tac'
  label: string
}

export const StaticAssetsLink = ({ asset, label }: StaticAssetsLinkProps) => {
  return (
    <a
      css={{
        color: colors.primary,
        cursor: 'pointer',
        fontWeight: 700,
        margin: '0 5px',
        textDecoration: 'underline',
      }}
      onClick={() => window.open(`/${asset}`)}
      target="_blank"
      href={`/${asset}`}
      rel="noreferrer"
    >
      {label}
    </a>
  )
}
