// import { Switch } from '@mantine/core'
// import { colors } from 'settings/colors'
// import { smallStore } from 'stores/smallStore'
// import { IconGlobe } from 'components/icons/IconGlobe'

const RegionalDataSwitch = () => {
  //TODO: TEMP_DISABLE_REGIONS

  return <></>
  // const showRegionalData = smallStore.useSelector((s) => s.showRegionalData)

  // return (
  //   <div
  //     css={{
  //       position: 'absolute',
  //       right: '20px',
  //       top: '12px',
  //     }}
  //   >
  //     <Switch
  //       label={
  //         <span css={{ fontSize: 25 }}>
  //           <IconGlobe />
  //         </span>
  //       }
  //       labelPosition={'left'}
  //       checked={showRegionalData}
  //       onChange={() =>
  //         smallStore.setState((previousState) => ({
  //           ...previousState,
  //           showRegionalData: !previousState.showRegionalData,
  //         }))
  //       }
  //       size="md"
  //       styles={{
  //         label: { cursor: 'pointer', fontSize: 16 },
  //         track: {
  //           cursor: 'pointer',
  //           backgroundColor: showRegionalData ? `${colors.green} !important` : undefined,
  //           borderColor: showRegionalData ? `${colors.green} !important` : undefined,
  //         },
  //       }}
  //     />
  //   </div>
  // )
}

export default RegionalDataSwitch
