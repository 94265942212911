import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const Crosshairs = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 220 239')}>
      <path d="M218 62.7101V2H161.763" stroke="currentColor" strokeWidth="4" />
      <path d="M2 63.3492V2.63916H58.2367" stroke="currentColor" strokeWidth="4" />
      <path d="M218 175.822L218 236.532L161.763 236.532" stroke="currentColor" strokeWidth="4" />
      <path d="M1.99999 175.822L2 236.532L58.2367 236.532" stroke="currentColor" strokeWidth="4" />
    </svg>
  )
}
