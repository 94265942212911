import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorBlock } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorBlock/DropdownSelectorBlock'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import { EAggregationInterval } from 'App/Map/types'
import type { StackedChartSection, TChartSeries } from 'components/StackedChart/types'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { selectedFieldAssetsStore } from 'stores/selectedFieldAssetsStore'
import type { selectedValueGroupsStore } from 'stores/selectedValueGroupsStore/selectedValueGroupsStore'
import { doesSelectedBlockHaveValueTypes } from 'utils/doesSelectedFieldAssetHaveValueTypes'
import { unitConverter } from 'utils/unitConverter/unitConverter'
import { getXDateFormat } from '../_utils/getXDateFormat'
import { makeCompareSeasonsSeriesFromRegularSeries } from '../_utils/makeCompareSeasonsSeriesFromRegularSeries'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_WALNUT_BLIGHT_DETAILS' })
const preferredAggregationInterval = { preferredAggregationInterval: EAggregationInterval.DAILY }

const valuesRequested: Record<string, typeof preferredAggregationInterval> = {
  walnutBlightRPV: preferredAggregationInterval,
  walnutBlightRPVCumulativeRolling7Days: preferredAggregationInterval,
}

export const apiArgs = ({
  selectedValueGroups,
  selectedFieldAssets,
}: {
  selectedValueGroups: ReturnType<typeof selectedValueGroupsStore.getState>['selectedValueGroups']
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): Partial<routes.Values.Request> => {
  if (!checkPermission()) return {}

  if (!selectedValueGroups.walnut_blight || !selectedFieldAssets.block) return {}

  if (!doesSelectedBlockHaveValueTypes({ valuesTimeseries: Object.keys(valuesRequested) })) return {}

  return {
    blocks: {
      blockIds: [selectedFieldAssets.block],
      valuesRequested,
    },
  }
}

export const content = ({
  data,
  compareSeasonsData,
}: {
  data: routes.Values.Response
  compareSeasonsData: routes.Values.Response
}): StackedChartSection => {
  const commonReturnItems = {
    title: unitConverter.walnutBlightRPV().categoryTitleWithoutUnit(),
    titleChildren: <DropdownSelectorBlock valuesTimeseriesToFilterOn={Object.keys(valuesRequested)} />,
    id: 'stackem-walnut-blight',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const blockId = String(selectedFieldAssetsStore.getState().block)
  const { compareSeasonsInterval } = detailsPanelStore.getState()

  const rpvSprayThreshold =
    data?.blocks?.[blockId]?.values?.walnutBlightRPVCumulativeRolling7Days?.[0]?.metadata
      .blockRPVSprayThreshold

  const series: TChartSeries[] = []

  const walnutBlightRPVSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: 'rgba(0, 0, 0, 1)',
    name: unitConverter.walnutBlightRPV().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.walnutBlightRPV().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.walnutBlightRPV().suffix()}`,
    },
    yAxis: 0,
    data: (data?.blocks?.[blockId]?.values?.walnutBlightRPV?.[0]?.timeseries ?? []).map((d) => [
      +new Date(d.timestamp),
      unitConverter.walnutBlightRPV(d.value).value(),
    ]),
    type: 'column',
    id: 'walnutBlightRPV',
  }

  series.push(walnutBlightRPVSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(walnutBlightRPVSeries, {
        data: (compareSeasonsData?.blocks?.[blockId]?.values?.walnutBlightRPV?.[0]?.timeseries ?? []).map(
          (d) => [+new Date(d.timestamp), unitConverter.walnutBlightRPV(d.value).value()],
        ),
      }),
    )
  }

  const walnutBlightRPVCumulativeRolling7DaysSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.idealSaturatedBoundary,
    name: unitConverter.walnutBlightRPVCumulativeRolling7Days().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.walnutBlightRPVCumulativeRolling7Days().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.walnutBlightRPVCumulativeRolling7Days().suffix()}`,
    },
    yAxis: 0,
    data: (data?.blocks?.[blockId]?.values?.walnutBlightRPVCumulativeRolling7Days?.[0]?.timeseries ?? []).map(
      (d) => [+new Date(d.timestamp), unitConverter.walnutBlightRPVCumulativeRolling7Days(d.value).value()],
    ),
    type: 'line',
    step: 'center',
    id: 'walnutBlightRPVCumulativeRolling7Days',
  }

  series.push(walnutBlightRPVCumulativeRolling7DaysSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(walnutBlightRPVCumulativeRolling7DaysSeries, {
        data: (
          compareSeasonsData?.blocks?.[blockId]?.values?.walnutBlightRPVCumulativeRolling7Days?.[0]
            ?.timeseries ?? []
        ).map((d) => [
          +new Date(d.timestamp),
          unitConverter.walnutBlightRPVCumulativeRolling7Days(d.value).value(),
        ]),
      }),
    )
  }

  const walnutBlightBlockRPVSprayThresholdSeries: TChartSeries & { color: TRGBAColorWith1AtTheEnd } = {
    color: colors.red,
    name: unitConverter.walnutBlightBlockRPVSprayThreshold().shortTitle(),
    tooltip: {
      valueDecimals: unitConverter.walnutBlightBlockRPVSprayThreshold().defaultNumberOfDecimalPlaces(),
      valueSuffix: ` ${unitConverter.walnutBlightBlockRPVSprayThreshold().suffix()}`,
    },
    yAxis: 0,
    data: (data?.blocks?.[blockId]?.values?.walnutBlightRPVCumulativeRolling7Days?.[0]?.timeseries ?? []).map(
      (d) => [
        +new Date(d.timestamp),
        unitConverter.walnutBlightRPVCumulativeRolling7Days(rpvSprayThreshold).value(),
      ],
    ),
    type: 'line',
    step: 'center',
    id: 'walnutBlightBlockRPVSprayThreshold',
  }

  series.push(walnutBlightBlockRPVSprayThresholdSeries)

  if (compareSeasonsInterval) {
    series.push(
      makeCompareSeasonsSeriesFromRegularSeries(walnutBlightBlockRPVSprayThresholdSeries, {
        data: (
          compareSeasonsData?.blocks?.[blockId]?.values?.walnutBlightRPVCumulativeRolling7Days?.[0]
            ?.timeseries ?? []
        ).map((d) => [
          +new Date(d.timestamp),
          unitConverter.walnutBlightRPVCumulativeRolling7Days(rpvSprayThreshold).value(),
        ]),
      }),
    )
  }

  return {
    ...commonReturnItems,
    items: [
      {
        chartConfig: {
          semiosHighchartsAdditions: {
            id: commonReturnItems.id,
            firstForecastTimestamp: +new Date(),
          },
          chart: {
            type: 'line',
          },
          series,
          tooltip: {
            xDateFormat: getXDateFormat(),
          },
        },
      },
    ],
  }
}
