import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconOvercastNightOutline = () => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 419 253')}
      viewBox="0 0 419 253"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18_1063)">
        <g clipPath="url(#clip1_18_1063)">
          <mask
            id="mask0_18_1063"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="100"
            height="147"
          >
            <path
              d="M100 71.5658C94.8087 71.5693 89.7047 73.3492 85.1757 76.7354C80.6466 80.1217 76.8438 85.0012 74.1304 90.9079C71.0538 88.4688 67.5486 87.159 63.9703 87.1114C60.3919 87.0638 56.8678 88.2801 53.7554 90.6369C50.643 92.9937 48.053 96.4071 46.2481 100.531C44.4432 104.655 43.4876 109.343 43.4783 114.118C43.4878 115.577 43.5848 117.032 43.7681 118.47C38.7922 119.732 34.3027 123.273 31.0745 128.482C27.8464 133.691 26.0823 140.24 26.087 147H0V0H100V71.5658Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_18_1063)">
            <g clipPath="url(#clip2_18_1063)">
              <path
                d="M171.6 109.4C160.455 109.479 149.405 107.361 139.079 103.166C128.754 98.9706 119.357 92.7815 111.426 84.9522C103.494 77.1229 97.1839 67.8069 92.8555 57.537C88.527 47.267 86.2654 36.2446 86.2 25.1C86.2272 17.9759 87.1682 10.8847 89 4C68.2476 6.14248 49.016 15.8686 34.9914 31.3141C20.9668 46.7596 13.136 66.8377 13 87.7C13.0654 98.8446 15.327 109.867 19.6555 120.137C23.9839 130.407 30.2943 139.723 38.2258 147.552C46.1573 155.382 55.5543 161.571 65.8795 165.766C76.2047 169.961 87.2555 172.079 98.4 172C117.201 172.07 135.497 165.919 150.437 154.505C165.378 143.092 176.123 127.058 181 108.9C177.878 109.234 174.74 109.401 171.6 109.4Z"
                // stroke="#72B9D5"
                stroke="currentColor"
                strokeWidth="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <g clipPath="url(#clip3_18_1063)">
            <mask
              id="mask1_18_1063"
              style={{ maskType: 'luminance' }}
              maskUnits="userSpaceOnUse"
              x="282"
              y="8"
              width="137"
              height="181"
            >
              <path
                d="M363.202 189C363.202 180.272 359.78 171.901 353.689 165.73C347.598 159.558 339.336 156.091 330.721 156.091L329.271 156.15C331.075 148.88 331.214 141.288 329.679 133.955C328.144 126.622 324.975 119.742 320.414 113.839C315.853 107.937 310.021 103.169 303.363 99.8993C296.706 96.6296 289.399 94.945 282 94.974V8H419V189H363.202Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_18_1063)">
              <g clipPath="url(#clip4_18_1063)">
                <path
                  d="M210.35 146.21C210.35 154.803 213.763 163.044 219.839 169.12C225.916 175.196 234.157 178.61 242.75 178.61H372.55V178.51L374.85 178.61C383.438 178.503 391.684 175.224 398 169.404C404.317 163.583 408.257 155.632 409.064 147.081C409.871 138.53 407.487 129.982 402.37 123.083C397.254 116.184 389.767 111.421 381.35 109.71C382.322 103.633 381.544 97.405 379.106 91.7544C376.668 86.1037 372.671 81.2643 367.583 77.8023C362.495 74.3403 356.526 72.399 350.375 72.2056C344.224 72.0121 338.145 73.5745 332.85 76.7099C326.784 66.8223 317.382 59.4303 306.342 55.8696C295.302 52.309 283.352 52.8145 272.652 57.2949C261.952 61.7753 253.208 69.935 247.999 80.2997C242.79 90.6643 241.46 102.55 244.25 113.81H242.75C234.157 113.81 225.916 117.224 219.839 123.3C213.763 129.376 210.35 137.617 210.35 146.21Z"
                  // stroke="#94A3B8"
                  stroke="currentColor"
                  strokeWidth="15"
                  strokeLinejoin="round"
                />
              </g>
            </g>
            <g clipPath="url(#clip5_18_1063)">
              <path
                d="M302.84 244.999C317.692 244.999 331.936 239.099 342.438 228.597C352.94 218.095 358.84 203.851 358.84 188.999C358.84 174.147 352.94 159.903 342.438 149.401C331.936 138.899 317.692 132.999 302.84 132.999L300.34 133.099C305.262 113.628 303.036 93.0376 294.067 75.0681C285.098 57.0985 269.981 42.9424 251.462 35.1716C232.942 27.4008 212.25 26.5308 193.144 32.7198C174.038 38.9088 157.785 51.746 147.34 68.8993C138.841 63.8508 129.157 61.1419 119.273 61.0478C109.388 60.9538 99.6546 63.4778 91.0613 68.3636C82.468 73.2494 75.3211 80.3228 70.3468 88.8651C65.3724 97.4074 62.7478 107.114 62.7397 116.999C62.7661 120.016 63.0336 123.025 63.5397 125.999C48.7987 128.724 35.6116 136.868 26.5742 148.828C17.5368 160.788 13.3038 175.698 14.7085 190.622C16.1131 205.547 23.0537 219.405 34.164 229.469C45.2742 239.533 59.7493 245.073 74.7397 244.999"
                // stroke="#E2E8F0"
                stroke="currentColor"
                strokeWidth="15"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_18_1063">
          <rect width="419" height="253" /* fill="white" */ fill="currentColor" />
        </clipPath>
        <clipPath id="clip1_18_1063">
          <rect width="419" height="253" /* fill="white" */ fill="currentColor" />
        </clipPath>
        <clipPath id="clip2_18_1063">
          <rect width="178" height="178" /* fill="white" */ fill="currentColor" transform="translate(8 -1)" />
        </clipPath>
        <clipPath id="clip3_18_1063">
          <rect width="412" height="245" /* fill="white" */ fill="currentColor" transform="translate(7 8)" />
        </clipPath>
        <clipPath id="clip4_18_1063">
          <rect
            width="214.3"
            height="140.1"
            /* fill="white" */ fill="currentColor"
            transform="translate(202.85 46.0098)"
          />
        </clipPath>
        <clipPath id="clip5_18_1063">
          <rect
            width="359"
            height="231"
            /* fill="white" */ fill="currentColor"
            transform="translate(7.33984 21.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
