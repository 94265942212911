import { Switch } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import type { TPermission } from '@semios/app-platform-value-type-definitions'
import type { TOptions } from 'components/MultiSelect/MultiSelect.types'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { colors } from 'settings/colors'
import { checkAuthorization } from 'utils/checkAuthorization'
import type { TReportStartEnd } from '../../../_utils/formTypesAndStyles'
import { getDateFromReport } from '../../../_utils/getDateFromReport'
import { PropertiesSubtitle } from './PropertiesSubtitle'

export const Weather = ({
  weather,
  setWeather,
  weatherStartEnd,
  setWeatherStartEnd,
  selectedReportProperties,
}: {
  weather: boolean
  setWeather: Dispatch<SetStateAction<boolean>>
  weatherStartEnd: TReportStartEnd | null
  setWeatherStartEnd: Dispatch<SetStateAction<TReportStartEnd | null>>
  selectedReportProperties: TOptions[]
}) => {
  const filteredPropertiesByPermission = Object.values(selectedReportProperties).filter(
    (property: TOptions) => {
      return checkAuthorization({
        permission: 'CREATE_REPORT_WEATHER' as TPermission,
        entity: Number(property.value),
      })
    },
  )

  const propertyIds = selectedReportProperties
    ? Object.values(selectedReportProperties).map((obj) => obj.value as string)
    : null

  if (filteredPropertiesByPermission.length < 1) {
    return null
  }

  return (
    <div css={{ paddingBottom: '25px', borderBottom: `1px solid ${colors.grey200}` }}>
      <h4 css={{ fontWeight: '500' }}>
        {translate.phrases.banyanApp('Weather')}
        {PropertiesSubtitle({
          permission: 'CREATE_REPORT_WEATHER' as TPermission,
          propertyIds: propertyIds,
        })}
      </h4>
      <div>
        <Switch
          size="md"
          color="lime"
          label={translate.phrases.banyanApp('Include the weather forecast in the report')}
          styles={{
            label: { cursor: 'unset', fontSize: 16 },
            track: {
              width: 36,
              cursor: 'unset',
              backgroundColor: weather ? `${colors.green} !important` : undefined,
              borderColor: weather ? `${colors.green} !important` : undefined,
            },
          }}
          checked={weather}
          onChange={() => setWeather(!weather)}
        />
      </div>
      <div css={{ marginTop: '15px', display: 'flex' }}>
        <DatePickerInput
          valueFormat={translate.dates.getMomentFormat('MMM D')}
          clearable={false}
          placeholder={translate.phrases.banyanApp('Select start date')}
          monthLabelFormat={translate.dates.getMomentFormat('MMM')}
          value={getDateFromReport(weatherStartEnd?.startDate)}
          onChange={(e) =>
            setWeatherStartEnd((prevState) => ({
              ...prevState,
              startDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
            }))
          }
          disabled={!weather}
          css={{ maxWidth: '100%', width: '240px', marginRight: '15px' }}
        />

        <DatePickerInput
          valueFormat={translate.dates.getMomentFormat('MMM D')}
          clearable={false}
          placeholder={translate.phrases.banyanApp('Select end date')}
          value={getDateFromReport(weatherStartEnd?.endDate)}
          monthLabelFormat={translate.dates.getMomentFormat('MMM')}
          onChange={(e) =>
            setWeatherStartEnd((prevState) => ({
              ...prevState,
              endDate: moment.tz(e, moment.tz.guess()).format('MM-DD'),
            }))
          }
          disabled={!weather}
          css={{ maxWidth: '100%', width: '240px' }}
        />
      </div>
    </div>
  )
}
