import { ActionIcon } from '@mantine/core'
import { IconInfo } from 'components/icons/IconInfo'
import { CloseIcon } from 'components/ModalDrawer/CloseIcon/CloseIcon'
import type { CSSProperties } from 'react'
import { useState } from 'react'
import { colors } from 'settings/colors'

export const InfoMessageBox = ({
  children,
  style,
  dismissable = false,
}: {
  children: React.ReactNode
  style?: CSSProperties
  dismissable?: boolean
}) => {
  const [isShown, setIsShown] = useState(true)

  if (!isShown && dismissable) {
    return <></>
  }

  return (
    <div
      css={{
        display: 'flex',
        background: colors.grey50,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        borderRadius: 3,
        padding: '12px 26px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...style,
      }}
    >
      <span css={{ color: colors.primary, marginRight: 12, fontSize: 20, marginTop: '0.2em' }}>
        <IconInfo />
      </span>
      <span css={{ fontWeight: 500, flex: 'auto' }}>{children}</span>
      {dismissable && (
        <ActionIcon onClick={() => setIsShown(false)}>
          <CloseIcon />
        </ActionIcon>
      )}
    </div>
  )
}
