import { IconControlBattery } from 'components/icons/IconControlsInfo/IconControlBattery'
import { IconControlFuel } from 'components/icons/IconControlsInfo/IconControlFuel'
import { IconControlRPM } from 'components/icons/IconControlsInfo/IconControlRPM'
import { IconControlTemperature } from 'components/icons/IconControlsInfo/IconControlTemperature'

const useIcon = (iconName: string) => {
  let theIcon = null

  switch (iconName) {
    case 'temperature':
      theIcon = <IconControlTemperature />

      break

    case 'rpm':
      theIcon = <IconControlRPM />

      break

    case 'fuel':
      theIcon = <IconControlFuel />

      break

    case 'battery':
      theIcon = <IconControlBattery />

      break

    default:
      break
  }

  return theIcon
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeviceInfoBlock = ({ data, icon }: { data: any; icon: any }) => {
  return (
    <div
      css={{
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'space-between',
        'alignItems': 'center',
        '@media (max-width: 600px)': {
          padding: '5px 10px',
        },
      }}
    >
      {useIcon(icon)}
      <p css={{ marginLeft: '10px', marginTop: '0px', marginBottom: '0px' }}>{data}</p>
    </div>
  )
}
