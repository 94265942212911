import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconBook = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 18')}>
      <path
        d="M10.5 17.5C9.8 16.9458 9.04167 16.5156 8.225 16.2094C7.40833 15.9031 6.5625 15.75 5.6875 15.75C5.075 15.75 4.47358 15.8302 3.88325 15.9906C3.29233 16.151 2.72708 16.3771 2.1875 16.6687C1.88125 16.8292 1.58608 16.8219 1.302 16.6469C1.01733 16.4719 0.875 16.2167 0.875 15.8812V5.3375C0.875 5.17708 0.91525 5.02396 0.99575 4.87812C1.07567 4.73229 1.19583 4.62292 1.35625 4.55C2.02708 4.2 2.72708 3.9375 3.45625 3.7625C4.18542 3.5875 4.92917 3.5 5.6875 3.5C6.53333 3.5 7.36108 3.60938 8.17075 3.82812C8.97983 4.04688 9.75625 4.375 10.5 4.8125V15.4C11.2437 14.9333 12.024 14.5833 12.8406 14.35C13.6573 14.1167 14.4812 14 15.3125 14C15.8375 14 16.3517 14.0437 16.8551 14.1312C17.358 14.2188 17.8646 14.35 18.375 14.525V4.025C18.5938 4.09792 18.809 4.17433 19.0208 4.25425C19.2319 4.33475 19.4396 4.43333 19.6438 4.55C19.8042 4.62292 19.9246 4.73229 20.0051 4.87812C20.085 5.02396 20.125 5.17708 20.125 5.3375V15.8812C20.125 16.2167 19.983 16.4719 19.6989 16.6469C19.4142 16.8219 19.1187 16.8292 18.8125 16.6687C18.2729 16.3771 17.7077 16.151 17.1168 15.9906C16.5264 15.8302 15.925 15.75 15.3125 15.75C14.4375 15.75 13.5917 15.9031 12.775 16.2094C11.9583 16.5156 11.2 16.9458 10.5 17.5ZM12.25 13.125V4.8125L16.625 0.4375V9.1875L12.25 13.125ZM8.75 14.5469V5.88438C8.26875 5.68021 7.76942 5.52358 7.252 5.4145C6.734 5.30483 6.2125 5.25 5.6875 5.25C5.14792 5.25 4.62292 5.30104 4.1125 5.40313C3.60208 5.50521 3.10625 5.65833 2.625 5.8625V14.5469C3.13542 14.3573 3.64233 14.2188 4.14575 14.1312C4.64858 14.0437 5.1625 14 5.6875 14C6.2125 14 6.72642 14.0437 7.22925 14.1312C7.73267 14.2188 8.23958 14.3573 8.75 14.5469Z"
        fill="currentColor"
      />
    </svg>
  )
}
