import { css } from '@emotion/css'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { translate } from 'i18n/i18n'
import type { TChillMarVsMay, TChillSepVsNov } from 'stores/detailsPanelStore'
import { detailsPanelStore } from 'stores/detailsPanelStore'
import type { TPointCategory } from 'stores/selectedFieldAssetsStore'
import type { TDropdownSelectorPointProps } from '../DropdownSelectorPoint/DropdownSelectorPoint'
import { DropdownSelectorPoint } from '../DropdownSelectorPoint/DropdownSelectorPoint'

const pointCategory: TPointCategory = 'weatherPoint'

export const ChillTitleChildren = (
  props: TDropdownSelectorPointProps & {
    isSouthernHemisphere: boolean
    showMonthsToggle: boolean
  },
) => {
  const { isSouthernHemisphere, showMonthsToggle, ...rest } = props

  const { data, monthChoosingKeyToUse, selectedMonth } = detailsPanelStore.useSelector((s) => {
    if (isSouthernHemisphere) {
      return {
        monthChoosingKeyToUse: 'chillMarVsMay' as 'chillMarVsMay' | 'chillSepVsNov',
        selectedMonth: s.chillMarVsMay,
        data: [
          { label: translate.dates.monthLabel('MAR'), value: 'MAR' as TChillMarVsMay },
          { label: translate.dates.monthLabel('MAY'), value: 'MAY' as TChillMarVsMay },
        ],
      }
    }

    return {
      monthChoosingKeyToUse: 'chillSepVsNov' as 'chillMarVsMay' | 'chillSepVsNov',
      selectedMonth: s.chillSepVsNov,
      data: [
        { label: translate.dates.monthLabel('SEP'), value: 'SEP' as TChillSepVsNov },
        { label: translate.dates.monthLabel('NOV'), value: 'NOV' as TChillSepVsNov },
      ],
    }
  })

  return (
    <div css={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '4px 12px' }}>
      <DropdownSelectorPoint pointCategory={pointCategory} {...rest} />
      {showMonthsToggle && (
        <ToggleButton
          buttonProps={{ size: 'xs', className: css({ fontSize: 13 }) }}
          data={data}
          onChange={(newValue) =>
            detailsPanelStore.setState((s) => ({ ...s, [monthChoosingKeyToUse]: newValue }))
          }
          value={selectedMonth}
        />
      )}
    </div>
  )
}
