import React, { forwardRef } from 'react'
import type { ButtonProps } from '../Button'
import { getButtonComponent } from '../_utils/getButtonComponent'
import { useAsyncButtonProps } from './_utils/useAsyncButtonProps'

const ButtonAsync = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { variant } = props
  const ButtonComponent = getButtonComponent(variant)
  const asyncButtonProps = useAsyncButtonProps(props)

  return <ButtonComponent ref={ref} {...asyncButtonProps} />
})

ButtonAsync.displayName = 'ButtonAsync'

export { ButtonAsync }
