import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { SettingsSearchNotFound } from 'App/Map/UserSettingsMenu/components/SettingsSearchNotFound/SettingsSearchNotFound'
import { useState } from 'react'
import { sortByKey } from 'utils/sortByKey'
import { EditZoneModal } from './EditZoneModal'
import { PropertySection } from './PropertySection'

export type Zone = {
  zoneName: string
  irrigationZoneId: number
}

const getZonesFromProperty = (property: SharedTypes.TProperty) => {
  return Object.values(
    Object.values(property.irrigationZoneEmitters ?? {}).reduce<
      Record<number, { zoneName: string; irrigationZoneId: number }>
    >((acc, { irrigationZoneId, name }) => {
      if (acc[irrigationZoneId]) return acc

      acc[irrigationZoneId] = {
        zoneName: name,
        irrigationZoneId,
      }

      return acc
    }, {}),
  )
}

export const ZonesTab = ({
  filterPropertiesText,
  properties,
}: {
  filterPropertiesText: string
  properties: SharedTypes.TProperty[]
}) => {
  const [selectedZone, setSelectedZone] = useState<(Zone & { propertyId: number }) | null>(null)

  const isSearchMatch = properties.some((property) => {
    const zones = getZonesFromProperty(property)

    return arrayOfObjectsSearch(zones, filterPropertiesText, ['zoneName']).length > 0
  })

  return (
    <div>
      {isSearchMatch ? (
        properties.map((property) => {
          const zones = getZonesFromProperty(property)

          return (
            zones.length && (
              <PropertySection
                key={property.propertyId}
                filterPropertiesText={filterPropertiesText}
                propertyId={property.propertyId}
                propertyName={property.propertyName}
                setSelectedZone={setSelectedZone}
                zones={zones.sort(sortByKey('zoneName'))}
              />
            )
          )
        })
      ) : (
        <SettingsSearchNotFound />
      )}
      <EditZoneModal setSelectedZone={setSelectedZone} selectedZone={selectedZone} />
    </div>
  )
}
