import { Table } from '@mantine/core'
import { userIsCelsius } from 'App/Map/UserSettingsMenu/Shared/Alerts/settings/alertSettings'
import { translate } from 'i18n/i18n'

const tableRowsMaker = (isCelsius: boolean) => {
  return [
    {
      event: translate.phrases.banyanApp('First Catch of Flight 1'),
      jan1Biofix: isCelsius ? 95 : 175,
      trapCatchBiofix: isCelsius ? 0 : 0,
    },
    {
      event: translate.phrases.banyanApp('1% Egg Hatch Flight 1'),
      jan1Biofix: isCelsius ? 220 : 395,
      trapCatchBiofix: isCelsius ? 120 : 220,
    },
    {
      event: translate.phrases.banyanApp('Peak Flight 1'),
      jan1Biofix: isCelsius ? 330 : 595,
      trapCatchBiofix: isCelsius ? 235 : 420,
    },
    {
      event: translate.phrases.banyanApp('Start of Flight 2'),
      jan1Biofix: isCelsius ? 575 : 1035,
      trapCatchBiofix: isCelsius ? 475 : 860,
    },
    {
      event: translate.phrases.banyanApp('1% Egg Hatch Flight 2'),
      jan1Biofix: isCelsius ? 710 : 1275,
      trapCatchBiofix: isCelsius ? 610 : 1100,
    },
    {
      event: translate.phrases.banyanApp('Peak Flight 2'),
      jan1Biofix: isCelsius ? 965 : 1735,
      trapCatchBiofix: isCelsius ? 950 : 1715,
    },
    {
      event: translate.phrases.banyanApp('Start of Flight 3'),
      jan1Biofix: isCelsius ? 1165 : 2095,
      trapCatchBiofix: isCelsius ? 1065 : 1920,
    },
    {
      event: translate.phrases.banyanApp('1% Egg Hatch Flight 3'),
      jan1Biofix: isCelsius ? 1300 : 2335,
      trapCatchBiofix: isCelsius ? 1200 : 2160,
    },
  ]
}

export const CodlingMothGuide = () => {
  const isCelsius = userIsCelsius()
  const data = tableRowsMaker(isCelsius)

  if (!data || !data.length) return null

  const rows = tableRowsMaker(isCelsius).map((row) => {
    return (
      <tr key={row.event}>
        <td>{row.event}</td>
        <td>{row.jan1Biofix}</td>
        <td>{row.trapCatchBiofix}</td>
      </tr>
    )
  })

  return (
    <div>
      <p css={{ fontSize: 14, fontWeight: 'bold', maxWidth: 440, textAlign: 'center' }}>
        {translate.phrases.banyanApp(
          'Threshold according to Washington State University degree days model for codling moth',
        )}
      </p>
      <Table horizontalSpacing="md">
        <thead>
          <tr>
            <th>{translate.phrases.banyanApp('Event')}</th>
            <th>{translate.phrases.banyanApp('Jan 1 Biofix')}</th>
            <th>{translate.phrases.banyanApp('Trap Catch Biofix')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  )
}
