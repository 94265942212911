import type { TMonthIndexNumber } from '@semios/app-platform-i18n-core'
import { translate } from 'i18n/i18n'
import { isNil } from 'lodash'
import { userDetailsStore } from 'stores/userDetailsStore'
import type { TUnitConverterOptionsWithoutUnits, TUnitConverterTypicalReturn } from '../types'

const DEFAULT_DECIMAL_PLACES_CHILL_HOURS = 0
const DEFAULT_DECIMAL_PLACES_CHILL_PORTIONS = 1

const chillHoursBetween32FAnd45FFunctionMaker =
  (monthNumberFrom0To11: TMonthIndexNumber) =>
  (
    chillHoursBetween32FAnd45F: number | null = null,
    { decimalPlaces = DEFAULT_DECIMAL_PLACES_CHILL_HOURS }: TUnitConverterOptionsWithoutUnits = {},
  ) => {
    let convertedValue: number | null

    if (isNil(chillHoursBetween32FAnd45F)) {
      convertedValue = null
    } else {
      convertedValue = chillHoursBetween32FAnd45F
    }

    return {
      categoryTitle: () => translate.phrases.banyanApp('Chill'),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Chill'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_CHILL_HOURS,
      suffix: () => {
        return ''
      },
      shortTitle: () => {
        const isMetric = userDetailsStore.getState().temperature === 'METRIC'

        return translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Chill Hours'),
          labelB: translate.phrases.templates('{{valueMin}} - {{valueMax}} {{unitSymbol}}', {
            valueMin: isMetric ? 0 : 32,
            valueMax: isMetric ? 7 : 45,
            unitSymbol: isMetric
              ? translate.measurements.degreesCelsius.unit()
              : translate.measurements.degreesFahrenheit.unit(),
          }),
        })
      },
      title: () => {
        const isMetric = userDetailsStore.getState().temperature === 'METRIC'

        return translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
          labelA: translate.phrases.banyanApp('Chill Hours'),
          labelB: translate.phrases.templates('{{valueMin}} - {{valueMax}} {{unitSymbol}}', {
            valueMin: isMetric ? 0 : 32,
            valueMax: isMetric ? 7 : 45,
            unitSymbol: isMetric
              ? translate.measurements.degreesCelsius.unit()
              : translate.measurements.degreesFahrenheit.unit(),
          }),
          labelC: translate.dates.monthLabel(monthNumberFrom0To11),
        })
      },
      titleWithoutUnit: () => {
        const isMetric = userDetailsStore.getState().temperature === 'METRIC'

        return translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
          labelA: translate.phrases.banyanApp('Chill Hours'),
          labelB: translate.phrases.templates('{{valueMin}} - {{valueMax}} {{unitSymbol}}', {
            valueMin: isMetric ? 0 : 32,
            valueMax: isMetric ? 7 : 45,
            unitSymbol: isMetric
              ? translate.measurements.degreesCelsius.unit()
              : translate.measurements.degreesFahrenheit.unit(),
          }),
          labelC: translate.dates.monthLabel(monthNumberFrom0To11),
        })
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        if (isNil(convertedValue)) return translate.phrases.templates('-')

        return convertedValue?.toFixed(decimalPlaces)
      },
      valueWithSuffix: () => {
        if (isNil(convertedValue)) return translate.phrases.templates('-')

        return convertedValue?.toFixed(decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

type TChillHoursBetween32And45FOptions =
  | 'chillCumulativeHoursBetween32and45FMAR'
  | 'chillCumulativeHoursBetween32and45FMAY'
  | 'chillCumulativeHoursBetween32and45FNOV'
  | 'chillCumulativeHoursBetween32and45FSEP'

const chillHoursBetween32FAnd45F: Record<
  TChillHoursBetween32And45FOptions,
  (
    chillHoursBetween32FAnd45F?: number | null,
    options?: TUnitConverterOptionsWithoutUnits,
  ) => TUnitConverterTypicalReturn
> = {
  chillCumulativeHoursBetween32and45FMAR: chillHoursBetween32FAnd45FFunctionMaker(2),
  chillCumulativeHoursBetween32and45FMAY: chillHoursBetween32FAnd45FFunctionMaker(4),
  chillCumulativeHoursBetween32and45FSEP: chillHoursBetween32FAnd45FFunctionMaker(8),
  chillCumulativeHoursBetween32and45FNOV: chillHoursBetween32FAnd45FFunctionMaker(10),
}

const chillHoursBelow45FFunctionMaker =
  (monthNumberFrom0To11: TMonthIndexNumber) =>
  (
    chillHoursBelow45F: number | null = null,
    { decimalPlaces = DEFAULT_DECIMAL_PLACES_CHILL_HOURS }: TUnitConverterOptionsWithoutUnits = {},
  ) => {
    let convertedValue: number | null

    if (isNil(chillHoursBelow45F)) {
      convertedValue = null
    } else {
      convertedValue = chillHoursBelow45F
    }

    return {
      categoryTitle: () => translate.phrases.banyanApp('Chill'),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Chill'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_CHILL_HOURS,
      suffix: () => {
        return ''
      },
      shortTitle: () => {
        const isMetric = userDetailsStore.getState().temperature === 'METRIC'

        return translate.phrases.templates('{{labelA}} ({{labelB}})', {
          labelA: translate.phrases.banyanApp('Chill Hours'),
          labelB: translate.phrases.templates('< {{value}} {{unitSymbol}}', {
            value: isMetric ? 7 : 45,
            unitSymbol: isMetric
              ? translate.measurements.degreesCelsius.unit()
              : translate.measurements.degreesFahrenheit.unit(),
          }),
        })
      },
      title: () => {
        const isMetric = userDetailsStore.getState().temperature === 'METRIC'

        return translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
          labelA: translate.phrases.banyanApp('Chill Hours'),
          labelB: translate.phrases.templates('< {{value}} {{unitSymbol}}', {
            value: isMetric ? 7 : 45,
            unitSymbol: isMetric
              ? translate.measurements.degreesCelsius.unit()
              : translate.measurements.degreesFahrenheit.unit(),
          }),
          labelC: translate.dates.monthLabel(monthNumberFrom0To11),
        })
      },
      titleWithoutUnit: () => {
        const isMetric = userDetailsStore.getState().temperature === 'METRIC'

        return translate.phrases.templates('{{labelA}} ({{labelB}}) {{labelC}}', {
          labelA: translate.phrases.banyanApp('Chill Hours'),
          labelB: translate.phrases.templates('< {{value}} {{unitSymbol}}', {
            value: isMetric ? 7 : 45,
            unitSymbol: isMetric
              ? translate.measurements.degreesCelsius.unit()
              : translate.measurements.degreesFahrenheit.unit(),
          }),
          labelC: translate.dates.monthLabel(monthNumberFrom0To11),
        })
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        if (isNil(convertedValue)) return translate.phrases.templates('-')

        return convertedValue?.toFixed(decimalPlaces)
      },
      valueWithSuffix: () => {
        if (isNil(convertedValue)) return translate.phrases.templates('-')

        return convertedValue?.toFixed(decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

type TChillHoursBelow45FOptions =
  | 'chillCumulativeHoursBelow45FMAR'
  | 'chillCumulativeHoursBelow45FMAY'
  | 'chillCumulativeHoursBelow45FNOV'
  | 'chillCumulativeHoursBelow45FSEP'

const chillHoursBelow45F: Record<
  TChillHoursBelow45FOptions,
  (
    chillHoursBelow45F?: number | null,
    options?: TUnitConverterOptionsWithoutUnits,
  ) => TUnitConverterTypicalReturn
> = {
  chillCumulativeHoursBelow45FMAR: chillHoursBelow45FFunctionMaker(2),
  chillCumulativeHoursBelow45FMAY: chillHoursBelow45FFunctionMaker(4),
  chillCumulativeHoursBelow45FSEP: chillHoursBelow45FFunctionMaker(8),
  chillCumulativeHoursBelow45FNOV: chillHoursBelow45FFunctionMaker(10),
}

const chillPortionsFunctionMaker =
  (monthNumberFrom0To11: TMonthIndexNumber) =>
  (
    chillPortions: number | null = null,
    { decimalPlaces = DEFAULT_DECIMAL_PLACES_CHILL_PORTIONS }: TUnitConverterOptionsWithoutUnits = {},
  ) => {
    let convertedValue: number | null

    if (isNil(chillPortions)) {
      convertedValue = null
    } else {
      convertedValue = chillPortions
    }

    return {
      categoryTitle: () => translate.phrases.banyanApp('Chill'),
      categoryTitleWithoutUnit: () => translate.phrases.banyanApp('Chill'),
      defaultNumberOfDecimalPlaces: () => DEFAULT_DECIMAL_PLACES_CHILL_PORTIONS,
      suffix: () => {
        return ''
      },
      shortTitle: () => {
        return translate.phrases.banyanApp('Chill Portions')
      },
      title: () => {
        return translate.phrases.templates('{{labelA}} {{labelB}}', {
          labelA: translate.phrases.banyanApp('Chill Portions'),
          labelB: translate.dates.monthLabel(monthNumberFrom0To11),
        })
      },
      titleWithoutUnit: () => {
        return translate.phrases.templates('{{labelA}} {{labelB}}', {
          labelA: translate.phrases.banyanApp('Chill Portions'),
          labelB: translate.dates.monthLabel(monthNumberFrom0To11),
        })
      },
      value: () => {
        if (isNil(convertedValue)) return null

        return +convertedValue?.toFixed(decimalPlaces)
      },
      valueAsString: () => {
        if (isNil(convertedValue)) return translate.phrases.templates('-')

        return convertedValue?.toFixed(decimalPlaces)
      },
      valueWithSuffix: () => {
        if (isNil(convertedValue)) return translate.phrases.templates('-')

        return convertedValue?.toFixed(decimalPlaces)
      },
      valueWithNoRounding: () => convertedValue,
    }
  }

type TChillPortionsOptions =
  | 'chillCumulativePortionsMAR'
  | 'chillCumulativePortionsMAY'
  | 'chillCumulativePortionsNOV'
  | 'chillCumulativePortionsSEP'

const chillPortions: Record<
  TChillPortionsOptions,
  (
    chillHoursBelow45F?: number | null,
    options?: TUnitConverterOptionsWithoutUnits,
  ) => TUnitConverterTypicalReturn
> = {
  chillCumulativePortionsMAR: chillPortionsFunctionMaker(2),
  chillCumulativePortionsMAY: chillPortionsFunctionMaker(4),
  chillCumulativePortionsSEP: chillPortionsFunctionMaker(8),
  chillCumulativePortionsNOV: chillPortionsFunctionMaker(10),
}

export const chill = {
  ...chillHoursBetween32FAnd45F,
  ...chillHoursBelow45F,
  ...chillPortions,
}
