import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconMapControls = () => (
  <svg {...makeBaseSvgIconProps('0 0 15 15')}>
    <path
      d="M14.5833 0L14.45 0.025L10 1.75L5 0L0.3 1.58333C0.125 1.64167 0 1.79167 0 1.98333V14.5833C0 14.8167 0.183333 15 0.416667 15L0.55 14.975L5 13.25L10 15L14.7 13.4167C14.875 13.3583 15 13.2083 15 13.0167V0.416667C15 0.183333 14.8167 0 14.5833 0ZM10 13.3333L5 11.575V1.66667L10 3.425V13.3333Z"
      fill="currentColor"
    />
  </svg>
)
