import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { DropdownSelectorIrrigationZone } from 'App/Map/PanelDetails/SectionTitleBars/DropdownSelectorIrrigationZone/DropdownSelectorIrrigationZone'
import { getTimezoneForSelectedPropertyOrRegion } from 'App/Map/PanelDetails/_utils/getTimezoneForSelectedPropertyOrRegion'
import { propertyLacksPermissionSectionMaker } from 'App/Map/PanelDetails/_utils/propertyLacksPermissionSectionMaker'
import { selectedPropertyHasPermission } from 'App/Map/PanelDetails/_utils/selectedPropertyHasPermission'
import type { StackedChartSection } from 'components/StackedChart/types'
import { translate } from 'i18n/i18n'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import type { TSelectedFieldAssetsStoreState } from 'stores/selectedFieldAssetsStore'
import { doesSelectedPointHaveValueTypes } from '../../../../../../../../utils/doesSelectedFieldAssetHaveValueTypes'
import { chooseAmongstUnAggHourlyAndDaily } from '../../../chooseAmongstUnAggHourlyAndDaily'
import { zoneEmitterLacksPermissionSectionMaker } from '../_utils/zoneEmitterLacksPermissionSectionMaker'
import { getActivityIntervalsFromData } from './_utils/getActivityIntervalsFromData'
import { getChartDataWithPressureAndActivity } from './_utils/getChartDataWithPressureAndActivity'

const checkPermission = () => selectedPropertyHasPermission({ permission: 'VIEW_IRRIGATION_DETAILS' })

export const pressureSensorsChart = ({
  data,
  selectedFieldAssets,
}: {
  data: routes.Values.Response
  selectedFieldAssets: TSelectedFieldAssetsStoreState
}): StackedChartSection | null => {
  const title = translate.phrases.templates('{{labelA}} - {{labelB}}', {
    labelA: translate.phrases.banyanApp('Irrigation Activity by Pressure Sensors'),
    labelB: translate.phrases.banyanApp('Semios'),
  })

  const commonReturnItems = {
    title,
    titleChildren: <DropdownSelectorIrrigationZone key={'irrigationZonesDropdown'} isPressureSensor={true} />,
    id: 'stackem-pressure-sensors-chart',
  }

  if (!checkPermission()) return propertyLacksPermissionSectionMaker(commonReturnItems)

  const property = fieldAssetStore.getState().properties?.[Number(selectedFieldAssets.property)]

  const ptPoint =
    selectedFieldAssets.pressureTransducerPoint &&
    property?.points?.[selectedFieldAssets.pressureTransducerPoint]

  if (
    !ptPoint ||
    !doesSelectedPointHaveValueTypes({
      valuesTimeseries: ptPoint.valuesTimeseries,
      pointCategory: 'pressureTransducerPoint',
    })
  )
    return zoneEmitterLacksPermissionSectionMaker({
      ...commonReturnItems,
      name: 'zone',
    })

  const activityIntervals = getActivityIntervalsFromData({
    data,
    selectedFieldAssets,
  })

  if (!Object.keys(activityIntervals).length)
    return zoneEmitterLacksPermissionSectionMaker({
      ...commonReturnItems,
      name: 'zone',
    })

  const { property: propertyId } = selectedFieldAssets
  const requestedAggregationInterval = chooseAmongstUnAggHourlyAndDaily()

  const timezone = getTimezoneForSelectedPropertyOrRegion({
    properties: fieldAssetStore.getState()?.properties,
    propertyId,
  })

  const showPressure = requestedAggregationInterval !== 'DAILY'

  const pressureSensorsChart = getChartDataWithPressureAndActivity({
    activityIntervals,
    property: propertyId,
    data,
    selectedFieldAssets,
    timezone,
    showPressure,
    title,
  })

  //@ts-ignore
  return pressureSensorsChart
}
