import { Button, useMantineTheme } from '@mantine/core'
import { hoverCssKey } from 'components/Button/_utils/getButtonComponent'
import React, { forwardRef } from 'react'
import type { MantineButtonProps } from '../../Button'

const Primary = forwardRef<HTMLButtonElement, MantineButtonProps>((props, ref) => {
  const theme = useMantineTheme()

  return (
    <Button
      ref={ref}
      css={{
        'background': theme.fn.linearGradient(90, theme.colors.primary[0], theme.colors.primary[1]),
        'height': 40,
        'padding': '0 15px',
        'border': 'none',
        'fontStyle': 'normal',
        'fontWeight': 600,
        'fontSize': 16,
        'lineHeight': '21px',
        'color': theme.colors.white[0],
        [`&:not([disabled],[data-loading="true"]):${hoverCssKey}`]: {
          background: theme.colors.primary[0],
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        },
        '&[disabled],&[data-loading="true"]': {
          background: theme.colors.grey[1],
          color: theme.colors.white[0],
        },
        '&[data-loading="true"]::before': {
          background: 'unset',
        },
      }}
      {...props}
    />
  )
})

Primary.displayName = 'Primary'

export { Primary }
