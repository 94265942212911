import Panel from 'components/SidePanel/Panel/Panel'
import type CssType from 'csstype'
import type { Dispatch } from 'react'
import React, { useState } from 'react'
import { useScreenSize } from 'utils/useScreenSize'
import { smallStore } from '../../../stores/smallStore'
import type { TabPane } from '../DetailPanel'

export default function Right(props: {
  viewportSize: { height: number; width: number }
  allowDragContentForTouch?: boolean
  handleSize: number
  children?: ({
    width,
    height,
    isPanelExpanded,
  }: {
    width: number
    height: number
    isPanelExpanded: boolean
  }) => React.ReactNode
  backgroundColor: CssType.Property.BackgroundColor
  debugShowSnapPointsOnDrag: boolean
  isPanelExpanded: boolean
  setIsPanelExpanded: Dispatch<React.SetStateAction<boolean>>
  tabs?: TabPane[]
}) {
  const { viewportSize, allowDragContentForTouch, handleSize, isPanelExpanded, setIsPanelExpanded } = props
  const safeArea = smallStore.useSelector((s) => s.safeArea)
  const x1Start = Math.round(viewportSize.width)
  const x2Start = Math.round(viewportSize.width - 5)
  const y1 = 5 + safeArea.insets.top
  const y2 = viewportSize.height - 5 - safeArea.insets.bottom
  // ^ use those in the `sizes=` below too
  const height = y2 - y1
  const [width, setWidth] = useState<number>(Math.round(x2Start - x1Start - handleSize))
  const { isWideScreen } = useScreenSize()

  return (
    <Panel
      backgroundColor={props.backgroundColor}
      handleSize={handleSize}
      borderRadius="10px"
      tabs={props.tabs}
      placement="right"
      x1Start={x1Start}
      x2Start={x2Start}
      sizes={{
        y1,
        y2,
        x1: [isWideScreen ? Math.round(viewportSize.width / 2) : 190, x1Start],
        x2: x2Start,
      }}
      sizesSnapping={{
        y1: false,
        y2: false,
        x1: false,
        x2: false,
      }}
      debugShowSnapPointsOnDrag={props.debugShowSnapPointsOnDrag}
      allowDragContentForTouch={allowDragContentForTouch}
      onCoordsChange={(args) => setWidth(args.visibleWidth)}
      setIsPanelExpanded={setIsPanelExpanded}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: width + handleSize,
          height: '100%',
        }}
      >
        {props.children && props.children({ width: width + handleSize, height, isPanelExpanded })}
      </div>
    </Panel>
  )
}
