import { isNil } from 'lodash'
import type { RefObject } from 'react'
import { useEffect } from 'react'
import type { M_ITEM } from 'stores/mapControlsStore/types'

type TSelectionState = 'base' | 'search' | 'selected' | 'hovered'

const stateZIndexes: Record<TSelectionState, number> = {
  base: 10,
  search: 20,
  selected: 30,
  hovered: 40,
}

const mItemZIndexes: Partial<Record<M_ITEM, number>> = {
  M_CurrentLocation: 50,
  M_Property: 3,
  M_InBlockPoint: 2,
  M_Block: 1,
  M_Trap: 3,
  M_Region: 10,
}

export const useSetMapOverlayZIndex = ({
  baseZIndex,
  ref,
  mItem,
  isSelected,
  isHighlighted,
  isHovered,
}: {
  baseZIndex?: number
  ref: RefObject<HTMLDivElement>
  mItem: M_ITEM
  isSelected?: boolean
  isHighlighted?: boolean
  isHovered?: boolean
}) => {
  return useEffect(() => {
    if (ref.current) {
      const reactOverlayDiv = ref.current.parentElement as HTMLDivElement

      let selectionState: TSelectionState = 'base'

      if (isHovered) selectionState = 'hovered'
      else if (isSelected) selectionState = 'selected'
      else if (isHighlighted) selectionState = 'search'

      const stateZIndex = stateZIndexes[selectionState]
      const mItemZIndex = mItemZIndexes[mItem] || 0

      reactOverlayDiv.style.zIndex = (stateZIndex + (isNil(baseZIndex) ? mItemZIndex : baseZIndex)).toString()
    }
  }, [isSelected, isHighlighted, isHovered, mItem])
}
