import { Button } from 'components/Button/Button'
import { detailsPanelStore } from 'stores/detailsPanelStore'

export const ClickHereLink = ({ children }: { children: React.ReactNode }) => {
  return (
    <Button
      variant="link"
      css={{ padding: 0, fontSize: '12px' }}
      onClick={() => {
        detailsPanelStore.setState((s) => ({
          ...s,
          soilSettingsOpened: true,
        }))
      }}
    >
      {children}
    </Button>
  )
}
