import type { routes, SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents } from 'App/irrigation-scheduler/components/right-panels/utils/getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents'
import { irrigationSchedulerStore } from 'App/irrigation-scheduler/utils/store/irrigationSchedulerStore'
import moment from 'moment-timezone'
import { fieldAssetStore } from 'stores/fieldAssetStore'
import { mapApiScheduledEventStatusToTScheduledEventEnum } from '../../mapApiScheduledEventStatusToTScheduledEventEnum'
import { getSelectedPropertyTimezoneForIrrigationScheduler } from '../selectors/getSelectedPropertyTimezoneForIrrigationScheduler'

export const pulseEventsCreate = (
  apiEvents: Exclude<
    routes.IrrigationSchedulerPulseEventsCreate.Response,
    SharedTypes.IRRIGATION_SCHEDULER_ERROR
  >,
) => {
  irrigationSchedulerStore.setState((isss) => {
    const propertyTimezone = getSelectedPropertyTimezoneForIrrigationScheduler(
      fieldAssetStore.getState(),
      isss,
    )

    const updatedScheduledPulseIrrigationEvents = apiEvents.reduce(
      (acc, apiEvent) => {
        const { earliestDateFrom, latestDateTo } =
          getEarliestDateFromAndLatestDateToFromPulseIrrigationEvents(
            apiEvent.pulseIrrigationEvents,
            propertyTimezone,
          )

        return {
          ...acc,
          [apiEvent.pulseEventId]: {
            dateFrom: earliestDateFrom,
            dateTo: latestDateTo,
            emitterType: apiEvent.emitterType,
            irrigationZoneEmitterId: apiEvent.irrigationZoneEmitterId,
            pulseEventId: apiEvent.pulseEventId.toString(),
            type: mapApiScheduledEventStatusToTScheduledEventEnum(apiEvent.status),
            irrigationZoneId: apiEvent.irrigationZoneId.toString(),
            pulseIrrigationConfig: apiEvent.pulseIrrigationConfig,
            pulseIrrigationEvents: apiEvent.pulseIrrigationEvents.map((event) => {
              return {
                dateFrom: moment.tz(event.dateFrom, propertyTimezone),
                dateTo: moment.tz(event.dateTo, propertyTimezone),
                eventId: event.eventId.toString(),
                externalEventId: event.externalEventId?.toString(),
                type: mapApiScheduledEventStatusToTScheduledEventEnum(event.status),
              }
            }),
          },
        }
      },
      { ...isss.scheduledPulseIrrigationEvents },
    )

    return {
      ...isss,
      scheduledPulseIrrigationEvents: updatedScheduledPulseIrrigationEvents,
    }
  })
}
