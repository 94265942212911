import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionIcon, TextInput, useMantineTheme } from '@mantine/core'
import { IconClose } from 'components/icons/IconClose'
import { translate } from 'i18n/i18n'
import type { FC } from 'react'
import { useCallback } from 'react'
import { blurOnEnterKeyUp } from 'utils/blurOnEnterKeyUp'
import type { TSearchTextInputProps } from '../../App/Map/UserSettingsMenu/types'

export const SearchTextInput: FC<TSearchTextInputProps> = ({
  searchText,
  setSearchText,
  placeholder,
  clearable,
}) => {
  const theme = useMantineTheme()

  const searchInputCallbackRef = useCallback((searchInput: HTMLInputElement) => {
    // TODO: This is a workaround to prevent input focus on load, but would be nice to prevent autofocus in a simpler way
    if (searchInput) setTimeout(() => searchInput.blur(), 50)
  }, [])

  return (
    <TextInput
      css={{
        '.mantine-TextInput-input:focus-within': {
          borderColor: theme.colors.grey[1],
        },
      }}
      placeholder={placeholder ? placeholder : translate.phrases.banyanApp('Search')}
      rightSection={
        clearable && searchText.length > 0 ? (
          <ActionIcon variant="transparent" onClick={() => setSearchText('')}>
            <IconClose />
          </ActionIcon>
        ) : (
          <FontAwesomeIcon css={{ fontSize: 16, color: theme.colors.grey[3] }} icon={faSearch} />
        )
      }
      value={searchText}
      onChange={(event) => setSearchText(event.target.value)}
      onKeyUp={blurOnEnterKeyUp}
      ref={searchInputCallbackRef}
      spellCheck="false"
    />
  )
}
