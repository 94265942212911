import { openModal } from '@mantine/modals'
import { AppInfo } from 'App/Map/LayerNav/Debug/components/AppInfo'
import { openQueuedRequestsModal } from 'App/ServiceCenter/QueuedRequestsModal/QueuedRequestsModal'
import { Authorization } from 'components/Authorization/Authorization'
import { Divider } from 'components/SideNav/Divider/Divider'
import { IconAndTitle } from 'components/SideNav/IconAndTitle/IconAndTitle'
import { SectionTitle } from 'components/SideNav/SectionTitle/SectionTitle'
import { userDetailsStore } from 'stores/userDetailsStore'
import { isSemiosEmployeeOrTester } from 'utils/isSemiosEmployeeOrTester'

export const Debug: React.FC = () => {
  const isEmployeeOrTester = userDetailsStore.useSelector((s) => isSemiosEmployeeOrTester(s))

  if (!isEmployeeOrTester) return null

  return (
    <>
      <Divider />
      <SectionTitle>{'DEBUG'}</SectionTitle>
      <IconAndTitle
        onClick={() =>
          openModal({
            modalId: 'appInfo',
            title: 'App Info',
            fullScreen: false,
            children: <AppInfo />,
          })
        }
        title={'App Info'}
      />
      <Authorization requires={{ permission: 'EDIT_SERVICE_CENTER', entity: 'FOR_ANY_ENTITY' }}>
        <IconAndTitle onClick={() => openQueuedRequestsModal()} title={'Pending Requests'} />
      </Authorization>
    </>
  )
}
