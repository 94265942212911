import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconThumbUp = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 30 30')}>
      <path
        d="M19.7072 21.4763H12.429C11.5395 21.4763 10.8117 20.7342 10.8117 19.8273V11.5824C10.8117 11.1289 10.9896 10.7166 11.2807 10.4198L16.6099 4.98642L17.4671 5.85214C17.6855 6.07475 17.823 6.38806 17.823 6.7261L17.7987 6.98994L17.0305 10.7579H22.1332C23.0228 10.7579 23.7506 11.4999 23.7506 12.4068V14.0558C23.7506 14.2702 23.7102 14.4681 23.6374 14.6577L21.1952 20.4704C20.9526 21.064 20.3784 21.4763 19.7072 21.4763Z"
        fill="#5C5C5C"
      />
      <path d="M9.19431 11.5824V21.4763H5.95958V11.5824H9.19431Z" fill="#5C5C5C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15ZM0.75 15C0.75 7.12994 7.12994 0.75 15 0.75C22.8701 0.75 29.25 7.12994 29.25 15C29.25 22.8701 22.8701 29.25 15 29.25C7.12994 29.25 0.75 22.8701 0.75 15Z"
        fill="#5C5C5C"
      />
    </svg>
  )
}
