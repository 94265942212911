import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconHurricane = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 202 304')}>
      <g clipPath="url(#clip0_11_506)">
        <path
          d="M45.05 12.8701L41.22 20.5701C19.2456 64.6974 9.78622 114.004 13.87 163.13M157 291.13L160.83 283.43C182.804 239.303 192.264 189.997 188.18 140.87M189 152C189 169.405 183.839 186.419 174.169 200.89C164.5 215.362 150.756 226.641 134.676 233.302C118.596 239.962 100.902 241.705 83.832 238.309C66.7617 234.914 51.0816 226.533 38.7746 214.226C26.4676 201.919 18.0864 186.238 14.6909 169.168C11.2954 152.098 13.0381 134.404 19.6986 118.324C26.3591 102.244 37.6383 88.5004 52.1098 78.8308C66.5813 69.1612 83.5952 64.0001 101 64.0001C124.339 64.0001 146.722 73.2715 163.225 89.7747C179.729 106.278 189 128.661 189 152Z"
          stroke="url(#paint0_linear_11_506)"
          strokeWidth="24"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11_506"
          x1="20.77"
          y1="13.0301"
          x2="181.23"
          y2="290.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4D7DD" />
          <stop offset="0.45" stopColor="#D4D7DD" />
          <stop offset="1" stopColor="#BEC1C6" />
        </linearGradient>
        <clipPath id="clip0_11_506">
          <rect width="202" height="304" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
