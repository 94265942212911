type MAP_ITEM<META, VALUE, OVERLAY> = {
  id: string
  meta: META
  value: Record<string, VALUE>
  overlay?: OVERLAY
}

type CREATE_OVERLAY<META, OVERLAY> = (o: {
  maps: typeof google.maps
  map: google.maps.Map
  meta: META
}) => OVERLAY

type RENDER_OVERLAY<META, OVERLAY, VALUE> = (o: {
  maps: typeof google.maps
  map: google.maps.Map
  meta: META
  value: VALUE
  overlay: OVERLAY
}) => void

type HIDE_OVERLAY<META, OVERLAY> = (o: { meta: META; overlay: OVERLAY }) => void

// TODO "hasLocation" logic for caching?

export class MapItemsCache<META, VALUE, OVERLAY> {
  readonly map: google.maps.Map
  readonly maps: typeof google.maps
  readonly items: Record<string, MAP_ITEM<META, VALUE, OVERLAY>>
  readonly createOverlay: CREATE_OVERLAY<META, OVERLAY>
  readonly renderOverlay: RENDER_OVERLAY<META, OVERLAY, VALUE>
  readonly hideOverlay: HIDE_OVERLAY<META, OVERLAY>

  constructor(options: {
    createOverlay: CREATE_OVERLAY<META, OVERLAY>
    renderOverlay: RENDER_OVERLAY<META, OVERLAY, VALUE>
    hideOverlay: HIDE_OVERLAY<META, OVERLAY>
    maps: typeof google.maps
    map: google.maps.Map
  }) {
    this.createOverlay = options.createOverlay

    this.renderOverlay = options.renderOverlay

    this.hideOverlay = options.hideOverlay

    this.maps = options.maps

    this.map = options.map

    this.items = {}
  }

  addItem(o: MAP_ITEM<META, VALUE, OVERLAY>) {
    this.items[o.id] = o
  }

  getItemsToProcess(o: { cacheKey: string; shouldShowItem: (o: { meta: META }) => boolean }) {
    const itemsWithinViewThatDontHaveValuesYet: Array<{ id: string; meta: META }> = []
    const itemsWithinView: Array<{ id: string; meta: META }> = []
    const itemIdsWithinView: Array<string> = []

    Object.entries(this.items).forEach(([id, item]) => {
      if (o.shouldShowItem({ meta: item.meta })) {
        itemIdsWithinView.push(id)

        itemsWithinView.push({ id, meta: item.meta })

        // TODO: add expiration to cache, probably here
        if (!item.value[o.cacheKey]) {
          itemsWithinViewThatDontHaveValuesYet.push({ id, meta: item.meta })
        }
      } else if (item.overlay !== undefined) {
        this.hideOverlay({ meta: item.meta, overlay: item.overlay })
      }
    })

    return {
      itemsWithinViewThatDontHaveValuesYet,
      itemIdsWithinView,
      itemsWithinView,
    }
  }

  process(o: {
    itemsWithinViewThatNowHaveValues: Array<{ id: string; value: VALUE }>
    itemIdsWithinView: Array<string>
    cacheKey: string
  }) {
    o.itemsWithinViewThatNowHaveValues.forEach((responseItem) => {
      const item = this.items[responseItem.id]

      item.value[o.cacheKey] = responseItem.value
    })

    //#region RENDER
    o.itemIdsWithinView.forEach((id) => {
      const item = this.items[id]

      if (item.overlay === undefined) {
        item.overlay = this.createOverlay({ map: this.map, maps: this.maps, meta: item.meta })
      }

      this.renderOverlay({
        map: this.map,
        maps: this.maps,
        meta: item.meta,
        value: item.value[o.cacheKey],
        overlay: item.overlay,
      })
    })

    //#endregion RENDER
  }
}
