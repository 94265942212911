import { routes } from '@semios/app-platform-banyan-route-definitions'
import { apiFetch } from 'utils/apiFetch'

export const serviceCenterNodeLogsGet = async (propertyIds: number[]) => {
  const nodeLogs = await apiFetch<
    routes.ServiceCenterNodeLogsGet.Request,
    routes.ServiceCenterNodeLogsGet.Response
  >({
    url: routes.ServiceCenterNodeLogsGet.path,
    body: {
      propertyIds,
    },
    params: {
      method: 'POST',
    },
  })

  return nodeLogs
}
