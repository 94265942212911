import { makeBaseSvgIconProps } from 'components/icons/_utils/makeBaseSvgIconProps'

export const IconNfcScan = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 16 16')}>
      <path
        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM5.168 9.96L4.08 9.464C4.304 8.976 4.408 8.472 4.4 7.976C4.392 7.472 4.288 6.984 4.08 6.536L5.168 6.032C5.448 6.632 5.592 7.28 5.6 7.952C5.608 8.64 5.464 9.312 5.168 9.96ZM7.624 11.208L6.584 10.616C7 9.88 7.208 9.032 7.208 8.096C7.208 7.144 6.992 6.232 6.568 5.376L7.64 4.84C8.152 5.864 8.408 6.96 8.408 8.096C8.408 9.24 8.144 10.288 7.624 11.208ZM10.136 12.264L9.056 11.736C9.68 10.456 10 9.192 10 7.984C10 6.776 9.68 5.528 9.056 4.272L10.128 3.736C10.848 5.16 11.2 6.584 11.2 7.984C11.2 9.376 10.848 10.816 10.136 12.264Z"
        fill="currentColor"
      />
    </svg>
  )
}
