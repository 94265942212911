import { useMantineTheme } from '@mantine/core'
import type { Ref } from 'react'
import React, { forwardRef } from 'react'
import { colors } from 'settings/colors'
import { useMultiSelectContext } from '../../MultiSelect.context'
import type { TOptions } from '../../MultiSelect.types'
import { MultiSelectListSearchInput } from '../MultiSelectListSearchInput'
import { Option } from '../Option'
import { Section } from '../Section'
import { ActionButtons } from './components/ActionButtons'
import { LoadingMessage } from './components/LoadingMessage'
import { TopSectionItems } from './components/TopSectionItems'

export const List = forwardRef((_, ref: Ref<HTMLInputElement>) => {
  const {
    childrenUpper,
    childrenUpperList,
    data,
    searchInDropdown,
    options,
    loading,
    loadingMessage,
    header,
    hideClearAll,
    hideSelectAll,
    isDropdownOpen,
    selectedValues,
    topSectionItems,
    isSelectedValue,
    notFoundContent,
    setSelectedValues,
    customSelectListBottomSection,
    groupOptions,
    topSectionLabel,
    bottomSectionLabel,
    dropdownPosition,
    onChange,
  } = useMultiSelectContext()

  const theme = useMantineTheme()

  const renderTopSectionItems = () => {
    const customData = data.filter(
      (item: TOptions) => topSectionItems && item && item.id && topSectionItems.includes(item.id),
    )

    return customData.map((item: TOptions) => {
      const isSelected = isSelectedValue(item)

      return (
        <React.Fragment key={item.id}>
          <Section isSelected={isSelected} sectionData={item} />
        </React.Fragment>
      )
    })
  }

  return (
    <div
      css={{
        position: 'absolute',
        width: '100%',
        background: theme.colors.white[0],
        bottom: dropdownPosition === 'top' ? '36px' : undefined,
        borderRadius: 4,
        marginTop: 1,
        marginBottom: 1,
        zIndex: 2,
        display: isDropdownOpen ? 'block' : 'none',
        border: `1px solid ${colors.grey500}`,
      }}
    >
      {(searchInDropdown || childrenUpper) && (
        <div css={{ padding: 10, margin: '10px 0', borderBottom: `1px solid ${colors.grey500}` }}>
          {searchInDropdown && <MultiSelectListSearchInput ref={ref} />}
          {childrenUpper && childrenUpper}
        </div>
      )}
      {loading ? (
        <LoadingMessage loadingMessage={loadingMessage} />
      ) : (
        <div
          css={{
            position: 'relative',
            display: 'block',
            padding: 0,
            margin: 0,
            borderRadius: 4,
            maxHeight: '40vh',
            overflowY: 'auto',
          }}
        >
          {options.length === 0 && (
            <span
              css={{
                padding: '20px 10px',
                display: 'block',
                fontSize: 14,
                textAlign: 'center',
                color: theme.colors.grey[2],
              }}
            >
              {notFoundContent}
            </span>
          )}
          <div>{childrenUpperList && childrenUpperList}</div>
          {options.length > 0 && (
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: '10px 10px 0',
                fontSize: 14,
                cursor: 'pointer',
                color: theme.colors.primary[0],
              }}
            >
              <ActionButtons
                hideClearAll={hideClearAll}
                hideSelectAll={hideSelectAll}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                onChange={onChange}
                options={options}
              />
            </div>
          )}
          {topSectionLabel && (
            <TopSectionItems
              topSectionLabel={topSectionLabel}
              bottomSectionLabel={bottomSectionLabel}
              renderTopSectionItems={renderTopSectionItems}
            />
          )}
          {header && (
            <div css={{ padding: '10px 10px 0' }}>
              {typeof header === 'string' ? (
                <div
                  css={{
                    color: colors.grey500,
                    fontSize: 12,
                    lineHeight: '14px',
                  }}
                >
                  {header}
                </div>
              ) : (
                header
              )}
            </div>
          )}
          {!groupOptions ? <Option.Default /> : <Option.Group />}
          {customSelectListBottomSection}
        </div>
      )}
    </div>
  )
})

List.displayName = 'MultiSelect.List'
