import { Button, LoadingOverlay, Popover } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { openConfirmModal } from '@mantine/modals'
import { routes } from '@semios/app-platform-banyan-route-definitions'
import { arrayOfObjectsSearch } from '@semios/app-platform-common'
import { Authorization } from 'components/Authorization/Authorization'
import { IconEdit } from 'components/icons/IconEdit'
import { IconTrashCan } from 'components/icons/IconTrashCan'
import { SummaryTable } from 'components/SummaryTable/SummaryTable'
import { translate } from 'i18n/i18n'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { colors } from 'settings/colors'
import { apiFetch } from 'utils/apiFetch'
import { checkAuthorization } from 'utils/checkAuthorization'
import { showNotification } from 'utils/showNotification'
import { natComparator } from 'utils/sort-by-key'
import { useSearchInput } from 'utils/useSearchInput'
import type { TAdminPermission } from '../../utils/useAdminEntites'
import { ActionButton } from '../ActionButton/ActionButton'
import { ViewEditPermissionModal } from './ViewEditPermissionModal/ViewEditPermissionModal'

export const Permissions = ({
  permissions,
  loading,
  onRefresh,
}: {
  permissions: TAdminPermission[]
  loading: boolean
  onRefresh: () => void
}) => {
  const PAGE_SIZE = 25
  const [permissionFormOpen, setPermissionFormOpen] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState<TAdminPermission | null>(null)
  const [opened, { close, open }] = useDisclosure(false)
  const [paginationResetKey, setPaginationResetKey] = useState(`${Math.random()}`)

  const userFirstNameLastInitialReturner = (
    user: TAdminPermission['createdBy'] | TAdminPermission['updatedBy'],
  ) => {
    let returner = translate.phrases.banyanApp('Unknown')

    try {
      const { firstName, lastName } = user || {}

      if (firstName || lastName) returner = `${firstName || ''} ${lastName?.[0] || ''}`
    } catch (err) {}

    return returner
  }

  const columns = [
    {
      title: translate.phrases.banyanApp('ID'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: translate.phrases.banyanApp('Permission Types'),
      dataIndex: 'permissionTypes',
      key: 'permissionTypes',
      render: (value: string[], record: TAdminPermission) => {
        if (value.length < 2) return value

        return (
          <Popover withArrow shadow="md" radius={5} opened={opened}>
            <Popover.Target>
              <div onMouseEnter={open} onMouseLeave={close} css={{ marginLeft: 5 }}>
                <span css={{ marginLeft: 5, textDecoration: 'underline dotted' }}>{value.length}</span>
              </div>
            </Popover.Target>
            <Popover.Dropdown
              css={{ pointerEvents: 'none', color: colors.midnight, fontSize: 14, fontWeight: '400' }}
            >
              <div css={{ display: 'flex', flexDirection: 'column', overflow: 'auto', maxHeight: '50vh' }}>
                {value.map((permission: string) => (
                  <div key={record.id}>{permission}</div>
                ))}
              </div>
            </Popover.Dropdown>
          </Popover>
        )
      },

      width: 160,
    },
    {
      title: translate.phrases.banyanApp('Last Modified At'),
      dataIndex: 'createdAt',
      key: 'lastModifiedAt',
      render: (_: string, record: TAdminPermission) =>
        moment
          .tz(record.updatedAt || record.createdAt, 'America/Los_Angeles')
          .format('ddd, MMM D YYYY, h:mm a'),
      sorter: (a: TAdminPermission, b: TAdminPermission) =>
        +new Date(b.updatedAt || b.createdAt) - +new Date(a.updatedAt || a.createdAt),
      width: 195,
    },
    {
      title: translate.phrases.banyanApp('Last Modified By'),
      dataIndex: 'createdBy',
      key: 'lastModifiedBy',
      render: (_: string, record: TAdminPermission) =>
        userFirstNameLastInitialReturner(record.updatedAt ? record.updatedBy : record.createdBy),
      sorter: (a: TAdminPermission, b: TAdminPermission) => {
        let aComparer = userFirstNameLastInitialReturner(a.updatedAt ? a.updatedBy : a.createdBy)
        let bComparer = userFirstNameLastInitialReturner(b.updatedAt ? b.updatedBy : b.createdBy)

        return natComparator(aComparer, bComparer)
      },
      width: 155,
    },
    {
      title: translate.phrases.banyanApp('Actions'),
      key: 'action',
      dataIndex: 'action',
      width: 80,
      render: (_: string, record: TAdminPermission) => {
        const userHasEditPermission = checkAuthorization({
          permission: 'ADMIN_SET_PERMISSION_DEFINITIONS',
          entity: '*',
        })

        if (!userHasEditPermission) return null

        return (
          <ActionButton
            menuItems={[
              {
                icon: <IconEdit />,
                children: (
                  <span
                    onClick={() => {
                      setSelectedPermission(record)

                      setPermissionFormOpen(true)
                    }}
                  >
                    {translate.phrases.banyanApp('View/Edit Permission')}
                  </span>
                ),
              },
              {
                icon: <IconTrashCan />,
                children: (
                  <span
                    onClick={() => {
                      openConfirmModal({
                        styles: {
                          root: {
                            padding: 10,
                          },
                        },
                        title: `Are you sure you want to delete the permission ${record.id}?`,
                        labels: {
                          confirm: translate.phrases.banyanApp('Delete'),
                          cancel: translate.phrases.banyanApp('Cancel'),
                        },
                        onCancel: () => null,
                        onConfirm: async () => {
                          await apiFetch<
                            routes.AdminDeletePermission.Request,
                            routes.AdminDeletePermission.Response
                          >({
                            url: routes.AdminDeletePermission.path,
                            body: {
                              permissionId: record.id,
                            },
                          })
                            .then((result) => {
                              if (result) {
                                if (result.errors.length) {
                                  showNotification({
                                    message: translate.phrases.validation('{{label}} could not be deleted', {
                                      label: translate.phrases.banyanApp('Permission'),
                                    }),
                                    type: 'error',
                                  })
                                } else {
                                  showNotification({
                                    message: translate.phrases.banyanApp('Permission deleted'),
                                    type: 'success',
                                  })
                                }
                              }

                              return result
                            })
                            .catch(() => {
                              showNotification({
                                message: translate.phrases.validation('{{label}} could not be deleted', {
                                  label: translate.phrases.banyanApp('Permission'),
                                }),
                                type: 'error',
                              })
                            })
                            .finally(() => {
                              onRefresh()
                            })
                        },
                      })
                    }}
                  >
                    {translate.phrases.banyanApp('Delete')}
                  </span>
                ),
              },
            ]}
          />
        )
      },
    },
  ]

  const { SearchInput, searchString } = useSearchInput({
    placeholder: translate.phrases.banyanApp('Search for a permission'),
  })

  const displayedData = arrayOfObjectsSearch(permissions, searchString, ['id'])

  useEffect(() => {
    setPaginationResetKey(`${Math.random()}`)
  }, [searchString])

  return (
    <>
      <LoadingOverlay visible={loading} />
      {!loading && permissions.length && (
        <>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <div css={{ margin: 10 }}>{SearchInput}</div>
            </div>
            <Authorization requires={{ permission: 'ADMIN_SET_PERMISSION_DEFINITIONS', entity: '*' }}>
              <Button
                variant="filled"
                onClick={() => {
                  setSelectedPermission(null)

                  setPermissionFormOpen(true)
                }}
              >
                <span>{translate.phrases.banyanApp('Create New Permission')}</span>
              </Button>
            </Authorization>
          </div>
          <SummaryTable
            autoSortColumns={['id', 'permissionTypes', 'createdAt', 'createdBy']}
            columns={columns}
            data={displayedData}
            pageSize={PAGE_SIZE}
            paginationResetKey={paginationResetKey}
          />
        </>
      )}
      <ViewEditPermissionModal
        permission={selectedPermission}
        opened={permissionFormOpen}
        onClose={(hasChanges) => {
          setPermissionFormOpen(false)

          if (hasChanges) {
            onRefresh()
          }
        }}
      />
    </>
  )
}
