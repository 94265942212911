import { translate } from 'i18n/i18n'

export const TableLengthText = ({
  activePage,
  filteredRows,
  totalRows,
  pageSize,
}: {
  activePage: number
  filteredRows: number
  totalRows: number
  pageSize: number
}) => {
  const isFiltered = filteredRows < totalRows
  const startRow = !activePage || activePage === 1 ? 1 : activePage * pageSize - (pageSize - 1)
  const endRow = !activePage ? filteredRows : Math.min(activePage * pageSize, filteredRows)

  let text = translate.phrases.banyanApp('Showing all {{count}} rows', { count: totalRows })

  if (!isFiltered && filteredRows > pageSize) {
    text = translate.phrases.banyanApp('Showing rows {{startRow}} to {{endRow}} of {{totalRows}}', {
      startRow,
      endRow,
      totalRows,
    })
  }

  if (isFiltered && filteredRows <= pageSize) {
    text = translate.phrases.banyanApp('Showing all {{filteredRows}} rows (filtered from {{totalRows}})', {
      filteredRows,
      totalRows,
    })
  }

  if (isFiltered && filteredRows > pageSize) {
    text = translate.phrases.banyanApp(
      'Showing rows {{startRow}} to {{endRow}} of {{filteredRows}} rows (filtered from {{totalRows}})',
      { startRow, endRow, filteredRows, totalRows },
    )
  }

  return <h4 css={{ fontWeight: 600, fontSize: 14 }}>{text}</h4>
}
