import { useMantineTheme } from '@mantine/core'
import { closeModal } from '@mantine/modals'
import React from 'react'
import { ACTION_MENU_MODAL_ID } from '../ActionMenu'

interface MenuButtonProps {
  label: string
  description?: string
  onClick: () => void
  disabled?: boolean
}

export const MenuButton: React.FC<MenuButtonProps> = ({ label, description, onClick, disabled }) => {
  const theme = useMantineTheme()

  return (
    <button
      css={{
        display: 'block',
        width: '100%',
        padding: 10,
        border: 'none',
        borderBottom: `1px solid ${theme.colors.grey[1]}`,
        cursor: 'pointer',
        backgroundColor: 'transparent',
        textAlign: 'left',
      }}
      onClick={() => {
        onClick()

        // Close the action menu after an action is clicked
        closeModal(ACTION_MENU_MODAL_ID)
      }}
      disabled={disabled}
    >
      <div css={{ fontSize: '14px', fontWeight: 600 }}>{label}</div>

      {description && (
        <div css={{ fontSize: 12, marginTop: 10, color: theme.colors.grey[2] }}>{description}</div>
      )}
    </button>
  )
}
