import { useMemo, useRef } from 'react'
import { Bottom } from './Bottom/Bottom'
import { GridTableScrollContext } from './context'
import { RightSideShadow } from './RightSideShadow/RightSideShadow'
import { Top } from './Top/Top'
import type { GridTableProps } from './types'
import { getTableLayout } from './_utils/getTableLayout'
import { useIsScrolledToEdges } from './_utils/useIsScrolledToEdges'

export const GridTable = ({
  childrenUpper,
  columns,
  content,
  containerWidth,
  topLeftCell,
}: GridTableProps) => {
  const gridTableRef = useRef<HTMLDivElement>(null)

  const { tableWidth, widthForLabelColumn, widthForValueColumns } = useMemo(() => {
    return getTableLayout({ columns, content, containerWidth, topLeftCell })
  }, [content, columns, topLeftCell?.minWidth])

  const { allTheWayBottom, allTheWayLeft, allTheWayRight, allTheWayTop } = useIsScrolledToEdges({
    availableWidth: tableWidth,
    wrapperRef: gridTableRef?.current,
  })

  return (
    <div
      css={{
        display: 'flex',
        overflow: 'hidden',
        maxHeight: '100%',
        boxShadow: `inset 0px -50px 25px -25px rgba(180, 179, 187, 0${!allTheWayBottom ? '.7' : ''})`,
        transition: '200ms ease all',
        flexDirection: 'column',
      }}
    >
      <div css={{ padding: '10px 20px' }}>{childrenUpper}</div>
      <GridTableScrollContext.Provider value={{ allTheWayLeft, allTheWayRight }}>
        <div ref={gridTableRef} css={{ overflowX: 'auto', minWidth: `${containerWidth}px` }}>
          <Top
            addShadow={!allTheWayTop}
            columns={columns}
            tableWidth={tableWidth}
            topLeftCell={topLeftCell}
            widthForLabelColumn={widthForLabelColumn}
            widthForValueColumns={widthForValueColumns}
          />
          <Bottom
            columns={columns}
            containerWidth={containerWidth}
            content={content}
            tableWidth={tableWidth}
            widthForLabelColumn={widthForLabelColumn}
            widthForValueColumns={widthForValueColumns}
          />
        </div>
        <RightSideShadow gridTableRef={gridTableRef} />
      </GridTableScrollContext.Provider>
    </div>
  )
}
