import { Checkbox } from 'components/Checkbox/Checkbox'
import { SectionTitleBar } from 'components/SectionTitleBar/SectionTitleBar'
import type { Dispatch, SetStateAction } from 'react'
import { Fragment, useState } from 'react'
import { colors } from 'settings/colors'
import { getConvertedWaterVolume } from 'utils/getConvertedWaterVolume'
import { makeNameEmitterTypeLabel } from 'utils/makeNameEmitterTypeLabel'
import { Separator } from '../components/Separator'
import { useEmitterConfigurationUnit } from '../hooks/useEmitterConfigurationUnit'
import type { TZoneFlowRateByEmitterType } from '../types'
import type { TSelectedZones } from './ModalDrawerBulkEdit'

type TZoneSelectionSection = {
  propertyId: number
  propertyName: string
  irrigationZones: TZoneFlowRateByEmitterType[]
  selectedZones: TSelectedZones[]
  setSelectedZones: Dispatch<SetStateAction<TSelectedZones[]>>
}

export const ZoneSelectionSection = ({
  propertyId,
  propertyName,
  irrigationZones,
  selectedZones,
  setSelectedZones,
}: TZoneSelectionSection) => {
  const [collapsed, setCollapsed] = useState(false)
  const { unit, precision, dbUnitPerHour } = useEmitterConfigurationUnit()

  const handleCheckboxChange = ({ emitterType, id, zoneId, propertyId }: TSelectedZones) => {
    const isSelected = selectedZones.some((zone) => zone.id === id)

    if (isSelected) {
      setSelectedZones((prev) => prev.filter((zone) => zone.id !== id))
    } else {
      setSelectedZones((prev) => [...prev, { emitterType, id, zoneId, propertyId }])
    }
  }

  return (
    <div>
      <SectionTitleBar
        key={propertyName}
        collapsed={collapsed}
        onCollapse={() => setCollapsed((prev) => !prev)}
        title={propertyName}
      />
      <div
        css={{
          padding: collapsed ? 0 : '20px',
          opacity: collapsed ? 0 : 1,
          height: collapsed ? 0 : '100%',
          overflow: collapsed ? 'hidden' : 'unset',
          boxSizing: 'border-box',
          borderCollapse: 'collapse',
          display: 'flex',
          gap: collapsed ? 0 : 20,
          flexDirection: 'column',
        }}
      >
        {irrigationZones.map((zone, zoneIndex) => {
          const { flowRate, zoneId, zoneName } = zone

          return (
            <Fragment key={zoneId}>
              {Object.values(flowRate).map((rate, rateIndex) => {
                const { emitterType, id, flowRate, unitPerHour } = rate
                const label = makeNameEmitterTypeLabel({ name: zoneName, emitterType })
                const hideSeparator = rateIndex === 0 && zoneIndex === 0

                const convertedFlowRateValue = getConvertedWaterVolume({
                  flowUnitPerHour: unitPerHour,
                  volumeUnitToDisplay: dbUnitPerHour,
                  waterApplied: flowRate,
                  decimalPlacesToRound: precision,
                }) as number

                return (
                  <Fragment key={id}>
                    {!hideSeparator && <Separator />}
                    <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Checkbox
                        key={id}
                        value={id}
                        label={label}
                        onChange={() => handleCheckboxChange({ emitterType, id, zoneId, propertyId })}
                        checked={selectedZones.some((zone) => zone.id === id)}
                      />
                      <div css={{ fontSize: 16, color: colors.grey800 }}>
                        {convertedFlowRateValue} {unit}
                      </div>
                    </div>
                  </Fragment>
                )
              })}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}
