import { routes } from '@semios/app-platform-banyan-route-definitions'
import { serviceCenterStore } from 'App/ServiceCenter/store/serviceCenterStore'
import { apiFetch } from 'utils/apiFetch'

export type PlannedNodesRepositionMeta = routes.ServiceCenterPlannedNodesReposition.Request

const request = async (meta: PlannedNodesRepositionMeta): Promise<PlannedNodesRepositionMeta> => {
  await apiFetch<
    routes.ServiceCenterPlannedNodesReposition.Request,
    routes.ServiceCenterPlannedNodesReposition.Response
  >({
    url: routes.ServiceCenterPlannedNodesReposition.path,
    body: meta,
    params: {
      method: 'PUT',
    },
  })

  return meta
}

const callback = (meta: PlannedNodesRepositionMeta) => {
  serviceCenterStore.actions.repositionPseudoNodes(meta.pseudonodes)
}

export const serviceCenterPlannedNodesReposition = {
  request,
  callback,
}
