import { lazy, memo, Suspense, useState } from 'react'
import type { StackedChartSection } from './types'

const LazyNavigator = lazy(() => import('./Navigator/Navigator').then((m) => ({ default: m.Navigator })))

const LazySectionsWrapper = lazy(() =>
  import('./SectionsWrapper/SectionsWrapper').then((m) => ({ default: m.SectionsWrapper })),
)

export const StackedChart = ({
  childrenUpper,
  containerWidth,
  content,
  timezone,
  navigatorKey,
  hasExtraRightPadding,
}: {
  childrenUpper?: React.ReactNode
  containerWidth: number
  content: StackedChartSection[]
  timezone: string
  navigatorKey: string | null
  hasExtraRightPadding?: boolean
}) => {
  const [stackedChartId] = useState(`StackedChart-${Math.random()}`)

  if (!content.length) return null

  return (
    <div>
      <Suspense fallback={null}>
        <LazyNavigator
          childrenUpper={childrenUpper}
          sections={content}
          stackedChartId={stackedChartId}
          timezone={timezone}
          key={navigatorKey}
          hasExtraRightPadding={hasExtraRightPadding}
        />
      </Suspense>
      <Suspense fallback={null}>
        <LazySectionsWrapper
          containerWidth={containerWidth}
          sections={content}
          stackedChartId={stackedChartId}
          timezone={timezone}
          hasExtraRightPadding={hasExtraRightPadding}
        />
      </Suspense>
    </div>
  )
}

export default memo(StackedChart)
