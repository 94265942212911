import { css } from '@emotion/css'
import type { CSSObject, MantineTheme, SelectItem } from '@mantine/core'
import { Loader, Select as MantineSelect } from '@mantine/core'
import type { CSSProperties, FC } from 'react'
import React, { useMemo } from 'react'
import { useScreenSize } from 'utils/useScreenSize'
import { Error } from './Icons/Error'
import { Success } from './Icons/Success'

export type TStateWithTimeout = 'default' | 'loading' | 'success' | 'error'

type SelectProps = {
  data: SelectItem[]
  inputStyle?: CSSObject
  icon?: React.ReactNode
  label?: string
  labelStyle?: CSSProperties
  value?: SelectItem['value']
  handleOnChange?: (newValue: SelectItem['value'] | null) => void
  stateWithTimeout?: TStateWithTimeout
  placeholder?: string
  width?: number
  disabled?: boolean
}

export const Select: FC<SelectProps> = ({
  data,
  inputStyle,
  icon: leftIcon,
  label,
  labelStyle,
  value,
  handleOnChange,
  stateWithTimeout = 'default',
  placeholder = '',
  width,
  disabled = false,
}) => {
  const { isWideScreen } = useScreenSize()

  const icon = useMemo(() => {
    switch (stateWithTimeout) {
      case 'default':
        return null

      case 'loading':
        return <Loader size="xs" />

      case 'success':
        return <Success size={18} />

      case 'error':
        return <Error size={18} />

      default:
        return null
    }
  }, [stateWithTimeout])

  return (
    <>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        {label && (
          <div
            className={css`
              font-weight: 700;
              display: flex;
              align-items: center;
              height: 35px;
            `}
            style={labelStyle}
          >
            {label}
          </div>
        )}

        {!isWideScreen && icon && (
          <div
            className={css`
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            {icon}
          </div>
        )}
      </div>
      <div
        className={css`
          flex-direction: row;
          display: flex;
        `}
      >
        <div
          css={{
            width: width ? width : '100%',
          }}
        >
          <MantineSelect
            placeholder={placeholder}
            value={value}
            onChange={(newValue) => {
              if (handleOnChange) handleOnChange(newValue)
            }}
            icon={leftIcon}
            data={data}
            disabled={disabled}
            styles={(theme: MantineTheme) => {
              return {
                input: {
                  'borderColor': stateWithTimeout === 'error' ? theme.colors.red[0] : theme.colors.grey[1],
                  '&:focus': {
                    borderColor: stateWithTimeout === 'error' ? theme.colors.red[0] : theme.colors.grey[1],
                  },
                  ...inputStyle,
                },
              }
            }}
          />
        </div>
        {isWideScreen && icon && (
          <div
            className={css`
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
            `}
          >
            {icon}
          </div>
        )}
      </div>
    </>
  )
}
