import { alphaColorReplacer, colors } from 'settings/colors'

export const Label = (props: { bottom: number; children: React.ReactNode }) => {
  return (
    <div css={{ position: 'absolute', bottom: props.bottom }}>
      <div
        css={{
          whiteSpace: 'nowrap',
          fontSize: 14,
          borderRadius: '10px',
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 8,
          paddingRight: 8,
          backgroundColor: alphaColorReplacer(colors.midnight, 0.8),
          color: 'white',
        }}
      >
        {props.children}
      </div>
    </div>
  )
}
