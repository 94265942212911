import type { FC } from 'react'
import React, { lazy, Suspense } from 'react'
import { smallStore } from 'stores/smallStore'

const WeatherForecastModal = lazy(() =>
  import('App/Map/WeatherForecastModal/WeatherForecastModal').then((m) => ({
    default: m.WeatherForecastModal,
  })),
)

export const WeatherForecastModalLazy: FC = () => {
  const showWeatherWidgetModal = smallStore.useSelector((s) => s.showWeatherWidgetModal)

  if (!showWeatherWidgetModal) return null

  return (
    <Suspense fallback={null}>
      <WeatherForecastModal />
    </Suspense>
  )
}
