import { useMantineTheme } from '@mantine/core'
import { translate } from 'i18n/i18n'
import React from 'react'
import Markdown from 'react-markdown'
import type { THelpGuideStep } from '../../types'

type HelpGuideStepProps = THelpGuideStep & { stepNumber: number; totalSteps: number }

export const HelpGuideStep: React.FC<HelpGuideStepProps> = ({
  title,
  content,
  image,
  stepNumber,
  totalSteps,
}) => {
  const theme = useMantineTheme()

  return (
    <div css={{ padding: 20, marginBottom: 60 }}>
      <span
        css={{
          fontSize: '12px',
          fontWeight: 'bold',
          color: theme.colors.grey[2],
          textTransform: 'uppercase',
        }}
      >
        {translate.phrases.placeholder('Step {{stepNumber}} of {{totalSteps}}', {
          stepNumber: stepNumber.toString(),
          totalSteps: totalSteps.toString(),
        })}
      </span>

      <h4
        css={{
          color: theme.colors.midnight[0],
          fontWeight: 'bold',
          margin: '5px 0 10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          minHeight: 20,
        }}
      >
        {title}
      </h4>

      {!!image && (
        <div css={{ marginTop: 10 }}>
          <img src={image} css={{ width: '100%' }} />
        </div>
      )}

      <div css={{ lineHeight: '24px', marginBottom: 20 }}>
        <Markdown>{content}</Markdown>
      </div>
    </div>
  )
}
