import { StatusBar, Style } from '@capacitor/status-bar'
import { useEffect } from 'react'

export type StatusBarThemeProps = {
  darkContent?: boolean
}

export const useSetStatusBarTheme = (props: StatusBarThemeProps): void => {
  useEffect(() => {
    StatusBar.setStyle({ style: props.darkContent ? Style.Dark : Style.Light }).catch(() => null)

    return () => {
      StatusBar.setStyle({ style: Style.Light }).catch(() => null)
    }
  })
}
