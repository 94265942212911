import { css } from '@emotion/css'
import type { GridTableColumn } from '../../../types'

export const Cell = ({
  children,
  column,
  widthForValueColumns,
}: {
  children: React.ReactNode
  column: GridTableColumn
  widthForValueColumns: number
}) => (
  <div
    className={css([
      {
        width: widthForValueColumns,
        flexGrow: 0,
        flexShrink: 0,
        textAlign: 'center',
        boxSizing: 'border-box',
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      column.headerCellCSS && column.headerCellCSS,
    ])}
  >
    {children}
  </div>
)
