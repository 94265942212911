import { InfoPopoverOnClick } from 'App/Map/UserSettingsMenu/Shared/Alerts/components/InfoPopoverOnClick'
import { ClickHereLink } from 'components/ClickHereLink/ClickHereLink'
import { translate } from 'i18n/i18n'

export const NaPopover = () => {
  return (
    <span>
      <InfoPopoverOnClick
        popoverContent={
          <div style={{ fontSize: '12px' }}>
            <translate.Phrases.banyanApp
              k="Root zone depth is not set, click <HereLink>here</HereLink> to configure"
              c={{ HereLink: <ClickHereLink>{null}</ClickHereLink> }}
            />
          </div>
        }
        yellowInfoIcon={true}
        outsideContent={
          <span style={{ textDecoration: 'underline', textUnderlineOffset: '2px', marginLeft: '3px' }}>
            {translate.phrases.banyanApp('NA')}
          </span>
        }
      />
    </span>
  )
}
