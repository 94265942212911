import type { TFieldAssetKeyTypes, TFieldAssetValueTypes } from 'App/Map/types'
import polylabel from 'polylabel'
import { sortByClosestPoints } from 'utils/sortByClosestPoints'
import { lngLatStringToLngLatObject } from './lngLatStringToLngLatObject'

export const getClosestIrrigationZoneEmitterInPropertyForAPoint = ({
  anchorCoordinates,
  property,
}: {
  anchorCoordinates: { lng: number; lat: number }
  property: TFieldAssetValueTypes.TProperty
}): TFieldAssetKeyTypes.TIrrigationZoneEmitterId | null => {
  const point = Object.values(property.points || {}).find((p) => {
    const isPointIrrigation = p.configuration.irrigationEmitterTypesAvailable
    const { lng, lat } = lngLatStringToLngLatObject(p.lngLat)

    return isPointIrrigation && lng === anchorCoordinates.lng && lat === anchorCoordinates.lat
  })

  const [closest] = Object.values(property.irrigationZoneEmitters ?? {})
    .filter((zoneData) => {
      const { irrigationZoneEmitterId } = zoneData

      if (point) {
        // filter only zones this point is inside
        return point.irrigationEmitterZoneIds?.includes(irrigationZoneEmitterId)
      } else {
        const pointsThatHaveThisZone = Object.values(property.points || {}).filter((p) =>
          p.irrigationEmitterZoneIds?.includes(irrigationZoneEmitterId),
        )

        return pointsThatHaveThisZone.length
      }
    })
    .map((f) => {
      const { coordinates } = JSON.parse(f.geometry)
      const [lng, lat] = polylabel([coordinates[0][0]], 1.0)

      return { lng, lat, irrigationZoneEmitterId: f.irrigationZoneEmitterId }
    })
    .sort(sortByClosestPoints(anchorCoordinates))

  if (closest) return closest.irrigationZoneEmitterId

  return null
}
