import type { TPhrases } from '@semios/app-platform-i18n-core'
import { translate } from 'i18n/i18n'
import { alphabeticalSort } from 'utils/alphabeticalSort'
import { getInsectNameFromId } from 'utils/getInsectNameFromId'

export type TPestSectionCategory =
  | 'trap-catches-property-level'
  | 'trap-catches-block-level'
  | 'degree-days'
  | 'larval-trend'

const pestSectionSortingWeight: Record<TPestSectionCategory, number> = {
  'trap-catches-property-level': 1,
  'trap-catches-block-level': 2,
  'degree-days': 3,
  'larval-trend': 4,
}

// this is a temporary solution to serve as a placeholder until we have more structured section-ordering
export const sortPestSections = (
  a: { title: string; id: string; pestSectionCategory: TPestSectionCategory; insectId: number },
  b: { title: string; id: string; pestSectionCategory: TPestSectionCategory; insectId: number },
) => {
  const insectA = getInsectNameFromId(a.insectId)
  const insectB = getInsectNameFromId(b.insectId)

  if (insectA !== insectB) {
    return alphabeticalSort(
      translate.phrases.dbInsectName(insectA as TPhrases['dbInsectName']),
      translate.phrases.dbInsectName(insectB as TPhrases['dbInsectName']),
    )
  }

  return pestSectionSortingWeight[a.pestSectionCategory] - pestSectionSortingWeight[b.pestSectionCategory]
}
