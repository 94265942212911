import { isNil } from 'lodash'
import { colors } from 'settings/colors'

const { idealBadge, moderateDeficitBadge, extremeDeficitBadge } = colors

export const getWalnutBlightStatus = (rpv7Days: number, threshold: number) => {
  let fillColor = 'transparent'

  if (isNil(rpv7Days)) return fillColor

  const riskPercentage = rpv7Days / threshold

  if (riskPercentage < 0.2) {
    fillColor = idealBadge
  } else if (riskPercentage >= 0.2 && riskPercentage < 0.8) {
    fillColor = moderateDeficitBadge
  } else {
    fillColor = extremeDeficitBadge
  }

  return fillColor
}
